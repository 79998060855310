// import  './../Assets/Styles/Responsive.css';
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../Components/functions/Auth";
import { useHistory } from 'react-router-dom';
//Components
import BoxHome from '../Components/Global/BoxInfo'
import ShowContracts from '../Components/Global/ShowContracts';
import ModalShow from '../Components/Global/Modal';
//img
import BoxImg from './../Assets/Img/General/portada_alumnos.png';
import user from './../Assets/Img/General/placeholder_boy.png';
//materialui
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// firebases
import * as firebase from 'firebase';
import { Paper, Typography } from '@material-ui/core';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

const boxInfo = {
    "box_title":    "Alumnos y contactos de referencia",
    "box_info":     "Busca, añade o modifica la información correspondiente a los alumnos. Recuerda que para añadir un nuevo alumno necesitarás información y documentos asociados al alumno, el padre de familia y los contactos de referencia",
    "box_list":[
        // "Lorem ipsum dolor sit amet.",
        // "Lorem ipsum dolor sit amet.",
        // "Lorem ipsum dolor sit amet."
    ],
    "box_button":{
        "btn_text": "Agregar alumno",
        "btn_link": "/alumnos/agregar",
    }
}

function Alumnos () {

    const history   = useHistory();
    const { currentUser }   = useContext(AuthContext);

    const [ refresh, setRefresh ]   = useState( 0 );
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ deleted, setDeleted ]   = useState( false );

    const [ list, setList ] = useState([]);
    const [ childID, setChildID ]       = useState('');
    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");


    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        setIsShow(false)
        ModalOpen();
        setIsDeleted( true );
        setChildID( id );
    }

    const deleteChild = ( e, status ) => {
        
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let childRef = db.ref(`childs/${childID}`);
            childRef.update(
                { status: "inactive", },
                (error) => {
                    if (error) {
                        setIsShow( true );
                        setIsDeleted( false );
                        setModalmsg( "Lo sentimos, algo extraño paso. Porfavor intenta de nuevo" );
                        ModalOpen();
                    }
                    else {
                        setRefresh( refresh + 1 )
                        setIsShow( true );
                        setIsDeleted( false );
                        setDeleted( false )
                        setModalmsg( "Alumno eliminado" );
                        ModalOpen();
                    }
                }
            );
        }
        else{
            ModalClose()
        }
    }
    
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`childs`);
        let listItem = [];
        let TutorsList = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                        TutorsList.push( itemInfo.tutors )
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user }
                                name={ `${itemInfo.name} ${itemInfo.last_name} ` }
                                info={ `${itemInfo.details.grade} ${ itemInfo.details.group }, Turno ${itemInfo.details.turn}` }
                                link={ `/alumnos/informacion/${item.key}` }
                                tutors={ TutorsList }
                            />
                        )
                    }
                })
                setList( listItem );      
            }
            if (  snapshot.val() == [] ) {
                setList( false );
            }
            if ( snapshot.val() == null){
                setList( false );
            }
        });
    }, [ refresh ] );

    return(
        <Grid className="alumn_grid" container>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted ?
                            <ModalShow
                                title=" ¿Estás seguro de eliminar a un alumno? "
                                confirmDelete={ true }
                                Delete={ deleteChild }
                            /> : null
                    }
                    {
                        isShow ?
                            <ModalShow
                                title={ modalMsg }
                                btnText="Continuar"
                                btnTo={`/referencias/${childID}`}
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid  xs={12}  id="BoxHomeAlumnos">
            {/* <NavEscuelaPhone /> */}
            <Box className="box_w">
                <BoxHome
                    box_img={ BoxImg }
                    box_title={ boxInfo.box_title }
                    box_text={ boxInfo.box_info }
                    box_item={ boxInfo.box_list }
                    box_btn_text={ boxInfo.box_button.btn_text }
                    box_btn_link={ boxInfo.box_button.btn_link }
                />
                </Box>
            </Grid>
            <Grid  xs={12}>
                <Box className="box_w" mt={5} boxShadow={3} >
                    { list 
                        ? <ShowContracts
                            list={ list }
                            details={ true }
                            onDeleted={ isElementDeleted }
                        />
                        : <Paper elevation={3} >
                            <Box className="" p={5} >
                                <Typography variant="h6" className="schoolInfo_title"> Sin información por el momento </Typography>
                            </Box>
                        </Paper>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

export default Alumnos
