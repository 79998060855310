import React, { useEffect, useState, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";

import { Grid, Box, Typography, Button } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PageHeader from '../../Components/Global/PageHeader'
import user from './../../Assets/Img/General/placeholder_boy.png';
import FormularioCitas from '../../Components/Teachers/FormularioCitas';


//FireBase
import * as firebase from 'firebase';
import NavTeacherPhone from '../../Pages/Maestros/NavTeacherPhone/NavTeacherPhone';

const ParentsItems =()=>{
    const match = useRouteMatch('/teacher/citas/tutores/:group/:student');
    const GroupID  = match.params.group;
    const childId  = match.params.student;
    const { currentUser, infoSchool } = useContext(AuthContext);
    const [tutorslist, setTutorsList] = useState([]);
    const [tutor, setTutor] = useState("");

    var listTutors = [];
    var list = ['primero','segundo'];
    
    useEffect( () => {
        let db = firebase.database();
        let ref = db.ref(`tutors/`);
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                let data = snapshot.val()
                var arrayTutors = Object.entries(data)            
                for(const tutor of arrayTutors)
                {
                    if(tutor[1].childs?.child_1 == childId || 
                        tutor[1].childs?.child_2 == childId || 
                        tutor[1].childs?.child_3 == childId || 
                        tutor[1].childs?.child_4 == childId || 
                        tutor[1].childs?.child_5 == childId || 
                        tutor[1].childs?.child_6 == childId || 
                        tutor[1].childs?.child_7 == childId || 
                        tutor[1].childs?.child_8 == childId || 
                        tutor[1].childs?.child_9 == childId   ) {
                        listTutors.push(tutor)
                    }
                }
                setTutorsList( listTutors );      
            }
            if ( listTutors === [] ) {
                setTutorsList( false );   
            }
            if ( listTutors === null ) {
                setTutorsList( false );   
            }
            if(listTutors.length < 1){
                setTutorsList(false)
            }
        });
    }, [ ] );

    
    return(
        <Grid container>
            {/* <NavTeacherPhone /> */}
            <Grid item xs={12} md={12}>
                <Box pb={3} className="PageHeader_toRight" >
                        <PageHeader 
                            title="Padre para cita"
                            text="Selecciona el padre al que deseas citar" 
                            btn_back="/"
                        />
                </Box>
            </Grid>
        
            <Grid item xs={12} >
                <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                {
                    tutorslist
                    ?tutorslist.map( item =>
                        <Link to={`/teacher/citas/tutores/${GroupID}/${childId}/${item[0]}`} >
                            <Grid  container direction="row" justify="space-between" alignItems="center">
                                <Grid xs={10} md={10}>
                                    <Box id="kiper_tutor_cita_childItem" p={2} display="flex" mt={2} >
                                        <img src={ item[1]?.avatar ? item[1]?.avatar : user } alt="avatar" className="img_home" style={{height:"70px", width:"70px"}}/>
                                        <Box  ml={2}>
                                            <Typography variant="h6" className="kipper_txt-grey" >
                                                { item[1]?.name } { item[1]?.last_name }
                                            </Typography>
                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                Contacto { item[1]?.contact?.email }{/* , { item[1]?.contact?.phone } */}
                                            </Typography>
                                            
                                        </Box>
                                    </Box>
                                </Grid>
                                
                                <Grid xs={1} md={1} className="arrow_end">
                                    <Box>
                                        <ArrowForwardIosIcon/>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                            <hr/>
                        </Link>
                     )
                        
                     :<Grid container xs={12} md={12} >
                         <Grid container py={3} direction="row" justify="space-between" alignItems="center">
                             <Box display="flex" p={2}>    
                                 <Typography variant="h6" className="kipper_txt-grey" >
                                     No hay tutores registrados para este alumno            
                                 </Typography>
                             </Box>
                         </Grid>
                     </Grid>
                 }
                </Box>
            </Grid>
        </Grid>
    );
}

export default ParentsItems;