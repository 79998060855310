import React,{useContext,useState} from 'react';
import { Link } from 'react-router-dom';

/*Material UI */
import {Badge,Menu,withStyles,MenuItem} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';

/* Sources */
import { AuthContext } from "../../../Components/functions/Auth";
import NavHeaderPhoneOptions from './/NavHeaderPhoneOptions';
import logo from './../../../Assets/Img/Layout/Logo_text.png';
import notificationIcon from './../../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';

/*¿Que hace NavChildPhone? Proyecta el menu para celular, se activa en dispositivos celulares con toda su funcionalidad para NIÑOS*/
/*NOTA: Para usarlo, se recomienda declararlo arriba del todo*/
const NavChildPhone =()=>{
    const { notif, handlelogout } = useContext(AuthContext);
    const [anchor, setAnchor] = useState(null);
  
    const handleNavTeacher=(event)=>{
        setAnchor(event.currentTarget);
    }

    const handleClose = () => {
        setAnchor(null);
    };

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
        },
      })((props) => (
        <Menu
          elevation={1}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          {...props}
        />
      ));
      
    const StyledMenuItem = withStyles((theme) => ({
        root: {
          '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.common.white,
            },
          },
        },
    }))(MenuItem);

    return(
        <div id="menuResponsive" className="navChild_menuContainer">
            <div id="navTeacher_logoKiper" >
                <img src={logo} />
            </div>
            
            
            <div className="navTeacher_iconsContainer">
                <Badge color="error" overlap="circle" badgeContent={''} invisible={notif}>
                    <Link to="/notificaciones">
                        <img src={ notificationIcon } alt="Usuario Kiper" style={{padding:"15%", marginRight:"30%", width:"35px", float:"right", filter: "contrast(1) invert(100%)"}} className="sidebar_iconNav"/>
                    </Link>
                </Badge>

                <DehazeIcon style={{fontSize:"35px", float:"right"}} onClick={handleNavTeacher} className="sidebar_iconNavDehaze" /> 

                <StyledMenu
                    id="customized-menu"
                    anchor={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                > 
                <NavHeaderPhoneOptions StyledMenuItem={StyledMenuItem} handlelogout={handlelogout}/>
                </StyledMenu>
                
            </div>
        </div>
    );
}  

export default NavChildPhone;