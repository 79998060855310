import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/placeholder_girl.png';
import logoSchool from './../../Assets/Img/Home/Logo_School.png';
import avatar from './../../Assets/Img/Home/tutor_example_avatar.JPG';
import download from './../../Assets/Img/Layout/download.png';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationIcon from '@material-ui/icons/LocationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Switch from '@material-ui/core/Switch';
import pencilIcon from './../../Assets/Img/Home/Icon_Pencil.png';
import CreateIcon from '@material-ui/icons/Create';


import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

function Documents() {
  const history = useHistory();
  const { currentUser, info }   = useContext(AuthContext);
  const [profileInfo, setProfileInfo] = useState('');
  const [isCheked, setIsCheked] = useState(false)
  const [ telephone, setTelephone] =useState(null);
  const [ change, setChange] = useState(false);
  const [ email, setEmail] = useState(null);
  const [error, setError] = useState({
                  phone: false, 
                  email: false
                });

  // Modal
  const [ modalStaus, setModalStaus ] = useState(false);
  const [ modalMsg, setModalmsg ]     = useState("");
  const [ modalLink, setModalLink ]   = useState("");

  const ModalOpen = () => {
      setModalStaus(true);
  };
  const ModalClose = () => {
      setModalStaus(false);
  };
  const modalGoTo = ( e, link ) => {
      e.preventDefault();
      ModalClose();
      if ( link ) {
          history.push(link);
      }
  };

  const alignRow = {
    display:'flex',
    justifyContent:'flex-end'
  }
  const regExps ={
    email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9-.]+$/,
    phone: /^[0-9.-]{10,15}$/,
}

  useEffect( () =>{
    handleCharge();
  }, [] );


  const handleCheck = e => {
    e.preventDefault();
    if(change){
      if(!regExps.phone.test(telephone)){
        setError({phone: true})
      }else if(!regExps.email.test(email)){
        setError({email: true})
      }else{
        setError({
          phone: false, 
          email: false
        })
        handleUpdate();
      }
    }
  }

  const handleCharge = () =>{
    let db = firebase.database();
    let ref = db.ref(`tutors/${currentUser}`);
    ref.once('value').then( snapshot => {
      if ( snapshot.val() ) {
        setProfileInfo( snapshot.val() );
        setTelephone(snapshot.val().contact.phone);
        setEmail(snapshot.val().contact.email)
      }
      else {
        setProfileInfo( false );   
      }
    });
  }

  const handleUpdate = () => {
    let db = firebase.database();
    let refUpdate = db.ref(`/tutors/${currentUser}/contact/`);
    refUpdate.update({
      phone: telephone
    }, (error) => {
      if(!error){
        handleCharge();
        setModalmsg('La informacion de contacto ha sido actualizada');
        ModalOpen();
      }
    });
  }
  
    return(
      <Box> 
        {/* <NavTutorPhone /> */}
        <Modal open={ modalStaus } onClose={ ModalClose }  >
          <Grid className="modal__Posotion modal__Global">
              <ModalShow title={ modalMsg } onlyShow={ true } showGoTo={ modalGoTo } goTo={ modalLink } />
          </Grid>
        </Modal>
        <form onSubmit={e => handleCheck(e)}>
          <Grid container className="padres_grid">                                   
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader title="Perfil" text="Información de tu cuenta"  btn_back="/" btn_action={true} btn_action_text="Guardar" />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid container xs={12} md={6} >
                          <Grid item md={ 4 } xs={4} p={2} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Box p={2} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <img src={ profileInfo.avatar ? profileInfo.avatar : user } className="Img_profile_tutor" style={{ width:'120px', height:'120px', borderRadius: '150px' }} alt="Avatar" />  
                            </Box>
                          </Grid>
                          <Grid item md={ 8 } xs={8} style={{display:'flex', alignItems:'center'}}>
                            <Box p={2} >
                              <Typography variant="h5" className="kipper_txt-grey">
                                  <b> { `${profileInfo.name} ${profileInfo.last_name}` } </b>
                              </Typography>
                              <Typography variant="body1" className="kipper_txt-grey">
                                Tutor
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} md={6} spacing={3} id="profile__dataContainer">
                            <Grid xs={12}>
                              <Box p={2} m={3} >
                                <Typography variant="h6" pb={2} className="kipper_txt-grey">
                                  <b> Personal </b> <br/>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid xs={12}>
                              <Box className="Dato_Prof_Tutor" style={{display: "flex", alignItems:'center'}} m={3}>
                                <PhoneIcon className="profile_icon"/> 

                                    <TextField value={ telephone } onChange={e =>{ setTelephone(e.target.value); setChange(true)}} style={{width:"70%", marginLeft: "2vw"}}
                                    error={error.phone} helperText={error.phone ? 'El numero telefonico debe contener de 10 a 15 digitos' : ' '}  /><br/>
                              </Box>
                            </Grid>
                            <Grid xs={12}>
                              <Box className="Dato_Prof_Tutor" style={{display: "flex", alignItems:'center' }}  m={3} >
                                  <EmailIcon className="profile_icon"/> 

                                  <TextField value={ email} onChange={e => {setEmail(e.target.value); setChange(true)}} style={{width:"70%", marginLeft: "2vw"}} disabled
                                  error={error.email} helperText={error.email ? 'La estructura del eMail debe ser "palabra@palabra.com"' : ' '}/><br/>
                              </Box>
                            </Grid>
                        </Grid> 
                    </Grid>
                </Box>
            </Grid>  
          </Grid>
        </form>
      </Box>
    )
}

export default Documents