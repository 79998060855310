import React, { useState, useContext }  from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from '../Components/Global/PageHeader';
import ModalShow from '../Components/Global/Modal'
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
import upload from './../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
//firebase
import * as firebase from 'firebase';
import { checkFile } from '../Components/functions/helpapers';

export default function CalendarioAgregar () {

    const history = useHistory();

    const [ eventType, setEventType ]       = useState('');
    const [ eventTo, setaEventTo ]          = useState('');
    const [ turn, setTurn ]                 = useState('');
    const [ grade, setGrade ]               = useState('');
    const [ group, setGroup ]               = useState('');
    const [ date, setDate ]                 = useState('');
    const [ time, setTime ]                 = useState('');
    const [ eventMessage, setEventMessage ] = useState('');
    const [ file, setFile]                  = useState('');
    const [ error, seterror ]               = useState('');
    const { currentUser, info} = useContext(AuthContext);
    const [errorMsg, seterrorMsg] = useState({
        file: false,
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };  
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const handlesubmit = e =>{
        e.preventDefault();
        // RegExp to check te fields 
        const regexname = RegExp(/\w/g);
        const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const regexphone = RegExp(/\d+[^a-zA-Z]{6,}/);
        const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

        if( eventType === "" ){ seterror('Ingrese el tipo de evento porfavor'); }
        else if( eventTo === "" ){ seterror('Ingrese los destinatarios del evento por favor'); }
        // else if( turn === "" ){ seterror('Ingrese el turno porfavor'); }
        // else if( grade === "" ){ seterror('Ingrese el grado porfavor'); }
        // else if( group === "" ){ seterror('Ingrese el grupo porfavor'); }
        else if( date === "" ){ seterror('Ingrese la fecha del evento por favor'); }
        else if( time === "" ){ seterror('Ingrese la hora del evento por favor'); }
        else if( eventMessage === "" ){ seterror('Ingrese los detalles del evento por favor'); }
        else {
            seterror('');
            handleForm(e);
        }
    };
    const handleForm = e => {
        e.preventDefault();
        const opcionalIMG =  file && document.getElementById('opcionalIMG').src;
        let db = firebase.database();
        let id_event = `event_${Math.floor(Math.random() * 10000)}`;
        let Usersref = db.ref(`events/${id_event}`);
        Usersref.set({
            id: id_event,
            school: currentUser,
            name: eventType,
            receiver_event: eventTo,
            details_event: eventMessage,
            date: date,
            time: time,
            timestamp: Date.now(),
            status: 'active',
            type: 'event',
            details:{
                grade: grade,
                gropu: group,
                turn: turn,
            },
            document:{
                document_1: opcionalIMG
            }
        }, function(error) {
            if (error) {
              // The write failed...
                console.log(error)
            } else {
                // Data saved successfully!
                setEventType('');
                setaEventTo('');
                setTurn('');
                setGrade('');
                setGroup('');
                setEventMessage('');
                seterror('');
                setModalmsg('El evento fue creado con éxito');
                setModalLink(`/eventos`);
                ModalOpen();
            }
        });
    }
    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();

        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setFile( img );
                seterrorMsg({...errorMsg, file: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, file: 'Formatos válidos: png, jpg y jpeg' });
        }
    }
    return(
        <form onSubmit={ e => handlesubmit(e) } >
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Grid container>
                <Grid xs={12} sm={12} md={12}>
                    <Box pb={3}>
                        <PageHeader 
                            title="Nuevo comunicado"
                            text="Define las configuraciones para el nuevo comunicado"
                            btn_back="/eventos"
                            btn_action=""
                        />
                    </Box>
                </Grid>

                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w ">
                        <Box >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Box>
                        <Box p={3}>
                            { error }
                            <Grid container>
                                <Grid xs={6}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="eventType">
                                            TIPO DE EVENTO
                                        </InputLabel>
                                        <TextField type="text" fullWidth required onChange={ e => setEventType(e.target.value) }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="eventFrom">
                                            DESTINATARIO
                                        </InputLabel>
                                        <Select labelId="eventFrom" displayEmpty required className="" fullWidth value={ eventTo } onChange={e => setaEventTo(e.target.value)} >
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="all">Todos</MenuItem>
                                            <MenuItem value="teacher">Maestros</MenuItem>
                                            <MenuItem value="tutor">Padres de familia</MenuItem>
                                            <MenuItem value="child">Alumnos</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid xs={4}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="sexo">
                                            TURNO
                                        </InputLabel>
                                        <Select labelId="sexo" displayEmpty className="" fullWidth value={ turn } onChange={e => setTurn(e.target.value)} >
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="Matutino">Matutino</MenuItem>
                                            <MenuItem value="Vespertino">Vespertino</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid xs={4}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="sexo">
                                            GRADO
                                        </InputLabel>
                                        <Select labelId="sexo" displayEmpty className="" fullWidth value={ grade } onChange={e => setGrade(e.target.value)} >
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="Primero">Primero</MenuItem>
                                            <MenuItem value="Segundo">Segundo</MenuItem>
                                            <MenuItem value="Tercero">Tercero</MenuItem>
                                            <MenuItem value="Cuarto">Cuarto</MenuItem>
                                            <MenuItem value="Quinto">Quinto</MenuItem>
                                            <MenuItem value="Sexto">Sexto</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid xs={4}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="sexo">
                                            GRUPO
                                        </InputLabel>
                                        <Select labelId="sexo" displayEmpty className="" fullWidth  value={ group } onChange={ e => setGroup(e.target.value)} >
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="A">A</MenuItem>
                                            <MenuItem value="B">B</MenuItem>
                                            <MenuItem value="C">C</MenuItem>
                                            <MenuItem value="D">D</MenuItem>
                                            <MenuItem value="E">E</MenuItem>
                                            <MenuItem value="F">F</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid xs={6}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="fecha">
                                            FECHA
                                        </InputLabel>
                                        <TextField id="fecha" type="date" fullWidth required onChange={ e => setDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="Hora">
                                            HORA
                                        </InputLabel>
                                        <TextField id="Hora" type="time" fullWidth required onChange={ e => setTime(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12}>
                                    <Box p={2}> 
                                        <InputLabel shrink id="sexo">
                                            DETALLES DEL EVENTO
                                        </InputLabel>
                                        <TextareaAutosize aria-label="minimum height" rowsMin={4} required placeholder="Detalles del evento" className="text_area-style" value={ eventMessage } onChange={e => setEventMessage(e.target.value)} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={2}>
                                        <InputLabel shrink id="uploadFile">
                                            DOCUMENTOS <br/>
                                        </InputLabel>
                                        <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                        <label for="docOpcional" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                { file 
                                                    ? <p className="kipper_txt-color">{ file.name }</p>
                                                    : <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                        { !!errorMsg.file && <span style={{color: 'red'}}><small>{ errorMsg.file }</small></span> }
                                        <Typography className="" variant="caption" display="block" gutterBottom>
                                            *La imagen debe ser tipo JPG, JPG o PNG
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={4}>
                                    <Box p={2}>
                                        <Button variant="contained" className="kipper_button" disableElevation type={'sumbit'} >
                                            Guardar
                                        </Button> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
