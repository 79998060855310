import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
import logoSchool from './../../Assets/Img/Home/Logo_School.png';
import download from './../../Assets/Img/Entregas/undraw_sync_files_xb3r.png';

//MaterialUI

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

function Documents() {
    const history = useHistory();
    const { currentUser, info }   = useContext(AuthContext);
    const [schoolInfo, setSchoolInfo] = useState([]);
    const [tutorInfo, setTutorInfo] = useState([]);


    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`tutors/${currentUser}`);
        ref.once('value').then( snapshot => {
            if (  snapshot.val().school ) {
                setTutorInfo(snapshot.val())
                let ref = db.ref(`schools/${snapshot.val().school}/documents`);
                ref.once('value').then( snapshotSchool => {
                    console.log(snapshotSchool.val())
                    if ( snapshotSchool.val() ) {
                        setSchoolInfo( snapshotSchool.val() );      
                    }
                    else{
                        setSchoolInfo( false );      
                    }
                })
            }
            else {
                setSchoolInfo( false );   
            }
        });
    }, [ ] );
    
    return(
        <Grid container className="padres_grid">
            {/* <NavTutorPhone /> */}
            <Grid item xs={12}>
                <Box pb={3} id="header_documents" className="PageHeader_toRight">
                    <PageHeader 
                        title="Documentos"
                        text="Visualiza y descarga los documentos de la escuela" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    { schoolInfo?.document_1 &&
                        <a href={ schoolInfo?.document_1 } target="_blank">
                            <Grid container >
                                <Grid item xs={10} md={10} >
                                    <Box className="kipperBox_documentName" p={2} >
                                        Reglamento Escolar
                                    </Box>
                                </Grid>
                                <Grid item xs={2} md={2} style={{display:'flex', justifyContent:'center'}}>
                                    <Box p={2} >
                                        <img src={ download } style={{ width:'40px' }} alt="" /> 
                                    </Box>
                                </Grid>
                                <Grid item md={12} p={2}>
                                    <Box> <hr/> </Box>
                                </Grid>
                            </Grid>
                        </a>
                    }
                    { schoolInfo?.document_2 &&
                        <a href={ schoolInfo?.document_2 } target="_blank">
                            <Grid container >
                                <Grid item xs={10} md={10} >
                                    <Box className="kipperBox_documentName" p={2} >
                                        Políticas de Privacidad
                                    </Box>
                                </Grid>
                                <Grid item xs={2} md={2} style={{display:'flex', justifyContent:'center'}}>
                                    <Box p={2} >
                                        <img src={ download } style={{ width:'40px' }} alt="" /> 
                                    </Box>
                                </Grid>
                                <Grid item md={12} p={2}>
                                    <Box> <hr/> </Box>
                                </Grid>
                            </Grid>
                        </a>
                    }
                    { schoolInfo?.document_3 &&
                        <a href={ schoolInfo?.document_3 } target="_blank">
                            <Grid container >
                                <Grid item xs={10} md={10} >
                                    <Box className="kipperBox_documentName" p={2} >
                                        Boletas de Calificacion
                                    </Box>
                                </Grid>
                                <Grid item xs={2} md={2} style={{display:'flex', justifyContent:'center'}}>
                                    <Box p={2} >
                                        <img src={ download } style={{ width:'40px' }} alt="" /> 
                                    </Box>
                                </Grid>
                                <Grid item md={12} p={2}>
                                    <Box> <hr/> </Box>
                                </Grid>
                            </Grid>
                        </a>
                    }
                    { schoolInfo?.document_4 &&
                        <a href={ schoolInfo?.document_4 }  target="_blank">
                            <Grid container >
                                <Grid item xs={10} md={10} >
                                    <Box className="kipperBox_documentName" p={2} >
                                        Documento
                                    </Box>
                                </Grid>
                                <Grid item xs={2} md={2} style={{display:'flex', justifyContent:'center'}}>
                                    <Box p={2} >
                                        <img src={ download } style={{ width:'40px' }} alt="" /> 
                                    </Box>
                                </Grid>
                                <Grid item md={12} p={2}>
                                    <Box> <hr/> </Box>
                                </Grid>
                            </Grid>
                        </a>
                    }
                    { !schoolInfo &&
                        <Grid container >
                            <Grid item xs={10} md={10} >
                                <Box p={2} >
                                    <Typography variant="h6" className="kipper_title" >
                                        <i class="fas fa-exclamation-circle " /> &nbsp;
                                        Sin documentos para descargar
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default Documents