import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';

// Material ui
import SchoolIcon from '@material-ui/icons/School';
import { Grid, Box, Typography, Avatar } from '@material-ui/core';
import * as firebase from 'firebase';
import ChildItem from '../../Components/tutors/tutorsItem';
import MaestroInfo from '../MaestrosInfo';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

const ChildInfo = ({ ChildID }) => {

    const { currentUser }   = useContext(AuthContext);
    const match             = useRouteMatch('/tutor/alumnos/:id');
    const childID           = match.params.id;

    const [childInfo, setChildInfo]     = useState('')
    const [tutors, setTutors]           = useState([])
    const [MestroInfo, setMaestroInfo] = useState([])
    const [maestroName, setMaestroName] = useState('')
    const [materiasInfo, setMateriasInfo] = useState([])


    useEffect(() => {
        getInfo()}, []);

    
    async function getInfo() {
        let db = firebase.database();
        let ref = await db.ref(`childs/${childID}`).once('value');
        let teacherInf = ref.val()
        if(teacherInf){
            setChildInfo(teacherInf)
        }else{
            setChildInfo(false)
        }

        ref = await db.ref(`schools/${teacherInf.school}/groups/${teacherInf.group}/school_subjects`).once('value')
        let MateriaList = []
        let MateriaInf;
        if(ref.val()){
            MateriaInf = Object.entries(ref.val())
        }
        if(MateriaInf) {
            for(const item of MateriaInf)
            {
                var nameMat = await db.ref('school_subjects/'+ item[0]).once('value');
                item[1]['title'] = nameMat.val()?.title
                MateriaList.push(item[1])       
            }
            setMateriasInfo(MateriaList)
        }else{
            setMateriasInfo(MateriaList)
        }
    }

    return (
        <Grid container className="padres_grid">
            {/* <NavTutorPhone /> */}
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title={ `${childInfo.name} ${childInfo.last_name} ` }
                        text="Información y detalles del alumno" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3} className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid container xs={12} md={6} >
                            <Grid item md={ 4 } xs={4} p={2} style={{display:'flex', justifyContent:'flex-end'}}>
                                <Box md={5} xs={5}>
                                    <Avatar src={ childInfo.avatar ? childInfo.avatar : user3 } style={{ width:'120px', height:'120px' }} alt="Avatar" />
                                    {/* <img src={ childInfo.avatar ? childInfo.avatar : user3 } style={{ width:'120px', height:'120px' }} alt="Avatar" />   */}
                                </Box>
                            </Grid>
                            <Grid item md={ 8 } xs={8} style={{display:'flex', alignItems:'center'}}>
                                <Box p={2} >
                                    <Typography variant="h5" className="kipper_txt-grey">
                                        <b> { `${childInfo.name} ${childInfo.last_name}` } </b>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={12} pl={3}>
                                <Box px={2} mt={2} style={{display:'flex', justifyContent:'center', alignItems:'center'}} >
                                    <Typography variant="h6" className="kipper_txt-grey">
                                        <b> Datos Escolares </b> <br/>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} mt={2}>
                                <Box px={2}>
                                    <Typography variant="body2" mt="2">
                                        <SchoolIcon/>{ ` Grado: ${childInfo?.details?.grade}` } <br/>
                                    </Typography>
                                    <Typography variant="body2">
                                        <SchoolIcon/> { ` Grupo: ${childInfo?.details?.group} ` } <br/>
                                    </Typography>
                                    <Typography variant="body2">
                                        <SchoolIcon/> { ` Turno: ${childInfo?.details?.turn} ` } <br/>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box align="center" mt={2} mb={2}>
                                <Typography align="center" variant="h5" className="kipper_txt-color">
                                    <b> Datos y asociados </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid  xs={12} md={6} >
                            <Grid id="kiper_tutor_alumno_tutorItem"  >
                                <Grid md={12}>
                                    <Box p={2} >
                                        <Typography align="center" variant="h6" pb={11}  className="kipper_txt-grey">
                                            <b> Tutor y delegados </b> <br/>
                                        </Typography>
                                    </Box>
                                </Grid>
                                { 
                                    childInfo?.tutors?.tutor_1 && 
                                        <Grid item xs={12} > 
                                            <Box mt={1} pr={5} >
                                                <ChildItem id={ childInfo?.tutors?.tutor_1 } isTutor={ true } status={1} /> 
                                            </Box>
                                        </Grid> 
                                }
                                { 
                                    childInfo?.tutors?.tutor_2 && 
                                        <Grid item xs={12} > 
                                            <Box mt={1} pr={5} >
                                                <ChildItem id={ childInfo?.tutors?.tutor_2 } isTutor={ true } status={2}/> 
                                            </Box>
                                        </Grid> 
                                }
                                { 
                                    childInfo?.tutors?.tutor_3 && 
                                        <Grid item xs={12} > 
                                            <Box mt={1} pr={5} >
                                                <ChildItem id={ childInfo?.tutors?.tutor_3 } isTutor={ true } status={2} /> 
                                            </Box>
                                        </Grid> 
                                }
                                { 
                                    childInfo?.tutors?.tutor_4 && 
                                        <Grid item xs={12} > 
                                            <Box mt={1} pr={5} >
                                                <ChildItem id={ childInfo?.tutors?.tutor_4 } isTutor={ true } status={2} /> 
                                            </Box>
                                        </Grid> 
                                }
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid>
                                <Grid item md={12}>
                                    <Box p={2} mt={2} >
                                        <Typography align="center" variant="h6" pb={11} className="kipper_txt-grey">
                                            <b> Maestros</b> <br/>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={12}>
                                    <Box p={2} >
                                        {
                                            MestroInfo &&
                                                <Grid id="kiper_tutor_alumno_tutorItem">
                                                    <Box display="flex" py={2} >
                                                        <img src={ MestroInfo.teacher_avatar ? MestroInfo.teacher_avatar : user } alt="avatar" className="img_home" />
                                                        <Box id="" ml={2} >
                                                            <Typography variant="h6" className="kipper_txt-grey" >
                                                                { maestroName }
                                                            </Typography>
                                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                                {`${childInfo?.details?.grade} ${childInfo?.details?.group}`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box >
                                                        {/* <ArrowForwardIosIcon /> */}
                                                    </Box>
                                                </Grid>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} >
                            <Grid md={12}>
                                <Box p={2} pl={4}>
                                    <Typography variant="h6" pb={11} className="kipper_txt-grey">
                                        <b> Materias </b> <br/>
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                materiasInfo.length > 0 ? 
                                    materiasInfo.map( materia => 
                                        <Grid id="kiper_tutor_alumno_materiaItem" item xs={4} key={ materia.key } >
                                            <Link to={`/tutor/alumno/tareas/${childInfo.group}/${materia.key}/${childID}`}>

                                                <Box boxShadow={2} m={2} p={2} pl={4} className="bg-white  outline-box">
                                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey">
                                                        <Box >
                                                                <Typography variant="subtitle1" className="fw-500 capitalize" >{ materia.title }</Typography>
                                                                <Typography variant="body1" className="fw-200 capitalize" >{ materia.teacher_name }</Typography>
                                                        </Box>
                                                        <Box>
                                                            <i class="fas fa-chalkboard fa-2x kipper_txt-grey opacity-05" />
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </Link>
                                        </Grid>
                                    )
                                : 
                                <Box pl={4} >
                                    <Typography variant="h6" className="kipper_title" >
                                        Sin Materias por el momento 
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ChildInfo
