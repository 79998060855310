import React from 'react'
import { Link } from 'react-router-dom';
import user from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Box, Typography, Divider } from '@material-ui/core'
import { useEffect } from 'react';
import { useState } from 'react';
import * as firebase from 'firebase';


function ChildItem({ id, isTutor, IsClild, isTeacher, status }) {

    const [child, setChild] = useState('')
    
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`tutors/${id}`);
        ref.once('value').then( snapshot => {
            if ( snapshot.val() ) {
                setChild( snapshot.val() )
            }
            else {
                setChild( false );   
            }
        });
    }, [id] );

    if ( isTutor ) {
        return (
            <Grid id="kiper_tutor_alumno_tutorNameItem" container direction="row" justify="space-between" alignItems="center" >
                <Box display="flex">
                    <Box ml={2}>
                        <img src={ child?.avatar ? child?.avatar : user } alt="" className="img_home" />
                    </Box>
                    <Box ml={2} className="center_center" >
                        <Typography variant="h6" className="kipper_txt-grey" >
                            { ` ${ child?.name } ${ child?.last_name } ` }
                        </Typography>
                        <br/>
                        <Typography variant="body1" className="kipper_txt-grey" >
                            {/* { status === 1 && 'Tutor' }
                            { status === 2 && 'Delegado' } */}
                        </Typography>
                    </Box>
                </Box>
                <Box >
                    {/* <ArrowForwardIosIcon /> */}
                </Box>
            </Grid>
        )
    }
    if ( IsClild ) {
        return (
            <Link to={ `/tutor/alumnos/` } >
                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Box display="flex">
                        <Box ml={2}>
                            <Typography variant="h6" className="kipper_txt-grey" >
                            </Typography>
                            <Typography variant="body1" className="kipper_txt-grey" >
                                Turno 
                            </Typography>
                        </Box>
                    </Box>
                    <Box >
                        <ArrowForwardIosIcon />
                    </Box>
                </Grid>
            </Link>
        )
    }
    
    if ( isTeacher ) {
        return (
            <Link to={ `/tutor/alumnos/` } >
                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Box display="flex">
                        <Box ml={2}>
                            <Typography variant="h6" className="kipper_txt-grey" >
                            </Typography>
                            <Typography variant="body1" className="kipper_txt-grey" >
                                Turno 
                            </Typography>
                        </Box>
                    </Box>
                    <Box >
                        <ArrowForwardIosIcon />
                    </Box>
                </Grid>
            </Link>
        )
    }
}

export default ChildItem