import React, { Component } from 'react';
//Components
import ContentSection from '../Components/Global/ContentSection';
import Boxinfo from '../Components/Global/BoxInfo'
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// IMG
import box1_img from './../Assets/Img/General/portada_espera.png';
import box2_img from './../Assets/Img/General/potada_lista-de-espera-item.png';
import box3_img from './../Assets/Img/General/portada_reportes.png';

const box_title = {
    'box_title_box1': 'Leer códigos QR',
    'box_title_box2': 'Lista de espera',
    'box_title_box3': 'Reportes',
}
const box_text = {
    'box_text_box1': 'Accede a la cámara para escanear los códigos QR de los padres de familia que llegan a recoger a los alumnos, recuerda revisar la coincidencia de la persona registrada con la persona que recoge.',
    'box_text_box2': '¿Es momento de entregar a los alumnos?, accede a la lista de espera de padres de familia que están más cerca de la escuela para ir llamando a los alumnos y facilitar la salida.',
    'box_text_box3': 'Descarga la lista de movimientos de salida de una fecha en específico para cualquier aclaración o análisis necesario, incluye hora, día, información del alumno y la persona que lo recogió.',
};
const box_btn_link = {
    'box1_btn_link': '',
    'box2_btn_link': '/lista_espera',
    'box3_btn_link': '',
}
const box_btn_text = {
    'box1_btn_text': 'Iniciar camara',
    'box2_btn_text': 'Lista de espera',
    'box3_btn_text': 'Descargar',
}

class Entregas extends Component {
    render( ) {
        return(
            <>
                <Grid container className="entregas_grid">
                    <Grid xs={12} >
                        <Box p={2}>
                            <Boxinfo 
                                box_img={ box1_img }
                                box_title={ box_title.box_title_box1 } 
                                box_text={ box_text.box_text_box1 } 
                                box_btn_text={ box_btn_text.box1_btn_text }
                                box_btn_link={ box_btn_link.box_btn_link }
                            /> 
                        </Box>
                    </Grid>
                    <Grid container spacing = {3} >
                        <Grid xs={12} md={6}>
                            <Box p={3}>
                                <ContentSection 
                                    box_img={ box2_img }
                                    box_title={ box_title.box_title_box2 } 
                                    box_text={ box_text.box_text_box2 }
                                    box_btn_text={ box_btn_text.box2_btn_text } 
                                    box_btn_link={ box_btn_link.box2_btn_link }
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box p={3} >
                                <ContentSection 
                                    box_img={ box3_img }
                                    box_title={ box_title.box_title_box3 } 
                                    box_text={ box_text.box_text_box3 } 
                                    box_btn_text={ box_btn_text.box3_btn_text } 
                                    box_btn_link={ box_btn_link.box3_btn_link }
                                />
                            </Box>
                        </Grid>
                    </Grid> 
                </Grid> 
            </> 
        );
    }
}

export default Entregas