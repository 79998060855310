import React from 'react'
import { Link } from 'react-router-dom';
import user from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Box, Typography } from '@material-ui/core'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import * as firebase from 'firebase';

import PageHeader from '../../Components/Global/PageHeader'
import NavTeacherPhone from '../../Pages/Maestros/NavTeacherPhone/NavTeacherPhone';

const ChildItem = () => {
    const history = useHistory();
    const match = useRouteMatch('/teacher/citas/tutores/:group');
    const GroupID = match.params.group;

    const [childs, setChilds] = useState([])
    
    useEffect( () =>{
        getChilds();
    }, [] );

    async function getChilds(){
        var listChilds = [];
        let db = await firebase.database().ref("childs/").once('value');
        var data = db.val();
        Object.entries(data).forEach( item =>{            
            if(item[1]?.group == GroupID && item[1].status == "active")
            {
                listChilds.push(item)
            }
        })
        setChilds(listChilds)

        if(listChilds == []){
            setChilds(false)
        }
        if(listChilds.length < 1){
            setChilds(false)
        }
    }

    return (    
        
        <Grid container>
            {/* <NavTeacherPhone/> */}
            <Grid item xs={12} md={12}>
                <Box pb={3} className="PageHeader_toRight" >
                    <PageHeader 
                        title="Alumno para cita"
                        text="Selecciona el alumno al que deseas solicitar la cita" 
                        btn_back="/"
                    />
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                    {console.log(childs)}
                {
                        childs
                        ?childs.map( item =>
                        <Link to={`/teacher/citas/tutores/${GroupID}/${item[0]}`} >
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Box id="kiper_tutor_cita_childItem" p={2} display="flex" mt={2}>
                                    <img src={ item[1].avatar ? item[1].avatar : user } alt="avatar" className="img_home" />
                                    <Box ml={2} style={{  alignSelf: "center"  }}>
                                        <Typography variant="h6" className="kipper_txt-grey" >
                                            { item[1].name } { item[1].last_name }
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Grid>
                            <hr/>
                        </Link>
                        )
                        
                        :<Grid container xs={12} md={12} >
                            <Grid container py={3} direction="row" justify="space-between" alignItems="center">
                                <Box display="flex" p={2}>    
                                    <Typography variant="h6" className="kipper_txt-grey" >
                                        No hay alumnos registrados en este grupo            
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    
                </Box>
            </Grid>

             
        </Grid>
    )
} 
export default ChildItem