import React, { useContext, useEffect, useState } from 'react'
import icon_alert from './../Assets/Img/General/Icon_danger.png'
import icon_salir from './../Assets/Img/General/Icon_out.png'
//Components
import PageHeader from './../Components/Global/PageHeader';
import NotificacionItem from './../Components/Global/NotificacionItem';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';
// import app from '../Components/functions/credentials';
import { AuthContext } from '../Components/functions/Auth';

const item = {
    'title': 'Nuevas funciones',
    'date': '27 junio',
    'body_title': 'Entregar',
    'body': 'Ahora te permite acceder a la cámara para escanear los códigos QR de los padres de familia que llegan a recoger a sus hijos.',
    'btn_action': 'action'
} 

function Notificaciones () {
    const { info, notif } = useContext( AuthContext );
    const [notifarray, setnotifarray] = useState([])

    useEffect(() => {
    }, [info]);


    const handlenotif = () =>{
        var listItem = [];
        if(info){
            if(info.notif){
                let arraynotif = Object.values(info.notif.notifications);
                arraynotif.forEach(element => {
                    listItem.push(
                        <NotificacionItem 
                        icon={ icon_salir }
                        title={ element.tittle }
                        date={'27 junio'}
                        body_title={ element.detail}
                        body={ element.detail }
                        btn_action={'action'}
                    />
                    )
                });
                setnotifarray(listItem)
            }
        }
    }

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid xs={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Notificaciones"
                        text="Aqui podrás recibir noticias de las últimas actualizaciones y novedades de la plataforma"
                    />
                </Box>
            </Grid>
            <Grid xs={12}>
                <Box className="kipperBox_content" boxShadow={3}>
                    {
                        notif
                        ?notif.map( notf => 
                            <NotificacionItem 
                                icon={ icon_alert }
                                title={ notf.title }
                                date={ notf.date }
                                body={ notf.body }
                                btn_action={ notf.btn_action }
                            />)
                        :null
                    }
                    {/* <NotificacionItem 
                        icon={ icon_salir }
                        title={ item.title }
                        date={ item.date }
                        body_title={ item.body_title}
                        body={ item.body }
                        btn_action={ item.btn_action }
                    /> */}
                    {notifarray}
                </Box>
            </Grid>
            <Grid xs={12}>
                {/* <Pagination count={10} /> */}
            </Grid>
        </Grid>
    )
}

export default Notificaciones