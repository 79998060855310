import React, { useState, useContext,  } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from '../Components/Global/PageHeader';
import ModalShow from '../Components/Global/Modal'
//img
import portada from './../Assets/Img/General/portada_add-blue.png';
import file from './../Assets/Img/General/File.png';
import upload from './../Assets/Img/General/Icon_upload.png';
import user from './../Assets/Img/General/placeholder_man.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
//firebase
import * as firebase from 'firebase';
import { InputLabel, MenuItem } from '@material-ui/core';
import { checkFile } from '../Components/functions/helpapers';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import Select from '../Constants/select';
import TextField from '../Constants/textfield';



function AgregarPadre ( ) {

    const history   = useHistory();
    const match     = useRouteMatch('/padres/agregar/:id');
    const childID   = match.params.id;
    const { currentUser } = useContext(AuthContext);

    const [stopHanldeSubmit,setStopHanldeSubmit] = useState(false);

    const [ docProfile, setDocProfile ]         = useState('');
    const [ docId, setDocId ]                   = useState('');
    const [ docProofAddres, setDocProofAddres ] = useState('');
    const [ docOpcional, setDocOpcional ]       = useState('');
    const [ error, seterror ]                   = useState('');
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    
    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        seterrorMsg({...errorMsg, avatar: '' });
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProfile( img );
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }
    }
    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        seterrorMsg({...errorMsg, id: '' });
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocId( img );
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg y jpeg' });
        }
    }
    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        seterrorMsg({...errorMsg, comprobante: '' });
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProofAddres( img );
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, comprobante: 'Formatos válidos: png, jpg y jpeg' });
        }
    }
    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        seterrorMsg({...errorMsg, doc: '' });
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocOpcional( img );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg y jpeg' });
        }
    }
    const addTutorReference = id => {
        let db = firebase.database();
        let childRef = db.ref(`childs/${childID}/tutors`);
        childRef.update({ tutor_1: id }, 
            function(error) {
                if (error) { console.log(error) } 
            }
        );
    }

    const handleForm = (val) =>{
        if(!stopHanldeSubmit){
            setStopHanldeSubmit(true);
            const avatarIMG = docProfile && document.getElementById('AvatarIMG').src ;
            const idIMG = docId && document.getElementById('idIMG').src ;
            const comprobanteIMG = docProofAddres && document.getElementById('comprobanteIMG').src;
            const opcionalIMG =  docOpcional && document.getElementById('opcionalIMG').src;

            let id_tutor = `tutor_${Date.now()}`;
            let db = firebase.database();
            let Usersref = db.ref(`tutors/${id_tutor}`);
            Usersref.set({
                id: id_tutor,
                school: currentUser,
                avatar: avatarIMG,
                name: val.name,
                last_name: val.lastName,
                type: val.type,
                status: "active",
                timestamp: Date.now(),
                contact:{
                    email: val.email,
                    phone: val.phone,
                },
                documents:{
                    id: idIMG,
                    opcional: opcionalIMG,
                    proof_addres: comprobanteIMG,
                },
                childs:{
                    child_1: childID,
                }
            }, 
            error => {
                if (error) {
                // The write failed...
                    setStopHanldeSubmit(false);
                    console.log(error)
                } else {
                    // Data saved successfully!
                    setDocProfile('');
                    setDocId('');
                    setDocProofAddres('');
                    setDocOpcional('');
                    addTutorReference( id_tutor );
                    setModalmsg('El Tutor fue creado con exito');
                    setModalLink(`/referencias/${childID}`);
                    ModalOpen();
                }
            }); 
        }
    }

    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .required('El nombre es obligatorio')
            .matches(/^\w\D{2,100}$/, "El nombre debe contener entre 2 a 100 caracteres" ),
        lastName: yup
            .string('Ingresa un apellido')
            .required('El apellido es obligatorio')
            .matches(/^\w\D{2,100}$/, "El apellido debe contener entre 2 a 100 caracteres" ),
        email: yup
            .string('Ingrese un correo electrónico')
            .email('Ingrese un correo válido')
            .required('El correo electrónico es obligatorio'),
        type: yup
            .string('Seleccione un tipo')
            .required('El tipo es obligatorio'),
        phone: yup
            .string('Ingrese un teléfono')
            .required('El teléfono es obligatorio')
            .matches(/^[1-9][0-9]{9,14}$/, 'El teléfono debe contener de 10 a 15 dígitos y debe ser númerico'),
    });

    const initialFormState ={
        name: '',
        lastName: '',
        email: '',
        type: '',
        phone: ''
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={validateForm}
            onSubmit={values =>{
                handleForm(values)
            }}
            >
                <Form>
                    <Modal
                        open={modalStaus}
                        onClose={ModalClose}
                    >
                        <Grid className="modal__Posotion modal__Global">
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            />
                        </Grid>
                    </Modal>
                    <Grid container className="add_padreGrid">
                        <Grid item xs={12}>
                            <Box pb={3}>
                                <PageHeader 
                                    title="Padre de familia o tutor"
                                    text="Información de seguridad del padre o tutor para la salida y recepción de los alumnos."
                                    btn_back={`/padres/${childID}`}
                                    btn_action={true}
                                    btn_action_text="Terminar"
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12} className="Form_add-content">
                            <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                                <Box >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Box>
                                <Box className="Form_add-profile-img">
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <div className="kipperBox_content-upload">
                                            <input type="file" id="ProfileImage" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e )} />
                                            <label for="ProfileImage" className="pointer">
                                                <Grid container direction="column" justify="center" alignItems="center">
                                            
                                                { docProfile 
                                                    ? <img alt="" className="previw_img rounded" id={'AvatarIMG'} />
                                                    : <img src={file} alt="" className="profileImg rounded" />
                                                }
                                                    <span>
                                                        <small>Elegir una foto de perfil</small> 
                                                    </span>
                                                { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                                </Grid>
                                            </label>
                                        </div>
                                    </Grid>
                                </Box>
                                <Box pt={3}>
                                    <Grid container>
                                        <Grid item sm={12} md={6}>
                                            <Box p={2}> 
                                                <InputLabel shrink> Nombre <b className="asterisk-w">*</b> </InputLabel>
                                                <TextField 
                                                    name="name"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <Box p={2}> 
                                                <InputLabel shrink> Apellido <b className="asterisk-w">*</b> </InputLabel>
                                                <TextField 
                                                    name="lastName"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink> Teléfono <b className="asterisk-w">*</b> </InputLabel>
                                                <TextField 
                                                    name="phone"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink> Correo Electrónico <b className="asterisk-w">*</b> </InputLabel>
                                                <TextField 
                                                    name="email"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink> Tipo<b className="asterisk-w">*</b> </InputLabel>
                                                <Select 
                                                    name="type"
                                                >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="tutor">Padre de familia o tutor</MenuItem>
                                                    <MenuItem value="delegado">Delegado escolar</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Box p={2}>
                                                <Typography variant="h5" gutterBottom>
                                                    Documentación (opcional)
                                                </Typography>
                                            </Box>
                                            <Grid container>
                                                <Grid item xs={12} md={4}>
                                                    <Box p={2}>
                                                        <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                        <label for="docIdentificacion" className="kipper_txt-color pointer">
                                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                <img src={ upload } alt=""/>
                                                                {
                                                                    docId ? 
                                                                    <p className="kipper_txt-color">{ docId.name }</p>
                                                                    :
                                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                                }
                                                            </Grid>
                                                        </label>
                                                        <img src="" alt="" className="previw_img d-none" id={'idIMG'} />
                                                        <Typography className="" variant="" display="block" gutterBottom>
                                                            Identificación Oficial
                                                        </Typography>
                                                        { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Box p={2}>
                                                        <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                        <label for="docComprobante" className="kipper_txt-color pointer">
                                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                <img src={ upload } alt=""/>
                                                                {
                                                                    docProofAddres ? 
                                                                    <p className="kipper_txt-color">{ docProofAddres.name }</p>
                                                                    :
                                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                                }
                                                            </Grid>
                                                        </label>
                                                        <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} />
                                                        <Typography className="" variant="" display="block" gutterBottom>
                                                            Comprobante de domicilio
                                                        </Typography>
                                                        { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Box p={2}>
                                                        <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                        <label for="docOpcional" className="kipper_txt-color pointer">
                                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                <img src={ upload } alt=""/>
                                                                {
                                                                    docOpcional ? 
                                                                    <p className="kipper_txt-color">{ docOpcional.name }</p>
                                                                    :
                                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                                }
                                                            </Grid>
                                                        </label>
                                                        <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                                        <Typography className="" variant="" display="block" gutterBottom>
                                                            Documento opcional
                                                        </Typography>
                                                        { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Box px={2} pb={3}>
                                                        <Typography className="" variant="body1" display="block" gutterBottom>
                                                            <b>*Los documentos deben ser de tipo JPG, JPEG o PNG.</b> 
                                                            { error }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    )
}

export default AgregarPadre