import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";

// IMG
import user from './../../../Assets/Img/General/placeholder_boy.png';
//MAterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography, Divider } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import * as firebase from 'firebase';


const HomeworkDeliveredItem = ( {  childcode, homework } ) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [information, setInformation] = useState(null)

    useEffect(() => {
        let db = firebase.database();
        var listtareas = [];
        let ref = db.ref(`childs/${childcode}`);
        ref.once('value').then( snapshot => {
            if(snapshot.val()){
                console.log(snapshot.val())
                setInformation(snapshot.val())
            }
        });
    }, [])

    return(
        <Box p={0}>
             <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={2} className="center-center" >
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <img src={ information?.avatar ?information?.avatar :user } alt="" className="assistenceItem_IMG" />
                    </Grid>
                </Grid>
                <Grid id="name_homework-list" item xs={6} md={3} className="center-center" >
                    <Typography variant="body1" >
                        {`${information?.name} ${information?.last_name}`}
                    </Typography>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={0} md={3} className="center-center" >
                        <Typography variant="body1" >
                            {`${moment(homework?.time, "MMMM Do YYYY, h:mm:ss a").format('DD/MM/YYYY hh:ss A')}`}
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid id="link_homework-list" item xs={4}>
                    <Hidden mdDown>
                        <Typography variant="body1">
                            Material enviado
                        </Typography>
                    </Hidden>
                    <a href={homework?.document} target="_blank" rel="noopener noreferrer">
                        <Typography variant="body1">
                            {matches ?homework?.document_name :homework?.document_name.substring(0, 11) }
                        </Typography>
                    </a>
                </Grid>
            </Grid>
            <Hidden mdUp>
                <Grid container direction="row" justify="center" alignItems="center" py={2}>
                    <Grid item xs={0} md={3} className="center-center" >
                        <Typography variant="body1" >
                            {`${moment(homework?.time, "MMMM Do YYYY, h:mm:ss a").format('DD/MM/YYYY hh:ss A')}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Hidden>
        </Box>
    )
}

export default HomeworkDeliveredItem