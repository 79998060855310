import React, { useState, useContext, useEffect  } from 'react'
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
//Component
import PageHeader from '../../Components/Global/PageHeader';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// FireBases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';

const CalendarDetails = () => {

    const match = useRouteMatch('/tutor/calendario/:id');
    const eventID = match.params.id;
    
    const [ eventInfo, setEventInfo ]   = useState('');
    const [ file, setFile ]             = useState('');
    const [ grade, setGrade ]           = useState('');
    const [ group, setGroup ]           = useState('');
    const [ turn, setTurn ]             = useState('');
    const { currentUser }               = useContext(AuthContext);
    useEffect(() => handleload(),[]);

    const handleload = e => {
        let db = firebase.database();
        let ref = db.ref(`events/${eventID}`);
        ref.once('value').then(function(snapshot) {
            setEventInfo(snapshot.val());
        });
    }

    return(
        <Grid container>
            <Grid xs={8} sm={12} md={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Detalles del evento"
                        text="Tu próximo evento se acerca."
                        btn_back="/tutor/calendario"
                        btn_action=""
                    />
                </Box>
            </Grid>
            <Grid sm={12}>
                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                        <Grid container >
                            <Grid item xs={12}>
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mx={3} mt={3} >
                                    <Typography variant="h5" className="kipper_title">
                                        <b> Motivo </b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Box mx={3}>
                                    <Typography variant="h6" className="kipper_txt-grey">
                                        <b> { eventInfo?.name } </b>
                                    </Typography>
                                </Box>
                                <Box mx={3}>
                                    <Typography variant="h6" className="kipper_txt-grey">
                                        <b> { eventInfo?.date } { eventInfo?.time } </b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mx={3} mt={2} >
                                    <Typography variant="h6" className="kipper_txt-grey">
                                        <b> Detalles de evento </b>
                                    </Typography>
                                </Box>
                                <Box mx={3} >
                                    <Typography variant="body1" className="kipper_txt-grey">
                                        <b> 
                                            { `Turno ${ eventInfo?.details?.turn }, ${ eventInfo?.details?.grade } ${ eventInfo?.details?.gropu } ` } 
                                        </b>
                                        <p>
                                            { eventInfo?.details_event }
                                        </p>
                                    </Typography>
                                </Box>
                                <Box mx={3} >
                                    <Typography variant="body1" className="kipper_txt-grey">
                                        { eventInfo?.document?.document_1 && <img src={ file } alt="" className="event_img"/> }
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CalendarDetails