import React from 'react'

import citas from '../Assets/Img/Home/entrega_new.png'
import chats from '../Assets/Img/Home/lista_espera_new.png'
import materias from '../Assets/Img/General/portada_alumnos-home.png'
import entregar from '../Assets/Img/Home/entrega_new.png'
import lista from '../Assets/Img/Home/lista_espera_new.png'
import alumnos from '../Assets/Img/General/portada_alumnos-home.png'
import sede from '../Assets/Img/General/portada_sede.png'

import citash from '../Assets/Img/Home/citash.png'
import materiash from '../Assets/Img/Home/materiash.png'
import chatsh from '../Assets/Img/Home/chatsh.png'


export const teacher = [
    {
        "box_img": citash,
        "box_title": "Citas",
        "box_text":  "Establece horarios para reunirte con padres de familia",
        "box_list":[
            'Aprueba o niega solicitudes de citas.',
            'Analiza el calendario de citas.',
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/citas",
        }
    },
    {
        "box_img":chatsh,
        "box_title": "Chats",
        "box_text":  "Comunicate directamente con los padres de familia.",
        "box_list":[
            'Chat de mensajes con padres de familia.',
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/teacher/chat",
        }
    },
    {
        "box_img" : materiash,
        "box_title": "Materias",
        "box_text":  "Gestiona las actividades de todas tus asignaturas",
        "box_list":[
            'Modifica deta;;es de la materia.',
            'Agregar y ver detalles de tareas.',
            'Pasar lista de asistencia.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/teacher/materias",
        }
    }
]

export const school = [
    {
        "box_img": entregar,
        "box_title": "Eventos",
        "box_text":  "Mantén a los padres de familia actualizados",
        "box_list":[
            'Agregar comunicados',
            'Agregar noticias".',
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/eventos",
        }
    },
    {
        "box_img": lista,
        "box_title": "Maestros",
        "box_text":  "Gestiona el personal educativo del plantel",
        "box_list":[
            'Agregar maestros',
            'Editar o actualizar perfiles de maestros',
            'Agregar permisos'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/maestros",
        }
    },
    {
        "box_img": alumnos,
        "box_title": "Alumnos",
        "box_text":  "Agrega nuevos alumnos a la plataforma añadiendo a uno de sus papás y sus contactos de referencia.",
        "box_list":[
            'Agregar padres de familia.',
            'Agregar contactos de referencia.',
            'Ver lista completa de alumnos.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/alumnos",
        }
    },
    {
        "box_img": sede,
        "box_title": "Sede",
        "box_text":  "Ingresa o actualiza la información correspondiente a la escuela.",
        "box_list":[
            'Agrega o modifica el logo y nombre de la escuela.',
            'Agrega o modifica la dirección de la escuela.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/sede",
        }
    }
]
