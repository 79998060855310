import React, { useState, useContext, useEffect  } from 'react'
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../Components/functions/Auth";
//Component
import PageHeader from '../Components/Global/PageHeader';
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// FireBases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';

const CalendarioDetalles = (  ) => {

    const match = useRouteMatch('/eventos/detalles/:id');
    const eventID = match.params.id;
    
    const { currentUser }               = useContext(AuthContext);
    const [isedit, setIsedit] = useState('');
    const [ eventInfo, setEventInfo ]   = useState('');
    const [ file, setFile ]             = useState('');
    const [ grade, setGrade ]           = useState('');
    const [ group, setGroup ]           = useState('');
    const [ turn, setTurn ]             = useState('');

    useEffect(() => handleload(),[]);

    const handleload = e => {
        let db = firebase.database();
        let ref = db.ref(`events/${eventID}`);
        ref.once('value').then(function(snapshot) {
            setEventInfo(snapshot.val());
            setTurn( snapshot.val().details.turn )
            setGrade( snapshot.val().details.grage )
            setGroup( snapshot.val().details.group )
            setFile( snapshot.val().document.document_1 )
        });
    }

    return(
        <Grid container>
            <Grid xs={8} sm={12} md={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Detalles del evento"
                        text="Tu próximo evento se acerca."
                        btn_back="/eventos"
                        btn_action=""
                    />
                </Box>
            </Grid>
            <Grid sm={12}>
                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                        <Box >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Box>
                        <Box p={3}>
                            <Typography variant="h4" className="kipper_title">
                                <b> { eventInfo.name } </b>
                            </Typography>
                            <span className="kipper_txt-grey">
                                { eventInfo.date } { eventInfo.time }
                            </span><br/>
                            <span className="kipper_txt-grey">
                                { eventInfo.receiver_event === 'all' && ' Evento global' }
                                { eventInfo.receiver_event === 'teachers' && ' Evento para Maestros' }
                                { eventInfo.receiver_event === 'tutor' && ' Evento para Padres de familia' }
                                { eventInfo.receiver_event === 'child' && ' Evento para Alumnos' }
                            </span><br/>
                            <span className="kipper_txt-grey">
                                { turn && `Turno ${ turn }` }
                                { grade && grade }
                                { group && group }
                            </span><br/>
                            <Typography variant="h5" className="kipper_txt-grey">
                                <b> Detalles del evento </b>
                            </Typography>
                            <p className="kipper_txt-grey">
                                { eventInfo.details_event }
                                { 
                                    file && 
                                    <img src={ file } alt="" className="event_img"/>
                                }
                            </p>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CalendarioDetalles