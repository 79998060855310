import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';



const BoxHome = ( { box_img, box_title, box_text, box_item, box_btn_text, box_btn_link } ) => {

    return (
        <Box boxShadow={3} borderRadius={16} className="box_media bg-white">
            <Grid container display="flex">
                <Hidden smDown>
                    <Grid item xs={4}>
                        <img id="boxinfo-img-alumnos" src={ box_img } alt="" className="boxinfo-img box_media" />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8} className="boxHome" container direction="column" justify="space-around" alignItems="stretch" >
                    <Box mx={3} >
                        <Typography variant="h5" className="boxHome_Title">{box_title}</Typography>
                        <Typography id="boxHome_Text_Alumnos" className="boxHome_Text"> {box_text} </Typography>    
                    </Box>
                    <Box mx={3} >
                        {
                            box_item &&
                                <ul className="BoxHome_lista">
                                    {box_item.map( item => <li>{item}</li> )}
                                </ul> 
                        }
                    </Box>
                    <Box mx={3} >
                        <Link to={ box_btn_link }> 
                            <Button className="boxHome_button marginb_10px" variant="contained" color="primary" size="large" disableElevation> {box_btn_text} </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
BoxHome.propTypes = {
    box_img:    PropTypes.string.isRequired,
    box_title:  PropTypes.string.isRequired,
    box_text:   PropTypes.string.isRequired,
    box_item:   PropTypes.array,
    box_btn_text: PropTypes.string.isRequired,
    box_btn_link: PropTypes.string.isRequired,
}

export default BoxHome