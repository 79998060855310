import React,{useContext,useState} from 'react';
import { Link } from 'react-router-dom';

/*Material UI */
import {Badge,Menu,withStyles,MenuItem} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';

/* Sources */
import { AuthContext } from "../../../Components/functions/Auth";
import NavHeaderPhoneOptions from './/NavHeaderPhoneOptions';
import logo from './../../../Assets/Img/Layout/Logo_text.png';
import notificationIcon from './../../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';
import { slide as Menu2 } from 'react-burger-menu'

import chat from '../../../../src/Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import logout from '../../../../src/Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import home from '../../../../src/Assets/Img/svg_icons/Icon feather-home.svg';
import settings from '../../../../src/Assets/Img/svg_icons/Icon feather-settings.svg';
import maestros from '../../../../src/Assets/Img/svg_icons/Icon awesome-chalkboard-teacher.svg';
import student from '../../../../src/Assets/Img/svg_icons/Icon feather-users.svg';
import group from '../../../../src/Assets/Img/svg_icons/icon-users.svg';
import materias from '../../../../src/Assets/Img/svg_icons/Icon feather-list.svg';
import evento from '../../../../src/Assets/Img/svg_icons/Icon feather-calendar.svg';


/*¿Que hace NavEscuelaPhone? Proyecta el menu para celular, se activa en dispositivos celulares con toda su funcionalidad para ESCUELA*/
/*NOTA: Para usarlo, se recomienda declararlo arriba del todo*/
const NavEscuelaPhone =()=>{
    const { notif, handlelogout } = useContext(AuthContext);
    const [anchor, setAnchor] = useState(null);
  
    const handleNavTeacher=(event)=>{
        setAnchor(event.currentTarget);
    }

    const handleClose = () => {
        setAnchor(null);
    };

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
        },
      })((props) => (
        <Menu
          elevation={1}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          {...props}
        />
      ));
      
    const StyledMenuItem = withStyles((theme) => ({
        root: {
          '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.common.white,
            },
          },
        },
    }))(MenuItem);

    return(
        <div id="menuResponsive" className="navEscuela_menuContainer">
            <div id="navTeacher_logoKiper" >
                <img src={logo} />
            </div>
            
            
            <div className="navTeacher_iconsContainer">
                <Badge color="error" overlap="circle" badgeContent={''} invisible={notif}>
                    <Link to="/notificaciones">
                        <img src={ notificationIcon } alt="Usuario Kiper" style={{padding:"15%", marginRight:"30%", width:"33px", float:"right", filter: "contrast(1) invert(100%)"}} className="sidebar_iconNav"/>
                    </Link>
                </Badge>

                <Menu2 right disableAutoFocus width={ '60%' }>

                  <Link to='/'>
                    <div className='burgerMenuItem_container'>
                      <img src={home}/>
                      <h1>Inicio</h1>
                    </div>
                  </Link>

                  <Link to='/maestros'>
                    <div className='burgerMenuItem_container'>
                      <img src={maestros}/>
                      <h1>Maestros</h1>
                    </div>                
                  </Link>

                  <Link to='/alumnos'>
                    <div className='burgerMenuItem_container'>
                      <img src={student}/>
                      <h1>Alumnos</h1>
                    </div> 
                  </Link>

                  <Link to='/grupos'>
                    <div className='burgerMenuItem_container'>
                      <img src={group}/>
                      <h1>Grupos</h1>
                    </div> 
                  </Link>

                  <Link to='/escuela/lista/materias'>
                    <div className='burgerMenuItem_container'>
                      <img src={materias}/>
                      <h1>Materias</h1>
                    </div> 
                  </Link>

                  <Link to='/comunicados'>
                    <div className='burgerMenuItem_container'>
                      <img src={evento}/>
                      <h1>Eventos y Comunicados</h1>
                    </div>
                  </Link>
                  
                  <Link to='/escuela/chat'>
                    <div className='burgerMenuItem_container'>
                      <img src={chat}/>
                      <h1>Chat</h1>
                    </div>
                  </Link>

                  <Link to='/sede'>
                    <div className='burgerMenuItem_container'>
                      <img src={settings}/>
                      <h1>Configuraciones</h1>
                    </div>
                  </Link>

                  <Link>
                    <div onClick={handlelogout} className='burgerMenuItem_container'>
                      <img src={logout}/>
                      <h1>Salir</h1>
                    </div>
                  </Link>

                

                </Menu2>



                {/*<DehazeIcon style={{fontSize:"35px", float:"right"}} onClick={handleNavTeacher} className="sidebar_iconNavDehaze" /> 

                <StyledMenu
                    id="customized-menu"
                    anchor={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                > 
                <NavHeaderPhoneOptions StyledMenuItem={StyledMenuItem} handlelogout={handlelogout}/>
                </StyledMenu>*/}
                
            </div>
        </div>
    );
}  

export default NavEscuelaPhone;