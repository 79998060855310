import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

//firebase
import * as firebase from 'firebase';

const ForgotPass = () => {
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState('');
    const [ succesful, setSucces] = useState('');


    const resetPassword = e => {
        setAlert('')
        setSucces('')
        var actionCodeSettings = {
            url: `https://www.kiperapp.com/recuperar_contrasena/?email=${email}`,
            iOS: {
              bundleId: 'com.example.ios'
            },
            android: {
              packageName: 'com.example.android',
              installApp: true,
              minimumVersion: '12'
            },
            handleCodeInApp: true
          };
        e.preventDefault();
        try{
            firebase.auth().sendPasswordResetEmail(email)
            .then(function() {
                setEmail('')
                setSucces('Un correo sera enviado al email')
              })
              .catch(function(error) {
                  setAlert(error.message)
              });
        }catch(error){
            console.log(error.message)
        }
    }

    return(
        <form onSubmit={e => resetPassword(e)} >
            <Box>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box py={3}>
                        <Typography className="kipper_title" variant="h5"> No te preocupes, restablece tu contraseña </Typography>
                        <Typography className="" > 
                            Escribe el correo electrónico al que está vinculada tu cuenta y enviaremos una nueva contraseña.
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box py={1}>
                        <TextField variant="outlined" placeholder="Correo eletronico" error={alert ?true :null} fullWidth value={email} onChange={ e => setEmail(e.target.value)} />
                        {
                            alert
                                ?<Alert severity="error">{alert}</Alert>
                                :null
                        }
                        {
                            succesful
                                ?<Alert severity="success" action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setSucces(''); }} > <CloseIcon fontSize="inherit" /> </IconButton> }>{succesful}</Alert>
                                :null
                        }
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box py={1}>
                        <Button className="kipper_button FullWidthButton" variant="contained" type="submit" fullWidth>Restablecer contraseña</Button>
                    </Box>
                </Grid>
            </Box>
        </form>
    );    
}

export default ForgotPass