import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory } from 'react-router-dom';
//Component
import PageHeader from '../Components/Global/PageHeader';
import EventoItem from '../Components/Global/EventoItem';
import ModalShow from '../Components/Global/Modal';
import Loader from '../Components/Global/Loader';
// meterialui
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';

function Eventos () {

    const history   = useHistory();

    const { currentUser, userType, infoSchool } = useContext(AuthContext);
    const [ Error, setError ]           = useState( false );
    const [ loader, setLoader ]         = useState( false );
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ eventID, setEventID ]       = useState('');
    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    }; 
    
    function refreshPage() {
        window.location.reload(false);
    }

    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        ModalOpen();
        setIsDeleted( true );
        setEventID( id );
    }

    const deleteEvent = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let EventRef = db.ref(`events/${eventID}`);
            EventRef.update(
                { status: "inactive", }, 
                (error) => {
                    if (error) { 
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                        ModalOpen();
                    } 
                    else {
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Evento eliminado." )
                        ModalOpen();
                        refreshPage();
                    }
                }
            );
        }
        else{
            ModalClose();
        }
    }

    const GetEvents = () => {
        const [ list, setList ] = useState([]);
        useEffect(() => handleload(),[]);
        const handleload = e =>{
            let db = firebase.database();
            let ref = db.ref(`comunicados`);
            let listItem = [];
            ref.once('value').then(function(snapshot) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if(userType == "school"){
                        if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                            listItem.push( 
                                <Grid item sm={6} key={ item.key }>
                                    <Box m={3}>
                                        <EventoItem  
                                            key={ item.key }
                                            id={ item.key }
                                            title={ `${itemInfo.name}` } 
                                            body={ `${itemInfo.details_event}` } 
                                            date={ `${itemInfo.date}` } 
                                            btn_to={ `/eventos/detalles/${item.key}` }
                                            deleteEvent={ isElementDeleted }
                                        /> 
                                    </Box>
                                </Grid>
                            )
                        }
                    }else if(userType == "teacher"){
                        if ( itemInfo.status === "active" && itemInfo.school === infoSchool.school ) {
                            listItem.push( 
                                <Grid item sm={6} key={ item.key }>
                                    <Box m={3}>
                                        <EventoItem  
                                            key={ item.key }
                                            id={ item.key }
                                            title={ `${itemInfo.name}` } 
                                            body={ `${itemInfo.details_event}` } 
                                            date={ `${itemInfo.date}` } 
                                            btn_to={ `/eventos/detalles/${item.key}` }
                                            deleteEvent={ isElementDeleted }
                                        /> 
                                    </Box>
                                </Grid>
                            )
                        }
                    }

                })
                setList( listItem );
                setLoader( true );
            });
        }
        return ( list );
    }

    return(
        <Grid container>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted &&
                            <ModalShow 
                                title=" ¿Estás seguro de eliminar el evento? "
                                confirmDelete={ true }
                                Delete={ deleteEvent }
                            /> 
                    }
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={8} sm={12} md={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Calendario de eventos"
                        text="Administra tus eventos y agrega nuevas entradas al calendario."
                        btn_back="/home"
                        btn_to="/eventos/agregar"
                        btn_to_text="Agregar evento"
                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Grid container>
                    {
                        loader === false ? 
                            <Loader onlyLoad={true} /> : null
                    }
                    { GetEvents() }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Eventos