import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core'
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../functions/Auth';

const AssistanceChildItem = ( { TareaID, MateriaID, title, details, date } ) => {
    
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const [homeworkStatus, setHomeworkStatus] = useState('');
    
    useEffect(() => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${MateriaID}/homework/${TareaID}/deliveries`);
        groupRef.once('value').then(  (snapshot) => {
            if ( snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( item.key == currentUser ) {
                        setHomeworkStatus( itemInfo.status )
                    }
                })    
            }
        })
    }, []);
    
    return (
        <Box mx={2} my={1} mt={1}>
            <Link to={`/alumno/tarea/detalles/${MateriaID}/${TareaID}`} >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid xs={5} md={5} className="assitM-w"> 
                            <Box mt={2} mb={2}>
                                <Typography variant="body1" className="txtDescTitle" >
                                    { title }
                                </Typography>
                                <Typography variant="body2" className="txtDescTitle" >
                                    { details } <br />
                                    <small> { date }  </small>
                                </Typography>
                            </Box>
                        </Grid>
                        
                        <Grid xs={5} md={5} className="assist-w">
                            <Box mt={2} mb={2}>
                                <Typography>
                                    { homeworkStatus == 0 && <span className="homework-pending disBl_w"> Pendiente </span> }
                                    { homeworkStatus == 3 && <span className="homework-pending disBl_w"> Pendiente </span> }
                                    { homeworkStatus == 1 && <span className="assistance-true disBl_w"> Entregado </span> }
                                    { homeworkStatus == 2 && <span className="assistance-true disBl_w"> Entregado </span> }
                                    { homeworkStatus == 4 && <span className="assistance-true disBl_w"> Entregado </span> }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <hr/>
            </Link>
        </Box>
    )
}

export default AssistanceChildItem
