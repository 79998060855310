import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
import logoSchool from './../../Assets/Img/General/Academy.png';
import icon_location from './../../Assets/Img/Home/Icon_Location.png';
import icon_phone from './../../Assets/Img/Home/Icon_phone.png';
//MaterialUI

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavChildPhone from './NavChildPhone/NavChildPhone';

function Information () {
    const history = useHistory();
    const { currentUser, info }   = useContext(AuthContext);
    const [schoolInfo, setSchoolInfo] = useState('');
    const [childInfo, setChildInfo] = useState('');
    const [tutorInfo, setTutorInfo] = useState('');

    useEffect( () =>{
        let db = firebase.database();
        console.log(info)
        let ref = db.ref(`childs/${currentUser}`);
        ref.once('value').then( snapshot => {
            setChildInfo(snapshot.val())
            if (  snapshot.val().school ) {
                setTutorInfo(snapshot.val())
                let ref = db.ref(`schools/${snapshot.val().school}`);
                ref.once('value').then( snapshotSchool => {
                setSchoolInfo( snapshotSchool.val() );      
                })
            }
            else {
                setSchoolInfo( false );   
            }
        });
    }, [ ] );
    
    return(
        <Grid container className="padres_grid">
            <NavChildPhone/>
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Acerca de la escuela"
                        text="Información de la escuela" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item  xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid item xs={12} md={2} style={{display:'flex', justifyContent:'center'}}>
                            <Box p={2}  >
                                <img src={ schoolInfo?.avatar ?schoolInfo?.avatar :logoSchool } style={{ width:'150px' }} alt="Logo" />
                            </Box>
                        </Grid>
                        <div className="informationComponentContainer">
                            
                            
                            <h4 className="information_schoolTitle"> { schoolInfo?.name } </h4>
                            
                            <div className="information_locationDataContainer">
                                <img src={ icon_location } id="img_informationIcons" alt=""/>&nbsp;&nbsp;
                                <p>{ schoolInfo?.location?.address }</p>
                            </div>
                            <div className="information_locationDataContainer">
                                <img src={ icon_phone } id="img_informationIcons" alt=""/>&nbsp;&nbsp;
                                <p>{ schoolInfo?.contact?.phone }</p>
                            </div>
                            
                        </div>
                        <Grid item xs={12} md={5} py={4} >
                            <Box>
                                <Typography variant="h6" className="kipper_txt-grey" >
                                    <b>Misión </b> 
                                </Typography>
                                <Typography className="information_misionVisionParraf" >
                                    { schoolInfo?.details?.mision }    
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6" className="kipper_txt-grey">
                                    <b> Visión</b> 
                                </Typography>
                                <Typography  className="information_misionVisionParraf">                            
                                    { schoolInfo?.details?.vision }    
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="stretch" >
                            <Grid item xd={6} md={6}>
                                <Box p={3}>
                                    <Typography variant="h5" className="kipper_txt-grey"><b>Datos personales</b></Typography>
                                    <Box p={3}>
                                        <Typography variant="h6" className="kipper_txt-grey" >
                                            <b>Nombre </b> 
                                        </Typography>
                                        <Typography className="information_misionVisionParraf" >
                                            { childInfo?.name } {childInfo?.last_name}  
                                        </Typography>
                                        <Typography variant="h6" className="kipper_txt-grey" >
                                            <b>Grupo </b> 
                                        </Typography>
                                        <Typography className="information_misionVisionParraf" >
                                        <b>{  childInfo?.child_type }</b> - {  childInfo?.details?.grade } "{ childInfo?.details?.group }" {  childInfo?.details?.turn } 
                                        </Typography>
                                        <Typography variant="h6" className="kipper_txt-grey" >
                                        <b>Dirección </b>
                                        </Typography>
                                        <Typography className="information_misionVisionParraf" >
                                            {  childInfo?.location?.address },  {  childInfo?.location?.zip_code } <br/> { childInfo?.location?.city }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xd={6} md={6}>
                                <Box p={3}>
                                    <Typography variant="h5" className="kipper_txt-grey"><b>Contacto</b></Typography>
                                    <Box p={3}>
                                    <Typography variant="h6" className="kipper_txt-grey" >
                                        <b>Email </b> 
                                    </Typography>
                                    <Typography className="information_misionVisionParraf" >
                                        {localStorage.getItem('email')}  
                                    </Typography>
                                </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}


export default Information

