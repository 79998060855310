import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../Components/Global/PageHeader'
// Materia UI
import PersonIcon from '@material-ui/icons/Person';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { Box, Grid, Typography } from '@material-ui/core'
import HomeworkItem from '../../Components/childs/HomeworkItem';
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../../Components/functions/Auth';
import AssistanceChildItem from '../../Components/childs/AssistanceChildItem';
import TutorHomeworkItem from '../../Components/tutors/TutorHomeworkItem';
import AssistenceChildForTutor from '../../Components/tutors/AssistenceChildForTutor';

const HomeworkChild = () => {

    const history = useHistory();
    const match = useRouteMatch('/tutor/alumno/tareas/:grupoID/:materiaID/:studentID');
    const GrupoID = match.params.grupoID;
    const StudentID = match.params.studentID;
    const MateriaID = match.params.materiaID;
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    const [details, setDetails] = useState(1)
    const [MateriaInfo, setMateriaInfo] = useState('');
    const [grupoInfo, setGrupoInfo]     = useState('');
    const [homework, setHomework]       = useState([]);
    const [assistance, setAssistance]   = useState([]);
    const [ evaluaciones, setEval ] =useState([]);
    const [ promedio, setPromedio ] = useState('');

    useEffect(() => {
        if(infoSchool){

            let db = firebase.database();
            let groupRef = db.ref(`schools/${infoSchool.school}/groups/${GrupoID}/`);
            groupRef.once('value').then(  (snapshot) => {
                setGrupoInfo( snapshot.val() )
            })
        }
    }, [infoSchool]);

    useEffect(() => {
        if(infoSchool){
            getInfo()
        }
    }, [infoSchool])

    const getInfo = () => {
        let listHomework = [];
        let listAssistance = [];
        let db = firebase.database();
        let groupRef =  db.ref(`schools/${infoSchool.school}/groups/${GrupoID}/school_subjects/${MateriaID}/`);
        groupRef.once('value').then(  (snapshot) => {

            let item = snapshot.val()
            let homeworks = item?.homework
            let assistances = item?.assistance
            let evaluations = item?.evaluations

            setMateriaInfo( snapshot.val() )

            if(homeworks){
                Object.entries(homeworks).forEach(element => {
                    listHomework.push( element[1] )
                });
                setHomework( listHomework )
            }
            if(assistances){
                Object.entries(assistances).forEach(element => {
                    let itemInfo = element[1]
                    listAssistance.push({key: element[0], itemInfo})
                })
                setAssistance( listAssistance )
            }
            if(evaluations && evaluations[StudentID]){
                Object.entries(evaluations).forEach(element =>{
                    let evaluationsArray = Object.entries(element[1])
                    setEval(evaluationsArray)
                    evalFormat(evaluationsArray)
                })
            }

        })
    }

    const evalFormat = (arrayEval) =>{
        var count = 0;
        var resultado = 0;

        arrayEval.forEach(element =>{
            if(element[1]){
                count++
            }
            resultado += element[1]
        })
        if(count){
            setPromedio((resultado/count).toFixed(1));
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} >
                <Box pb={3}>
                    <PageHeader 
                        title={`${MateriaInfo?.title}`}
                        text="Detalles de la materia"
                        btn_back="/"  
                    />
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box my={3} boxShadow={3} >
                    <Grid container >
                        <Grid item xs={12} className="bg-primary" >
                            <Box px={5} py={2}   borderRadius={10} >
                                <Typography variant="h5" className="text-white" >
                                    Evaluacion
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="bg-white text-content" >
                            <Box px={5} py={3} >
                                <Grid container >
                                    <Grid item xs={12} className="" >
                                        <Box display="flex" mb={2} >
                                            <Typography variant="subtitle1" className="" >
                                                <b> Parciales: </b> 
                                                {
                                                    evaluaciones
                                                    ? evaluaciones.map(i => <span className="bubble-primary" >&nbsp;{i[1]}&nbsp;</span> )
                                                    :<span >&nbsp;No hay evaluaciones</span>
                                                }
                                            </Typography>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Typography variant="subtitle1" className="" >
                                                <b> Promedio: </b>
                                                {promedio
                                                    ?<span className="bubble-secondary" >{promedio}</span>
                                                    :<span className="bubble-secondary" >&nbsp;0</span>
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className="" >
                                        <Box mb={1} >
                                            <Typography variant="h5" className=""  >
                                                Acerca de la materia
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1} >
                                                { MateriaInfo?.details }
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1}  >
                                                <PersonIcon className="mar_r_2" />
                                                { MateriaInfo?.teacher_name ? MateriaInfo?.teacher_name  : grupoInfo?.teacher?.teacher_name }
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1}  >
                                                <WatchLaterIcon className="mar_r_2" />
                                                { 
                                                    MateriaInfo?.days ? 
                                                        <>
                                                            { MateriaInfo?.days?.monday && 'Lunes ' }
                                                            { MateriaInfo?.days?.tuersday && 'Martes '}
                                                            { MateriaInfo?.days?.wensday && 'Miercoles ' }
                                                            { MateriaInfo?.days?.tursday && 'Jueves ' }
                                                            { MateriaInfo?.days?.friday && 'Viernes ' }
                                                            { MateriaInfo?.days?.saturday && 'Sabado ' }
                                                            de { MateriaInfo?.schedule[0] } a { MateriaInfo?.schedule[0] }
                                                            
                                                        </>
                                                        : 'Sin definir'
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Box 
                                            className="pointer" 
                                            onClick={ () => setDetails(1) } 
                                        >
                                            <Typography variant="h5" align="center" className={ details == 1 ? "kipper_txt-color h5_responsive" : "text-placeholder h5_responsive"  } >
                                                Tareas
                                            </Typography>
                                            <hr className={ details == 1 ? "divider_materia-active" : "divider_materia"  } />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Box 
                                            className="pointer" 
                                            onClick={ () => setDetails(2) } 
                                        >
                                            <Typography variant="h5"  align="center" className={ details == 2 ? "kipper_txt-color h5_responsive" : "text-placeholder h5_responsive"  } >
                                                Asistencias
                                            </Typography>
                                            <hr className={ details == 2 ? "divider_materia-active" : "divider_materia"  } />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        { details == 1 
                                            ? (homework.length > 0) 
                                                ? homework.map( homework => 
                                                    <TutorHomeworkItem 
                                                        MateriaID={ MateriaID }
                                                        TareaID={ homework.id }
                                                        SchoolID={ infoSchool.school }
                                                        GroupID={ GrupoID }
                                                        title={ homework.title }
                                                        details={ homework.description }
                                                        date={ homework.fulltime }
                                                    />
                                                )
                                                : <Typography variant="h6" className="kipper_title" > Sin tareas por el momento </Typography>
                                            : (homework.length > 0) 
                                                ? assistance.map( assistance => 
                                                    <AssistenceChildForTutor 
                                                        assistanceId={ assistance.key }
                                                        materiaID={ MateriaID }
                                                        SchoolID={ infoSchool.school }
                                                        GroupID={ GrupoID }
                                                        StudentID={ StudentID }
                                                        date={ assistance.itemInfo.time }
                                                    />
                                                )
                                                : <Typography variant="h6" className="kipper_title" > Sin Asistencias por el momento </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeworkChild
