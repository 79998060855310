import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom';

// img
import avatarboy from './../../Assets/Img/General/placeholder_boy.png'
import avatargirl from './../../Assets/Img/General/placeholder_girl.png'

// materialUI
import { Card, CardContent, Grid, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles }  from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) =>({
    btn : {
        color:'#F8B236',
        border:'2px solid #F8B236',
        borderRadius : '50%',
        padding:'3px'
    },
    toRight : {
        display:'flex',
        justifyContent:'flex-end'
    },
    toBold :{
        fontWeight:'bold'
    },
    divi : {
        width:'100%',
        listStyle:'none',
        marginLeft:0
    },
    avt :{
        width:'75px',
        height:'75px'
    }
}))

function HomeworkHome({childs}) {
    const [Gender, setGender] = useState("")

    const classes = useStyles();
    useEffect(() => {
        // console.log(childs)
        if(childs.sex == "Femenino"){
            setGender(avatargirl)
        }else if(childs.sex == "Masculino"){
            setGender(avatarboy)
        }
    }, [childs])

    return (
        <Box m={1} p={1} >
            <Card variant="outlined" >
                <CardContent gutterBottom>
                    <Grid container>
                        <Grid item md={3}>
                            <Box>
                                <img src={ childs.avatar?childs.avatar : Gender } className={ classes.avt } alt="Avatar"/>
                            </Box>
                        </Grid>
                        <Grid container md={9}>
                            <Grid item md={10}>
                                <Box>
                                    <Typography variant="subtitle1" className={classes.toBold}>
                                        {
                                            childs? childs.name
                                            :"name"
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={2} className={classes.toRight}>
                                <Box>
                                    <Typography varian="h5">
                                        <Link to={ `/tutor/alumnos/${childs.id}` } >
                                            <ArrowForwardIosIcon className={classes.btn}/>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid md={12}>
                                <Box p={2}>
                                    <Divider className={classes.divi} variant="inset" component="li" />
                                </Box>
                            </Grid>
                            <Grid item md={7}>
                                <Box>
                                    <Typography variant="subtitle2">
                                        {
                                            childs? childs.details.grade +' '+ childs.details.group
                                            :"Location"
                                        }                                    
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {
                                            childs? childs.details.turn
                                            :"Location"
                                        }                                    
                                    </Typography>
                                    {/* <Typography variant="overline">
                                        PRÓXIMA TAREA
                                    </Typography> */}
                                </Box>
                            </Grid>
                            {/* <Grid item md={5} className={classes.toRight}>
                                <Box>
                                    <Typography variant="overline" gutterBottom>
                                        {
                                            childs? childs.location.address
                                            :"Location"
                                        }                                     </Typography>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default HomeworkHome
