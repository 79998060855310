import React, { Component, useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../Components/functions/Auth";

import * as firebase from 'firebase';

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { ChildHomeItem } from '../../Components/childs/ChildHomeItem';
import SchoolInformation from '../../Components/Global/SchoolInformation';
import CalendarChild from  '../../Components/Global/Calendar'
import NavChildPhone from './NavChildPhone/NavChildPhone';

const HomeChild  = () => {

  const { info, infoSchool,events } = useContext(AuthContext);
  const [Subjects, setSubjects] = useState([])
  const [schoolInfo, setSchoolInfo] = useState('')

  useEffect(() => {
    let db = firebase.database();
    var Schoolref = db.ref(`schools/${info?.school}`);
    Schoolref.once('value').then( snapshot => {
      if ( snapshot.val() ) {
        setSchoolInfo( snapshot.val() )
      }
      else{
        setSchoolInfo( false )
      }
    })
    hadleload()
  }, [info]);

  async function  hadleload(){

    let db = firebase.database();
    var Schoolref = db.ref(`schools/${info?.school}/groups/${info?.group}/school_subjects`);
    
    let MateriaList = [];
    
    var infoRef = await Schoolref.once('value')
    
    if(infoRef.val()){
      var iterable_infoRef = Object.entries(infoRef.val())
      for (const ref of iterable_infoRef){
        let subjectRef = await db.ref(`school_subjects/${ref[0]}`).once('value');
        var subjectInfo = subjectRef.val();
        if(subjectInfo)
        {
          MateriaList.push(
            <ChildHomeItem                
            key={ ref[0] }
            title={ subjectInfo?.title }
            details={ subjectInfo?.details }
            link={`/alumno/materia/details/${ref[0]}`}
            />
            )   
        }
      }
      setSubjects(MateriaList )

    }else{
      setSubjects(false)
    }
  }

  return (
    <Box>
      <NavChildPhone/> 
      <Box display="flex" justifyContent="center" mb={5} className="SchoolInformation__main">  
         
          <SchoolInformation 
            isTutor={ false }
            user={ info?.avatar }
            nameuser={ `${info?.name} ${info?.last_name}` }
            logo={ schoolInfo?.avatar } 
            name={ schoolInfo?.name } 
            address={ schoolInfo?.location?.address } 
            phone={ schoolInfo?.contact?.phone } 
          />
      </Box>
      <Box className="bg-white" p={3} m={1} boxShadow={3} borderRadius={10} id="calendarChild">
        <CalendarChild events={events} type="child" />
      </Box>
      <Grid container spacing={6} >
        { Subjects }
      </Grid>
    </Box>
  )
}

export default HomeChild;
