// import  './../Assets/Styles/backgroundFixed.css';
import React, { useState, useContext, useEffect} from 'react';
import { key_Geocode } from './../Constants/keys';
import Geocode from 'react-geocode';
import '../Assets/Styles/Responsive.css';
//Components
import PageHeader from './../Components/Global/PageHeader'
import ModalShow from '../Components/Global/Modal';
//materialUI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

//img
import upload from './../Assets/Img/General/Icon_upload.png';
//storage
import { AuthContext } from "./../Components/functions/Auth";
import * as firebase from 'firebase';
import { checkFile, checkFilepdf } from '../Components/functions/helpapers';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';
//Components Override
import TextField from '../Constants/textfield';

const headerTitle   = 'Información de la escuela';
const headerText    = 'Ingresa o modifica información referente a la sede de la escuela';

const Sede = () => {

    const { currentUser, info } = useContext(AuthContext);
    const [uid, setUid] = useState(currentUser.uid);

    const [form, setForm] = useState({
        name:"",
        phone:"",
        address:"",
        city:"",
        zipcode:"",
        country:"",
        motto1:"",
        motto2:"",        
    })

    const [back, setBack] = useState({
        name:"",
        phone:"",
        address:"",
        city:"",
        zipcode:"",
        country:"",
        motto1:"",
        motto2:"",        
    })

    const [logofile, setLogofile] = useState('');
    const [previwIMG, setPreviwIMG] = useState('');
    const [email, setEmail] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [Info, setInfo] = useState([]);
    const [ modalLink, setModalLink ]   = useState("");
    const [Reglamento, setReglamento] = useState("");
    const [Privacidad, setPrivacidad] = useState("");
    const [docOpcional, setdocOpcional] = useState("");
    const [docChange1, setdocChange1] = useState(false);
    const [docChange2, setdocChange2] = useState(false);
    const [docChange3, setdocChange3] = useState(false);
    const [docChange4, setdocChange4] = useState(false);
    const [reglamentoDisplay, setReglamentoDisplay] = useState("");
    const [privacidadDisplay, setPrivacidadDisplay] = useState("");
    const [opcionalDisplay, setOpcionalDisplay] = useState("");
    const [loading1, setLoading1] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)

    const [errorMsg, seterrorMsg] = useState({
        file: false,
        doc1: false,
        doc2: false,
        doc3: false,
    });

    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ isShow, setIsShow ]         = useState( false );
    const regExps ={
        telefono: /^[0-9.-]{10,15}$/,
        codigoPostal: /^[0-9]{5,7}$/, 
    }

    const ModalOpen = () =>  setModalStaus(true);
    const ModalClose = () => setModalStaus(false);

   
    const GetLocation = ( address ) => {
        Geocode.setApiKey( key_Geocode );
        Geocode.setLanguage("en");
        Geocode.setRegion("es");
        Geocode.enableDebug();
        Geocode.fromAddress( address ).then(
            response => {
                const position = response.results[0].geometry.location;
                setLat( position.lat );
                setLng( position.lng );
            },
            error => {
                console.error(error);
            }
        );
    }

    useEffect(() => {
        handleload()
    }, [])

    const previwImg = async ( e ) =>{
        try {
            if(e.target.files[0]){
                setPreviwIMG( URL.createObjectURL( e.target.files[0] ))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const uploadLogo = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setLogofile(img);
                setdocChange1(true);
                seterrorMsg({...errorMsg, file: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("LogoIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, file: 'Formatos válidos: png, jpg y jpeg' });
        }  
    }

    const handleload = e => {
        let db = firebase.database();
        let Schoolref = db.ref(`schools/${currentUser}`);
        Schoolref.once('value').then(function(snapshot) {
            if(snapshot.val()){
                setForm({
                    name: snapshot.val()?.name,
                    phone: snapshot.val()?.contact?.phone,
                    address: snapshot.val()?.location?.address,
                    city: snapshot.val()?.location?.city,
                    zipcode: snapshot.val()?.location?.zip_code,
                    country: snapshot.val()?.location?.country,
                    motto1: snapshot.val()?.details?.mision,
                    motto2: snapshot.val()?.details?.vision
                })
                setInfo(snapshot.val());
                setEmail(snapshot.val()?.contact?.email);
                setLat(snapshot.val()?.l?.lat);
                setLng(snapshot.val()?.l?.lng);
                setLogofile(snapshot.val()?.avatar)
                setReglamento( snapshot.val()?.documents?.document_1 )
                setPrivacidad( snapshot.val()?.documents?.document_2 )
                setdocOpcional( snapshot.val()?.documents?.document_4 )
            }
        });
    }

    const handlesubmit = (val) =>{
        console.log(val)
        if(val.name){
            console.log(val.name)
        }

        const LogoIMG = docChange1 ?document.getElementById('LogoIMG').src :logofile;
        const doc_1 = docChange2 ?document.querySelector('#reglamentoDOC').src :Reglamento;
        const doc_2 = docChange3 ?document.getElementById('privacidadDOC').src :Privacidad;
        const doc_4 = docChange4 ?document.getElementById('opcionalDOC').src :docOpcional;        

    
        let db = firebase.database();
        let Schoolref = db.ref(`schools/${currentUser}`);
        if ( val.address ) {
            GetLocation( val.address );
        }
        Schoolref.update({
            avatar: LogoIMG,
            name: val.name ? val.name : form.name,
            contact: {
                email: email,
                phone: val.phone ? val.phone : form.phone
            },
            details: {
                mision: val.motto1 ? val.motto1 : form.motto1,
                vision: val.motto2 ? val.motto1 : form.motto2,
            },
            l:{
                lat: lat,
                lng: lng
            },
            location:{
                address: val.address ? val.address : form.address,
                city: val.city ? val.city : form.city,
                country: val.country ? val.country : form.country,
                zip_code: val.zipcode ? val.zipcode : form.zipcode,
            },
            documents:{
                document_1: doc_1 || "",
                document_2: doc_2 || "",
                document_4: doc_4 || "",
            }
        }, function(error) {
            if (error) {// The write failed...
                console.log(error)
            } 
            else {
                setReglamento("")
                setPrivacidad("")
                setdocOpcional("")
                setIsShow(true);
                setdocChange2(false);
                setdocChange3(false);
                setdocChange4(false);
                ModalOpen();
            }
            handleload(val);
        });
    }

    const uploadDoc1 = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading1(false)
                setdocChange2( true )
                // setReglamento( img );
                seterrorMsg({...errorMsg, doc1: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg = document.getElementById("reglamentoDOC")
                        const docURL = document.getElementById("reglamentoViewDoc")
                        profileImg.src = url;
                        setReglamento( profileImg.src );
                        setLoading1(true)
                    })
                })
                setReglamentoDisplay(img.name)
            } catch (error) {
                console.log( error );
                setLoading1(true)
            }
        } else {
            return seterrorMsg({...errorMsg, doc1: 'Formatos válidos: png, jpg, jpeg o pdf' });
        }  
    }

    const uploadDoc2 = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading2(false)
                setdocChange3( true )
                // setPrivacidad( img );
                seterrorMsg({...errorMsg, doc2: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("privacidadDOC")
                        const docURL = document.getElementById("privacidadViewDoc")
                        profileImg.src = url;
                        setPrivacidad( url );
                        setLoading2(true)
                     })
                })
                setPrivacidadDisplay(img.name)
            } catch (error) {
                console.log( error );
                setLoading2(true)
            }  
        } else {
            return seterrorMsg({...errorMsg, doc2: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 
    }
    
    const uploadDoc3 = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading3(false)
                setdocChange4( true )
                // setdocOpcional( img );
                seterrorMsg({...errorMsg, doc3: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalDOC")
                        const docURL = document.getElementById("opcionalViewDoc")
                        profileImg.src = url;
                        setdocOpcional( profileImg.src );
                        setLoading3(true)
                    })
                })
                setOpcionalDisplay(img.name)
            } catch (error) {
                console.log( error );
                setLoading3(true)
            }
        } else {
            return seterrorMsg({...errorMsg, doc3: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 
    }

    const validateForm = yup.object({
        name: yup
            .string()
            .max(60, "Se admite un máximo de 60 caracteres")
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        address: yup
            .string()
            .max(150, "Se admite un máximo de 150 caracteres"),
        phone: yup
            .string()
            .matches(/^[1-9][0-9]{9,14}$/, 'El teléfono debe contener de 10 a 15 dígitos y debe ser númerico'),
        zipcode: yup
            .string()
            .matches(/^(?:\d{5})?$/, 'El CP debe contener 5 dígitos y ser númerico'),
        city: yup
            .string()
            .max(20, "Se admite un máximo de 20 caracteres")
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        country: yup
            .string()
            .max(20, "Se admite un máximo de 20 caracteres")
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        motto1: yup
            .string()
            .min(5, "Ingrese una misión de minimo 5 caracteres")
            .max(200, "Se admite un máximo de 200 caracteres")
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        motto2: yup
            .string()
            .min(5, "Ingrese una visión de minimo 5 caracteres")
            .max(200, "Se admite un máximo de 200 caracteres")
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras')
    });

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title='Información actualizada'
                        onlyShow={ true }
                        btnText="Continuar"
                        showGoTo={ ModalClose }
                        
                    />
                </Grid>
            </Modal>
            <Grid container>
                {/* <NavEscuelaPhone /> */}
                <Formik
                initialValues={{
                    ...form
                }}
                validationSchema={validateForm}
                onSubmit={(values, {resetForm}) =>{
                    handlesubmit(values)
                    resetForm()
                }}
                >
                    <Form>
                    {/* <form action="" method="post" onSubmit={e => handlesubmit(e)}> */}
                        <Grid xs={12}>
                            <PageHeader
                                title={ headerTitle }
                                text={ headerText }
                                btn_back="/home"
                                btn_action={ true }
                                btn_action_text="Actualizar"
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Box mt={5} className="box_w">
                                <Paper elevation={3} mt={3} >
                                    <Grid container>
                                        <Grid xs={12} md={4}>
                                            <Box p={3}>
                                                <Typography variant="h5" className="schoolInfo_title"> Dirección y datos de la escuela </Typography>
                                                <Typography className=""> Agrega los datos oficiales de la escuela (esta información aparecerá en la ventana de inicio de la aplicación) </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={8} >
                                            <Box p={6}>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                        <img alt="" className="previw_img rounded d-none" id={'LogoIMG'} />
                                                        <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { uploadLogo( e ); previwImg(e) } }  />
                                                        <label htmlFor="uploadFile" className="kipper_txt-color pointer">
                                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                    { logofile 
                                                                        ? docChange1 
                                                                            ? <img src={ previwIMG } alt="base" className="previw_img" id="LogoEsc" />
                                                                            : <img src={ logofile } alt="previe" className="previw_img" />
                                                                        : <img src={ upload } alt=""/>
                                                                    }
                                                                    <Typography className="kipper_txt-color imgConfigRes txtHw">Selecciona una imágen como escudo de la escuela</Typography>
                                                                    { !!errorMsg.file && <span style={{color: 'red'}}><small>{ errorMsg.file }</small></span> }
                                                            </Grid>
                                                        </label>
                                                        <Typography className="" variant="caption" display="block" gutterBottom>
                                                            *La imagen debe ser tipo JPG, JPEG o PNG
                                                        </Typography>
                                                        <Typography className="" variant="caption" display="block" gutterBottom>
                                                            *Se recomiendan las siguientes dimensiones: 400x400 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                        <Typography className="" variant="caption" display="block" gutterBottom>
                                                            <b>NOMBRE DE LA ESCUELA</b>
                                                        </Typography>
                                                        <TextField name="name" variant="outlined" placeholder={form.name}/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>NÚMERO TELEFONICO</b>
                                                            </Typography>
                                                            <TextField name="phone" variant="outlined" placeholder={form.phone}/>

                                                            {/* <TextField size="small" variant="outlined" placeholder={ phone } onChange={e => setPhone(e.target.value)}  error={errorPhoneAlert}  helperText={errorPhoneAlert ? 'El numero telefonico debe contener de 10 a 15 digitos' : ' '} fullWidth /> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>DIRECCIÓN</b>
                                                            </Typography>
                                                            <TextField name="address" variant="outlined" placeholder={form.address}/>
                                                            {/* <TextField size="small" variant="outlined" placeholder={ address } onChange={e =>  { setAddress(e.target.value); setaddressChange( true ); } } fullWidth /> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid x={12} md={12} container direction="row" justify="space-between">
                                                            <Grid xs={6} md={6}>
                                                                <Box py={1}>
                                                                    <Typography className="" variant="caption" display="block" gutterBottom>
                                                                        <b>CIUDAD</b>
                                                                    </Typography>
                                                                    <TextField name="city" variant="outlined" placeholder={form.city}/>
                                                                    {/* <TextField size="small" variant="outlined" placeholder={ city } onChange={e => setCity(e.target.value)} fullWidth /> */}
                                                                </Box>
                                                            </Grid>
                                                            <Grid xs={5} md={5}>
                                                                <Box py={1}>
                                                                    <Typography className="" variant="caption" display="block" gutterBottom>
                                                                        <b>CÓD. POSTAL</b>
                                                                    </Typography>
                                                                    <TextField name="zipcode" variant="outlined" placeholder={form.zipcode}/>
                                                                    {/* <TextField size="small" variant="outlined" placeholder={ zipcode } onChange={e => setZipcode(e.target.value)} error={errorZipCodeAlert} helperText={errorZipCodeAlert ? 'El codigo postal debe contener de 5 a 7 digitos.' : ' '} fullWidth /> */}
                                                                </Box>
                                                            </Grid>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>PAÍS</b>
                                                            </Typography>
                                                            <TextField name="country" variant="outlined" placeholder={form.country}/>
                                                            {/* <TextField size="small" variant="outlined" placeholder={ country } onChange={e => setCountry(e.target.value)} fullWidth /> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>MISIÓN</b>
                                                            </Typography>
                                                            <TextField name="motto1" variant="outlined" placeholder={form.motto1}/>
                                                            {/* <TextField size="small" variant="outlined" placeholder={ motto1 } onChange={e => setMotto1(e.target.value)} fullWidth /> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>VISIÓN</b>
                                                            </Typography>
                                                            <TextField name="motto2" variant="outlined" placeholder={form.motto2}/>
                                                            {/* <TextField size="small" variant="outlined" placeholder={ motto2 } onChange={e => setMotto2(e.target.value)} fullWidth /> */}
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Box py={1}>
                                                            <Typography className="" variant="caption" display="block" gutterBottom>
                                                                <b>DOCUMENTOS DESCARGABLES</b>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    
                                                    <Grid x={12} md={12} container direction="row" justify="space-around" alignItems="center" className="sede__documentosDescargables">
                                                            <Grid xs={6} md={6}>
                                                            { Reglamento
                                                                ?
                                                                loading1?

                                                                    <Box py={1} className="txtDescTitle">
                                                                        <Typography variant="p">Reglamento Escolar</Typography>&nbsp;
                                                                        <a href={ `${Reglamento}` } target="_blank" >
                                                                            {/* Si existia doxChange2 se proyectaba la variable reglamentoDisplay */}
                                                                            { docChange2 ?<VisibilityIcon style={{fill: "black",fontSize:20}} className="kipper_txt-grey" /> :<VisibilityIcon style={{fontSize:20}} className="kipper_txt-grey" /> }
                                                                        </a>
                                                                        
                                                                    </Box>
                                                                    :
                                                                    <CircularProgress />
                                                                : <Box py={1}> <span>Reglamento Escolar</span> </Box>
                                                            }
                                                            </Grid>
                                                            <Grid xs={6} md={6} container direction="row" justify="flex-end" alignItems="end">
                                                                <Box py={1}>
                                                                    <input type="file" id="uploadDoc1" className="fileUpload" name="fileUpload" onChange={ e => uploadDoc1( e ) }  />
                                                                    <Button id="kipper_sede_button_changeDocument" variant="contained" className="kipper_button" disableElevation onClick={ () => document.querySelector('#uploadDoc1').click() } >
                                                                        Seleccionar Documento
                                                                    </Button>
                                                                    { !!errorMsg.doc1 && <><br/><span style={{color: 'red'}}><small>{ errorMsg.doc1 }</small></span></> }
                                                                </Box>
                                                                <img src="" alt="" className="previw_img d-none" id={'reglamentoDOC'} />
                                                            </Grid>
                                                            <Divider variant="middle" />                                                    
                                                    </Grid>

                                                    <Grid x={12} md={12} container direction="row" justify="space-around" alignItems="center" className="sede__documentosDescargables">
                                                            <Grid xs={6} md={6}>
                                                                {  Privacidad
                                                                    ? 
                                                                    loading2?
                                                                        <Box py={1} className="txtDescTitle">
                                                                            <Typography variant="p">Políticas de Privacidad</Typography>&nbsp;
                                                                            <a href={ `${Privacidad}` } target="_blank" >
                                                                                {/* Si existia doxChange3 se proyectaba la variable privacidadDisplay */}
                                                                                { docChange3 ?<VisibilityIcon style={{fill: "black",fontSize:20}} className="kipper_txt-grey" /> : <VisibilityIcon style={{fontSize:20}} className="kipper_txt-grey" />}
                                                                            </a>
                                                                        </Box>
                                                                        :
                                                                        <CircularProgress/>
                                                                    : <Box py={1}> <span>Políticas de Privacidad</span> </Box>
                                                                }
                                                            </Grid>
                                                            <Grid xs={6} md={6} container direction="row" justify="flex-end" alignItems="end">
                                                                <Box py={1}>
                                                                    <input type="file" id="uploadDoc2" className="fileUpload" name="fileUpload" onChange={ e => uploadDoc2( e ) } />
                                                                    <Button id="kipper_sede_button_changeDocument" variant="contained" className="kipper_button" disableElevation onClick={ () => document.querySelector('#uploadDoc2').click() } >
                                                                        Seleccionar Documento
                                                                    </Button>
                                                                    { !!errorMsg.doc2 && <><br/><span style={{color: 'red'}}><small>{ errorMsg.doc2 }</small></span></> }
                                                                </Box>
                                                                <img src="" alt="" className="previw_img d-none" id={'privacidadDOC'} />
                                                            </Grid>
                                                            <Divider variant="middle" />                                                    
                                                    </Grid>

                                                    <Grid x={12} md={12} container direction="row" justify="space-around" alignItems="center" className="sede__documentosDescargables">
                                                            <Grid xs={6} md={6}>
                                                                { docOpcional
                                                                    ? 
                                                                    loading3?
                                                                        <Box py={1} className="txtDescTitle">
                                                                            <Typography variant="p">Documento Opcional</Typography>&nbsp;
                                                                            <a href={ `${docOpcional}` } target="_blank">
                                                                                {/* Si existia doxChange4 se proyectaba la variable opcionalDisplay */}
                                                                                { docChange4 ?<VisibilityIcon style={{fill: "black",fontSize:20}} className="kipper_txt-grey" /> :<VisibilityIcon style={{fontSize:20}} className="kipper_txt-grey" /> }
                                                                            </a>
                                                                        </Box>
                                                                        :
                                                                        <CircularProgress/>
                                                                    : <Box py={1}> <span>Documento Opcional</span> </Box>
                                                                }    
                                                            </Grid>
                                                            <Grid xs={6} md={6} container direction="row" justify="flex-end" alignItems="center">
                                                                <Box py={1}>
                                                                    <input type="file" id="uploadDoc3" className="fileUpload" name="fileUpload" onChange={ e => uploadDoc3( e ) } />
                                                                    <label for="uploadDoc3" className="kipper_txt-color pointer" >
                                                                        <Button id="kipper_sede_button_changeDocument" variant="contained" className="kipper_button" disableElevation onClick={ () => document.querySelector('#uploadDoc3').click() } >
                                                                            Seleccionar Documento
                                                                        </Button>
                                                                    </label>
                                                                    { !!errorMsg.doc3 && <><br/><span style={{color: 'red'}}><small>{ errorMsg.doc3 }</small></span></> }
                                                                </Box>
                                                                <img src="" alt="" className="previw_img d-none" id={'opcionalDOC'} />
                                                            </Grid>
                                                            <Divider variant="middle" />                                                    
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Box p={3} ></Box>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
        </>
    );
}


export default Sede
