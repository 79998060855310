import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// Imagenes
import notification from './../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';
import chat from './../../Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import home from './../../Assets/Img/svg_icons/Icon feather-home.svg';
import subjects from './../../Assets/Img/svg_icons/Icon feather-list.svg';
import calendar from './../../Assets/Img/svg_icons/Icon feather-calendar.svg';
import information from './../../Assets/Img/svg_icons/Icon ionic-ios-information-circle-outline.svg';

import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import logo from './../../Assets/Img/Layout/Logo_text.png';

// Estilos
import './../../Assets/Styles/Layout.css';
//
import app from "../functions/credentials";

import { AuthContext } from "../functions/Auth";


const SideBarTutor = ({}) => {
    const { handlelogout, currentUser } = useContext( AuthContext );

    return(
    <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
        <Box className="sidebar__headerChild" display="flex">
            <Box flexGrow={1} pt={2} pl={3}>
                <Link to="/home">
                    <img src={ logo } alt="Logo Kiper" />
                </Link>
            </Box>
            <Box pt={6}>
                <ul className="flex">
                    <li className="mx-3 bellinsidebar">
                        <Link to="/notificaciones">
                            <img src={ notification } alt="Usuario Kiper" style={{filter: "contrast(1) invert(100%)"}} className="sidebar_icon"  />
                        </Link>
                    </li>
                    
                </ul>
            </Box>
        </Box>
        <Box className="sidebar__search" display="flex" justifyContent="center">
            {/* <TextField
                id="input-with-icon-textfield"
                variant="outlined"
                placeholder="Buscar"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            /> */}
        </Box>
        <Box className="sidebar__menu ">
            <ul className="pl-10 pt-5">
                <li className="flex my-5 cursor-pointer">
                    <Link to="/home" className="flex">
                        <img src={ home } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Inicio</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/child/materia" className="flex">
                        <img src={ subjects } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Materias</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    {/* <Link to="/tutor/chat" className="flex"> */}
                    <Link to="/alumno/chat" className="flex"> 
                        <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Chat</span>
                    </Link> 
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/alumno/calendario" className="flex">
                        <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Calendario</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/child/information" className="flex">
                        <img src={ information } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Información</span>
                    </Link>
                </li>
                {
                    currentUser &&
                        <li className="flex my-5 cursor-pointer">
                            <Link to={`/child/horarios/child/detalles/info/${currentUser}`} className="flex">
                                <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                                <span>Ver horario</span>
                            </Link>
                        </li>
                }
                <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon"/>
                    <span>Salir</span>
                </li> 
                
            </ul>
        </Box>
    </Box>
    )
};

export default SideBarTutor
