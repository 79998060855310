import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ImformationItem from './../../Components/Global/ImformationItem';
import ModalShow from './../../Components/Global/Modal';

//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/Icon awesome-clock.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import { TextField } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';


//firebase
import * as firebase from 'firebase';
import { logDOM } from '@testing-library/react';
import HomeWorkItem from '../../Components/Global/Maestros/HomeWorkItem';
import HomeworkDeliveredItem from '../../Components/Global/Maestros/HomeworkDeliveredItem';
import { checkFile } from '../../Components/functions/helpapers';

function SchoolSubjectsDetails () {
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    const history = useHistory();
    const match = useRouteMatch('/teacher/tareas/detalles/:grupo/:subject/:tarea');
    const grupoID = match.params.grupo;
    const subjectID = match.params.subject;
    const tareaID = match.params.tarea;

    const [information, setinformation] = useState(null)
    const [deliveries, setdeliveries] = useState(null)
    const [materia, setmateria] = useState(null);
    const [change, setchange] = useState(false);
    const [ docOpcional, setDocOpcional ]   = useState('');
    const [docId, setDocId ] = useState('');
    const [ error, seterror ]               = useState('');


    const [ title, setTitle ] = useState(null);
    const [ description, setDescription ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ time, setTime] = useState(null);
    const [ file, setfile ] = useState(null);
    const [ link, setLink ] = useState(null);
    const [errorMsg, seterrorMsg] = useState({
        file: false
    });


    const [ scheduleTime, setScheduleTime ] = useState([ 10, 30 ]);
    const [ scheduleDay, setScheduleDay ]   = useState({
        monday: true,
        thuesday: false,
        wesday: false,
        tursday: false,
        friday: true,
        saturday: false
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    

    useEffect(() => {
        if(infoSchool)
        {
            subjects()
            handleLoad()
        }
    },[infoSchool]);

    function handleLoad() {
        let db = firebase.database();
        var listtareas = [];
        let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/homework/${tareaID}`);
        ref.once('value').then( snapshot => {
            if(snapshot.val()){
                setinformation(snapshot.val())
                setTitle(snapshot.val().title);
                setDescription(snapshot.val().description);
                setDate(snapshot.val().fulltime);
                setTime(snapshot.val().time);
                setfile(snapshot.val().document);
                setLink(snapshot.val().link);
            }
            if(snapshot.val().deliveries){
                setdeliveries(Object.entries(snapshot.val().deliveries))

            } 
        });
    }

    function subjects(){
        let db = firebase.database();
        var listtareas = [];
        let ref = db.ref(`school_subjects/${subjectID}`);
        ref.once('value').then( snapshot => {
            if(snapshot.val()){
                setmateria(snapshot.val())
            }   
        });
    }

    const handleForm = e =>{
        e.preventDefault();
        const regexUrl = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
        if(!regexUrl.test(link)){
            seterror('Ingrese una URL válida para el link del utilidad');
        }else{
            seterror('');
            handlesubmit(e);
        }
    }

    const handlesubmit = e =>{
        e.preventDefault();
        const idIMG = docId ?document.getElementById('idIMG').src :file ;
        
        if(change){
            setchange(false)
            let ref = firebase.database().ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/homework/${tareaID}`)
            ref.update({
                description: description,
                document: idIMG,
                fulltime: date,
                link: link,
                time: time,
                title: title
            }, function(error) {
                if (error) {
                    console.log(error)
                } else {
                    handleLoad()
                    setModalmsg('Información de la tarea actualizada');
                    ModalOpen(); 
                }
            });
        }
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocId( img )
                seterrorMsg({...errorMsg, file: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        var profileImg = document.getElementById('idIMG')
                        profileImg.src = url;
                        setchange(true)
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, file: 'Formatos válidos: png, jpg y jpeg' });
        }
    }


    return(
        <>            
        <Modal
            open={modalStaus}
            onClose={ModalClose}
        >
            <Grid className="modal__Posotion modal__Global">
                <ModalShow
                    title={ modalMsg }
                    onlyShow={ true }
                    showGoTo={ modalGoTo }
                    goTo={ modalLink }
                />
            </Grid>
        </Modal>
        <form onSubmit={ e => handleForm( e ) }>
            <Grid container className="alumInfo_grid">
                <Grid item xs={12} sm={12} >
                    <Box id="header_homework_details" pb={3}  mx={3} >
                        <PageHeader 
                            title="Detalles de la tarea asignada"
                            text="Modifica los detalles de la tarea y revisa las entregas de tus alumnos"
                            btn_back={`/teacher/materias/tareas/${grupoID}/${subjectID}`}
                            btn_action={change ?true :null}
                            btn_action_text="Guardar"
                        />
                        {
                            error
                            ?<Alert severity="error">{error}</Alert>
                            :null
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} className="Form_add-content">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                        <Box >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Box>
                        <Box p={3}>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}>
                                        <Typography variant="h5">
                                            {materia?.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            {materia?.grade}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}> 
                                        <Typography variant="body1" align="right">
                                            {/* $Fecha $hora */}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Título
                                        </Typography>
                                    </Box>
                                    <Box py={1} >
                                        <TextField  
                                            value={title}
                                            style={{width: "90%"}}
                                            onChange={e =>{ setTitle(e.target.value); setchange(true)}}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Descripción de la tarea
                                        </Typography>
                                    </Box>
                                    <Box py={1} >
                                        <TextField  
                                            value={description}
                                            style={{width: "90%"}}
                                            onChange={ e => {setDescription(e.target.value); setchange(true)}}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Tiempo limite de entrega
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Typography variant="body1">
                                            Día
                                        </Typography>
                                        <Typography variant="body1">
                                            <TextField  
                                                value={date}
                                                style={{width: "90%"}}
                                                type="date"
                                                onChange={ e => {setDate(e.target.value); setchange(true)}}
                                            />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Typography variant="body1">
                                            Hora
                                        </Typography>
                                        <Typography variant="body1">
                                            <TextField  
                                                value={time}
                                                style={{width: "90%"}}
                                                type="time"
                                                onChange={ e => {setTime(e.target.value); setchange(true)}}
                                            />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Material
                                        </Typography>
                                    </Box>
                                    <Box py={1} display="flex" >
                                        <img src="" alt="" className="previw_img d-none" id={'idIMG'} onChange={e => {setDocId( e ); }} />
                                        <a href={file} download={file} sim target="_blank" rel="noopener noreferrer">
                                            Link de Material
                                        </a>
                                        <Box ml={1} >
                                            <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload"  onChange={ e => {uploadDocId( e ); } }/>
                                            <label for="docIdentificacion" className="kipper_txt-grey pointer">
                                                <CreateIcon />
                                            </label>
                                            { !!errorMsg.file && <span style={{color: 'red'}}><small>{ errorMsg.file }</small></span> }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Links de utilidad
                                        </Typography>
                                    </Box>
                                    <Box py={1} >
                                        {/* <a href={information?.link} target="_blank" rel="noopener noreferrer">
                                            {information?.link} 
                                        </a> */}
                                        <TextField  
                                            value={link}
                                            style={{width: "90%"}}
                                            onChange={ e => {setLink(e.target.value); setchange(true)}}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Tareas entregadas
                                        </Typography>
                                    </Box>
                                    <Box py={1} >
                                        {
                                            deliveries?
                                            deliveries.map( i =>
                                                        <HomeworkDeliveredItem childcode={i[0]} homework={i[1]} />
                                                    )
                                            :null
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
        </>
    )
    
}

export default SchoolSubjectsDetails