import React from 'react';
import { Route } from 'react-router-dom';
//Layout
import LayoutOutSide from './LayoutOutSide';

const LayoutRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <LayoutOutSide>
                <Component {...props} />
            </LayoutOutSide>
        )} />
    )
}

export default LayoutRoutes;