import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../functions/Auth';
import SchoolInformation from '../SchoolInformation';

//db
import * as firebase from 'firebase';

const useStyles = makeStyles((theme) =>({
    btn : {
      color:'#F8B236',
      border:'2px solid #F8B236',
      borderRadius : '50%',
      padding:'3px'
    },
    toRight : {
      display:'flex',
      justifyContent:'flex-end'
    },
    toBold :{
      fontWeight:'bold'
    },
    divi : {
      width:'100%',
      listStyle:'none',
      marginLeft:0
    },
    avt :{
      width:'75px',
      height:'75px'
    }
  }))

const HeaderSchoolInfoTutor =()=>{

    const classes = useStyles();
    const { infoSchool, currentUser} = useContext(AuthContext);
    const [schoolInfo, setSchoolInfo] = useState('');
    const [tutorInfo, setTutorInfo] = useState('')
    const [childs, setChild] = useState([])

    useEffect( () =>{
        if(infoSchool){
            infogen()
            infochild()
        }
    }, [infoSchool] );
    
    const infogen = () =>{
    
        let db = firebase.database();
        let ref = db.ref(`tutors/${currentUser}`);
        ref.once('value').then( snapshot => {
            if ( snapshot.val()?.school ) {
            setTutorInfo(snapshot.val())
            let ref = db.ref(`schools/${snapshot.val()?.school}`);
            ref.once('value').then( snapshotSchool => {
                setSchoolInfo( snapshotSchool.val() );      
            })
            // infochild()
                // Object.entries(arraychilds).forEach(element => {
                //   infochild(element[1])
                // });
            }
            else {
            setSchoolInfo( false );
            }
        });
    }
    
    const infochild = () =>{
        var listchilds = []
        let db = firebase.database();
        let arraychilds = infoSchool.childs
        if(arraychilds){
            Object.entries(arraychilds).forEach(element => {
                let ref = db.ref(`childs/${element[1]}`);
                ref.once('value').then( snapshot => {
                    listchilds.push(snapshot.val())
                });
            })
            setChild(listchilds)
        }
    }

    return(
        <SchoolInformation 
            isTutor={ true }
            user={ tutorInfo?.avatar }
            nameuser={ `${tutorInfo?.name} ${tutorInfo?.last_name}` }
            logo={ schoolInfo?.avatar } 
            name={ schoolInfo?.name } 
            address={ schoolInfo?.location?.address } 
            phone={ schoolInfo?.contact?.phone } 
            mision = {schoolInfo?.details?.mision}
            vision = {schoolInfo?.details?.vision}
          />
    );
}

export default HeaderSchoolInfoTutor;