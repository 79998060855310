import React, { useEffect, useContext, useState } from 'react'
// Components
import { AuthContext } from "../../Components/functions/Auth";
import HomeworkHome from '../../Components/tutors/HomeworkHome'
import SchoolInformation from '../../Components/Global/SchoolInformation'
import Calendario from '../../Components/Global/Calendar'
// img
import avatar from './../../Assets/Img/Home/tutor_example_avatar.JPG'
// MAterial UI
import { Card, CardContent, Grid, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles }  from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import * as firebase from 'firebase';
import { ChildHomeItem } from '../../Components/childs/ChildHomeItem';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

const useStyles = makeStyles((theme) =>({
  btn : {
    color:'#F8B236',
    border:'2px solid #F8B236',
    borderRadius : '50%',
    padding:'3px'
  },
  toRight : {
    display:'flex',
    justifyContent:'flex-end'
  },
  toBold :{
    fontWeight:'bold'
  },
  divi : {
    width:'100%',
    listStyle:'none',
    marginLeft:0
  },
  avt :{
    width:'75px',
    height:'75px'
  }
}))

function HomeTutor() {
  
  const classes = useStyles();
  const { infoSchool, currentUser} = useContext(AuthContext);
  const [schoolInfo, setSchoolInfo] = useState('');
  const [tutorInfo, setTutorInfo] = useState('')
  const [childs, setChild] = useState([])

  useEffect( () =>{
    if(infoSchool){
      infogen()
      infochild()
    }
  }, [infoSchool] );

  const infogen = () =>{

    let db = firebase.database();
    let ref = db.ref(`tutors/${currentUser}`);
    ref.once('value').then( snapshot => {
      if ( snapshot.val()?.school ) {
        setTutorInfo(snapshot.val())
        let ref = db.ref(`schools/${snapshot.val()?.school}`);
        ref.once('value').then( snapshotSchool => {
          setSchoolInfo( snapshotSchool.val() );      
        })
        // infochild()
          // Object.entries(arraychilds).forEach(element => {
          //   infochild(element[1])
          // });
      }
      else {
        setSchoolInfo( false );
      }
    });
  }

  const infochild = () =>{
    var listchilds = []
    let db = firebase.database();
    let arraychilds = infoSchool.childs
    if(arraychilds){
      Object.entries(arraychilds).forEach(element => {
        let ref = db.ref(`childs/${element[1]}`);
        ref.once('value').then( snapshot => {
          listchilds.push(snapshot.val())
        });
      })
      setChild(listchilds)
    }
  }

  return(
    <Box>
      {/* <NavTutorPhone /> */}
      {/* <Box>
          <SchoolInformation 
            isTutor={ true }
            user={ tutorInfo?.avatar }
            nameuser={ `${tutorInfo?.name} ${tutorInfo?.last_name}` }
            logo={ schoolInfo?.avatar } 
            name={ schoolInfo?.name } 
            address={ schoolInfo?.location?.address } 
            phone={ schoolInfo?.contact?.phone } 
            mision = {schoolInfo?.details?.mision}
            vision = {schoolInfo?.details?.vision}
          />
      </Box> */}
      <Grid id="homeTutor_childsItemsContainer" container spacing={3}>
          {
            childs.map(element => 
              <>
                <ChildHomeItem                
                  key={ element.id }
                  fronTutor={ true }
                  avatar={`${element.avatar}`}
                  title={ `${element.name} ${element.last_name}` }
                  turno={`${element.details.grade} ${element.details.group} `}
                  grado={`${element.details.turn} `}
                  link={`/tutor/alumnos/${element.id}`}
                />
              </>
            )
          }
      </Grid>
      <Box className="bg-white" p={3} m={1} boxShadow={3} borderRadius={10}>
        <Calendario type="tutor" />
      </Box>
    </Box>
  )
}

export default HomeTutor;