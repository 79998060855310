import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
import Calendar from './../../Components/Global/Calendar'
//img
import user from './../../Assets/Img/General/user_example.png';
import logoSchool from './../../Assets/Img/Home/Logo_School.png';
import icon_location from './../../Assets/Img/Home/Icon_Location.png';
import icon_phone from './../../Assets/Img/Home/Icon_phone.png';
//MaterialUI

import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavChildPhone from './NavChildPhone/NavChildPhone';

function CalendarChild () {
    const history = useHistory();
    const { currentUser, info }   = useContext(AuthContext);
    const [schoolInfo, setSchoolInfo] = useState('');
    const [tutorInfo, setTutorInfo] = useState('');

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`childs/${currentUser}`);
        ref.once('value').then( snapshot => {
            if (  snapshot.val().school ) {
                setTutorInfo(snapshot.val())
                let ref = db.ref(`schools/${snapshot.val().school}`);
                ref.once('value').then( snapshotSchool => {
                setSchoolInfo( snapshotSchool.val() );      
                })
            }
            else {
                setSchoolInfo( false );   
            }
        });
    }, [ ] );
    
    return(
        <Grid container className="padres_grid">
            <NavChildPhone/>
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Calendario Escolar"
                        text="Consulta eventos y comunicados escolares " 
                        btn_back="/alumno/inicio"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box p={2} >
                                <Calendar />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CalendarChild