import React, { useState, useContext, useEffect, useRef  } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
// IMG
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import user from './../../Assets/Img/General/placeholder_boy.png';
// import editIcon from './../../Assets/Img/Home/Icon_Pencil.png';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import EventoItem from './../../Components/Global/EventoItem';
import ModalShow from './../../Components/Global/Modal';
import Loader from './../../Components/Global/Loader';
// meterialui
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

// firebases
import * as firebase from 'firebase';
import { Button, InputLabel, MenuItem, Typography } from '@material-ui/core';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import Select from './../../Constants/select';

//Scheduler
import { ViewState } from '@devexpress/dx-react-scheduler';
import { Scheduler, WeekView, Appointments, AppointmentTooltip} from '@devexpress/dx-react-scheduler-material-ui';
import ModalSinSubmit from '../../Components/Global/ModalSinSubmit';

function GroupDetails () {

    const history = useHistory();
    const match = useRouteMatch('/grupos/informacion/:id');
    const GroupID = match.params.id;

    const formRef = useRef();
    const [loadValues, setLoadValues] = useState({
        turno: '',
        grado: '',
        grupo: '',
        nivel: '',
        type: '',
        maestro: ''
    })

    const [data,setData] = useState([]);

    const { currentUser }               = useContext(AuthContext);
    const [saveGroup, setSaveGroup ]    = useState( false );
    const [ loader, setLoader ]         = useState( false );
    const [groupInfo, setGroupInfo]     = useState('')
    const [materiaInfo, setMateriaInfo] = useState([])
    const [maestroInfo, setMaestroInfo] = useState([])
    const [studentsInfo, setstudentsInfo] = useState([])
    const [school_subject, setSchool_subject] = useState([]);
    const [level, setLevel]                 = useState('');
    const [selectSubject,setSelectSubject] = useState({
        teacherId:null,
        subjectId:null,
    });

    const [grupo, setGrupo]     = useState('')
    const [maestro, setMaestro] = useState('')
    const [maestroName, setMaestroName] = useState('');
    const [maestroAvatar, setMaestroAvatar] = useState('');
    const [errors, seterrors] = useState('');

    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ isShow2, setIsShow2 ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalStaus2, setModalStaus2 ] = useState( false );
    const [refresh, setRefresh] = useState(0);

    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalMsg2, setModalmsg2 ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");

    const [ isDeleted2, setIsDeleted2 ]   = useState( false );


    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);

    const ModalOpen2 = () => setModalStaus2(true);
    const ModalClose2 = () => setModalStaus2(false);
    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const modalGoTo2 = ( e, link ) => {
        e.preventDefault();
        ModalClose2();
        if ( link ) {
            history.push(link);
        }
    };

    const handleSubmit = val =>{
        let db = firebase.database();
        let Usersref = db.ref(`schools/${currentUser}/groups/${GroupID}`);
        Usersref.update({
            turn: val.turno,
            grade: val.grado,
            group: val.grupo,
            level: val.nivel,
            type: 2,
            teacher: {
                teacher_key: val.maestro,
                teacher_name: maestroName ? maestroName : '',
                teacher_avatar: maestroAvatar ? maestroAvatar : '',
            },
        },
        error => {
            if (error) {
                // The write failed...
                setModalmsg('Inténtalo de nuevo en unos minutos')
                setModalLink(`/grupos/informacion/${GroupID}`)
            } else {
                // Data saved successfully!
                setSaveGroup( true )
            }
        });

        let TeacherRef = db.ref(`teacher/${maestro}/groups/${GroupID}`);
        TeacherRef.update({
            group_key: GroupID,
            details:{
                turn: val.turno,
                grade: val.grado,
                group: val.grupo,
                level: val.nivel,
            }
        },
        error => {
            console.log( error );
            if ( error ){
                // The write failed...
                setModalmsg('Inténtalo de nuevo en unos minutos')
                setModalLink(`/grupos/informacion/${GroupID}`)
            } else {
                // Data saved successfully!
                setChatteacher()
                setModalmsg('Maestro asignado');
                setModalLink(`/grupos/informacion/${GroupID}`);
            }
        });
        ModalOpen();
    }

    useEffect(() => {
        getAlumnos();
    }, [GroupID,refresh]);

    const GetMaterias = async ( grupo ) => {
        if(grupo){
            var gradeVar = Object.entries(grupo);
            // if(gradeVar.length > 2){
            //     var finalgrade = gradeVar[2][1].grade
            // }else{
            //     var finalgrade = gradeVar[0][1].grade
            // }
            let listMaterias = [];
            // let db = firebase.database();
            // let Ref2 = db.ref(`school_subjects/`);
            // await Ref2.once('value').then( snapshot => {
            //     snapshot.forEach( item =>{
            //         let itemInfo = item.val()                    
            //         if(itemInfo.status === "active" && itemInfo.school === currentUser && itemInfo.grade === finalgrade){
            //             console.log(itemInfo)
            //             listMaterias.push( 
            //                 <item
            //                     key={ itemInfo.id }
            //                     title={ `${itemInfo.title} ` }
            //                     grade={ `${itemInfo.grade} ` }
            //                     details={ `${itemInfo.details} ` }
            //                     teacher={ grupo[itemInfo.id]?.teacher_key ? grupo[itemInfo.id]?.teacher_name : ' ' }
            //                 />
            //             )              
            //         }
            //     })
            // })
            for(const materia of gradeVar)
            {
                let informateria = await firebase.database().ref('school_subjects/'+materia[0]).once('value');
                let materiadata = informateria.val()
                
                if(materiadata != null){
                    listMaterias.push( 
                        <item
                            key={ materiadata.id }
                            title={ `${materiadata.title} ` }
                            grade={ `${materiadata.grade} ` }
                            details={ `${materiadata.details} ` }
                            teacher={ grupo[materiadata.id]?.teacher_key ? grupo[materiadata.id]?.teacher_name : ' ' }
                            teacherKey={materia[1]?.teacher_key ? materia[1].teacher_key :null}
                            subjectKey={materia[0]? materia[0]:null}
                        />
                    ) 
                }

            }
            setLoader( true )
            return listMaterias
        }
    }

    useEffect(() => {
        if(grupo){
            let listMaterias = [];
            let db = firebase.database();
            let Ref2 = db.ref(`school_subjects/`);
            Ref2.once('value').then( snapshot => {
                snapshot.forEach( item =>{
                    let itemInfo = item.val()
                    if(grupo[item.val().id]){
                        listMaterias.push( 
                            <item
                                key={ item.key }
                                title={ `${itemInfo.title} ` }
                                grade={ `${itemInfo.grade} ` }
                                details={ `${itemInfo.details} ` }
                                teacher={ itemInfo.teacher_key ? itemInfo.teacher_name : ' ' }
                            />
                        )}
                })
            })
        }
    }, [school_subject])

    useEffect(() => { getTeacherList(); }, []);

    useEffect(()=>{
        changeLanguage();
        getDataSchedule();
    },[refresh]);

    const getDataSchedule =async()=>{
        let db = firebase.database();
        let list = [];
        let arrayDetails = [];

        let schoolRef = db.ref(`schools/${currentUser}/groups/${GroupID}`);
        let subjectRef = db.ref(`school_subjects`);//Get subject's details

        await subjectRef.once('value').then(function(snapshot) {
            let itemDetails = snapshot.val();
            arrayDetails = itemDetails;
        });

        await schoolRef.once('value').then(function(snapshot) {
            let item = snapshot.val();

            for (var clave in item.school_subjects){
                let titleSchedule = '';
                for(var clave2 in arrayDetails){
                    if(clave2 === clave){
                        titleSchedule = arrayDetails[clave2].title;
                        break;
                    }
                }

                const {monday, tuersday, wensday, tursday, friday, saturday} = item.school_subjects[clave].days;
                const {
                    horaInicioLunes,
                    horaInicioMartes,
                    horaInicioMiercoles,
                    horaInicioJueves,
                    horaInicioViernes,
                    horaInicioSabado,
                    horaFinalLunes, 
                    horaFinalMartes,
                    horaFinalMiercoles,
                    horaFinalJueves, 
                    horaFinalViernes, 
                    horaFinalSabado,
                } = item.school_subjects[clave].days;

                if(monday && horaInicioLunes && horaFinalLunes && horaInicioLunes !== '' && horaFinalLunes !== ''){
                    const array1 = horaInicioLunes.split(":");
                    const array2 = horaFinalLunes.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 25, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 25, Number(array2[0]), Number(array2[1])),
                    });
                }
                if(tursday && horaInicioMartes && horaFinalMartes && horaInicioMartes !== '' && horaFinalMartes !== ''){
                    const array1 = horaInicioMartes.split(":");
                    const array2 = horaFinalMartes.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 26, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 26, Number(array2[0]), Number(array2[1])),
                    });
                } 
                if(wensday && horaInicioMiercoles && horaFinalMiercoles && horaInicioMiercoles !== '' && horaFinalMiercoles !== ''){
                    const array1 = horaInicioMiercoles.split(":");
                    const array2 = horaFinalMiercoles.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 27, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 27, Number(array2[0]), Number(array2[1])),
                    });
                }
                if(tuersday && horaInicioJueves && horaFinalJueves && horaInicioJueves !== '' && horaFinalJueves !== ''){
                    const array1 = horaInicioJueves.split(":");
                    const array2 = horaFinalJueves.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 28, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 28, Number(array2[0]), Number(array2[1])),
                    });
                }
                if(friday && horaInicioViernes && horaFinalViernes && horaInicioViernes !== '' && horaFinalViernes !== ''){
                    const array1 = horaInicioViernes.split(":");
                    const array2 = horaFinalViernes.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 29, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 29, Number(array2[0]), Number(array2[1])),
                    });
                }
                if(saturday && horaInicioSabado && horaFinalSabado && horaInicioSabado !== '' && horaFinalSabado !== ''){
                    const array1 = horaInicioSabado.split(":");
                    const array2 = horaFinalSabado.split(":");
                    list.push({
                        title: `Profesor ${item.school_subjects[clave].teacher_name} - Materia ${titleSchedule}`,
                        startDate: new Date(2018, 5, 30, Number(array1[0]), Number(array1[1])),
                        endDate: new Date(2018, 5, 30, Number(array2[0]), Number(array2[1])),
                    });
                }   
            }

        });
        setData(list);
    }

    const getTeacherList = async() => {
        let listMaestros = [];
        let resp = await firebase.database().ref(`teacher`).once('value');
        resp.forEach( item => {
            let itemInfo = item.val()
            if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                listMaestros.push({ 
                    key:item.key,
                    avatar: itemInfo.avatar,
                    name: `${itemInfo.name} ${itemInfo.lastname}`,
                })
            }
        })
        if( !listMaestros.length ){
            setMaestroInfo( listMaestros )
        } else{
            setMaestroInfo( listMaestros )
        }
    }

    const handleChangeMestro = ( e ) => {
        maestroInfo.forEach( item => {
            if ( `${item.key}` === `${e.target.value}` ) {
                setMaestroName( `${(item.name) ? item.name : ''} ${(item.lastname) ? item.lastname : ''}` )
                setMaestroAvatar( item.avatar )
            }
        })
    }

    async function setChatteacher()
    {
        let refGroups = firebase.database().ref("chats")
        let dbGroups = await refGroups.once('value');
        dbGroups.forEach(item =>{
            if(item.val()?.group?.key == GroupID)
            {
                refGroups.child(item.key).update({teacher: maestro})
            }
        })
    }

    async function getAlumnos() 
    {
        let groupRef = firebase.database().ref(`schools/${currentUser}/groups/${GroupID}`);
        let listStudent = [];
        let dataGroup = await groupRef.once('value');
        let materias = await GetMaterias( dataGroup.val().school_subjects )
        let key = ''
        setMateriaInfo( materias )
        setLevel(dataGroup.val().level);
        setGroupInfo  ( dataGroup.val() );

        if(dataGroup.val().teacher?.teacher_key){
            key = dataGroup.val().teacher?.teacher_key
        }

        setLoadValues({
            turno: dataGroup.val().turn,
            grado: dataGroup.val().grade,
            grupo: dataGroup.val().group,
            type: dataGroup.val().type,
            nivel: dataGroup.val().level,
            maestro: key 
        })
        setMaestro( dataGroup.val().teacher?.teacher_key )
        setMaestroName( dataGroup.val().teacher?.teacher_name )
        setMaestroAvatar( dataGroup.val().teacher?.teacher_avatar )

        let iterdataStudents = dataGroup.val()?.student ?Object.entries(dataGroup.val()?.student) :null
        if(iterdataStudents)
        {
            for(const student of iterdataStudents)
            {
            let itemVal = await firebase.database().ref(`childs/${student[0]}`).once('value')
            if(itemVal.val()?.status == "active")
            {
                listStudent.push( 
                    <item
                    key={ itemVal.key }
                    avatar={ itemVal.val().avatar }
                    name={ `${itemVal.val().name} ${itemVal.val().last_name} ` }
                    />
                    )
                }
            } 
            setstudentsInfo( listStudent );
        }
    }

    const changeLanguage =()=>{
        var days = document.querySelectorAll('p[class^="Cell-dayOfWeek-"]');

        for (let index = 0; index < days.length; index++) {
            if(index === 0) days[index].innerHTML="Lunes";
            else if(index === 1) days[index].innerHTML="Martes";
            else if(index === 2) days[index].innerHTML="Miercoles";
            else if(index === 3) days[index].innerHTML="Jueves"; 
            else if(index === 4) days[index].innerHTML="Viernes"; 
            else if(index === 5) days[index].innerHTML="Sabado";
        }
    }

    const validateForm = yup.object({
        turno: yup
            .string('Seleccione un turno')
            .required('El turno es obligatorio'),
        grado: yup
            .string('Seleccione un grado')
            .required('El grado es obligatorio'),
        grupo: yup
            .string('Seleccione un grupo')
            .required('El grupo es obligatorio'),
        nivel: yup
            .string('Seleccione el género')
            .required('El nivel es obligatorio'),
        maestro: yup
            .string('Seleccione un maestro')
            .required('Tiene que seleccionar un maestro obligatoriamente'),
    });
      
    const initialFormState ={
        turno: loadValues.turno,
        grado: loadValues.grado,
        grupo: loadValues.grupo,
        nivel: loadValues.nivel,
        type: loadValues.type,
        maestro: loadValues.maestro
    }

    const handleOpen =()=>{
        setIsShow2(false);
        ModalOpen2();
        setIsDeleted2(true);
    }

    const handleDeleteSubject =async(e,status)=>{
        if(status){
            const { teacherId, subjectId } = selectSubject;
            if(teacherId && subjectId && currentUser){
                
                // (1) - First we need to delete the subject of school collection...
                let db = firebase.database();
                let schoolRef = db.ref(`schools/${currentUser}/groups/${GroupID}/school_subjects/${subjectId}`);

                return new Promise(resolve =>{
                    schoolRef.remove().then(function() {
                        setIsShow2( true );
                        setIsDeleted2( false );
                        setModalmsg2( `La materia ${subjectId} ah sido eliminada de este grupo` );
                        ModalOpen2();
                        setRefresh(refresh + 1);//Refresh data of shedule and subjects
                    })
                    .catch(function(error) {
                        setIsShow2( true );
                        setIsDeleted2( false );
                        setModalmsg2( "Lo sentimos, algo extraño paso. Porfavor intenta de nuevo" );
                        ModalOpen2();
                        setRefresh(refresh + 1);//Refresh data of shedule and subjects
                    });
                })

            }else{
                setIsShow2( true );
                setIsDeleted2( false );
                setModalmsg2( "Lo sentimos, algo extraño paso. Porfavor intenta de nuevo" );
                ModalOpen2();
                setRefresh(refresh + 1);//Refresh data of shedule and subjects
            }
        }else ModalClose2()
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        btnText="Continuar"
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                    /> 
                </Grid>
            </Modal>

            <Modal
                open={modalStaus2}
                onClose={ModalClose2}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted2 ?
                            <ModalSinSubmit
                                title=" ¿Estás seguro de eliminar la materia del grupo? "
                                confirmDelete={ true }
                                Delete={ handleDeleteSubject }
                            /> : null
                    }
                    {
                        isShow2 ?
                            <ModalSinSubmit
                                title={ modalMsg2 }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo2 }
                            /> : null
                    }
                    
                </Grid>
            </Modal>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                innerRef={formRef}
                enableReinitialize={true}
                validationSchema={validateForm}
                onSubmit={values =>{
                    handleSubmit(values)
                }}>


                <Form>
                    <Grid container>
                        {/* <NavEscuelaPhone /> */}
                        <Grid id="header_info_group" item xs={8} sm={12} md={12}>
                            <Box pb={3} >
                                <PageHeader 
                                    title={`Información de grupo`}
                                    text="Administra, agrega y edita clases para los grupos"
                                    btn_back="/grupos"
                                    btn_action={true}
                                    btn_action_text="Guardar"
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} className="Form_add-content kipper_box">
                            <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w" mb={3} >
                                <Grid container >
                                    <Grid item xs={12} >
                                        <img src={ portada } alt="" className="kipperBox_portada-img" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box p={2}>
                                            <InputLabel shrink id="nivel">
                                                Nivel <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="nivel"
                                                disabled
                                            >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Kinder">Kinder</MenuItem>
                                                <MenuItem value="Primaria">Primaria</MenuItem>
                                                <MenuItem value="Secundaria">Secundaria</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box p={2}>
                                            <InputLabel shrink id="grado">
                                                Grado <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="grado"
                                                disabled
                                            >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Primero">Primero</MenuItem>
                                                <MenuItem value="Segundo">Segundo</MenuItem>
                                                <MenuItem value="Tercero">Tercero</MenuItem>
                                                <MenuItem value="Cuarto">Cuarto</MenuItem>
                                                <MenuItem value="Quinto">Quinto</MenuItem>
                                                <MenuItem value="Sexto">Sexto</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box p={2}>
                                            <InputLabel shrink id="grupo">
                                                Grupo <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="grupo"
                                                disabled
                                            >
                                                <MenuItem disabled value="" ><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                                <MenuItem value="D">D</MenuItem>
                                                <MenuItem value="E">E</MenuItem>
                                                <MenuItem value="F">F</MenuItem>
                                                <MenuItem value="G">G</MenuItem>
                                                <MenuItem value="H">H</MenuItem>
                                                <MenuItem value="I">I</MenuItem>
                                                <MenuItem value="J">J</MenuItem>

                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box p={2}>
                                            <InputLabel shrink id="turn">
                                                Turno <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select 
                                                name="turno" 
                                                disabled
                                            >
                                                <MenuItem disabled value="" ><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Matutino">Matutino</MenuItem>
                                                <MenuItem value="Vespertino">Vespertino</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={2}>
                                            <InputLabel shrink id="maestro">
                                                Maestro Titular <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select 
                                                name="maestro"
                                                onBlur={ e =>{handleChangeMestro( e ) }} 
                                                >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                { maestroInfo 
                                                    ? maestroInfo.map( item => <MenuItem value={item.key} key={item.key} ><em> { item.name } { item.lastname } </em></MenuItem> )
                                                    : <MenuItem value=""><em>Sin maestros</em></MenuItem>
                                                }
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mx={4} >
                                            <Grid container direction="row" justify="center" alignItems="center" className="new_item" >
                                                <Box>
                                                    <Link to={`/grupos/agregar/${GroupID}`}>
                                                        <Button className="kipper_button" variant="outlined" fullWidth> Asignar maestros por materia </Button>
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} justify="center"  alignItems="center"  >
                                        <Box px={2} mt={1} className="kipper_txt-grey">
                                            <Typography variant="h5" className="aling_center"> Materias </Typography>
                                            <hr />
                                        </Box>
                                    </Grid>
                                    {loader 
                                        ? materiaInfo.map( item =>
                                            <Grid id="kiper_gridContainer" item xs={12} md={3} key={ item.key } >
                                                <Box id="kiper_school_subjetListItem" boxShadow={2} m={2} p={2} className="bg-white  outline-box">
                                                    <Grid   container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey div_long_text">
                                                        <Box id="TextMAteriasGrupos" className="boxGridContainer1" >
                                                            <Link to={`/escuela/materias/informacion/${item.key}/${level}/1`}>
                                                                <Typography variant="subtitle1" className="fw-500 capitalize long_Text_cut" >{ item.props.title }</Typography>
                                                                <Typography variant="body1" className="fw-200 capitalize" >{ item.props.teacher }</Typography>
                                                            </Link>
                                                        </Box>
                                                        {
                                                            item.props.teacherKey &&
                                                            <Box id="TextMAteriasGrupos" className="boxGridContainer2">
                                                                {/* <Link to={`/grupos/agregar/${GroupID}/${item.props.teacherKey}`}> */}
                                                                <Link onClick={ function(event){ handleOpen(); setSelectSubject({
                                                                    teacherId:item.props.teacherKey,
                                                                    subjectId:item.props.subjectKey,
                                                                })} }>
                                                                    {/* <Typography variant="body1" className="fw-200 capitalize" ></Typography> */}
                                                                    {/* <img src={editIcon} alt="" width="50px" height="50px"/> */}
                                                                    <DeleteIcon />
                                                                </Link>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        )
                                        : <Grid item xs={3} >
                                            <Box boxShadow={2} m={2} p={2} className="bg-white  outline-box">
                                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey">
                                                    <Box >
                                                        <Typography variant="subtitle1" className="fw-500 capitalize" > Sin Materias </Typography>
                                                        <Typography variant="body1" className="fw-200 capitalize" ></Typography>
                                                    </Box>
                                                    <Box>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                    <Grid item xs={12} >
                                        <Box px={2} mb={1} className="kipper_txt-grey">
                                            <Typography variant="h5" className="aling_center"> Alumnos </Typography>
                                            <hr />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        {
                                            studentsInfo.map( item =>
                                                <Box px={3} mb={1} className="kipper_txt-grey">
                                                    <Grid container direction="row" justify="flex-start" alignItems="center" className="kipper_txt-grey">
                                                        <Box borderRadius="50%">
                                                            <Link to={`/alumnos/informacion/${item.key}`}>
                                                                <img src={ item.props.avatar ? item.props.avatar : user } alt="" width="50px" height="50px" style={{borderRadius:'50%'}}/>
                                                            </Link>
                                                        </Box>
                                                        <Box>
                                                            <Link to={`/alumnos/informacion/${item.key}`}>
                                                                <Typography id="kiper_school_childsListItem" variant="h6" className="fw-500 capitalize" >&nbsp;&nbsp;{ item.props.name }</Typography>
                                                            </Link>
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            )
                                        }
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Box px={2} mb={1} className="kipper_txt-grey">
                                            <Typography variant="h5" className="aling_center"> Horario del grupo </Typography>
                                            <hr />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Grid container className="schedule__main">
                                            <Paper>
                                                <Scheduler data={data}>
                                                    <ViewState
                                                        defaultCurrentDate="2018-06-27"
                                                    />
                                                    <WeekView
                                                        startDayHour={8}
                                                        endDayHour={20}
                                                        excludedDays={[0]}
                                                    />
                                                    <Appointments />
                                                    <AppointmentTooltip />
                                                </Scheduler>
                                            </Paper>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    )
}

export default GroupDetails