import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from '../Components/Global/PageHeader';
import ModalShow from '../Components/Global/Modal';
//img
import portada from '../Assets/Img/General/portada_add-yellow.png';
import file from '../Assets/Img/General/File.png';
import upload from '../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
//firebase
import * as firebase from 'firebase';
import { Avatar } from '@material-ui/core';
import { checkFile } from '../Components/functions/helpapers';

const MateriasAgregar = () => {

    const history = useHistory();
    // const match = useRouteMatch('/teacher/materias/informacion/:id');
    const teacherID = 243567;
    // const { currentUser }           = useContext(AuthContext);


    const [docOpcional, setDocOpcional] = useState('');
    const [scheduleTime, setScheduleTime] = useState([10, 30]);

    const [scheduleDay, setScheduleDay] = useState("lunes")

    const [Info, setInfo] = useState([10, 30]);

    const [error, setError] = useState('');
    // const [loader, setLoaderChild] = useState(false);
    const { info } = useContext(AuthContext);
    const [title, setTitle] = useState('')
    const [Avatar, setAvatar] = useState('')
    const [turno, setTurno] = useState('')
    const [grado, setGrado] = useState('')
    const [grupo, setGrupo] = useState('')
    const [about, setAbout] = useState('')
    const [lunes, setLunes] = useState([0, 1])
    const [martes, setMartes] = useState([0, 1])
    const [miercoles, setMiercoles] = useState([0, 1])
    const [jueves, setJueves] = useState([0, 1])
    const [viernes, setViernes] = useState([0, 1])
    const [sabado, setSabado] = useState([0, 1])
    const [errorMsg, seterrorMsg] = useState({
        doc: false
    });
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const marks = [
        {
            value: 0,
            label: '08:00',
        },
        {
            value: 10,
            label: '09:00',
        },
        {
            value: 20,
            label: '10:00',
        },
        {
            value: 30,
            label: '11:00',
        },
        {
            value: 40,
            label: '12:00',
        },
        {
            value: 50,
            label: '13:00',
        },
        {
            value: 60,
            label: '14:00',
        },
        {
            value: 70,
            label: '15:00',
        },
        {
            value: 80,
            label: '16:00',
        },
        {
            value: 90,
            label: '17:00',
        },
        {
            value: 100,
            label: '18:00',
        },
    ];
    useEffect(() => handleLoad(), []);
    useEffect(() => {
        switch (scheduleDay) {
            case "monday":
                setScheduleTime(lunes)
                break;
            case "tuesday":
                setScheduleTime(martes)
                break;
            case "wednesday":
                setScheduleTime(miercoles)
                break;
            case "thursday":
                setScheduleTime(jueves)
                break;
            case "friday":
                setScheduleTime(viernes)
                break;
            case "saturday":
                setScheduleTime(sabado)
                break;
        }
    }, [scheduleDay])
    useEffect(() => {
        switch (scheduleDay) {
            case "monday":
                if (lunes != scheduleTime) {
                    setLunes(scheduleTime)
                }
                break;
            case "tuesday":
                if (martes != scheduleTime) {
                    setMartes(scheduleTime)
                }
                break;
            case "wednesday":
                if (miercoles != scheduleTime) {
                    setMiercoles(scheduleTime)
                }
                break;
            case "thursday":
                if (jueves != scheduleTime) {
                    setJueves(scheduleTime)
                }
                break;
            case "friday":
                if (viernes != scheduleTime) {
                    setViernes(scheduleTime)
                }
                break;
            case "saturday":
                if (sabado != scheduleTime) {
                    setSabado(scheduleTime)
                }
            break;
        }
    }, [scheduleTime])
    function handleLoad(e) {
        let db = firebase.database();
        let ref = db.ref('teachers');
        let childRef = ref.child(teacherID);
        childRef.once('value').then(function (snapshot) {
            setInfo(snapshot.val());
        })
    }
    const handlesubmit = async e => {
        e.preventDefault();
        let db = firebase.database();
        let ref = db.ref(`/schools/${info.coderef}/school_subjects/`);
        let ref2 = db.ref(`/schools/${info.coderef}/groups/${grado}/${grupo}/${turno}`).child(title)

        let time_0 = marks.find(mark => mark.value === scheduleTime[0]).label
        let time_1 = marks.find(mark => mark.value === scheduleTime[1]).label

        const signature_key = await ref.push()
        ref2.update({
            code: signature_key.key
        })
        // console.log(signature_key)
        let body = {
            [signature_key.key]: {
                key: signature_key.key,
                nombre: title,
                picture: Avatar,
                description: about,
                schedule: {
                    day: scheduleDay,
                    time: `${time_0} - ${time_1}`
                },
                turn:turno,
                grade:grado,
                group:grupo
            }
        };

        await ref.update(body).then(()=>{
            setModalmsg("Materia agregada")
            setModalLink('/teacher/materias')
        })
        .catch((reason)=>{
            setModalmsg('Inténtalo de nuevo en unos minutos')
            setModalLink('/teacher/materias')
        })
        // ,
        // (error) => {
        //     if(error) {
        //         alert('Algo ha salido mal');
        //     }
        //     else{
        //         alert('Materia agregada correctamente');
        //         setTitle("")
        //         setAbout("")
        //     }
        // }
        handleLoad(e);
    }
    const uploadSubjectLogo = async (e) => {
        e.preventDefault();

        const resource = e.target.files[0]
        const fileIsValid = await checkFile( resource );
        if ( fileIsValid ) {
            seterrorMsg({...errorMsg, doc: false });
            const resource_uri = URL.createObjectURL(resource)
            setAvatar(resource_uri)
            let storage = firebase.storage().ref();
            let upload = storage.child(`schools/${info.coderef}/signatures/${resource.name}`)
            await upload.put(resource).then(snap => {
                const { state } = snap
                if (state === "success") {
                } 
                else {
                }
            })
            const downloadLink = await upload.getDownloadURL()
            const profileImg = document.getElementById("imgsubject")
            profileImg.src = downloadLink;
            setAvatar(downloadLink)
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos validos: png, jpg y jpeg' });
        }
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={e => handlesubmit(e)} onLoad={(e) => handleLoad(e)}>
                <Grid container className="alumInfo_grid">
                    <Grid item xs={12} sm={12} >
                        <Box pb={3}>
                            <PageHeader
                                title="Detalles de la materia"
                                text="Define detalles de la asignatura."
                                btn_back="/teacher/materias"
                                btn_action={true}
                                btn_action_text="Guardar"
                                btn_action_to_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="Form_add-content">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box className="Form_add-profile-img">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <div className="kipperBox_content-upload" >
                                        <input type="file" id="ProfileImage" className="fileUpload" name="fileUpload" onChange={ e => { uploadSubjectLogo( e ); } }  />
                                        <label for="ProfileImage" className="pointer">
                                            { Avatar 
                                                ? <img alt="" className="previw_img rounded" id={'AvatarIMG'} />
                                                : <img src={file} alt="" className="profileImg rounded" />
                                            }
                                        </label>
                                    {/* <img src="" alt="" className="previw_img d-none" id={'AvatarIMG'} /> */}
                                    { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                    </div><br/>
                                    { error }
                                </Grid>
                            </Box>
                            <Box className="Form_add-profile-img">
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <div className="kipperBox_content-upload">
                                        {/* <img src={ file } alt=""/> */}
                                    </div>
                                </Grid>
                            </Box>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid xs={12}>
                                        <Box p={2}>
                                            <InputLabel shrink id="turno">
                                                Título
                                            </InputLabel>
                                            <TextField value={title} onChange={e => setTitle(e.target.value)} size="small" fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="turno">
                                                Turno
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                fullWidth
                                                onChange={e => setTurno(e.target.value)}
                                                value={turno}
                                            >
                                                <MenuItem ><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Matutino">Matutino</MenuItem>
                                                <MenuItem value="Vespertino">Vespertino</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="grado">
                                                Grado
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                fullWidth
                                                onChange={e => setGrado(e.target.value)}
                                                value={grado}
                                            >
                                                <MenuItem ><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Primero">Primero</MenuItem>
                                                <MenuItem value="Segundo">Segundo</MenuItem>
                                                <MenuItem value="Tercero">Tercero</MenuItem>
                                                <MenuItem value="Cuarto">Cuarto</MenuItem>
                                                <MenuItem value="Quinto">Quinto</MenuItem>
                                                <MenuItem value="Sexto">Sexto</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="grupo">
                                                Grupo
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                fullWidth
                                                onChange={e => setGrupo(e.target.value)}
                                                value={grupo}
                                            >
                                                <MenuItem ><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                                <MenuItem value="D">D</MenuItem>
                                                <MenuItem value="E">E</MenuItem>
                                                <MenuItem value="F">F</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6">
                                                Acerca de la materia
                                            </Typography>
                                        </Box>
                                        <Box p={2} >
                                            <TextareaAutosize value={about} onChange={e => setAbout(e.target.value)} style={{ width: '100%', border: '1px solid grey', padding: '10px' }} aria-label="minimum height" rowsMin={10} placeholder="Descripción aquí" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6">
                                                Día
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={2}>
                                            <RadioGroup value={scheduleDay} onChange={e => setScheduleDay(e.target.value)} row  >
                                                <FormControlLabel value="monday" control={<Radio color="primary" />} label="Lunes" />
                                                <FormControlLabel value="tuesday" control={<Radio color="primary" />} label="Martes" />
                                                <FormControlLabel value="wednesday" control={<Radio color="primary" />} label="Miercoles" />
                                                <FormControlLabel value="thursday" control={<Radio color="primary" />} label="Jueves" />
                                                <FormControlLabel value="friday" control={<Radio color="primary" />} label="Viernes" />
                                                <FormControlLabel value="saturday" control={<Radio color="primary" />} label="Sabado" />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="subtitle1">
                                                Horario de clase
                                            </Typography>
                                            <Box px={3}>
                                                <Slider
                                                    value={scheduleTime}
                                                    marks={marks}
                                                    step={10}
                                                    // onChange={ e => setScheduleTime( e.target.value ) }
                                                    onChange={(event, newValue) => setScheduleTime(newValue)}
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="subtitle1">
                                                Temario
                                            </Typography>
                                            <Box p={2}>
                                                <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={e => setDocOpcional((e.target.files[0]))} />
                                                <label htmlFor="uploadFile" className="kipper_txt-color pointer">
                                                    <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                        <img src={upload} alt="" />
                                                        <p className="kipper_txt-color">Selecciona archivo</p>
                                                    </Grid>
                                                </label>
                                                <Typography className="" variant="body1" display="block" gutterBottom>
                                                    <b>*Los documentos deben ser de tipo JPG, JPEG o PNG.</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}

export default MateriasAgregar