import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../functions/Auth'
import * as firebase from 'firebase';

//material-UI
import { Grid, Box, Typography, Link, Button } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'

//Assets
import imgChild from '../../../Assets/Img/General/placeholder_boy.png';
import imgTutor from '../../../Assets/Img/General/placeholder_man.png';
import imgTeacher from '../../../Assets/Img/General/placeholder_teacher-m.png';
import imgGroup from '../../../Assets/Img/General/Academy.png';

//Componentes
import Chatbody from '../ChatBody';
import {CreateConversation} from '../ChatFunctions';
import { useHistory } from 'react-router';
import style from '../../../Assets/Styles/chat.module.css'
import NavTeacherPhone from '../../../Pages/Maestros/NavTeacherPhone/NavTeacherPhone';

export default function SeleccionarChat() {
    //Obtener informacion del context
    const { info, infoSchool, currentUser } = useContext(AuthContext);
    const history = useHistory();
    //Estados del componente
    const [ selection, setSelection ] = useState(null);
    /* Estados de condicion del render */
    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);
    const [ step3, setStep3 ] = useState(false);
    /* Estados de la conversacion */
    const [ users, setUsers ] = useState(null);
    const [ conversation, setConversation ] = useState(null);
    const [ conversationType, setConversationType ] = useState(null);
    const [ sender, setSender ] = useState(null);
    const [ messages, setMessages ] = useState([])
    const [user, setuser] = useState(null)
    const [ chatRefres, setChatRefresh ] = useState(0);

    const [ arrayGroup, setArrayGroup]=useState([]);
    const [ arrayChild, setArrayChlds]=useState([]);
    const [going, setGoing]=useState(null)

    //firebase  
    /* Hacemos referencias a firebase para el llamado de la informacion */
    const Db = firebase.database();
    const refTutor = Db.ref('tutors/');
    const refGroup = Db.ref(`schools/${infoSchool?.school}/groups`);
    const refSchool = Db.ref(`schools/${infoSchool?.school}`)
    const refChild = Db.ref('childs/');
    const chatRef = Db.ref('chats/');

    /*Esta funcon se encarga de invertir los estados de step1 y step2
    para su correcto render dependiendo de la situacion y las necesidades
    del mismo usuario al elegir con quien desea conversar*/
    const handleStep1 = (select) =>{
        setStep1(!step1)
        setStep2(!step2)
        setArrayChlds(null)
        getGroup(select);
    }
    /*Esta funcion se encarga de invertir lo estados de step1 y step2, 
    ademas limpia el etado de users para que al cambiar la vista el usuario no vea la lista
    de usuario anteriormente seleccionada*/
    const handleStep2 = () =>{
        setStep2(!step2)
        setStep1(!step1)
        setUsers(null)
        setConversation(null)
        setConversationType(null)
    }
    const handleConversation = (id, data,user) =>{
        setuser(user)
        setStep2(!step2)
        setStep3(!step3)
        setConversation(id)
        getInformation(id, false, data)
    }
    const handleBack = () =>{
        setStep1(true)
        setStep3(false);
        setStep2(false);
        setConversation(null)
    }
    /* Este useEffect carga al entrar al componente y busca que exista algo dentro del estado selection,
    si el estado selection tuviese algun dato, este llama a la funcion getUsers */
    // useEffect(() => {
    //     if(selection) {
    //         getUsers();
    //     }
        /* Aqui se le implanta una variale al useEffect, para que este se aplique cada que esta variable tenga un cambio */
    // }, [selection])
    
    async function getUsers2(){
        /*
            selection
            2 = Tutors
            3 = Childs
            4 = Groups
        */
        var listContacts = [];
        let childList = [];
        let tutorList = [];
        /* Se condiciona la accion de la funcion, dependiendo de el estado selection */
        switch ( selection ) {
            case 2:
                var infoGrupo = await refGroup.once('value')
                infoGrupo.forEach( grupo => {
                    if ( grupo.val()?.teacher?.teacher_key == currentUser ) {
                        Object.entries(grupo.val().student).forEach( student => {
                            childList.push( student[0] )
                        })
                    }
                })
                var infoChildT = await refChild.once('value')
                infoChildT.forEach(child => {
                    if ( child.val().status == 'active' ) {
                        childList.forEach( validChild => {
                            if ( validChild == child.val().id ) {
                                if ( child.val()?.tutors?.tutor_1 ) {
                                    tutorList.push( child.val().tutors.tutor_1 )
                                }
                                if ( child.val()?.tutors?.tutor_2 ) {
                                    tutorList.push( child.val().tutors.tutor_1 )
                                }
                                if ( child.val()?.tutors?.tutor_3 ) {
                                    tutorList.push( child.val().tutors.tutor_1 )
                                }
                                if ( child.val()?.tutors?.tutor_4 ) {
                                    tutorList.push( child.val().tutors.tutor_1 )
                                }
                            }
                        })     
                    }
                })
                var infoTutores = await refTutor.once('value')
                infoTutores.forEach( tutor => {
                    tutorList.forEach( validTutor => {
                        if ( validTutor == tutor.val().id ) {
                            
                            listContacts.push(
                                <Itemlist 
                                user={tutor.val()} 
                                    img = {tutor.val().avatar ? tutor.val().avatar : imgChild}
                                    name = {`${tutor.val().name} ${tutor.val().last_name ? tutor.val().last_name : tutor.val().lastname}`}
                                    info = {` `}
                                    btnTo = { handleConversation }
                                    information = {tutor.key}
                                    data = {tutor.val()}
                                />
                            )
                        }
                    })     
                })
                if ( listContacts.length > 0 ) {
                    setUsers(listContacts)
                }
                else{
                    setUsers( false )
                }
                setConversationType('tutor')
                break;
            case 3:
                var infoGrupoChild = await refGroup.once('value')
                infoGrupoChild.forEach( grupo => {
                    if ( grupo.val()?.teacher?.teacher_key == currentUser ) {
                        Object.entries(grupo.val().student).forEach( student => {
                            childList.push( student[0] )
                        })
                    }
                })
                var infoChild = await refChild.once('value')
                infoChild.forEach(child => {
                    if ( child.val().status == 'active' ) {
                        childList.forEach( validChild => {
                            if ( validChild == child.val().id ) {
                                listContacts.push(
                                    <Itemlist 
                                    user={child.val()} 
                                        img = {child.val().avatar ? child.val().avatar : imgChild}
                                        name = {`${child.val().name} ${child.val().last_name ? child.val().last_name : child.val().lastname}`}
                                        info = {`${child.val()?.details?.grade} ${child.val()?.details?.group} ${child.val()?.details?.turn}`}
                                        btnTo = { handleConversation }
                                        information = {child.key}
                                        data = {child.val()}
                                    />
                                )
                            }
                        })     
                    }
                })
                if ( listContacts.length > 0 ) {
                    setUsers(listContacts)
                }
                else{
                    setUsers( false )
                }
                setConversationType('child')
                break;
            case 4:
                var info = await chatRef.once('value')
                info.forEach(item => {
                    if( item.val()?.teacher == currentUser && item.val()?.status?.IsGroup == 1 ) {
                        listContacts.push(
                            <Itemlist 
                            user={item.val()} 
                                img = {item.val().avatar ? item.val().avatar : imgChild}
                                name = {`Grupo ${item.val()?.group?.grade} ${item.val()?.group?.group} ${item.val()?.group?.turn} `}
                                info = {``}
                                btnTo = { handleConversation }
                                information = {item.key}
                                data = {item.val()}
                            />
                        )
                    }
                })
                if ( listContacts.length > 0 ) {
                    setUsers(listContacts)
                }
                else{
                    setUsers( false )
                }
                setConversationType('groups')
                break;
            default:
                break;
        }
    } 

    useEffect(() => {
        getInformation(false, sender)
    }, [chatRefres])

    async function getInformation (id, chat_reload, data) {
        var find = false;
        console.log('ID: ', id)
        if(id) {
            var typeid = id.split("_")
            var typetutor = id.slice(0,5)

            var infos = await chatRef.once('value')
            var valor1 = infos.val();
            infos.forEach(item =>{
                var valor = item.val();
                if((valor[typeid[0]]?.key == id|| valor[typetutor]?.key == id) && valor[info.type]?.key == currentUser )
                {
                    setSender(item.key)
                    setMessages(valor['messages'])
                    find = true
                }                   
            })
            if(!find && data) {
                console.log('DATA: ', data)
                var key_receiver = data.id ? data.id : data.key;
                var type;
                console.log('key_receiver: ', key_receiver)
                if(data?.id) {
                    type = data?.id.split("_");
                }
                else {
                    type = data?.key.split("_")
                }
                var tutorcheck;
                if(type.length == 2) {
                    tutorcheck = type[0]
                }else {
                    tutorcheck = "tutor"
                }
                var arraySender = {
                    key: infoSchool.teacher_key,
                    avatar: infoSchool.avatar,
                    name: `${infoSchool.name} ${infoSchool.lastname}`,
                    type: info.type
                }
                var arrayReceiver = {
                    key: key_receiver,
                    avatar: data.avatar,
                    name: `${data.name} ${data.lastname ?data.lastname :data.last_name}`,
                    type: tutorcheck
                }
                createChat(arraySender, arrayReceiver);
            }
        }
        else if(chat_reload) {
            var infos = await chatRef.child(chat_reload ? chat_reload : conversation).once('value')
            setMessages(infos.val()?.messages)
        }
    }

    async function createChat (sender, receiver) 
    {
        let db = firebase.database();
        let CHATS_REF = db.ref().child('/chats/')
        var arrayChat = {
            lastUpdate: Date.now()
        };
        arrayChat[sender.type] = {
            avatar: sender.avatar,
            key: sender.key,
            name: sender.name
        }
        arrayChat[receiver.type] = {
            avatar: receiver.avatar,
            key: receiver.key,
            name: receiver.name
        }
        let push_ = await CHATS_REF.push(arrayChat)
        setSender(push_.key)
        getInformation(false, push_.key)
    }

    async function getGroup (state) {
        var consInfo = await refGroup.once('value')
        var listGroups = []
        Object.entries(consInfo.val()).forEach( e =>{
            if(infoSchool.groups[e[0]])
            {
                listGroups.push(
                    <Itemlist img={ imgGroup } name={`${e[1].level} ${e[1].grade} ${e[1].group} ${e[1].turn}`} btnTo={ handleChildbyGroup } information={ e[0] } data={e} state={state} textButton={"ver alumnos"}/>
                )
            }
        })
        if ( listGroups.length > 0 ) {
            setArrayGroup(listGroups)
        }
        else{
            setArrayGroup( false )
        }
    }

    async function getChilds (info, data, state)
    {
        var listChilds = []
        var childs = data[1]?.student ?Object.entries(data[1]?.student) :null
        if(childs && state)
        {
            for (const child of childs )
            {
                var infoChild = await refChild.child(`/${child[0]}`).once('value');
                if(infoChild.exists() && infoChild.val().status == "active"){
                    switch (state) 
                    {
                        case 2:
                            listChilds.push(
                                <Itemlist img={ infoChild.val().avatar ?infoChild.val().avatar :imgChild } user={infoChild.val()} name={`${infoChild.val().name} ${infoChild.val().last_name}`} btnTo={ handleTutorbyChild }  information={infoChild.key} data={infoChild.val()} textButton={"Ver tutores"}/>
                            )
                        break;
                        case 3:
                            listChilds.push(
                                <Itemlist img={ infoChild.val().avatar ?infoChild.val().avatar :imgChild  } user={infoChild.val()} name={`${infoChild.val().name} ${infoChild.val().last_name}`} btnTo={ handleInitChat }information={infoChild.key} data={infoChild.val()} textButton={"Iniciar Chat"}/>
                            )
                        break;
                        default:
                            console.log(infoChild.val(), "default")
                        break;
                    }
                }
            }
            
            setArrayChlds(listChilds.length > 0 ?listChilds :<span>Este grupo no tiene alumnos</span>)
        }
        else
        {
            setArrayChlds(<span>Este grupo no tiene alumnos</span>)
        }
    }

    async function getTutors(data)
    {
        var listTutors = [];
        var tutors = data?.tutors ?Object.entries(data?.tutors) :null
        if(tutors)
        {
            for( const tutor of tutors)
            {
            var infoTutor = await refTutor.child(tutor[1]).once('value')
            if(infoTutor.exists())
            {
                listTutors.push(
                    <Itemlist img={ infoTutor.val().avatar ?infoTutor.val().avatar :imgTutor  } user={infoTutor.val()} name={`${infoTutor.val().name} ${infoTutor.val().last_name}`} btnTo={ handleInitChat } info={ infoTutor.val().contact.phone  } information={infoTutor.key} data={infoTutor.val()} textButton={"Iniciar Chat"}/>
                    )
                }
            }
        }
        setArrayChlds(listTutors.length > 0 ?listTutors :<span>Este alumno no tiene tutores o delegados</span>)
    }

    async function selectSchool(select)
    {
        setArrayGroup(null)
        setArrayChlds(null)
        var dataSchool = await refSchool.once('value');
        var listSchool = [];
        listSchool.push(
            <Itemlist img={ dataSchool.val().avatar ?dataSchool.val().avatar :imgChild  } user={dataSchool.val()} name={`${dataSchool.val().name}`} btnTo={ handleInitChat } info={ dataSchool.val().contact.email  } information={dataSchool.key} data={dataSchool.val()} textButton={"Iniciar Chat"}/>
        )

        setArrayChlds(listSchool.length > 0 ?listSchool :<span>Este usuario no tiene escuela</span>)
        
        setStep1(false)
        setStep2(true)
    }

    const handleChildbyGroup = (info, data, state, user) =>{
        setuser(user)
        setArrayGroup(null)
        getChilds(info, data, state)
    }

    const handleTutorbyChild = (info, data, state, user) =>{
        setuser(user)
        getTutors(data)
    }
    const handleInitChat = (info, data, state, user) =>{
        setMessages(null)
        setuser(user)
        getInformation(info, false, data)
        setStep2(false)
        setStep3(true)
    }

    return (
        <Grid xs={12}>
            {/* <NavTeacherPhone /> */}
            { step3
                && <Chatbody information={sender} messages={messages} type={conversationType} userType="school" user={user} functionBack={handleBack} valrefresh={chatRefres} reload={setChatRefresh}/>
            }
            {/* Se condiciona el render de esta parte dependiendo si existe o si es true la variable step1 */}
            { step1 &&                     
            <Box className="kipperBox_content" boxShadow={3}>
                
                <Grid container xs={12} md={12} >
                    <Grid container >
                        <Grid container className="end_chat_contactList" direction="row"  alignItems="center">
                            <Box display="flex" p={3} alignItems>
                                <Button onClick={history.goBack} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid className="clickeable_class conversacionesMaestro__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => {handleStep1(2); setSelection(2)}}>
                        <Box display="flex" className="clickeable_class" px={2} py={4}  >
                            <Box ml={2}>
                                <Typography variant="h4" className="kipper_txt-grey" >
                                    Tutor
                                </Typography>
                            </Box>
                        </Box>
                        <Box px={2} py={4} >
                            <ArrowForwardIos />
                        </Box>
                    </Grid>

                    <Grid className="clickeable_class conversacionesMaestro__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => {handleStep1(3); setSelection(3)}}  >
                        <Box display="flex" className="clickeable_class" px={2} py={4} >
                            <Box ml={2}>
                                <Typography variant="h4" className="kipper_txt-grey" >
                                    Alumno
                                </Typography>
                            </Box>
                        </Box>
                        <Box px={2} py={4} >
                            <ArrowForwardIos />
                        </Box>
                    </Grid>

                    <Grid className="clickeable_class conversacionesMaestro__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => { selectSchool(4) }}  >
                        <Box display="flex" className="clickeable_class" px={2} py={4} >
                            <Box ml={2}>
                                <Typography variant="h4" className="kipper_txt-grey" >
                                    Escuela
                                </Typography>
                            </Box>
                        </Box>
                        <Box px={2} py={4} >
                            <ArrowForwardIos />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
            }
            {/* Se condiciona el render de esta seccion dependiendo de la variable step2 */}
            { step2 
                && 
                <Box className="kipperBox_content" boxShadow={3}>
                <Grid container >
                    <Grid container className="end_chat_contactList" direction="row"  alignItems="center">
                        <Box display="flex" p={3}>
                            <Button onClick={e => handleStep2()} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={ 12 } mt={1} mb={0}>
                        {
                            arrayGroup
                            ?arrayGroup
                            :null
                        }
                        {
                            arrayChild
                            ?arrayChild
                            :null
                        }

                    </Grid>
                </Grid>
            </Box>
            }
           
        
        </Grid>
    )
}
/* Este es un componente usado para enlistar todos los usuarios */
const Itemlist = ({ img, name, info, btnTo, information, data, textButton, state, user }) =>{
    return(
        <Box p={3}>
            <Grid className="clickeable" container direction="row" justify="space-around" alignItems="center" spacing={2}>
                <Grid item xs={2}>
                    <Box>
                        <img src={ img } alt="" className={style.avatar_chat}  />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <span className="Kipper_title-listChat">
                        { name } 
                    </span>
                    <span className="Kipper_email-list">
                        { info }
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <Button className="kipper_button" variant="outlined" onClick={e => btnTo(information, data, state, user)} fullWidth>{textButton}</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
