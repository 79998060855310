import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user2 from './../../Assets/Img/General/placeholder_teacher-f.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// import Pagination from '@material-ui/lab/Pagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import { Grid, Box, Typography, Divider } from '@material-ui/core';
import * as firebase from 'firebase';

export default function ChildListitem({listchilds, assistance, metadata, statuslist}) {
// listchilds es donde se obtiene el codigo del niño
// assistance es donde se ontiene el codigo de la lista de asistencia

// variables del context 
    const { currentUser, infoSchool } = useContext(AuthContext);

// obtener parametros por medio de la url para saber grupo y materia de la asistencia
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/asistencias/:grupo/:materia');
    const grupoID = match.params.grupo;
    const subjectID = match.params.materia;


// hooks de este componente
    const [info, setinfo] = useState([])
    const [asistenciavl, setasistenciavl] = useState(metadata.assistance)

    useEffect(() => {
        GetInfo()
        // console.log(metadata.assistance);
        // console.log(listchilds);
        // console.log(assistance);
        // console.log(info)
    }, [])

/* utilizando la variable listchilds que obtenemos desde el inicio del componente a traves de herencias 
buscamos la informacion del niño para mostrarla en el componente  */
    function GetInfo(){
        let db = firebase.database();
        let ref = db.ref(`childs/${listchilds}`);
        ref.once('value').then( snapshot => {
            let data = snapshot.val()
            setinfo(data)
        })
    }
/* funcion donde obtendremos  */
    function Assists (e){
        // console.log(e)
        setasistenciavl(e)
        let db = firebase.database();
        let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance/${assistance}/students/${listchilds}`);
        ref.update({ assistance: e }, 
            function(error) {
                if (error) { console.log(error) } 
            }
        );

    }

/* return del render */  
    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
        {
            listchilds ? 
                    <Grid container>
                        <Grid item xs={1}>
                            <Box borderRadius="50%">
                                {
                                    info?.avatar 
                                    ?<img src={ info.avatar } alt="" width="60px" height="60px" className="rounded" />
                                    :<img src={ user3 } alt="" width="60px" height="60px" className="rounded" />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <span className="Kipper_title-black capitalize">
                                {info?.name} {info?.last_name}
                            </span> <br/> 
                            <span className="capitalize">
                                Email
                            </span>
                        </Grid>
                        <Grid item xs={3}>
                        <Grid container direction="row" justify="space-between" alignItems="center">

                            <span className="notificacion_title">
                                Presente
                            </span>
                            <FormControl component="fieldset">
                                {/* <RadioGroup row aria-label="position" name="position" id={'RadioAssistance'} value={asistenciavl}  onChange={e => Assists(e.target.value)}>
                                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                                    </RadioGroup> */}
                                    {statuslist
                                        ?<RadioGroup row aria-label="position" name="position" id={'RadioAssistance'} value={asistenciavl}  onChange={e => Assists(e.target.value)}>
                                        <FormControlLabel disabled  value="Yes" control={<Radio color="primary" />} label="Si" />
                                        <FormControlLabel disabled value="No" control={<Radio color="primary" />} label="No" />
                                        </RadioGroup>
    
                                        :<RadioGroup row aria-label="position" name="position" id={'RadioAssistance'} value={asistenciavl}  onChange={e => Assists(e.target.value)}>
                                        <FormControlLabel  value="Yes" control={<Radio color="primary" />} label="Si" />
                                        <FormControlLabel  value="No" control={<Radio color="primary" />} label="No" />
                                        </RadioGroup>
                                    }
                            </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                : null
                }
        </Grid>
    )

}
