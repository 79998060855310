import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Coponent
import ModalShow from '../Components/Global/Modal';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
// firebases
import * as firebase from 'firebase';

const SingIn = () => {

    
    const history   = useHistory();
    const auth = firebase.auth();
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [codeexist, setCodeExist] = useState(false);
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    const [Info, setInfo] = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const checkcode = () =>{
        var ref = firebase.database().ref('schools')
        ref.once('value').then( snapshot => {
            const info = snapshot.val();
            const arrayinfo = Object.keys(info);
            snapshot.forEach(e =>{
                let info = e.val()
                let keys = e.key
                if(keys == code){
                    setInfo(e.val())
                    SaveElement(e.val());
                }
            })
        });
    }
    
    const handleSubmit = ( e ) => {
        e.preventDefault();
        checkcode();
        if( code === '' ){
            setError('code');
        }else if( email === "" ){
            setError('email');
        }else if( password === "" ){
            setError('Ingrese una contraseña');
        }else {
            setError('');

        }
    }

    const SaveElement = (code) => {

        // let db = firebase.database();
        // let ref = db.ref('test/schools/school1598382555312');
        // ref.once('value').then(function(snapshot) {

        //     console.log( snapshot );
            
            // auth.createUserWithEmailAndPassword( email, password )
            // .then(() =>{
            //     var user= firebase.auth().currentUser;
            //     firebase.database().ref('/accounts').child( user.uid ).update( { code: code, type: 'school' } );
            //     setModalmsg('Escuela agregada.')
            //     setModalLink('/login');
            //     ModalOpen()
            // })
            // .catch(e => {
            //     setError(e.message);
            // });
        // })

    }
    
    useEffect(() => {
        // firebase.auth().signInAnonymously()
        firebase.auth().signOut()
        var ref = firebase.database().ref('/schools')
        ref.once('value').then( snapshot => {
        });

    }, [])
    
    return(
        <form onSubmit={ e => handleSubmit( e ) }>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Box>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box py={2}>
                        <Typography className="kipper_title" variant="h5">Registro para escuelas</Typography>
                        <Typography className="" variant="body1" > 
                            Introduce código de referencia, correo electrónico y contraseña
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box pb={1}>
                        <TextField type="text" variant="outlined" placeholder="Código de registro" fullWidth required value={ code } onChange={e => setCode(e.target.value)} />
                    </Box>
                    <Box pb={1}>
                        <TextField type="text" variant="outlined" placeholder="Correo eletrónico" fullWidth required value={ email } onChange={e => setEmail(e.target.value)} />
                    </Box>
                    <Box pb={1}>
                        <TextField type="password" variant="outlined" placeholder="Contraseña" fullWidth required value={ password } onChange={e => setPassword(e.target.value)} />
                    </Box>
                    {
                        error ?
                            <Alert severity="error">Algunos campos son incorrectos</Alert> : null
                    }
                </Grid>
                <Grid xs={12}>
                    <Box py={1}>
                        <Button type="submit" className="kipper_button" variant="contained" fullWidth >Enviar</Button>
                    </Box>
                </Grid>
            </Box>
        </form>
    );
}
    

export default SingIn