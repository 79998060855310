import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
// MaterialUI
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// Imagenes
import home from './../../Assets/Img/Layout/Icon_Home.png';
import sticky from './../../Assets/Img/Layout/sticky-note.png';
import chat from './../../Assets/Img/Layout/chat_bubble-grey.png';
import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import evento from './../../Assets/Img/Layout/Icon_Evento.png';
import sede from './../../Assets/Img/Layout/Icon_Sede.png';
import materias from './../../Assets/Img/Layout/Icon_list.png';
import { AuthContext } from '../functions/Auth';
import { ListItemText  } from '@material-ui/core';

const BottomNavTeacher = () => {

    const { handlelogout, currentUser } = useContext( AuthContext );

    return (
        <div className="bottomTeacherNav">
            {/* <Link to="/" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainer">
                    <img src={ home } />
                    <h4 >Inicio</h4>
                </div>

            </Link>

            <Link to="/teacher/materias/select/" className="flex">
                <div  id="botomNav_item2" className="botomNav_itemContainer">
                    <img src={ materias } />  
                    <h4 >Materias</h4>
                </div>

            </Link>

            <Link to="/teacher/citas" className="flex">
                <div  id="botomNav_item3" className="botomNav_itemContainer">
                    <img src={ evento } />
                    <h4 >Citas</h4>
                </div>

            </Link>

            <Link to="/teacher/chat" className="flex">
                <div  id="botomNav_item4" className="botomNav_itemContainer">
                    <img src={ chat } />
                    <h4 >Chat</h4>
                </div>

            </Link>

            <Link to="/teacher/chat" className="flex">
                <div  id="botomNav_item4" className="botomNav_itemContainer">
                    <img src={ chat } />
                    <h4 >Chat</h4>
                </div>

            </Link> */}
            

            <BottomNavigation className="AdminNavbar" style={{ height: '10vh', backgroundColor: '#EFF4F8' }}>
                <Link to="/" className="flex">
                    <BottomNavigationAction 
                        label="Inicios" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ home } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />
                </Link>
                <Link to="/teacher/citas/tutores" className="flex">
                    <BottomNavigationAction 
                        label="Solicitar Citas" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ sticky } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />
                </Link> 
                <Link to="/teacher/materias/select/" className="flex">
                    <BottomNavigationAction 
                        label="Materias" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ materias } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />

                </Link>
                <Link to="/teacher/evaluaciones/group" className="flex">
                    <BottomNavigationAction 
                        label="Evaluaciones" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <CheckCircleOutlineIcon className="sidebar_icon" style={{color: "grey", width: "25px"}}/>
                        } 
                    />
                </Link> 
                <Link to="/teacher/citas" className="flex">
                    <BottomNavigationAction 
                        label="Citas" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ evento } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        } 
                    /> 
                        
                </Link>
                <Link to="/teacher/profile" className="flex">
                    <BottomNavigationAction 
                        label="Perfil" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ sede } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        } 
                    />
                </Link> 
                <Link to="/teacher/chat" className="flex">
                    <BottomNavigationAction 
                        label="Chat" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        } 
                    />

                </Link>
                {
                    currentUser &&
                        <Link to={`/admin/horarios/profesores/detalles/info/${currentUser}`} className="flex">
                        <BottomNavigationAction 
                            label="Horario" 
                            value="recents"
                            style={{ outline: 'none' }}
                            icon={ 
                                <AccessTimeIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                            }  
                        />

                    </Link>
                }
                
                <Link className="flex">
                    <BottomNavigationAction 
                        label="Salir" 
                        value="recents"
                        style={{ outline: 'none' }}
                        onClick={ () => handlelogout() }
                        icon={ 
                            <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        } 
                    />
                </Link> 
            </BottomNavigation>
        </div>



    )
}

export default BottomNavTeacher
