import React, {useState, useEffect, createContext} from 'react';
import app from './credentials';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('currentUser'));
  const [userType, setuserType] = useState(localStorage.getItem('userType'));
  const [info, setInfo] = useState({coderef: localStorage.getItem('currentUser'), type: localStorage.getItem('userType')})
  const [notif, setNotif] = useState(false)
  const [events, setEvents] = useState(null)
  const [infoSchool, setInfoSchool] = useState(null)
  const [pending, setPending] = useState(false);
  const [sesion, setSession] = useState(false);

  const [gradeChild,setGradeChild] = useState(null);


  useEffect(() => {
    if(currentUser){
      setSession(true)
    }
    setPending(true)
      try{
            if( userType === "school" ) {
              handleinfo(currentUser, userType)
              handlenotif( 'school', currentUser )
              setuserType('school')
            }
            if( userType === "teacher" ) {
                handleinfo(currentUser, userType)
                setuserType('teacher')
              }
            if( userType === "tutor" ) {
              handleinfo(currentUser, userType)
              setuserType('tutor')
            }
            if( userType === "child" ) {
              handleinfo(currentUser, userType)
              setuserType( 'child', currentUser )
            }
            if( userType === "Admin" ) {
              setInfo({type: 'Admin'})
              handleinfo(userType)
            }
      }
      catch(error){ 
        console.log(error)
      }
      finally{
        setPending(false)
      }
  }, [currentUser,sesion]);

  const funcTeacher =(itemInfo,_viewedList,uid,listItem)=>{
    let flag = false;
    
    if(itemInfo.type == "evento"){
      if ( _viewedList ) {
        Object.entries( _viewedList ).forEach( ([key, val]) => {
          if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
            flag = true;
            listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
          }
        })
        if(!flag){
          Object.entries( _viewedList ).forEach( ([key, val]) => {
            if ( [ key ] == currentUser && val ) {//Tenia tambien [ key ] == currentUser &&
              flag = true;
              listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
            }
          })
        }
        if(!flag)
          listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
      } else {
        listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
      }
    }else if(itemInfo.type == "comunicado"){
      if ( _viewedList ) {
        Object.entries( _viewedList ).forEach( ([key, val]) => {
          if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
            flag = true;
            listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
          }
        })
        if(!flag){
          Object.entries( _viewedList ).forEach( ([key, val]) => {
            if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
              flag = true;
              listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
            }
          })
        }
        if(!flag)
          listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
      } else {
        listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
      }
    }
  }

  const funcChild =(info,_viewedList,uid,listItem)=>{
    let flag = false;

    if(info.type == "evento"){
      if ( _viewedList ) {
        Object.entries( _viewedList ).forEach( ([key, val]) => {
          if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
            flag = true;
            listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
          }
        })
        if(!flag)
          listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
      } else {
        listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
      }
    }else if(info.type == "comunicado"){
      if ( _viewedList ) {
        Object.entries( _viewedList ).forEach( ([key, val]) => {
          if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
            flag = true;
            listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
          }
        })
        if(!flag)
          listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
      } else {
        listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
      }
    } 
    return listItem;
  }

  const handleinfo = (user, type) =>{
    if(user){
      var db = app.database();
      var ref2 = db.ref(`schools/${user}/`);
      let ref3 = db.ref(`teacher/${user}/`);
      let ref4 = db.ref(`childs/${user}/`);
      let ref5 = db.ref(`tutors/${user}/`);
      if( type == 'teacher' ){
        ref3.once('value').then((snapshot) => {
          if(snapshot.val()){
            let info = snapshot.val()
            setInfoSchool(snapshot.val())
            handlenotif( 'teacher', info?.school, info?.teacher_key, info?.groups )
            
          }
        })  
      }
      if( type == 'school' ){
        ref2.once('value').then((snapshot) => {
          if(snapshot.val()){
            let info = snapshot.val()
            setInfoSchool(snapshot.val())
          }
        })
      }
      if( type == 'tutor' ){
        ref5.once('value').then((snapshot) => {
          if(snapshot.val()){
            let info = snapshot.val()
            setInfoSchool(snapshot.val())
            handlenotif('tutor', info?.school, info?.id )
            
          }
        })
      }
      if( type == 'child' ){
        ref4.once('value').then((snapshot) => {
          if(snapshot.val()){
            let info = snapshot.val()
            setInfo(info)
            setInfoSchool( info.school )
            handlenotif( 'child', info?.school, info?.id )
            
          }
        })  
      }
      if(type == 'Admin'){
        setInfo({})
      }
    }
  }

  const handlenotif = async ( type, school, uid, groups ) =>{
    var db = app.database();
    
    if ( type === 'tutor' || type === 'delegado' ) {
      //-- Citas --
      //*Puede ver solicitudes de citas pendientes = 0
      //*Puede ver citas confirmadas = 1
      //*Puede ver peticiones de citas del maestro = 0 | 1
      let ref = db.ref(`comunicados`);
      let listItem = [];
      ref.once('value').then( snapshot => {
        snapshot.forEach( e => {
          let itemInfo = e.val();

          if(typeof school === 'undefined')
            school = localStorage.getItem("schoolID");
          else
            localStorage.setItem("schoolID", school);

              if ( itemInfo.status === "active" && itemInfo.school === school && ( itemInfo.receiver_event === 'group'|| itemInfo.receiver_event === 'tutor' || itemInfo.receiver_event === 'all' || itemInfo.receiver_event === 'appointment' || itemInfo.receiver_event === 'evento' || itemInfo.receiver_event === 'appointment_tutor') ) {
                let info = e.val()

                const _viewedList = itemInfo.viewed;
                let flag = false;
                let tutorChilds = '';

                if(localStorage.getItem("tutorChilds"))
                  tutorChilds = JSON.parse(localStorage.getItem("tutorChilds"));

                if(itemInfo.receiver_event != 'appointment_tutor' && itemInfo.receiver_event != 'appointment'){
                  if( (tutorChilds.length == 0 || tutorChilds == '') || itemInfo.receiver_event === 'all' ){//No tiene hijos
                    if(itemInfo.receiver_event === 'all'){//Solo eventos o comunicados globales o para todos los tutores en general
                      if(info.type == "evento"){
                        if ( _viewedList ) {
                          Object.entries( _viewedList ).forEach( ([key, val]) => {
                            if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser && O ESTO [ key ] == uid
                              flag = true;
                              listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                            }
                          })
                          if(!flag)
                            listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                        } else {
                          listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                        }
                      }
                      else if(info.type == "comunicado"){  
                        if ( _viewedList ) {
                          Object.entries( _viewedList ).forEach( ([key, val]) => {
                            if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                              flag=true;
                              listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                            }
                          })
                          if(!flag)
                            listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                        } else {
                          listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                        }
                      }
                    }
                  }else{//Tiene hijos
                    const checkObj = [];

                    Object.values(tutorChilds).forEach(child=>{
                      const count = checkObj.filter(obj => (obj.grade == "" && obj.group == "" && obj.turn == "") || (obj.grade == child.grade && obj.group == child.group && obj.turn == child.turn) || (obj.grade == child.grade && (obj.group == child.group || obj.group == "") && (obj.turn == child.turn || obj.turn == "")) || ((obj.grade == child.grade || obj.grade == "") && obj.group == child.group && (obj.turn == child.turn || obj.turn == "")) || ((obj.grade == child.grade || obj.grade == "") && (obj.group == child.group || obj.group == "") && obj.turn == child.turn ));

                      if( (itemInfo.details.grade == "" && itemInfo.details.group == "" && itemInfo.details.turn == "") || (itemInfo.details.grade == child.grade && itemInfo.details.group == child.group && itemInfo.details.turn == child.turn) || (itemInfo.details.grade == child.grade && (itemInfo.details.group == child.group || itemInfo.details.group == "") && (itemInfo.details.turn == child.turn || itemInfo.details.turn == "")) || ((itemInfo.details.grade == child.grade || itemInfo.details.grade == "") && itemInfo.details.group == child.group && (itemInfo.details.turn == child.turn || itemInfo.details.turn == "")) || ((itemInfo.details.grade == child.grade || itemInfo.details.grade == "") && (itemInfo.details.group == child.group || itemInfo.details.group == "") && itemInfo.details.turn == child.turn ) ){
                        if(count.length == 0){
                          if(info.type == "evento"){
                            checkObj.push({grade:itemInfo.details.grade,group:itemInfo.details.group,turn:itemInfo.details.turn});

                            if ( _viewedList ) {
                              Object.entries( _viewedList ).forEach( ([key, val]) => {
                                if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser && O ESTO [ key ] == uid
                                  flag = true;
                                  listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                                }
                              })
                              if(!flag)
                                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                            } else {
                              listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                            }
                          }
                          else if(info.type == "comunicado"){ 
                            checkObj.push({grade:itemInfo.details.grade,group:itemInfo.details.group,turn:itemInfo.details.turn});
 
                            if ( _viewedList ) {
                              Object.entries( _viewedList ).forEach( ([key, val]) => {
                                if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                                  flag=true;
                                  listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                                }
                              })
                              if(!flag)
                                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                            } else {
                              listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                            }
                          }
                        }
                      }
                    });
                  }
                }
                else if(info.type == "appointment"){
                  if(itemInfo.confirm == 1 && itemInfo.tutor.tutor === uid){//*Puede ver citas confirmadas
                    if ( _viewedList ) {
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                        }
                      })
                      if(!flag)
                        listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    } else {
                      listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    }
                  }else if(itemInfo.tutor.tutor === uid && itemInfo.receiver_event === 'appointment_tutor' && (itemInfo.confirm == 0 || itemInfo.confirm == 1)){//Puede ver peticiones de citas del maestro = 0 | 1
                    if ( _viewedList ) {
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                        }
                      })
                      if(!flag)
                        listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    } else {
                      listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    }
                  }else if(itemInfo.tutor.tutor === uid && itemInfo.receiver_event === 'appointment' && itemInfo.confirm == 1 ){//*Puede ver solicitudes de citas pendientes = 0
                    if ( _viewedList ) {
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                        }
                      })
                      if(!flag)
                        listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    } else {
                      listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#ff9326', description:`${info.details_event}`, id:`${info.id}`})
                    }
                  }
              }     
            }
          
          /*else if( itemInfo.status === "active" && itemInfo.confirm === 1 && itemInfo.type == "appointment" && itemInfo.tutor.tutor == uid ){
            listItem.push({title:`${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
            console.log("ENTRA ELSE IF");
          }*/
        })
        setEvents(listItem)
      })      
      
      // Notification
      let NotfRef = db.ref(`notifications`);
      let notfList = [];
      NotfRef.once('value').then( snapshot => {
        snapshot.forEach( notf => {
          let itemInfo = notf.val()
          if ( itemInfo.type === 'kiper' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'anybody' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'tutor' ) {
            let info = notf.val()
            if(info.userList){//REVISAR MAÑANA EN ENTREVISTA
              info.userList.forEach( users => {
                if ( users.key === uid ) {
                  notfList.push( itemInfo )
                }
              })
            }

          }
        })
        setNotif(notfList.reverse())
      }) 
    }

    if ( type === 'teacher' ) {
      // Calendar
      let ref = db.ref(`comunicados`);
      let listItem = [];
      const snapshotData = await ref.once('value');
      //*Puede ver solicitudes de citas pendientes = 0
      //*Puede ver citas confirmadas = 1
      //*Puede ver peticiones de citas del maestro = 0 | 1
      snapshotData.forEach( e => {
        let itemInfo = e.val();

        if(typeof school === 'undefined')
          school = localStorage.getItem("schoolID");
        else
          localStorage.setItem("schoolID", school);

        if(typeof uid === 'undefined')
          uid = localStorage.getItem("teacherID");
        else
          localStorage.setItem("teacherID", uid);
        /*if(groups){
          var arraygroups = Object.entries(groups);
          arraygroups.forEach(element => {
            
            if ( itemInfo.status === "active" && itemInfo.school === school && ( itemInfo.receiver_event === 'teacher' || itemInfo.receiver_event === 'all' || itemInfo.receiver_event === 'appointment' ) ) {
              const _viewedList = itemInfo.viewed;
              if(itemInfo.type == "evento"){
                if ( _viewedList ) {//Ya la vio
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name} `, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.type == "comunicado"){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.confirm === 1 && itemInfo.type == "appointment" && itemInfo.teacher.teacher == uid){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }   
            }else if(element[1].details.grade === itemInfo.details.grade && (element[1].details.group === itemInfo.details.group || element[1].details.group === itemInfo.details.gropu) && element[1].details.turn === itemInfo.details.turn){
              const _viewedList = itemInfo.viewed;  
              if(itemInfo.type == "evento"){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.type == "comunicado"){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.confirm === 1 && itemInfo.type == "appointment" && itemInfo.teacher.teacher == uid){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == currentUser && val == true ) {
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }
            }
          });
        }*/
        //SE QUITO UN ELSE QUE ESTABA UNIDO AL IF GROUPS Y ES TODO LO QUE ESTA ABAJO
          if ( itemInfo.status === "active" && itemInfo.school === school && ( itemInfo.receiver_event === 'teacher' || itemInfo.receiver_event === 'all' || itemInfo.receiver_event === 'appointment'|| itemInfo.receiver_event === 'appointment_tutor' || itemInfo.receiver_event === 'evento' || itemInfo.receiver_event === 'group' ) ) {
            const _viewedList = itemInfo.viewed;
            let flag = false;
            let teacherGroups = '';

            if(localStorage.getItem("teacherGroups"))
              teacherGroups = JSON.parse(localStorage.getItem("teacherGroups"));

            if(teacherGroups){//Si existe el item

              if(( itemInfo.receiver_event === 'all' )){//No tiene grupo asignado
                if(itemInfo.type == "evento"){
                  if ( _viewedList ) {
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                    if(!flag){
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == currentUser && val ) {//Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                        }
                      })
                    }
                    if(!flag)
                      listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                  } else {
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                  }
                }else if(itemInfo.type == "comunicado"){
                  if ( _viewedList ) {
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                    if(!flag){
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                        }
                      })
                    }
                    if(!flag)
                      listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                  } else {
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                  }
                }
              }else if(teacherGroups.length >0 && ( itemInfo.receiver_event === 'teacher' || itemInfo.receiver_event === 'evento' || itemInfo.receiver_event === 'group' )){ //Tiene grupo asignado, por lo tanto, se evaluan los eventos y comunicados

                if( (itemInfo.details.grade!="" && itemInfo.details.group!="" && itemInfo.details.turn!="") || (itemInfo.details.grade!="" && (itemInfo.details.group!="" || itemInfo.details.group=="") && (itemInfo.details.turn!="" || itemInfo.details.turn=="")) || ( (itemInfo.details.grade!=""||itemInfo.details.grade=="") && itemInfo.details.group!="" && (itemInfo.details.turn!="" || itemInfo.details.turn=="")) || ( (itemInfo.details.grade!=""||itemInfo.details.grade=="") && (itemInfo.details.group!="" || itemInfo.details.group=="") && itemInfo.details.turn!="" ) ){//Es un evento especifico para ciertas personas
                  const checkObj = [];

                  Object.values(teacherGroups).forEach(e=>{
                    // const count = checkObj.filter(obj => obj.grade === itemInfo.details.grade && obj.group === itemInfo.details.group && obj.turn === itemInfo.details.turn);
                    const count = checkObj.filter(obj => (obj.grade == "" && obj.group == "" && obj.turn == "") || (obj.grade == e.grade && obj.group == e.group && obj.turn == e.turn) || (obj.grade == e.grade && (obj.group == e.group || obj.group == "") && (obj.turn == e.turn || obj.turn == "")) || ((obj.grade == e.grade || obj.grade == "") && obj.group == e.group && (obj.turn == e.turn || obj.turn == "")) || ((obj.grade == e.grade || obj.grade == "") && (obj.group == e.group || obj.group == "") && obj.turn == e.turn ));

                    if( (itemInfo.details.grade == "" && itemInfo.details.group == "" && itemInfo.details.turn == "") || (itemInfo.details.grade == e.grade && itemInfo.details.group == e.group && itemInfo.details.turn == e.turn) || (itemInfo.details.grade == e.grade && (itemInfo.details.group == e.group||itemInfo.details.group == "") && (itemInfo.details.turn == e.turn||itemInfo.details.turn == "")) || ( (itemInfo.details.grade == e.grade||itemInfo.details.grade == "") && itemInfo.details.group == e.group && (itemInfo.details.turn == e.turn||itemInfo.details.turn == "")) || ( (itemInfo.details.grade == e.grade || itemInfo.details.grade == "") && (itemInfo.details.group == e.group || itemInfo.details.group == "") && itemInfo.details.turn == e.turn) ){
                      
                      if(count.length == 0){
 
                        if(itemInfo.type == "evento"){
                          checkObj.push({grade:itemInfo.details.grade,group:itemInfo.details.group,turn:itemInfo.details.turn});
                          if ( _viewedList ) {
                            Object.entries( _viewedList ).forEach( ([key, val]) => {
                              if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                                flag = true;
                                listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                              }
                            })
                            if(!flag){
                              Object.entries( _viewedList ).forEach( ([key, val]) => {
                                if ( [ key ] == currentUser && val ) {//Tenia tambien [ key ] == currentUser &&
                                  flag = true;
                                  listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                                }
                              })
                            }
                            if(!flag)
                              listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          } else {
                            listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          }
                        }else if(itemInfo.type == "comunicado"){
                          checkObj.push({grade:itemInfo.details.grade,group:itemInfo.details.group,turn:itemInfo.details.turn});
                          if ( _viewedList ) {
                            Object.entries( _viewedList ).forEach( ([key, val]) => {
                              if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                                flag = true;
                                listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                              }
                            })
                            if(!flag){
                              Object.entries( _viewedList ).forEach( ([key, val]) => {
                                if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                                  flag = true;
                                  listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                                }
                              })
                            }
                            if(!flag)
                              listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          } else {
                            listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          }
                        }
                      }
                    }
                  });


                }else if(itemInfo.receiver_event === 'all'){
                  if(itemInfo.type == "evento"){
                    if ( _viewedList ) {
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                        }
                      })
                      if(!flag){
                        Object.entries( _viewedList ).forEach( ([key, val]) => {
                          if ( [ key ] == currentUser && val ) {//Tenia tambien [ key ] == currentUser &&
                            flag = true;
                            listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          }
                        })
                      }
                      if(!flag)
                        listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    } else {
                      listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  }else if(itemInfo.type == "comunicado"){
                    if ( _viewedList ) {
                      Object.entries( _viewedList ).forEach( ([key, val]) => {
                        if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                          flag = true;
                          listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                        }
                      })
                      if(!flag){
                        Object.entries( _viewedList ).forEach( ([key, val]) => {
                          if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                            flag = true;
                            listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                          }
                        })
                      }
                      if(!flag)
                        listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    } else {
                      listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  }
                }
              }
            }else if(itemInfo.receiver_event === 'all'){//No tiene datos en el localStorage / comunicados y eventos globales
              if(itemInfo.type == "evento"){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                      flag = true;
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                  if(!flag){
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == currentUser && val ) {//Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                  }
                  if(!flag)
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#378006', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.type == "comunicado"){
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                      flag = true;
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                  if(!flag){
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                  }
                  if(!flag)
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#0000ff', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }
            }

            if( itemInfo.type == "appointment" ){

              if(itemInfo.confirm == 1 && itemInfo.teacher.teacher == uid){//*Puede ver citas confirmadas = 1
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                      flag = true;
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                  if(!flag){
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                  }
                  if(!flag)
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.receiver_event === 'appointment' && itemInfo.confirm == 0 && itemInfo.teacher.teacher == uid){//*Puede ver solicitudes de citas pendientes = 0 TUTOR A MAESTRO
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                      flag = true;
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                  if(!flag){
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                  }
                  if(!flag)
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }else if(itemInfo.receiver_event === 'appointment_tutor' && itemInfo.teacher.teacher == uid && (itemInfo.confirm == 1)){//*Puede ver peticiones de citas del maestro = 0 | 1
                if ( _viewedList ) {
                  Object.entries( _viewedList ).forEach( ([key, val]) => {
                    if ( [ key ] == uid && val ) { //Tenia tambien [ key ] == currentUser &&
                      flag = true;
                      listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                    }
                  })
                  if(!flag){
                    Object.entries( _viewedList ).forEach( ([key, val]) => {
                      if ( [ key ] == currentUser && val ) { //Tenia tambien [ key ] == currentUser &&
                        flag = true;
                        listItem.push({title:`Visto ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                      }
                    })
                  }
                  if(!flag)
                    listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                } else {
                  listItem.push({title:`Nuevo ${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
                }
              }
               
            }          
          }/*
          if (itemInfo.status === "active" && itemInfo.school === school && itemInfo.receiver_event === 'appointment') { 
            if( (itemInfo.confirm === 1 || itemInfo.confirm === 0) && itemInfo.type == "appointment" && itemInfo.teacher.teacher == uid){
              listItem.push({title:`${itemInfo.name}`, date:`${itemInfo.date}`, color: '#ff9326', description:`${itemInfo.details_event}`, id:`${itemInfo.id}`})
            }
          } */
        
      })
        setEvents(listItem)
      
      // Notification
      let NotfRef = db.ref(`notifications`);
      let notfList = [];
      NotfRef.once('value').then( snapshot => {
        snapshot.forEach( notf => {
          let itemInfo = notf.val()
          if ( itemInfo.type === 'kiper' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'anybody' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'teacher' ) {
            let info = notf.val()
            if(info && info.userList){
              info.userList.forEach( users => {
                if ( users.key === uid ) {
                  notfList.push( itemInfo )
                }
              })
            }

          }
        })
        setNotif(notfList.reverse())
      }) 
    }
    
    if ( type === 'school' ) {
      // Calendar
      let ref = db.ref(`comunicados`);
      let listItem = [];
      ref.once('value').then( snapshot => {
        snapshot.forEach( e => {
          let itemInfo = e.val()

          if(typeof school === 'undefined')
            school = localStorage.getItem("schoolID");
          else
            localStorage.setItem("schoolID", school);

          const _viewedList = itemInfo.viewed;
          if ( itemInfo.status == "active" && itemInfo.school == school &&(itemInfo.receiver_event == 'evento' || itemInfo.receiver_event == 'comunicado' || itemInfo.receiver_event == 'all' || itemInfo.receiver_event == 'group' || itemInfo.receiver_event == 'child') ) {
            let info = e.val()
            let flag = false;

            if(info.type == "evento"){
              if ( _viewedList ) {
                Object.entries( _viewedList ).forEach( ([key, val]) => {
                  if ( [ key ] == school && val ) {//Tenia tambien [ key ] == currentUser &&
                    flag = true;
                    listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                  }
                })
                if(!flag)
                  listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
              } else {
                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
              }
            }else if(info.type == "comunicado"){
              if ( _viewedList ) {
                Object.entries( _viewedList ).forEach( ([key, val]) => {
                  if ( [ key ] == school && val ) {//Tenia tambien [ key ] == currentUser &&
                    flag = true;
                    listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                  }
                })
                if(!flag)
                  listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
              } else {
                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
              }
            }        
          }
        })
        setEvents(listItem)
      }) 

      // Notification
      let NotfRef = db.ref(`notifications`);
      let notfList = [];
      NotfRef.once('value').then( snapshot => {
        snapshot.forEach( notf => {
          let itemInfo = notf.val()
          if ( itemInfo.type === 'kiper' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'kipper' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'school' ) {
            let info = notf.val()
            info.userList.forEach( users => {
              if ( users.key === uid ) {
                notfList.push( itemInfo )
              }
            })
          }
        })
        setNotif(notfList.reverse())
      }) 
    }
    
    if ( type === 'child' ) {
      // Calendar
      let ref = db.ref(`comunicados`);
      let listItem = [];
      ref.once('value').then( snapshot => {
        snapshot.forEach( e => {
          let itemInfo = e.val()
          
          if(typeof school === 'undefined')
            school = localStorage.getItem("schoolID");
          else
            localStorage.setItem("schoolID", school);

          const _viewedList = itemInfo.viewed;

          //Esta condicion tenia tambien la condicion itemInfo.school === school PERO CAMBIA CADA VES QUE CAMBIO DE VISTA
          if ( itemInfo.status === "active" && itemInfo.school === school && ( itemInfo.receiver_event === 'child' || itemInfo.receiver_event === 'evento' || itemInfo.receiver_event === 'all' || itemInfo.receiver_event === 'group' ) ) {
            let info = e.val()

            if( (itemInfo.details.grade == "" && itemInfo.details.group=="" && itemInfo.details.turn == "") || (itemInfo.details.grade == localStorage.getItem("childGrade") && itemInfo.details.group == localStorage.getItem("childGroup") && itemInfo.details.turn == localStorage.getItem("childTurn")) || (itemInfo.details.grade == localStorage.getItem("childGrade") && (itemInfo.details.group == localStorage.getItem("childGroup")||itemInfo.details.group == "") && (itemInfo.details.turn == localStorage.getItem("childTurn") || itemInfo.details.turn == "")) || ((itemInfo.details.grade == localStorage.getItem("childGrade") || itemInfo.details.grade =="") && itemInfo.details.group == localStorage.getItem("childGroup") && (itemInfo.details.turn == localStorage.getItem("childTurn") || itemInfo.details.turn == "")) || ((itemInfo.details.grade == localStorage.getItem("childGrade") || itemInfo.details.grade =="") && (itemInfo.details.group == localStorage.getItem("childGroup")||itemInfo.details.group =="") && itemInfo.details.turn == localStorage.getItem("childTurn")) ){
              listItem = funcChild(info,_viewedList,uid,listItem);   
            }else if(itemInfo.receiver_event === 'all'){
              listItem = funcChild(info,_viewedList,uid,listItem);
            }

            /*if(info.type == "evento"){
              if ( _viewedList ) {
                Object.entries( _viewedList ).forEach( ([key, val]) => {
                  if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                    flag = true;
                    listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
                  }
                })
                if(!flag)
                  listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
              } else {
                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#378006', description:`${info.details_event}`, id:`${info.id}`})
              }
            }else if(info.type == "comunicado"){
              if ( _viewedList ) {
                Object.entries( _viewedList ).forEach( ([key, val]) => {
                  if ( [ key ] == uid && val ) {//Tenia tambien [ key ] == currentUser &&
                    flag = true;
                    listItem.push({title:`Visto ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
                  }
                })
                if(!flag)
                  listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
              } else {
                listItem.push({title:`Nuevo ${info.name}`, date:`${info.date}`, color: '#0000ff', description:`${info.details_event}`, id:`${info.id}`})
              }
            }   */      
          }
        })
        setEvents(listItem)
      }) 
      
      // Notification
      let NotfRef = db.ref(`notifications`);
      let notfList = [];
      NotfRef.once('value').then( snapshot => {
        snapshot.forEach( notf => {
          let itemInfo = notf.val()
          if ( itemInfo.type === 'kiper' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'anybody' ) {
            notfList.push( itemInfo )
          }
          if ( itemInfo.school_key === school && itemInfo.type === 'child' ) {
            let info = notf.val()
            info.userList.forEach( users => {
              if ( users.key === uid ) {
                notfList.push( itemInfo )
              }
            })
          }
        })
        setNotif(notfList.reverse())
      }) 
    }
  }

  const handlelogout = () =>{
    app.auth().signOut()
    setInfo({})
    setPending(false)
    setSession(false)
    setEvents(null)
    setNotif(null)
    setInfoSchool(null)
    localStorage.removeItem("currentUser")
    localStorage.removeItem("userType")
    setCurrentUser(null)
    setuserType(null)
  }

  if(pending){
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        info,
        notif,
        userType,
        handlelogout,
        setPending,
        handlenotif,
        setSession,
        setCurrentUser,
        setuserType,
        infoSchool,
        events,
        setInfo
     }}>
      {children}
    </AuthContext.Provider>
  );
};
