//importes de React
import React, {useEffect, useState, useContext} from 'react'
import {useHistory} from "react-router-dom";
//Context
import { AuthContext } from '../../Components/functions/Auth'
//importaciones de firebase
import * as firebase from 'firebase';
//Material-ui
import { Grid, Box, Button, Typography,Avatar , InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
//Importacion funciones
import { Send } from './ChatFunctions';
//Assets
import icon from '../../Assets/Img/General/Academy.png'
import avatarimg from "../../Assets/Img/General/placeholder_teacher-f.png";
import style from '../../Assets/Styles/chat.module.css'
import ComunicadosDetalles from '../../Pages/GeneralEventsDetail';

//Assets
import imgChild from '../../Assets/Img/General/placeholder_boy.png';
import imgTutor from '../../Assets/Img/General/placeholder_man.png';
import imgTeacher from '../../Assets/Img/General/placeholder_teacher-m.png';
import imgSchool from '../../Assets/Img/General/Academy.png';
import SendIcon from '@material-ui/icons/Send';
import { send_notification_fcm } from '../functions/FCMNotification';

export default function ChatBody ({  type, user, information, functionBack, messages, reload }){ 
    
    const messaging = firebase.messaging();
    const CHATS_REF = firebase.database().ref('chats/');
    const { info, userType, infoSchool, currentUser } = useContext(AuthContext);
    const [ text, setText ] = useState('')
    const [ messages2, setmessages2] = useState(null)
    const [ refinfo, setRefinfo ] = useState(null)
    const [ statuschat, setStatuschat ] = useState(0)
    const [ statusmsg, setStatusmsg ] = useState(null)
    const [ avatarholder, setAvatarHolder] = useState(null)
    const [ active, setActive ] = useState(null)
    const [ request, setRequest ] = useState(null)
    const [ close, setClose ] = useState(null);
    const [ sender, setSender ] = useState(null);
    const [device_token, setdevice_token] = useState(null)
    const [deviceLists, setdeviceLists] = useState(null)
    const [validDevice, setvalidDevice] = useState(null)
    const history   = useHistory();
    const listDevices =[];
    const listDevicesReceiver =[];
    const componentDidMount = ()=>{
        
        messaging.requestPermission().then((token)=>{
        return messaging.getToken()
        }).then( async (token)=>{
            const devices = firebase.database().ref().child('devices')
            
            const devicesList = firebase.database().ref('devices')
            let valid=0;
            var info = await devicesList.once('value')
            console.log(token)
            info.forEach(item => {
                if(item.val().userID === currentUser){
                    listDevices.push(item.val().deviceKey)
                }
            })
            if(listDevices.includes(token)===true){
                // console.log("encontre un dispositivo")
                devices.push({
                    deviceKey:token,
                    userID:currentUser
                })
            }else {
           
            }
        }).catch(()=>{
            console.log('error chat')
        })
    }

    useEffect(() => {
        if(information){
            firebase.database().ref(`chats/${information}/`).on('child_changed', (snapshot) => {
                const data = snapshot.val();
                reload(statuschat+1)            
                setStatuschat(statuschat+1)
            });
        }
    })
    
        useEffect(() => {
            componentDidMount()
        }, [])

    const send_notification =async (text)=>{
        const devicesList = firebase.database().ref('devices')
        var info = await devicesList.once('value')
        const receiverID = user.id ? user.id : user.teacher_key;
        info.forEach(item => {
            if(item.val().userID === receiverID){
                // console.log("se envia notificacion a "+item.val().deviceKey)
                send_notification_fcm(item.val().deviceKey,text)
            }
    })
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(9),
            height: theme.spacing(9),
        },
    }));

    useEffect(() => {
        return () => {
            setClose(null)
            setActive(null)
            setRequest(null)
        }
    },[information || statusmsg])

    useEffect(() => {
        var el = document.getElementById('mensajeschat')
        var hel = el.scrollHeight;
        el.scrollTop = hel + 150;
        if(statusmsg){
            if(statusmsg.status?.Active){
                setActive(true)
                setRequest(null)
                setClose(null)
            }else if(statusmsg.status.Closed){
                setClose(true)
                setActive(null)
                setRequest(null)
            }else if(statusmsg.status?.Request){
                setRequest(true)
                setClose(null)
                setActive(null)
            }else{
                setRequest(null)
                setClose(null)
                setActive(null)
            }
        }
    }, [messages])

    useEffect(() => {
        switch (user?.type) {
            case "child":
                setAvatarHolder(imgChild)
                break;
            case "tutor":
                setAvatarHolder(imgTutor)
                break;
            case "teacher":
                setAvatarHolder(imgTeacher)
                break;
            case "school":
                setAvatarHolder(imgSchool)
                break;
            case "delegado":
                setAvatarHolder(imgTutor)
                break;
            default:
                setAvatarHolder(imgSchool)
                break;
        }
    }, [user])
    
    const handleSubmit = e => {
        var count = 0;
        e.preventDefault();
        if(information && text){
            if(userType === 'teacher')
            {
                Send(text, infoSchool, information);
                setText("")
                send_notification(text);
            }
            else if(userType === 'child')
            {
                Send(text, info, information);
                setText("")
                send_notification(text);
            }
            else if(userType === 'tutor')
            {
                Send(text, infoSchool, information);
                setText("")
                send_notification(text);
            }
            else if(userType === 'school'){
                Send(text, info, information);
                setText("")
                send_notification(text);
            }
        }
        reload(statuschat+1)            
        setStatuschat(statuschat+1)
    }
    const classes = useStyles();
    return(
        <>
        <Grid container direction="row" justify="space-between" style={{marginBottom:'20px'}}  >
            <Box justifyContent="flex-start" className="chatbody_block1_xs">
                <div className={style.chatHeader} >
                    <Avatar className={`chatbody_img_xs ${classes.large}`}  src={ user?.avatar ? user.avatar : avatarholder } />
                    <span className="chatbody_title_xs" style={{paddingLeft:'8px'}}>  
                        <h6  className="kipper_txt-color" style={{wordWrap:'break-word'}} >
                            <b>{ user?.name } { user?.last_name ?user?.last_name :user?.lastname }</b>
                        </h6>
                        {user?.contact?.email ?  user?.contact?.email : ''}
                    </span>
                </div>
            </Box>      
            <Box className="chatbody_block2_xs" alignItems="flex-end" style={{paddingTop:'20px'}} >
                <Button onClick={e => functionBack() } className="kipper_btn-outline-black" variant="outlined" >Atras</Button>
            </Box>
        </Grid>
        <Grid container className="kipperBox_content card card-2" boxShadow={3} >
            <div style={{margin:'20px'}}></div>
            <MessagesBody style={{marginTop:'15px'}} msgs={messages} sender={refinfo} />
            <Grid item xs={ 12 }>
                <form className="form_chat" style={{paddingTop:'15px'}} spacing={2} onSubmit={ e => handleSubmit(e)}>
                    <Grid container   direction="row"    >
                        <Grid item xs={ 9 } md={ 10 } lg={ 10 } >
                            <InputBase className={ style.form_control_chat } inputProps={{ 'aria-label': 'naked' }} style={{paddingLeft:15}}  placeholder="Escribe aquí" value={ text } onChange={e => setText(e.target.value) }/>
                        </Grid>
                        <Grid item xs={ 3 } md={ 2 } lg={2}>
                            <Button type="submit" className={ style.btnSendMessage } variant="contained"  >
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
        </>
    )
}

const MessagesBody = (msgs) =>{
    const renderMessages = (msgs) => {
        if(msgs.msgs){
            return Object.entries(msgs.msgs).map(e => <Messages  key={e[0]} msg={e} sender={msgs.sender} />)
        }else{
            return  <><label style={{paddingLeft:'20px'}}>Envía un mensaje</label></>
        }
    }    
    
    return(
        <Grid item xs={ 12 }  className={ style.chat_content } id={'mensajeschat'}>
            { msgs && renderMessages(msgs) }
        </Grid>
    )
}

const Messages = ( {msg, sender, functionInf} ) => {
    const { currentUser, infoSchool } = useContext(AuthContext);
    const [senderinfo, setSenderinfo] = useState(sender);
    const [typeUser, setType] = useState('');
    useEffect(() => {
        var typeuser = msg[1]?.sender?.key.split("_");
        setType(typeuser[0])
        if(typeuser && msg[1]?.sender?.key !== currentUser){
            if(typeuser[0] === 'school' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/schools/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else if(typeuser[0] === 'teacher' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/teacher/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else if(typeuser[0] === 'tutor' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/tutors/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else{
                firebase.database().ref(`/childs/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }
        }
    }, [sender])
    //condicionales para decidir como imprimir el mensaje
    //el tipo uno seria como un mensaje del sistema, como chat creado, cerrado o inicio/fin de las conversaciones
    if(msg[1].type === 1){
        return (
            <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                <br/>
                <Grid item xs={ 10 } className={ `${style.msg_content} ${style.grey}` }>
                    <Box className={ style.msg_text }>
                        { msg[1].msg } 
                    </Box>
                </Grid>
                <Grid item xs={ 2 } className={ style.avatar_content }>
                </Grid>
            </Grid>
        )
    //El tipo 2 son los mensajes normales de usuarios, estos incluyen la informacion de su creador en el apartado array.sender
    }else if(msg[1].type === 2){
        //Esta condicional nos permite saber si somos los creadores del mensaje o los que lo recibimos para imprimir la informacion del creador
        //y asi el usuario tenga una idea de con quien(es) esta hablando
        if(msg[1].sender.key === currentUser && typeUser === "school" ){
            return (
                <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${style.blue}` }>
                        <Box className={ style.msg_text }>
                            { msg[1].msg }
                        </Box>
                    </Grid>
                <Grid item xs={ 2 } className={ style.avatar_content }>
                        <Avatar src={  infoSchool?.avatar ?infoSchool?.avatar :avatarimg }  className={ style.avatar_chat }/>
                    </Grid>
                    
                </Grid>
            )
        }else if(msg[1].sender.key === currentUser && typeUser !== "school" ){
            return (
                <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${style.blue}` }>
                        <Box className={ style.msg_text }>
                            { msg[1].msg }
                        </Box>
                    </Grid>
                    <Grid item xs={ 2 } className={ style.avatar_content }>
                        <Avatar src={  msg[1]?.sender?.avatar ?msg[1].sender.avatar :avatarimg }  className={ style.avatar_chat }/>
                    </Grid>
                    
                </Grid>
            )
            
        }else{
            return(
                <Grid container className= {` ${style.msg_content}` } spacing={ 2 }>
                    <Grid item xs={ 2 } className={ style.avatar_content }>
                        <Avatar src={ senderinfo?.avatar ?senderinfo?.avatar :avatarimg } className={ style.avatar_chat } />
                    </Grid>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${ style.lowblue }`} style={{display:"block"}} >
                        {/* <span>{senderinfo?.name}</span> */}
                        <Box className={ style.msg_text.dark }>
                            <span>{ msg[1].msg }<br></br></span>
                        </Box>
                    </Grid>
                    
                </Grid>
            )
        }
    }
}