import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user2 from './../../Assets/Img/General/placeholder_teacher-f.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Listitem from './ListItem';
import moment from 'moment';
import 'moment/locale/es';

// import Pagination from '@material-ui/lab/Pagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import { Grid, Box, Typography, Divider } from '@material-ui/core';
import * as firebase from 'firebase';

export default function Homework() {
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const [errors, setErrors] = useState('')
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/asistencias/:grupo/:materia');
    const grupoID = match.params.grupo;
    const subjectID = match.params.materia;
    const [lista, setlista] = useState(null)

    // Funcion inicial del componente
    useEffect(() => {
        Listas()
    }, [infoSchool])

    //funcion de obtencion de informacion por primera vez
    const Listas = () =>{
        if(infoSchool)
        {
            let db = firebase.database();
            var listtareas = [];
            let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance`);
            ref.once('value').then( snapshot => {
                if(snapshot.val()){
                    let data = snapshot.val()
                    Object.entries(data).map( item => {
                        listtareas.push(item)
                    })
                    setlista( listtareas.reverse() );
                }
            });
        }
    }

    function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
    }

/* funcion que revisa si existe alguna lista del dia ya creada */ 
    function CheckAssistanceToday(){
        let db = firebase.database();
        var confirmacion = false; 
        let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance`);
        ref.once('value').then( snapshot => {
/* En caso de existir la lista esta este envia un true a la funcion de Asistencia() */
            if(snapshot.val()){
                let data = snapshot.val()
                Object.entries(data).map( item => {
                    var fechas = new Date(item[1].time);
                    var dia = fechas.getDate();
                    var month = fechas.getMonth();
                    var year = fechas.getFullYear();
                    var timenow = new Date();
                    const times = dia === timenow.getDate() && month === timenow.getMonth() && year === timenow.getFullYear();
                    if(times){
                        confirmacion = true
                    }
                })
                Asistencias(confirmacion);
            }else{
/* En caso contrario aun respeta el false de su declaracion inicial de la variable confirmacion
haciendo asi que la siguiente funcion sepa que no hay una lista de asistencias del dia de hoy */
                Asistencias(confirmacion);
            }
        });
    }

/* funcion que crea la lista en caso de no haber una lista ya existente */
    const Asistencias = (revision) =>{
        if(revision){
            setErrors('Error: ya existe una lista con la fecha del dia de hoy')
        }else{
            var listaniños = [];
            var infokids = [];
            var keypush;
            try{
                const today = new Date();
                let db = firebase.database();
                let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance`);
                keypush = ref.push({
                    time: `${moment().format()}`,
                    status: 0
                }, function(error){
                    if(error){
                        console.log(error)
                    }else{
                        setErrors('Lista Creada')
                        // Listas()
                    }
                }).key;
            }finally{ 
                let db = firebase.database();
                var confirmacion = false;
                let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/student`);
                let ref2 = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance/${keypush}/students`);
                ref.once('value').then( snapshot => {
                    Object.entries(snapshot.val()).forEach( item => {
                        let Setstudents = ref2.child(item[0]);
                        Setstudents.update({
                            assistance: "No"
                        }, function(error) {
                            if (error) {
                              // The write failed...
                              console.log(error)
                            } 
                        });
                    })
                    setlista(null)
                    Listas()
                });     
            }
        }
    }

    return (
        <Grid container className="padres_grid">
            <Grid item xs={12}>
                <Box pb={3}  mx={3} >
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Box>
                            <Typography variant="h4" className="schoolInfo_title capitalize"> Asistencias </Typography>
                            <Typography className=""> Aqui podras ver tu lista de asistencias y tomarlas </Typography>
                        </Box>
                        <Box display="flex" >
                            {/* <Link to={`/teacher/materias/group/${grupoID}`}> */}
                                <Button onClick={() => history.goBack()} variant="outlined" className="kipper_btn-outline-black mar_r_2_i">Atras</Button>
                            {/* </Link> */}
                                <Button  variant="contained" className="kipper_button FullWidthButton" type="button" onClick={e => CheckAssistanceToday()} disableElevation>
                                    Tomar asistencia del dia de hoy 
                                </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
            

            <Grid xs={12}>                
                <Box className="kipperBox_content" boxShadow={3}>

                <Box className="box_asistencias_messages">
                    <p className="kipper_txt-color">{errors}</p>
                </Box>
                    
                {
                    lista ?
                        lista.map( i => 
                            <Listitem list={i}/>
                        )
                    : null
                }
                </Box>
            </Grid>
        </Grid>
    )
}
