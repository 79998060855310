import React, { useState, useContext, useEffect, useRef }  from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal'
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
//firebase
import * as firebase from 'firebase';
import moment from 'moment';
import { checkFilepdf } from '../../Components/functions/helpapers';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../../Constants/textfield';
import Select from '../../Constants/select';



export default function ComunicadosAgregar () {

    const history = useHistory();
    // const match = useRouteMatch('/padres/agregar/:id');

    const [turnInfo, setTurnInfo]           = useState([])
    const [groupInfo, setGroupInfo]         = useState([])
    const [gradeList, setGradeList]         = useState([])
    const [groupList, setGroupList]         = useState([])
    const [ file, setFile]                  = useState('');
    const [ error, seterror ]               = useState('');
    const { currentUser }                   = useContext(AuthContext);

    const formRef = useRef();

    const [flag, setFlag] = useState(false)
    
    const [errorMsg, seterrorMsg] = useState({
        doc: false
    });

    const [loading, setLoading] = useState(true)
    const [docOpcional, setDocOpcional] = useState('')
    const [docNameOpcional, setDocNameOpcional] = useState('')
    

    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => setModalStaus(true);

    const ModalClose = () => setModalStaus(false);
    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    
    const getGroupInfo = async () => {
        let list = [];
        let resp = await firebase.database().ref(`schools/${currentUser}/groups`).once('value');
        resp.forEach( item => {
            let itemInfo = item.val()
            if ( item.key !== 'details') {
                list.push( itemInfo );  
            }
        })
        setGroupInfo( list )
    }
    
    useEffect( () =>{ getGroupInfo() }, [ ] );

    
    const handleSubmit = (val) => {
        const opcionalIMG =  file && document.getElementById('opcionalIMG').src;
        let db = firebase.database();
        let id_comunicado = `comunicado_${Math.floor(Math.random() * 100000)}`;
        let Usersref = db.ref(`comunicados/${id_comunicado}`);
        try
        {
            Usersref.set({
                id: id_comunicado,
                school: currentUser,
                name: val.name,
                receiver_event: val.to,
                details_event: val.eventMessage,
                date: val.date,
                time: val.time,
                timestamp: Date.now(),
                status: 'active',
                type: val.type,
                viewed:{ },
                details:{
                    level: val.level,
                    grade: val.grade,
                    group: val.group,
                    turn: val.turn,
                },
                document:{
                    document_1: opcionalIMG
                }
            });
        }
        catch(error)
        {
            console.log(error)

        }
        finally
        {
            // Data saved successfully!
            seterror('');
            setModalmsg('El comunicado fue creado con éxito');
            setModalLink(`/comunicados`);
            ModalOpen();
            // createNotif(val)
        }

    }

    const createNotif = (val) => {
        let db = firebase.database();
        let notfType;
        let userList = []
        switch ( val.to ) {
            case 'all':
                notfType = 'anybody'
                let NotificationRef = db.ref(`notifications/`);
                NotificationRef.push({
                    school_key: currentUser,
                    title: `Nuevo ${ val.type }`,
                    body: `Nuevo ${ val.type } ${ val.name } para ${ val.date } a las ${ val.time }`,
                    date: moment().format('MMMM Do YYYY'),
                    time: moment().format('h:mm:ss a'),
                    type: notfType,
                    userList
                }, (error) => {
                    if (error) { console.log(error) } 
                });
                break;
            case 'teacher':
                notfType = 'teacher'
                if ( val.grade && val.group && val.turn ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.grade === val.grade && itemInfo.group === val.group && itemInfo.turn === val.turn ) {
                                userList.push({ key: itemInfo.teacher.teacher_key, status: 0 }) 
                                
                                let NotificationRef = db.ref(`notifications/`);
                                NotificationRef.push({
                                    school_key: currentUser,
                                    title: `Nuevo ${ val.type }`,
                                    body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                                    date: moment().format('MMMM Do YYYY'),
                                    time: moment().format('h:mm:ss a'),
                                    type: notfType,
                                    userList
                                }, (error) => {
                                    if (error) { console.log(error) } 
                                });
                            }
                        })
                    });
                }
                else if ( val.grade ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.grade === val.grade && itemInfo.teacher ) {
                                userList.push({ key: itemInfo?.teacher?.teacher_key, status: 0 }) 
                            }
                        })
                    });
                    setTimeout(() => { 
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    }, 5000);
                }
                else if ( val.turn ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.turn === val.turn && itemInfo.teacher ) {
                                userList.push({ key: itemInfo?.teacher?.teacher_key, status: 0 }) 
                            }
                        })
                    });
                    setTimeout(() => { 
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    }, 5000);
                }
                else {
                    let refteacher = db.ref(`teacher`);
                    refteacher.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                                userList.push({ key: item.key, status: 0 })
                            }
                        })
                        
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    });
                }
                break;
            case 'tutor':
                notfType = 'tutor' 
                let studenList = [];
                if ( val.grade && val.group && val.turn ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.grade === val.grade && itemInfo.group === val.group && itemInfo.turn === val.turn ) {
                                let refgroup = db.ref(`schools/${ currentUser }/groups/${ item.key }/student`);
                                refgroup.once('value').then( snapshot => {
                                    snapshot.forEach( item => { 
                                        studenList.push({ key: item.key }) 
                                    })
                                    studenList.forEach( item => {
                                        let refgroup = db.ref(`childs/${ item.key }`);
                                        refgroup.once('value').then( snapshot => {
                                            if ( snapshot.val().status === 'active' && snapshot.val().school === currentUser ) {
                                                userList.push({ key: snapshot.val().tutors.tutor_1 , status: 0 }) 
                                            }
                                        })
                                        // let refgroup = db.ref(`childs/${ item.key }/tutors`);
                                        // refgroup.once('value').then( snapshot => {
                                        //     snapshot.forEach( item => {  
                                        //         userList.push({ key: item.val() , status: 0 }) 
                                        //     })
                                        // })
                                    })
                                })
                            }
                        })
                    });
                    setTimeout(() => { 
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    }, 5000);
                }
                else if ( val.grade ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.grade === val.grade ) {
                                let refgroup = db.ref(`schools/${ currentUser }/groups/${ item.key }/student`);
                                refgroup.once('value').then( snapshot => {
                                    snapshot.forEach( item => { 
                                        studenList.push({ key: item.key }) 
                                    })
                                    studenList.forEach( item => {
                                        let refgroup = db.ref(`childs/${ item.key }`);
                                        refgroup.once('value').then( snapshot => {
                                            let isInclude = userList.includes( snapshot.val().tutors.tutor_1 )
                                            if ( isInclude ) {
                                                userList.push({ key: snapshot.val().tutors.tutor_1 , status: 0 }) 
                                            }
                                        })
                                    })
                                })
                            }
                        })
                    });
                    setTimeout(() => { 
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    }, 5000);
                }
                else if ( val.turn ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.turn === val.turn ) {
                                let refgroup = db.ref(`schools/${ currentUser }/groups/${ item.key }/student`);
                                refgroup.once('value').then( snapshot => {
                                    snapshot.forEach( item => { 
                                        studenList.push({ key: item.key }) 
                                    })
                                    studenList.forEach( item => {
                                        let refgroup = db.ref(`childs/${ item.key }`);
                                        refgroup.once('value').then( snapshot => {
                                            if ( snapshot.val().status === 'active' && snapshot.val().school === currentUser ) {
                                                let isInclude = userList.includes( snapshot.val().tutors.tutor_1 )
                                                if ( isInclude ) {
                                                    userList.push({ key: snapshot.val().tutors.tutor_1 , status: 0 }) 
                                                }
                                            }
                                        })
                                    })
                                })
                            }
                        })
                    });
                    setTimeout(() => { 
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    }, 5000);
                }
                else {
                    let reftutor = db.ref(`tutors`);
                    reftutor.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                                userList.push({ key: item.key, status: 0 })
                            }
                        })
                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.date } a las ${ val.time }`,
                            date: moment().format('MMMM Do YYYY'),
                            time: moment().format('h:mm:ss a'),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    });
                }
                break;
            case 'group': 
                notfType = 'child'
                let refgroup = db.ref(`schools/${ currentUser }/groups`);
                refgroup.once('value').then( snapshot => {
                    snapshot.forEach( item => {
                        let itemInfo = item.val()
                        if ( itemInfo.grade === val.grade && itemInfo.group === val.group && itemInfo.turn === val.turn ) {
                            let refgroup = db.ref(`schools/${ currentUser }/groups/${ item.key }/student`);
                            refgroup.once('value').then( snapshot => {
                                snapshot.forEach( item => { 
                                    userList.push({ key: item.key, status: 0 }) 
                                })
                                
                                let NotificationRef = db.ref(`notifications/`);
                                NotificationRef.push({
                                    school_key: currentUser,
                                    title: `Nuevo ${ val.type }`,
                                    body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                                    date: moment().format('MMMM Do YYYY'),
                                    time: moment().format('h:mm:ss a'),
                                    type: notfType,
                                    userList
                                }, (error) => {
                                    if (error) { console.log(error) } 
                                });
                            })
                        }
                    })
                });
                break;
            case 'child':
                notfType = 'child'; 
                if ( val.grade && val.group && val.turn ) {
                    let refgroup = db.ref(`schools/${ currentUser }/groups`);
                    refgroup.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( itemInfo.grade === val.grade && itemInfo.group === val.group && itemInfo.turn === val.turn ) {
                                let refgroup = db.ref(`schools/${ currentUser }/groups/${ item.key }/student`);
                                refgroup.once('value').then( snapshot => {
                                    snapshot.forEach( item => { 
                                        userList.push({ key: item.key, status: 0 }) 
                                    })
                                    
                                    let NotificationRef = db.ref(`notifications/`);
                                    NotificationRef.push({
                                        school_key: currentUser,
                                        title: `Nuevo ${ val.type }`,
                                        body: `Nuevo ${ val.type } ${ val.name } para ${ val.grade } ${ val.group } ${ val.date } a las ${ val.time }`,
                                        date: moment().format('MMMM Do YYYY'),
                                        time: moment().format('h:mm:ss a'),
                                        type: notfType,
                                        userList
                                    }, (error) => {
                                        if (error) { console.log(error) } 
                                    });
                                })
                            }
                        })
                    });
                }
                else {
                    let refchild = db.ref(`childs`);
                    refchild.once('value').then( snapshot => {
                        snapshot.forEach( item => {
                            let itemInfo = item.val()
                            if ( val.turn ) {
                                if ( itemInfo.status === "active" && itemInfo.school === currentUser && itemInfo.details.turn === val.turn ) {
                                    userList.push({ key: item.key, status: 0 })
                                }
                            }
                            else if ( val.grade ) {
                                if ( itemInfo.status === "active" && itemInfo.school === currentUser && itemInfo.details.grade === val.grade ) {
                                    userList.push({ key: item.key, status: 0 })
                                }
                            }
                            else {
                                if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                                    userList.push({ key: item.key, status: 0 })
                                }
                            }
                        })

                        let NotificationRef = db.ref(`notifications/`);
                        NotificationRef.push({
                            school_key: currentUser,
                            title: `Nuevo ${ val.type }`,
                            body: `Nuevo ${ val.type } ${ val.name } para ${ val.date } a las ${ val.time }`,
                            date: new Date(),
                            time: new Date().getTime(),
                            type: notfType,
                            userList
                        }, (error) => {
                            if (error) { console.log(error) } 
                        });
                    });
                }
                break;
            default:
                break;
        }
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading(false)
                setFile( img );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional(profileImg.src)
                        setDocNameOpcional(profileImg)
                        setLoading(true)
                    })
                })
            } catch (error) {
                setLoading(true)
                console.log( error );
            }

        } else {
            setLoading(true)
            return seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }  
    }

    const handleSelectLevel = () => {
        let level = formRef.current.values.level
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        formRef.current.values.turn = ''
        let list = [];
        groupInfo.forEach( i => {
            if ( i.level === level ) {
                list.push( i.turn )
            }
        })
        if ( !list.length ) {
            list.push( 'Sin información' )
        } else {
            list = Array.from(new Set( list ))
        }
        setTurnInfo( list )
    } 

    const handleSelectTurn = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        let listOrder = []
        let list = [];
        groupInfo.forEach( i => {
            if ( i.turn === turn && i.level === level ) {
                list.push( i.grade )
            }
        })
        if ( !list.length ) {
            list.push( 'Sin información' )
        } else {
            list = Array.from(new Set( list ))
        }

        list.forEach(item => {
            switch(item){
                case "Primero":
                    listOrder.push({key:1, grade: item})
                    break;
                case "Segundo":
                    listOrder.push({key:2, grade: item})
                    break;
                case "Tercero":
                    listOrder.push({key:3, grade: item})
                    break;    
                case "Cuarto":
                    listOrder.push({key:4, grade: item})
                    break;
                case "Quinto":
                    listOrder.push({key:5, grade: item})
                    break;
                case "Sexto":
                    listOrder.push({key:6, grade: item})
                    break;
                default:
                    break;    
            }
        })
        setGradeList( listOrder.sort((a,b) => a.key - b.key ) );
    } 

    const handleSelectGrade = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        let grade = formRef.current.values.grade
        formRef.current.values.group = ''
        let list = [];
        groupInfo.forEach( i => {
            if ( i.level === level && i.turn === turn  && i.grade === grade ) {
                list.push( i.group )
            }
        })
        if ( !list.length ) {
            list.push( 'sin información' )
        } 
        else {
            list = Array.from(new Set( list ))
        }
        setGroupList( list.sort() )
    } 
    
    const handleDisable = (e) => {
        if(e === 'all'){
            formRef.current.values.grade = ''
            formRef.current.values.group = ''
            formRef.current.values.turn = ''
            formRef.current.values.level = ''
            setFlag(true)
        }else{
            setFlag(false)
        }
    }
    const today = new Date();
    const yesterday = new Date();

    yesterday.setDate(today.getDate() - 1)

    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .min(5, "El nombre debe contener minimo 5 caracteres")
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El nombre es obligatorio'),
        type: yup
            .string('Ingresa un tipo')
            .required('El tipo es obligatorio'),
        to: yup
            .string('Ingresa un destinatario')
            .required('El destinatario es obligatorio'),
        level: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un nivel')
                    .required('El nivel es obligatorio'),
            }),
        grade: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un grado')
                    .required('El grado es obligatorio'),
            }),            
        group: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un grupo')
                    .required('El grupo es obligatorio'),
            }),  
        turn: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un turno')
                    .required('El turno es obligatorio'),
            }), 
        date: yup
            .date().min(yesterday, "No se pueden crear eventos de fechas pasadas")
            .required('La fecha es obligatoria'),
        time: yup
            .string('Ingrese un hora')
            .required('La hora es obligatorio'),
        eventMessage: yup
            .string('Ingrese los detalles del evento')
            .required('Los detalles son obligatorios')
            .max(200, "Se admite un máximo de 200 caracteres"),
    });

    const initialFormState ={
        name: '',
        type: '',
        to: '',
        turn: '',
        grade: '',
        level: '',
        group: '',
        date: '',
        time: '',
        eventMessage: ''
    }    

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                innerRef={formRef}
                validationSchema={validateForm}
                onSubmit={values =>{
                    handleSubmit(values)
                }}
                
            >
                <Form>
                    <Grid container>
                        {/* <NavEscuelaPhone /> */}
                        <Grid xs={12} sm={12} md={12}>
                            <Box pb={3}>
                                <PageHeader 
                                    title="Nuevo comunicado"
                                    text="Define las configuraciones para el nuevo comunicado y/o evento"
                                    btn_back="/comunicados"
                                    btn_action={ true }
                                    btn_action_text="Guardar"
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} className="Form_add-content kipper_box">
                            <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                                <Box >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Box>
                                <Box p={3}>
                                    { error }
                                    <Grid container>
                                        <Grid xs={12} md={6}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="eventType">
                                                    Nombre de Comunicado <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="name"/>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="eventFrom">
                                                    Tipo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select name="type">
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="comunicado">Comunicado</MenuItem>
                                                    <MenuItem value="evento">Evento</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="eventFrom">
                                                    Destinatarios <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select name="to"
                                                    onBlur={e => handleDisable(e.target.value)}>
                                                    <MenuItem disabled selected value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="all">Todos</MenuItem>
                                                    <MenuItem value="teacher">Maestros</MenuItem>
                                                    <MenuItem value="tutor">Padres de familia</MenuItem>
                                                    <MenuItem value="group">Grupo</MenuItem>
                                                    <MenuItem value="child">Alumnos</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="nivel">
                                                    Nivel <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="level"
                                                    onBlur={ e => handleSelectLevel()}
                                                >
                                                    <MenuItem disabled selected value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Kinder">Kinder</MenuItem>
                                                    <MenuItem value="Primaria">Primaria</MenuItem>
                                                    <MenuItem value="Secundaria">Secundaria</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="turno">
                                                    Turno <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="turn"
                                                    onBlur={e => handleSelectTurn()}
                                                >
                                                    <MenuItem disabled selected value=""><em>Seleccionar</em></MenuItem>
                                                    { turnInfo.map( i => <MenuItem value={i} key={i} >{ i }</MenuItem> ) }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="grado">
                                                    Grado <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="grade"
                                                    onBlur={e => handleSelectGrade()}
                                                >
                                                    <MenuItem disabled selected value=""><em>Seleccionar</em></MenuItem>
                                                    { gradeList.map( i => <MenuItem value={i.grade} key={i.grade} >{ i.grade }</MenuItem> ) }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="grupo">
                                                    Grupo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="group"
                                                >
                                                    <MenuItem disabled selected value=""><em>Seleccionar</em></MenuItem>
                                                    onBlur={e => handleSelectGrade()}
                                                    { groupList.map( i => <MenuItem value={i} key={i}>{ i }</MenuItem> ) }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="fecha">
                                                    Fecha <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField
                                                    name="date" type="date"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="Hora">
                                                    Hora <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="time" type="time" 
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="eventMessage">
                                                    Detalles del Evento <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="eventMessage"
                                                    multiline={true}
                                                    rows={4}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2}>
                                                <>
                                                    {
                                                        loading ?

                                                            <a href={ docOpcional } target="_blank" rel="noopener noreferrer"> 
                                                                <Typography  className="txtDescTitle fonsty"> { file.name } {file? <VisibilityIcon className="kipper_txt-grey" />:""} </Typography>
                                                            </a>
                                                        :
                                                            <CircularProgress/>
                                                    }
                                                
                                                    <InputLabel shrink id="uploadFile">
                                                        <Typography variant="body1" >{file? "Editar documento" : "Documento "  }  </Typography>
                                                    </InputLabel>
                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                    <label htmlFor="docOpcional" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img className="imW" src={ upload } alt=""/>
                                                                <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                            
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                                </>
                                                
                                                <Typography className="" variant="caption" display="block" gutterBottom>
                                                    *El documento debe ser tipo JPG, JPG, PNG Y PDF
                                                </Typography>
                                                { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid> 
                </Form>   
            </Formik>
        </>
    )
}
