import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../Components/Global/PageHeader'

// Materia UI
import PersonIcon from '@material-ui/icons/Person';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { Box, Grid, Typography, Hidden } from '@material-ui/core'
import HomeworkItem from '../../Components/childs/HomeworkItem';
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../../Components/functions/Auth';
import AssistanceChildItem from '../../Components/childs/AssistanceChildItem';
import NavChildPhone from './NavChildPhone/NavChildPhone';


const HomeworkChild = () => {
    const db = firebase.database();
    const history = useHistory();
    const match = useRouteMatch('/alumno/materia/details/:id');
    const MateriaID = match.params.id;
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    const [details, setDetails] = useState(1)
    const [MateriaInfo, setMateriaInfo] = useState('');
    const [grupoInfo, setGrupoInfo]     = useState('');
    const [homework, setHomework]       = useState([]);
    const [assistance, setAssistance]   = useState([]);
    const [evaluations, setevaluations] = useState(null);
    const [promedio, setpromedio] = useState(null)
    const [subject, setSubject] = useState([])
    
    useEffect(() => {
        if(infoSchool && info){
            let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}/`);
            groupRef.once('value').then(  (snapshot) => {
                setGrupoInfo( snapshot.val() )
            })
            getInfo();
            getSubject();
        }
    }, [info , infoSchool]);

    const getInfo = () => {
        let listHomework = [];
        let listAssistance = [];
        let db = firebase.database();
        let groupRef =  db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${MateriaID}`);
        groupRef.once('value').then(  (snapshot) => {
            let item = snapshot.val()
            let homeworks = item?.homework
            let assistances = item?.assistance
            let eval_Array = item?.evaluations

            setMateriaInfo( snapshot.val() )

            if(homeworks){
                Object.entries(homeworks).forEach(element => {
                    listHomework.push( element[1] )
                });
                setHomework( listHomework )
            }else{
                setHomework( false )
            }
            if(assistances){
                Object.entries(assistances).forEach(element => {
                    let itemInfo = element[1]
                    listAssistance.push({key: element[0], itemInfo})
                })
                setAssistance( listAssistance )
            }else{
                setAssistance( false )
            }
            if(eval_Array && eval_Array[infoSchool]){
                Object.entries(evaluations).forEach(element =>{
                    let evaluationsArray = Object.entries(element[1])
                    setevaluations(evaluationsArray)
                    evalFormat(evaluationsArray)
                })
            }

        })
    }
    const evalFormat = (arrayEval) =>{
        var count = 0;
        var resultado = 0;
        arrayEval.forEach(element =>{
            if(element[1]){
                count++
            }
            resultado += element[1]
        })
        console.log(arrayEval)
        if(count){
            setpromedio((resultado/count).toFixed(1));
        }
    }
    const getSubject = () =>{
        let groupRef = db.ref(`school_subjects/${MateriaID}`);
        groupRef.once('value').then(  (snapshot) => {
            setSubject( snapshot.val() )
        })
    }
    

    return (
        <Grid container >
            <NavChildPhone />
            <Grid item xs={12} >
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title={`${subject?.title}`}
                        text="Detalles de la materia"
                        btn_back="/"
                        btn_action={true}
                        btn_action_text="Guardar"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box my={3} boxShadow={3} className="box_w" >
                    <Grid container >
                        <Grid item xs={12} className="bg-primary" >
                            <Box px={5} py={2}   borderRadius={10} >
                                <Typography variant="h5" className="text-white" >
                                    Evaluación
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="bg-white text-content" >
                            <Box px={5} py={3} >
                                <Grid container >
                                    <Grid item xs={12} className="" >
                                        <Box display="flex" mb={2} >
                                            <Typography variant="subtitle1" className="" >
                                                <b> Parciales: </b> 
                                                {
                                                    evaluations
                                                    ? evaluations.map(i =>
                                                    <span className="bubble-primary" >&nbsp;{i[1]}&nbsp;</span>
                                                    )
                                                    :<span>&nbsp;No hay evaluaciones</span>
                                                }
                                            </Typography>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Typography variant="subtitle1" className="" >
                                                <b> Promedio: </b>
                                                {promedio
                                                    ?<span className="bubble-secondary" >{promedio}</span>
                                                    :<span className="bubble-secondary" >0</span>
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className="" >
                                        <Box mb={1} >
                                            <Typography variant="h5" className=""  >
                                                Acerca de la materia
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1} >
                                                { subject?.details }
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1}  >
                                                <PersonIcon />&nbsp;
                                                { MateriaInfo?.teacher_name ? MateriaInfo?.teacher_name  : grupoInfo?.teacher?.teacher_name }
                                            </Typography>
                                        </Box>
                                        <Box mb={1} >
                                            <Typography variant="body1" className="" mb={1}  >
                                                <WatchLaterIcon/>&nbsp;
                                                { 
                                                    MateriaInfo?.days ? 
                                                        <>
                                                            { MateriaInfo?.days?.monday && 'Lunes ' }
                                                            { MateriaInfo?.days?.tuersday && 'Martes '}
                                                            { MateriaInfo?.days?.wensday && 'Miercoles ' }
                                                            { MateriaInfo?.days?.tursday && 'Jueves ' }
                                                            { MateriaInfo?.days?.friday && 'Viernes ' }
                                                            { MateriaInfo?.days?.saturday && 'Sabado ' }
                                                            de { MateriaInfo?.schedule[0] } a { MateriaInfo?.schedule[0] }
                                                            
                                                        </>
                                                        : 
                                                        'Sin definir'
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Box mt={3} 
                                            className="pointer" 
                                            onClick={ () => setDetails(1) } 
                                        >
                                            <Typography variant="h6" align="center" className={ details == 1 ? "kipper_txt-color" : "text-placeholder"  } >
                                                Tareas
                                            </Typography>
                                            <hr className={ details == 1 ? "divider_materia-active" : "divider_materia"  } />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Box mt={3}
                                            className="pointer" 
                                            onClick={ () => setDetails(2) } 
                                        >
                                                <Typography variant="h6" align="center" className={ details == 2 ? "kipper_txt-color" : "text-placeholder"  } >
                                                    Asistencias
                                                </Typography>
                                            <hr className={ details == 2 ? "divider_materia-active" : "divider_materia"  } />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Box mt={3} display="block">
                                            {                                            
                                                details == 1 ?
                                                    <>
                                                        {
                                                            homework ?
                                                                homework.map( homework => 
                                                                    <HomeworkItem 
                                                                        key={homework.id}
                                                                        MateriaID={ MateriaID }
                                                                        TareaID={ homework.id }
                                                                        title={ homework.title }
                                                                        details={ homework.description }
                                                                        date={ homework.fulltime }
                                                                    />
                                                                )
                                                                :
                                                                <Typography variant="h6" className="kipper_txt-grey" >
                                                                    No hay nada que evaluar         
                                                                </Typography>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {   
                                                            assistance ?
                                                                assistance.map( assistance => 
                                                                    <AssistanceChildItem 
                                                                        assistanceId={ assistance.key }
                                                                        materiaID={ MateriaID }
                                                                        date={ assistance.itemInfo.time }
                                                                    />
                                                                )
                                                            :   
                                                            <Typography variant="h6" className="kipper_txt-grey" >
                                                                No hay asistencias registradas         
                                                            </Typography> 
                                                        }
                                                    </>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeworkChild
