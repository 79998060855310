import React, { useState, useContext, useEffect  } from 'react'
import { useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../Components/functions/Auth";
//Component
import PageHeader from './../Components/Global/PageHeader';
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// FireBases
import * as firebase from 'firebase';
import NavTutorPhone from './Tutores/NavTutorPhone/NavTutorPhone';
import NavTeacherPhone from './Maestros/NavTeacherPhone/NavTeacherPhone';
import NavChildPhone from './Child/NavChildPhone/NavChildPhone';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';
import moment from 'moment';

const ComunicadosDetalles = () => {

    const match         = useRouteMatch('/events/details/:id');
    const comunicadoID  = match.params.id;
    const {currentUser,userType} = useContext(AuthContext);
    
    const [turn, setTurn]               = useState('');
    const [grade, setGrade]             = useState('');
    const [group, setGroup]             = useState('');
    const [file, setFile]               = useState('');
    const [eventInfo, setEventInfo]     = useState('');

    const loadData = async () => {
        // Get Data
        let db = firebase.database();
        let ref = db.ref(`comunicados/${comunicadoID}`);
        const snapshotData =  await ref.once('value')
        const _viewedList = snapshotData.val().viewed;
        setEventInfo( snapshotData.val() );
        setTurn( snapshotData.val().details.turn );
        setGrade( snapshotData.val().details.grade );
        setGroup( snapshotData.val().details.group );
        setFile( snapshotData.val()?.document?.document_1 || "" );
        // set like a viewed
        let childRef = db.ref(`comunicados/${comunicadoID}`);
        childRef.update({
            viewed:{
                ..._viewedList,
                [currentUser]: true
            }
        }, 
        (error) => {
            if (error) {
                console.log(error)
            } 
        });
    }
    
    useEffect(() => { loadData(); }, []);

    return(
        <Grid container>
            {/* { (userType == 'teacher')&&<NavTeacherPhone />} */}
            {/* { (userType == 'tutor')&&<NavTutorPhone />} */}
            { (userType == 'child')&&<NavChildPhone />}
            {/* { (userType == 'school')&&<NavEscuelaPhone />} */}
            
            <Grid xs={8} sm={12} md={12}>
                <Box pb={3} id="details_events_header">
                    <PageHeader 
                        title="Detalles"
                        text="Detalles del evento, cita o comunicado seleccionado."
                        btn_back="/"
                        btn_action=""
                    />
                </Box>
            </Grid>
            <Grid sm={12}>
                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3} >
                        <Grid container >
                            <Grid item xs={12} >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Grid>
                            <Grid item xs={10} >
                                <Box px={3}  pt={3} >
                                    <Typography variant="h4" className="kipper_title">
                                        <b> { eventInfo?.name } </b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} pb={3}>
                                <Box px={3} pb={3} >
                                    <span className="kipper_txt-grey">
                                        {
                                            eventInfo.date&&(
                                                moment(eventInfo.date).format('LL')
                                            )
                                        }
                                        {
                                            eventInfo.time&&(
                                                ` a las ${eventInfo.time}`
                                            )
                                        }
                                    </span><br/>
                                    <span className="kipper_txt-grey">
                                        { eventInfo?.receiver_event === 'all' && ' Evento global' }
                                        { eventInfo?.receiver_event === 'teachers' && ' Evento para Maestros' }
                                        { eventInfo?.receiver_event === 'tutor' && ' Evento para Padres de familia' }
                                        { eventInfo?.receiver_event === 'child' && ' Evento para Alumnos' }
                                    </span><br/>
                                    <span className="kipper_txt-grey">
                                        { turn  && `Turno ${turn}` }
                                        <br/>
                                        { grade && `Grado ${grade}` }
                                        <br/>
                                        { group && `Grupo ${group}` }
                                    </span><br/><br/>
                                    <Typography variant="h5" className="kipper_txt-grey">
                                        <b> Detalles del evento </b>
                                    </Typography>
                                    <p className="kipper_txt-grey">
                                        { eventInfo.details_event }
                                        {  file &&  <img src={ file } alt="" className="event_img"/> }
                                    </p>
                                </Box>
                            </Grid>
                        </Grid> 
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ComunicadosDetalles