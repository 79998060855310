import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDfI49S1TDIE7pohLK7iuCTJ4ahhdwnHNI",
  authDomain: "gpstracker-5f3ac.firebaseapp.com",
  databaseURL: "https://gpstracker-5f3ac.firebaseio.com",
  projectId: "gpstracker-5f3ac",
  storageBucket: "gpstracker-5f3ac.appspot.com",
  messagingSenderId: "886124875230",
  appId: "1:886124875230:web:0c69ff4ee17e73043e214f"
});

export default app;
