import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

//Resources
import portada from './../../Assets/Img/General/portada_info-item.png';
import file from './../../Assets/Img/General/File.png';
import pencil from './../../Assets/Img/Home/Icon_Pencil.png';
import user from './../../Assets/Img/General/placeholder_man.png';

//materialUI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, Modal, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';

//Style
import '../../Assets/Styles/Items/imformationItem.css';

//Components
import ModalShow from '../../Components/Global/ModalSinSubmit';

//firebase
import * as firebase from 'firebase';


const ImformationItem = ( { tutorID, childID, avatar, name, lastname, email, phone, type, delegado, refresh, setRefresh, numberTutor } ) => {
    let history = useHistory();

    const [tutorInfo, setTutorInfo] = useState('');

    /* Para obtener documentos del tutor / delegado */
    const [documents,setDocuments] = useState({
        id:null,
        opcional:null,
        proof_addres:null,
    });

    /* Modal */
    const [ modalStaus2, setModalStaus2 ] = useState( false );
    const [ isDeleted2, setIsDeleted2 ]   = useState( false );
    const [ isShow2, setIsShow2 ]         = useState( false );
    const [ modalMsg2, setModalmsg2 ]     = useState("");

    /* Funciones del modal */
    const ModalOpen2 = () => {
        setModalStaus2(true);
    };

    const ModalClose2 = () => {
        setModalStaus2(false);
    };

    /* Obtenemos informacion del tutor / delegado */
    useEffect(() => {
        if( tutorID ){
            let db = firebase.database();
            let tutorRef = db.ref(`tutors/${tutorID}`);
            tutorRef.once('value').then(function(snapshot) {
                if ( snapshot.val() ) {
                    setTutorInfo( snapshot.val() );
                    setDocuments({
                        id: (snapshot.val().documents?.id && snapshot.val().documents?.id.length > 0) && snapshot.val().documents.id,
                        opcional:(snapshot.val().documents?.opcional && snapshot.val().documents?.opcional.length > 0) && snapshot.val().documents.opcional,
                        proof_addres:(snapshot.val().documents?.proof_addres && snapshot.val().documents?.proof_addres.length > 0) && snapshot.val().documents.proof_addres,
                    });                  
                }
                else{
                    setTutorInfo( false );
                }
            })
        }
        else{
            setTutorInfo( false )
        }
    },[tutorID]);

    const tutorordele = delegado ?delegado :type
    const tutorCheck = tutorID ?tutorID :tutorordele;

    const handleDeleteUser =async(e,status)=>{
        if(status){//Delete

            let db = firebase.database();
            let tutorRef = db.ref(`tutors/${tutorID}`);
            tutorRef.update(
                { status: "inactive", },
                (error) => {
                    if (error) {
                        setIsShow2( true );
                        setIsDeleted2( false );
                        setModalmsg2( "Lo sentimos, algo extraño paso. Porfavor intenta de nuevo" );
                        ModalOpen2();
                    }
                    else {
                        //Delete tutor from child
                        let db2 = firebase.database();
                        let childRef = db2.ref(`childs/${childID}/tutors`);
                        childRef.update(
                            { [numberTutor]: "", },
                            (error) => {
                                if(error){
                                    setIsShow2( true );
                                    setIsDeleted2( false );
                                    setModalmsg2( "Lo sentimos, algo extraño paso. Porfavor intenta de nuevo" );
                                    ModalOpen2();
                                }else{
                                    setIsShow2( true );
                                    setIsDeleted2( false );
                                    setModalmsg2( "Usuario eliminado" );
                                    ModalOpen2();
                                    setRefresh(refresh + 1);
                                }
                            }
                        );
                        
                    }
                }
            );
            
        }else{//Cancel
            ModalClose2()
        }      
    }

    const handleOpen =()=>{
        setIsShow2(false);
        ModalOpen2();
        setIsDeleted2(true);
    }

    const modalGoTo = ( e, link ) => {
        ModalClose2();

        // if(modalMsg2 !== 'Lo sentimos, algo extraño paso. Porfavor intenta de nuevo') history.go(0);
    };

    return(
        <Box m={3} pb={2} className="box_info-content informationItem__main" boxShadow={3}>
            <Modal
                open={modalStaus2}
                onClose={ModalClose2}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted2 ?
                            <ModalShow
                                title=" ¿Estás seguro de eliminar al usuario? "
                                confirmDelete={ true }
                                Delete={ handleDeleteUser }
                            /> : null
                    }
                    {
                        isShow2 ?
                            <ModalShow
                                title={ modalMsg2 }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> : null
                    }
                    
                </Grid>
            </Modal>
            <Box className="" >
                <img src={ portada } alt="" class="box_info-content-img" />
            </Box>
            <Box className="box_info-img">
                <Grid container className="boxheader">
                    <Grid item xs={5}>
                        <Box pl={3} className="box_info-upload-img">
                            <img src={ tutorInfo.avatar ? tutorInfo.avatar : user } alt="" className="previw_img rounded fit"/>
                            <Typography variant="h6" className="kipper_txt-grey">
                                { type === 1 ? 'Tutor' : 'Delegado' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <Box py={3} px={1}>
                            { tutorID 
                                ?
                                <>
                                    <Grid container className="boxButtons">
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Button variant="outlined" className="kipper_btn-outline-primary" onClick={handleOpen} disableElevation>
                                                Eliminar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Button variant="outlined" className="kipper_button2" type="submit" disableElevation onClick={()=>history.push(`/escuela/tutor/editar/${tutorID}`)}>
                                                Detalles
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Link to={`/escuela/tutor/agregar/${childID}/${tutorCheck}`} >
                                                <Button variant="contained" className="kipper_button" fullWidth type="submit" disableElevation>
                                                    Agregar
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </> 
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container>
                    <Grid item xs={12} className="boxDocuments">
                        <Box p={2}>
                            {
                                tutorID && (documents && documents.id) &&
                                <div className="row">
                                    <a href={documents.id} target="_blank" >
                                        Documento de identificacion &nbsp;<VisibilityIcon className="kipper_txt-grey" />
                                    </a>
                                </div>
                            }
                            {
                                tutorID && (documents && documents.proof_addres) &&
                                <div className="row">
                                    <a href={documents.proof_addres} target="_blank" >
                                        Documento de domicilio &nbsp;<VisibilityIcon className="kipper_txt-grey" />
                                    </a>
                                </div>
                            }
                            {
                                tutorID && (documents && documents.opcional) &&
                                <div className="row">
                                    <a href={documents.opcional} target="_blank" >
                                        Documento opcional &nbsp;<VisibilityIcon className="kipper_txt-grey" />
                                    </a>
                                </div>
                            }
                            {
                                (tutorID && ( !documents || (!documents.id && !documents.proof_addres && !documents.opcional) ) ) && <h5 target="_blank" className="withoutDocuments-text">Sin documentos</h5>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}> 
                            <TextField label="CODIGO DE TUTOR" type="text" onlyShow fullWidth value={ tutorID ? tutorID : ""  }  
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}> 
                            <TextField label="NOMBRE" type="text" onlyShow fullWidth value={ tutorID ? tutorInfo.name : "" }  
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}> 
                            <TextField label="APELLIDOS" type="text" onlyShow fullWidth value={ tutorID ? tutorInfo.last_name : "" }  
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}> 
                            <TextField label="TELEFONO" type="text" onlyShow fullWidth value={ tutorID ? tutorInfo.contact?.phone : "" } 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}> 
                            <TextField label="CORREO ELECTRONICO" type="text" onlyShow fullWidth value={ tutorID ? tutorInfo.contact?.email : "" } 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ImformationItem