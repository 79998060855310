import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { Grid, Box, Typography } from '@material-ui/core'
import PageHeader from '../../Components/Global/PageHeader'
import EventoItem from './../../Components/Global/EventoItem';


// firebases
import * as firebase from 'firebase';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

function Calendar() {
    
    const { infoSchool }   = useContext(AuthContext);
    const [ appoimentsApprove, setAppoimentsApprove ]  = useState([]);
    const [ appoimentsRejected, setAppoimentsRejected ]  = useState([]);
    const [ appoimentsWaiting, setAppoimentsWaiting ]  = useState([]);
    const [ appoimentsWaitingApprove, setAppoimentsWaitingApprove ]  = useState([]);
    const [ status, setStatus ]  = useState( 1 );

    useEffect(() => {
        if(infoSchool){
            let db = firebase.database();
            let ref = db.ref(`comunicados`);
            let listRejected = [];
            let listApprove = [];
            let listWaiting = [];
            let listWaitingApprove = [];
            // 0 === no status
            // 1 === Approve
            // 2 === Rejected 
            ref.orderByChild("date").once('value').then(function(snapshot) {
                snapshot.forEach( item => {
                    let itemInfo = item.val();
                    if ( itemInfo.status === "active"  && itemInfo.school === infoSchool.school && itemInfo.type === 'appointment' && itemInfo.confirm === 1 && itemInfo?.teacher?.teacher === infoSchool.teacher_key ) {
                        listApprove.push( itemInfo )
                    }
                    if ( itemInfo.status === "active"  && itemInfo.school === infoSchool.school && itemInfo.type === 'appointment' && ( itemInfo.confirm === 2 ) && itemInfo?.teacher?.teacher === infoSchool.teacher_key) {
                        listRejected.push( itemInfo )
                    }

                    if ( itemInfo.status === "active"  && itemInfo.school === infoSchool.school && itemInfo.type === 'appointment' && ( itemInfo.confirm === 0 ) && itemInfo?.teacher?.teacher === infoSchool.teacher_key && itemInfo.receiver_event === "appointment_tutor") {
                        listWaiting.push( itemInfo )
                    }

                    if ( itemInfo.status === "active"  && itemInfo.school === infoSchool.school && itemInfo.type === 'appointment' && ( itemInfo.confirm === 0 ) && itemInfo?.teacher?.teacher === infoSchool.teacher_key && itemInfo.receiver_event === "appointment") {
                        listWaitingApprove.push( itemInfo )
                    }
                })
                setAppoimentsApprove( listApprove );
                setAppoimentsRejected( listRejected );
                setAppoimentsWaiting( listWaiting );
                setAppoimentsWaitingApprove( listWaitingApprove );
            });
        }
    }, [infoSchool]);
        
    return (
        <Grid container className="padres_grid">
            {/* <NavTeacherPhone /> */}
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight" >
                    <PageHeader 
                        title="Citas"
                        text="Apruebe o decline citas con padres de familia" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box display="flex"className="Box_Tabs_Appointments box_w" >
                    <Box 
                        p={2} 
                        className={status === 1 ? "box_tab-active" : "box_tab-inactive" }  
                        onClick={ () => setStatus(1) }
                    >
                        Aceptadas
                    </Box>
                    <Box 
                        p={2} 
                        className={status ===3 ? "box_tab-active" : "box_tab-inactive" }  
                        onClick={ () => setStatus(3) }
                    >  
                        En revisión
                    </Box>
                    <Box 
                        p={2} 
                        className={status === 2 ? "box_tab-active" : "box_tab-inactive" }  
                        onClick={ () => setStatus(2) }
                    >  
                        Denegadas
                    </Box>
                    <Box 
                        p={2} 
                        className={status === 4 ? "box_tab-active" : "box_tab-inactive" }  
                        onClick={ () => setStatus(4) }
                    >  
                        Por aprobar
                    </Box>
                </Box>
                <Box 
                    className="box_tab box_w" 
                    boxShadow={3} 
                    p={3} 
                >
                    <Grid container >
                    { 
                        (status === 1) &&
                            [ appoimentsApprove.length > 0 ?
                                appoimentsApprove.map( appoiment => 
                                    <Grid item sm={6} key={ appoiment.id }>
                                        <Box m={3}>
                                            <EventoItem  
                                                key={ appoiment.id }
                                                isOutline={ true }
                                                id={ appoiment.id }
                                                type={ appoiment.type } 
                                                title={ `${appoiment.name}` } 
                                                body={ `${appoiment.details_event}` } 
                                                date={ `${appoiment.date}` } 
                                                btn_to={ `/teacher/citas/detalles/${appoiment.id}` }
                                                denied_appoiment = { appoiment.confirm }

                                                // deleteEvent={ isElementDeleted }
                                            /> 
                                        </Box>
                                    </Grid>
                                )
                                : <Typography variant="h6" className="kipper_title" > Sin citas por el momento </Typography>
                            ]
                        }
                        
                        {
                            (status === 2) &&
                            [
                                appoimentsRejected.length > 0 ?
                                appoimentsRejected.map( appoiment => 
                                    <Grid item sm={6} key={ appoiment.id }>
                                        <Box m={3}>
                                            <EventoItem  
                                                key={ appoiment.id }
                                                isOutline={ true }
                                                id={ appoiment.id }
                                                type={ appoiment.type } 
                                                title={ `${appoiment.name}` } 
                                                body={ `${appoiment.details_event}` } 
                                                date={ `${appoiment.date}` } 
                                                btn_to={ `/teacher/citas/detalles/${appoiment.id}` }
                                                denied_appoiment = { appoiment.confirm }
                                            /> 
                                        </Box>
                                    </Grid>
                                )
                                : <Typography variant="h6" className="kipper_title" > Sin citas por el momento  </Typography>
                            ]
                        }
                        {                            
                            (status === 3) &&
                            [
                                appoimentsWaiting.length > 0 ?
                                    appoimentsWaiting.map( appoiment => 
                                        <Grid item sm={6} key={ appoiment.id }>
                                            <Box m={3}>
                                                <EventoItem  
                                                    key={ appoiment.id }
                                                    isOutline={ true }
                                                    id={ appoiment.id }
                                                    type={ appoiment.type } 
                                                    title={ `${appoiment.name}` } 
                                                    reason={ appoiment.reason }
                                                    body={ `${appoiment.details_event}` } 
                                                    btn_to={ `/teacher/citas/detalles/${appoiment.id}` }
                                                    date={ `${appoiment.date}` } 
                                                    denied_appoiment = { appoiment.confirm }
                                                /> 
                                            </Box>
                                        </Grid>
                                    )
                                    :
                                    <Typography variant="h6" className="kipper_title" >
                                        Sin citas por el momento 
                                    </Typography>
                            ]
                        }
                        {                            
                            (status === 4) &&
                            [
                                appoimentsWaitingApprove.length > 0 ?
                                    appoimentsWaitingApprove.map( appoiment => 
                                        <Grid item sm={6} key={ appoiment.id }>
                                            <Box m={3}>
                                                <EventoItem  
                                                    key={ appoiment.id }
                                                    isOutline={ true }
                                                    id={ appoiment.id }
                                                    type={ appoiment.type } 
                                                    title={ `${appoiment.name}` } 
                                                    reason={ appoiment.reason }
                                                    body={ `${appoiment.details_event}` } 
                                                    btn_to={ `/teacher/citas/detalles/${appoiment.id}` }
                                                    date={ `${appoiment.date}` } 
                                                    denied_appoiment = { appoiment.confirm }
                                                /> 
                                            </Box>
                                        </Grid>
                                    )
                                    :
                                    <Typography variant="h6" className="kipper_title" >
                                        Sin citas por el momento 
                                    </Typography>
                            ]
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Calendar
