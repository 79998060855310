import React, { useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import ModalShow from '../../Components/Global/Modal';
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user2 from './../../Assets/Img/General/placeholder_teacher-f.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Modal from '@material-ui/core/Modal';

import { Grid, Box, Typography, Divider, Button } from '@material-ui/core';
import * as firebase from 'firebase';

export default function Homework({homework, to, refreshafter}) {
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/tareas/:grupo/:materia');
    const childID = match.params.grupo;
    const SubjectID = match.params.materia;
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const [materia, setMateria] = useState('')
    
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    function ModalOpen ()  {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };  

    const borrarTarea = ( e, Delete ) => {
        e.preventDefault();
        
        if ( Delete ) {
            let db = firebase.database();
            var ref = db.ref(`schools/${infoSchool.school}/groups/${childID}/school_subjects/${SubjectID}/homework/${homework.id}`)
    
            ref.remove().then(function() {
                console.log("Remove succeeded.")
                // refreshafter();
                history.push(`/teacher/materias/group/${childID}/`)
            })
            .catch(function(error) {
                console.log("Remove failed: " + error.message)
            });    
        }else{
            ModalClose()
        }
    };

   

/* Esta funcion esta asignada a un boton para borrar la tarea cuando este sea presionado  */
    function BorrarTarea() {

        let db = firebase.database();
        var ref = db.ref(`schools/${infoSchool.school}/groups/${childID}/school_subjects/${SubjectID}/homework/${homework.id}`)

        ref.remove().then(function() {
            console.log("Remove succeeded.")
            // refreshafter();
            history.push(`/teacher/materias/group/${childID}/`)
        })
        .catch(function(error) {
            console.log("Remove failed: " + error.message)
        });

    }

    function Day(){
        if(homework.fulltime){
            var day = homework.fulltime
            var array = day.split('-')
            return array[2]
        }else{
            return null
        }
    }

    function Month(){
        if(homework.fulltime){
            var month = new Date(homework.fulltime)
            switch (month.getMonth()) {
                case 0:
                    return 'Enero'
                break;
                case 1:
                    return 'Febrero'
                break;
                case 2:
                    return 'Marzo'
                break;
                case 3:
                    return 'Abril'
                break;
                case 4:
                    return 'Mayo'
                break;
                case 5:
                    return 'Junio'
                break;
                case 6:
                    return 'Julio'
                break;
                case 7:
                    return 'Agosto'
                break;
                case 8:
                    return 'Septiembre'
                    break;
                case 9:
                    return 'Octubre'
                break;
                case 10:
                    return 'Noviembre'
                break;
                case 11:
                    return 'Diciembre'
                break;
            }
        }else{
            return null
        }
    }         
    if(homework){
        return (
            <Box >
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        <ModalShow 
                            title={ '¿Estas seguro de eliminar esta tarea?' }
                            confirmDelete={ true }
                            Delete={ borrarTarea }
                            //goTo={ modalLink }
                        />
                    </Grid>
                </Modal>
                <Box>
                    <Box p={3}>
                        <Grid container direction="row" justify="flex-start"  alignItems="center" spacing={2} mb={6}>
                            <Grid id="" item xs={2}>
                                <span className="Kipper_title-black">
                                    {Day()}
                                </span> <br/>
                                <span className="">
                                    {Month()}    
                                </span>
                            </Grid>
                            <Grid item xs={4} className="HomeTittle">
                                <span className="">
                                    {homework.title}
                                </span> <br/>
                                <span className="">
                                    {homework.description}
                                </span>
                            </Grid>
                            <Grid item xs={3}>
                            <Link to={to}>
                                <Button id="buttonDetHomework" className="kipper_button " variant="outlined" fullWidth>Detalles</Button>
                            </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Button id="buttonDelHomework" className="kipper_button" variant="outlined" fullWidth onClick={e => ModalOpen()} >Borrar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Divider/>
            </Box>       
       
        )
    }else{
        return (
            <span className="">
                No Homeworks
            </span>
        )
    }
}
