import React from 'react'
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles }  from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import userIMG from './../../Assets/Img/General/placeholder_boy.png';

const useStyles = makeStyles((theme) =>({
    btn : {
        color:'#F8B236',
        border:'2px solid #F8B236',
        borderRadius : '50%',
        padding:'3px'
    },
    toRight : {
        display:'flex',
        justifyContent:'flex-end'
    },
    toBold :{
        fontWeight:'bold'
    },
    divi : {
        width:'100%',
        listStyle:'none',
        marginLeft:0
    },
    avt :{
        width:'75px',
        height:'75px'
    }
}))

export const ChildHomeItem = ({ title, link, fronTutor, avatar, turno, grado, details }) => {

    const classes = useStyles();

    if ( fronTutor ) {
        return (
            
            <Grid  item md={6}>
                <Link to={`${link}`}>
                    <Box id="kiper_tutor_alumno_alumnoItem"  p={3} m={3} className="bg-white" borderRadius={10} boxShadow={3} >
                        <Grid container >
                            <Grid item md={3} spacing={2} p={2}>
                                <img src={ avatar ? avatar : userIMG } alt="" className={ classes.avt } />
                            </Grid>
                            <Grid item md={9} p={2}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" >
                                        <Typography id="kiper_tutor_alumno_alumnoNameItem" variant="subtitle1" className="">
                                            <b> { title } </b>
                                        </Typography>
                                        <ArrowForwardIosIcon className={classes.btn} />
                                    </Grid>
                                <hr/>
                                <Grid container direction="row" justify="flex-start" alignItems="center" >
                                    <Box className="kipper_txt-grey" >
                                        <Typography variant="subtitle1">
                                            
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                    <Box className="kipper_txt-grey" >
                                        <Typography variant="body1">
                                            { turno }
                                        </Typography>
                                    </Box>
                                    <Box className="kipper_txt-grey" >
                                        <Typography variant="body1">
                                            { grado }
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Link>

            </Grid>
        )
    }
    else{
        return (
            <Grid id="childHome_gridSubjectItem" item md={6}>
                <Link to={`${link}`}>
                    <Box id="childHome_boxSubjectItem" p={3} m={3} className="bg-white" borderRadius={10} boxShadow={3} >
                        <Grid container >
                            <Grid id="childHome_gridAvatarItem" item md={3} spacing={2} p={2}>
                                <img src={ userIMG } alt="" className={ classes.avt } />
                            </Grid>
                            <Grid id="childHome_gridTextItem" item md={9} p={2}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" >
                                        <Typography id="childHome_typographyTitle" variant="subtitle1" className="">
                                            <b> { title } </b>
                                        </Typography>
                                        <ArrowForwardIosIcon className={classes.btn} />
                                    </Grid>
                                <hr/>
                                <Grid container direction="row" justify="flex-start" alignItems="center" >
                                    <Box className="kipper_txt-grey" >
                                        <Typography variant="subtitle1">
                                            
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                    <Box id="childHome_boxDetails" className="kipper_txt-grey" >
                                        <Typography id="childHome_typographyDetails" variant="body1">
                                            { details }
                                        </Typography>
                                    </Box>
                                    {/* <Box className="kipper_txt-grey" >
                                        <Typography variant="body1">
                                            Fecha
                                        </Typography>
                                    </Box> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Link>
            </Grid>
        )
    }
}
