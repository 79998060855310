import React from 'react';
import { Link } from 'react-router-dom';
//MAterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
const ContactItem = ( { 
    id,
    img,
    error, 
    name, 
    level,
    sortKey,
    info,
    time, 
    btnTo, 
    btn2To, 
    btnDetails, 
    btnSelect, 
    btnRemove, 
    onSelect, 
    onDelete, 
    onRemove, 
    isChat, 
    isSubjects, 
    SubjectsHomework, 
    isSchollSubjects,
    isGroups,
    isEvaluation,
    btnFirstButton,
    onlyOneButton,
    deleteGroup,
} ) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    if ( isSubjects ) {
        return(
            <Box p={3}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={matches ?2 :0}>
                    <Grid item xs={6}>
                        <span className="Kipper_title-black">
                            { name } 
                        </span> <br/> 
                        <span className="">
                            { info }
                        </span><br/> 
                        <span className="">
                            { time }
                        </span>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Link to={ btnDetails }>
                            <Button id="kipper_button_list_subjectHomework" className="kipper_btn-outline-primary" variant="outlined" fullWidth>Tareas</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Link to={ btnTo }>
                            <Button id="kipper_button_list_subjectAssistances" className="kipper_button" variant="outlined" fullWidth>Asistencias</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    if ( isSchollSubjects ) {
        return(
            <Box id="kipper_boxContainer_subject_contactItem" p={3}>
                <Grid   container direction="row" justify="space-around" alignItems="center" spacing={matches ?2 :0}>
                    <Grid id="kipper_item_subject_contactItem"  item xs={6} md={6}>
                        <span className="Kipper_title-black">
                            { name } 
                        </span> <br/> 
                        <span className="">
                            { info }
                        </span><br/> 
                        <span className="">
                            { time }
                        </span>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <form onSubmit={ e => onDelete( e, id ) }>
                            <Button id="kipper_button_deleteSubject_contactItem"  className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Eliminar </Button>
                        </form>                        
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Link to={ btnDetails }>
                            <Button id="kipper_button_detailsSubject_contactItem" className="kipper_button" variant="outlined" fullWidth> Detalles </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    if ( isChat ) {
        return(
            <Box p={3}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Box borderRadius="50%">
                            <img src={ img } alt="" width="100px" height="100px" style={{borderRadius:'50%'}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <span className="Kipper_title-black">
                            { name } 
                        </span> <br/> 
                        <span className="">
                            { info }
                        </span>
                    </Grid>
                    <Grid item xs={2}> </Grid>
                    <Grid item xs={2}>
                        <Link to={ btnTo }>
                            <Button className="kipper_button" variant="outlined" fullWidth>Ir a chat</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    if ( isGroups ) {
        return(
            <Box id="kipper_boxContainer_subject_contactItem" p={3}>
                <Grid   container direction="row" justify="space-around" alignItems="center" spacing={matches ?2 :0}>

                    <Grid item xs={2}>
                        <Box borderRadius="50%">
                            <img src={ img } alt="" width="100px" height="100px" style={{borderRadius:'50%'}}/>
                        </Box>
                    </Grid>
                    <Grid id="kipper_item_subject_contactItem"  item xs={4} md={4}>
                        <span className="Kipper_title-black">
                            {level} - {name}
                        </span> <br/> 
                        <span className="">
                            { info }
                        </span><br/> 
                        <span className="">
                            { time }
                        </span>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <>
                            <Button onClick={()=>deleteGroup()} id="kipper_button_deleteSubject_contactItem"  className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Eliminar </Button>
                        </>                        
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Link to={ btnDetails }>
                            <Button id="kipper_button_verGrupo_contactItem" className="kipper_button" variant="outlined" fullWidth>{btnFirstButton ? btnFirstButton:'Ver Grupo'}</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>

        )
    }
    if(isEvaluation && !error){
        return(
            <Box p={3}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Box borderRadius="50%">
                            <img src={ img } alt="" width="100px" height="100px" style={{borderRadius:'50%'}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <span className="Kipper_title-black capitalize">
                            { name }
                        </span> <br/> 
                        <span className="capitalize">
                            { info }
                        </span>
                    </Grid>
                    <Grid item xs={4}>
                        <Link to={ btnDetails }>
                            <Button className="kipper_button" variant="outlined" fullWidth>Ver Evaluaciones</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    if(isEvaluation && error){//No hay materias en el grupo
        return(
            <Box p={3}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <span className="Kipper_title-black capitalize">
                            No hay materias asignadas
                        </span> <br/> 
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else{
        return(
            <Box p={1}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Box borderRadius="50%">
                            <img src={ img } alt="" width="100px" height="100px" id="profileIMGx" className="ContactItem__Avatar"/>
                        </Box>
                    </Grid>
                    <Grid id="kipper_gridContainer_contactItem" item xs={4} md={4}>
                        <span id="kipper_name_student_contactItem" className="Kipper_title-black capitalize">
                            { name } 
                        </span> <br/> 
                        <span id="kipper_info_student_contactItem" className="capitalize">
                            { info }
                        </span>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        {
                            btnRemove ? null :
                                <>
                                {
                                    onlyOneButton ? null:
                                        <form onSubmit={ e => onDelete( e, id ) }>
                                            <Button id="kipper_button_deleteChild_contactItem" className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Eliminar</Button>
                                        </form>
                                }
                                </>
                        }
                    </Grid>
                    <Grid item xs={3} md={3} className="">
                        {
                            btnTo ?
                                <Link to={ btnTo}>
                                    <Button id="kipper_button_detailsChild_contactItem" className="kipper_button list_aling" variant="outlined" fullWidth>{btnFirstButton  ?btnFirstButton:'Detalles'}</Button>
                                </Link> :  null
                        }
                        {
                            btnSelect ? 
                            <form onSubmit={ e => onSelect(e, id) }>
                                <Button className="kipper_button list_aling" variant="outlined" fullWidth type="submit" >Seleccionar </Button>
                            </form> : null
                        }
                        {
                            btnRemove ?
                            <form onSubmit={ e => onRemove( e ) }>
                                <Button className="kipper_btn-outline-primary list_aling" variant="outlined" type="submit" fullWidth>Quitar</Button>
                            </form> : null
                        }
                        {
                            SubjectsHomework ?
                            <Link to={ btn2To}>
                                <Button className="kipper_button list_aling" variant="outlined" fullWidth>Tareas</Button>                            
                            </Link>: null
                        }
                    </Grid>
                </Grid>
            </Box>   
        )
    }
}

export default ContactItem