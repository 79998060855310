import React, { useState, useEffect, useContext  } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Button, Grid, InputLabel, MenuItem, Modal, Typography } from '@material-ui/core';
import * as firebase from 'firebase';
import ModalShow from './../../Components/Global/Modal'
import PageHeader from '../../Components/Global/PageHeader'
import portada from './../../Assets/Img/General/portada_add-blue.png';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../../Constants/textfield';
import TextareaAutosize from '../../Constants/textarea';
import Select from '../../Constants/select';

import { AuthContext } from "../../Components/functions/Auth";

export default function SchoolRegister() {
    const { currentUser, info }   = useContext(AuthContext);

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [errorEmailAlert, setErrorEmailAlert] = useState(false);
    const [password, setPassword] = useState("");
    const [errorPasswordAlert, setErrorPasswordAlert] = useState(false);
    const [NameSchool, setNameSchool] = useState("");
    const [Phone, setPhone] = useState("");
    const [errorPhoneAlert, setErrorPhoneAlert] = useState(false);
    const [Address, setAddress] = useState("");
    const [City, setCity] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [errorPostalCodeAlert, setErrorPostalCodeAlert] = useState(false);
    const [Country, setCountry] = useState("");
    const [Motto1, setMotto1] = useState("");
    const [Motto2, setMotto2] = useState("");
    const [State, setState] = useState("");
    const [Province, setProvince] = useState("");
    const [codeSEP, setCodeSEP] = useState("");

    const [error, setErrors] = useState("");

    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState('');
    const [ modalLink, setModalLink ]   = useState("");


    const ModalOpen = () => setModalStaus(true);

    const ModalClose = () => setModalStaus(false);

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const checkForm = e => {
        e.preventDefault();
        // RegExp to check te fields 
        const regExps ={
            zipCode: /^[0-9]{5,7}$/,
            email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9-.]+$/,
            phone: /^[0-9.-]{10,15}$/,
            passWord:/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/
        }
        if(!regExps.email.test(email)){
            setEmail('')
            setErrorEmailAlert(true);
            setErrorPhoneAlert(false);
            setErrorPostalCodeAlert(false);
            setErrorPasswordAlert(false)
        }else if(!regExps.phone.test(Phone)){
            setPhone('')
            setErrorPhoneAlert(true);
            setErrorEmailAlert(false);
            setErrorPostalCodeAlert(false);
            setErrorPasswordAlert(false)
        }else if(!regExps.zipCode.test(PostalCode)){
            setPostalCode('')
            setErrorPostalCodeAlert(true);
            setErrorPhoneAlert(false);
            setErrorEmailAlert(false);
            setErrorPasswordAlert(false)
        // }else if(!regExps.passWord.test(password)){
        //     setPassword('')
        //     setErrorPasswordAlert(true)
        //     setErrorPostalCodeAlert(false);
        //     setErrorPhoneAlert(false);
        //     setErrorEmailAlert(false);
        }else {
            setErrors('');
            CheckAdmin();
        }
    };

    function CheckAdmin(values){
        try{
            let db = firebase.database();
            let ref = db.ref('Info/'+ currentUser);
            ref.once('value').then((snapshot)=>{
                if(snapshot.val().type == 'Admin'){
                    handleForm(values)
                }
            })
        }catch(error){
            setErrors(error.message)
        }
    }

    const handlereg = () =>{
        firebase.auth().createUserWithEmailAndPassword(email, password).then(result => {
            handleForm(result.user.uid)
        })
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
        });
    
    }

    const handleForm = (val) => {  
        let db = firebase.database();
        var ref2 = `school_${Date.now()}`;
        let ref = db.ref('schools/'+ref2);
        try{
            ref.set({
                name: val.name,
                avatar: "",
                PublicIdok : val.code,
                contact: {
                    email: val.email,
                    phone: val.telephone
                        },
                details: {
                    mision: val.mision,
                    vision: val.vision,
                },
                documents:{
                    rules:""
                },
                key: ref2,
                l:{
                    lat:"",
                    lng:""
                },
                location:{
                    address: val.address,
                    city: val.city,
                    country: val.country,
                    province: val.municipio,
                    state: val.state,
                    zip_code: val.zipcode,
                },
                timestamp: Date.now(),
            })
        }
        finally{
            setEmail();
            setPassword();
            setNameSchool();
            setPhone();
            setAddress();
            setCity();
            setPostalCode();
            setCountry();
            setMotto1();
            setMotto2();
            setState();
            setProvince();
            setCodeSEP();
            setModalmsg('La escuela fue creada exitosamente');
            setModalLink('/');
            ModalOpen();
        }
    };

    
    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .required('El nombre es obligatorio')
            .matches(/^\w\D{2,100}$/, "El nombre debe contener entre 2 a 100 caracteres" ),
        email: yup
            .string('Ingrese un correo electrónico')
            .email('Ingrese un correo válido')
            .required('El correo electrónico es obligatorio'),
        address: yup
            .string('Ingresa una dirección')
            .required('La dirección es obligatoria')
            .matches(/^\w\D{5,2000}$/, "La direccion debe contener entre 5 a 200 caracteres" ),
        telephone: yup
            .string('Ingrese un teléfono')
            .required('El teléfono es obligatorio')
            .matches(/^(?:\d{10})?$/, 'El teléfono debe contener al menos 10 dígitos y ser númerico'),
        zipcode: yup
            .string('Ingrese un código postal')
            .required('El código postal es obligatorio')
            .matches(/^(?:\d{5})?$/, 'El CP debe contener al menos 5 dígitos y ser númerico'),
        city: yup
            .string('Ingrese una ciudad')
            .required('La ciudad es obligatoria')
            .matches(/^\w\D{5,50}$/, "La ciudad debe contener entre 5 a 50 caracteres" ),
        state: yup
            .string('Ingrese un estado')
            .required('El estado es obligatorio'),
        country: yup
            .string('Ingrese un país')
            .required('El país es obligatorio'),
        code: yup
            .string('Ingrese el codigo de la SEP'),
        municipio: yup
            .string('Ingrese una provincia')
            .required('La provincia es obligatoria')
            .matches(/^\w\D{5,50}$/, "La provincia debe contener entre 5 a 50 caracteres" ),
        address: yup
            .string('Ingrese una dirección')
            .required('La dirección es obligatoria'),
        mision: yup
            .string('Ingrese la misión')
            .required('La mision es obligatoria'),
            
        vision: yup
            .string('Ingrese una visión')
            .required('La vision es obligatoria')
            
    });

    const initialFormState ={
        name: '',
        address: '',
        email: '',
        telephone: '',
        zipcode: '',
        city: '',
        country: '',
        code: '',
        municipio: '',
        mision: '',
        vision: '', 
        state: ''
    }
    


    return (
        <Grid container >
            <Modal
                open={modalStaus}
                onClose={ModalClose}
                id="modalschool"
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={validateForm}
            onSubmit={values =>{
                CheckAdmin(values)
            }}
            >
                <Form>
                    <Grid item xs={12} >
                        <Box pb={3}>
                            <PageHeader 
                                title={`Registro de escuela`}
                                text="Crea nuevas escuelas"
                                btn_back="/"
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} id="box_Admin_register" borderRadius={16} className="kipperBox_content" mb={3} >
                            <Grid container >
                                <Grid item xs={12} >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Grid>
                                <Grid item xs={12} >
                                    <Box p={3}>
                                        <Grid container >
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Correo Electrónico <b className="asterisk-w">*</b> </InputLabel>
                                                    <TextField 
                                                        name="email"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Nombre de la Escuela <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField 
                                                        name="name"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Codigo SEP </InputLabel>
                                                    <TextField
                                                        name="code"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} >
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Telefono <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="telephone"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Pais<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="country"
                                                    >
                                                        <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="Mexico">Mexico</MenuItem>
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Estado <b className="asterisk-w">*</b></InputLabel>
                                                    {/* <TextField
                                                        name="state"
                                                    /> */}
                                                    <Select 
                                                        name="state"
                                                    >
                                                        <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="Aguascalientes">Aguascalientes</MenuItem>
                                                        <MenuItem value="Baja California">Baja California</MenuItem>
                                                        <MenuItem value="Baja California Sur">Baja California Sur</MenuItem>
                                                        <MenuItem value="Campeche">Campeche</MenuItem>
                                                        <MenuItem value="Chiapas">Chiapas</MenuItem>
                                                        <MenuItem value="Chihuahua">Chihuahua</MenuItem>
                                                        <MenuItem value="Coahuila">Coahuila</MenuItem>
                                                        <MenuItem value="Colima">Colima</MenuItem>
                                                        <MenuItem value="Ciudad de México">Ciudad de México / Distrito Federal</MenuItem>
                                                        <MenuItem value="Durango">Durango</MenuItem>
                                                        <MenuItem value="Estado de México">Estado de México</MenuItem>
                                                        <MenuItem value="Guanajuato">Guanajuato</MenuItem>
                                                        <MenuItem value="Guerrero">Guerrero</MenuItem>
                                                        <MenuItem value="Hidalgo">Hidalgo</MenuItem>
                                                        <MenuItem value="Jalisco">Jalisco</MenuItem>
                                                        <MenuItem value="Michoacán">Michoacán</MenuItem>
                                                        <MenuItem value="Morelos">Morelos</MenuItem>
                                                        <MenuItem value="Nayarit">Nayarit</MenuItem>
                                                        <MenuItem value="Nuevo León">Nuevo León</MenuItem>
                                                        <MenuItem value="Oaxaca">Oaxaca</MenuItem>
                                                        <MenuItem value="Puebla">Puebla</MenuItem>
                                                        <MenuItem value="Querétaro">Querétaro</MenuItem>
                                                        <MenuItem value="Quintana Roo">Quintana Roo</MenuItem>
                                                        <MenuItem value="San Luis Potosí">San Luis Potosí</MenuItem>
                                                        <MenuItem value="Sinaloa">Sinaloa</MenuItem>
                                                        <MenuItem value="Sonora">Sonora</MenuItem>
                                                        <MenuItem value="Tabasco">Tabasco</MenuItem>
                                                        <MenuItem value="Tamaulipas">Tamaulipas</MenuItem>
                                                        <MenuItem value="Tlaxcala">Tlaxcala</MenuItem>
                                                        <MenuItem value="Veracruz">Veracruz</MenuItem>
                                                        <MenuItem value="Yucatán">Yucatán</MenuItem>
                                                        <MenuItem value="Zacatecas">Zacatecas</MenuItem>
                                                    </Select>

                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Provincia <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="municipio"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Ciudad <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="city"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Codigo Postal <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="zipcode"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}>
                                                    <InputLabel shrink> Direccion <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="address"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Misión <b className="asterisk-w">*</b> </InputLabel>
                                                    <TextField
                                                        name="mision"
                                                        className="text_area-style"
                                                        multiline
                                                        rows={3}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box px={2} pb={2}>     
                                                    <InputLabel shrink> Visión <b className="asterisk-w">*</b></InputLabel>
                                                    <TextField
                                                        name="vision"
                                                        className="text_area-style"
                                                        multiline
                                                        rows={3}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Form>
            </Formik>
        </Grid>
    )
}
