import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import {Box,Grid,Typography,Modal} from '@material-ui/core';

import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';
import ModalShow from '../../Components/Global/Modal';
import PageHeader from '../../Components/Global/PageHeader';

const MateriasListLevels =()=>{
    const history   = useHistory();
    const match = useRouteMatch('/escuela/materias/filtro/nivel/:id');
    const {id} = useParams();

    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    }; 

    useEffect(() => {
        console.log(id)
    }, [])

    return(
        <Grid container>
            {/* <NavEscuelaPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={12} sm={12} md={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title={`Materias de ${match.params.id}`}
                        text="Administra y agrega nuevas materias por grado"
                        btn_back="/home"
                    />
                </Box>
            </Grid>
            <Grid container>
                <Grid item xs={6} >
                    <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                        <Link to={`/escuela/materias/Primero/${match.params.id}`}>
                            <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                <Box id='NoPaddingPrimero' pl={3}>
                                    <Typography variant="h4" className="fw-500" > Primero </Typography>
                                </Box>
                                <Box>
                                    <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                </Box>
                            </Grid>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={6} >
                    <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                        <Link to={`/escuela/materias/Segundo/${match.params.id}`}>
                            <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                <Box id='NoPaddingSegundo' pl={3}>
                                    <Typography variant="h4" className="fw-500" > Segundo </Typography>
                                </Box>
                                <Box>
                                    <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                </Box>
                            </Grid>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={6} >
                    <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                        <Link to={`/escuela/materias/Tercero/${match.params.id}`}>
                            <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                <Box id='NoPaddingTercero' pl={3}>
                                    <Typography variant="h4" className="fw-500" > Tercero </Typography>
                                </Box>
                                <Box>
                                    <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                </Box>
                            </Grid>
                        </Link>
                    </Box>
                </Grid>

                {id === 'Kinder' || id==='Secundaria'?(null):(
                    <>
                        <Grid item xs={6} >
                            <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                <Link to={`/escuela/materias/Cuarto/${match.params.id}`}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                        <Box id='NoPaddingCuarto' pl={3}>
                                            <Typography variant="h4" className="fw-500" > Cuarto </Typography>
                                        </Box>
                                        <Box>
                                            <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                        </Box>
                                    </Grid>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                <Link to={`/escuela/materias/Quinto/${match.params.id}`}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                        <Box id='NoPaddingQuinto' pl={3}>
                                            <Typography variant="h4" className="fw-500" > Quinto </Typography>
                                        </Box>
                                        <Box>
                                            <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                        </Box>
                                    </Grid>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                <Link to={`/escuela/materias/Sexto/${match.params.id}`}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                        <Box id='NoPaddingSexto' pl={3}>
                                            <Typography variant="h4" className="fw-500" > Sexto </Typography>
                                        </Box>
                                        <Box>
                                            <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                        </Box>
                                    </Grid>
                                </Link>
                            </Box>
                        </Grid>
                    </>
                )}
            
            
            
            
            
            
            </Grid>
        </Grid>
    );
}

export default MateriasListLevels;