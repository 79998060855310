//REACT
import React, {useContext} from 'react'
import {AuthContext} from '../functions/Auth'
//MUI
import { AppBar, Toolbar, Button, IconButton, Grid } from '@material-ui/core';
import { Menu } from '@material-ui/icons'
// BottonBar
import BottomNavSchool from './BottomNavSchool';
import BottomNavTeacher from './BottomNavTeacher';
import BottomNavChild from './BottomNavChild';
import BottomNavTutor from './BottomNavTutor';
import BottomNavAdmin from './BottonNavAdmin';



export default function AppViewrLayout( {children} ) {
    const { userType }   = useContext(AuthContext);

    let BottomNav = <BottomNavSchool />

    if(userType === "school"){
        BottomNav = <BottomNavSchool />
    }
    if(userType === "teacher"){
        BottomNav = <BottomNavTeacher />
    }
    if(userType === "tutor"){
        BottomNav = <BottomNavTutor />
    }
    if(userType === "child"){
        BottomNav = <BottomNavChild />
    }
    if(userType === "Admin"){
        BottomNav = <BottomNavAdmin />
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="end" aria-label="menu" >
                        <Menu />
                    </IconButton>
                    <IconButton edge="end" aria-label="menu" >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid style={style.container}>
                {children}
            </Grid>
            {BottomNav}
        </>
    )
}

const style = {
    container:{
        height: "80vh",
        overflow: "scroll"
    }
}