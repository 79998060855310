import React, { Component } from 'react'
//Component
import PageHeader from './../Components/Global/PageHeader';
import EsperaItem from '../Components/Global/EsperaItem';
//MaterialUI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class ListaEntregas extends Component {

    render() {
        return(

            <Grid container className="delList_grid" spacing={3}>
                <Grid xs={6} sm={12} md={12}>
                    <Box pb={3}>
                        <PageHeader 
                            title="Hora de salida"
                            text="Observa los primeros lugares en la fila de espera para gestionar la salida de los alumnos."
                            btn_back="/home"
                            btn_action="action"
                        />
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box p={3} boxShadow={3} className="box-content">
                        <Grid container direction="row" justify="space-around" alignItems="center">
                            <Typography variant="h6" gutterBottom>
                                Posición
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Padre e hijo
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Llegada
                            </Typography>
                        </Grid>
                        <EsperaItem/>
                        <EsperaItem/>
                        <EsperaItem/>
                        <EsperaItem/>
                    </Box>
                </Grid>
            </Grid>
        )
    }

}

export default ListaEntregas