import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
// Imagenes
import notification from './../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';
import home from './../../Assets/Img/svg_icons/Icon feather-home.svg';
import student from './../../Assets/Img/svg_icons/Icon feather-users.svg';
import chat from './../../Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import logout from './../../Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import evento from './../../Assets/Img/svg_icons/Icon feather-calendar.svg';
import group from './../../Assets/Img/svg_icons/icon-users.svg';
import maestros from './../../Assets/Img/svg_icons/Icon awesome-chalkboard-teacher.svg';
import ajustes from './../../Assets/Img/svg_icons/Icon feather-settings.svg';
import materias from './../../Assets/Img/svg_icons/Icon feather-list.svg';
import logo from './../../Assets/Img/Layout/Logo_text.png';
import users from './../../Assets/Img/Layout/Icon_Usuario.png';
import deliver from './../../Assets/Img/Layout/Icon_Entregas.png';
// Estilos
import './../../Assets/Styles/Layout.css';
//
import app from "../functions/credentials";
import { AuthContext } from "../functions/Auth";
import * as firebase from 'firebase';

const Sidebar = ({}) => {
    const { handlelogout, info, notif } = useContext( AuthContext );

    const hanbletest = () => {
        firebase.database().ref('teachers/'+ info.codigoref).remove();
    }

    return(
        <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
            <Box className="sidebar__header" display="flex">
                <Box flexGrow={1} pt={2} pl={3}>
                    <Link to="/home">
                        <img src={ logo } alt="Logo Kiper" />
                    </Link>
                </Box>
                <Box className="align-items-center">
                    <ul className="flex">
                        <li className="mx-3 bellinsidebar">
                            <Badge color="error" overlap="circle" badgeContent={''} invisible={notif}>
                                <Link to="/notificaciones">
                                    <img src={ notification } alt="Usuario Kiper" style={{filter: "contrast(1) invert(100%)"}} className="sidebar_icon"/>
                                </Link>
                            </Badge>
                        </li>
                        {/* <li className="mx-3">
                            <div>
                                <Link to="/sede">
                                    <img src={ users } alt="notificaciones Kiper" />
                                </Link>
                            </div>
                        </li> */}
                    </ul>
                </Box>
            </Box>
            <Box className="sidebar__search" display="flex" justifyContent="center">
            </Box>
            <Box className="sidebar__menu ">
                <ul className="pl-10 pt-5">
                    <li className="my-5 cursor-pointer">
                        <Link to="/home" className="flex">
                            <img src={ home } alt="Inicio Kiper" className="mr-3 sidebar_icon" />
                            <span>Inicio</span>
                        </Link>
                    </li>
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/maestros" className="flex">
                            <img src={ maestros } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Maestros</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/alumnos" className="flex">
                            <img src={ student } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Alumnos</span>
                        </Link>
                    </li>
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/grupos" className="flex">
                            <img src={ group } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Grupos</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/escuela/lista/materias" className="flex">
                            <img src={ materias } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Materias</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/comunicados" className="flex">
                            <img src={ evento } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Eventos y Comunicados</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/escuela/chat" className="flex">
                            <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Chat</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer">
                        <Link to="/sede" className="flex">
                            <img src={ ajustes } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Configuraciones</span>
                        </Link>
                    </li> 
                    <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                        <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon"/>
                        <span>Salir</span>
                    </li> 
                </ul>
            </Box>
        </Box>
    )

};

export default Sidebar