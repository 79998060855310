import React, { useState, useContext, useEffect  } from 'react';
import { useHistory } from 'react-router-dom';
//Component
import PageHeader from '../../Components/Global/PageHeader';
import EventoItem from '../../Components/Global/EventoItem';
import ModalShow from '../../Components/Global/Modal';
import Loader from '../../Components/Global/Loader';
// meterialui
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';
import MateriasListLevels from './MateriasListLevels';

const MateriasList = () => {

    const history   = useHistory();

    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    }; 

    return(
        <Grid container>
            {/* <NavEscuelaPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={12} sm={12} md={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Materias"
                        text="Administra y agrega nuevas materias por grado"
                        btn_back="/home"
                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                {/* <Grid container>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Primero`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Primero </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Segundo`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Segundo </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Tercero`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Tercero </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Cuarto`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Cuarto </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Quinto`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Quinto </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/Sexto`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Sexto </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                </Grid> */}
                <Grid container>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/filtro/nivel/Kinder`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box pl={3}>
                                        <Typography variant="h4" className="fw-500" > Kinder </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/filtro/nivel/Primaria`}>
                                <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box id='NoPaddingPrimaria' pl={3}>
                                        <Typography variant="h4" className="fw-500" > Primaria </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                            <Link to={`/escuela/materias/filtro/nivel/Secundaria`}>
                                <Grid id='prueba' container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                    <Box id='NoPaddingSecundaria' pl={3}>
                                        <Typography variant="h4" className="fw-500" > Secundaria </Typography>
                                    </Box>
                                    <Box>
                                        <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                    </Box>
                                </Grid>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MateriasList