import React, {useEffect} from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import portada from './../../Assets/Img/Modals/modal.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';



const Modal = ({ title, onlyShow, showGoTo, goTo, confirmDelete, confirmDeleteGroup, Delete, confirmOperation, rechazarAppoiment, handleReason, Operation, onClose }) =>{
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    
    return(
        <Grid container> 
            <Grid item className="AddAlumn_grid" justify="">
                <Box>
                    <img src={ portada } alt="modal img" />
                </Box >
                <Box p={2} textAlign="center">
                    <Typography className="textMod_p" >
                        { title }
                    </Typography>      
                </Box>

                {rechazarAppoiment?
                    <Box pb={2} pt={2}   textAlign="center">
                        <TextField onChange={handleReason} name="reasonInput" label="Razón" />  
                    </Box>
                :
                    <></>
                }


                <Box m={matches ?4 :1} >
                    {   onlyShow ? 
                            <Grid container>
                                <Grid item xs={12} >
                                    <form onSubmit={ e => showGoTo(e, goTo) }>
                                        <Button variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Confirmar</Button>
                                    </form>
                                </Grid>
                            </Grid> : null
                    }
                    {
                        confirmDelete ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Delete(e, false) }>
                                            <Button className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Delete(e, true) }>
                                            <Button variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Eliminar</Button>
                                        </form>
                                    </Box>
                                </Grid> 
                            </Grid> : null
                    }

                    {
                        confirmDeleteGroup ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form>
                                            <Button className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <>
                                            <Button onClick={ e => Delete(e, true) } variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Eliminar</Button>
                                        </>
                                    </Box>
                                </Grid> 
                            </Grid> : null
                    }
                    
                    {
                        confirmOperation ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => { Operation(e, false); onClose(); }}>
                                            <Button className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form id="aceptarAppoiment" onSubmit={ e => Operation(e, true) }>
                                            <Button variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Confirmar</Button>
                                        </form>
                                    </Box>
                                </Grid> 
                            </Grid> : null
                    }

                    { rechazarAppoiment &&
                        <Grid container>
                            <Grid item xs={6} >
                                <Box px={2}>
                                    <form onSubmit={ e => { Operation(e, false); onClose(); }}>
                                        <Button className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                    </form>
                                </Box>
                            </Grid>
                            <Grid item xs={6} >
                                <Box px={2}>
                                    <form id="rechazarAppoiment" onSubmit={ e => Operation(e, true) }>
                                        <Button variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Confirmar</Button>
                                    </form>
                                </Box>
                            </Grid> 
                        </Grid> 
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

export default Modal