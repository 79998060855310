import React, { useState, useContext, useEffect  } from 'react'
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../Components/functions/Auth";
//Component
import PageHeader from './../Components/Global/PageHeader';
import ModalShow from './../Components/Global/Modal'
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
import upload from './../Assets/Img/General/Icon_upload.png';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, Typography } from '@material-ui/core';

// FireBases
import * as firebase from 'firebase';
import NavTutorPhone from './Tutores/NavTutorPhone/NavTutorPhone';

const EventosPublico = ( { title, event_info, title_event, body_event } ) => {

    const history       = useHistory();
    const match         = useRouteMatch('/public/comunicados/:id');
    const comunicadoID  = match.params.id;
    
    const [groupDetails, setGroupDetails] = useState([])
    
    const [ file, setFile ]             = useState('');
    const [ eventInfo, setEventInfo ]   = useState('');
    const { currentUser }               = useContext(AuthContext);

    useEffect( () =>{
        let db = firebase.database();
        let list = [];
        let Ref = db.ref(`schools/${currentUser}/groups/details`);
        Ref.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                list.push( itemInfo )
            })
        })
        setGroupDetails( list )
    }, [ ] );
    useEffect(() => {
        let db = firebase.database();
        let ref = db.ref(`comunicados/${comunicadoID}`);
        ref.once('value').then(function(snapshot) {
            setEventInfo(snapshot.val());
        });
    },[]);

    return(
        <Grid container>
            {/* <NavTutorPhone /> */}
            <Grid xs={8} sm={12} md={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Detalles del Comunicado o Evento"
                        text="Tu próximo evento se acerca."
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid sm={12}>
                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3} >
                        <Grid container >
                            <Grid item xs={12} >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Grid>
                            <Grid item xs={12} >
                                <Box px={3}  pt={3} >
                                    <Typography variant="h4" className="kipper_title">
                                        <b> { eventInfo?.name } </b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} pb={3}>
                                <Box px={3} pb={3} >
                                    <span className="kipper_txt-grey">
                                        { eventInfo?.date } { eventInfo?.time }
                                    </span><br/>
                                    <span className="kipper_txt-grey">
                                        { eventInfo?.receiver_event === 'all' && ' Evento global' }
                                        { eventInfo?.receiver_event === 'teachers' && ' Evento para Maestros' }
                                        { eventInfo?.receiver_event === 'tutor' && ' Evento para Padres de familia' }
                                        { eventInfo?.receiver_event === 'child' && ' Evento para Alumnos' }
                                    </span><br/>
                                    <span className="kipper_txt-grey">
                                        { eventInfo?.details?.turn && `Turno ${ eventInfo?.details?.turn }` }
                                        { eventInfo?.details?.grade && eventInfo?.details?.grade }
                                        { eventInfo?.details?.gropu && eventInfo?.details?.gropu }
                                    </span><br/>
                                    <Typography variant="h5" className="kipper_txt-grey">
                                        <b> Detalles del evento </b>
                                    </Typography>
                                    <p className="kipper_txt-grey">
                                        { eventInfo.details_event }
                                        { 
                                            file && 
                                            <img src={ file } alt="" className="event_img"/>
                                        }
                                    </p>
                                </Box>
                            </Grid>
                        </Grid> 
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EventosPublico