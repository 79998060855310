import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import moment from 'moment';
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../functions/Auth';
import Moment from 'moment';

const HomeworkItem = ( { assistanceId, date, materiaID  } ) => {

    const { info, infoSchool } = useContext(AuthContext);
    const [AssistenceInfo, setAssistenceInfo] = useState({})

    
    useEffect( () => {
       loadAssistances()
    }, []);

    async function loadAssistances(){
       
        let db = firebase.database();
        let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${materiaID}/assistance/${assistanceId}/students/${info.id}`);
        var assistance = await groupRef.once('value')
                
        if(assistance.val()){
             setAssistenceInfo(assistance.val())
        }         
      }

    return (
        <Box mx={2} my={1} mt={1} >
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid xs={5} md={5} className="assitM-w"> 
                    <Box mt={2} mb={2}>
                        <Typography variant="body1" className="cent-tx">
                            { Moment(date).format('LL') }
                        </Typography>
                    </Box>
                </Grid>
            
                <Grid xs={5} md={5} className="assist-w">
                    <Box mt={2}  mb={2}>
                        <Typography>
                            { AssistenceInfo.assistance == "No" && <span className="assistance-false disBl_w"> Falta </span> }
                            { AssistenceInfo.assistance == "Yes" && <span className="assistance-true disBl_w"> Asistencia </span> }
                            { AssistenceInfo.assistance == null && <span className="assistance-true disBl_w"> Asistencia </span> }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <hr/>
        </Box>
    )
}

export default HomeworkItem
