import React, { useState, useContext, useEffect, useRef  } from 'react'
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal'
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';


// FireBases
import * as firebase from 'firebase';
import { checkFilepdf } from '../../Components/functions/helpapers';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../../Constants/textfield';
import Select from '../../Constants/select';
import moment from 'moment'
const ComunicadosDetalles = ( { title, event_info, title_event, body_event } ) => {

    const history       = useHistory();
    const match         = useRouteMatch('/comunicados/detalles/:id');
    const comunicadoID  = match.params.id;

    const formRef = useRef();
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(true)
    const [act, setAct] = useState({
        grade: true,
        turn: true,
        group: true
    })

    const [loadValues, setLoadValues] = useState({
        type: '',
        to: '',
        level: '',
        turn: '',
        grade: '',
        group: '',
        date: '',
        time: '',
        body: '',
    })
    
    const [turnInfo, setTurnInfo]       = useState([])
    const [groupInfo, setGroupInfo]     = useState([])
    const [gradeList, setGradeList]     = useState([])
    const [groupList, setGroupList]     = useState([])
    const [ isEdit, setIsEdit ]         = useState( false );
    const [ file, setFile ]             = useState('');
    const [ eventInfo, setEventInfo ]   = useState('');
    const [ changeFile, setChangeFile ] = useState(false);
    const { currentUser }               = useContext(AuthContext);
    const [errorMsg, seterrorMsg] = useState({
        doc: false
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    
    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const getGroupInfo = async () => {
        let list = [];
        let resp = await firebase.database().ref(`schools/${currentUser}/groups`).once('value');
        resp.forEach( item => {
            let itemInfo = item.val()
            if ( item.key !== 'details') {
                list.push( itemInfo );  
            }
        })
        setGroupInfo( list )
    }
    
    useEffect( () =>{ getGroupInfo() }, [ ] );

    useEffect(() => {
        let db = firebase.database();
        let ref = db.ref(`comunicados/${comunicadoID}`);
        ref.once('value').then(function(snapshot) {
            let item = snapshot.val()
            setLoadValues({
                type: item.type,
                name: item.name,
                to: item.receiver_event,
                turn: item.details?.turn,
                grade: item.details?.grade,
                group: item.details?.group,
                level: item.details?.level,
                date: item.date,
                time: item.time,
                body: item.details_event
            })
            if(item.receiver_event === 'all'){
                setFlag(true)
            }
            setEventInfo(item);
            setFile(item.document?.document_1 )
            
        });
    },[]);

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading(false)
                setFile( img );
                setChangeFile( true );
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setFile(profileImg.src)
                        setLoading(true)
                    })
                })
            } catch (error) {
                setLoading(true)
                console.log( error );
            }
        } else {
            setLoading(true)
            return seterrorMsg({...errorMsg, avatar: 'Formatos validos: png, jpg y jpeg' });
        } 
    }

    const handleSubmit = val => {
        const opcionalIMG =  changeFile ? document.getElementById('opcionalIMG').src : file;
        let db = firebase.database();
        let Usersref = db.ref(`comunicados/${comunicadoID}`);
        Usersref.update({
            school: currentUser,
            name: val.name,
            receiver_event: val.to,
            details_event: val.eventMessage,
            date: val.date,
            time: val.time,
            type: val.type,
            details:{
                level: val.level,
                grade: val.grade,
                group: val.group,
                turn: val.turn,
            },
            document:{
                document_1: opcionalIMG ? opcionalIMG : ''
            }
        }, function(error) {
            if (error) {
              // The write failed...
                console.log(error)
            } else {
              // Data saved successfully!
                setModalmsg('El comunicado fue editado con éxito');
                setModalLink(`/comunicados`);
                ModalOpen();
            }
        });
    }

    const handleSelectLevel = () => {
        let level = formRef.current.values.level
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        formRef.current.values.turn = ''
        let list = [];
        groupInfo.forEach( i => {
            if ( i.level === level ) {
                list.push( i.turn )
            }
        })
        console.log(list)
        if ( !list.length ) {
            list.push( 'Sin información' )
        } else {
            setAct({...act, turn: false})
            list = Array.from(new Set( list ))
        }
        setTurnInfo( list )
    } 

    const handleSelectTurn = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        let list = [];
        groupInfo.forEach( i => {
            if ( i.turn === turn && i.level === level ) {
                list.push( i.grade )
            }
        })
        if ( !list.length ) {
            list.push( 'Sin información' )
        } else {
            list = Array.from(new Set( list ))
            setAct({...act, grade: false})
        }
        setGradeList( list );
    } 

    const handleSelectGrade = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        let grade = formRef.current.values.grade
        formRef.current.values.group = ''
        let list = [];
        groupInfo.forEach( i => {
            if ( i.level === level && i.turn === turn  && i.grade === grade ) {
                list.push( i.group )
            }
        })
        if ( !list.length ) {
            list.push( 'sin información' )
        } 
        else {
            list = Array.from(new Set( list ))
            setAct({...act, group: false})

        }
        setGroupList( list )
    } 

    const handleDisable = (e) => {
        if(e === 'all'){
            setFlag(true)
            setAct(false)
            formRef.current.values.grade = ''
            formRef.current.values.group = ''
            formRef.current.values.turn = ''
            formRef.current.values.level = ''
            setAct({...act, turn: true, group: true, grade: true})
        }else{
            setFlag(false)
        }
    }

    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El nombre es obligatorio'),
        type: yup
            .string('Ingresa un tipo')
            .required('El tipo es obligatorio'),
        to: yup
            .string('Ingresa un destinatario')
            .required('El destinatario es obligatorio'),
        level: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un nivel')
                    .required('El nivel es obligatorio'),
            }),
        grade: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un grado')
                    .required('El grado es obligatorio'),
            }),            
        group: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un grupo')
                    .required('El grupo es obligatorio'),
            }),  
        turn: yup
            .string()
            .ensure()
            .when('to', {
                is: 'all',
                then: null,
                otherwise: yup
                    .string('Seleccione un turno')
                    .required('El turno es obligatorio'),
            }), 
        date: yup
            .date().min(new Date(), "No se pueden crear eventos en el mismo día o fechas pasadas")
            .required('La fecha es obligatoria'),
        time: yup
            .string('Ingrese un hora')
            .required('La hora es obligatorio'),
        eventMessage: yup
            .string('Ingrese los detalles del evento')
            .required('Los detalles son obligatorios'),
    });
      
    const initialFormState ={
        name: loadValues.name,
        type: loadValues.type,
        to: loadValues.to,
        turn: loadValues.turn,
        grade: loadValues.grade,
        level: loadValues.level,
        group: loadValues.group,
        date: loadValues.date,
        time: loadValues.time,
        eventMessage: loadValues.body
    }   

    if( isEdit ){
        return(

            <Grid container>
                {/* <NavEscuelaPhone /> */}
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        />
                    </Grid>
                </Modal>
                <Grid xs={8} sm={12} md={12}>
                    <Box pb={3}>
                        <PageHeader 
                            title="Editar comunicado o evento"
                            text="Modifica los detalles de tu próximo evento se acerca"
                        />
                    </Box>
                </Grid>
                <Grid sm={12}>
                    <Grid xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content" >
                            <Formik
                                initialValues={{
                                    ...initialFormState
                                }}
                                innerRef={formRef}
                                validationSchema={validateForm}
                                onSubmit={values =>{
                                    handleSubmit(values)
                                }}
                            >
                                <Form>
                                    <Grid container >
                                        <Grid item xs={12} >
                                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                                        </Grid>
                                        
                                        <Grid item xs={7} >
                                            <Box px={3} pt={3}>
                                                <Typography variant="h4" className="kipper_title">
                                                    <b> { eventInfo?.name } </b>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={5} >
                                            <Box px={3} pt={3} display="flex" justifyContent="flex-end" >
                                                <Button 
                                                    variant="contained" 
                                                    className="kipper_button mar_r_2_i" 
                                                    disableElevation
                                                    style={{marginRight:'10px'}}
                                                    onClick={ e => setIsEdit( false ) }
                                                > 
                                                    Cancelar 
                                                </Button>
                                                &nbsp;
                                                <Button 
                                                    variant="contained"
                                                    className="kipper_button" 
                                                    disableElevation 
                                                    type={'sumbit'} 
                                                >
                                                    Guardar
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box p={3}>  
                                                <InputLabel shrink id="eventType">
                                                    Nombre de Comunicado <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="name"/>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="eventFrom">
                                                    Tipo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select name="type">
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="comunicado">Comunicado</MenuItem>
                                                    <MenuItem value="evento">Evento</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="eventFrom">
                                                    Destinatarios <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select 
                                                    name="to"
                                                    onBlur={e => handleDisable(e.target.value)}
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="all">Todos</MenuItem>
                                                    <MenuItem value="teacher">Maestros</MenuItem>
                                                    <MenuItem value="tutor">Padres de familia</MenuItem>
                                                    <MenuItem value="group">Grupo</MenuItem>
                                                    <MenuItem value="child">Alumnos</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="nivel">
                                                    Nivel <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="level"
                                                    onBlur={ e => handleSelectLevel()}
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Kinder">Kinder</MenuItem>
                                                    <MenuItem value="Primaria">Primaria</MenuItem>
                                                    <MenuItem value="Secundaria">Secundaria</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="turno">
                                                    Turno <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="turn"
                                                    onBlur={e => handleSelectTurn()}
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>

                                                    {
                                                    act.turn?
                                                        <MenuItem value={ loadValues.turn } key={ loadValues.turn }>{  loadValues.turn }</MenuItem>
                                                    :
                                                    
                                                    turnInfo ? 
                                                    
                                                        turnInfo.map( item => 
                                                            <MenuItem value={ item } key={ item }>{  item }</MenuItem>
                                                        )
                                                        :
                                                        <MenuItem disabled >No hay turnos registrados en este nivel</MenuItem>
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="grado">
                                                    Grado <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="grade"
                                                    onBlur={e => handleSelectGrade()}
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    {
                                                    act.grade?
                                                        <MenuItem value={ loadValues.grade } key={ loadValues.grade }>{  loadValues.grade }</MenuItem>
                                                    :
                                                    gradeList ? 
                                                        gradeList.map( item => 
                                                            <MenuItem value={ item } key={ item }>{  item }</MenuItem>
                                                        )
                                                        :
                                                        <MenuItem disabled >No hay grados registrados en este grupo</MenuItem>
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={3}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="grupo">
                                                    Grupo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    disabled={flag}
                                                    name="group"
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                {
                                                    act.group?
                                                        <MenuItem value={ loadValues.group } key={ loadValues.group }>{  loadValues.group }</MenuItem>
                                                    :
                                                    groupList ? 
                                                        groupList.map( item => 
                                                            <MenuItem value={ item } key={ item }>{  item }</MenuItem>
                                                        )
                                                        :
                                                        <MenuItem disabled >No hay grupos registrados en este grado</MenuItem>
                                                }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="fecha">
                                                    Fecha <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField
                                                    name="date" type="date"
                                                />    
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="Hora">
                                                    Hora <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="time" type="time" 
                                                />    
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} md={12}>
                                            <Box p={3}> 
                                                <InputLabel shrink id="eventMessage">
                                                    Detalles del Evento <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="eventMessage"
                                                    multiline={true}
                                                    rows={4}
                                                />    
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={3}>
                                                <>
                                                    {
                                                        loading ?

                                                            <a href={ file } target="_blank" rel="noopener noreferrer"> 
                                                                <Typography  className="txtDescTitle fonsty"> Previsualizar documento {file? <VisibilityIcon className="kipper_txt-grey" />:""} </Typography>
                                                            </a>
                                                        :
                                                            <CircularProgress/>
                                                    }
                                                    <InputLabel shrink id="uploadFile"> DOCUMENTOS <br/> </InputLabel>
                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                    <label for="docOpcional" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img className="imW" src={ upload } alt=""/>
                                                            <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                               </>
                                                <Typography className="" variant="caption" display="block" gutterBottom>
                                                    *La imagen debe ser tipo JPG, JPG, PNG y PDF
                                                </Typography>
                                                { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                            </Box>
                                        </Grid>
                                    </Grid> 
                                </Form>
                            </Formik>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else{
        return(
            <Grid container>
                {/* <NavEscuelaPhone /> */}
                <Grid xs={8} sm={12} md={12}>
                    <Box pb={3}>
                        <PageHeader 
                            title="Detalles del comunicado o evento"
                            text="Tu próximo evento se acerca"
                            btn_back="/comunicados"
                            btn_action=""
                        />
                    </Box>
                </Grid>
                <Grid sm={12}>
                    <Grid xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3} >
                            <Grid container >
                                <Grid item xs={12} >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Grid>
                                <Grid item xs={10} >
                                    <Box px={3}  pt={3} >
                                        <Typography variant="h4" className="kipper_title">
                                            <b> { eventInfo?.name } </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Box px={3} pt={3} display="flex" justifyContent="flex-end" >
                                        <Button 
                                            variant="contained" 
                                            className="kipper_button" 
                                            disableElevation
                                            onClick={ e => setIsEdit( true ) }
                                        > 
                                            Editar 
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} pb={3}>
                                    <Box px={3} pb={3} >
                                        <span className="kipper_txt-grey">
                                            { moment(eventInfo?.date).format('MMMM Do YYYY')} - { eventInfo?.time }
                                        </span><br/>
                                        <span className="kipper_txt-grey">
                                            { eventInfo?.receiver_event === 'all' && ' Evento global' }
                                            { eventInfo?.receiver_event === 'teachers' && ' Evento para Maestros' }
                                            { eventInfo?.receiver_event === 'tutor' && ' Evento para Padres de familia' }
                                            { eventInfo?.receiver_event === 'child' && ' Evento para Alumnos' }
                                        </span>
                                        <span className="kipper_txt-grey">
                                            <br/>
                                            { loadValues.level ? `Nivel: ${ loadValues.level }` : ''}
                                            <br/>
                                            { loadValues.turn ? `Turno: ${ loadValues.turn }` : ''}
                                            <br/>
                                            { loadValues.grade ? `Grado: ${ loadValues.grade }` : '' }
                                            <br/>
                                            { loadValues.group ? `Grupo: ${ loadValues.group }` : '' }
                                        </span>
                                        <Typography variant="h5" className="kipper_txt-grey"> <b> Detalles del evento </b> </Typography>
                                        <p className="kipper_txt-grey">
                                            { eventInfo?.details_event }
                                            { file &&  <img src={ file } alt="" className="event_img"/> }
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid> 
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default ComunicadosDetalles