import { School } from '@material-ui/icons';
import * as firebase from 'firebase';
const db = firebase.database();
const CHATS_REF = db.ref().child('/chats/')


export async function CreateConversation(sender, receiver){
    var arrayChat = {
        lastUpdate: Date.now()
    };
    arrayChat[sender.type] = {
            avatar: sender.avatar,
            key: sender.key,
            name: sender.name
        }
        arrayChat[receiver.type] = {
            avatar: receiver.avatar,
            key: receiver.key,
            name: receiver.name
        }
        let push_ = await CHATS_REF.push(arrayChat)
}
/** CHAT MAESTRO - GRUPOS
 * Esta funcion, crea los grupos, antes revisand que estos existan, 
 * en cualquier medida, este primero revisa la cantidad de chats por grupo 
 * existentes y luego crea los faltantes, de tal manera en que crea chats
 * para grupos nuevos que le sean asignados en cada momento al docente
 * la variable info que se espera debe contener
 * {
 *      coderef: codigo del usuario
 *      type: tipo del usuario
 * }
 */
export async function CheckChatGroups (info, school){
    const db = firebase.database();
    const ref = db.ref(`teacher/${info.coderef}/groups`);
    const refchats = db.ref('chats');
    var groups = [];
    var chatsgroups = [];
    var schoolchat =[];
    try{
        await ref.once('value').then(snapshot =>{
            var groupsma = Object.entries(snapshot.val())
            groupsma.forEach(element => {
                groups.push(element[1])
            });
        })
        await refchats.once('value').then(snapshot =>{
            var chatsma = Object.entries(snapshot.val());
        
            chatsma.forEach(element => {
                if(element[1].teacher == info.coderef && element[1].status.Active === 1){
                    chatsgroups.push(element)
                }else if(element[1].status.IsSchool === 1 && element[1].school.key === school.school && element[1].teacher.key === info.coderef && element[1].status.Active === 1){
                    schoolchat.push(element)
                }
            });
        })
    }catch(error){
        console.log(error);
    }finally{
        await groups.forEach(e =>{
            let found = chatsgroups.find(element => e.group_key === element[1].group.key)
            if(!found){
                CreateGruopChat(e, info.coderef)
            }  
        });
        if(schoolchat.length == 0){
            db.ref(`schools/${school.school}`).once('value').then(snap =>{
                var body = {
                    "teacher": {
                        name: `${school.name} ${school.lastname}`,
                        avatar: school.avatar,
                        key:info.coderef
                    },
                    "school":{
                        key: snap.val().key,
                        name: snap.val().name,
                        avatar: snap.val().avatar
                    } ,
                    "status":{
                        "IsSchool":1,
                        "Active":1
                    },
                    lastUpdate: Date.now()
                }
                refchats.push(body);
            })
        }
    }
}

/** CreateGruoChat("Clave del grupo", "Clave del maestro")
    Esta funcion creara los grupos de chats que el maestro necesite para sus grupos */
export async function CreateGruopChat(group, teacher){
    //declaro la base de datos de firebase
    const db = firebase.database();
    //declaro la referencia a la cual estoy apuntando
    var ref = db.ref('chats');
    //cuerpo del chat al ser creado 
    var body = {
        "teacher": teacher,
        "group": {
            "key": group.group_key,
            "grade": group.details.grade,
            "group": group.details.group, 
            "turn": group.details.turn
        } ,
        "status":{
            "IsGroup":1,
            "Active":1
        },
        lastUpdate: Date.now()
    }
//primer mensaje del chat, el tipo implica que es de kipper, por lo tanto no necesita un sender
    var msgCreated = {
            "msg": "Chat was Created",
            "type": 1             
        }
//push y obtencion del codigo de la conversacion
    var keyref = ref.push(body).key
//referencia a la conversacion recien creada
    var ref2 = db.ref('chats/'+keyref+'/messages')
//push del mensaje de kipper "Chat Creado" dentro de la conversacion para finalizar
    ref2.push(msgCreated);
}

/**
 * 
 * @param {string: mensaje} text 
 * @param {object: objeto que contenga: avatar, id y nombre completo} user 
 * @param {string: codigo del chat} idChat 
 */
export const Send = async (text, user, idChat) =>{
/* Debido a que los maestros tienen la referencia de su apellido como lastname y los tutores y alumnos lo tienen como last_name
se debe identificar el tipo de usuario que envia el mensaje para del arreglo de su informacion sacar el nombre completo, 
se esta usando de ejemplo un arreglo identico a el que se genera al obtener informacion de typeuser/iduser en firebase */
    if(user.type === 'teacher'){
        //objeto que ordena el mensaje para agregar a la conversacion, el type 2 es tipo grupos
        let body = {
            date  : new Date().toDateString(),
            msg: text,
            sender: {
                fullname: `${user.name} ${user.lastname}`,
                key: user.teacher_key,
                avatar: user.avatar
            },
            type: 2
        }
    //metood push de firebase para almacenar el mensaje en la conversacion deseada
        let push_ = await CHATS_REF.child(`/${idChat}/messages/`).push(body)
        let update_ = await CHATS_REF.child(`/${idChat}/`).update({lastUpdate:Date.now()})
    //return true por si se planea usar en un try catch finally
        return true

    }else if(user.type === 'school'){
        db.ref(`schools/${user.coderef}`).once('value').then(snapshot =>{
            //objeto que ordena el mensaje para agregar a la conversacion, el type 2 es tipo grupos
            let body = {
                date  : new Date().toDateString(),
                msg: text,
                sender: {
                    fullname: `${snapshot.val().name}`,
                    key: snapshot.val().key,
                    avatar: snapshot.val().avatar
                },
                type: 2
            }
            //metood push de firebase para almacenar el mensaje en la conversacion deseada
                let push_ = CHATS_REF.child(`/${idChat}/messages/`).push(body)
                let update_ = CHATS_REF.child(`/${idChat}/`).update({lastUpdate:Date.now()})
            //return true por si se planea usar en un try catch finally
                return true
        })
    }else{
        //objeto que ordena el mensaje para agregar a la conversacion, el type 2 es tipo grupos
            let body = {
                date  : new Date().toDateString(),
                msg: text,
                sender: {
                    fullname: `${user.name} ${user.last_name}`,
                    key: user.id,
                    avatar: user.avatar
                },
                type: 2
            }
        //metood push de firebase para almacenar el mensaje en la conversacion deseada
            let push_ = await CHATS_REF.child(`/${idChat}/messages/`).push(body)
            let update_ = await CHATS_REF.child(`/${idChat}/`).update({lastUpdate:Date.now()})
        //return true por si se planea usar en un try catch finally
            return true
    }
}

export const GroupChilds = (objectchilds, currentTutor) =>{
//Declaracion de Variables
    var arraychilds = Object.entries(objectchilds);
    var refchat = db.ref('chats/')
    var arraychildats = [];
    var childinfo; 
    var groupinfo;
//Arreglo para Revisar cada uno de los niños que el padre tiene
    arraychilds.forEach(element => {
//Referenciar  a los niños a la BD para poder jalar su informacion
        let refchilds = db.ref('childs/' + element[1]);
//Obtener la informacion del niño referenciado 
        refchilds.once('value').then(snapshot =>{
//Meter toda la informacion de del niño actual en una variable para su manejo
            childinfo = snapshot.val();
//Referenciar al grupo del niño si este existe, para poder llamar su informacion 
            let refchildrens = db.ref(`schools/${childinfo.school}/groups/${childinfo.group}/teacher`);
//Obtener la informacion del niño y el grupo para su comparativa 
            refchildrens.once('value').then(snap =>{
//Meter la informacion de los grupos en una nueva variable para su manejo
                groupinfo = snap.val();
                if(snap.exists()){
                    var jsonchat = {
                        "teacher": {
                            "name": groupinfo.teacher_name,
                            "key": groupinfo.teacher_key,
                            "avatar": groupinfo.teacher_avatar
                        },
                        "tutor": {
                            "name": `${currentTutor.name} ${currentTutor.last_name}`,
                            "avatar": currentTutor.avatar,
                            "key": currentTutor.id
                        },
                        "status": {
                            IsTutor: 1,
                            Active: 0,
                            Request: 0,
                            Closed: 1
                        },
                        lastUpdate: Date.now()
                    }
                    refchat.once('value').then(chatssnap =>{
                        let chatsarray = Object.entries(chatssnap.val())
                        const found = chatsarray.find(element => element[1].tutor?.key == currentTutor.id);
                        if(!found){
//push de la conversacion con el maestro, que actualmente se creara
                            refchat.push(jsonchat);
                        }
                    })
                }

            })
        })
    })
}