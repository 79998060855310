import React, { useContext, useEffect } from 'react';
import { AuthContext } from "./Components/functions/Auth";
// Router React
import { BrowserRouter, Switch, Route } from 'react-router-dom';
//Styles
import './Assets/Styles/App.css';
import './Assets/Styles/backgroundFixed.css';
import './Assets/Styles/chat.module.css';
import app from './Components/functions/credentials'
// import './Assets/Styles/Responsive.css';
// import './Assets/Styles/Global.css';


// Teachers
import HomeTeacher from './Pages/Maestros/HomeTeacher';
import ProfileTeacher from './Pages/Maestros/ProfileTeacher';
import ReportsTeacher from './Pages/Maestros/MaestrosReportes';
import Evaluaciones from './Pages/Maestros/Evaluaciones';
import SchoolSubjects from './Pages/Maestros/SchoolSubjects';
import SchoolSubjectsDetails from './Pages/Maestros/SchoolSubjectsDetails';
import SchoolSubjectsOperations from './Pages/Maestros/SchoolSubjectsOperations';
import HomeWorkDetails from './Pages/Maestros/HomeWorkDetails';
import NewHomework from './Pages/Maestros/HomeworkNew';
import CitasTeacher from './Pages/Maestros/Citas';
import CitasTutores from './Pages/Maestros/CitasTutores';
import TeacherGroups from './Pages/Maestros/GroupsList'
import HomeworkList from './Pages/Maestros/Homeworklist'
import HomeworkNew from './Pages/Maestros/HomeworkNew'
import HomeworkDetail from './Pages/Maestros/HomeWorkDetails'
import Asistencias from './Pages/Maestros/Asistencias'
import AppoimentsDetails from './Pages/Maestros/AppoimentsDetails'
import EvaluationGroups from './Pages/Maestros/EvalGrupos';
import EvaluationSubjects from './Pages/Maestros/EvalSubject';
import Evaluations from './Pages/Maestros/Evaluaciones'

// School
import LayoutRoute from './Pages/LayoutRoutes';
import PrivateRoute from "./Components/functions/Privateroute";
import Home from './Pages/Home';
import Entregas from'./Pages/Entregas';
import Alumnos from'./Pages/Alumnos';
import AlumnoInfo from'./Pages/AlumnoInfo';
import AlumnoAgregar from'./Pages/AlumnoAgregar';
import Padres from'./Pages/Padres';
import PadreAgregar from'./Pages/PadreAgregar';
import ContactoReferencia from'./Pages/ContactoReferencia';
import AgregarReferencia from'./Pages/AgregarReferencia';
import ListaEntregas from'./Pages/ListaEntregas';
import Notificaciones from'./Pages/Notificaciones';
import TutorDetails from './Pages/Escuela/TutorDetails'
import TutorAgregar from './Pages/Escuela/TutorAgregar'
import Sede from'./Pages/Sede';
import Eventos from'./Pages/Eventos';
import EventosDetalles from'./Pages/EventosDetalles';
import EventosAgregar from'./Pages/EventosAgregar';
import Comunicados from'./Pages/Escuela/Comunicados';
import ComunicadosAgregar from'./Pages/Escuela/ComunicadosAgregar';
import ComunicadosDetalles from'./Pages/Escuela/ComunicadosDetalles';
import Groups from'./Pages/Escuela/Groups';
import GroupDetails from'./Pages/Escuela/GroupDetails';
import GroupDetailsBySubjects from'./Pages/Escuela/GroupDetailsBySubjects';
import GroupAssign from'./Pages/Escuela/GroupAssign';
import MateriasEscuela from'./Pages/Escuela/Materias';
import MateriasList from'./Pages/Escuela/MateriasList';
import MateriasAgregarEscuela from'./Pages/Escuela/MateriasAgregar';
import MateriasDetallesEscuela from'./Pages/Escuela/MateriasDetalles';
import Maestros from'./Pages/Maestros';
import MaestrosAgregar from'./Pages/MaestrosAgregar';
// import MaestroSecundaria from './Pages/MaestroSecundaria';
import MaestrosInfo from'./Pages/MaestrosInfo';
import NotFound from './Pages/NotFound';
import MateriasAgregar from './Pages/MateriasAgregar'
import Profile from './Pages/Tutores/Profile'
// Admin
import SchoolReg from './Pages/Admin/SchoolRegister'
import AddGroupBySchool from './Pages/Admin/AddGroupBySchool'
import NotificatiosforSchool from './Pages/Admin/NotificatiosforSchool'
import Schedule from './Pages/Admin/Schedule';

// Chidl
import HomeChild from './Pages/Child/HomeChild'
import CalendarChild from './Pages/Child/CalendarChild'
import HomeworkChild from './Pages/Child/HomeworkChild'
import HomeworkDetailsChild from './Pages/Child/HomeworkDetailsChild'
import SubjectsChild from './Pages/Child/SubjectsChild'

//Tutor
import Citas from './Components/tutors/Citas'
import Appointments from './Pages/Tutores/Appointments'
import AppoimentsDetailsTutor from './Pages/Tutores/AppoimentsDetails'
import CalendarDetails from './Pages/Tutores/CalendarDetails'
import Calendar from './Pages/Tutores/Calendar'
import CitasTutor from './Pages/Tutores/Citas'
import AlumnosForTutor from './Pages/Tutores/AlumnosForTutor'
import Settings from './Pages/Tutores/Settings'
import Faq from './Pages/Tutores/Faq'
import ChildInfo from './Pages/Tutores/ChildInfo'
import ChildCalendar from './Components/Global/Calendar'
import Information from './Pages/Tutores/Information'
import Documents from './Pages/Tutores/Documents'
import Information2 from './Pages/Child/Information'
import TutorHomeworkChild from './Pages/Tutores/TutorHomeworkChild'

import EventsGeneralDetails from './Pages/GeneralEventsDetail'; 
import EventosPublico from './Pages/EventosPublico'; 

//Chat
import Chats from './Components/Chat/Chats'
import ChatTutorListTeachers from './Pages/Tutores/ChatlistTeachers'
import ChatTutorList from './Pages/Tutores/Chatlist'
import ChatIndex from './Pages/Tutores/ChatIndex'
import ChatChild from './Pages/Child/ChatChild'
import ChatDireccion from './Pages/Escuela/ChatDireccion'
import ChatView from './Pages/Maestros/ChatView';
import Chat from './Pages/Maestros/Chat';
import SeleccionChat from './Components/Chat/Escuela/SeleccionarChat';
import SeleccionarChatTutor from './Components/Chat/Tutor/SeleccionarChatTutor';
import Conversaciones from './Components/Chat/Escuela/Conversaciones';
import ConversacionesTutor from './Components/Chat/Tutor/ConversacionTutor';
import SeleccionChatMaestro from './Components/Chat/Maestros/SeleccionarChatMaestro';
import ConversacionesMaestro from './Components/Chat/Maestros/ConversacionesMaestro';
import SeleccionarChatAlumno from './Components/Chat/Alumno/SeleccionarChatAlumno';
import ConversacionAlumno from './Components/Chat/Alumno/ConversacionAlumno';

// Components
import { AuthProvider } from "./Components/functions/Auth";
import Login from './Pages/Login';
import ForgotPass from './Pages/ForgotPass';
import SingInTeacher from './Pages/SingInTeacher';
import SingInSchool from './Pages/SingInSchool';
import ParentsItems from './Components/Teachers/ParentsItems';
import FormularioCitas from './Components/Teachers/FormularioCitas';
import StudentItem from './Components/Teachers/ChildItem'
import ChatComponent from './Components/Chat/Chats';
import AppPrivate from './Components/functions/AppPrivateRoute'
import TutorChild from './Pages/Tutores/TutorChild';
import MateriasListLevels from './Pages/Escuela/MateriasListLevels';
import CrearGroups from './Pages/Escuela/crearGrupo';
import ScheduleDetails from './Pages/Admin/ScheduleDetails';
import ScheduleInfo from './Pages/Admin/ScheduleInfo';

function App() {
  
 
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
            <LayoutRoute exact path="/login" component={ Login } />
            <LayoutRoute exact path="/registro/maestros" component={ SingInTeacher } />
            <LayoutRoute exact path="/registro/escuelas" component={ SingInSchool } />
            <LayoutRoute exact path="/recuperar_contrasena" component={ ForgotPass } />

            {/* School Routes */}
            <PrivateRoute type="school" exact path="/home" component={ Home } />
            <PrivateRoute type="school" exact path="/entregas" component={ Entregas } />
            <PrivateRoute type="school" exact path="/alumnos" component={ Alumnos } />
            <PrivateRoute type="school" exact path="/alumnos/agregar" component={ AlumnoAgregar } />
            <PrivateRoute type="school" exact path="/alumnos/informacion/:id" component={ AlumnoInfo } />
            <PrivateRoute type="school" exact path="/padres/:id" component={ Padres } />
            <PrivateRoute type="school" exact path="/padres/agregar/:id" component={ PadreAgregar } />
            <PrivateRoute type="school" exact path="/escuela/tutor/editar/:id" component={ TutorDetails } />
            <PrivateRoute type="school" exact path="/escuela/tutor/agregar/:id/:idd" component={ TutorAgregar } />
            <PrivateRoute type="school" exact path="/referencias/:id" component={ ContactoReferencia } />
            <PrivateRoute type="school" exact path="/referencias/agregar/:id" component={ AgregarReferencia } />
            <PrivateRoute type="school" exact path="/maestros" component={ Maestros } />
            <PrivateRoute type="school" exact path="/maestros/agregar" component={ MaestrosAgregar } />
            <PrivateRoute type="school" exact path="/maestros/informacion/:id" component={ MaestrosInfo } />
            <PrivateRoute type="school" exact path="/citas" component={ Eventos } />
            <PrivateRoute type="school" exact path="/eventos" component={ Eventos } />
            <PrivateRoute type="school" exact path="/eventos/agregar" component={ EventosAgregar } />
            <PrivateRoute type="school" exact path="/eventos/detalles/:id" component={ EventosDetalles } />
            <PrivateRoute type="school" exact path="/comunicados" component={ Comunicados } />
            <PrivateRoute type="school" exact path="/comunicados/agregar" component={ ComunicadosAgregar } />
            <PrivateRoute type="school" xact path="/comunicados/detalles/:id" component={ ComunicadosDetalles } />
            <PrivateRoute type="school" exact path="/lista_espera" component={ ListaEntregas } />
            <PrivateRoute type="school" exact path="/sede" component={ Sede } />
            <PrivateRoute type="school" exact path="/materias/agregar" component={ MateriasAgregar } />
            <PrivateRoute type="school" exact path="/escuela/lista/materias" component={ MateriasList } />
            <PrivateRoute type="school" exact path="/escuela/materias/:id/:nivel" component={ MateriasEscuela } />
            <PrivateRoute type="school" exact path="/escuela/materias/filtro/nivel/:id" component={ MateriasListLevels } />
            <PrivateRoute type="school" exact path="/escuela/agregar/materias/:id/:nivel" component={ MateriasAgregarEscuela } />
            <PrivateRoute type="school" exact path="/escuela/materias/informacion/:id/:nivel/:type" component={ MateriasDetallesEscuela } />
            <PrivateRoute type="school" exact path="/grupos" component={ Groups } />
            <PrivateRoute type="school" exact path="/grupos/crearGrupo" component={ CrearGroups } />
            <PrivateRoute type="school" exact path="/grupos/agregar/:id/:editId?" component={ GroupAssign } />
            <PrivateRoute type="school" exact path="/grupos/informacion/:id/" component={ GroupDetails } />
            <PrivateRoute type="school" exact path="/grupos/details/:id/:idS" component={ GroupDetailsBySubjects } />
            <PrivateRoute type="school" exact path="/escuela/chat" component={ Conversaciones } />
            <PrivateRoute type="school" exact path="/escuela/crearChat" component={ SeleccionChat } />
            {/* <PrivateRoute exact path="/maestros/secundaria/agregar" component={ MaestroSecundaria } /> */}


            {/* Teachers Routers */}
            
            {/* <PrivateRoute type="teacher" exact path="/teacher/materias" component={ SchoolSubjects }/> */}
            <PrivateRoute type="teacher" exact path="/teacher/home" component={ Home }/>
            <PrivateRoute type="teacher" exact path="/teacher/profile" component={ ProfileTeacher }/>
            <PrivateRoute type="teacher" exact path="/teacher/reports" component={ ReportsTeacher }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/informacion/:id" component={ SchoolSubjectsDetails }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/Operaciones/:id" component={ SchoolSubjectsOperations }/>
            <PrivateRoute type="teacher" exact path="/tareas/agregar" component={ NewHomework }/>
            <PrivateRoute type="teacher" exact path="/teacher/citas/detalles/:id" component={ AppoimentsDetails }/>
            <PrivateRoute type="teacher" exact path="/teacher/citas/tutores" component={ CitasTutores } />
            <PrivateRoute type="teacher" exact path="/teacher/citas/tutores/:group" component={ StudentItem }/>
            <PrivateRoute type="teacher" exact path="/teacher/citas/tutores/:group/:student" component={ ParentsItems }/>
            <PrivateRoute type="teacher" exact path="/teacher/citas/tutores/:group/:student/:tutor" component={ FormularioCitas }/>
            <PrivateRoute type="teacher" exact path="/teacher/avisos" component={ Calendar }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/select/" component={ TeacherGroups }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/group/:id" component={ SchoolSubjects }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/tareas/:id/:subject" component={ HomeworkList }/>
            <PrivateRoute type="teacher" exact path="/teacher/tareas/agregar/:id/:subject" component={ HomeworkNew }/>
            <PrivateRoute type="teacher" exact path="/teacher/tareas/detalles/:grupo/:subject/:tarea" component={ HomeworkDetail }/>
            <PrivateRoute type="teacher" exact path="/teacher/materias/asistencias/:grupo/:subject" component={ Asistencias }/>
            <PrivateRoute type="teacher" exact path="/teacher/evaluaciones/group" component={ EvaluationGroups }/>
            <PrivateRoute type="teacher" exact path="/teacher/evaluaciones/group/:group" component={ EvaluationSubjects }/>
            <PrivateRoute type="teacher" exact path="/teacher/evaluaciones/details/:group/:subject" component={ Evaluations }/>
            <PrivateRoute type="teacher" exact path="/teacher/chat" component={ ConversacionesMaestro } />
            <PrivateRoute type="teacher" exact path="/teacher/crearChat" component={ SeleccionChatMaestro } />
            <PrivateRoute type="teacher" exact path="/teacher/citas" component={ CitasTeacher }/>
            <PrivateRoute type="teacher" exact path="/admin/horarios/:type/detalles/info/:id" component={ScheduleInfo} />
            {/* <PrivateRoute exact path="/teacher/chat" component={ Chats }/> */}

            {/* Tutors Routers */}
            <PrivateRoute type="tutor" exact path="/tutor/chat" component={ ConversacionesTutor }/>
            <PrivateRoute type="tutor" exact path="/tutor/childs" component={ TutorChild }/>
            <PrivateRoute type="tutor" exact path="/tutor/crearChat" component={ SeleccionarChatTutor }/>
            <PrivateRoute type="tutor" exact path="/tutor/chat/:id" component={ ChatView }/>
            <PrivateRoute type="tutor" exact path="/tutor/information" component={ Information }/>
            <PrivateRoute type="tutor" exact path="/tutor/documents" component={ Documents }/>
            <PrivateRoute type="tutor" exact path="/tutor/profile" component={ Profile }/>
            <PrivateRoute type="tutor" exact path="/tutor/citas" component={ CitasTutor }/>
            <PrivateRoute type="tutor" exact path="/tutor/citas/alumno" component={ AlumnosForTutor }/>
            <PrivateRoute type="tutor" exact path="/tutor/citas/detalles/:id" component={ AppoimentsDetailsTutor }/>
            <PrivateRoute type="tutor" exact path="/tutor/citas/alumno/:id/:student" component={ Citas }/>
            <PrivateRoute type="tutor" exact path="/tutor/alumnos/:id" component={ ChildInfo }/>
            <PrivateRoute type="tutor" exact path="/tutor/adicionales" component={ ChildInfo }/>
            <PrivateRoute type="tutor" exact path="/tutor/configuracion" component={ Settings }/>
            <PrivateRoute type="tutor" exact path="/tutor/faq" component={ Faq }/>
            <PrivateRoute type="tutor" exact path="/tutor/calendario" component={ Calendar }/>
            <PrivateRoute type="tutor" exact path="/tutor/calendario/:id" component={ CalendarDetails }/>
            <PrivateRoute type="tutor" exact path="/tutor/citas/:teacherID/:studentID" component={ Appointments }/>
            <PrivateRoute type="tutor" exact path="/tutor/alumno/tareas/:grupoID/:materiaID/:studentID" component={ TutorHomeworkChild }/>

            {/* Child Routers*/}
            <PrivateRoute type="child" exact path="/child/information" component={ Information2 }/>
            <PrivateRoute type="child" exact path="/alumno/inicio" component={ HomeChild }/>
            <PrivateRoute type="child" exact path="/alumno/calendario" component={ CalendarChild }/>
            <PrivateRoute type="child" exact path="/alumno/materia/details/:id" component={ HomeworkChild }/>
            <PrivateRoute type="child" exact path="/alumno/tarea/detalles/:materiaID/:tareaID" component={ HomeworkDetailsChild }/>
            <PrivateRoute type="child" exact path="/alumno/chat" component={ ConversacionAlumno }/>
            <PrivateRoute type="child" exact path="/alumno/crearChat" component={ SeleccionarChatAlumno }/>
            <PrivateRoute type="child" exact path="/child/chat" component={ ChatComponent }/>
            <PrivateRoute type="child" exact path="/child/calendar" component={ ChildCalendar }/>
            <PrivateRoute type="child" exact path="/child/materia" component={SubjectsChild}/>
            <PrivateRoute type="child" exact path="/child/horarios/:type/detalles/info/:id" component={ScheduleInfo} />


            {/* Admin Routes */}
            <PrivateRoute type="Admin" exact path="/admin/escuela/crear" component={ SchoolReg } />
            <PrivateRoute type="Admin" exact path="/admin/escuela/grupos" component={ AddGroupBySchool } />
            <PrivateRoute type="Admin" exact path="/admin/escuela/lista" component={ AddGroupBySchool } />
            <PrivateRoute type="Admin" exact path="/admin/notificaciones" component={ NotificatiosforSchool } />
            <PrivateRoute type="Admin" exact path="/admin/horarios" component={ Schedule } />
            <PrivateRoute type="Admin" exact path="/admin/horarios/:id" component={ ScheduleDetails } />
            <PrivateRoute type="Admin" exact path="/admin/horarios/grupos/:from/:id" component={ Groups } />
            <PrivateRoute type="Admin" exact path="/admin/horarios/:type/detalles/info/:id" component={ScheduleInfo} />
            <PrivateRoute type="Admin" exact path="/admin/escuela/profesores/:from/:id" component={Maestros} />
            
            {/* General Routes */}
            
            <PrivateRoute type="all" exact path="/" component={ Home }/>
            <PrivateRoute type="all" exact path="/notificaciones" component={ Notificaciones } />
            <PrivateRoute type="all" exact path="/events/details/:id" component={ EventsGeneralDetails } />
            <PrivateRoute type="all" exact path="/public/comunicados/:id" component={ EventosPublico } />



            {/* WebViewr Router */}
            <AppPrivate type="all" exact path="/app/" component={Home} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}
export default App;
