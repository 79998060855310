import React, {useState, useContext, useEffect} from 'react'
import { AuthContext } from '../../../Components/functions/Auth'
import * as firebase from 'firebase';
import {useHistory} from "react-router-dom";
//material-UI
import { Grid, Box, Button, Link, Typography, Avatar  } from '@material-ui/core';
import { Chat, ArrowForwardIos } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//Assets
import imgChild from '../../../Assets/Img/General/placeholder_boy.png';
import imgTutor from '../../../Assets/Img/General/placeholder_man.png';
import imgTeacher from '../../../Assets/Img/General/placeholder_teacher-m.png';
//Componentes
import Chatbody from '../ChatBody'
import style from '../../../Assets/Styles/chat.module.css'
import NavChildPhone from '../../../Pages/Child/NavChildPhone/NavChildPhone';
export default function ConversacionAlumno() {
    const {currentUser } = useContext(AuthContext);
    const history   = useHistory();

    const [ refresh, setRefresh ] = useState(0)
    const [ messages, setMessages ] = useState(null);
    const [ conversation, setConversation ] = useState(null);
    const [ idConversation, setIDConversation ] = useState(null)
    const [ sender, setSender ] = useState(null)

    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);

    const ChatREF = firebase.database().ref('chats')

    useEffect(() => {
        if(idConversation){
            getRefresh()
        }else{
            getChats()
        }
    }, [refresh])

    async function getChats(){
        var listChats = [];
        var type = currentUser.split("_")
        var valores = await ChatREF.once('value')  
        valores.forEach(item => {
            let data = item.val()
            if( data[type[0]]?.key == currentUser ){
                if(data.school){
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "school" })
                }
                else if(data.teacher) {
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "teacher" })
                }
                else if(data.tutor) {
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "tutor" })
                }
                else if(data.child)
                {
                    listChats.push({ timestamp:data.lastUpdate, id:item.key, messages:data.messages, data:data, type:"child" })
                }
            }
        })
        if ( listChats.length > 0 ) {
            let arraylist = listChats.sort(compare)
            setMessages(arraylist.reverse())
        }
        else{
            setMessages( false )
        }
    }

    async function getRefresh(){
        var data = await ChatREF.child(idConversation).once('value')
        setConversation(data.val().messages)
    }

    function compare(a, b) {
        const optionA = a.timestamp;
        const optionB = b.timestamp;
        let comparison = 0;
        if (optionA > optionB) 
        {
            comparison = 1;
        } 
        else if (optionA < optionB) 
        {
            comparison = -1;
        }
        return comparison;
    }

    const handleStep1 = () =>{
        setStep1(!step1)
        setStep2(!step2)
    }
    const handleSelectConversation = (mesages, id, info) =>{
        setStep1(!step1)
        setStep2(!step2)
        setConversation(mesages);
        setIDConversation(id)
        setSender(info)
    }
    const handleSender = (info) => 
    {
        setSender(info)
    }

    useEffect(() => {
        if(messages && refresh){
            let arraylist = messages.sort(compare)
            setMessages(arraylist.reverse())
        }
    }, [refresh])

    return(
        <Grid xs={12}>
            { step2 && 
                <Grid container >
                    <NavChildPhone/>
                    <Grid container className="end_chat_contactList" direction="row"  alignItems="center">
                        <Chatbody information={idConversation} functionBack={handleStep1} messages={conversation} reload={setRefresh} user={sender}  />
                    </Grid>
                </Grid>
            }
            { step1 && 
                <Box className="kipperBox_content" boxShadow={3}>
                    <NavChildPhone />
                    <Grid container >
                        <Grid container className="end_chat_contactList" direction="row"  alignItems="flex-end">
                            <Box display="flex" pr={1} id="ConversacionTutor__buttonHeader">
                                <Link to="/" px={1} py={3}>
                                    <Button onClick={ e => history.push("/alumno/crearChat")} variant="outlined" className="kipper_btn-outline-new">Nueva Conversacion</Button>
                                </Link>
                            </Box>
                            <Box display="flex" className="ChatButtonDiv" >
                                <Link to="/" px={1} py={3}>
                                    <Button onClick={ e => history.push("/")} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={ 12 }>                  
                            {( messages )
                                ? messages.map(item => 
                                    <ItemList 
                                        id={item.id} 
                                        data={item.data} 
                                        messages={item.messages} 
                                        type={item.type} 
                                        selectConversation={handleSelectConversation} 
                                        functionInfo={handleSender}
                                    />
                                )
                                : <Box mx={1} > <ErrorOutlineIcon /> Sin conversaciones. </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            }
        </Grid>
    )
}

const ItemList = ({ id, data, type, selectConversation, functionInfo}) =>{
    const [information, setInformation]=useState(null)
    const [ msg, setMsg] = useState(null)
    const [avatarPlaceholder, setavatarPlaceholder]=useState(null)
    const ChatREF = firebase.database().ref('chats')


    useEffect(() => {
        getInformation();
        refreshMessages();
    }, [data])

    async function getInformation ()
    {
        
        var traslateType;
        switch (type) {
            case "child":
                traslateType = "childs";
                setavatarPlaceholder(imgChild)
                break;
            case "tutor":
                traslateType = "tutors";
                setavatarPlaceholder(imgTutor)
                break;
            case "school":
                traslateType = "schools";
                setavatarPlaceholder(imgTutor)
                break;
            default:
                traslateType = "teacher"
                setavatarPlaceholder(imgTeacher)
                break;
        }
        if(traslateType)
        {
            let refcheck = await firebase.database().ref(`${traslateType}/${data[type].key}`).once('value');
            setInformation(refcheck.val())
            functionInfo(refcheck.val())
        }
    }

    async function refreshMessages(){
        var infos = await ChatREF.child(id).once('value')
        setMsg(infos.val().messages)
       
    }

    return(
        <Box p={3}>
            <Grid container onClick={e => {selectConversation(msg, id, information) }} direction="row" justify="space-around" alignItems="center" spacing={2}>
                <Grid item xs={2} >
                    <Box borderRadius="50%" className="flex-container flex-center">
                        <img src={ information?.avatar ?information?.avatar :avatarPlaceholder } alt="" className={`${style.avatar_chat}` }  />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className="div_long_text" >
                    <p className="Kipper_title-black listNameChat" style={{paddingLeft:'14px'}}  >
                        { information?.name } {information?.lastname ?information?.lastname :null}
                    </p> <br/> 
                    </Box>
                </Grid>
                <Grid item xs={1} className="Conversaciones__Boxicon">
                    <ArrowForwardIos />      
                </Grid>
            </Grid>
        </Box>
    )
}
