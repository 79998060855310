import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../../Components/functions/Auth";
import { useRouteMatch } from 'react-router-dom';
//materialui
import {Box,Grid,Typography,Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Evaluations from '../../../Assets/Img/General/placeholder_boy.png';
//Firebase
import * as firebase from 'firebase';

const useStyles = makeStyles({
    promedy_textDIV:{
        margin:'30px',
    },
});

const EvaluacionesPromedio = ({ infoindex, infogen, infoPart, fresh }) => {
    /* 
        infodex: codigo de la informacion del alumno
        infogen: array inicial con las calificaciones del alumno
    */
    const styles = useStyles();
    
    const { infoSchool } = useContext(AuthContext);
    const match = useRouteMatch('/teacher/evaluaciones/details/:group/:subject');
    const groupID = match.params.group;
    const subjectID = match.params.subject;

    const db = firebase.database();
    const [informationgen, setinformationgen] = useState(null)
    const [eval0, seteval0] = useState({...infogen});
    const [parcial, setParcial] = useState(null);
    const [evalchange, setevalchange] = useState(0)
    const [promedio, setPromedio] = useState(null);

    function infogetting(){
        let ref = db.ref('childs/'+infoindex);
        ref.once('value').then(function(snapshot) {
            setinformationgen(snapshot.val())
        });
    }

    const changeevaluations =(index,parcialValue)=>{
        
        let ref4 = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/school_subjects/'+subjectID+'/evaluations/'+infoindex)

        if(!isNaN(parcialValue)){
            if(parcialValue<=10 && parcialValue>=0){
                if(parcialValue=="")//Insertamos valor 0
                    ref4.update({ ...eval0,[index]:0 });
                else//Insertamos el valor correcto
                    ref4.update({ ...eval0,[index]:parseInt(parcialValue) })
                setevalchange(evalchange+1)
            }else{//Valor invalido
                ref4.update({ ...eval0,[index]:0 });
                seteval0({ ...eval0, [index]: 0 });
                setevalchange(evalchange+1)
            }
        }else{
            ref4.update({ ...eval0,[index]:0 });
            seteval0({ ...eval0, [index]: 0 });
        }


    }

    const getSubjects = async () => {
        let refSubjects = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/school_subjects/'+subjectID)
        const data = await refSubjects.once('value')
        let inputs= []
        for (let p = 0; p < parseInt( data.val().partials ); p++) {
            inputs.push( `P${p+1}` )
        }
        setParcial( inputs );
    }

    useEffect(() => {  
        infogetting() 
        getSubjects()
    }, [fresh])

    useEffect(() => {
        if(evalchange>0){
            let ref = db.ref('notifications')
            var userList = [];
            userList.push({key: infoindex, status: 0})
            ref.push({
                body: `El maestro ${infoSchool.name} ${infoSchool.lastname} ha actualizado tus evaluaciones`,
                date: new Date().getDate(),
                school_key: infoSchool.school,
                time: new Date().getTime(),
                title: "Evaluacion Actualizada" ,
                type: "childs" ,
                userList
            }, function(error) {
                if (error) { 
                    // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                }
            });
        }
    }, [evalchange,fresh])

    useEffect(() => {
        /*let _count = 0
        Object.entries( eval0 ).forEach( index => {
            console.log("forEach: ", eval0[index]);
            console.log("Index: ",index);
            console.log("eval0: ",eval0[index[1]]);
            if (( eval0[index[1]] === 'undefined' || eval0[index[1]] === 'null' )) {
                _count = 0 + _count;
            } else {
                _count = eval0[index[1]] + _count;
            }
        })
        let res = _count / eval0.length;
        setPromedio( res );
        console.log("Promedio: ",promedio)*/

        /*parcial.forEach(p =>{
            console.log("Parcial iteracion: ", p);
        });*/
        let count = 0;
        let res = 0;
        if(parcial){
            parcial.forEach(index => {
                if(!isNaN(eval0[index])&& (eval0[index] >=0 &&eval0[index] <=10))
                    count += parseInt(eval0[index]);
                else
                    count += 0;
            });
            res = count / parcial.length;
            if(isNaN(res))setPromedio(0);
            else setPromedio(Math.floor(res));
        }

    }, [evalchange,fresh,parcial])
    
    return (
        <Grid  container>
            <Grid  item xs={2} md={2} className="Grid_image">
                <Box>
                    { informationgen?.avatar
                        ?<img src={informationgen?.avatar} alt="" className="image_evaluations" width="" />
                        :<img src={Evaluations} alt="" className="image_evaluations" width="" />
                    }
                </Box>
            </Grid>  
            <Grid item xs={3} md={3} lg={2} className="boxChild" container direction="column" justify="space-around"  >
                <Box>
                    <Typography variant="h5" >{informationgen?.name} {informationgen?.last_name}</Typography>    
                </Box>                    
            </Grid>
            <Hidden mdDown>
                <Grid item className={styles.promedy_textDIV} xs={true} md={1} container direction="column" justify=" space-around" alignItems="stretch">
                    <Box >
                    <Typography>PARCIALES: </Typography>
                    </Box>
                </Grid>
            </Hidden>
            <Grid item xs={5} md={3} lg={2} container direction="column" justify="space-around" alignItems="stretch">
                <Box>
                    { parcial && parcial.map( index => {
                        return <input
                            className="inputEvaluations"
                            value={ isNaN(eval0[index])?0:eval0[index]}  
                            onChange={ e => {seteval0({ ...eval0, [index]: parseInt(e.target.value) }); changeevaluations(index,e.target.value) }} 
                            border={1} 
                            type="text" 
                            minlength={0}
                            maxlength={3}
                        />
                    }
                    )}
                </Box>
            </Grid>
            <Hidden mdDown>
            <Grid  className={styles.promedy_textDIV} item xs={true} md={1}  container direction="column" justify="space-around"  alignItems="stretch">
                <Box className="promedy_text">
                    <Typography> PROMEDIO: </Typography>
                </Box>
            </Grid>
            </Hidden>
            <Grid item xs={2} md={1} mr={25} ml={25} container direction="column" justify="space-around" alignItems="stretch">
                <Box spacing={10}>
                    <input value={promedio ?promedio :0} border={1} padding={20} className="EvaluationsProm" type="text" disabled/>
                </Box>
            </Grid>
        </Grid>
    );
}


export default EvaluacionesPromedio