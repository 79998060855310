import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core'
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../functions/Auth';

const TutorHomeworkItem = ( { TareaID, MateriaID, SchoolID, GroupID, title, details, date } ) => {
    
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const [homeworkStatus, setHomeworkStatus] = useState('');
    
    useEffect(() => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${SchoolID}/groups/${GroupID}/school_subjects/${MateriaID}/homework/${TareaID}/deliveries`);
        groupRef.once('value').then(  (snapshot) => {
            if ( snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    setHomeworkStatus( itemInfo.status )
                })    
            }
        })
    }, []);
    
    return (
        <Box mx={2} my={1} >
            {/* <Link to={`/alumno/tarea/detalles/${MateriaID}/${TareaID}`} > */}
                <Grid container >
                    <Grid item xs={8} sm={10}>
                        <Typography variant="body1" >
                            { title }
                        </Typography>
                        <Typography variant="body2" >
                            { details } <br />
                            <small> { date } </small>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2} style={{ alignSelf: "center" }}>
                        <Box display="flex" flexDirection="row-reverse">
                            { homeworkStatus === 0 && <span className="homework-pending"> Pendiente </span> }
                            { homeworkStatus === 3 && <span className="homework-pending"> Pendiente </span> }
                            { homeworkStatus === 1 && <span className="assistance-true"> Entregado </span> }
                            { homeworkStatus === 2 && <span className="assistance-true"> Entregado </span> }
                            { homeworkStatus === 4 && <span className="assistance-true"> Entregado </span> }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box  mb={1} >
                            <hr/>   
                        </Box>
                    </Grid>
                </Grid>
            {/* </Link> */}
        </Box>
    )
}

export default TutorHomeworkItem
