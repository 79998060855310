import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal'
//img
import portada from './../../Assets/Img/General/portada_add-blue.png';
import file from './../../Assets/Img/General/File.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
import pencil from './../../Assets/Img/Home/Icon_Pencil.png';
import user from './../../Assets/Img/General/placeholder_man.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
//firebase
import * as firebase from 'firebase';
import { checkFile, checkFilepdf } from '../../Components/functions/helpapers';

const TutorDetails = ( ) => {

    const history   = useHistory();
    const match     = useRouteMatch('/escuela/tutor/editar/:id');
    const tutorID   = match.params.id;
    const { currentUser } = useContext(AuthContext);

    const [ avatar, setAvatar ]                         = useState('');
    const [ name, setName ]                             = useState('');
    const [ errorNameAlert, setErrorNameAlert ]         = useState(false)
    const [ lastname, setLastName ]                     = useState('');
    const [ errorLastNameAlert, setErrorLastNameAlert ] = useState(false)
    const [ email, setEmail ]                           = useState('');
    const [ errorEmailAlert, setErrorEmailAlert ]       = useState(false)
    const [ phone, setPhone ]                           = useState('');
    const [ errorPhoneAlert, setErrorPhoneAlert ]       = useState(false)
    const [ docId, setDocId ]                           = useState('');
    const [ docProofAddres, setDocProofAddres ]         = useState('');
    const [ docOpcional, setDocOpcional ]               = useState('');
    const [ refresh, setRefresh ]                       = useState( 1 );
    const [ changeAvatar, setChangeAvatar]               = useState(false);
    const [ changeId, setChangeId]                       = useState(false);
    const [ changeProofAddres, setChangeProofAddres]     = useState(false);
    const [ changeOpcional, setChangeOpcional]           = useState(false);
    const [ error, seterror ]                           = useState('');
    const [ errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    const regExps ={
        name: /^[a-zA-ZÀ-ÿ\s]{5,40}$/,
        lastName:  /^[a-zA-ZÀ-ÿ\s]{5,40}$/,
        eMail: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9-.]+$/,
        phone: /^[0-9.-]{10,15}$/,
    }
    
    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setAvatar( img );
                setChangeAvatar( true )
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        console.log( url );
                        const profileImg = document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }  
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setDocId( img );
                setChangeId( true );
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg o pdf' });
        }  
    }

    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setDocProofAddres( img );
                setChangeProofAddres( true )
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutor/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                        // console.log(url)
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, comprobante: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setChangeOpcional( true )
                setDocOpcional( img );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        // console.log(url)
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 
    }
    const handlesubmit = e =>{
        e.preventDefault();
        // RegExp to check te fields 
        const regexname = RegExp(/\w/g);
        const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const regexphone = RegExp(/\d+[^a-zA-Z]{6,}/);
        const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

        if( !regExps.name.test(name) ){ 
            setName('')
            setErrorNameAlert(true);
            setErrorEmailAlert(false);
            setErrorLastNameAlert(false);
            setErrorPhoneAlert(false);
        }
        else if( !regExps.lastName.test(lastname)){ 
            setLastName('')
            setErrorLastNameAlert(true);
            setErrorNameAlert(false);
            setErrorEmailAlert(false);
            setErrorPhoneAlert(false);
        }
        else if( !regExps.eMail.test(email) ){ 
            setEmail('')
            setErrorEmailAlert(true);
            setErrorLastNameAlert(false);
            setErrorNameAlert(false);
            setErrorPhoneAlert(false);
        }
        else if( !regExps.phone.test(phone) ){ 
            setPhone('')
            setErrorPhoneAlert(true);
            setErrorLastNameAlert(false);
            setErrorNameAlert(false);
            setErrorEmailAlert(false);
            
        }
        else{ handleForm(e); }
    }
    const handleForm = e =>{
        e.preventDefault();

        const avatarIMG = changeAvatar ? document.getElementById('AvatarIMG').src : avatar  ;
        const idIMG = changeId ? document.getElementById('idIMG').src : docId ;
        const comprobanteIMG = changeProofAddres ? document.getElementById('comprobanteIMG').src : docProofAddres;
        const opcionalIMG = changeOpcional ? document.getElementById('opcionalIMG').src : docOpcional ;

        let db = firebase.database();
        let Usersref = db.ref(`tutors/${tutorID}`);
        Usersref.update({
            avatar: avatarIMG,
            name: name,
            last_name: lastname,
            contact:{
                email: email,
                phone: phone,
            },
            documents:{
                id: idIMG,
                opcional: opcionalIMG,
                proof_addres: comprobanteIMG,
            }
        }, 
        error => {
            if (error) {
              // The write failed...
                console.log(error)
            } else {
                // Data saved successfully!
                setName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setAvatar('');
                setDocId('');
                setDocProofAddres('');
                setDocOpcional('');
                setModalmsg('Información actualizada.');
                setRefresh( refresh + 1 )
                setModalLink(`/alumnos`);
                ModalOpen();
            }
        });
    }
    
    useEffect(() => {
        let db = firebase.database();
        let childRef = db.ref(`tutors/${tutorID}`);
        childRef.once('value').then(function(snapshot) {
            setAvatar   (snapshot.val().avatar);
            setName     (snapshot.val().name);
            setLastName (snapshot.val().last_name);
            setEmail    (snapshot.val().contact?.email);
            setPhone    (snapshot.val().contact?.phone);
            setDocId    (snapshot.val().documents?.id);
            setDocProofAddres (snapshot.val().documents?.proof_addres);
            setDocOpcional (snapshot.val().documents?.opcional);
        })
    }, [ refresh ]);

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={ e => handlesubmit(e) } >
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        />
                    </Grid>
                </Modal>
                <Grid container>
                    <Grid item xs={12}>
                        <Box pb={3}>
                            <PageHeader 
                                title="Tutores y delegados"
                                text="Información de seguridad del tutor o delegado para la salida y recepción de los alumnos"
                                btn_back={`/alumnos`}
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={12} className="Form_add-content">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box className="Form_add-profile-img">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <div className="kipperBox_content-upload">
                                        <input type="file" id="ProfileImage" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e )} />
                                        <Grid container direction="column" justify="center" alignItems="center">
                                            { changeAvatar
                                                ?<img className="rounded previw_img" alt="tutor" id='AvatarIMG'/>
                                                :<img src={ avatar ? avatar : user } alt="tutor" className="rounded previw_img"  />
                                            }
                                            <img src={pencil} style={{cursor:'pointer'}} className="icon_pencil kipper_txt-grey" onClick={ e => document.getElementById("ProfileImage").click() } alt="teacher avatar"  />
                                            { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                        </Grid>
                                    </div>
                                </Grid>
                            </Box>
                            <Box pt={3}>
                                <Grid container>
                                    <Grid item sm={12} md={6}>
                                        <Box id="kiper_editarTutor_textInput" p={2}> 
                                            <TextField  label="Nombre" type="text" fullWidth placeholder={ name } error={errorNameAlert} helperText={errorNameAlert ? 'El nombre debe contener entre 5-40 caracteres y solo se admiten letras' : ' '} onChange={e => setName(e.target.value) }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Box id="kiper_editarTutor_textInput" p={2}> 
                                            <TextField label="Apellido" type="text" fullWidth placeholder={ lastname } error={errorLastNameAlert}  helperText={errorLastNameAlert ? 'El apellido debe contener entre 5-40 caracteres y solo se admiten letras' : ' '} onChange={ e => setLastName(e.target.value) }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Box id="kiper_editarTutor_textInput" p={2}> 
                                            <TextField label="Teléfono" type="number" fullWidth placeholder={ phone } error={errorPhoneAlert}  helperText={errorPhoneAlert ? 'El número telefónico debe contener de 10 a 15 dígitos' : ' '} onChange={ e => setPhone(e.target.value) }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Box id="kiper_editarTutor_textInput" p={2}> 
                                            <TextField label="Correo electrónico" type="email" fullWidth placeholder={ email } error={errorEmailAlert}  helperText={errorEmailAlert ? 'La estructura del eMail debe ser "palabra@palabra.com"' : ' '} onChange={ e => setEmail(e.target.value) }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Box p={2}>
                                            <Typography variant="h5" gutterBottom>
                                                Documentación (opcional)
                                            </Typography>
                                        </Box>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                    <label for="docIdentificacion" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img src={ upload } alt=""/>
                                                            { docId 
                                                                ? <p className="kipper_txt-color">{ docId.name }</p>
                                                                : <p className="kipper_txt-color">Selecciona archivo</p>
                                                            }
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'idIMG'} />
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        Identificación oficial
                                                    </Typography>
                                                    { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                    <label for="docComprobante" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img src={ upload } alt=""/>
                                                            { docProofAddres 
                                                                ? <p className="kipper_txt-color">{ docProofAddres.name }</p>
                                                                : <p className="kipper_txt-color">Selecciona archivo</p>
                                                            }
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} />
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        Comprobante de domicilio
                                                    </Typography>
                                                    { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                    <label for="docOpcional" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img src={ upload } alt=""/>
                                                            { docOpcional 
                                                                ? <p className="kipper_txt-color">{ docOpcional.name }</p>
                                                                : <p className="kipper_txt-color">Selecciona archivo</p>
                                                            }
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        Documento opcional
                                                    </Typography>
                                                    { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Box px={2} pb={3}>
                                                    <Typography className="" variant="body1" display="block" gutterBottom>
                                                        <b>*Los documentos deben ser de tipo JPG, JPEG, PNG o PDF.</b> 
                                                        { error }
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default TutorDetails