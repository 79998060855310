import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import moment from 'moment';
// firebases
import * as firebase from 'firebase';
import { AuthContext } from '../functions/Auth';

const AssistenceChildForTutor = ( { assistanceId, date, materiaID, SchoolID, GroupID, StudentID } ) => {

    const { info, infoSchool } = useContext(AuthContext);
    const [AssistenceInfo, setAssistenceInfo] = useState('')

    useEffect( () => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${SchoolID}/groups/${GroupID}/school_subjects/${materiaID}/assistance/${assistanceId}/students/${StudentID}`);
        groupRef.once('value').then(  (snapshot) => {
            setAssistenceInfo( snapshot.val() === null ? 0 : snapshot.val() )
        })
    }, []);

    return (
        <Box mx={2} my={1} >
            <Grid container >
                <Grid item xs={8} sm={10}>
                    <Typography variant="body1" >
                        { date }
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={2} style={{ alignSelf: "center" }}>
                    <Box display="flex" flexDirection="row-reverse">
                        { AssistenceInfo.assistance == "No" && <span className="assistance-false"> Falta </span> }
                        { AssistenceInfo.assistance == "Yes" && <span className="assistance-true"> Asistencia </span> }
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box mb={1} >
                    <hr/>   
                </Box>
            </Grid>
        </Box>
    )
}

export default AssistenceChildForTutor
