import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { Link } from 'react-router-dom';
import moment from 'moment';
import ModalShow from './../../Components/Global/Modal'
import Modal from '@material-ui/core/Modal';

import 'moment/locale/es';
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// firebases
import * as firebase from 'firebase';
import { Hidden } from '@material-ui/core';

const EventoItem = ( { id, type, title, body, date, btn_to, visto, deleteEvent, issubjects, gruop, isRemove, isOutline, denied_appoiment, reason } ) => {

    const { currentUser }   = useContext(AuthContext);
    const [ modalStaus, setModalStaus ] = useState( false );
    

    const renderAppoiment = () =>{
        switch(denied_appoiment){
            case 0:
                return "Appoiment_Flag pending"
            case 1:
                return "Appoiment_Flag aproved"
            case 2:
                return "Appoiment_Flag denied"
        }

    }
    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    if ( isOutline ) {
        return(
            <Box className="box_outline-primary" py={2} pr={3}>
                <Grid container>
                    <Grid item xs={5} sm={4}>
                        <Grid container direction="row" justify="center" alignItems="center" className="EventBox_date" >
                            <div className="aling_center">
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("dddd") } </span> <br />
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("D") } </span> <br />
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("MMMM") } </span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} sm={8}>
                        <p className="Kipper_title-black kipper_title"> { title } </p> 
                        <p className="kipper_txt-grey box_details"> { body } </p>
                        {(visto > 0) && <p className="Kipper_title-black kipper_title"> Visto: { visto } veces </p>  }
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box className="padding_left_events">
                            <Grid xs={12} md={12} container direction="row" justify="center" alignItems="center" className={renderAppoiment()}>
                                {denied_appoiment == 1
                                    ?<span>Aprobada</span>
                                    :null
                                }
                                {denied_appoiment == 2
                                    ?<span>Denegada</span>
                                    :null
                                }
                                {denied_appoiment == 0
                                    ?<span>Pendiente</span>
                                    :null
                                }
                            </Grid>
                        </Box>                        
                    </Grid>
                    <Grid item xs={6} md={8} justify="center" alignItems="center">
                        <Box>
                            <Link to={ btn_to } >
                                <Button variant="contained" className="kipper_button" fullWidth disableElevation> Detalles </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    
    if( issubjects ){
        return(
            <Box boxShadow={3} borderRadius={16} className="bg-white" py={3} pr={3}>
                <Grid container>
                    <Grid item xs={4}>
                        <Grid container direction="row" justify="center" alignItems="center" className="EventBox_date" >
                            <div className="aling_center">
                                <span className="EventBox_date-day capitalize"> { type } </span> 
                                <span className="EventBox_date-day capitalize"> { gruop } </span> <br />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <p className="kipper_txt-grey capitalize"> { type } </p> 
                        <p className="Kipper_title-black kipper_title"> { title } </p> 
                        <p className="kipper_txt-grey"> { body } </p>
                        <p className="Kipper_title-black kipper_title"> Visto: { visto?.length } veces </p> 
                        {/* <p className="Kipper_title-black kipper_title"> No recibio nada </p>  */}
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={3} pl={3}>
                            <Grid container direction="row" justify="space-between" alignItems="center" >
                                <form onSubmit={ e => deleteEvent( e, id ) }>
                                    <Button variant="outlined" className="kipper_btn-outline-black" type="submit" > Eliminar</Button>
                                </form>
                                <Link to={ btn_to } >
                                    <Button variant="contained" className="kipper_button" disableElevation> Detalles </Button>
                                </Link>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else{
        return(
            <Box boxShadow={3} borderRadius={16} className="bg-white" py={3} pr={3}>
                <Grid container>
                    <Grid item xs={4}>
                        <Grid container direction="row" justify="center" alignItems="center" className="EventBox_date" >
                            <div className="aling_center">
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("dddd") } </span> <br />
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("D") } </span> <br />
                                <span className="EventBox_date-day capitalize"> { moment( date ).format("MMMM") } </span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <p className="kipper_txt-grey capitalize"> { type } </p> 
                        <p className="Kipper_title-black kipper_title"> { title } </p> 
                        <p className="kipper_txt-grey"> { body } </p>
                        { visto > 0  && <p className="Kipper_title-black kipper_title"> Visto: { visto } veces </p>  }
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={3} pl={3}>
                            <Grid container direction="row" justify="space-between" alignItems="center" >
                                { isRemove 
                                    ? <p></p>
                                    : <form onSubmit={ e => deleteEvent( e, id ) }>
                                            <Button variant="outlined" className="kipper_btn-outline-black" type="submit" > Eliminar</Button>
                                        </form>
                                }
                                <Link to={ btn_to } >
                                    <Button id="eventoItem-blueButton" variant="contained" className="kipper_button" disableElevation> Detalles </Button>
                                </Link>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default EventoItem