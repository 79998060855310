import React, { useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
// functions
import Formlogin from '../Components/functions/Login'
import { AuthContext } from "../Components/functions/Auth";

//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

function Login(){
    const { currentUser } = useContext(AuthContext);

        if (localStorage.getItem('currentUser')) 
        {
            return <Redirect to="/" />;
        }
        else
        {
            return(
                <Grid className="login_grid" container >
                    <Formlogin/>
                </Grid>
            );
        }
}

export default withRouter(Login)
