import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user2 from './../../Assets/Img/General/placeholder_teacher-f.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChildListItem from './ChildListitem'

// import Pagination from '@material-ui/lab/Pagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import { Grid, Box, Typography, Divider } from '@material-ui/core';
import * as firebase from 'firebase';
import { months } from 'moment';

export default function ListItem({list}) {
    //Context and Params in the page
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    /*  Declaraciones de variables en los parametros  */
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/asistencias/:grupo/:materia');
    const grupoID = match.params.grupo;
    const subjectID = match.params.materia;

    /* Declaraciones o Redeclaraciones de variables que se usaran mucho en este componente */
    const kids = Object.entries(list[1].students);
    const settime = Object.entries(list[1].time);
    const statuslist = list[1].status
    const db = firebase.database();
    
    /* declarar y refedinir los tiempo obtenidos  */
    const time = new Date(list[1].time);
    const day = time.getDate()
    const mont = time.getMonth()
    const dayweek = time.getDay()

    /* hooks del componente */
    const [listas, setlistas] = useState(null)
    const [statuslistvar, setstatuslistvar] = useState(list[1].status)

    /*  */
    useEffect(() => {
        let ref = db.ref(`childs`);
        var listkids = [];
        try{
            kids.forEach(element => {
                let childref = ref.child(element[0])
                childref.once('value').then( snapshot => {
                    let data = snapshot.val()
                    listkids.push({name:data?.name, last_name: data?.last_name, avatar: data?.avatar})
                });
            });
            setlistas(listkids)
        }finally{
            setlistas(listkids)
        }
    }, [])

    function Day(){
        if(time){
            switch (dayweek) {
                case 0:
                    return 'Domingo'
                break;
                case 1:
                    return 'Lunes'
                break;
                case 2:
                    return 'Martes'
                break;
                case 3:
                    return 'Miercoles'
                break;
                case 4:
                    return 'Jueves'
                break;
                case 5:
                    return 'Viernes'
                break;
                case 6:
                    return 'Sabado'
                break;
            }
        }

    }

    function Month(){
        if(time){
            switch (mont) {
                case 0:
                    return 'Enero'
                break;
                case 1:
                    return 'Febrero'
                break;
                case 2:
                    return 'Marzo'
                break;
                case 3:
                    return 'Abril'
                break;
                case 4:
                    return 'Mayo'
                break;
                case 5:
                    return 'Junio'
                break;
                case 6:
                    return 'Julio'
                break;
                case 7:
                    return 'Agosto'
                break;
                case 8:
                    return 'Septiembre'
                    break;
                case 9:
                    return 'Octubre'
                break;
                case 10:
                    return 'Noviembre'
                break;
                case 11:
                    return 'Diciembre'
                break;
            }
        }else{
            return null
        }
    }

    function Statuschange(e){
        setstatuslistvar(statuslistvar? 0 :1)
        let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance/${list[0]}`);
        ref.update({ status: 1 }, 
            function(error) {
/* Revisamos si existe algun error para que este sea impreso en la consola si es que este existe de alguna forma */
                if (error) { console.log(error) }
/* Si no existe ningun error procedemos a notificar al alumno(s) sobre sus inasistencias */
                else{notificacion();}
            }
        );
    }

    function EditList(e){
        setstatuslistvar(statuslistvar? 0 :1)
        let ref = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance/${list[0]}`);
        ref.update({ status: 0 }, 
            function(error) {
/* Revisamos si existe algun error para que este sea impreso en la consola si es que este existe de alguna forma */
                if (error) { console.log(error) }
            }
        );
    }

/* Esta funcion dara las notificaciones a los alumnos solo si estos fueron guardados con falta o insasistencias
de esa forma al maestro concluir su toma de asistencia se notifica al alumno de su falta */
    function notificacion(  ){
/* Declaro mi arreglo al vacio para rellenar despues  */
        var userList = [];
/* Declaro mis referencias */
        let ref2 = db.ref(`schools/${infoSchool.school}/groups/${grupoID}/school_subjects/${subjectID}/assistance/${list[0]}`);
        var ref = db.ref('notifications')
/* Llamado a firebase para consultar la informacion de las asistencias de la lista guardada */
        ref2.once('value').then((snapshot) =>{
            if(snapshot.val().students){
/* Definimos nuestro arreglo compuesto por todos los estudiantes, para revisar si alguno tiene falta o quienes tienen falta */
            var students = Object.entries(snapshot.val().students)
            students.forEach(element => {
/* Revisamossi el alumno tiene su inasistencia para meterlo en un arreglo y asi notificar de dicha falta */
                if(element[1].assistance == "No"){
                    userList.push({key: element[0], status: 0})
                }
            });
/* Esto llama a la funcion de envio de notificaciones para reportar las faltas a los alumnos y padres */
            sendnotification(userList)
            }
        })
    }

/* Funcion que solo se encarga de llevar las notificaciones de inasistencia de los alumnos para su comprension */
    function sendnotification(userList){
        if(userList.length > 0){
            let ref = db.ref('notifications')
            ref.push({
                body: `El maestro ${infoSchool.name} ${infoSchool.lastname} ha marcado que no has asistido a clases`,
                date: new Date().getDate(),
                school_key: infoSchool.school,
                time: new Date().getTime(),
                title: "Notificacion de Inasistencia" ,
                type: "childs" ,
                userList
            }, function(error) {
                if (error) { 
                    // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                }
            }); 
        } 
    }

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <span className="notificacion_title">{`${Day()}, ${day} ${Month()}`}</span>
                    </Box>
                </Grid>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
            <Grid container>
                {
                    kids ? 
                        kids.map( i => 
                            <ChildListItem listchilds={i[0]} assistance={list[0]} metadata={i[1]} statuslist={statuslistvar} />
                        )
                    : null
                }
                {
                    statuslistvar
                        ?<Grid container>
                            <Grid xs={2}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button className="kipper_button" disableElevation onClick={e=>EditList()}>
                                        Editar
                                    </Button> 
                                </Box>
                            </Grid>
                        </Grid>
                        :<Grid container>
                            <Grid xs={2}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button className="kipper_button" disableElevation onClick={e=>Statuschange()}>
                                        Guardar
                                    </Button> 
                                </Box>
                            </Grid>
                        </Grid>
                }
            </Grid>
            </ExpansionPanelDetails>

        </ExpansionPanel>
    )
}
