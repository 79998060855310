// import  './../../Assets/Styles/Responsive.css'
import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';

function SchoolSubjects () {
    const { currentUser, userType, info, infoSchool }   = useContext(AuthContext);
    
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/group/:id');
    const subjectsID = match.params.id;
    
    const DbSchool = firebase.database().ref('schools/'+currentUser+'/school_subjects');
    const DbTeacher = firebase.database().ref(`schools/${infoSchool?.school}/groups/${subjectsID}/school_subjects`);

    const [ isShow, setIsShow ]         = useState( false );
    const [ tutorError, setTutorError ] = useState('');
    const [ list, setList ] = useState([])

    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect(() => {getInformation()},[infoSchool]);

    async function getInformation(){
        var listItem = [];
        if(userType == "school")
        {
            let data = await DbSchool.once('value')
            let valor = data.val()
            Object.entries(valor).forEach(item =>{
                if(valor){
                    Object.entries(data).map( item => {
                        listItem.push(
                            <item
                            key={ item[0] }
                            name={ item[1].nombre}
                            info={ `${item[1].turn},${item[1].grade} ${item[1].group}` }
                            link={ `/teacher/materias/asistencias/${subjectsID}/${item[0]}` }
                            details={ `/teacher/materias/tareas/${subjectsID}/${item[0]}` }
                            time={  `${item[1].schedule.day} ${item[1].schedule.time}`}
                            />
                        )
                    })
                    setList( listItem );
                }
            })
            if (  valor == [] ) {
                setList( false );
            }
            if (  valor == null ) {
                setList( false );
            }
        }
        else if(userType == "teacher")
        {
            let data = await DbTeacher.once('value')
            if(data.val() != null)
            {
                let valor = Object.entries(data.val())
                for (const item of valor)
                {
                    let itemInfo = item[1]
                    if(item[1].teacher_key === currentUser){
                        var subjectInfo = await firebase.database().ref(`school_subjects/${item[0]}`).once('value')
                        if(subjectInfo.val())
                        {
                            listItem.push(
                                <item
                                key={ item[0] }
                                name={ subjectInfo.val().title }
                                info={ subjectInfo.val().grade }
                                link={ `/teacher/materias/asistencias/${subjectsID}/${item[0]}` }
                                details={ `/teacher/materias/tareas/${subjectsID}/${item[0]}` }
                                time={``}
                                />
                            )
                        }
                    }
                }
                setList( listItem );
                if (  valor == [] ) {
                    setList( false );
                }
                if (  valor == null ) {
                    setList( false );

                }
            }else{
                setList(false)
            }
        }
    }

    return(
        <Grid container className="padres_grid materiasB21">
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isShow ?
                            <ModalShow
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader
                        title="Materias"
                        text="Modifica materias y agrega tareas para tus alumnos."
                        btn_back="/teacher/materias/select/"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    <ShowContracts
                        list={ list  }
                        isSubjects={ true }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default SchoolSubjects