import React from 'react';
import PropTypes from 'prop-types';
//img
import arrow from './../../Assets/Img/General/arrow.png';
import circle_arrow from './../../Assets/Img/General/Icon_arrow-circle.png';
import user from './../../Assets/Img/General/user_example.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const EsperaItem = ( { tutor_name, tutor_email, alumno_name, alumno_grado, entrega_hora, entrega_status } ) => {
    return(
        <Box py={1}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <div> 
                    <img src={ arrow } alt=""/>
                </div>
                <div> 
                    <img src={ user } alt="" className="entrega_img"/> 
                </div>
                <div> 
                    <span className="entrega_name"> Juan Prado </span> <br/> 
                    <span className="entrega_subtitle"> example@gmail.com </span>
                </div>
                <div> 
                    <img src={ circle_arrow } alt=""/> 
                </div>
                <div> 
                    <img src={ user } alt="" className="entrega_img"/> 
                </div>
                <div> 
                    <span className="entrega_name"> Mariana Prado </span> <br/> 
                    <span className="entrega_subtitle"> Primero A </span>
                </div>
                <div className="center"> 
                    <span className="entrega_time"> 01:30 </span> <br/> 
                    <span className="entrega_status">POR ENTREGAR</span> 
                </div>
                <div > 
                    <Button variant="contained" className="kipper_button" disableElevation>
                        Escanear QR
                    </Button> 
                </div>
            </Grid>
        </Box>
    )
}

EsperaItem.propTypes = {
    lugar: PropTypes.string,
    tutor_name:    PropTypes.string.isRequired,
    tutor_email:  PropTypes.string.isRequired,
    alumno_name:   PropTypes.string.isRequired,
    alumno_grado:   PropTypes.string,
    entrega_hora:   PropTypes.string.isRequired,
    entrega_status: PropTypes.string.isRequired,
}

export default EsperaItem
    