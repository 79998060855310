import React, { useState, useEffect, useContext} from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
//Component
import ContactItem from './../../Components/Global/ContactItem';
import Loader from './../../Components/Global/Loader';
import ModalShow from '../../Components/Global/Modal';
//img
//materialui
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '@material-ui/core/Modal';
// firebases
import * as firebase from 'firebase';

function ShowContracts({ error, list, details, noDetails, onSelectSubmit, onRemoveSelected, onDeleted, isChat, isSubjects, isSchoolSubjects, isGroups, isEvaluation}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const { currentUser }         = useContext(AuthContext);

    const [ finalList, setFinalList ]   = useState([]);
    // const [ tmpList, setTmpList ]       = useState( list );
    const [ isSearch, setIsSearch ]     = useState( false );
    const [ search, setSearch ]         = useState('');
    const [ isSelected, setIsSelected ] = useState( false );
    const [ selected, setSelected ]     = useState([]);
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ deleteGroupModal, setDeleteGroupModal ] = useState( false );
    const [ confirmDeleteGroupModal, setConfirmDeleteGroupModal] = useState(false)
    const [ groupToDelete, setGroupToDelete ] = useState( '' );


    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 

    useEffect(() => {
        getContacts( list )

    }
    ,[]);

    const onSelect = ( e, id ) => {
        e.preventDefault();
        setIsSelected( true );
        const newSelected = list.filter( item => {
            return item.key.toLowerCase().includes( id.toLowerCase() )
        })
        onSelectSubmit( id )
        setSelected(newSelected);
    }

    const onRemove = e => {
        e.preventDefault();
        setSelected( "" );
        setIsSelected( false );
        onRemoveSelected();
    }

    const getContacts = ( list ) => {     
        setFinalList(list)
    }

    const handleSearchLive = e =>{
        //alert(e)

        if(e === ''){
            setFinalList(list)
        }else{
            if( isGroups ){

                if(search === ''){
                    setIsSearch( true );
                    setFinalList('');
                    setSearch('');
                }else{
                    setIsSearch( true )
                    const newList = list.filter( item => { 
                        return `${item.props.level} ${item.props.grade} ${item.props.group} ${item.props.info}`.toLowerCase().includes( e )
                    })
                    setFinalList(newList);
                    
                }
 
            }
            else{

                if(search === ''){
                    setIsSearch( true );
                    setFinalList(list)
                    setSearch('')

                }else{
                    setIsSearch( true )
                    const newList = list.filter( item => { 
                        return `${item.props.name} ${item.props.info}`.toLowerCase().includes( e )
                    })
                    setFinalList(newList);

                }
            }
        }
    }

    const handlesubmit = e => {
        e.preventDefault();

        if ( search === '') {
            setIsSearch( true );
            setFinalList(list)
        }
        else {
            if( isGroups ){

                if(search === ''){
                    setIsSearch( true );
                    setFinalList(list);
                    setSearch('');
                }else{
                    setIsSearch( true )
                    const newList = list.filter( item => { 
                        return `${item.props.grade} ${item.props.group} ${item.props.info}`.toLowerCase().includes( e )
                    })
                    setFinalList(newList);
                    
                }
 
            }
            else{

                if(search === ''){
                    setIsSearch( true );
                    setFinalList(list)
                    setSearch('')

                }else{
                    setIsSearch( true )
                    const newList = list.filter( item => { 
                        return `${item.props.name} ${item.props.info}`.toLowerCase().includes( e )
                    })
                    setFinalList(newList);

                }
            }
        }
    }

    const onRemoveGroup=async()=>{
        // alert('aqui eliminas el grupo: '+groupToDelete);
        // alert('currentUser: ' + currentUser);

        const statusDeleteFromSchool = await validateRemoveFromSchool()
        const statusDeleteFromTeachers = await validateRemoveFromTeacher()
        if(statusDeleteFromSchool && statusDeleteFromTeachers){
            setDeleteGroupModal(false)
            setConfirmDeleteGroupModal(true)
            //alert(currentUser)
        }
    }
    
    const validateRemoveFromSchool = () => {
        
        const db = firebase.database();
        const ref = db.ref(`schools/${currentUser}/groups/${groupToDelete}`)


        return new Promise(resolve =>{
            ref.remove().then(function() {
                resolve(true)
            })
            .catch(function(error) {
                resolve(false)
            });
        })

    }

    const validateRemoveFromTeacher = () => {
        
        const db = firebase.database();
        const ref = db.ref(`teacher`)


        return new Promise(resolve =>{
            ref.once('value').then(function(snapshot) {
                snapshot.forEach(item=>{

                    if(item.val().groups != undefined){
                        Object.values(item.val().groups).forEach(groupFound=>{

                            if(groupFound.group_key === groupToDelete){
                                var newRef = db.ref(`teacher/${item.val().teacher_key}/groups/${groupToDelete}`)
                                newRef.remove().then(function() {
                                    //resolve(true)
                                })
                                .catch(function(error) {
                                    alert('hubo un error al hacer la eliminacion')
                                });

                                //console.log('teacher: '+item.val().name+' '+item.val().lastname+', ID: '+item.val().teacher_key)
                            }
                            
                        })
                    }
                    
                })
            resolve(true)
            })
            .catch(function(error) {
                //resolve(false)
            });
        })

    }


    /*const validateHorarioMaestro = async(val) =>{
        let db = firebase.database();
        let status = true
        let datoTeacher = db.ref(`teacher/${val.maestro}/groups`);

        await datoTeacher.once('value').then(function(snapshot){
            

            if(snapshot.exists()){
                
                snapshot.forEach(item => {
                    let days = item.val().school_subjects?.days
                    let sub = item.val().school_subjects
                    let det = item.val().details
    
                    if(item.val().school_subjects.days.monday){
                        //console.log('entro aqui Maestro')
                        var horaInicioLunesBD = moment(item.val().school_subjects.days.horaInicioLunes, 'hh:mm');
                        var horaFinalLunesBD = moment(item.val().school_subjects.days.horaFinalLunes, 'hh:mm');

                        var horaInicioLunesLocal = moment(classDay.horaInicioLunes, 'hh:mm')
                        var horaFinalLunesLocal = moment(classDay.horaFinalLunes, 'hh:mm')
                        
                        //EXPLICACION
                        // condicion 1: verifica si la hora de inicio capturada esta entre alguna de las horas de inicio y de final en la bd del maestro
                        // condicion 2: verifica si la hora de inicio capturada sea igual a la hora de inicio de la base de datos
                        // condicion 3: lo mismo que la condicion 1 pero ahora con la hora final capturada
                        // condicion 4: lo mismo que la condicion 2 pero ahora con la hora final capturada
                        // condicion 5 verifica que la hora de inicio de la bd este entre la hora de inicio y de final capturadas
                        // si alguna de esas condiciones se cumplen muestra un aviso explicando que el horario capturado interviene con algun otro horario del profesor
                        
                        if(    horaInicioLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaInicioLunesLocal.isSame(horaInicioLunesBD)
                            || horaFinalLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaFinalLunesLocal.isSame(horaFinalLunesBD) 
                            || horaInicioLunesBD.isBetween(horaInicioLunesLocal, horaFinalLunesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los lunes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioLunes} a ${item.val().school_subjects.days.horaFinalLunes}`)
                                    status = false
                        }

                        
                    }
                    if(item.val().school_subjects.days.tursday){
                        var horaInicioMartesBD = moment(item.val().school_subjects.days.horaInicioMartes, 'hh:mm');
                        var horaFinalMartesBD = moment(item.val().school_subjects.days.horaFinalMartes, 'hh:mm');

                        var horaInicioMartesLocal = moment(classDay.horaInicioMartes, 'hh:mm')
                        var horaFinalMartesLocal = moment(classDay.horaFinalMartes, 'hh:mm')
                        
                        if(    horaInicioMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaInicioMartesLocal.isSame(horaInicioMartesBD)
                            || horaFinalMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaFinalMartesLocal.isSame(horaFinalMartesBD) 
                            || horaInicioMartesBD.isBetween(horaInicioMartesLocal, horaFinalMartesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los martes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioMartes} a ${item.val().school_subjects.days.horaFinalMartes}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.wensday){
                        var horaInicioMiercolesBD = moment(item.val().school_subjects.days.horaInicioMiercoles, 'hh:mm');
                        var horaFinalMiercolesBD = moment(item.val().school_subjects.days.horaFinalMiercoles, 'hh:mm');

                        var horaInicioMiercolesLocal = moment(classDay.horaInicioMiercoles, 'hh:mm')
                        var horaFinalMiercolesLocal = moment(classDay.horaFinalMiercoles, 'hh:mm')
                        
                        if(    horaInicioMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaInicioMiercolesLocal.isSame(horaInicioMiercolesBD)
                            || horaFinalMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaFinalMiercolesLocal.isSame(horaFinalMiercolesBD) 
                            || horaInicioMiercolesBD.isBetween(horaInicioMiercolesLocal, horaFinalMiercolesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los miercoles en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioMiercoles} a ${item.val().school_subjects.days.horaFinalMiercoles}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.tuersday){
                        var horaInicioJuevesBD = moment(item.val().school_subjects.days.horaInicioJueves, 'hh:mm');
                        var horaFinalJuevesBD = moment(item.val().school_subjects.days.horaFinalJueves, 'hh:mm');

                        var horaInicioJuevesLocal = moment(classDay.horaInicioJueves, 'hh:mm')
                        var horaFinalJuevesLocal = moment(classDay.horaFinalJueves, 'hh:mm')
                        
                        if(    horaInicioJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaInicioJuevesLocal.isSame(horaInicioJuevesBD)
                            || horaFinalJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaFinalJuevesLocal.isSame(horaFinalJuevesBD) 
                            || horaInicioJuevesBD.isBetween(horaInicioJuevesLocal, horaFinalJuevesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los jueves en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioJueves} a ${item.val().school_subjects.days.horaFinalJueves}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.friday){
                        var horaInicioViernesBD = moment(item.val().school_subjects.days.horaInicioViernes, 'hh:mm');
                        var horaFinalViernesBD = moment(item.val().school_subjects.days.horaFinalViernes, 'hh:mm');

                        var horaInicioViernesLocal = moment(classDay.horaInicioViernes, 'hh:mm')
                        var horaFinalViernesLocal = moment(classDay.horaFinalViernes, 'hh:mm')
                        
                        if(    horaInicioViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaInicioViernesLocal.isSame(horaInicioViernesBD)
                            || horaFinalViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaFinalViernesLocal.isSame(horaFinalViernesBD) 
                            || horaInicioViernesBD.isBetween(horaInicioViernesLocal, horaFinalViernesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los viernes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioViernes} a ${item.val().school_subjects.days.horaFinalViernes}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.saturday){
                        var horaInicioSabadoBD = moment(item.val().school_subjects.days.horaInicioSabado, 'hh:mm');
                        var horaFinalSabadoBD = moment(item.val().school_subjects.days.horaFinalSabado, 'hh:mm');

                        var horaInicioSabadoLocal = moment(classDay.horaInicioSabado, 'hh:mm')
                        var horaFinalSabadoLocal = moment(classDay.horaFinalSabado, 'hh:mm')
                        
                        if(    horaInicioSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaInicioSabadoLocal.isSame(horaInicioSabadoBD)
                            || horaFinalSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaFinalSabadoLocal.isSame(horaFinalSabadoBD) 
                            || horaInicioSabadoBD.isBetween(horaInicioSabadoLocal, horaFinalSabadoLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los sabado en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioSabado} a ${item.val().school_subjects.days.horaFinalSabado}`)
                                    status = false
                        }
                    }
                })
            }else{
                status = true
                //alert('esta llegando')
            }

        })
        return status
    }*/


    const showModalRemoveGroup = (id) =>{
        setModalStaus(true)
        setDeleteGroupModal(true)
        setGroupToDelete(id)
    }







    if ( Object.entries(list).length === 0 && list != false ) {
        return( 
            <Paper elevation={3} >
                <Box className="" p={5} >
                    <Loader withImg={ true } />
                    <Loader withImg={ true } />
                </Box>
            </Paper>
        )
    }
    
    else{
        return(
            <Paper elevation={3} >
                <Box p={matches ?5 :2} >
                    <Grid container >
                    <Modal
                        open={modalStaus}
                        onClose={ModalClose}
                    >
                        <Grid className="modal__Posotion modal__Global"> 
                        { deleteGroupModal ? (
                                                <ModalShow
                                                    title=" ¿Estás seguro de eliminar este grupo? "
                                                    confirmDeleteGroup={ true }
                                                    Delete={ ()=>onRemoveGroup() }
                                                /> 
                                            ):null
                        }

                        { confirmDeleteGroupModal ? (
                                                <ModalShow
                                                    title=" Grupo eliminado con exito "
                                                    onlyShow={ true }
                                                /> 
                                            ):null
                        }

                            

                        </Grid>
                    </Modal>

                        <Grid item xs={12}>
                            {/* <form onSubmit={ e => handlesubmit(e) }> */}
                                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <Typography> <b> Escribe un nombre: </b> </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField id="Search" variant="outlined" size="small" fullWidth value={ search } onChange={ e => {handleSearchLive(e.target.value.toLocaleLowerCase()); setSearch(e.target.value) } } 
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}/>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box py={1}>
                                            <Button className="kipper_button" variant="outlined" fullWidth type="submit" >Buscar</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            {/* </form> */}
                        </Grid>
                        <Grid item xs={12} className="List_showContracts">
                            <Box py={1} mt={4} >
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={matches ?2 :0}>
                                    <Grid item xs={12} className="box_contact-content" style={{minHeight:'60vh'}} >
                                    {
                                        isSelected &&
                                            <Box>
                                                <div className="list_divider">
                                                    <b>Seleccionado</b>
                                                </div>
                                                {
                                                    selected.map( i => 
                                                        <ContactItem 
                                                            error={error} 
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name } 
                                                            info={ i.props.info } 
                                                            btnRemove={ true }
                                                            onRemove= { onRemove }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            onlyOneButton={i.props.onlyOneButton}
                                                        />
                                                    )
                                                }
                                            </Box> 
                                    }
                                    <div className="list_divider">
                                        <b></b>
                                    </div>
                                    {
                                        isChat ?
                                            list.map( i => 
                                                <ContactItem
                                                    error={error}  
                                                    key={ i.key }
                                                    id={ i.key }
                                                    img={ i.props.img } 
                                                    name={ i.props.name } 
                                                    info={ i.props.info } 
                                                    btnTo={ i.props.link } 
                                                    isChat={ true }
                                                    btnFirstButton={i.props.btnFirstButton}
                                                    onlyOneButton={i.props.onlyOneButton}
                                                />
                                            )
                                        : null
                                    }
                                    {
                                        details ?
                                            // Only show contacts with details
                                            isSearch ?
                                                finalList == ''?(<Box className="ShowContracts__notFound">No hay coincidencias</Box>):(
                                                    finalList.map( i => 
                                                        <ContactItem
                                                            error={error}  
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name } 
                                                            info={ i.props.info } 
                                                            btnTo={ i.props.link } 
                                                            onDelete={ onDeleted }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            onlyOneButton={i.props.onlyOneButton}
                                                        />
                                                    )  
                                                ) 

                                            :
                                            list.map( i => 
                                                <ContactItem  
                                                    key={ i.key }
                                                    id={ i.key }
                                                    img={ i.props.img } 
                                                    name={ i.props.name }
                                                    info={ i.props.info }
                                                    btnTo={ i.props.link }
                                                    onDelete={ onDeleted }
                                                    btnFirstButton={i.props.btnFirstButton}
                                                    onlyOneButton={i.props.onlyOneButton}
                                                />
                                            ) 
                                        : null
                                    }
                                    {
                                        noDetails ?
                                            // Only show Tutors with no details
                                            
                                                    isSearch ?
                                                        finalList.map( i => 
                                                            <ContactItem
                                                                error={error}  
                                                                key={ i.key }
                                                                id={ i.key }
                                                                img={ i.props.img } 
                                                                name={ i.props.name } 
                                                                info={ i.props.info } 
                                                                btnSelect={true}
                                                                onSelect={ onSelect }
                                                                onDelete={ onDeleted }
                                                                btnFirstButton={i.props.btnFirstButton}
                                                                onlyOneButton={i.props.onlyOneButton}
                                                            />
                                                        ) :
                                                        list.map( i => 
                                                            <ContactItem
                                                                error={error}  
                                                                key={ i.key }
                                                                id={ i.key }
                                                                img={ i.props.img } 
                                                                name={ i.props.name } 
                                                                info={ i.props.info } 
                                                                btnSelect={true}
                                                                onSelect={ onSelect }
                                                                btnFirstButton={i.props.btnFirstButton}
                                                                onlyOneButton={i.props.onlyOneButton}
                                                                onDelete={ onDeleted }
                                                            />
                                                        )  
                                        : null
                                    }
                                    {
                                        isSubjects ?
                                            isSearch ? 
                                                finalList == ''?(<Box className="ShowContracts__notFound">No hay coincidencias</Box>):(
                                                    finalList.map( i => 
                                                        <ContactItem  
                                                            error={error}
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name }
                                                            time={ i.props.time }
                                                            info={ i.props.info } 
                                                            btnTo={ i.props.link }
                                                            btn2To={i.props.link }
                                                            btnDetails={ i.props.details }
                                                            isSubjects={ true }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            onlyOneButton={i.props.onlyOneButton}
                                                            SubjectsHomework={ true }
                                                        />
                                                    ) 
                                                )

                                            :
                                            [
                                                list != false ?
                                                    list.map( i => 
                                                        <ContactItem
                                                            error={error}  
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name }
                                                            time={ i.props.time }
                                                            info={ i.props.info } 
                                                            btnTo={ i.props.link }
                                                            btn2To={i.props.link }
                                                            btnDetails={ i.props.details }
                                                            isSubjects={ true }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            onlyOneButton={i.props.onlyOneButton}
                                                            SubjectsHomework={ true }
                                                        /> 
                                                    )
                                                :
                                                <Grid container direction="row" justify="center" alignItems="center">
                                                    <Typography variant="h6" className="kipper_title" >No hay materias registradas para este grupo</Typography>
                                                </Grid>                                                
                                            ]
                                        : null
                                    }
                                    {
                                        isSchoolSubjects ?
                                            isSearch ?
                                                finalList == ''?(<Box className="ShowContracts__notFound">No hay coincidencias</Box>):(
                                                    finalList.map( i => 
                                                        <ContactItem
                                                            error={error}  
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name }
                                                            info={ i.props.info } 
                                                            btnDetails={ i.props.btn_to }
                                                            isSchollSubjects={ true }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            onDelete={ onDeleted }
                                                        />
                                                    ) 
                                                ) 

                                            :
                                                list.map( i => 
                                                    <ContactItem
                                                        error={error}  
                                                        key={ i.key }
                                                        id={ i.key }
                                                        img={ i.props.img } 
                                                        name={ i.props.name }
                                                        info={ i.props.info } 
                                                        btnDetails={ i.props.btn_to }
                                                        isSchollSubjects={ true }
                                                        btnFirstButton={i.props.btnFirstButton}
                                                        onDelete={ onDeleted }
                                                    />
                                                )
                                        : null
                                    }
                                    {
                                        isGroups ?
                                            isSearch ? 
                                                finalList == ''?(<Box className="ShowContracts__notFound">No hay coincidencias</Box>):(
                                                    finalList.map( i => 
                                                        <ContactItem 
                                                            error={error} 
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ ` ${i.props.grade} ${i.props.group} ` }
                                                            info={ ` ${i.props.info} ` }
                                                            level={ ` ${i.props.level} ` }
                                                            btnDetails={ i.props.btn_to }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            isGroups={ true }
                                                            deleteGroup={()=> showModalRemoveGroup(i.key)}
                                                        />
                                                    )  
                                                )

                                            :
                                                list.map( i => 
                                                    <ContactItem
                                                        error={error}  
                                                        key={ i.key }
                                                        id={ i.key }
                                                        img={ i.props.img } 
                                                        name={ ` ${i.props.grade} ${i.props.group} ` }
                                                        level={ ` ${i.props.level} ` }
                                                        sortKey={i.sortKey}
                                                        info={ ` ${i.props.info}` }
                                                        btnDetails={ i.props.btn_to }
                                                        btnFirstButton={i.props.btnFirstButton}
                                                        isGroups={ true }
                                                        deleteGroup={()=>showModalRemoveGroup(i.key)}
                                                    />
                                                )
                                        : null
                                    }
                                    {
                                        isEvaluation ?
                                            isSearch?
                                                finalList == ''?(<Box className="ShowContracts__notFound">No hay coincidencias</Box>):(
                                                    finalList.map( i => 
                                                        <ContactItem 
                                                            error={error}  
                                                            key={ i.key }
                                                            id={ i.key }
                                                            img={ i.props.img } 
                                                            name={ i.props.name }
                                                            info={ i.props.info } 
                                                            btnDetails={ i.props.details }
                                                            btnFirstButton={i.props.btnFirstButton}
                                                            isEvaluation={ true }
                                                        />
                                                    )  
                                                )
                                            :
                                                list.map( i => 
                                                    <ContactItem
                                                        error={error}  
                                                        key={ i.key }
                                                        id={ i.key }
                                                        img={ i.props.img } 
                                                        name={ i.props.name }
                                                        info={ i.props.info } 
                                                        btnDetails={ i.props.details }
                                                        btnFirstButton={i.props.btnFirstButton}
                                                        onlyOneButton={i.props.onlyOneButton}
                                                        isEvaluation={ true }
                                                />
                                                )
                                        : null
                                    }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )
    }

}

export default ShowContracts
