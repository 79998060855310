import React, { Component, useContext, useEffect, useState } from 'react'
import icon_alert from './../../Assets/Img/General/Icon_danger.png'
import icon_salir from './../../Assets/Img/General/Icon_out.png'
//Components
import PageHeader from './../../Components/Global/PageHeader';
//MaterialUI
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Box, Typography, ExpansionPanel, ExpansionPanelSummary,ExpansionPanelDetails } from '@material-ui/core';

import { AuthContext } from './../../Components/functions/Auth';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';
import {questions} from './data/questions';
import FaqContent from './FaqContent';

function Faq () {
    
    const [isCheked, setIsCheked] = useState(false)
    const data = questions;

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            {/* <NavTutorPhone /> */}
            <Grid xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Preguntas Frecuentes"
                        text="Aqui encontrarás las preguntas mas frecuentes hechas a la escuela"
                    />
                </Box>
            </Grid>
            <Grid xs={12}>
                <Box className="kipperBox_content_Faqs" boxShadow={3} py={3}>
                        {
                            data.map(res =>( 
                                <Box >     
                                    <FaqContent question={res.question} answer={res.answer} />
                                </Box>
                            ))
                        }
                </Box>
            </Grid>
        </Grid>
    )
}

export default Faq