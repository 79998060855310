import React from 'react';
import {Grid,ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails, Box} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const FaqContent =({question,answer})=>{
    return(
        <ExpansionPanel className="FaqContent__General" >
            <ExpansionPanelSummary>
                    <Grid xs={11} >
                        <span className="FaqContent__question">{question}</span>
                    </Grid>
                    <Grid xs={1} >
                        <ExpandMoreIcon id="iconArrowFaqs"  />
                    </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid>
                    <span className="FaqContent__answer">{answer}</span>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

export default FaqContent;