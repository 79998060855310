// import  './../../Assets/Styles/Responsive.css'
import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
//MaterialUI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

function Chat () {
    const { currentUser, info }   = useContext(AuthContext);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const history = useHistory();
    const fire = firebase.database()
    const [ name, setName ]         = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ phone, setPhone ]       = useState('');
    const [ address, setAddress ]   = useState('');
    const [ zip, setZip ]           = useState('');
    const [ country, setCountry ]   = useState('');
    const [ city, setCity ]         = useState('');
    const [ grado, setGrado ]       = useState('');
    const [ grupo, setGrupo ]       = useState('');
    const [ turno, setTurno ]       = useState('');
    const [avatar,setAvatar]        = useState('');
    //Modal
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    const [ currentUs, setCurrentUs ] = useState([])
    const [ currentUsContact, setCurrentUsContact ] = useState([])


    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const ConfirmOperation = ( e ) =>{
        e.preventDefault();
        if ( isConfirm ) {
            setIsConfirm( true );
            setIsShow( false );
            ModalOpen();
        }
        else{
            setIsShow( true );
            setIsConfirm( false );
            setModalmsg( "No se ha seleccionado ningun contacto" );
            ModalOpen();
        }
    }
    useEffect(()=>{
        fire.ref().child(`teacher/${currentUser}/`).on('value', snap => {
            if(snap.val()){
                let infosnap = snap.val()
                console.log(infosnap)
                setCurrentUsContact(infosnap.contact)
                setCurrentUs(snap.val())
              /*  setPhone(infosnap.contact.phone)
                setAddress(infosnap.location.address)
                setCity(infosnap.location.city)
                setZip(infosnap.location.zip_code)
                setCountry(infosnap.location.country)*/
                setTurno(infosnap.turno)
                setGrado(infosnap.grado)
                setGrupo(infosnap.grupo)
                setName(infosnap.name);
                setAvatar(infosnap.avatar);
                // console.log('setCurrentUs ', snap.val());
            }
            // console.log('info teach ',snap.val());
        })
    },[])
    return(
        <Grid container className="padres_grid teacherProfile39">
            {/* <NavTeacherPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isConfirm ?
                            <ModalShow
                            title="Estás seguro de agerar como padre de familia? "
                            confirmOperation={ true }
                            Operation={ ConfirmOperation }
                            /> : null
                    }
                    {
                        isShow ?
                            <ModalShow
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3}  my={matches ?2 :1} className="PageHeader_toRight">
                    <PageHeader
                        title="Informacion de perfil"
                        text="Datos referentes a tu cuenta"
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid item xs={12} md={4} align="center">
                            <Box p={2}>
                                <img src={avatar} alt={name} className="profileAvatar"/>
                            </Box>
                            <Box p={2} >
                                <Typography variant="h5" className="kipper_txt-color">
                                    Direccion y datos de perfil
                                </Typography>
                                <Typography variant="body1">
                                    Estos son los datos de tu cuenta, esta información se muestra a los padres de familia para que puedan contactarte
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box px={2} py={1}>
                                        <label htmlFor="name">Nombre</label>
                                        <TextField id="name" size="small" fullWidth value={ currentUs.name } onChange={e => setName(e.target.value) } disabled />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box px={2} py={1}>
                                        <label htmlFor="lastname">Apellido</label>
                                        <TextField id="lastname" size="small" fullWidth value={ currentUs.lastname } onChange={e => setLastName(e.target.value) } disabled />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box px={2} py={1}>
                                        <label htmlFor="lastname">Correo Electronico</label>
                                        <TextField id="email" size="small" fullWidth value={ currentUsContact.email } onChange={e => setLastName(e.target.value) } disabled />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box px={2} py={1}>
                                        <label htmlFor="lastname">Telefono</label>
                                        <TextField id="email" size="small" fullWidth value={ currentUsContact.phone } onChange={e => setLastName(e.target.value) } disabled />
                                    </Box>
                                </Grid>
                                {/* <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                    <label htmlFor="turno">Turno</label>
                                    <Select labelId="turno" displayEmpty variant="outlined" className="" fullWidth value={ currentUs.turno } onChange={e => setTurno(e.target.value) }>
                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                        <MenuItem value="Matutino">Matutino</MenuItem>
                                        <MenuItem value="Vespertino">Vespertino</MenuItem>
                                    </Select>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                    <label htmlFor="grado">Grado</label>
                                    <Select labelId="grado" displayEmpty variant="outlined" className="" fullWidth value={ grado } onChange={e => setGrado(e.target.value) }>
                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                        <MenuItem value="Primero">Primero</MenuItem>
                                        <MenuItem value="Segundo">Segundo</MenuItem>
                                        <MenuItem value="Tercero">Tercero</MenuItem>
                                        <MenuItem value="Cuarto">Cuarto</MenuItem>
                                        <MenuItem value="Quinto">Quinto</MenuItem>
                                        <MenuItem value="Sexto">Sexto</MenuItem>
                                    </Select>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                    <label htmlFor="grupo">Grupo</label>
                                    <Select labelId="group" displayEmpty variant="outlined" className="" fullWidth value={ grupo } onChange={e => setGrupo(e.target.value) }>
                                      <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                        <MenuItem value="A">A</MenuItem>
                                        <MenuItem value="B">B</MenuItem>
                                        <MenuItem value="C">C</MenuItem>
                                        <MenuItem value="D">D</MenuItem>
                                        <MenuItem value="E">E</MenuItem>
                                        <MenuItem value="F">F</MenuItem>
                                    </Select>
                                    </Box>
                                </Grid> */}
                                {/*
                                  <Grid item sm={12} >
                                      <Box px={2} py={1}>
                                          <label htmlFor="phone">Número Telefónico</label>
                                          <TextField id="phone" variant="outlined" size="small" disabled fullWidth value={ phone } onChange={e => setPhone(e.target.value) } />
                                      </Box>
                                  </Grid>
                                  <Grid item sm={12}>
                                      <Box px={2} py={1}>
                                          <label htmlFor="address">Direccion</label>
                                          <TextField id="address" variant="outlined" size="small" disabled fullWidth value={ address } onChange={e => setAddress(e.target.value) } />
                                      </Box>
                                  </Grid>
                                  <Grid item sm={12} md={6}>
                                      <Box px={2} py={1}>
                                          <label htmlFor="city">Ciudad</label>
                                          <TextField id="city" variant="outlined" size="small" disabled fullWidth value={ city } onChange={e => setCity(e.target.value) } />
                                      </Box>
                                  </Grid>
                                  <Grid item sm={12} md={6}>
                                      <Box px={2} py={1}>
                                          <label htmlFor="ZIP">Codigo Postal</label>
                                          <TextField id="ZIP" variant="outlined" size="small" disabled fullWidth value={ zip } onChange={e => setZip(e.target.value) } />
                                      </Box>
                                  </Grid>
                                  <Grid item sm={12}>
                                      <Box px={2} py={1}>
                                          <label htmlFor="country">Pais</label>
                                          <TextField id="country" variant="outlined" size="small" disabled fullWidth value={ country } onChange={e => setCountry(e.target.value) } />
                                      </Box>
                                  </Grid>


                                   <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                        <InputLabel shrink id="turno">
                                            TURNO
                                        </InputLabel>
                                        <Select labelId="turno" displayEmpty variant="outlined" className="" fullWidth value={ turno } onChange={e => setTurno(e.target.value) }>
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="Matutino">Matutino</MenuItem>
                                            <MenuItem value="Vespertino">Vespertino</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                        <InputLabel shrink id="grado">
                                            GRADO
                                        </InputLabel>
                                        <Select labelId="grado" displayEmpty variant="outlined" className="" fullWidth value={ grado } onChange={e => setGrado(e.target.value) }>
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="Primero">Primero</MenuItem>
                                            <MenuItem value="Segundo">Segundo</MenuItem>
                                            <MenuItem value="Tercero">Tercero</MenuItem>
                                            <MenuItem value="Cuarto">Cuarto</MenuItem>
                                            <MenuItem value="Quinto">Quinto</MenuItem>
                                            <MenuItem value="Sexto">Sexto</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Box px={2} py={1}>
                                        <InputLabel shrink id="group">
                                            GRUPO
                                        </InputLabel>
                                        <Select labelId="group" displayEmpty variant="outlined" className="" fullWidth value={ grupo } onChange={e => setGrupo(e.target.value) }>
                                            <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                            <MenuItem value="A">A</MenuItem>
                                            <MenuItem value="B">B</MenuItem>
                                            <MenuItem value="C">C</MenuItem>
                                            <MenuItem value="D">D</MenuItem>
                                            <MenuItem value="E">E</MenuItem>
                                            <MenuItem value="F">F</MenuItem>
                                        </Select>
                                    </Box>
                                </Grid> */}

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Chat
