import React, { useState, useContext, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
//Components
import SchoolInformation from '../Components/Global/SchoolInformation';
import { school, teacher } from '../Constants/cards'
import Calendar from './../Components/Global/Calendar'
// meterialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SchoolIcon from '@material-ui/icons/School';
import Hidden from '@material-ui/core/Hidden';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// IMG
import user from './../Assets/Img/General/placeholder_boy.png';
import logo_school from './../Assets/Img/Home/Logo_School.png';
import box1_img from './../Assets/Img/Home/entrega_new.png';
import box2_img from './../Assets/Img/Home/lista_espera_new.png';
import box3_img from './../Assets/Img/General/portada_alumnos-home.png';
import box4_img from './../Assets/Img/General/portada_sede.png';
import icon_teacher from './../Assets/Img/Layout/Icon_Maestros.png';
//
import { AuthContext } from "../Components/functions/Auth";
import HomeTutor from './Tutores/HomeTutor'
import HomeChild from './Child/HomeChild'
import HomeTeacher from '../Pages/Maestros/HomeTeacher'
import HomeAdmin from '../Pages/Admin/HomeAdmin'

import * as firebase from 'firebase';
import { Typography } from '@material-ui/core';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

const box_info2 = [
    {
        "box_key": "1",
        "box_title": "Entregar",
        "box_text":  "Accede a la cámara para escanear códigos QR, descargar reportes o acceder al historial de movimientos.",
        "box_list":[
            'Iniciar la cámara permite leer los códigos QR.',
            'Escanear el código cambia el estatus a "entregado".',
            'Descargar reportes.',
            'Historial de movimientos.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/entregas",
        }
    },
    {
        "box_key": "2",
        "box_title": "Lista de espera",
        "box_text":  "Observa una lista de los padres de familia más próximos a la escuela para comenzar a acercar a los alumnos a la puerta.",
        "box_list":[
            'Conocer la posición de los padres de familia y el tiempo estimado de llegada.',
            'Saber la información del padre y el estado actualdel alumno.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/lista_espera",
        }
    },
    {
        "box_key": "3",
        "box_title": "Alumnos",
        "box_text":  "Agrega nuevos alumnos a la plataforma añadiendo a uno de sus papás y sus contactos de referencia.",
        "box_list":[
            'Agregar padres de familia.',
            'Agregar contactos de referencia.',
            'Ver lista completa de alumnos.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/alumnos",
        }
    },
    {
        "box_key": "4",
        "box_title": "Sede",
        "box_text":  "Ingresa o actualiza la información correspondiente a la escuela.",
        "box_list":[
            'Agrega o modifica el logo y nombre de la escuela.',
            'Agrega o modifica la dirección de la escuela.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/sede",
        }
    }
]

export function Header(){
    const {  info, userType, currentUser, infoSchool, handlenotif } = useContext(AuthContext);
    const [ nombre, setNombre ]     = useState('');
    const [ address, setAddress ]   = useState('');
    const [ phone, setPhone ]       = useState('');
    const [ logo, setLogo ]         = useState('');

    useEffect(() =>  handleload(),[infoSchool]);

    const handleload = () => {
        let db = firebase.database();
        var Schoolref;
        if(userType === "school"){
            Schoolref = db.ref(`schools/${currentUser}`);
            Schoolref.once('value').then(function(snapshot) {
                if(snapshot.val()){
                    setNombre(snapshot.val().name);
                    setPhone(snapshot.val().contact.phone);
                    setAddress(snapshot.val().location.address);
                    setLogo(snapshot.val().avatar)
                }
            });
        }else if (userType === "teacher"){
            try{
                if(infoSchool){
                    Schoolref = db.ref(`schools/${infoSchool.school}`);
                    Schoolref.once('value').then(function(snapshot) {
                        if(snapshot.val()){
                            setNombre(snapshot.val().name);
                            setPhone(snapshot.val().contact.phone);
                            setAddress(snapshot.val().location.address);
                            setLogo(snapshot.val().avatar)
                        }
                    });
                }
            }finally{
                if(infoSchool){
                    handlenotif(userType, infoSchool.school)
                }
            }
        }else if (userType === "tutor"){
            try{
                Schoolref = db.ref(`tutors/${currentUser}`);
                Schoolref.once('value').then(function(snapshot) {
                    if(snapshot.val()){
                        setNombre(snapshot.val().name);
                        setPhone(snapshot.val().contact.phone);
                        setAddress(snapshot.val().location.address);
                        setLogo(snapshot.val().avatar)
                    }
                });
            }
            catch (error){
                console.log(error)
            }
        }else if ( userType === "child"){
            try{
                Schoolref = db.ref(`schools/${infoSchool}`);
                Schoolref.once('value').then(function(snapshot) {
                    if(snapshot.val()){
                        setNombre(snapshot.val().name);
                        setPhone(snapshot.val().contact.phone);
                        setAddress(snapshot.val().location.address);
                        setLogo(snapshot.val().avatar)
                    }
                });
            }
            catch (error){
                console.log(error)
            }
        }
    }
    return <SchoolInformation logo={ logo } name={nombre} address={address} phone={phone} edit_link="/sede" />
}

const Home = () => {
    const { currentUser,userType, info, infoSchool, handlenotif }  = useContext(AuthContext);
    const [chilsInfo, setChilsInfo] = useState('cargando');
    const [teacherInfo, setTeacherInfo] = useState('cargando');

    useEffect(() => {

        
        if(userType === 'school'){
            handlenotif(userType, currentUser)
        }else{
            if(infoSchool){
                if(userType === 'tutor'){
                    handlenotif(userType, infoSchool.school, currentUser)
                }else if(userType === 'teacher'){
                    handlenotif(userType, infoSchool.school)
                }else if(userType === 'child'){
                    handlenotif(userType, infoSchool.school, currentUser)
                }
            }
        }
    },[]);
    
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`childs`);
        let listItem = [];
        let TutorsList = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user }
                                name={ `${itemInfo.name} ${itemInfo.last_name} ` }
                                info={ `${itemInfo.details.grade} ${ itemInfo.details.group }, Turno ${itemInfo.details.turn}` }
                                link={ `/alumnos/informacion/${item.key}` }
                                tutors={ TutorsList }
                            />
                        )
                    }
                })
                setChilsInfo( listItem );      
            }
            if (  snapshot.val() == [] ) {
                setChilsInfo( false );
            }
            if ( snapshot.val() == null){
                setChilsInfo( false );
            }
        });
    }, [chilsInfo] );

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`teacher`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user  }
                                name={ `${itemInfo.name} ${itemInfo.lastname} ` }
                                info={ `${itemInfo.details.grade} ${ itemInfo.details.group }, Turno ${itemInfo.details.turn}` }
                                link={ `/maestros/informacion/${item.key}` }
                            />
                        )
                    }
                })
                setTeacherInfo( listItem );      
            }
            if (  snapshot.val() == [] ) {
                setTeacherInfo( false );   
            }
            if (  snapshot.val() == null ) {
                setTeacherInfo( false );   
            }
        });
    }, [teacherInfo] );
    
    let box_info = school;
    
    if(userType === "teacher"){
        return (
            <Box mb={3}>
                <HomeTeacher/>
            </Box>
        )    }
    if(userType === "school"){
        box_info = school;
    }
    if(userType === "tutor"){
        return (
            <Box mb={3}>
                <HomeTutor/>
            </Box>
        )
    }
    if(userType === "child"){
        return (
            <Box mb={3}>
                <HomeChild/>
            </Box>
        )
    }
    if(userType === "Admin"){
        return (
            <Box mb={3}>
                <HomeAdmin/>
            </Box>
        )
    }
    return (
        <Box mb={3}>
            {/* <NavEscuelaPhone /> */}
            {/* <Box display="flex" justifyContent="center" mb={5} className="SchoolInformation__main">
                <Header/>
            </Box> */}
            <Grid container spacing={3}>
                <Grid item xs={6} >
                    <Box id="kiper_school_cardHome" boxShadow={2} m={2} p={3} className="bg-white">
                        <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey">
                            <Box id="kiper_school_cardHome_text"  pl={3}>
                                <Typography variant="h4" className="fw-500" > { chilsInfo !== 'cargando' ? chilsInfo.length : 'cargando...' } </Typography>
                                <Typography variant="h5" className="fw-200" > Alumnos </Typography>
                            </Box>
                            <Box>
                                <i className="fas fa-graduation-cap fa-5x kipper_txt-color opacity-05" />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} >
                    <Box id="kiper_school_cardHome" boxShadow={2} m={2} p={3} className="bg-white">
                        <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey">
                            <Box id="kiper_school_cardHome_text" pl={3}>
                                <Typography variant="h4" className="fw-500" >{ teacherInfo !== 'cargando' ? teacherInfo.length : 'cargando...' }</Typography>
                                <Typography variant="h5" className="fw-200" > Maestros </Typography>
                            </Box>
                            <Box>
                                <i className="fas fa-chalkboard-teacher fa-5x kipper_txt-color opacity-05" />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Box className="bg-white" p={3} boxShadow={2} m={2}>
                        <Calendar type="school" />
                    </Box>
                </Grid>


                {/* { box_info.map(card =>(
                    <Grid item xs={12} md={6} key={ card.box_key } >
                        <Box p={3}>
                            <BoxHome 
                                box_img={ card.box_img }
                                box_title={ card.box_title } 
                                box_text={ card.box_text } 
                                box_item={ card.box_list }    
                                box_btn_text={ card.box_button.btn_text }
                                box_btn_link={ card.box_button.btn_link }
                            />
                        </Box>
                    </Grid>
                )) } */}
            </Grid>
        </Box>
    );  
}

export default Home
