import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


import { Grid, Box, Typography, Divider } from '@material-ui/core';
import * as firebase from 'firebase';
import NavTutorPhone from '../../Pages/Tutores/NavTutorPhone/NavTutorPhone';

export default function Citas() {
    const history = useHistory();
    const match = useRouteMatch('/tutor/citas/alumno/:id/:student');
    const GroupID = match.params.id;
    const studentID = match.params.student;
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const [maestrosInfo, setMaestrosInfo] = useState('')


    useEffect(() => {
        if(infoSchool)
        {
            getInfoteacher()
        }
    }, [infoSchool])
    
    async function getInfoteacher()
    {
        let db = firebase.database();
        let ref = db.ref(`schools/${infoSchool.school}/groups/${GroupID}/teacher`);
        ref.once('value').then(async (snapshot) => {
            let ref2 = await firebase.database().ref(`teacher/${snapshot.val().teacher_key}`).once('value');
            setMaestrosInfo(ref2.val())
        });
    }

    return (
        <Grid container>
            {/* <NavTutorPhone /> */}
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title='Docente para cita'
                        text="Selecciona al docente al que deseas solicitar la cita" 
                        btn_back="/tutor/citas/alumno"
                    />
                </Box>
            </Grid>
            <Grid sm={12} xs={12} md={12} >
                <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                    {
                        maestrosInfo
                        ?<Link to={`/tutor/citas/${maestrosInfo.teacher_key}/${studentID}`} >
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid xs={10} md={10}>
                                    <Box id="kiper_tutor_cita_childItem" p={2} display="flex" mt={1} >
                                        <img src={ maestrosInfo.avatar ? maestrosInfo.avatar : user } alt="avatar" className="img_home" />
                                        <Box ml={2} style={{  alignSelf: "center"  }}>
                                            <Typography variant="h6" className="kipper_txt-grey" >
                                                { maestrosInfo.name } { maestrosInfo.lastname }
                                            </Typography>
                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={1} md={1} className="arrow_end">
                                    <Box>
                                        <ArrowForwardIosIcon/>
                                    </Box>
                                </Grid>
                            </Grid>
                            <hr/>
                        </Link>
                        :<Grid container xs={12} md={12} >
                            <Grid container py={3} direction="row" justify="space-between" alignItems="center">
                                <Box display="flex" p={2}>    
                                    <Typography variant="h6" className="kipper_txt-grey" >
                                        No hay maestro registrado para este alumno            
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    }
                    
                </Box>
            </Grid>
        </Grid>
    )
}
