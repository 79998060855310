import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import academy from './../../Assets/Img/General/Academy.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

export default function GroupsList({materias, evaluaciones, citas}) {
    const { currentUser, userType, info, infoSchool }   = useContext(AuthContext);
    const history = useHistory();
    
    const [ isShow, setIsShow ]         = useState( false );
    const [ tutorError, setTutorError ] = useState('');
    const [ list, setList ]             = useState([]);

    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect( () =>{
        if(infoSchool)
        {
            getGroups()
        }
    }, [infoSchool] )

    async function getGroups () 
    {
        let db = firebase.database();
        let listGroup = [];
        let groups = Object.entries(infoSchool.groups)
        for (const group of groups)
        {
            let groupInfo = await db.ref(`schools/${infoSchool.school}/groups/${group[0]}`).once('value')
            let checking = await checkSubjects(group[0])
            if(checking)
            {
                listGroup.push(
                    <item
                        key={ groupInfo.key }
                        img={ academy }
                        grade={ `${groupInfo.val().grade}` }
                        group={ `${groupInfo.val().group}` }
                        level={ `${groupInfo.val().level}` }
                        info={ `${groupInfo.val().turn}` }
                        btn_to={ `/teacher/evaluaciones/group/${groupInfo.key}` }
                    />
                )
            }
        }
        if( listGroup.length > 0)
        {
            setList( listGroup );      
        }

        if (  listGroup == [] ) 
        {
            setList( false );
        }
        if ( listGroup == null)
        {
            setList( false );
        }

    }

    async function checkSubjects (group) 
    {
        var checking = false;
        let checkthegroup = await firebase.database().ref(`schools/${infoSchool?.school}/groups/${group}/school_subjects`).once('value');
        if(checkthegroup.exists())
        {
            let arraysubjects = Object.entries(checkthegroup.val())
            for(const subject of arraysubjects)
            {
                if(subject[1].teacher_key == currentUser)
                {
                    checking = true
                }
            }
        }
        return checking;
    }

    return(
        <Grid container className="padres_grid">
            {/* <NavTeacherPhone /> */}

            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global"> 
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Evaluaciones"
                        text="Elige un grupo para ver sus evaluaciones"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    <ShowContracts 
                        list={ list } 
                        isGroups={ true }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
