import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Grid } from '@material-ui/core';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { useParams } from 'react-router-dom';
import { Scheduler, WeekView, Appointments, AppointmentTooltip} from '@devexpress/dx-react-scheduler-material-ui';

//Styles
import '../../Assets/Styles/Schedule/ScheduleInfo.css';

//Firebase
import * as firebase from 'firebase';

//Components
import PageHeader from '../../Components/Global/PageHeader';

const ScheduleInfo =()=>{

    const {type,id} = useParams();

    const [data,setData] = useState([]);

    // const appointments = [{
    //     title: 'Nikolas Tesla',
    //     startDate: new Date(2018, 5, 25, 12, 35),
    //     endDate: new Date(2018, 5, 25, 15, 0),
    //     id: 0,
    //     location: 'Grupo A Turno Matutino',
    //   }, {
    //     title: 'Nikolas Tesla',
    //     startDate: new Date(2018, 5, 26, 12, 35),
    //     endDate: new Date(2018, 5, 26, 15, 0),
    //     id: 1,
    //     location: 'Grupo B Turno Matutino',
    //   }, {
    //     title: 'Nikolas Tesla',
    //     startDate: new Date(2018, 5, 27, 12, 35),
    //     endDate: new Date(2018, 5, 27, 15, 0),
    //     id: 2,
    //     location: 'Grupo C Turno Matutino',
    //   }, {
    //     title: 'Nikolas Tesla',
    //     startDate: new Date(2018, 5, 28, 12, 35),
    //     endDate: new Date(2018, 5, 28, 15, 0),
    //     id: 3,
    //     location: 'Grupo C Turno Matutino',
    //   }, {
    //     title: 'Nikolas Tesla',
    //     startDate: new Date(2018, 5, 29, 12, 35),
    //     endDate: new Date(2018, 5, 29, 15, 0),
    //     id: 4,
    //     location: 'Grupo E Turno Matutino',
    //   },{
    //       title: 'Nikolas Tesla',
    //       startDate: new Date(2018, 5, 25, 16, 30),
    //       endDate: new Date(2018, 5, 25, 20, 0),
    //       id: 5,
    //       location: 'Grupo F Turno Matutino',
    // }];

    useEffect(()=>{
        changeLanguage();
        getData();
    },[])

    const changeLanguage =()=>{
        var days = document.querySelectorAll('p[class^="Cell-dayOfWeek-"]');

        for (let index = 0; index < days.length; index++) {
            if(index === 0) days[index].innerHTML="Lunes";
            else if(index === 1) days[index].innerHTML="Martes";
            else if(index === 2) days[index].innerHTML="Miercoles";
            else if(index === 3) days[index].innerHTML="Jueves"; 
            else if(index === 4) days[index].innerHTML="Viernes"; 
            else if(index === 5) days[index].innerHTML="Sabado";
        }
    }

    const getData =async()=>{
        let db = firebase.database();
        let list = [];

        if(type === 'profesores' && data.length === 0){
            let teacherNameRef = db.ref(`teacher/${id}`);
            
            let name = '';
            let schoolId = '';
            let listSubject = [];

            /* Get teacher's name */
            await teacherNameRef.once('value').then(function(snapshot) {
                let itemTeacher = snapshot.val();
                if(itemTeacher){
                    name = itemTeacher.name;
                    schoolId = itemTeacher.school;
                }
            });

            /* Get all subjects */
            let subjectRef = db.ref(`school_subjects`);
            await subjectRef.once('value').then(function(snapshot) {
                listSubject = snapshot.val();
            });

            let schoolRef = db.ref(`schools/${schoolId}/groups`);

            /* Get schedule */
            await schoolRef.once('value').then(function(snapshot) {
                let item = snapshot.val();

                for (var clave in item){
                    let item2 = item[clave].school_subjects;

                    for(var clave2 in item2){
              
                        if(item2[clave2].teacher_key && item2[clave2].teacher_key === id ){

                            let subjectTitle = '';
                            if(listSubject[clave2] && listSubject[clave2].title) subjectTitle = listSubject[clave2].title;

                            //Get
                            const { monday, tuersday, wensday, tursday, friday, saturday } = item2[clave2].days || {};
                            const { 
                                horaInicioLunes,
                                horaInicioMartes,
                                horaInicioMiercoles,
                                horaInicioJueves,
                                horaInicioViernes,
                                horaInicioSabado,
                                horaFinalLunes, 
                                horaFinalMartes,
                                horaFinalMiercoles,
                                horaFinalJueves, 
                                horaFinalViernes, 
                                horaFinalSabado,
                            } = item2[clave2].days || {};

                            if( monday && horaInicioLunes && horaFinalLunes && horaInicioLunes !== '' && horaFinalLunes !== ''){
                                const array1 = horaInicioLunes.split(":");
                                const array2 = horaFinalLunes.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 25, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 25, Number(array2[0]), Number(array2[1])),
                                });
                            }
                            if(tursday && horaInicioMartes && horaFinalMartes && horaInicioMartes !== '' && horaFinalMartes !== ''){
                                const array1 = horaInicioMartes.split(":");
                                const array2 = horaFinalMartes.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 26, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 26, Number(array2[0]), Number(array2[1])),
                                });
                            } 
                            if(wensday && horaInicioMiercoles && horaFinalMiercoles && horaInicioMiercoles !== '' && horaFinalMiercoles !== ''){
                                const array1 = horaInicioMiercoles.split(":");
                                const array2 = horaFinalMiercoles.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 27, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 27, Number(array2[0]), Number(array2[1])),
                                });
                            }
                            if(tuersday && horaInicioJueves && horaFinalJueves && horaInicioJueves !== '' && horaFinalJueves !== ''){
                                const array1 = horaInicioJueves.split(":");
                                const array2 = horaFinalJueves.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 28, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 28, Number(array2[0]), Number(array2[1])),
                                });
                            }
                            if(friday && horaInicioViernes && horaFinalViernes && horaInicioViernes !== '' && horaFinalViernes !== ''){
                                const array1 = horaInicioViernes.split(":");
                                const array2 = horaFinalViernes.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 29, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 29, Number(array2[0]), Number(array2[1])),
                                });
                            }
                            if(saturday && horaInicioSabado && horaFinalSabado && horaInicioSabado !== '' && horaFinalSabado !== ''){
                                const array1 = horaInicioSabado.split(":");
                                const array2 = horaFinalSabado.split(":");
                                list.push({
                                    title: `${subjectTitle} - ${name} - ${item[clave].grade ? item[clave].grade:''} ${item[clave].group ? item[clave].group:''} ${item[clave].level ? item[clave].level:''}`,
                                    startDate: new Date(2018, 5, 30, Number(array1[0]), Number(array1[1])),
                                    endDate: new Date(2018, 5, 30, Number(array2[0]), Number(array2[1])),
                                });
                            } 
                        }
                    }
                }
            });

            setData(list);
        }
        if(type === 'child' && data.length === 0){
            let childNameRef = db.ref(`childs/${id}`);

            /* Child's data */
            let grade = '';
            let group = '';
            let turn = '';
            let child_type = '';
            let school = '';
            let level = '';
            let arrayDetails = [];


            /* Get child */
            await childNameRef.once('value').then(function(snapshot) {
                let itemChild = snapshot.val();
                if(itemChild && itemChild.school && itemChild.details){
                    grade = itemChild.details.grade;
                    group = itemChild.details.group;
                    turn = itemChild.details.turn;
                    child_type = itemChild.child_type;
                    school = itemChild.school;
                    level = itemChild.group;
                }
            });

            let subjectRef = db.ref(`school_subjects`);//Get subject's details
            /* Get subject's details */
            await subjectRef.once('value').then(function(snapshot) {
                let itemDetails = snapshot.val();
                arrayDetails = itemDetails;
            });

            let schoolRef = db.ref(`schools/${school}/groups/${level}/school_subjects/`);

            /* Get schedule, check each teacher that match with school, grade, group and turn*/
            await schoolRef.once('value').then(function(snapshot) {
                let item = snapshot.val();

                for (var clave in item){
                    let title = '';
                    for(var clave2 in arrayDetails){
                        if(clave2 === clave){
                            title = arrayDetails[clave2].title;
                            break;
                        }
                    }
                    
                    const {monday, tuersday, wensday, tursday, friday, saturday} = item[clave].days;
                    const {
                        horaInicioLunes,
                        horaInicioMartes,
                        horaInicioMiercoles,
                        horaInicioJueves,
                        horaInicioViernes,
                        horaInicioSabado,
                        horaFinalLunes, 
                        horaFinalMartes,
                        horaFinalMiercoles,
                        horaFinalJueves, 
                        horaFinalViernes, 
                        horaFinalSabado,
                    } = item[clave].days;

                    if(monday && horaInicioLunes && horaFinalLunes && horaInicioLunes !== '' && horaFinalLunes !== ''){
                        const array1 = horaInicioLunes.split(":");
                        const array2 = horaFinalLunes.split(":");
                                    
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 25, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 25, Number(array2[0]), Number(array2[1])),
                        });
                    }
                    if(tursday && horaInicioMartes && horaFinalMartes && horaInicioMartes !== '' && horaFinalMartes !== ''){
                        const array1 = horaInicioMartes.split(":");
                        const array2 = horaFinalMartes.split(":");
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 26, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 26, Number(array2[0]), Number(array2[1])),
                        });
                                } 
                    if(wensday && horaInicioMiercoles && horaFinalMiercoles && horaInicioMiercoles !== '' && horaFinalMiercoles !== ''){
                        const array1 = horaInicioMiercoles.split(":");
                        const array2 = horaFinalMiercoles.split(":");
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 27, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 27, Number(array2[0]), Number(array2[1])),
                        });
                    }
                    if(tuersday && horaInicioJueves && horaFinalJueves && horaInicioJueves !== '' && horaFinalJueves !== ''){
                        const array1 = horaInicioJueves.split(":");
                        const array2 = horaFinalJueves.split(":");
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 28, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 28, Number(array2[0]), Number(array2[1])),
                        });
                    }
                    if(friday && horaInicioViernes && horaFinalViernes && horaInicioViernes !== '' && horaFinalViernes !== ''){
                        const array1 = horaInicioViernes.split(":");
                        const array2 = horaFinalViernes.split(":");
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 29, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 29, Number(array2[0]), Number(array2[1])),
                        });
                    }
                    if(saturday && horaInicioSabado && horaFinalSabado && horaInicioSabado !== '' && horaFinalSabado !== ''){
                        const array1 = horaInicioSabado.split(":");
                        const array2 = horaFinalSabado.split(":");
                        list.push({
                            title: `${title} - Profesor ${item[clave].teacher_name} - ${grade} ${group} ${turn}.`,
                            startDate: new Date(2018, 5, 30, Number(array1[0]), Number(array1[1])),
                            endDate: new Date(2018, 5, 30, Number(array2[0]), Number(array2[1])),
                        });
                    }  
                }
            });
            setData(list);
        }else{
            if(type && type !== '' && data.length === 0){
                let groupNameRef = db.ref(`schools/${type}/groups/${id}`);
                let groupRef = db.ref(`schools/${type}/groups/${id}/school_subjects`);

                let grade = '';
                let group = '';
                let level = '';

                await groupNameRef.once('value').then(function(snapshot) {
                    let item = snapshot.val();
                    if(item && item.grade && item.group && item.level){
                        grade = item.grade;
                        group = item.group;
                        level = item.level;
                    }
                });

                await groupRef.once('value').then(function(snapshot) {
                    let item = snapshot.val();

                    for (var clave in item){
                        const {monday, tuersday, wensday, tursday, friday, saturday} = item[clave].days;
                        const {
                            horaInicioLunes,
                            horaInicioMartes,
                            horaInicioMiercoles,
                            horaInicioJueves,
                            horaInicioViernes,
                            horaInicioSabado,
                            horaFinalLunes, 
                            horaFinalMartes,
                            horaFinalMiercoles,
                            horaFinalJueves, 
                            horaFinalViernes, 
                            horaFinalSabado,
                        } = item[clave].days;
                        
                        if(monday && horaInicioLunes && horaFinalLunes && horaInicioLunes !== '' && horaFinalLunes !== ''){
                            const array1 = horaInicioLunes.split(":");
                            const array2 = horaFinalLunes.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 25, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 25, Number(array2[0]), Number(array2[1])),
                            });
                        }
                        if(tursday && horaInicioMartes && horaFinalMartes && horaInicioMartes !== '' && horaFinalMartes !== ''){
                            const array1 = horaInicioMartes.split(":");
                            const array2 = horaFinalMartes.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 26, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 26, Number(array2[0]), Number(array2[1])),
                            });
                        } 
                        if(wensday && horaInicioMiercoles && horaFinalMiercoles && horaInicioMiercoles !== '' && horaFinalMiercoles !== ''){
                            const array1 = horaInicioMiercoles.split(":");
                            const array2 = horaFinalMiercoles.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 27, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 27, Number(array2[0]), Number(array2[1])),
                            });
                        }
                        if(tuersday && horaInicioJueves && horaFinalJueves && horaInicioJueves !== '' && horaFinalJueves !== ''){
                            const array1 = horaInicioJueves.split(":");
                            const array2 = horaFinalJueves.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 28, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 28, Number(array2[0]), Number(array2[1])),
                            });
                        }
                        if(friday && horaInicioViernes && horaFinalViernes && horaInicioViernes !== '' && horaFinalViernes !== ''){
                            const array1 = horaInicioViernes.split(":");
                            const array2 = horaFinalViernes.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 29, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 29, Number(array2[0]), Number(array2[1])),
                            });
                        }
                        if(saturday && horaInicioSabado && horaFinalSabado && horaInicioSabado !== '' && horaFinalSabado !== ''){
                            const array1 = horaInicioSabado.split(":");
                            const array2 = horaFinalSabado.split(":");
                            list.push({
                                title: `Profesor ${item[clave].teacher_name} - ${grade} ${group} ${level}`,
                                startDate: new Date(2018, 5, 30, Number(array1[0]), Number(array1[1])),
                                endDate: new Date(2018, 5, 30, Number(array2[0]), Number(array2[1])),
                            });
                        }
                    }
                });

                setData(list);
            }  
        }
        

    }
    
    return(
        <Grid container className="schedule__main">
            <Grid item xs={12} >
                <Box pb={3}>
                    <PageHeader 
                        title="Horario"
                        text="Selecciona para ver detalles"
                        btn_back="/"
                        btn_action={false}
                    />
                </Box>
            </Grid>
            <Grid container>
                <Paper>
                    <Scheduler data={data}>
                        <ViewState
                            defaultCurrentDate="2018-06-27"
                        />
                        <WeekView
                            startDayHour={8}
                            endDayHour={20}
                            excludedDays={[0]}
                        />
                        <Appointments />
                        <AppointmentTooltip />
                    
                    </Scheduler>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ScheduleInfo; 