import React, { useState, useContext,  } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//IMG
import file from './../Assets/Img/General/File.png';
import upload from './../Assets/Img/General/Icon_upload.png';
//Component
import PageHeader from '../Components/Global/PageHeader';
import ContactoRefenciaItem from '../Components/Global/ContactoRefenciaItem';
import ModalShow from '../Components/Global/Modal'
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

//firebase
import * as firebase from 'firebase';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { checkFile } from '../Components/functions/helpapers';

function AgregarReferencia () {

    const history = useHistory();
    const match = useRouteMatch('/referencias/agregar/:id');
    const childID = match.params.id;

    const [ oneName, setOneName ]               = useState('');
    const [ oneLastName, setaOneLastName ]      = useState('');
    const [ oneEmail, setOneEmail ]             = useState('');
    const [ onePhone, setOnePhone ]             = useState('');
    const [ oneType, setOneType ]               = useState('delegado');
    const [ oneDocProfile, setOneDocProfile ]   = useState('');
    const [ oneDocId, setOneDocId ]             = useState('');

    /* Para subir archivo del comprobante de domicilio */
    const [ oneDocProofAddresId, setOneDocProofAddresId ]     = useState('');
    const [ twoDocProofAddresId, setTwoDocProofAddresId ]     = useState('');
    const [ threeDocProofAddresId, setThreeDocProofAddresId ]     = useState('');

    /* Para subir archivos del documento opcional */
    const [ oneDocOpcionalId, setOneDocOpcionalId ] = useState('');
    const [ twoDocOpcionalId, setTwoDocOpcionalId ] = useState('');
    const [ threeDocOpcionalId, setThreeDocOpcionalId ] = useState('');


    const [ oneRef, setOneRef ]                 = useState(false);

    const [ twoName, setTwoName ]               = useState('');
    const [ twoLastName, setTwoLastName ]       = useState('');
    const [ twoEmail, setTwoEmail ]             = useState('');
    const [ twoPhone, setTwoPhone ]             = useState('');
    const [ twoType, setTwoType ]               = useState('delegado');
    const [ twoDocProfile, setTwoDocProfile ]   = useState('');
    const [ twoDocId, setTwoDocId ]             = useState('');
    const [ twoRef, setTwoRef ]                 = useState(false);

    const [ threeName, setThreeName ]               = useState('');
    const [ threeLastName, setThreeLastName ]       = useState('');
    const [ threeEmail, setThreeEmail ]             = useState('');
    const [ threePhone, setThreePhone ]             = useState('');
    const [ threeType, setThreeType ]               = useState('delegado');
    const [ threeDocProfile, setThreeDocProfile ]   = useState('');
    const [ threeDocId, setThreeDocId ]             = useState('');
    const [ threeRef, setThreeRef ]                 = useState(false);

    const [ oneError, setOneError ]     = useState('');
    const [ twoError, setTwoError ]     = useState('');
    const [ threeError, setThreeError ] = useState('');
    const [ error, setError ] = useState('');
    const { currentUser }               = useContext( AuthContext );
    const [ modalStaus, setModalStaus ]         = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };  

    const handleUploadFile = async ( id, path, file, setfile ) => {

        let img = file;
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            seterrorMsg({...errorMsg, doc: false });
            const storage = firebase.storage();
            const storageRef = firebase.storage().ref(`schools/${id}/${img.name}`);
            const task = storageRef.put(img);
            task.on('state_changed', (snapshot) => {
            }, (error) => {
                setError(error.message);
            }, () => {
              // Upload complete
                storage
                .ref( `schools/${id}/${img.name}` )
                .getDownloadURL()
                .then( url => {
                    // setLogofile( url );
                });
            })
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos validos: png, jpg y jpeg' });
        }
    }
    
    const uploadAvatar = async ( e, ref ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            if ( ref === 1 ) {
                setOneDocProfile( img );
                seterrorMsg({...errorMsg, avatar1: false });
            }
            if ( ref === 2 ) {
                setTwoDocProfile( img );
                seterrorMsg({...errorMsg, avatar2: false });
            }
            if ( ref === 3 ) {
                setThreeDocProfile( img );
                seterrorMsg({...errorMsg, avatar3: false });
            }
            try {
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutors/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        if ( ref === 1 ) {
                            const profileImg =  document.getElementById("AvatarIMG_1")
                            profileImg.src = url;
                        }
                        if ( ref === 2 ) {
                            const profileImg =  document.getElementById("AvatarIMG_2")
                            profileImg.src = url;
                        }
                        if ( ref === 3 ) {
                            const profileImg =  document.getElementById("AvatarIMG_3")
                            profileImg.src = url;
                        }
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            if ( ref === 1 ) {
                seterrorMsg({...errorMsg, avatar1: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 2 ) {
                seterrorMsg({...errorMsg, avatar2: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 3 ) {
                seterrorMsg({...errorMsg, avatar3: 'Formatos validos: png, jpg y jpeg' });
            }
            
        }
    }

    const uploadDocId = async( e, ref ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            if ( ref === 1 ) {
                setOneDocId( img );
                seterrorMsg({...errorMsg, id1: false });
            }
            if ( ref === 2 ) {
                setTwoDocId( img );
                seterrorMsg({...errorMsg, id2: false });
            }
            if ( ref === 3 ) {
                setThreeDocId( img );
                seterrorMsg({...errorMsg, id3: false });
            }
            try {
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutors/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        if ( ref === 1 ) {
                            const profileImg =  document.getElementById("idIMG_1")
                            profileImg.src = url;
                        }
                        if ( ref === 2 ) {
                            const profileImg =  document.getElementById("idIMG_2")
                            profileImg.src = url;
                        }
                        if ( ref === 3 ) {
                            const profileImg =  document.getElementById("idIMG_3")
                            profileImg.src = url;
                        }
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            if ( ref === 1 ) {
                seterrorMsg({...errorMsg, id1: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 2 ) {
                seterrorMsg({...errorMsg, id2: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 3 ) {
                seterrorMsg({...errorMsg, id3: 'Formatos validos: png, jpg y jpeg' });
            }
        }
    }

    const uploadDocComprobante = async(e,ref)=>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            if ( ref === 1 ) {
                setOneDocProofAddresId( img );
                seterrorMsg({...errorMsg, id1: false });
            }
            if ( ref === 2 ) {
                setTwoDocProofAddresId( img );
                seterrorMsg({...errorMsg, id2: false });
            }
            if ( ref === 3 ) {
                setThreeDocProofAddresId( img );
                seterrorMsg({...errorMsg, id3: false });
            }
            try {
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutors/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        if ( ref === 1 ) {
                            const profileImg =  document.getElementById("comprobanteIMG1")
                            profileImg.src = url;
                        }
                        if ( ref === 2 ) {
                            const profileImg =  document.getElementById("comprobanteIMG2")
                            profileImg.src = url;
                        }
                        if ( ref === 3 ) {
                            const profileImg =  document.getElementById("comprobanteIMG3")
                            profileImg.src = url;
                        }
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            if ( ref === 1 ) {
                seterrorMsg({...errorMsg, id1: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 2 ) {
                seterrorMsg({...errorMsg, id2: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 3 ) {
                seterrorMsg({...errorMsg, id3: 'Formatos validos: png, jpg y jpeg' });
            }
        }
    }

    const uploadDocOpcional =async(e,ref)=>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            if ( ref === 1 ) {
                setOneDocOpcionalId( img );
                seterrorMsg({...errorMsg, id1: false });
            }
            if ( ref === 2 ) {
                setTwoDocOpcionalId( img );
                seterrorMsg({...errorMsg, id2: false });
            }
            if ( ref === 3 ) {
                setThreeDocOpcionalId( img );
                seterrorMsg({...errorMsg, id3: false });
            }
            try {
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`tutors/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        if ( ref === 1 ) {
                            const profileImg =  document.getElementById("opcionalIMG1")
                            profileImg.src = url;
                        }
                        if ( ref === 2 ) {
                            const profileImg =  document.getElementById("opcionalIMG2")
                            profileImg.src = url;
                        }
                        if ( ref === 3 ) {
                            const profileImg =  document.getElementById("opcionalIMG3")
                            profileImg.src = url;
                        }
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            if ( ref === 1 ) {
                seterrorMsg({...errorMsg, id1: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 2 ) {
                seterrorMsg({...errorMsg, id2: 'Formatos validos: png, jpg y jpeg' });
            }
            if ( ref === 3 ) {
                seterrorMsg({...errorMsg, id3: 'Formatos validos: png, jpg y jpeg' });
            }
        }
    }

    const handlesubmit = e =>{
        e.preventDefault();
        // RegExp to check te fields 
        const regexname = RegExp(/\w/g);
        const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const regexphone = RegExp(/\d+[^a-zA-Z]{6,}/);
        const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        const isNumber = num => /^([0-9])*$/.test(num);

        let flag1 = false;
        let flag2 = false;  
        let flag3 = false;
        let mustBeComplete1 = false;
        let mustBeComplete2 = false;
        let mustBeComplete3 = false;

        if( oneName === "" && oneLastName === "" && oneEmail === "" && onePhone === "" ){
            setOneRef( false );
            mustBeComplete1 = false;
        }else{
            mustBeComplete1 = true;
            if(oneName === "" ){
                setOneError('Ingrese un Nombre porfavor');
                setOneRef( false );
            }else if(oneName.length < 5 || oneName.length > 40){
                setOneError('El nombre debe contener entre 5 a 40 caracteres');
                setOneRef( false );
            }else if(oneLastName === ""){
                setOneError('Ingrese un Apellido porfavor');
                setOneRef( false );
            }else if(oneLastName.length < 5 || oneLastName.length > 40){
                setOneError('El apellido debe contener entre 5 a 40 caracteres');
                setOneRef( false );
            }else if(oneEmail === ""){
                setOneError('Ingrese un correo email porfavor');
                setOneRef( false );
            }else if(onePhone === ""){
                setOneError('Ingrese un numero celular por favor');
                setOneRef( false );
            }else if(onePhone && ( (onePhone.length < 10 || onePhone.length > 15) || !isNumber(onePhone) ) ){
                setOneError('El teléfono debe contener de 10 a 15 dígitos y debe ser númerico');
                setOneRef( false );
            }else {
                setOneRef( true );
                flag1 = true;
            }
        }

        if(twoName === "" && twoLastName === "" && twoEmail === "" && twoPhone === ""){
            setTwoRef( false )
            mustBeComplete2 = false;
        }else{
            mustBeComplete2 = true;
            if(twoName === "" ){
                setTwoError('Ingrese un Nombre porfavor');
                setTwoRef( false );
            }else if(twoLastName === ""){
                setTwoError('Ingrese un Apellido porfavor');
                setTwoRef( false );
            }else if(twoEmail === ""){
                setTwoError('Ingrese un correo email porfavor');
                setTwoRef( false );
            }else if(twoPhone === ""){
                setTwoError('Ingrese un numero celular por favor');
                setTwoRef( false );
            }else if (twoPhone && ( (twoPhone.length < 10 || twoPhone.length > 15) || !isNumber(twoPhone) )){
                setTwoError('El teléfono debe contener de 10 a 15 dígitos y debe ser númerico');
                setTwoRef( false );
            }else{
                setTwoRef( true );
                flag2 = true;
            } 
        }

        if(threeName === "" && threeLastName === "" && threeEmail === "" && threePhone === ""){
            setThreeRef( false );
            mustBeComplete3 = false;
        }else{
            mustBeComplete3 = true;
            if(threeName === "" ){
                setThreeError('Ingrese un Nombre porfavor');
                setThreeRef( false );
            }else if(threeLastName === ""){
                setThreeError('Ingrese un Apellido porfavor');
                setThreeRef( false );
            }else if(threeEmail === ""){
                setThreeError('Ingrese un correo email porfavor');
                setThreeRef( false );
            }else if(threePhone === ""){
                setThreeError('Ingrese un numero celular por favor');
                setThreeRef( false );
            }else if(threePhone && ( (threePhone.length < 10 || threePhone.length > 15) || !isNumber(threePhone) )){
                setThreeError('El teléfono debe contener de 10 a 15 dígitos y debe ser númerico');
                setThreeRef( false );
            }else{
                setThreeRef( true );
                flag3 = true;
            } 
        }

        if ( flag1 || flag2 || flag3 ) {
            if( (mustBeComplete1 && !flag1) || (mustBeComplete2 && !flag2) || (mustBeComplete3 && flag3) ){ //error
            }else{
                setThreeError('');
                setTwoError('');
                // setOneRef('');
                handleForm(e,flag1, flag2, flag3); 
            }
        }
    };

    const addReference = ( e, Ref ) => {
        let db = firebase.database();
        let childRef = db.ref(`childs/${childID}/tutors`);
        if ( Ref === 1 ) {
            childRef.update({ tutor_2: e }, 
                function(error) {
                    if (error) { console.log(error) } 
                }
            );
        }
        if ( Ref === 2 ) {
            childRef.update({ tutor_3: e }, 
                function(error) {
                    if (error) { console.log(error) } 
                }
            );
        }
        if ( Ref === 3 ) {
            childRef.update({ tutor_4: e }, 
                function(error) {
                    if (error) { console.log(error) } 
                }
            );
        }
        
    }

    const handleForm = (e,flag1,flag2,flag3) => {
        e.preventDefault();
        let db = firebase.database();
        const avatarIMG_1 = oneDocProfile && document.getElementById('AvatarIMG_1').src ;
        const avatarIMG_2 = twoDocProfile && document.getElementById('AvatarIMG_2').src ;
        const avatarIMG_3 = threeDocProfile && document.getElementById('AvatarIMG_3').src ;
        /* Img document */
        const idIMG_1 = oneDocId && document.getElementById('idIMG_1').src ;
        const idIMG_2 = twoDocId && document.getElementById('idIMG_2').src ;
        const idIMG_3 = threeDocId && document.getElementById('idIMG_3').src ;
        /* Address */
        const idIMGAdrres_1 = oneDocProofAddresId && document.getElementById('comprobanteIMG1').src;
        const idIMGAdrres_2 = twoDocProofAddresId && document.getElementById('comprobanteIMG2').src;
        const idIMGAdrres_3 = threeDocProofAddresId && document.getElementById('comprobanteIMG3').src;
        /* Optional */
        const idIMGOptional_1 = oneDocOpcionalId && document.getElementById('opcionalIMG1').src;
        const idIMGOptional_2 = twoDocOpcionalId && document.getElementById('opcionalIMG2').src;
        const idIMGOptional_3 = threeDocOpcionalId && document.getElementById('opcionalIMG3').src;

        if ( flag1 ) {
            let id_tutor = `tutor_${Date.now()}`;
            let tutorRef = db.ref(`tutors/${id_tutor}`);
            tutorRef.set({
                avatar: avatarIMG_1,
                school: currentUser,
                id: id_tutor,
                name: oneName,
                last_name: oneLastName,
                status: "active",
                type: oneType,
                timestamp: Date.now(),
                documents: {
                    id: idIMG_1,
                    opcional: idIMGOptional_1,
                    proof_addres: idIMGAdrres_1,
                },
                contact:{
                    email: oneEmail,
                    phone: onePhone,
                },
                childs: {
                    child_1: childID
                }
            }, 
            error => {
                if (error) { console.log(error) } 
                else {
                    // Data saved successfully!
                    setOneName('');
                    setaOneLastName('');
                    setOneEmail('');
                    setOnePhone('');
                    setOneDocProfile('');
                    setOneDocId('');
                    setOneError('');
                    ModalOpen();
                    addReference( id_tutor, 1 );
                }
            });
            
        }

        if ( flag2 ) {
            let id_tutor = `tutor${Date.now()+1}`;
            let tutorRef = db.ref(`tutors/${id_tutor}`);
            tutorRef.set({
                avatar: avatarIMG_2,
                school: currentUser,
                id: id_tutor,
                name: twoName,
                last_name: twoLastName,
                status: "active",
                type: twoType,
                timestamp: Date.now(),
                documents: {
                    id: idIMG_2,
                    opcional: idIMGOptional_2,
                    proof_addres: idIMGAdrres_2,
                },
                contact:{
                    email: twoEmail,
                    phone: twoPhone,
                },
                childs: {
                    child_1: childID
                }
            }, 
            error => {
                if (error) {
                  // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                    setOneName('');
                    setaOneLastName('');
                    setOneEmail('');
                    setOnePhone('');
                    setOneDocProfile('');
                    setOneDocId('');
                    setOneError('');
                    ModalOpen();
                    addReference( id_tutor, 2 );
                }
            });
        }

        if ( flag3 ) {
            let id_tutor = `tutor${Date.now()+2}`;
            let tutorRef = db.ref(`tutors/${id_tutor}`);
            tutorRef.set({
                avatar: avatarIMG_3,
                school: currentUser,
                id: id_tutor,
                name: threeName,
                last_name: threeLastName,
                status: "active",
                type: threeType,
                timestamp: Date.now(),
                documents: {
                    id: idIMG_3,
                    opcional: idIMGOptional_3,
                    proof_addres: idIMGAdrres_3,
                },
                contact:{
                    email: threeEmail,
                    phone: threePhone,
                },
                childs: {
                    child_1: childID
                }
            }, 
            error => {
                if (error) {
                  // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                    setOneName('');
                    setaOneLastName('');
                    setOneEmail('');
                    setOnePhone('');
                    setOneDocProfile('');
                    setOneDocId('');
                    setOneError('');
                    ModalOpen();
                    addReference( id_tutor, 3 );
                }
            });
        }
        
        setModalmsg('Referencias agregadas')
        setModalLink(`/alumnos/informacion/${childID}`)
        ModalOpen();
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={ e => handlesubmit(e) } >
                <Grid container className="addReference_grid">
                    <Grid item xs={12}>
                        <Box pb={3}>
                            <PageHeader 
                                title="Contactos de referencia"
                                text="Añade 3 contactos para que otra persona pueda recoger al alumno."
                                btn_back={`/referencias/${childID}`}
                                btn_action={true}
                                btn_action_text="Siguiente"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box borderRadius={16} boxShadow={3} p={5} className="kipperBox_content">
                            <Grid container>
                                <Grid item xs={12}>
                                        {
                                            oneError
                                                ?<Alert severity="error">{oneError}</Alert>
                                                :null
                                        }
                                    <Box mt={3}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Typography variant="h5" gutterBottom> Primer Contacto </Typography><br/>
                                            {/* {oneError}  */}
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>

                                    <div className="kipperBox_content-upload" >
                                        <input type="file" id="ProfileImage_1" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e, 1 )  } />
                                        <label htmlFor="ProfileImage_1" className="pointer">
                                            { oneDocProfile 
                                                ? <img alt="" className="previw_img rounded" id={'AvatarIMG_1'} />
                                                : <img src={file} alt="" className="profileImg rounded" />
                                            }
                                            Elegir una foto de perfil
                                        </label>
                                        { !!errorMsg.avatar1 && <span style={{color: 'red'}}><small>{ errorMsg.avatar1 }</small></span> }
                                    </div>
                                    
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="NOMBRE" type="text" fullWidth value={ oneName } onChange={ e => setOneName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="APELLIDO" type="text" fullWidth value={ oneLastName }  onChange={ e => setaOneLastName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="TELEFONO" type="text" fullWidth value={ onePhone }  onChange={ e => setOnePhone(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="CORREO ELECTRONICO" type="email" fullWidth value={ oneEmail }  onChange={ e => setOneEmail(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="type">
                                                    Tipo
                                                </InputLabel>
                                                <Select labelId="type" onChange={ e => setOneType(e.target.value) } value={ oneType } displayEmpty className="" fullWidth required >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="tutor">Padre de familia o tutor</MenuItem>
                                                    <MenuItem value="delegado">Delegado escolar</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="row">
                                        <input type="file" id="docIdentificacion_1" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e, 1 ) } />
                                        <label htmlFor="docIdentificacion_1" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                { oneDocId 
                                                    ? <p className="kipper_txt-color">{ oneDocId.name }</p>
                                                    : <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'idIMG_1'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Identificación Oficial
                                        </Typography>
                                        { !!errorMsg.id1 && <span style={{color: 'red'}}><small>{ errorMsg.id1 }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docComprobante_1" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e, 1 ) }  />
                                        <label for="docComprobante_1" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    oneDocProofAddresId ? 
                                                    <p className="kipper_txt-color">{ oneDocProofAddresId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG1'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Comprobante de domicilio
                                        </Typography>
                                        { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docOpcional_1" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e, 1 ) }  />
                                        <label for="docOpcional_1" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    oneDocOpcionalId ? 
                                                    <p className="kipper_txt-color">{ oneDocOpcionalId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'opcionalIMG1'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Documento opcional
                                        </Typography>
                                        { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                    </div>
                                </Grid>
                                
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                            { twoError && <Alert severity="error">{twoError}</Alert> }
                                            {/* {twoError} */}
                                    <Box mt={3}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Typography variant="h5" gutterBottom> Segundo Contacto </Typography><br/>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="kipperBox_content-upload" >
                                        <input type="file" id="ProfileImage_2" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e, 2 )  } />
                                        <label htmlFor="ProfileImage_2" className="pointer">
                                            { twoDocProfile 
                                                ? <img alt="" className="previw_img rounded" id={'AvatarIMG_2'} />
                                                : <img src={file} alt="" className="profileImg rounded" />
                                            }
                                            Elegir una foto de perfil
                                        </label>
                                        { !!errorMsg.avatar2 && <span style={{color: 'red'}}><small>{ errorMsg.avatar2 }</small></span> }
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="NOMBRE" type="text" fullWidth value={ twoName } onChange={ e => setTwoName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="APELLIDO" type="text" fullWidth value={ twoLastName } onChange={ e => setTwoLastName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="TELEFONO" type="text" fullWidth value={ twoPhone } onChange={ e => setTwoPhone(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="CORREO ELECTRONICO" type="email" fullWidth value={ twoEmail } onChange={ e => setTwoEmail(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="type">
                                                    Tipo
                                                </InputLabel>
                                                <Select labelId="type" onChange={ e => setTwoType(e.target.value) } value={twoType} displayEmpty className="" fullWidth required >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="tutor">Padre de familia o tutor</MenuItem>
                                                    <MenuItem value="delegado">Delegado escolar</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="row">
                                        <input type="file" id="docIdentificacion_2" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e, 2 ) } />
                                        <label htmlFor="docIdentificacion_2" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                { twoDocId 
                                                    ? <p className="kipper_txt-color">{ twoDocId.name }</p>
                                                    : <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'idIMG_2'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Identificación Oficial
                                        </Typography>
                                        { !!errorMsg.id2 && <span style={{color: 'red'}}><small>{ errorMsg.id2 }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docComprobante_2" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e, 2 ) }  />
                                        <label for="docComprobante_2" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    twoDocProofAddresId ? 
                                                    <p className="kipper_txt-color">{ twoDocProofAddresId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG2'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Comprobante de domicilio
                                        </Typography>
                                        { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docOpcional_2" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e, 2 ) }  />
                                        <label for="docOpcional_2" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    twoDocOpcionalId ? 
                                                    <p className="kipper_txt-color">{ twoDocOpcionalId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'opcionalIMG2'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Documento opcional
                                        </Typography>
                                        { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                    </div>

                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    { threeError && <Alert severity="error">{threeError}</Alert> }
                                    <Box mt={3}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Typography variant="h5" gutterBottom> Tercer Contacto </Typography><br/>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="kipperBox_content-upload" >
                                        <input type="file" id="ProfileImage_3" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e, 3 )  } />
                                        <label htmlFor="ProfileImage_3" className="pointer">
                                            { threeDocProfile 
                                                ? <img alt="" className="previw_img rounded" id={'AvatarIMG_3'} />
                                                : <img src={file} alt="" className="profileImg rounded" />
                                            }
                                            Elegir una foto de perfil
                                        </label>
                                    </div>
                                    { !!errorMsg.avatar3 && <span style={{color: 'red'}}><small>{ errorMsg.avatar3 }</small></span> }
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="NOMBRE" type="text" fullWidth value={ threeName } onChange={ e => setThreeName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2}> 
                                                <TextField label="APELLIDO" type="text" fullWidth value={ threeLastName } onChange={ e => setThreeLastName(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="TELEFONO" type="text" fullWidth value={ threePhone } onChange={ e => setThreePhone(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <TextField label="CORREO ELECTRONICO" type="email" fullWidth value={ threeEmail } onChange={ e => setThreeEmail(e.target.value) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}> 
                                                <InputLabel shrink id="type">
                                                    Tipo
                                                </InputLabel>
                                                <Select labelId="type" onChange={ e => setThreeType(e.target.value) } value={threeType} displayEmpty className="" fullWidth required >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="tutor">Padre de familia o tutor</MenuItem>
                                                    <MenuItem value="delegado">Delegado escolar</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="row">
                                        <input type="file" id="docIdentificacion_3" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e, 3 ) } />
                                        <label htmlFor="docIdentificacion_3" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                { threeDocId 
                                                    ? <p className="kipper_txt-color">{ threeDocId.name }</p>
                                                    : <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'idIMG_3'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Identificación Oficial
                                        </Typography>
                                        { !!errorMsg.id3 && <span style={{color: 'red'}}><small>{ errorMsg.id3 }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docComprobante_3" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e, 3 ) }  />
                                        <label for="docComprobante_3" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    threeDocProofAddresId ? 
                                                    <p className="kipper_txt-color">{ threeDocProofAddresId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG3'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Comprobante de domicilio
                                        </Typography>
                                        { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                    </div>
                                    <div className="row">
                                        <input type="file" id="docOpcional_3" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e, 3 ) }  />
                                        <label for="docOpcional_3" className="kipper_txt-color pointer">
                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                <img src={ upload } alt=""/>
                                                {
                                                    threeDocOpcionalId ? 
                                                    <p className="kipper_txt-color">{ threeDocOpcionalId.name }</p>
                                                    :
                                                    <p className="kipper_txt-color">Selecciona archivo</p>
                                                }
                                            </Grid>
                                        </label>
                                        <img src="" alt="" className="previw_img d-none" id={'opcionalIMG3'} />
                                        <Typography className="" variant="" display="block" gutterBottom>
                                            Documento opcional
                                        </Typography>
                                        { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}


export default AgregarReferencia