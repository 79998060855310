import React, { useContext } from 'react';
import { ListItemText,ListItemIcon  } from '@material-ui/core';
import { Link } from 'react-router-dom';

//Icons
import sticky from '../../../src/Assets/Img/svg_icons/Icon awesome-sticky-note.svg';
import materias from '../../../src/Assets/Img/svg_icons/Icon feather-list.svg';
import evaluations from '../../../src/Assets/Img/svg_icons/Icon material-playlist-add-check.svg';
import evento from '../../../src/Assets/Img/svg_icons/Icon feather-calendar.svg';
import sede from '../../../src/Assets/Img/svg_icons/Icon feather-user.svg';
import chat from '../../../src/Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import logout from '../../../src/Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import home from '../../../src/Assets/Img/svg_icons/Icon feather-home.svg';

const NavHeaderPhoneOptions =({StyledMenuItem,handlelogout})=>{

    return(
        <>
            <StyledMenuItem>
                <ListItemIcon>
                    <img src={home} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/" className="flex">
                    <ListItemText primary="Inicio" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={sticky} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/teacher/citas/tutores" className="flex">
                    <ListItemText primary="Solicitar citas" />
                </Link>  

            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ materias } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/teacher/materias/select/" className="flex">
                    <ListItemText primary="Materias" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ evaluations } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/teacher/evaluaciones/group" className="flex">
                    <ListItemText primary="Evaluaciones" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ evento } alt="Usuario Kiper"  />
                </ListItemIcon>
                <Link to="/teacher/citas" className="flex">
                    <ListItemText primary="Citas" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ sede } alt="Usuario Kiper"  />
                </ListItemIcon>
                <Link to="/teacher/profile" className="flex">
                    <ListItemText primary="Perfil" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ chat } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/teacher/chat" className="flex">
                    <ListItemText primary="Chat" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ logout } alt="Usuario Kiper" />
                </ListItemIcon>
                
                <ListItemText primary="Salir" onClick={handlelogout}/>
          
            </StyledMenuItem>
        </>
    );
}

export default NavHeaderPhoneOptions;