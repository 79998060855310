import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
import logoSchool from './../../Assets/Img/General/Academy.png';
import icon_location from './../../Assets/Img/Home/Icon_Location.png';
import icon_phone from './../../Assets/Img/Home/Icon_phone.png';
import ScheduleIcon from '@material-ui/icons/Schedule';

//MaterialUI
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
// firebases
import * as firebase from 'firebase';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

function Information () {
    const history = useHistory();
    const { currentUser, info, infoSchool }   = useContext(AuthContext);
    const [schoolInfo, setSchoolInfo] = useState('');
    const [tutorInfo, setTutorInfo] = useState('');
    const [planteles, setPlanteles] = useState([]);


    useEffect( () =>{
        if(infoSchool){

            let db = firebase.database();
        // let ref = db.ref(`tutors/${currentUser}`);
        // ref.once('value').then( snapshot => {
        //     if (  snapshot.val().school ) {
        //         setTutorInfo(snapshot.val())
            let ref = db.ref(`schools/${infoSchool.school}`);
            ref.once('value').then( snapshotSchool => {
                console.log(snapshotSchool.val())
            setSchoolInfo( snapshotSchool.val() );
            })
        //     }
        //     else {
        //         setSchoolInfo( false );   
        //     }
        // });
    }   
    }, [infoSchool ] );
    
    return(
        <Grid container className="padres_grid">
            {/* <NavTutorPhone/> */}
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Acerca de la escuela"
                        text="Información de la escuela" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item  xs={12}>
                <Box py={3}  className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid item xs={12} md={2} style={{display:'flex', justifyContent:'center'}}>
                            <Box p={2}  >
                                <img src={ schoolInfo?.avatar ?schoolInfo?.avatar :logoSchool } style={{ width:'150px' }} alt="Logo" />
                            </Box>
                        </Grid>
                        <div className="informationComponentContainer">
                            
                            
                            <h4 className="information_schoolTitle"> { schoolInfo?.name } </h4>
                            
                            <div className="information_locationDataContainer">
                                <img src={ icon_location } id="img_informationIcons" alt=""/>&nbsp;&nbsp;
                                <p>{ schoolInfo?.location?.address }</p>
                            </div>
                            <div className="information_locationDataContainer">
                                <img src={ icon_phone } id="img_informationIcons" alt=""/>&nbsp;&nbsp;
                                <p>{ schoolInfo?.contact?.phone }</p>
                            </div>
                            
                        </div>
                        <Grid id="tutorInformation_gridMisionVision" item xs={12} md={5} py={4} >
                            <Box p={2}>
                                <Typography variant="h6" className="kipper_txt-grey" >
                                    <b>Misión </b> 
                                </Typography>
                                <Typography className="information_misionVisionParraf" >
                                    { schoolInfo?.details?.mision }    
                                </Typography>
                            </Box>
                            <Box p={2}>
                                <Typography variant="h6" className="kipper_txt-grey">
                                    <b> Visión</b> 
                                </Typography>
                                <Typography  className="information_misionVisionParraf">                            
                                    { schoolInfo?.details?.vision }    
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Information