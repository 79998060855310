import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
// MaterialUI
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
// Imagenes
import solicitar from './../../Assets/Img/Layout/sticky-note-grey.png';
import chat from './../../Assets/Img/Layout/chat_bubble-grey.png';
import calendar from './../../Assets/Img/Layout/father-calendar.png';
import profile from './../../Assets/Img/Layout/father-user.png';
import settings from './../../Assets/Img/Layout/father-settings.png';
import hijos from './../../Assets/Img/Layout/childs-lion-icon.png';
import documents from './../../Assets/Img/Layout/file-download.png';
import home from './../../Assets/Img/Layout/Icon_Home.png';
import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import { AuthContext } from '../functions/Auth';

const BottomNavTutor = () => {
    
    // const { handlelogout } = useContext( AuthContext );

    return (
        <div className="bottomTutorNav">
            <Link to="/" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainer">
                    <img src={ home } />
                    <h4 >Inicio</h4>
                </div>
            </Link>
            {/* <Link to="/tutor/citas/" className="flex">
                <BottomNavigationAction 
                    label="Citas" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ solicitar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link> */}
            <Link to="/tutor/childs" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainer">
                    <img src={ hijos } />
                    <h4 >Hijos</h4>
                </div>
            </Link>

            <Link to="/tutor/citas/alumno" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainer">
                    <img src={ solicitar } />
                    <h4 >Solicitar Citas</h4>
                </div>
            </Link>
            {/* <Link to="/tutor/calendario" className="flex">
                <BottomNavigationAction 
                    label="Eventos" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link> */}
            {/* <Link to="/tutor/profile" className="flex">
                <BottomNavigationAction 
                    label="Perfil" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ profile } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link>
            <Link to="/tutor/faq" className="flex">
                <BottomNavigationAction 
                    label="FAQ's" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ settings } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link>
            <Link to="/tutor/information" className="flex">
                <BottomNavigationAction 
                    label="Información" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ information } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link>
            <Link to="/tutor/documents" className="flex">
                <BottomNavigationAction 
                    label="Documentos" 
                    value="recents"
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ documents } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    }  
                />
            </Link> */}
            <Link to="/tutor/chat" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainer">
                    <img src={ chat } />
                    <h4 >Chat</h4>
                </div>
            </Link>
            {/* <BottomNavigationAction 
                label="Salir" 
                value="recents"
                style={{ outline: 'none' }}
                onClick={ () => handlelogout() }
                icon={ 
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                }  
            /> */}
            
        </div>
    )
}

export default BottomNavTutor
