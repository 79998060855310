import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../Components/Global/PageHeader'
import ModalShow from './../../Components/Global/Modal'
//firebase
import * as firebase from 'firebase';
import { Box, Grid, InputLabel, MenuItem, Modal } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import portada from './../../Assets/Img/General/portada_add-blue.png';
import { AuthContext } from "../../Components/functions/Auth";

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import Select from '../../Constants/select';

const CrearGrupos = () => {
    const history = useHistory();
    const { currentUser }   = useContext(AuthContext);

    const [activeGrups, setActiveGrups] = useState('')
    const [ schoolSelector, setSchoolSelector ] = useState(null)
    const [errorSchoolNotFound, setErrorSchoolNotFound] = useState(false);
    const [status, setStatus] = useState({
        ok: null,
        err: null, 
        msg: null
    })
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => setModalStaus(true);

    const ModalClose = () => setModalStaus(false);

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const checkGrup = async (val) => {
        let isGroup = true; 
        if(val.grado && val.grupo && val.turno && val.nivel ){    
        const resp = await firebase.database().ref(`schools/${val.school}/groups/`).once('value');
        resp.forEach( value => {
            let data = value.val()
            if ( data?.grade === val.grado && data?.group === val.grupo && data?.turn === val.turno && data?.level === val.nivel ) {
                isGroup = false
            }
        })
        }else{
            isGroup = false;
        }
        return isGroup
    }

    const handleForm = async ( val ) => {
        setStatus({...status, ok: false, err: false})
        let key;
        switch (val.grado) {
            case 'Primero':
                key = 1;
            break;
            case 'Segundo':
                key = 2;
            break;
            case 'Tercero':
                key = 3;
            break;
            case 'Cuarto':
                key = 4;
            break;
            case 'Quinto':
                key = 5;
            break;
            case 'Sexto':
                key = 6;
            break;
            default:
                break;
        }

        const checkgrup = await checkGrup(val);
        if (!checkgrup && val.grado && val.grupo && val.turno && val.nivel ) {
            return setStatus({...status, ok: false, err: true, msg: 'Este grupo se encuentra activo, por favor intenta crear otro grupo'});
        }
        else if( !val.grado && !val.grupo && !val.turno && !val.nivel )
        {
            return setStatus({...status, ok: false, err: true, msg: 'Rellene todos los campos del formulario'});
        }

        let db = firebase.database();
        let ref = db.ref(`schools`);
        let resultSearch = false;

        const promesa = new Promise((resolve, reject)=>{
            ref.orderByChild('key').equalTo(val.school.toString()).on('value', function(snapshot){
                snapshot.forEach(function(data){
                    if(data.exists()){
                        resolve(resultSearch = true)
                    }
                })
                if(resultSearch == false){
                    resolve(resultSearch = false);
                }
            })
        })
        .then(()=>{
            if(resultSearch){
                let db = firebase.database();
                let id_Grupo = `group${Math.floor(Math.random() * 100000)}`;
                let Usersref = db.ref(`schools/${val.school}/groups/${id_Grupo}`);
                Usersref.set({
                    key: key,
                    grade: val.grado,
                    group: val.grupo,
                    turn: val.turno,
                    level: val.nivel,
                    tipoGrupo:val.tipoGrupo
                }, function(error) {
                    if (error) {
                        // The write failed...
                        console.log(error)
                        setErrorSchoolNotFound(false)
                        setModalmsg('Problemas al crear grupo');
                        ModalOpen();
                    } else {
                        setChattoGruop(id_Grupo, val)
                        // Data saved successfully!
                        setStatus({...status, ok: true, err: false, msg: '¡Grupo Agregado!'})
                        setErrorSchoolNotFound(false)
                        clean();
                        setModalmsg('Grupo creado con éxito');
                        ModalOpen();
                    }
                });

                let ref = db.ref(`teacher/${val.school}/groups/details`);
                let listItem = [];
                ref.once('value').then( snapshot => {
                    if (  snapshot.val() ) {
                        snapshot.forEach( item => {
                            if( item.val().group !==  val.grupo ){
                                CheckAdmin( true, val )
                            } 
                            else{
                                CheckAdmin( false, val )
                            }
                        })  
                    }
                    else{
                        CheckAdmin( true, val )
                    }
                });
            }else{
                setErrorSchoolNotFound(true);
            }
        }).catch()
    }

    async function setChattoGruop( idGroup, val ) {   
        const arrayGroupinfo = {
            group: {
                grade: val.grado,
                group: val.grupo,
                key: idGroup, 
                turn: val.turno,
                level: val.nivel
            },
            status:{
                Active: 1,
                IsGroup: 1
            },
            lastUpdate: Date.now()
        }
        let ref = await firebase.database().ref("/chats").push();
        ref.set(arrayGroupinfo)
    }

    function CheckAdmin( pass, val ){
        try{
            let db = firebase.database();
            let ref = db.ref('Info/'+currentUser);
            ref.once('value').then((snapshot)=>{
                if(currentUser != ''){
                    AgregarGrupo(pass, val)
                }
            })
        }catch(error){
            setStatus({...status, ok: false, err: true, msg: 'Solamente usuarios de tipo administrador pueden hacer esta operación'})
        }finally{

        }
    }

    const AgregarGrupo = ( status, val ) => {

        if( status ) {
            let db = firebase.database();
            let GroupRef = db.ref(`schools/${val.school}/groups/details`);
            GroupRef.push({
                group: val.grupo,
            }, function(error) {
                if (error) { 
                    // The write failed...
                    console.log(error)
                    setStatus({...status, ok: false, err: true, msg: 'Ha ocurrido un problema al crear el grupo, por favor intenta de nuevo.'})
                } else {
                    // Data saved successfully!
                    setStatus({...status, ok: true, err: false, msg: '¡Grupo Agregado!' })
                    clean();
                }
            });
        }
        else{
            console.log( 'Grupo existente ');
        }
    }

    const clean = () => {
        
        setStatus({...status,
            ok: null,
            err: null, 
            msg: null
        })
    }

    useEffect(() => {
        schools()
        console.log('Current User: ', currentUser)
    }, [])

    const schools = async () => {
        var listschools = [];
        var ref = firebase.database().ref('schools');
        var data = await ref.once('value');
        var iterdata = Object.entries(data.val())
        for( const school of iterdata )
        {
            var info = school[1];
            listschools.push({
                name: info.name,
                key: school[0],
                img: info.avatar
            })
        }
        setSchoolSelector(listschools)
    }

    const validateForm = yup.object({
        school: yup
            .string('Seleccione una escuela')
            .required('La escuela es obligatoria'),
        tipoGrupo: yup
        .string('Seleccione una categoria para el grupo')
        .required('La categoria es obligatoria'),
        grado: yup
            .string('Seleccione un grado')
            .required('El grado es obligatorio'),
        turno: yup
            .string('Seleccione un turno')
            .required('El turno es obligatoria'),
        grupo: yup
            .string('Seleccione un grupo')
            .required('El grupo es obligatorio'),
        nivel: yup
        .string('Seleccione un nivel')
        .required('El nivel es obligatorio')
    });

    const initialFormState ={
        school: currentUser,
        tipoGrupo:'',
        grado: '',
        turno: '',
        grupo: '',
        nivel: ''
    }
    
    return (
        <Grid container >
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={validateForm}
            onSubmit={(values, {resetForm}) =>{
                handleForm(values)
                resetForm()
            }}
            >
                <Form>
                    <Grid item xs={12} >
                        <Box pb={3}>
                            <PageHeader 
                                title='Agregar grupos a escuela'
                                text="Crea gupos por escuela"
                                btn_back="/"
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} id="box_Admin_groups" borderRadius={16} className="kipperBox_content" mb={3} >
                            <Grid container >
                                <Grid item xs={12} >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Grid>
                                <Grid item xs={12} >
                                    <Box p={3}>
                                        <Grid container >
                                            { status.ok &&  <Box px={2} mb={2} > <Alert severity="success"> {status.msg} </Alert> </Box> }
                                            { status.err && <Box px={2} mb={2} > <Alert severity="error" > {status.msg} </Alert> </Box> }
                                            { errorSchoolNotFound && <Box px={2} mb={2} > <Alert severity="error"> 'No ha sido seleccionado ninguna escuela' </Alert> </Box> }
                                            <Grid item xs={12}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Escuela<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="school"
                                                        disabled
                                                    >
                                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value={currentUser} > { currentUser } </MenuItem>
{/* 
                                                        {
                                                            schoolSelector
                                                                ?schoolSelector.map(item => <MenuItem value={item.key} > { item.name } </MenuItem> )
                                                                :null
                                                        } */}
                                                        
                                                    </Select>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Tipo de Grupo<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="tipoGrupo"
                                                    >
                                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="Escolar">Escolar</MenuItem>
                                                        <MenuItem value="Deportivo">Deportivo</MenuItem>
                                                        <MenuItem value="Cultural">Cultural</MenuItem>
                                                        <MenuItem value="Artistico">Artistico</MenuItem>
                                                        <MenuItem value="Idiomas">Idiomas</MenuItem>
                                                        <MenuItem value="Otro">Otro</MenuItem>
                                                        
                                                    </Select>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Grado<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="grado"
                                                    >
                                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="Primero">Primero</MenuItem>
                                                        <MenuItem value="Segundo">Segundo</MenuItem>
                                                        <MenuItem value="Tercero">Tercero</MenuItem>
                                                        <MenuItem value="Cuarto">Cuarto</MenuItem>
                                                        <MenuItem value="Quinto">Quinto</MenuItem>
                                                        <MenuItem value="Sexto">Sexto</MenuItem>
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Grupo<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="grupo"
                                                    >
                                                        <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="A">A</MenuItem>
                                                        <MenuItem value="B">B</MenuItem>
                                                        <MenuItem value="C">C</MenuItem>
                                                        <MenuItem value="D">D</MenuItem>
                                                        <MenuItem value="E">E</MenuItem>
                                                        <MenuItem value="F">F</MenuItem>
                                                        <MenuItem value="G">G</MenuItem>
                                                        <MenuItem value="H">H</MenuItem>
                                                        <MenuItem value="I">I</MenuItem>
                                                        <MenuItem value="J">J</MenuItem>
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Nivel<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="nivel"
                                                    >
                                                        <MenuItem value=""><em>Seleccionar</em></MenuItem> 
                                                        <MenuItem value="Kinder">Kinder</MenuItem>
                                                        <MenuItem value="Primaria">Primaria</MenuItem>
                                                        <MenuItem value="Secundaria">Secundaria</MenuItem>
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box px={2} pb={2}> 
                                                    <InputLabel shrink> Turno<b className="asterisk-w">*</b> </InputLabel>
                                                    <Select 
                                                        name="turno"
                                                    >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                        <MenuItem value="Matutino">Matutino</MenuItem>
                                                        <MenuItem value="Vespertino">Vespertino</MenuItem>
                                                    </Select>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Form>
            </Formik>
        </Grid>
    )
}

export default CrearGrupos
