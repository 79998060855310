import React, {useState, useContext, useEffect} from 'react'
import { AuthContext } from '../../functions/Auth'
import * as firebase from 'firebase';
import {useHistory} from "react-router-dom";
//material-UI
import { Grid, Box, Button, Link, Typography  } from '@material-ui/core';
import { Chat, ArrowForwardIos } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//imagenes
import imgChild from '../../../Assets/Img/General/placeholder_boy.png';
import imgTutor from '../../../Assets/Img/General/placeholder_man.png';
import imgTeacher from '../../../Assets/Img/General/placeholder_teacher-m.png';
//Componentes
import Chatbody from '../ChatBody'
import style from '../../../Assets/Styles/chat.module.css'
import NavTeacherPhone from '../../../Pages/Maestros/NavTeacherPhone/NavTeacherPhone';

export default function Conversaciones() {
    const {currentUser, infoSchool  }    = useContext(AuthContext);
    const history           = useHistory();
    const [ refresh, setRefresh ]           = useState(0)
    const [ messages, setMessages ]         = useState(null);
    const [ conversation, setConversation ] = useState(null);
    const [ idConversation, setIDConversation ] = useState(null)
    const [ sender, setSender ] = useState(null)

    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);
    const ChatREF = firebase.database().ref('chats')

    useEffect(() => {
        if(idConversation){
            getRefresh()
        }else{
            getChats()
        }
    }, [refresh])

    async function getChats(){
        var listChats = [];
        var type = currentUser.split("_")
        var valores = await ChatREF.once('value')  
        valores.forEach(item => {
            let data = item.val()
            if( data[type[0]]?.key == currentUser ){
                if(data.school) {
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "school" })
                }
                else if(data.tutor) {
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "tutor" })
                }
                else if(data.child) {
                    listChats.push({ timestamp: data.lastUpdate, id: item.key, messages: data.messages, data: data, type: "child" })
                }
            }
        })
        if ( listChats.length > 0 ) {
            setMessages(listChats.sort(compare).reverse())
        }
        else{
            setMessages( false )
        }
    }

    async function getRefresh(){
        var data = await ChatREF.child(idConversation).once('value')
        setConversation(data.val().messages ?data.val().messages :null)
    }

    function compare(a, b) {
        return a.timestamp - b.timestamp;
    }

    const handleStep1 = () =>{
        setStep1(!step1)
        setStep2(!step2)
    }
    const handleSelectConversation = (mesages, id, info) =>{
        setStep1(!step1)
        setStep2(!step2)
        setConversation(mesages);
        setIDConversation(id)
        setSender(info)
    }
    const handleSender = (info) => 
    {
        setSender(info)
    }

    useEffect(() => {
        if(step1 && messages && refresh){
            console.log(messages)
            getChats()
        }
    }, [refresh, step1])

    return(
        <Grid xs={12}>
            { step2 
                ?<Grid container className="end_chat_contactList" direction="row"  alignItems="center">
                        <Chatbody information={idConversation} functionBack={handleStep1} messages={conversation} reload={setRefresh} user={sender}  />
                    </Grid>
                    :null
                }
                { step1 && 
                <Box className="kipperBox_content" boxShadow={3}>
                    <Grid container >
                        <Grid container className="end_chat_contactList Conversaciones__buttons Concersaciones__buttonsFix" direction="row"  alignItems="center">
                            <Box display="flex" className="ChatButtonDiv" id="ConversacionesMaestro__buttonHeader" py={3} >
                                <Button onClick={ e => history.push("/teacher/crearChat")} variant="outlined" className="kipper_btn-outline-new">Nueva Conversacion</Button>
                            </Box>
                            <Box display="flex"  py={3}>
                                <Button onClick={ e => history.push("/")} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={ 12 }>                    
                        {messages
                            ? messages.map(item => <ItemList id={item.id} data={item.data} messages={item.messages} type={item.type} selectConversation={handleSelectConversation} functionInfo={handleSender}/>)
                            : <span> <ErrorOutlineIcon /> Sin conversaciones.</span>
                        }
                        </Grid>
                    </Grid>     
                </Box>
            }
        </Grid>

    )
}

const ItemList = ({ id, data, type, selectConversation, functionInfo}) =>{

    const [information, setInformation]=useState(null)
    const { currentUser } = useContext(AuthContext);
    const [msg, setMsg] = useState(null)
    const [avatarPlaceholder, setavatarPlaceholder]=useState(null)
    const ChatREF = firebase.database().ref('chats')

    useEffect(() => {
        getInformation();
        refreshMessages();
    }, [data])

    async function getInformation () {
        var traslateType;
        switch (type) {
            case "child":
                traslateType = "childs";
                setavatarPlaceholder(imgChild)
                break;
            case "tutor":
                traslateType = "tutors";
                setavatarPlaceholder(imgTutor)
                break;
            case "school":
                traslateType = "schools";
                setavatarPlaceholder(imgTutor)
                break;
            default:
                traslateType = "teacher"
                setavatarPlaceholder(imgTeacher)
                break;
        }
        if(traslateType)
        {
            let refcheck = await firebase.database().ref(`${traslateType}/${data[type].key}`).once('value');
            setInformation(refcheck.val())
            functionInfo(refcheck.val())
        }
    }

    async function refreshMessages() {
        var infos = await ChatREF.child(id).once('value')
        setMsg(infos.val().messages ?infos.val().messages :null)
    }

    return(
        <Box px={3} py={2}>
            <Grid container onClick={ e => { selectConversation(msg, id,information) } } direction="row" justify="space-around" alignItems="center" spacing={2} className="conversacionesMaestro__hoverPointer">
                <Grid item xs={1}>
                    <Box borderRadius="50%" className="flex-container flex-center">
                        <img src={ information?.avatar ?information?.avatar :avatarPlaceholder } alt="" className={`${style.avatar_chat} ${style.avatar_chat_list}` }   />
                    </Box>
                </Grid>
                <Grid item xs={9} md={10}>
                    <p className="Kipper_title-black" style={{paddingLeft:'14px'}}>
                        { information?.name } {information?.lastname ?information?.lastname :information?.last_name}
                    </p> <br/> 
                </Grid>
                <Grid item xs={1} className="Conversaciones__Boxicon">
                    <ArrowForwardIos />
                </Grid>
            </Grid>
        </Box>
    )
}
