import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import {Typography, Box, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';

const HomeAdmin  = () => {

  const [list, setList] = useState([])

  useEffect( () =>{
    let db = firebase.database();
    let ref = db.ref(`schools`);
    let listItem = [];
    let count = 0;
    let flag = false;

    ref.once('value').then( snapshot => {
      if ( snapshot.val() ) {
        snapshot.forEach( item => {
          let itemInfo = item.val();

          if(count === 0)
            for (var clave in itemInfo){
              if(count === 0 && clave === 'details') flag = true;
              else break;
              count += 1;
            }

          if (!flag) listItem.push( itemInfo )
          flag = false;
        })
        setList( listItem );      
      } else{
        setList( listItem );      
      }
    });
  }, [] );

  return (
    <>
      <Grid container >
        <Grid item xs={12} >
          <Box m={2} p={3} >
            <Typography variant="h3" className="schoolInfo_title"> Panel de control Kiper </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} >
          <Box boxShadow={2} m={2} p={3} className="bg-white">
            <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-grey">
              <Box pl={3}>
                <Typography variant="h4" className="fw-500" > { list.length } </Typography>
                <Typography variant="h5" className="fw-200" > Escuelas </Typography>
              </Box>
              <Box>
                <i className="fas fa-school fa-5x kipper_txt-color opacity-05" />
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Box boxShadow={2} m={2} p={3} className="bg-white">
            <TableContainer >
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell > Nombre </TableCell>
                    <TableCell> Escuela ID </TableCell>
                    <TableCell> Telefono </TableCell>
                    <TableCell> Correo </TableCell>
                    {/* <TableCell> Telefono </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map( escuela => 
                    <TableRow key={ escuela.key } >
                      <TableCell component="th" scope="row"> {escuela.name} </TableCell>
                      <TableCell> { escuela.key } </TableCell>
                      <TableCell> { escuela?.contact?.phone } </TableCell>
                      <TableCell> { escuela?.contact?.email } </TableCell>
                      {/* <TableCell> { escuela?.contact?.phone } </TableCell> */}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default HomeAdmin;
