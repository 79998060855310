import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/File.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
//firebase
import * as firebase from 'firebase';
import { Avatar, Checkbox } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';

const marks = [
    {
        value: 0,
        label: '07:00',
    },
    {
        value: 7.6,
        label: '08:00',
    },
    {
        value: 15.2,
        label: '09:00',
    },
    {
        value: 22.8,
        label: '10:00',
    },
    {
        value: 30.4,
        label: '11:00',
    },
    {
        value: 38,
        label: '12:00',
    },
    {
        value: 45.5,
        label: '13:00',
    },
    {
        value: 53.2,
        label: '14:00',
    },
    {
        value: 60.8,
        label: '15:00',
    },
    {
        value: 68.4,
        label: '16:00',
    },
    {
        value: 76,
        label: '17:00',
    },
    {
        value: 86.6,
        label: '18:00',
    },
    {
        value: 91.1,
        label: '18:00',
    },
    {
        value: 100,
        label: '18:00',
    },
];

const GroupDetailsBySubjects = ( ) => {

    const history = useHistory();
    const match = useRouteMatch('/grupos/details/:id/:idS');
    const GroupID = match.params.id;
    const MateriaID = match.params.idS;

    const { info, currentUser }         = useContext(AuthContext);
    const [materiaInfo, setMateriaInfo] = useState([])
    const [maestroInfo, setMaestroInfo] = useState([])
    const [gruposInfo, setgruposInfo]   = useState([])
    const [maestro, setMaestro]         = useState('')
    const [maestroName, setMaestroName] = useState('');
    const [materia, setMateria]         = useState('')
    const [materiaTitle, setmateriaTitle]= useState('');
    const [materiaGrade, setmateriaGrade]= useState('');
    const [materiaDetails, setmateriaDetails]= useState('');
    const [classDay, setClassDay]       = useState([])
    const [schedule, setSchedule]       = useState([7.6, 22.8])
    const [errors, seterrors]           = useState('');
    const [Loading, setLoading]         = useState({
        maestro: false,
        materia: false,
        grupo: false,
    });
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    useEffect( () =>{
        let db = firebase.database();
        let Ref = db.ref(`schools/${currentUser}/groups/${GroupID}/school_subjects/${MateriaID}`);
        Ref.once('value').then(function(snapshot) {    
            setgruposInfo( snapshot.val() )
            setMaestro( snapshot.val().teacher_key )
            setMateria( snapshot.val().school_subjects_key )
            setClassDay( snapshot.val().days )
            setSchedule( snapshot.val().schedule )
            GetMaterias( snapshot.val().grade )
            setLoading({ ...Loading, grupo: true })
        })

        let Ref3 = db.ref(`teacher`);
        let listMaestros = [];
        Ref3.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                if ( itemInfo.status === "active"  && itemInfo.school === currentUser ) {
                    listMaestros.push( 
                        <item
                            key={ item.key }
                            name={ `${itemInfo.name} ${itemInfo.lastname} ` }
                        />
                    )
                }
            })
        })
        if( listMaestros > 0 ){
            setMaestroInfo( listMaestros )
        }
        else{
            setMaestroInfo( listMaestros )
        }
        setLoading({ ...Loading, maestro: true })

    }, [ ] );

    const GetMaterias = ( grupo ) => {
        let db = firebase.database();
        let Ref2 = db.ref(`school_subjects`);
        let listMaterias = [];
        Ref2.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                if (  itemInfo.status === "active"  && itemInfo.school === currentUser && itemInfo.grade == grupo ) {
                    listMaterias.push( 
                        <item
                            key={ item.key }
                            title={ `${itemInfo.title} ` }
                            grade={ `${itemInfo.grade} ` }
                            details={ `${itemInfo.details} ` }
                        />
                    )
                }
            })
        })
        setMateriaInfo( listMaterias )
        setLoading({ ...Loading, materia: true })
    }

    const handlesubmit = e =>{
        e.preventDefault();
        // console.log(currentUser.uid)
        // RegExp to check te fields 
        const regexname = RegExp(/\w/g);
        const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const regexphone = RegExp(/\d+[^a-zA-Z]{6,}/);
        const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

        if(maestro === "" ){ seterrors('Ingrese un Nombre porfavor'); }
        else if(materia === ""){ seterrors('Ingrese un Turno por favor'); }
        else if(classDay === ""){ seterrors('Ingrese un Grado por favor'); }
        else if(schedule === ""){ seterrors('Ingrese un Grado por favor'); }
        // else if(docProfile === ""){ seterrors('Ingrese un Avatar por favor'); }
        else {
            seterrors('');
            handleForm(e);
        }
    };
    const handleForm = e =>{
        e.preventDefault();
        
        let time_0 = marks.find(mark => mark.value === schedule[0]).label
        let time_1 = marks.find(mark => mark.value === schedule[1]).label
        let db = firebase.database();
        let Usersref = db.ref(`schools/${currentUser}/groups/${GroupID}/school_subjects/${materia}`);
        Usersref.update({
            school_subjects_key: materia,
            school_subjects_title: materiaTitle ? materiaTitle : '',
            school_subjects_details: materiaDetails ? materiaDetails : '',
            school_subjects_grade: materiaGrade ? materiaGrade : '',
            teacher_key: maestro,
            teacher_name: maestroName ? maestroName : '',
            days: classDay,
            schedule:{
                0: time_0,
                1: time_1,
            },
            school: currentUser,
        }, function(error) {
            if (error) {
              // The write failed...
                setModalmsg('Inténtalo de nuevo en unos minutos')
                setModalLink(`/grupos/informacion/${GroupID}`)
            } 
            else {
              // Data saved successfully!
                setModalmsg('Materia Agregada');
                setModalLink(`/grupos/informacion/${GroupID}`);
                ModalOpen();
            }
        });
    }
    const handleChangeCheckBox = ( e ) => {
        setClassDay({ ...classDay, [ e.target.name ]: e.target.checked })
    }
    const handleChangeMateria = ( e ) => {
        materiaInfo.forEach( item => {
            if ( `${item.key}` === `${e.target.value}` ) {
                console.log( item );
                setmateriaTitle( item.props.title )
                setmateriaDetails( item.props.details )
                setmateriaGrade( item.props.grade )
            }
        })
    }
    const handleChangeMestro = ( e ) => {
        maestroInfo.forEach( item => {
            if ( `${item.key}` === `${e.target.value}` ) {
                console.log( item );
                setMaestroName( `${item.props.name} ${item.props.lastname}` )
            }
        })
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={e => handlesubmit(e)} >
                <Grid container className="alumInfo_grid">
                    <Grid item xs={12} sm={12} >
                        <Box pb={3}>
                            <PageHeader
                                title="Agregar nueva materia"
                                text="Define informacion de la asignatura."
                                btn_back={`/grupos/informacion/${GroupID}`}
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="Form_add-content">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box p={2}>
                                            <InputLabel shrink id="materia">
                                                Materia 
                                            </InputLabel>
                                            <Select labelId="materia" value={ materia } onChange={e => { setMateria( e.target.value ); handleChangeMateria( e ); }} displayEmpty className="" fullWidth required >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                { 
                                                    materiaInfo ?
                                                        materiaInfo.map( item => <MenuItem value={item.key} key={item.key} ><em>{item.props.title}</em></MenuItem> )
                                                        : 
                                                        <MenuItem value=""><em>Sin materias para este grado</em></MenuItem>
                                                }
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box p={2}>
                                            <InputLabel shrink id="maestro">
                                                Teacher 
                                            </InputLabel>
                                            <Select labelId="maestro" value={ maestro } onChange={e =>{ setMaestro( e.target.value ); handleChangeMestro( e )}} displayEmpty className="" fullWidth required >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                {
                                                    maestroInfo ? 
                                                        maestroInfo.map( item => <MenuItem value={item.key} key={item.key} ><em> { item.props.name } { item.props.lastname } </em></MenuItem> )
                                                        : 
                                                        <MenuItem value=""><em>Sin maestros</em></MenuItem>
                                                }
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h5" className="" >
                                                Horario
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6" className="kipper_txt-grey" >
                                                Dia
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <FormControlLabel control={ <Checkbox checked={ classDay.monday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="monday"  /> } label="Lunes" />
                                            <FormControlLabel control={ <Checkbox checked={ classDay.tuersday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="tuersday" /> } label="Martes" />
                                            <FormControlLabel control={ <Checkbox checked={ classDay.wensday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="wensday" /> } label="Miercoles" />
                                            <FormControlLabel control={ <Checkbox checked={ classDay.tursday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="tursday" /> } label="Jueves" />
                                            <FormControlLabel control={ <Checkbox checked={ classDay.friday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="friday" /> } label="Viernes" />
                                            <FormControlLabel control={ <Checkbox checked={ classDay.saturday } onChange={ e => handleChangeCheckBox( e ) } color="primary" name="saturday" /> } label="Sabado" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6" className="kipper_txt-grey">
                                                Horario de clase
                                            </Typography>
                                            <Box px={3}>
                                                <Slider
                                                    value={ schedule }
                                                    marks={ marks }
                                                    step={ 7}
                                                    onChange={ (event, newValue) => setSchedule(newValue) }
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}

export default GroupDetailsBySubjects