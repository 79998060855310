import React, { useContext, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { AuthContext } from '../../../Components/functions/Auth'
import * as firebase from 'firebase';
import {useHistory} from "react-router-dom";
//material-UI
import { Grid, Box, Typography,  Button } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//Assets
import imgChild from '../../../Assets/Img/General/placeholder_boy.png';
import imgTutor from '../../../Assets/Img/General/placeholder_man.png';
import imgTeacher from '../../../Assets/Img/General/placeholder_teacher-m.png';
//Componentes
import Chatbody from '../ChatBody';
import {CreateConversation} from '../ChatFunctions';

import style from '../../../Assets/Styles/chat.module.css'
import NavEscuelaPhone from '../../../Pages/Escuela/NavEscuelaPhone/NavEscuelaPhone';
export default function SeleccionarChat() {
//Obtener informacion del context
    const { info, infoSchool, currentUser } = useContext(AuthContext);
    const history   = useHistory();
//Estados del componente
    const [ selection, setSelection ] = useState(null);
/* Estados de condicion del render */
    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);
    const [ step3, setStep3 ] = useState(false);
/* Estados de la conversacion */
    const [ users, setUsers ] = useState(null);
    const [ conversationID, setConversationID ] = useState(null)
    const [ conversation, setConversation ] = useState(null);
    const [ conversationType, setConversationType ] = useState(null);
    const [user, setuser] = useState(null)
    const [ sender, setSender ] = useState(null);
    const [ messages, setMessages ] = useState([])
    const [ chatRefres, setChatRefresh ]=useState(0);

//firebase  
/* Hacemos referencias a firebase para el llamado de la informacion */
    const Db = firebase.database();
    const refTeacher = Db.ref('teacher/');
    const refTutor = Db.ref('tutors/');
    const refChild = Db.ref('childs/');
    const chatRef = Db.ref('chats/');
/*Esta funcon se encarga de invertir los estados de step1 y step2
para su correcto render dependiendo de la situacion y las necesidades
del mismo usuario al elegir con quien desea conversar*/
    const handleStep1 = () =>{
        setStep1(!step1)
        setStep2(!step2)
    }
/*Esta funcion se encarga de invertir lo estados de step1 y step2, 
ademas limpia el etado de users para que al cambiar la vista el usuario no vea la lista
de usuario anteriormente seleccionada*/
    const handleStep2 = () =>{
        setStep2(!step2)
        setStep1(!step1)
        setUsers(null)
        setConversation(null)
        setConversationType(null)
    }
    const handleConversation = (id, data,user) =>{
        setuser(user)
        setStep2(!step2)
        setStep3(!step3)
        setConversation(id)
        getInformation(id, false, data)
        setSender(data)
    }
    const handleBack = () =>{
        history.push("/escuela/chat")
        setConversation(null)
    }
    /* Este useEffect carga al entrar al componente y busca que exista algo dentro del estado selection,
    si el estado selection tuviese algun dato, este llama a la funcion getUsers */
    useEffect(() => {
        if(selection)
        {
            getUsers();
        }
        /* Aqui se le implanta una variale al useEffect, para que este se aplique cada que esta variable tenga un cambio */
    }, [selection])
    
    async function getUsers()
    {
        /*
            selection
            1 = Teachers
            2 = Tutors
            3 = Childs
        */
        var listContacts = [];
        /* Se condiciona la accion de la funcion, dependiendo de el estado selection */
        if(selection == 1) 
        {
            /* Se usa la referencia refTeacher, para llamar la informacion con la funcion once de firebase */
            var info = await refTeacher.once('value')
            /* Se recorre la informacion obtenida con un forEach */
            info.forEach(item => {
                /* Se revisa que la informacion cumpla ciertos parametros para ser usada,
                en este caso que sea un suario activo y que pertenezca a la escuela */
                if(item.val().school === currentUser && item.val().status == "active") {
                    /* Si el usuario cumple todos la condicional, este es agregado a una lista dentro de un componente item */
                    listContacts.push(
                        <Itemlist 
                            img = {item.val().avatar ?item.val().avatar :imgTeacher }
                            name = {`${item.val().name} ${item.val().last_name ?item.val().last_name :item.val().lastname}`}
                            info = {item.val().contact.email}
                            btnTo = { handleConversation}
                            information = {item.key}
                            data = {item.val()}
                        />
                    )
                }
            })
            if ( listContacts.length > 0 ) {
                setUsers(listContacts)
            }
            else{
                setUsers( false )
            }
            setConversationType('teacher')
        }
        else if(selection == 2) 
        {
            var info = await refTutor.once('value')
            info.forEach(item => {
                if(item.val().school === currentUser  && item.val().status == "active")
                {
                    listContacts.push(
                        <Itemlist 
                            img = {item.val().avatar ?item.val().avatar :imgTutor}
                            name = {`${item.val().name} ${item.val().last_name ?item.val().last_name :item.val().lastname}`}
                            info = {item.val().contact.email}
                            btnTo = { handleConversation}
                            information = {item.key }
                            data = {item.val()}

                        />
                    )
                }
            })
            if ( listContacts.length > 0 ) {
                setUsers(listContacts)
            }
            else{
                setUsers( false )
            }
            setConversationType('tutor')
        }
        else if(selection == 3)
        {
            var info = await refChild.once('value')
            info.forEach(item => {
                if(item.val().school === currentUser && item.val().status == "active")
                {
                    listContacts.push(
                        <Itemlist 
                            img = {item.val().avatar ?item.val().avatar :imgChild}
                            name = {`${item.val().name} ${item.val().last_name ?item.val().last_name :item.val().lastname}`}
                            info = {`${item.val().details.grade} ${item.val().details.group} ${item.val().details.turn}`}
                            btnTo = { handleConversation }
                            information = {item.key}
                            data = {item.val()}
                        />
                    )
                }
            })
            if ( listContacts.length > 0 ) {
                setUsers(listContacts)
            }
            else{
                setUsers( false )
            }
            setConversationType('child')
        }
    } 
    useEffect(() => {
        getInformation(false, conversationID)
    }, [chatRefres])

    async function getInformation (id, chat_reload, data) 
    {
        var find = false;
        if(id) 
        {
            var typeid = id.split("_")
            var typetutor = id.slice(0,5)
            var infos = await chatRef.once('value')
            infos.forEach(item =>{
                var valor = item.val();
                if((valor[typeid[0]]?.key == id|| valor[typetutor]?.key == id) && valor[info.type]?.key == currentUser )
                {
                    setConversationID(item.key)
                    setMessages(valor['messages'])
                    find = true
                }
            
            })
            if(!find && data) 
            {
                var key_receiver = data.id ? data.id : data.teacher_key;
                var type;
                if(data?.id) 
                {
                    type = data?.id.split("_");
                }
                else 
                {
                    type = data?.teacher_key.split("_")
                }
                var tutorcheck;
                if(type.length == 2) 
                {
                    tutorcheck = type[0]
                }else {
                    tutorcheck = "tutor"
                }
                var arraySender = {
                    key: infoSchool.key,
                    avatar: infoSchool.avatar,
                    name: infoSchool.name,
                    type: info.type
                }
                var arrayReceiver = {
                    key: key_receiver,
                    avatar: data.avatar,
                    name: `${data.name} ${data.lastname ?data.lastname :data.last_name}`,
                    type: tutorcheck
                }
                createChat(arraySender, arrayReceiver);
            }
        }
        else if(chat_reload) 
        {
            var infos = await chatRef.child(chat_reload ? chat_reload : conversation).once('value')
            setMessages(infos.val()?.messages)
        }
    }

    async function createChat (sender, receiver) 
    {
        let db = firebase.database();
        let CHATS_REF = db.ref().child('/chats/');

        var arrayChat = {
            lastUpdate: Date.now()
        };

        arrayChat[sender.type] = {
            avatar: sender.avatar,
            key: sender.key,
            name: sender.name
        };

        arrayChat[receiver.type] = {
            avatar: receiver.avatar,
            key: receiver.key,
            name: receiver.name
        };
        let push_ = await CHATS_REF.push(arrayChat);
        setConversationID(push_.key)
        getInformation(false, push_.key);
    }

    async function checkChat ( user1, user2 )
    {
        let db = firebase.database().ref('chats/');
        let info = await db.once('value');
        var listChats = false;
        info.forEach( item => {
            let data = item.val();
            if(data[user1] && data[user2]){
                listChats = true;
            }
        });
        return listChats
    }

    return (
        <Grid xs={12}>
            {/* <NavEscuelaPhone /> */}
            <Box>
{/* Se condiciona el render de esta parte dependiendo si existe o si es true la variable step1 */}
            {
                step1 && 
                <Grid container  className="kipperBox_content" boxShadow={3} > 
                        <Grid container className="end_chat_contactList SeleccionarChat__HeaderButton" direction="row"  alignItems="center">
                            <Box display="flex" py={3} pr={2.5}>
                                <Link to="/escuela/chat">
                                    <Button variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                                </Link>
                            </Box>
                        </Grid>                  
                        <Grid container xs={12} md={12} >
                            <Grid className="clickeable_class conversacionesSchool__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => {handleStep1(); setSelection(1)}} >
                                <Box display="flex" className="clickeable_class" px={2} py={4}  >
                                    <Box ml={2}>
                                        <Typography variant="h4" className="kipper_txt-grey" >
                                            Maestro
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box px={2} py={4} className="SeleccionarChat__Boxicon">
                                    <ArrowForwardIos />
                                </Box>
                            </Grid>
                            <Grid className="clickeable_class conversacionesSchool__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => {handleStep1(); setSelection(2)}}>
                                <Box display="flex" className="clickeable_class" px={2} py={4}  >
                                    <Box ml={2}>
                                        <Typography variant="h4" className="kipper_txt-grey" >
                                            Tutor
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box px={2} py={4} className="SeleccionarChat__Boxicon" >
                                    <ArrowForwardIos />
                                </Box>
                            </Grid>
                            <Grid className="clickeable_class conversacionesSchool__hoverPointer" container py={3} direction="row" justify="space-between" alignItems="center" onClick={e => {handleStep1(); setSelection(3)}}  >
                                <Box display="flex" className="clickeable_class" px={2} py={4} >
                                    <Box ml={2}>
                                        <Typography variant="h4" className="kipper_txt-grey" >
                                            Alumno
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box px={2} py={4} className="SeleccionarChat__Boxicon" >
                                    <ArrowForwardIos />
                                </Box>
                            </Grid>
                        </Grid>
                </Grid> 
            }
{/* Se condiciona el render de esta seccion dependiendo de la variable step2 */}
            {
                step2 
                ?<Grid container   className="kipperBox_content" boxShadow={3}>
                    <Grid container className="end_chat_contactList" direction="row"  alignItems="center">
                        <Box display="flex" py={3} >
                            <Button onClick={e => handleStep2()} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={ 12 }>
                        {( users )
                            ? users
                            : <Box mx={1} > <ErrorOutlineIcon /> Sin contactos. </Box>
                        }
                    </Grid>
                </Grid>
                :null
            }
            {
                step3
                ?<Chatbody user={sender} information={conversationID} messages={messages} type={conversationType} userType="school" functionBack={handleBack} valrefresh={chatRefres} reload={setChatRefresh}/>
                :null
            }
            </Box>
        </Grid>
    )
}
/* Este es un componente usado para enlistar todos los usuarios */
const Itemlist = ({ img, name, info, btnTo, information, data }) =>{
    return(
        <Box p={2}>
            <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                <Grid item xs={2} >
                    <Box>
                        <img src={ img } alt="" className="avatar_chat" />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <span className="Kipper_title-listChat">
                        { name } 
                    </span> <br/> 
                    <span className="Kipper_email-list">
                        { info }
                    </span>
                </Grid>
                <Grid xs={4}>
                    <Box  py={4} px={1}>
                        <Button className="kipper_button" style={{marginRight:'50px'}} variant="outlined" onClick={e => btnTo(information, data)} fullWidth>Iniciar Chat</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
