import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal';
//img
import portada from '../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/File.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
//firebase
import * as firebase from 'firebase';
import { Avatar, FormHelperText } from '@material-ui/core';
import { checkFilepdf } from '../../Components/functions/helpapers';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

function MateriasAgregar() {
    const history           = useHistory();
    const match             = useRouteMatch('/escuela/agregar/materias/:id/:nivel');
    const GradeID           =  match.params.id;
    const level           =  match.params.nivel;
    const { currentUser }   = useContext(AuthContext);

    const [docOpcional, setDocOpcional] = useState('');
    const [ChangeOpcional, setChangeOpcional] = useState(false);

    const { info } = useContext(AuthContext);
    const [title, setTitle] = useState('')
    const [grade, setgrade] = useState( GradeID );
    const [about, setAbout] = useState('')
    const [nivel, setNivel] = useState( level );
    const [GruposList, setGruposList] = useState([]);

    const [titleError, setTitleError] = useState(false);
    const [titleErrorText, setTitleErrorText] = useState('');

    const [aboutError, setAboutError] = useState(false);
    const [aboutErrorText, setAboutErrorText] = useState('');

    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`schools/${currentUser}/groups`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    listItem.push(
                        <item
                            key={ item.key }
                            grade={ `${itemInfo.grade}` }
                        />
                    )
                })
                setGruposList( listItem );      
            }
            if (  snapshot.val() == [] ) {
                setGruposList( false );
            }
            if ( snapshot.val() == null){
                setGruposList( false );
            }
        });
    }, [] )

    const handlesubmit = e =>{
        e.preventDefault();
        resetError();

        if(validarFormulario())
            handleForm(e);
    };

    const validarFormulario =()=>{
        resetError();
        if(title.length != 0 && about.length != 0){//Validar que no esten vacios los campos
            if( (title.length <= 20 && title.length >= 5) && (about.length <= 200 && about.length >= 5) ){//Validar los rangos
                return true;
            }else{
                if(title.length > 20 || title.length < 5){
                    setTitleError(true);
                    setTitleErrorText('El titulo debe tener entre 5 a 20 caracteres');
                }
                            
                if(about.length > 200 || about.length < 5){
                    setAboutError(true);
                    setAboutErrorText('La descripción debe tener entre 5 a 200 caracteres');
                }                                
            }
        }else{
            if(title.length == 0){
                setTitleError(true);
                setTitleErrorText('Debe ingresar el titulo');
            }
            if(about.length == 0){
                setAboutError(true);
                setAboutErrorText('Debe ingresar la descripción');
            }  

        }

        return false;
    }
    
    const resetError =()=>{
        setTitleError(false);
        setTitleErrorText('');
        setAboutError(false);
        setAboutErrorText('');
    }

    const handleForm = e =>{
        e.preventDefault();
        const opcionalIMG =  docOpcional && document.getElementById('opcionalIMG').href;
        let id = `subjects_${Date.now()}`;
        let db = firebase.database();
        let Usersref = db.ref(`school_subjects/${id}`);
        Usersref.set({
            id: id,
            school: currentUser,
            title: title,
            details: about,
            status: 'active',
            type: 'school_subjects',
            level: nivel.toLowerCase(),
            grade: grade,
            documents:{
                temary: opcionalIMG
            }
        }, 
        (error) => {
            if (error) {
              // The write failed...
                setModalmsg('Inténtalo de nuevo en unos minutos')
                setModalLink(`/escuela/materias/${GradeID}/${level}`)
            } 
            else {
                // Data saved successfully!
                setModalmsg('Información guardada');
                setModalLink(`/escuela/materias/${GradeID}/${level}`);
                ModalOpen();
                GruposList.forEach( item => {
                    if ( item.props.grade === grade && item.props.level === nivel) {
                        let GroupRef = db.ref(`schools/${currentUser}/groups/${item.key}/school_subjects/${id}`);
                        GroupRef.update({
                            key: id,
                            title: title,
                            details: about,
                            grade: grade,
                            level:nivel,
                            documents:{
                                temary: opcionalIMG
                            }
                        }, (error) => {
                            if (error) {
                                // The write failed...
                                setModalmsg('Inténtalo de nuevo en unos minutos')
                                setModalLink(`/escuela/materias/${GradeID}/${level}`)
                            } 
                            else {
                                // Data saved successfully!
                                setModalmsg('Información guardada');
                                setModalLink(`/escuela/materias/${GradeID}/${level}`);
                                ModalOpen();
                            } 
                        });
                    }
                })
            }
        });

    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();

        let doc = e.target.files[0];

        const fileIsValid = await checkFilepdf( doc );
        if ( fileIsValid ) {
            try {
                setChangeOpcional( true )
                setDocOpcional( doc );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school_subjects/documents/Img${Date.now()}`);
                upload.put( doc ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        // const profileDocIcon = document.getElementById("opcionalIMGIcon");
                        // profileImg.src = url;
                        profileImg.href = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg Y pdf' });
        }
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={e => handlesubmit(e)} >
                {/* <NavEscuelaPhone /> */}
                <Grid container>
                    <Grid item xs={12} sm={12} >
                        <Box pb={3}>
                            <PageHeader
                                title="Agregar nueva materia"
                                text="Define informacion de la asignatura"
                                btn_back="/escuela/lista/materias"
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="Form_add-content">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="turno">
                                                Título  <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField
                                                inputProps={{ minLength: 5, maxLength: 20 }}
                                                required  
                                                error={titleError}
                                                helperText={titleErrorText}
                                                value={title} 
                                                onChange={e => setTitle(e.target.value)} 
                                                size="small" 
                                                fullWidth 
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="materia">
                                                Nivel educativo 
                                            </InputLabel>
                                            <Select disabled labelId="materia" value={nivel} onChange={e => setNivel(e.target.value)} displayEmpty fullWidth required >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Kinder">Kinder</MenuItem>
                                                <MenuItem value="Primaria">Primaria</MenuItem>
                                                <MenuItem value="Secundaria">Secundaria</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4} >
                                        <Box p={2}>
                                            <InputLabel shrink id="materia">
                                                Materia para: 
                                            </InputLabel>
                                            <Select disabled labelId="materia" value={grade} onChange={e => setgrade(e.target.value)} displayEmpty fullWidth required >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Primero">Primero</MenuItem>
                                                <MenuItem value="Segundo">Segundo</MenuItem>
                                                <MenuItem value="Tercero">Tercero</MenuItem>
                                                <MenuItem value="Cuarto">Cuarto</MenuItem>
                                                <MenuItem value="Quinto">Quinto</MenuItem>
                                                <MenuItem value="Sexto">Sexto</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6">
                                                Acerca de la materia  <b className="asterisk-w">*</b>
                                            </Typography>
                                        </Box>
                                        <Box p={2} >
                                            <TextareaAutosize
                                                className="text_area-style"
                                                value={about} 
                                                onChange={e => setAbout(e.target.value)} 
                                                rowsMin={4}
                                                required
                                                placeholder="Descripción aquí" 
                                            />
                                            <FormHelperText error={aboutError}>{aboutErrorText}</FormHelperText>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="subtitle1">
                                                Temario
                                                {
                                                    docOpcional&&<a href="" id={'opcionalIMG'} target="_blank"><VisibilityIcon style={{fill: "black",fontSize:20,marginLeft:10,verticalAlign:"middle"}} className="kipper_txt-grey" /></a>
                                                }
                                            </Typography>
                                            <Box p={2}>
                                                {/* <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} /> */}
                                                <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={e => uploadDocOpcional( e ) } />
                                                <label htmlFor="uploadFile" className="kipper_txt-color pointer">
                                                    <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                        <img className="imW" src={upload} alt="" />
                                                        { docOpcional 
                                                            ? <Typography id="kipper_txt_dragDropDocArea" className="kipper_txt-color txtHw">{ docOpcional.name }</Typography>
                                                            : <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                        }
                                                    </Grid>
                                                </label>
                                                { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                <Typography className="" variant="body1" display="block" gutterBottom>
                                                    <b>*Los documentos deben ser de tipo jpg, jpeg, png o pdf.</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}

export default MateriasAgregar