// import  './../../Assets/Styles/Responsive.css'
import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/Academy.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

function SchoolSubjects () {
    const { currentUser, userType, info, infoSchool }   = useContext(AuthContext);
    const history = useHistory();
    const match = useRouteMatch('/teacher/evaluaciones/group/:group');
    const subjectsID = match.params.group;
    
    const [ list, setList ] = useState([]);
    const [ isShow, setIsShow ]         = useState( false );
    const [ tutorError, setTutorError ] = useState('');
    const [error,setError] = useState(false);
    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect(() => {
        if(infoSchool)
        {
            getInformation()
        }
    },[infoSchool]);

    async function getInformation () {
        let listItem = [];
        let data = await firebase.database().ref(`schools/${infoSchool.school}/groups/${subjectsID}/school_subjects`).once('value');
        if(data?.val()) {
            let subjects = Object.entries(data.val());
            for( const subject of subjects)
            {
                let data2 = await firebase.database().ref(`school_subjects/${subject[0]}`).once('value');
                let item = data2.val();
                if(item && subject[1]?.teacher_key == currentUser)
                {
                    listItem.push(
                        <item
                        key={ subject[0] }
                        name={ item.title}
                        info={ `${item.grade}` }
                        img={user}
                        link={ `/teacher/evaluaciones/details/${subjectsID}/${subject[0]}` }
                        details={ `/teacher/evaluaciones/details/${subjectsID}/${subject[0]}` }
                        time={``}
                        error={false}
                        />
                    )
                }
            }
            setList( listItem );                  
            if (  data.val() == [] ){ setList( false ); }
            if (  data.val() == null ){ setList( false ); }
            if(listItem.length == 0)
            { 
                listItem.push(
                    <box name="NO SE ENCONTRO PARCIALES" error={true} />
                ) 
                setList(listItem);
                setError(true);
            }
        }
        else
        {
            listItem.push(
                <box name="NO SE ENCONTRO PARCIALES" error={true} />
            )
            setList(listItem);
            setError(true);
        }
    }

    return(
        <Grid container className="padres_grid materiasB21">
            {/* <NavTeacherPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isShow ?
                            <ModalShow
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3} mx={3}>
                    <PageHeader
                        title="Materias"
                        text="Mira las evaluaciones de tus alumnos"
                        btn_back="/teacher/evaluaciones/group"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    <ShowContracts
                        error={error}
                        list={list}
                        isEvaluation={ true }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default SchoolSubjects
