import React,{useState,useEffect} from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

//Components
import PageHeader from '../../Components/Global/PageHeader';
import { Link } from 'react-router-dom';

//Style
import '../../Assets/Styles/Items/scheduleItem.css';

//Firebase
import * as firebase from 'firebase';


const Schedule =()=>{

    const [schools,setSchools] = useState('loading');

    useEffect(()=>{
        getSchools();
    });

    const getSchools =async()=>{
        let db = firebase.database();
        let schoolsRef = db.ref(`schools`);
        let list = [];

        await schoolsRef.once('value').then(function(snapshot) {
            let item = snapshot.val();
            list = [];

            for (var clave in item){
                if (item.hasOwnProperty(clave) && clave !== 'groups') {
                    list.push(
                        <item
                            key={clave}
                            name={item[clave].name ? item[clave].name:null}   
                        />
                    )
                }
            }
        });
        setSchools(list);
    }

    return(
        <Grid container className="schedule__main">
            <Grid item xs={12} >
                <Box pb={3}>
                    <PageHeader 
                        title="Horarios de escuelas"
                        text="Selecciona la escuela que deseas ver"
                        btn_back="/"
                        btn_action={false}
                    />
                </Box>
            </Grid>
            <Grid container>
                { (schools && schools === 'loading') && <h4>Cargando...</h4> }
                {
                    (schools && schools.length > 0 && schools !== 'loading') ?
                    <>
                        {schools && schools.map((school)=>(
                            <Grid item xs={6} key={school.key} >
                                <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                    <Link to={`/admin/horarios/${school.key}`}>
                                        <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                            <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <Typography variant="h4" className="fw-500" > {school.props.name} </Typography>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Box>
                            </Grid> 
                        ))}
                    </>:schools && schools !== "loading" && <h4>No hay escuelas por el momento</h4>
                }
            </Grid>
        </Grid>
    );
}

export default Schedule;