import React,{ useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../functions/Auth';

//Components
import SchoolInformation from '../../../Components/Global/SchoolInformation';

//db
import * as firebase from 'firebase';

const HeaderSchoolInfo =()=>{
    const {  info, userType, currentUser, infoSchool, handlenotif } = useContext(AuthContext);

    const [ nombre, setNombre ]     = useState('');
    const [ address, setAddress ]   = useState('');
    const [ phone, setPhone ]       = useState('');
    const [ logo, setLogo ]         = useState('');

    useEffect(() =>  handleload(),[infoSchool]);

    const handleload = () => {
        let db = firebase.database();
        var Schoolref;
        if(userType === "school"){
            Schoolref = db.ref(`schools/${currentUser}`);
            Schoolref.once('value').then(function(snapshot) {
                if(snapshot.val()){
                    setNombre(snapshot.val().name);
                    setPhone(snapshot.val().contact.phone);
                    setAddress(snapshot.val().location.address);
                    setLogo(snapshot.val().avatar)
                }
            });
        }else if (userType === "teacher"){
            try{
                if(infoSchool){
                    Schoolref = db.ref(`schools/${infoSchool.school}`);
                    Schoolref.once('value').then(function(snapshot) {
                        if(snapshot.val()){
                            setNombre(snapshot.val().name);
                            setPhone(snapshot.val().contact.phone);
                            setAddress(snapshot.val().location.address);
                            setLogo(snapshot.val().avatar)
                        }
                    });
                }
            }finally{
                if(infoSchool){
                    handlenotif(userType, infoSchool.school)
                }
            }
        }else if (userType === "tutor"){
            try{
                Schoolref = db.ref(`tutors/${currentUser}`);
                Schoolref.once('value').then(function(snapshot) {
                    if(snapshot.val()){
                        setNombre(snapshot.val().name);
                        setPhone(snapshot.val().contact.phone);
                        setAddress(snapshot.val().location.address);
                        setLogo(snapshot.val().avatar)
                    }
                });
            }
            catch (error){
                console.log(error)
            }
        }else if ( userType === "child"){
            try{
                Schoolref = db.ref(`schools/${infoSchool}`);
                Schoolref.once('value').then(function(snapshot) {
                    if(snapshot.val()){
                        setNombre(snapshot.val().name);
                        setPhone(snapshot.val().contact.phone);
                        setAddress(snapshot.val().location.address);
                        setLogo(snapshot.val().avatar)
                    }
                });
            }
            catch (error){
                console.log(error)
            }
        }
    }
    return <SchoolInformation logo={ logo } name={nombre} address={address} phone={phone} edit_link="/sede" />
}

export default HeaderSchoolInfo;