import React, { useState, useContext, useEffect  } from 'react'
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal'
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import user from './../../Assets/Img/General/placeholder_boy.png';
// meterialui
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, Typography } from '@material-ui/core';

// FireBases
import * as firebase from 'firebase';
import moment from 'moment';

const AppoimentsDetails = ( { title, event_info, title_event, body_event } ) => {

    const history       = useHistory();
    const match         = useRouteMatch('/teacher/citas/detalles/:id');
    const AppoimentID  = match.params.id;
    const { infoSchool }               = useContext(AuthContext);
    const db = firebase.database();
    const [appoimentInfo, setAppoimentInfo] = useState('')
    const [refresh, setRefresh ] = useState(0);
    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ reason, setReason ] = useState(null);
    const [ reasonRender, setReasonRender ] = useState(false);

    const [reasonValue, setReasonValue] = useState("");
    const [value, setValue] = useState('');
    const [modalStatusMessage, setModalStatusMessage] = useState("")


    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    useEffect(() => {
        let ref = db.ref(`comunicados/${AppoimentID}`);
        ref.once('value').then(function(snapshot) {
            if ( snapshot.val() ) {
                setAppoimentInfo( snapshot.val() )
            }
            else {
                setAppoimentInfo( false )
            }
        });
    }, [ refresh ]);

    function citasnotif(confirmation){
        if(confirmation === 1){
            let ref = db.ref('notifications')
            var userList = [];
            userList.push({key: appoimentInfo.tutor.tutor, status: 0})
            ref.push({
                body: `El maestro ${infoSchool.name} ${infoSchool.lastname} ha aceptado la cita`,
                date: new Date().getDate(),
                school_key: infoSchool.school,
                time: new Date().getTime(),
                title: "Evaluacion Actualizada" ,
                type: "tutor" ,
                userList
            }, function(error) {
                if (error) { 
                    // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                }
            });
        }else if(confirmation === 2 && reason){
            let ref = db.ref('notifications')
            var userList = [];
            userList.push({key: appoimentInfo.tutor.tutor, status: 0})
            ref.push({
                body: `El maestro ${infoSchool.name} ${infoSchool.lastname} ha declinado la cita`,
                date: new Date().getDate(),
                school_key: infoSchool.school,
                time: new Date().getTime(),
                title: "Evaluacion Actualizada" ,
                type: "tutor" ,
                reason: reason,
                userList
            }, function(error) {
                if (error) { 
                    // The write failed...
                    console.log(error)
                } else {
                    // Data saved successfully!
                }
            });
        }else if(confirmation === 2 && !reason){
            setReasonRender(true);
        }
        
    }

    const handleChangeReason = (e) =>{
        e.preventDefault();
        setReasonValue(e.target.value)
    }

    const changeAppoimentStatus = (e, status) =>{
        e.preventDefault();
        let ref = db.ref(`comunicados/${AppoimentID}`);

        if(e.target.id === "aceptarAppoiment" && status){
            ref.update({ confirm: 1 })
            ModalClose()
            history.push('/teacher/citas')
        }
        else if(e.target.id === "rechazarAppoiment" && status){
            ref.update({
                confirm: 2,
                reason: reasonValue
            })
            ModalClose()
            setRefresh( refresh + 1 )
        }
    }

    const handleChange = (value) =>{
        setValue(value);
        setModalStaus(true)
        if(value === "aceptada"){
            setModalStatusMessage("¿Estás de acuerdo con aceptar la cita?")
        }else{
            setModalStatusMessage("Escribe la razón por la cual no podrás asistir a la cita")
        }
    };

    return(
        <Grid container>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {value === "aceptada"
                        ? <ModalShow 
                                title={modalStatusMessage}
                                confirmOperation={ true }
                                Operation={changeAppoimentStatus}
                                onClose={ ModalClose }
                            />
                        : <ModalShow 
                                title={modalStatusMessage}
                                rechazarAppoiment={ true }
                                handleReason={handleChangeReason}
                                Operation={changeAppoimentStatus}
                                onClose={ ModalClose }
                            />
                    }     
                </Grid>
            </Modal>
            <Grid xs={12} >
                <Box pb={3}  className="PageHeader_toRight" >
                    <PageHeader 
                        title="Cita"
                        text="Ve detalles, aprueba o rechaza cita con tutores"
                        btn_back="/teacher/citas"
                    />
                </Box>
            </Grid>
            <Grid xs={12} className="Form_add-content kipper_box box_w">
                <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3} >
                    <Grid container >
                        <Grid item xs={12} >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Grid>
                        <Grid item xs={12} >
                            <Box p={3} >
                                <Grid container >
                                    <Grid item xs={12} >
                                        <Typography variant="h4" className="kipper_title">
                                            <b> {appoimentInfo?.name } </b>
                                        </Typography>
                                        {/* { appoimentInfo.confirm === 2 && "( Denegada )" } */}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} className="AppoinmentsDetails__TutorName" >
                                        <Typography variant="h6" className="kipper_txt-grey">
                                            {appoimentInfo?.tutor?.tutor_name }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} >
                                        <Typography variant="h6" className="kipper_txt-grey AppoinmentsDetails__Date" align="right">
                                            {
                                                appoimentInfo.date&&(
                                                    moment(appoimentInfo.date).format('LL')
                                                )
                                            }
                                            {
                                                appoimentInfo.time&&(
                                                    ` a las ${appoimentInfo.time}`
                                                )
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Box my={2}  >
                                            <Typography variant="h5" className="kipper_txt-grey" >
                                                <b> Detalles de cita</b>
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1" className="kipper_txt-grey">
                                            {` ${appoimentInfo?.details_event}` } 
                                        </Typography>
                                        <Box my={1} display="flex"  >
                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                <b>Maestro: &nbsp;</b>
                                            </Typography>
                                            <Typography variant="body1" className="kipper_txt-grey">
                                                {` ${appoimentInfo?.teacher?.teacher_name} ` } 
                                            </Typography>
                                        </Box>
                                        <Box my={1} display="flex"  >
                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                <b>Alumno: &nbsp;</b>
                                            </Typography>
                                            <Typography variant="body1" className="kipper_txt-grey">
                                                {` ${appoimentInfo?.student?.student_name} ` } 
                                            </Typography>
                                        </Box>
                                        <Box mb={2}  display="flex"  >
                                            <Typography variant="body1" className="kipper_txt-grey" >
                                                <b>Grupo: &nbsp;</b>
                                            </Typography>
                                            <Typography variant="body1" className="kipper_txt-grey">
                                                {`${appoimentInfo?.details?.grade} ${appoimentInfo?.details?.group}  ${appoimentInfo?.details?.turn} `  } 
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    { appoimentInfo.receiver_event === "appointment" && appoimentInfo.confirm === 0 ?
                                            <Box mb={2}  display="flex"  >
                                                <Grid item xs={12} >
                                                    <Button 
                                                        variant="outlined" 
                                                        className="kipper_btn-outline-black btn_size"
                                                        onClick={e => handleChange("rechazada")}
                                                    > 
                                                        Denegar 
                                                    </Button>
                                                    <Button 
                                                        variant="contained" 
                                                        className="kipper_button btn_size confirmAppointment" 
                                                        disableElevation
                                                        onClick={e => handleChange("aceptada")}
                                                    > 
                                                        Aceptar 
                                                    </Button>
                                                </Grid>
                                            </Box>
                                            
                                            : appoimentInfo.confirm === 1?
                                            <Box mb={2}  display="flex"  >
                                                <Typography variant="body1" className="color-green" >
                                                    <b>Esta cita ya fue aceptada</b>
                                                </Typography>
                                            </Box>
                                            :appoimentInfo.confirm === 2?
                                            <Grid>
                                                <Box mb={2}  display="flex"  >
                                                    <Typography color="error" variant="body1" className="kipper_txt-grey" >
                                                        <b>Razón de la negación: &nbsp;</b>
                                                    </Typography>
                                                    <Typography  variant="body1" className="kipper_txt-grey">
                                                        {`${appoimentInfo?.reason || "no hay razon"}`  } 
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            :
                                            <div></div>
                                        }
                                    
                                </Grid>
                                { reasonRender
                                    ? <Grid id="reasondiv" xs={12} pt={2} className="reasonInput" > 
                                        <TextField label="Razón" value={reason} onChange={e => setReason(e.target.value)} />
                                    </Grid>
                                    : null
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AppoimentsDetails
