import React, { useState, useContext, useEffect  } from 'react';
import { Link, useHistory, useRouteMatch  } from 'react-router-dom';
import { AuthContext } from "./../Components/functions/Auth";
//Components
import PageHeader from './../Components/Global/PageHeader';
import ShowContracts from './../Components/Global/ShowContracts';
import ModalShow from '../Components/Global/Modal';
//img
import user from './../Assets/Img/General/placeholder_teacher-m.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';

function ContactoReferencia () {
    const history   = useHistory();
    const match     = useRouteMatch('/referencias/:id');
    const childID   = match.params.id;
    const { currentUser }   = useContext(AuthContext);
    const [ refresh, setRefresh ]   = useState( 0 );


    const [ isSelected, setIsSelected ] = useState( false );
    const [ keyTutor, setkeyTutor ]     = useState("");
    const [ tutorID, setTutorID ]       = useState('');
    const [ list, setList ]             = useState([]);    
    const [ tutorError, setTutorError ] = useState('');
    const [ tutorUpdate, setTutorUpdate ]= useState(false);
    const [tutorRepeated, setTutorRepeated] = useState(false);
    const [avanzar, setAvanzar] = useState(false);
    //Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    function refreshPage() {
        window.location.reload(false);
    }
    
    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        setIsDeleted( true );
        setIsConfirm( false );
        setIsShow( false );
        setTutorID( id );
        ModalOpen();
    }

    const onSelectSubmit = id => {
        setIsSelected( true );
        setkeyTutor( id );
    }

    const onRemoveSelected = () => {
        setIsSelected( false )
    }

    const deleteTutor = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let tutorRef = db.ref(`tutors/${tutorID}`);
            tutorRef.update(
                { status: "inactive", }, 
                (error) => {
                    if (error) { 
                        setIsDeleted( false );
                        setIsShow( true );
                        setIsConfirm( false );
                        setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                        ModalOpen();
                    } 
                    else {
                        setIsDeleted( false );
                        setIsShow( true );
                        setIsConfirm( false );
                        setModalmsg( "Tutor eliminado." )
                        ModalOpen();
                        setRefresh( refresh + 1 )

                    }
                }
            );
        }
        else{
            ModalClose()
        }
    }

    const getTutorList = ( path ) => {
        let db = firebase.database();
        let ref = db.ref( path );
        let refUpdate = db.ref( `${path}/childs` );
        ref.once('value').then(function(snapshot) {
            if (  snapshot.val() == [] ) {
                setList( false );   
            } 
            if (  snapshot.val() == null) {
                setList( false );   
            }
            let info = snapshot.val().childs;
            let isUpdate = false;
            if ( info.child_1 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_1: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true)  }
                    }
                );
            }
            if ( info.child_2 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_2: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_3 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_3: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_4 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_4: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_5 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_5: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_6 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_6: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_7 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_7: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_8 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_8: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_9 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_9: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
            if ( info.child_10 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_10: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                        else { setTutorUpdate(true) }
                    }
                );
            }
        });
        
    }

    const ConfirmOperation = ( e ) =>{
        e.preventDefault();
        if ( isSelected ) {
            setIsShow( false );
            setIsConfirm( true )
            setIsDeleted( false );
            ModalOpen();
        }
        else{
            setIsShow( true );
            setIsConfirm( false );
            setIsDeleted( false );
            setModalmsg( "No se ha seleccionado ningun contacto" )
            ModalOpen();
        }
    }

    const findTutorRepeated = () => {
        let db = firebase.database();
        let childRef = db.ref(`childs/${childID}/tutors`);
        let refSearch=db.ref(`childs/${childID}`)


        childRef.on('value', (snapshot) => {

            snapshot.forEach(function(child) {

                console.log(child.val())
                console.log(keyTutor)

                if(child.val() === keyTutor){
                    setTutorRepeated(true)
                }

            });

            if(tutorRepeated){
                setIsShow( true );
                setIsConfirm( false );
                setIsDeleted( false );
                setModalmsg( "Este contacto ya ha sido agregado como referencias para este niño" )
                ModalOpen();    
            }
            else{
                saveTutorOnSelect()
            }
            
        });
    }

    const saveTutorOnSelect = ( e, status ) => {
        e.preventDefault();
        let statusErr   = false;
        let statusChild = false;
        if ( status === true ){
            if ( isSelected ) {
                let db = firebase.database();
                let childRef = db.ref(`childs/${childID}/tutors`);
                childRef.child("tutor_1").on('value',function(snapshot){
                    setAvanzar(true)
                    if(snapshot.val() === keyTutor){
                        setIsShow( true );
                        setIsConfirm( false );
                        setIsDeleted( false );
                        setModalmsg( "Este tutor ya existe como referencia para este alumno" )
                        ModalOpen();
                    }else{
                        childRef.child("tutor_2").on('value',function(snapshot2){
                            setAvanzar(true)
                            if(snapshot2.val() === keyTutor){
                                setIsShow( true );
                                setIsConfirm( false );
                                setIsDeleted( false );
                                setModalmsg( "Este tutor ya existe como referencia para este alumno" )
                                ModalOpen();
                            }else{
                                childRef.child("tutor_3").on('value',function(snapshot3){
                                    setAvanzar(true)
                                    if(snapshot3.val() === keyTutor){
                                        setIsShow( true );
                                        setIsConfirm( false );
                                        setIsDeleted( false );
                                        setModalmsg( "Este tutor ya existe como referencia para este alumno" )
                                        ModalOpen();
                                    }else{
                                        childRef.child("tutor_1").on('value',function(snapshot4){
                                            setAvanzar(true)
                                            if(snapshot4.val() === keyTutor){
                                                setIsShow( true );
                                                setIsConfirm( false );
                                                setIsDeleted( false );
                                                setModalmsg( "Este tutor ya existe como referencia para este alumno" )
                                                ModalOpen();
                                            }else{
                                                childRef.update({ tutor_2: keyTutor }, 
                                                    function(error) {
                                                        if (error) {  
                                                            statusErr = true;
                                                            console.log(error)  
                                                        } 
                                                        else { statusChild = true }
                                                    }
                                                );
                                                getTutorList( `tutors/${keyTutor}` )
                                                
                                                if ( statusErr || tutorError ) {
                                                    setIsShow( true );
                                                    setIsConfirm( false );
                                                    setIsDeleted( false );
                                                    setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                                                    ModalOpen();
                                                }
                                                else {
                                                    setIsShow( true );
                                                    setIsConfirm( false );
                                                    setIsDeleted( false );
                                                    setModalmsg( "Contacto de referencia agregado." )
                                                    setModalLink(`/alumnos/informacion/${childID}`)
                                                    ModalOpen();
                                                }
                                            }
                                        });     
                                    }
                                });
                            }
                        });   
                    }
                });
            }
            else{
                setIsShow( true );
                setIsConfirm( false );
                setIsDeleted( false );
                setModalmsg( "No has seleccionado ningun contacto." )
                ModalOpen();
            }
        }
        else {
            ModalClose()
        }
    }


    const GetRefecences = () => {
        const { currentUser }   = useContext(AuthContext);
        useEffect(() => handleload(),[refresh]);
    
        const handleload = e =>{
            let db = firebase.database();
            let ref = db.ref(`tutors`);
            let listItem = [];
            let childList = [];
            ref.once('value').then(function(snapshot) {
                if(snapshot.val()){
                    snapshot.forEach( item => {
                        let itemInfo = item.val()
                        if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                            childList.push( itemInfo.tutors )
                            listItem.push( 
                                <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user} 
                                name={ `${itemInfo.name} ${itemInfo.last_name} ` } 
                                info={ `Telefono: ${itemInfo.contact.phone}, Email ${itemInfo.contact.email}` } 
                                action={ true } 
                                isDeleted={ isElementDeleted } 
                                childs={ childList }
                                />
                            )
                        }
                    })
                    setList( listItem );
                }
                if (  snapshot.val() == [] ) {
                    setList( false );   
                } 
                if (  snapshot.val() == null) {
                    setList( false );   
                }
            });
        }
        return ( list );
    }
    
    return(
        <Grid container className="contactReference_grid">
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    { isDeleted &&
                        <ModalShow 
                            title=" ¿Estás seguro de eliminar contactos de referencia? "
                            confirmDelete={ true }
                            Delete={ deleteTutor }
                        /> 
                    }
                    { isConfirm &&
                        <ModalShow 
                            title=" ¿Estás seguro de agregar referencia? "
                            confirmOperation={ true }
                            Operation={ saveTutorOnSelect }
                        /> 
                    }    
                    { isShow &&
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        />
                    }
                </Grid>
            </Modal>
            <Grid item xs={8} sm={12} md={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Contactos de referencia"
                        text="Añade hasta 3 contactos para que otra persona pueda recoger al alumno."
                        btn_back={`/padres/${childID}`}
                        btn_select_submit={ true }
                        onSelect={ ConfirmOperation }
                    />
                </Box>
            </Grid>
            <Grid item xs={4} sm={12} md={12}>
                <Grid container direction="row" justify="center" alignItems="center" className="kipperBoxColor_content" >
                    <div>
                        <Link to={`/referencias/agregar/${childID}`}>
                            <Button className="kipper_button"match variant="outlined" fullWidth>Agrega nuevos contactos</Button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    <ShowContracts 
                        list={ GetRefecences() } 
                        noDetails={ true }
                        onSelectSubmit={ onSelectSubmit } 
                        onRemoveSelected={ onRemoveSelected } 
                        onDeleted={ isElementDeleted }   
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ContactoReferencia