import React, { Component, useState, useContext, useEffect } from 'react';
import {Grid,Typography} from '@material-ui/core/';
import { AuthContext } from "../../Components/functions/Auth";

import { ChildHomeItem } from '../../Components/childs/ChildHomeItem';
import PageHeader from '../../Components/Global/PageHeader';

import * as firebase from 'firebase';
import NavChildPhone from './NavChildPhone/NavChildPhone';

const SubjectsChild =()=>{
    const { info, infoSchool,events } = useContext(AuthContext);
    const [subjects, setSubjects] = useState([])
    const [schoolInfo, setSchoolInfo] = useState('')
    let flag = false;
  
    useEffect(() => {
      if(info)
      {

        let db = firebase.database();
        var Schoolref = db.ref(`schools/${info.school}`);
        Schoolref.once('value').then( snapshot => {
          if ( snapshot.val() ) {
          setSchoolInfo( snapshot.val() )
        }
        else{
          setSchoolInfo( false )
        }
      })
      hadleload()
      }
    }, [info]);
  
    async function  hadleload(){
        
      let db = firebase.database();
      var Schoolref = db.ref(`schools/${info.school}/groups/${info.group}/school_subjects`);
      
      let MateriaList = [];
      
      var infoRef = await Schoolref.once('value')
      
      if(infoRef.val()){
        var iterable_infoRef = Object.entries(infoRef.val())
        for (const ref of iterable_infoRef){
          let subjectRef = await db.ref(`school_subjects/${ref[0]}`).once('value');
          var subjectInfo = subjectRef.val();
          if(subjectInfo)
          {
              flag=true;
            MateriaList.push(
              <ChildHomeItem                
              key={ ref[0] }
              title={ subjectInfo?.title }
              details={ subjectInfo?.details }
              link={`/alumno/materia/details/${ref[0]}`}
              />
              )   
          }
        }
        if(flag && MateriaList.length>0)
            setSubjects(MateriaList)
        else
            setSubjects(<Typography id="withoutSubjects">No hay materias asignadas</Typography>)
  
      }else{
        setSubjects("No hay materias asignadas")
      }
    }

    return(
      <>
        <PageHeader 
            title="Materias"
            text="Todas las materias en las que estas inscrito se verán aqui"
            btn_back={`/`}
        />
        <Grid container className="boxReports_width" id="Evaluaciones_parciales_container">
            <NavChildPhone />
            {subjects}
        </Grid>
      </>
    );
}

export default SubjectsChild