import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
import PageHeader from '../../Components/Global/PageHeader';
import user from './../../Assets/Img/General/placeholder_teacher-m.png';
import user2 from './../../Assets/Img/General/placeholder_teacher-f.png';
import user3 from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeworkItem from './HomeWorks';

import { Grid, Box, Typography, Divider, Button } from '@material-ui/core';
import * as firebase from 'firebase';

export default function Homework() {
    const { info, currentUser, infoSchool } = useContext(AuthContext);
    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/tareas/:id/:subject');
    const SubjectID = match.params.subject;
    const childID = match.params.id;
    const [materia, setMateria] = useState('')
    const [Tareas, setTareas] = useState(null)
    const [ list, setList ] = useState([]);
    const [refresh, setrefresh] = useState(null);


    useEffect(() => {
        Horarios()     
    }, [refresh || infoSchool])

    const Horarios = () =>{
        if(infoSchool)
        {
            let db = firebase.database();
            var listtareas = [];
            let ref = db.ref(`schools/${infoSchool.school}/groups/${childID}/school_subjects/${SubjectID}/homework`);
            ref.once('value').then( snapshot => {
                if(snapshot.val()){
                    let data = snapshot.val()
                    Object.entries(data).map( item => {
                        listtareas.push(item[1])
                    })
                    setTareas( listtareas );
                }
            });
        }
    }

    return (
        <Grid container className="padres_grid">
            <Grid item xs={12}>
                <Box pb={3}  mx={3} >
                    <PageHeader 
                        title='Tareas'
                        text="Agrega, modifica y revisa las tareas de la materia" 
                        btn_back={`/teacher/materias/group/${childID}`}
                        btn_to={`/teacher/tareas/agregar/${childID}/${SubjectID}`}
                        btn_to_text="Agregar Tarea"
                    />
                </Box>
            </Grid>
            <Grid xs={12}>
                <Box className="kipperBox_content" boxShadow={3} style={{height:"90vh"}}>
                    <Grid container xs={12} md={12} >
                        <Grid item xs={12} className="" style={{minHeight:'90vh'}} >
                            {
                                Tareas ?
                                    Tareas.map( i => 
                                        <HomeworkItem homework ={i} to={`/teacher/tareas/detalles/${childID}/${SubjectID}/${i.id}`} refreshafter={Horarios}/>
                                    )
                                : null
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
