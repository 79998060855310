import React, { useState } from 'react'
// IMG
import user from './../../../Assets/Img/General/user_example.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';

const AssistanceItem = ( { date, list  } ) => {


    const [ scheduleDay, setScheduleDay ] = useState({
        yes: true,
        no: false,
    });

    return(
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <span className="notificacion_title">{ date }</span>
                    </Box>
                </Grid>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={2} className="center-center" >
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <img src={ user } alt="" className="assistenceItem_IMG" />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    Name
                                </Typography>
                                <Typography variant="body1">
                                    Email@email.com
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1">
                                    Presente:
                                </Typography>
                                <RadioGroup value={ scheduleDay } onChange={ e => setScheduleDay( e.target.value ) } row  >
                                    <FormControlLabel value="yes" control={<Radio  color="primary" />} label="Si" />
                                    <FormControlLabel value="no" control={<Radio  color="primary" />} label="No" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end" pt={2} >
                            <Button className="kipper_btn-outline-primary" disableElevation>
                                Cancelar
                            </Button>
                            <Button className="kipper_btn-outline-primary" disableElevation>
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}
     
export default AssistanceItem