import React from 'react'

import { Grid,Box,InputLabel,FormControl,Select } from '@material-ui/core'


import Report from '.././../Assets/Img/General/portada_add-yellow-12.png';



export default function SimpleSelect() {
  
     
    
    return(
        <Grid container  >
            <Grid item xs={12}>
            <img src={Report} fullWidth alt="" />
            </Grid>
            <Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >TIPO DE REPORTE</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          <optgroup >
            <option value={1}>Asistencia</option>
            <option value={2}>Conducta</option>
          </optgroup>
        </Select>
      </FormControl>
      </Box>
      </Grid>
      <Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >DESTINATARIO</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          <optgroup >
            <option value={1}>Grupo en especifico</option>
            <option value={2}>Grupo en especificos</option>
          </optgroup>
        </Select>
      </FormControl>
      </Box>
      </Grid><Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >Fecha</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          
        </Select>
      </FormControl>
      </Box>
      </Grid><Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >TURNO</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          <optgroup >
            <option value={1}>Matutino</option>
            <option value={2}>Vespertino</option>
          </optgroup>
        </Select>
      </FormControl>
      </Box>
      </Grid><Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >GRADO</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          <optgroup >
            <option value={1}>Primero</option>
            <option value={2}>Segundo</option>
            <option value={3}>Tercero</option>
          </optgroup>
        </Select>
      </FormControl>
      </Box>
      </Grid><Grid item  xs={4} >
                <Box p={2 } >
                <FormControl >
        <InputLabel >GRUPO</InputLabel>
        <Select className="input_width" native defaultValue="" >
          
          <optgroup >
            <option value={1}>A</option>
            <option value={2}>B</option>
            <option value={3}>C</option>
          </optgroup>
        </Select>
      </FormControl>
      </Box>
      </Grid>
    </Grid>
    )
    }