import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from '../../Components/Global/Modal';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/Icon awesome-clock.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
import documentIcon from './../../Assets/Img/Entregas/undraw_sync_files_xb3r.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
//firebase
import * as firebase from 'firebase';
import { logDOM } from '@testing-library/react';
import { Title } from '@material-ui/icons';
import Notificaciones from '../Notificaciones';
import { checkFilepdf } from '../../Components/functions/helpapers';

function HomeworkNew () {
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    const db = firebase.database();
    const history = useHistory();
    const match = useRouteMatch('/teacher/tareas/agregar/:id/:subject');
    const childID = match.params.id;
    const SubjectID = match.params.subject;

    const [ title, setTitle ]                       = useState('');
    const [ description, setDescriprion ]           = useState('');
    const [ day, setDay ]                           = useState('');
    const [ time, setTime ]                         = useState('');
    const [ TareaName, setTareaName ] = useState('');
    const [ TareaGrado, setTareaGrado ] = useState('');
    const [enlace, setEnlace] = useState('');
    const [errors, seterrors] = useState('');
    const [ docId, setDocId ] = useState('');
    const [ fileUploaded, setFileUploaded ] = useState(false);
    const [ fileUploadedName, setFileUploadedName ] = useState('');
    const [ fileUploadedUrl, setFileUploadedUrl ] = useState('');
    const [errorMsg, seterrorMsg] = useState({
        doc: false,
        date: null,
        description: null,
        title: null,
        url: null,
        time: null
    });

    const [ errorTitle, setErrorTitle ]=useState(null)
    const [ errorDescription, setErrorDescription ]=useState(null)
    const [ errorDate, setErrorDate ]=useState(null)
    const [ errorTime, setErrorTime ]=useState(null)
    const [ errorUrl, setErrorUrl ]=useState(null)


    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };  

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    
    const handlesubmit = e =>{
        setErrorTitle(null)
        setErrorDescription(null)
        setErrorDate(null)
        setErrorTime(null)
        setErrorUrl(null)
        const actualDate=new Date();
        const fechaParse= new Date(day)
        var errorflag = false;
        e.preventDefault();
        const regexUrl = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);

        if(0 < enlace.length &&!regexUrl.test(enlace)){ errorflag = true;  setErrorUrl( "Ingrese una URL válida para el link del utilidad o deje el campo vacio" ) }
        if(fechaParse<actualDate){  errorflag = true;  setErrorDate("No puedes crear una tarea con fecha de entrega pasada" )}
        if(day.length <= 0){  errorflag = true;  setErrorDate("Seleccione una fecha maxima de entrega" ) }
        if(time.length <= 0){  errorflag = true;  setErrorTime("Seleccione la hora final de la entrega" )}
        if(description.length <= 0){  errorflag = true;  setErrorDescription("Escribe una descripción para la tarea" ) }
        if(title === ""){ errorflag = true; setErrorTitle( "Escribe un titulo para la tarea" ) }
        if(!errorflag)
        {
            seterrors('');
            submitready(e)
        }


    }

    const submitready = e =>{
        e.preventDefault();
        //const opcionalIMG = document.getElementById('opcionalIMG').src
        var imgtoarray;
        if(docId){
            imgtoarray = document.getElementById('opcionalIMG').src
        }else{
            imgtoarray = ''
        }

        let id = `Homework_${Date.now()}`;
        let Usersref = db.ref(`schools/${infoSchool.school}/groups/${childID}/school_subjects/${SubjectID}/homework/${id}`);
        
        Usersref.set({
            id: id,
            title: title,
            description: description,
            document: imgtoarray,
            fulltime: day,
            time: time,
            link: enlace
        }, function(error) {
            if (error) {
                console.log(error)
            } else {
                notifications();
                setTitle('');
                setDescriprion('');
                setEnlace('');
                setModalmsg('La tarea fue creada con exito');
                setModalLink(`/teacher/materias/tareas/${childID}/${SubjectID}`);
                ModalOpen();
            }
        });
    }

    const cleanFileFields = () =>{
        setFileUploadedUrl('')
        setFileUploadedName('')
        setFileUploaded(false)
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        //const fileIsValid = await checkFilepdf( img );
        
            try {
                setDocId( img );
                seterrorMsg({...errorMsg, doc: false })
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/homeworks/documents/Doc${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        setFileUploadedUrl(url)
                        setFileUploadedName(img.name)
                        setFileUploaded(true)
                    })
                })
            } catch (error) {
                console.log( error );
            }

    }

    function notifications(){
        var responseval = [];
        let refchilds = db.ref(`childs`)
        refchilds.once('value').then((response)=>{
            let data = Object.entries(response.val())
            data.forEach(element => {
                if(element[1].group == childID){
                    responseval.push({key: element[0], status: 0})
                }
            });
            notificationsv2(responseval)
        })    
    }

    function notificationsv2(userList){
        let ref = db.ref('notifications')
        ref.push({
            body: `El maestro ${infoSchool.name} ${infoSchool.lastname} ha dejado una nueva tarea`,
            date: new Date().getDate(),
            school_key: infoSchool.school,
            time: new Date().getTime(),
            title: "Nueva Tarea" ,
            type: "childs" ,
            userList
        }, function(error) {
            if (error) { 
                // The write failed...
                console.log(error)
            } else {
                // Data saved successfully!
            }
        });
    }


    return(
        <form onSubmit={ e => handlesubmit( e ) }>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>

            <Grid container className="alumInfo_grid">
                <Grid item xs={12} sm={12} >
                    <Box  pb={3}>
                        <PageHeader 
                            title="Crear tarea"
                            text="Crea una nueva tarea para tus alumnos"
                            btn_back={`/teacher/materias/tareas/${childID}/${SubjectID}`} 
                            btn_action={true}
                            btn_action_text="Guardar"
                        />
                        <p className="kipper_txt-color">{errors}</p>
                    </Box>
                </Grid>
                <Grid item xs={12} className="Form_add-content">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                        <Box >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Box>
                        {/* <Box  className="Form_add-profile-img">
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <div className="kipperBox_content-upload">
                                    <img src={ file } alt=""/>
                                </div>
                            </Grid>
                        </Box> */}
                        <Box p={3}>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}>
                                        <Typography variant="h5">
                                            {TareaName}
                                        </Typography>
                                        <Typography variant="body1">
                                            {TareaGrado}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}> 
                                        <Typography variant="body1" align="right">
                                            
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Titulo
                                        </Typography>
                                        <TextField 
                                            id="Title" 
                                            variant="outlined"
                                            error={errorTitle}
                                            helperText={errorTitle}
                                            size="small" 
                                            fullWidth 
                                            placeholder={ title } 
                                            onChange={e => setTitle(e.target.value) } 
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Descripcion de la tarea
                                        </Typography>
                                        <TextField 
                                            rows={4} 
                                            multiline
                                            error={errorDescription}
                                            helperText={errorDescription}
                                            placeholder="Detalles del evento" 
                                            className="text_area-style" 
                                            value={ description } 
                                            onChange={e => setDescriprion(e.target.value)} 
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Tiempo limite de entrega
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Typography variant="body1">
                                            Dia
                                        </Typography>
                                        <Box pr={2} >
                                            <TextField
                                                type="date"
                                                fullWidth
                                                error={errorDate}
                                                helperText={errorDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={ day } onChange={e => setDay(e.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Typography variant="body1">
                                            Hora
                                        </Typography>
                                        <Box pl={2}>
                                        <TextField
                                            type="time"
                                            error={errorTime}
                                            helperText={errorTime}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                            value={ time } onChange={e => setTime(e.target.value)}
                                        />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Agregar Material
                                        </Typography>
                                    </Box>
                                    <Box py={1}>
                                        <Box p={2}>

                                            {!fileUploaded ?
                                                <>
                                                <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                <label for="uploadFile" className="kipper_txt-color pointer">
                                                    <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                    <img src={ upload } alt=""/>

                                                    <p className="kipper_txt-color">Selecciona archivo</p>
  
                                                    </Grid>
                                                </label>
                                                </>
                                                :
                                            
                                                <div style={{textAlign:'center'}}>
                                                    <img style={{marginLeft:'auto', marginRight:'auto'}} src={documentIcon} />
                                                    <h3 className="kipper_txt-color">{fileUploadedName} </h3>
                                                    <a href={fileUploadedUrl} target='_blank'>
                                                      <VisibilityIcon className="kipper_txt-grey" />  
                                                    </a>
                                                    
                                                    <CreateIcon style={{cursor:'pointer', marginLeft:'10px'}} onClick={e=>cleanFileFields()} className="kipper_txt-grey" />
                                                </div>




                                            }

                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Links de utilidad
                                        </Typography>
                                        <Box>
                                            <TextField 
                                                id="Title" 
                                                variant="outlined"
                                                error={errorUrl}
                                                helperText={errorUrl}
                                                size="small" 
                                                fullWidth 
                                                placeholder={ enlace } 
                                                onChange={e => setEnlace(e.target.value) } 
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
    
}

export default HomeworkNew