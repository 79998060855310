import React,{useState,useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Grid, InputLabel,Typography } from '@material-ui/core';

//Components
import PageHeader from '../../Components/Global/PageHeader';

//Firebase
import * as firebase from 'firebase';

const ScheduleDetails =()=>{
    const {id} = useParams();

    const [data,setData] = useState({
        school:null,
        teachers:'loading',
        groups:'loading',
    });

    useEffect(()=>{
        checkIfExistsTeachersGroups();
    });

    const checkIfExistsTeachersGroups =async()=>{
        let db = firebase.database();
        let teachersRef = db.ref(`teacher`);
        let groupsRef = db.ref(`schools/${id}/groups`);
        let schoolNameRef = db.ref(`schools/${id}`);
        let flagTeacher = false;
        let flagGroups = false;
        let schoolsName = null;

        //Verify if exists teachers in the school
        await teachersRef.once('value').then(function(snapshot) {
            let item = snapshot.val();
            for (var clave in item){
                if (item.hasOwnProperty(clave) && clave !== 'groups') {
                    if (item[clave].school && item[clave].status && (item[clave].school === id && item[clave].status === 'active')){
                        flagTeacher = true;
                        break;
                    }
                }
            }
        });
        //Verify if exists groups in the school
        await groupsRef.once('value').then(function(snapshot) {
            let item = snapshot.val();
            for (var clave in item){
                if (item.hasOwnProperty(clave) && clave !== 'details') {
                    if(item[clave]){
                        flagGroups = true;
                        break;
                    }
                }
            }
        });
        //Get name of the school
        await schoolNameRef.once('value').then(function(snapshot) {
            let item = snapshot.val();
            if(item) schoolsName = item.name;
        });

        setData({
            school:schoolsName,
            teachers:flagTeacher,
            groups:flagGroups,
        });
    }

    return(
        <Grid container className="scheduleDetails__main">
            <Grid item xs={12} >
                <Box pb={3}>
                    <PageHeader 
                        title="Opciones de horarios"
                        text={data.school ? `Selecciona los horarios que quieres ver en la escuela ${data.school}`:"Selecciona los horarios que quiere ver"}
                        btn_back="/"
                        btn_action={false}
                    />
                </Box>
            </Grid>
            <Grid container>
                {
                    data && (data.groups === 'loading') ?<h4>Cargando...</h4> :
                    <>
                    {
                        data && data.teachers &&
                        <Grid item xs={6} >
                            <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                <Link to={`/admin/escuela/profesores/admin/${id}`}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                        <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                                            <Typography variant="h4" className="fw-500" > Profesores </Typography>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                        </Grid>
                                    </Grid>
                                </Link>
                            </Box>
                        </Grid>
                    }
                    {
                        data && data.groups &&
                        <Grid item xs={6}>
                            <Box id="materiaItem" boxShadow={2} m={2} p={3} borderRadius={5} className="bg-white">
                                <Link to={`/admin/horarios/grupos/admin/${id}`}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="kipper_txt-color">
                                        <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                                            <Typography variant="h4" className="fw-500" > Grupos </Typography>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <i className="fas fa-chalkboard fa-5x kipper_txt-color opacity-05" />
                                        </Grid>
                                    </Grid>
                                </Link>
                            </Box>
                        </Grid> 
                    }
                    </>
                }
                { (data && !data.groups && !data.teachers) && <h4>{data.school ? `No hay maestros ni grupos en la escuela ${data.school}`:`No hay maestros ni grupos con el identificador ${id}`}</h4> }

            </Grid>
        </Grid>
    );
}

export default ScheduleDetails;