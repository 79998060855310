import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
// MaterialUI
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Imagenes
import home from './../../Assets/Img/Layout/Icon_Home.png';
import calendar from './../../Assets/Img/Layout/father-calendar.png';
import information from './../../Assets/Img/Layout/ios-information.png';
import chat from './../../Assets/Img/Layout/chat_bubble.png';
import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import subjects from './../../Assets/Img/Layout/Icon_list.png';
import { AuthContext } from '../functions/Auth';

const BottomNavChild = () => {

    const { handlelogout, currentUser } = useContext( AuthContext );

    return (
        <div className="bottomChildNav">

            {/* <Link to="/" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerChild">
                    <img src={ home } />
                    <h4 >Inicio</h4>
                </div>
            </Link>
            <Link to="/alumno/calendario" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerChild">
                    <img src={ calendar } />
                    <h4 >Calendario</h4>
                </div>
            </Link>
            <Link to="/alumno/chat" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerChild">
                    <img src={ chat } />
                    <h4 >Chat</h4>
                </div>
            </Link> */}

            <BottomNavigation className="AdminNavbar" style={{ height: '10vh', backgroundColor: '#EFF4F8' }}>
                <Link to="/" className="flex">
                    <BottomNavigationAction 
                        label="Inicios" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ home } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />
                </Link>
                <Link to="/alumno/calendario" className="flex">
                    <BottomNavigationAction 
                        label="Calendario" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />
                </Link> 
                <Link to="/alumno/chat" className="flex">
                    <BottomNavigationAction 
                        label="Chat" 
                        value="recents"
                        style={{ outline: 'none' }}
                        icon={ 
                            <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        }
                    />

                </Link>
                {
                    currentUser &&
                    <Link to={`/child/horarios/child/detalles/info/${currentUser}`} className="flex">
                        <BottomNavigationAction 
                            label="Horario" 
                            value="recents"
                            style={{ outline: 'none' }}
                            icon={ 
                                <AccessTimeIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                            }  
                        />

                    </Link>
                } 
                <Link className="flex">
                    <BottomNavigationAction 
                        label="Salir" 
                        value="recents"
                        style={{ outline: 'none' }}
                        onClick={ () => handlelogout() }
                        icon={ 
                            <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        } 
                    />
                </Link>
            </BottomNavigation>
            
        </div>
    )
}

export default BottomNavChild
