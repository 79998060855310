import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
// Imagenes
import logo from './../../Assets/Img/Layout/Logo_text.png';
import users from './../../Assets/Img/Layout/Icon_Usuario.png';
import notification from './../../Assets/Img/Layout/Icon_Notification.png';
import home from './../../Assets/Img/Layout/Icon_Home.png';
import student from './../../Assets/Img/Layout/Icon_Alumnos.png';
import sede from './../../Assets/Img/Layout/Icon_Sede.png';
import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import evento from './../../Assets/Img/Layout/Icon_Evento.png';
import materias from './../../Assets/Img/Layout/Icon_list.png';


// Estilos
import './../../Assets/Styles/Layout.css';
//
import app from "../functions/credentials";

import { AuthContext } from "../functions/Auth";

const useStyles = makeStyles({
    root: {
        width: "90%",
    },
    sk_content:{
        alignItems:"center"
    }
});

const SideLoad = ({}) => {
    const { handlelogout} = useContext( AuthContext );
    const classes = useStyles();
    return(
    <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
        <Box className="sidebar__header" display="flex">
            <Box flexGrow={1} pt={3} pl={3}>
                <Link to="/home">
                    <img src={ logo } alt="Logo Kiper" />
                </Link>
            </Box>
            <Box pt={6}>
                <ul className="flex">
                    <li className="mx-3">
                        <Link to="/notificaciones">
                            <img src={ notification } alt="Usuario Kiper" />
                        </Link>
                    </li>
                    <li className="mx-3">
                        <div>
                            <Link to="/teacher/profile">
                                <img src={ users } alt="notificaciones Kiper" />
                            </Link>
                        </div>
                    </li>
                </ul>
            </Box>
        </Box>
        <Box className="sidebar__search" display="flex" justifyContent="center">
            <TextField
                id="input-with-icon-textfield"
                variant="outlined"
                placeholder="Buscar"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
        <Box className="sidebar__menu " style={{paddingLeft:"20px"}}>
            <div className={classes.root}>
                <Grid container className={classes.sk_content}>
                    <Grid item xs={3}>
                    <Skeleton variant="circle" width={40} height={40} />

                    </Grid>
                    <Grid item xs={9}>
                        <Skeleton animation="wave" height={65} />
                    </Grid>
                </Grid>
                <Grid container className={classes.sk_content}>
                    <Grid item xs={3}>
                    <Skeleton variant="circle" width={40} height={40} />

                    </Grid>
                    <Grid item xs={9}>
                        <Skeleton animation="wave" height={65} />
                    </Grid>
                </Grid>
                <Grid container className={classes.sk_content}>
                    <Grid item xs={3}>
                    <Skeleton variant="circle" width={40} height={40} />

                    </Grid>
                    <Grid item xs={9}>
                        <Skeleton animation="wave" height={65} />
                    </Grid>
                </Grid>
                <Grid container className={classes.sk_content}>
                    <Grid item xs={3}>
                    <Skeleton variant="circle" width={40} height={40} />

                    </Grid>
                    <Grid item xs={9}>
                        <Skeleton animation="wave" height={65} />
                    </Grid>
                </Grid>
                <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon"/>
                    <span>Salir</span>
                </li> 
            </div>
        </Box>
    </Box>
    )
};

export default SideLoad;