import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from './../Components/Global/PageHeader';
import ShowContracts from './../Components/Global/ShowContracts';
import ModalShow from '../Components/Global/Modal';
//img
import user from './../Assets/Img/General/placeholder_man.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Typography, Paper } from '@material-ui/core';

function Padres () {

    const history = useHistory();
    const match     = useRouteMatch('/padres/:id');
    const childID   = match.params.id;
    const { currentUser }   = useContext(AuthContext);
    const [ refresh, setRefresh ]   = useState( 0 );
    const [ list, setList ] = useState([]);
    
    const [ keyTutor, setkeyTutor ]         = useState("");
    const [ isSelected, setIsSelected ]     = useState( false );
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ tutorID, setTutorID ]       = useState('');
    const [ tutorError, setTutorError ] = useState('');
    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        setIsDeleted( true );
        setIsConfirm( false );
        setIsShow( false );
        setTutorID( id );
        ModalOpen();
    }
    const deleteTutor = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let tutorRef = db.ref(`tutors/${tutorID}`);
            tutorRef.update(
                { status: "inactive", }, 
                (error) => {
                    if (error) { 
                        setIsConfirm( true );
                        setIsShow( false );
                        setIsDeleted( false );
                        setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                        ModalOpen();
                    } 
                    else {
                        setIsShow( true );
                        setIsConfirm( false );
                        setIsDeleted( false );
                        setModalmsg( "Tutor eliminado." )
                        ModalOpen();
                        setRefresh( refresh + 1 )
                    }
                }
            );
        }
        else{
            ModalClose()
        }
    }
    const onSelectSubmit = id => {
        setIsSelected( true );
        setkeyTutor( id );
    }
    const onRemoveSelected = () => {
        setIsSelected( false )
    }
    const ConfirmOperation = ( e ) =>{
        e.preventDefault();
        if ( isSelected ) {
            setIsConfirm( true );
            setIsShow( false );
            setIsDeleted( false );
            ModalOpen();
        }
        else{
            setIsShow( true );
            setIsConfirm( false );
            setIsDeleted( false );
            setModalmsg( "No se ha seleccionado ningun contacto" );
            ModalOpen();
        }
    }
    const getTutorList = ( path ) => {
        let db = firebase.database();
        let 
        
        ref = db.ref( path );
        let refUpdate = db.ref( `${path}/childs` );
        ref.once('value').then(function(snapshot) {
            let info = snapshot.val().childs;
            let isUpdate = false;
            if ( info.child_1 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_1: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_2 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_2: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_3 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_3: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_4 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_4: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_5 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_5: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_6 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_6: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_7 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_7: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_8 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_8: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
            if ( info.child_9 === undefined && isUpdate === false ) { 
                isUpdate = true;
                refUpdate.update(
                    { child_9: childID },
                    (error) => {
                        if (error) { setTutorError(error) } 
                    }
                );
            }
        });
    }
    const saveTutorOnSelect = ( e, status ) => {
        e.preventDefault();
        let statusErr   = false;
        let statusChild = false;
        if ( status === true ){
            if (isSelected) {
                let db = firebase.database();
                let childRef = db.ref(`childs/${childID}/tutors`);
                childRef.update({ tutor_1: keyTutor }, 
                    function(error) {
                        if (error) { 
                            console.log(error) 
                            statusErr = true;
                        } 
                        else { statusChild = true }
                    }
                )

                getTutorList( `tutors/${keyTutor}` )
                
                if ( statusErr || tutorError ) {
                    setIsShow( true );
                    setIsConfirm( false );
                    setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                    ModalOpen();
                }
                else {
                    setIsShow( true );
                    setIsConfirm( false );
                    setModalmsg( "Padre agregado." )
                    setModalLink(`/referencias/${childID}`);
                    ModalOpen();
                }

            }
            else{
                setIsShow( true );
                setIsConfirm( false );
                setModalmsg( "No has seleccionado ningun contacto." )
                ModalOpen();
            }
        }
        else {
            ModalClose()
        }
    }
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`tutors`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && itemInfo.school === currentUser ) {
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user } 
                                name={ `${itemInfo.name} ${itemInfo.last_name} ` } 
                                info={ `Telefono: ${itemInfo.contact.phone}, Email ${itemInfo.contact.email}` } 
                                action={ true } 
                                isDeleted={ isElementDeleted } 
                            />
                        )
                    }
                })
                setList( listItem );    
            }
            if (  snapshot.val() == [] ) {
                setList( false );   
            } 
            if (  snapshot.val() == null) {
                setList( false );   
            }
        });
    }, [ refresh ] );

    return(
        <Grid container className="padres_grid">
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted ?
                            <ModalShow 
                                title=" ¿Estás seguro de eliminar? "
                                confirmDelete={ true }
                                Delete={ deleteTutor }
                            /> : null
                    }
                    {
                        isConfirm ?
                            <ModalShow 
                            title=" ¿Estás seguro de agregar como padre de familia? "
                            confirmOperation={ true }
                            Operation={ saveTutorOnSelect }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Padre de familia o tutor"
                        text="Selecciona un contacto existente o agrega uno nuevo."                        
                        btn_select_submit={ true }
                        onSelect={ ConfirmOperation }
                        btn_back="/alumnos"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center" alignItems="center" className="new_item" >
                    <Box>
                        <Link to={`/padres/agregar/${childID}`}>
                            <Button className="kipper_button" variant="outlined" fullWidth>Nuevo padre de familia</Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    {
                        list ?
                            <ShowContracts 
                                list={ list } 
                                noDetails={ true }
                                onSelectSubmit={ onSelectSubmit } 
                                onRemoveSelected={ onRemoveSelected } 
                                onDeleted={ isElementDeleted }  
                            />
                        :
                            <Paper elevation={3} >
                                <Box className="" p={5} >
                                    <Typography variant="h6" className="schoolInfo_title"> Sin informacion por el momento </Typography>
                                </Box>
                            </Paper>
                    }
                    
                </Box>
            </Grid>
        </Grid>
    )
}

export default Padres