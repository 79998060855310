import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import academy from './../../Assets/Img/General/Academy.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import { Paper, Typography } from '@material-ui/core';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

function Materias () {
    const { currentUser, userType, info, infoSchool }   = useContext(AuthContext);
    const match = useRouteMatch('/escuela/materias/:id/:nivel');
    const GradeID =  match.params.id;
    const nivel = match.params.nivel;

    const history = useHistory();
    
    
    const [ isShow, setIsShow ]         = useState( false );
    const [ list, setList ]             = useState([]);
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ materiaID, setMateriaID ]       = useState('');
    const [ refresh, setRefresh ]   = useState( 0 );

    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
        setIsShow(false)
    };

    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        setIsDeleted( true );
        setMateriaID( id );
        ModalOpen();
    }

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`school_subjects`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && itemInfo.school === currentUser && itemInfo.grade === GradeID && itemInfo.level === nivel.toLowerCase() ) {
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ academy }
                                name={ `${itemInfo.title} ` }
                                info={ `${ itemInfo.grade ? itemInfo.grade : '' }` }
                                btn_to={ `/escuela/materias/informacion/${item.key}/${nivel}/0` }
                                isSchoolSubjects={ true }
                                isGroups={false}
                            />
                        )
                    }
                })
                setList( listItem );      
            }
            else {
                setList( false );
            }
        });
    }, [ refresh ] )

    const deleteChild = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            ModalClose()

            const db = firebase.database();
            const ref = db.ref(`school_subjects/${materiaID}`)
            ref.remove().then(function() {
                setRefresh( refresh + 1 )
                setIsShow( true );
                setIsDeleted( false );
                setModalmsg( "Materia eliminada" );
                ModalOpen();
            })
            .catch(function(error) {
                console.log("Remove failed: " + error.message)
                setIsShow( true );
                setIsDeleted( false );
                setModalmsg( "Error al eliminar materia" );
                ModalOpen();
            });
        }
        else{
            setIsShow( false );
            setIsDeleted( false );
            ModalClose()
        }
    }

    return(
        <Grid container>
            {/* <NavEscuelaPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global"> 
                    {
                        isDeleted ?
                            <ModalShow
                                title=" ¿Estás seguro de eliminar la materia? "
                                confirmDelete={ true }
                                Delete={ deleteChild }
                            /> : null
                    }
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3}>
                    <PageHeader 
                        title={`Materias de ${nivel}`}
                        text="Ver y administrar materias" 
                        btn_back="/escuela/lista/materias"
                        btn_to={`/escuela/agregar/materias/${GradeID}/${nivel}`}
                        btn_to_text="Agregar"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3} className="box_w">
                    {
                        list.length > 0 ?
                            <ShowContracts 
                                list={ list } 
                                isSchoolSubjects={ true }
                                onDeleted={ isElementDeleted }
                            />
                        :
                        <Paper elevation={3} >
                            <Box className="" p={5} >
                                <Typography variant="h6" className="schoolInfo_title"> Sin información por el momento </Typography>
                            </Box>
                        </Paper>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default Materias