import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
// MaterialUI
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
// Imagenes
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { AuthContext } from '../functions/Auth';

const BottomNavChild = () => {

    const { handlelogout } = useContext( AuthContext );

    return (
        <BottomNavigation className="AdminNavbar" style={{ height: '10vh', backgroundColor: '#EFF4F8' }}>
            <Link to="/home" className="flex">
                <BottomNavigationAction 
                    label="Inicio" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <HomeIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link to="/admin/escuela/crear" className="flex">
                <BottomNavigationAction 
                    label="Creacion de Escuelas" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <AddCircleIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link to="/admin/escuela/grupos" className="flex">
                <BottomNavigationAction 
                    label="Asignacion de Grupo a Escuela" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <GroupWorkIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link to="/admin/notificaciones" className="flex">
                <BottomNavigationAction 
                    label="Creacion de notificaciones" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <NotificationsIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link to="/admin/horarios" className="flex">
                <BottomNavigationAction 
                    label="Ver horarios" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <AccessTimeIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link className="flex">
                <BottomNavigationAction 
                    label="Salir" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    onClick={ () => handlelogout() }
                    icon={ 
                        <ExitToAppIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            
        </BottomNavigation>
    )
}

export default BottomNavChild
