import React, { useState, useContext, useEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from "./../../Components/functions/Auth";
//Component
import PageHeader from './../../Components/Global/PageHeader';
import EventoItem from './../../Components/Global/EventoItem';
import ModalShow from './../../Components/Global/Modal';
import Loader from './../../Components/Global/Loader';
// meterialui
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';
import { Typography } from '@material-ui/core';

function Comunicados () {

    const history   = useHistory();

    const { currentUser }               = useContext(AuthContext);
    const [ Error, setError ]           = useState( false );
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ comunicadoID, setcomunicadoID ]       = useState('');
    const loading = useRef(true);
    //Modal
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
        setIsShow(false);
        setIsDeleted(false);
    };

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    }; 
    
    function refreshPage() {
        window.location.reload(false);
    }

    const isElementDeleted = ( e, id ) => {
        e.preventDefault();
        ModalOpen();
        setIsDeleted( true );
        setcomunicadoID( id );
    }

    const deleteEvent = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let EventRef = db.ref(`comunicados/${comunicadoID}`);
            EventRef.remove(                
                (error) => {
                    if (error) { 
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                        ModalOpen();
                    } 
                    else {
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Comunicado eliminado." )
                        //ModalOpen();
                    }
                })
            /*EventRef.update(
                { status: "inactive", }, 
                (error) => {
                    if (error) { 
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Lo sentimos algo extraño paso, Porfavor intenta de nuevo." )
                        ModalOpen();
                    } 
                    else {
                        setIsDeleted( false );
                        setIsShow(true);
                        setModalmsg( "Comunicado eliminado." )
                        ModalOpen();
                    }
                }
            );*/
        }
        else{
            ModalClose();
        }
    }

    const GetEvents = () => {
        const [ list, setList ] = useState([]);
        useEffect(() => handleload(),[list]);
        const handleload = e =>{
            let db = firebase.database();
            let ref = db.ref(`comunicados`);
            let listItem = [];
   
            //ref.orderByChild('school').equalTo(currentUser.toString()).on('value', function(snapshot)

            ref.orderByChild('date').once('value').then(function(snapshot) {

                snapshot.forEach( item => {

 
                    let itemInfo = item.val()

                    if ( itemInfo.status === "active" && itemInfo.school === currentUser && itemInfo.type !== 'appointment' ) {
                        //let viewedString = JSON.parse(itemInfo.viewed)
                        listItem.push( 
                            <Grid item sm={6} key={ item.key }>
                                <Box m={3}>
                                    <EventoItem  
                                        key={ item.key }
                                        id={ item.key }
                                        type={ itemInfo.type } 
                                        title={ `${itemInfo.name}` } 
                                        body={ `${itemInfo.details_event}` } 
                                        date={ `${itemInfo.date}` } 
                                        btn_to={ `/comunicados/detalles/${item.key}` }
                                        deleteEvent={ isElementDeleted }
                                        visto={itemInfo.viewed?(Object.keys(itemInfo.viewed).length):(0) }
                                    /> 
                                </Box>
                            </Grid>
                        )
                    }
                })
                loading.current = false;
                setList( listItem );
            });
        }

        if(loading.current){//Cargando
            return (
                <Grid item sm={6}>
                    <Box m={3}>
                        <Typography variant="p">Cargando...</Typography>
                    </Box>
                </Grid>
            );

        }else{//Informacion lista
            if(list.length > 0){//Hay eventos
                return list;
            }else{//No hay eventos
                return(
                    <Grid item sm={6}>
                        <Box m={3}>
                            <Typography variant="p">No hay eventos</Typography>
                        </Box>
                    </Grid>
                );
            }
        }
        
    }

    return(
        <Grid container>
            {/* <NavEscuelaPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted &&
                            <ModalShow 
                                title=" ¿Estás seguro de eliminar a un comunicado? "
                                confirmDelete={ true }
                                Delete={ deleteEvent }
                            /> 
                    }
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                btnText="Continuar"
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                            /> 
                    }
                </Grid>
            </Modal>
            
                <Box style={{width:"100%"}} pb={3}>
                    <PageHeader 
                        title="Comunicados y eventos"
                        text="Administra y agrega nuevos comunicados y eventos"
                        btn_back="/home"
                        btn_to="/comunicados/agregar"
                        btn_to_text="Crear evento"
                    />
                </Box>
            
            <Grid item sm={12}>
                <Grid container>
                    { 
                        GetEvents()
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Comunicados