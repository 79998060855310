import React, {useEffect} from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

//Resources
import portada from './../../Assets/Img/Modals/modal.png';

//Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';

const ModalSinSubmit = ({ title, onlyShow, showGoTo, goTo, confirmDelete, Delete, confirmOperation, rechazarAppoiment, handleReason, Operation, onClose }) =>{
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    
    return(
        <Grid container> 
            <Grid item className="AddAlumn_grid" justify="">
                <Box>
                    <img src={ portada } alt="modal img" />
                </Box >
                <Box p={2} textAlign="center">
                    <Typography className="textMod_p" >
                        { title }
                    </Typography>      
                </Box>

                {rechazarAppoiment?
                    <Box pb={2} pt={2}   textAlign="center">
                        <TextField onChange={handleReason} name="reasonInput" label="Razón" />  
                    </Box>
                :
                    <></>
                }


                <Box m={matches ?4 :1} >
                    {   onlyShow ? 
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button onClick={e => showGoTo(e, goTo)} variant="outlined" className="kipper_button modalbuttonFix" fullWidth >Confirmar</Button>
                                </Grid>
                            </Grid> : null
                    }
                    {
                        confirmDelete ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <Button onClick={e => Delete(e, false) } className="kipper_btn-outline-primary" variant="outlined" fullWidth>Cancelar</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <Button onClick={e => Delete(e, true)} variant="outlined" className="kipper_button modalbuttonFix" fullWidth>Eliminar</Button>
                                    </Box>
                                </Grid> 
                            </Grid> : null
                    }
                    
                    {
                        confirmOperation ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <Button onClick={ e => { Operation(e, false); onClose(); }} className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2} id="aceptarAppoiment">
                                        <Button onClick={ e => Operation(e, true) } variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Confirmar</Button>
                                    </Box>
                                </Grid> 
                            </Grid> : null
                    }

                    { rechazarAppoiment &&
                        <Grid container>
                            <Grid item xs={6} >
                                <Box px={2}>
                                    <Button onClick={ e => { Operation(e, false); onClose(); }} className="kipper_btn-outline-primary" variant="outlined" type="submit" fullWidth>Cancelar</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={6} >
                                <Box px={2} id="rechazarAppoiment">
                                    <Button onClick={ e => Operation(e, true) } variant="outlined" className="kipper_button modalbuttonFix" fullWidth type="submit" >Confirmar</Button>
                                </Box>
                            </Grid> 
                        </Grid> 
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

export default ModalSinSubmit;