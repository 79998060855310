import React from 'react'
import { Link } from 'react-router-dom';
import user from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Box, Typography } from '@material-ui/core'
import { useEffect } from 'react';
import { useState } from 'react';
import * as firebase from 'firebase';


const ChildItem = ({ id }) => {

    const [child, setChild] = useState('')
    
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`childs/${id}`);
        ref.once('value').then( snapshot => {
            if ( snapshot.val() ) {
                setChild( snapshot.val() )
            }
            else {
                setChild( false );   
            }
        });
    }, [ ] );

    return (
        <Link to={ `/tutor/citas/alumno/${child.group}/${id}` } >
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Box id="kiper_tutor_cita_childItem" p={2} display="flex" mt={1} >
                    <img src={ child?.avatar ? child?.avatar : user } alt="avatar" className="img_home" style={{height:"70px", width:"70px"}}/>
                    <Box  ml={2} className="align-items-center" >
                        <Typography variant="h6" className="kipper_txt-grey" >
                            { child?.name } { child?.last_name }
                        </Typography>
                        <Typography variant="body1" className="kipper_txt-grey" >
                            Turno { child?.details?.turn }, { child?.details?.grade } { child?.details?.group }
                        </Typography>
                    </Box>
                </Box>
                <Box >
                    <ArrowForwardIosIcon />
                </Box>
            </Grid>
            <hr/>
        </Link>
    )
}

export default ChildItem
