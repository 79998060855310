import React, { useContext } from 'react';
import { ListItemText,ListItemIcon  } from '@material-ui/core';
import { Link } from 'react-router-dom';

//Icons
import chat from '../../../../src/Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import logout from '../../../../src/Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import home from '../../../../src/Assets/Img/svg_icons/Icon feather-home.svg';
import calendar from '../../../../src/Assets/Img/svg_icons/Icon feather-calendar.svg';
import information from '../../../../src/Assets/Img/svg_icons/Icon ionic-ios-information-circle-outline.svg';
import solicitar from '../../../../src/Assets/Img/svg_icons/Icon awesome-sticky-note.svg';
import profile from '../../../../src/Assets/Img/svg_icons/Icon feather-user.svg';
import documents from '../../../../src/Assets/Img/svg_icons/Icon material-file-download.svg';
import settings from '../../../../src/Assets/Img/svg_icons/Icon feather-settings.svg';

const NavHeaderPhoneOptions =({StyledMenuItem,handlelogout})=>{

    return(
        <>
            <StyledMenuItem>
                <ListItemIcon>
                    <img src={home} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/" className="flex">
                    <ListItemText primary="Inicio" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ solicitar } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/citas/alumno" className="flex">
                    <ListItemText primary="Solicitar Citas" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={calendar} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/tutor/calendario" className="flex">
                    <ListItemText primary="Eventos" />
                </Link>  

            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ profile } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/profile" className="flex">
                    <ListItemText primary="Perfil" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ information } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/information" className="flex">
                    <ListItemText primary="Informacion" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ documents } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/documents" className="flex">
                    <ListItemText primary="Documentos" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ chat } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/chat" className="flex">
                    <ListItemText primary="Chat" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ settings } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/tutor/faq" className="flex">
                    <ListItemText primary="FAQ's" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ logout } alt="Usuario Kiper" />
                </ListItemIcon>
                
                <ListItemText primary="Salir" onClick={handlelogout}/>
          
            </StyledMenuItem>
        </>
    );
}

export default NavHeaderPhoneOptions;