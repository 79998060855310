import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../Components/Global/PageHeader';
import ModalShow from '../Components/Global/Modal';
import { checkFile } from '../Components/functions/helpapers';
import { checkFilepdf } from '../Components/functions/helpapers';

//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
import user from './../Assets/Img/General/placeholder_teacher-f.png';
import pencil from './../Assets/Img/Home/Icon_Pencil.png';

//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
//firebase
import * as firebase from 'firebase';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../Constants/textfield';
import Select from '../Constants/select';


function MaestroInfo () {

    const history = useHistory();
    const match = useRouteMatch('/maestros/informacion/:id');
    const teacherID = match.params.id;
    const [ refresh, setRefresh]                = useState(1);
    const [ avatar, setAvatar ]                 = useState('');

    const [ loadValues, setLoadValues ]         = useState({
        name: '',
        lastName: '',
        address: '',
        email: '',
        zipCode: '',
        city: '',
        gender: '',
        level: '',
        turn: '',
        grade: '',
        group: '',
        uid: ''
    })
    
    const [loading, setLoading] = useState({
        ofi: false,
        dom: false,
        opc: false
    }) 

    const [ docProfile, setDocProfile ]         = useState('');
    const [ docId, setDocId ]                   = useState('');
    const [ docProofAddres, setDocProofAddres ] = useState('');
    const [ docOpcional, setDocOpcional ]       = useState('');
    const [ ChangeProfile, setChangeProfile ]         = useState( false );
    const [ ChangeId, setChangeId ]                   = useState( false );
    const [ ChangeProofAddres, setChangeProofAddres ] = useState( false );
    const [ ChangeOpcional, setChangeOpcional ]       = useState( false );
    const [ info, setInfo ]                 = useState([]);
    const [dataSubjects, setDataSubjects]   = useState([])
    const { currentUser }           = useContext(AuthContext);
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    // Modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    useEffect(() => {
        let db = firebase.database();
        let childRef = db.ref(`teacher/${teacherID}`);
        childRef.once('value').then(function(snapshot) {
            let item = snapshot.val();
            setLoadValues({
                name: item.name,
                lastName: item.lastname,
                address: item.location.address,
                email: item.contact.email,
                zipCode: item.location.zip_code,
                city: item.location.city,
                gender: item.sex,
                level: item.teacher_type,
                turn: item.details.turn,
                grade: item.details.grade,
                group: item.details.group,
                uid: snapshot.key
            })
            setInfo     (item);
            setAvatar   (item.avatar);            
            setDocId    (item.documents.id);
            setDocProofAddres(item.documents.proof_addres);
            setDocOpcional(item.documents.opcional);
            setDataSubjects(item.school_subject )
        })
    }, []);

    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProfile( img );
                setChangeProfile( true );
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, ofi: true})
                setDocId( img );
                setChangeId( true );
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                        setDocId(profileImg.src)
                    })
                    setLoading({...loading, ofi: false})
                })
            } catch (error) {
                setLoading({...loading, ofi: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, ofi: false})
            seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, dom: true})
                setDocProofAddres( img );
                setChangeProofAddres( true );
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                        setDocProofAddres(profileImg.src)
                    })
                    setLoading({...loading, dom: false})
                })
            } catch (error) {
                setLoading({...loading, dom: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, dom: false})
            seterrorMsg({...errorMsg, comprobante: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, opc: true})
                setDocOpcional( img );
                setChangeOpcional( true );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional( profileImg.src );
                    })
                    setLoading({...loading, opc: false})
                })
            } catch (error) {
                setLoading({...loading, opc: false})
                console.log( error );
            }
        }else {
            setLoading({...loading, opc: false})
            seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const handleSubmit = val =>{
        const avatarIMG = ChangeProfile ?document.getElementById('AvatarIMG').src :avatar ;
        const idIMG = ChangeId ? document.getElementById('idIMG').src : docId ;
        const comprobanteIMG = ChangeProofAddres ? document.getElementById('comprobanteIMG').src : docProofAddres ;
        const opcionalIMG =  ChangeOpcional ? document.getElementById('opcionalIMG').src : docOpcional;

        let db = firebase.database();
        let teacherRef = db.ref(`teacher/${teacherID}`);
        teacherRef.update({
            name: val.name,
            lastname: val.lastName,
            sex: val.gender,
            teacher_type: val.level,
            avatar : avatarIMG,
            location:{
                address: val.address,
                zip_code: val.zipCode,
                city: val.city
            },
            details:{
                turn: val.turn,
                grade: val.grade,
                group: val.group,
            },
            contact:{
                email:val.email,
            },
            documents:{
                id: idIMG,
                proof_addres: comprobanteIMG,
                opcional: opcionalIMG,
            }
        }, (error) => {
            if (error) { console.log(error) } 
            else {
                setRefresh( refresh + 1 )
                setModalmsg('La información del maestro fue editada con éxito');
                setModalLink(`/maestros`);
                ModalOpen();
            }
        });
    }

    const handleclickAvatar = () => {
        document.getElementById("uploadFile").click();
    }
    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El nombre es obligatorio')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        lastName: yup
            .string('Ingresa un apellido')
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El apellido es obligatorio')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        address: yup
            .string('Ingresa una dirección')
            .max(150, "Se admite un máximo de 150 caracteres")
            .required('La dirección es obligatoria'),
        zipCode: yup
            .string()
            .required('El CP es obligatorio')
            .matches(/^(?:\d{5})?$/, 'El CP debe contener al menos 5 dígitos y ser númerico'),
        city: yup
            .string('Ingrese una ciudad')
            .max(20, "Se admite un máximo de 20 caracteres")
            .required('La ciudad es obligatoria')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        gender: yup
            .string('Seleccione el género')
            .required('El genero es obligatorio'),
        level: yup
            .string('Seleccione un nivel')
            .required('El nivel es obligatorio'),
        email: yup
            .string('Ingrese un correo electronico')
            .email('Ingrese un correo válido')
            .required('El correo electronico es obligatorio'),
    });
      
    const initialFormState ={
        name: loadValues.name,
        lastName: loadValues.lastName,
        address: loadValues.address,
        email: loadValues.email,
        zipCode: loadValues.zipCode,
        city: loadValues.city,
        gender: loadValues.gender,
        level: loadValues.level,
        turn: loadValues.turn,
        grade: loadValues.grade,
        group: loadValues.group,
        uid: loadValues.uid
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                enableReinitialize={true}
                validationSchema={validateForm}
                onSubmit={values =>{
                    handleSubmit(values)
                }}>


                    <Form>
                        <Grid container>
                            {/* <NavEscuelaPhone /> */}
                            <Grid item xs={12} sm={12} >
                                <Box  pb={3}>
                                    <PageHeader 
                                        title={`${info.name} ${info.lastname}`}
                                        text="Información y documentación del maestro"
                                        btn_back="/maestros"
                                        btn_action={true}
                                        btn_action_text="Guardar"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="Form_add-content">
                                <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                                    <Box >
                                        <img src={ portada } alt="" className="kipperBox_portada-img" />
                                    </Box>
                                    <Box  className="Form_add-profile-img">
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <div className="kipperBox_content-upload portion">
                                                {
                                                    ChangeProfile
                                                    ?<img id="AvatarIMG" className="rounded previw_img fit" alt="teacher avatar"/>
                                                    :<img className="fit previw_img rounded" src={ avatar ? avatar : user } alt="teacher avatar" />
                                                }
                                                <img src={pencil} style={{cursor:'pointer'}} className="icon_pencil kipper_txt-grey" onClick={e => handleclickAvatar()} alt="teacher avatar"  />
                                                <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { uploadAvatar(e) } }  />
                                            </div><br/>
                                            <Grid container direction="row" justify="center" alignItems="center">                                    
                                                { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box pt={3}>
                                    <Grid container>
                                    <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Nombre <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="name"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Apellidos <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="lastName" 
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Dirección <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="address" 
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Código Postal <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                    <TextField 
                                                        name="zipCode" 
                                                        inputProps={{ maxLength: 5 }}
                                                    />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Ciudad <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="city" 
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Sexo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select 
                                                    name="gender"
                                                    displayEmpty
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Masculino">Masculino</MenuItem>
                                                    <MenuItem value="Femenino">Femenino</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Box p={2}>
                                                <InputLabel shrink id="nivel">
                                                    Nivel <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select 
                                                    name="level"
                                                    displayEmpty 
                                                >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Kinder">Kinder</MenuItem>
                                                    <MenuItem value="Primaria">Primaria</MenuItem>
                                                    <MenuItem value="Secundaria">Secundaria</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink id="nivel">
                                                    Código de maestro <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="uid"
                                                    disabled                                           
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Email <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField 
                                                    name="email" 
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box px={2} pb={3}>
                                                <img src="" alt="" className="previw_img d-none" id={'idIMG'} onChange={e => setDocId( e )} />
                                                <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} onChange={e => setDocProofAddres( e )}/>
                                                <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} onChange={e => setDocOpcional( e )}/>
                                                {
                                                    !loading.ofi?
                                                        docId ?
                                                            <Box display="flex" pb={1}>
                                                                <a href={ `${docId}` } target="_blank" >
                                                                    Identificación
                                                                </a>
                                                                <Box ml={1}>
                                                                <a href={ `${docId}` } target="_blank" >
                                                                        <VisibilityIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                                <Box ml={1}>
                                                                    <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                                    <label for="docIdentificacion" className="kipper_txt-grey pointer">
                                                                        <CreateIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>

                                                                <Box ml={1}>
                                                                <a style={{cursor:'pointer'}} onClick={()=>setDocId('')} target="_blank" >
                                                                        <DeleteIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                            </Box>
                                                        :
                                                            <Box pb={1} >
                                                                <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                                <label for="docIdentificacion" className="kipper_txt-grey pointer">
                                                                    Agregar Identificación   <AddIcon className="kipper_txt-grey" />
                                                                </label>
                                                            </Box>
                                                    :<CircularProgress/>

                                                }
                                                { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                                {
                                                    !loading.dom?
                                                        docProofAddres ? 
                                                            <Box display="flex" pb={1}>
                                                                <a href={ `${docProofAddres}` } target="_blank">
                                                                    Comprobante de domicilio
                                                                </a>
                                                                <Box ml={1}>
                                                                <a href={ `${docProofAddres}` } target="_blank">
                                                                        <VisibilityIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                                <Box ml={1}>
                                                                    <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                                    <label for="docComprobante" className="kipper_txt-grey pointer">                                                            
                                                                        <CreateIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>
                                                                <Box ml={1}>
                                                                <a style={{cursor:'pointer'}} onClick={()=>setDocProofAddres('')} target="_blank" >
                                                                        <DeleteIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                            </Box>
                                                        :
                                                            <Box pb={1} >
                                                                <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                                <label for="docComprobante" className="kipper_txt-grey pointer">                                                            
                                                                    Agregar Comprobante de domicilio <AddIcon className="kipper_txt-grey" />
                                                                </label>
                                                            </Box>
                                                    :<CircularProgress/>
                                                }
                                                { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                                {
                                                    !loading.opc?
                                                        docOpcional ?
                                                            <Box display="flex" pb={2}>
                                                                <a href={ `${docOpcional}` } target="_blank">
                                                                    Documento extra
                                                                </a>
                                                                <Box ml={1}>
                                                                <a href={ `${docOpcional}` } target="_blank">
                                                                        <VisibilityIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                                <Box ml={1}>
                                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                                    <label for="docOpcional" className="kipper_txt-grey pointer">                                                            
                                                                        <CreateIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>
                                                                <Box ml={1}>
                                                                <a style={{cursor:'pointer'}} onClick={()=>setDocOpcional('')} target="_blank" >
                                                                        <DeleteIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                </Box>
                                                            </Box>
                                                        :
                                                            <Box pb={1}>
                                                                <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                                <label for="docOpcional" className="kipper_txt-grey pointer">                                                            
                                                                    Agregar Documento extra <AddIcon className="kipper_txt-grey" />
                                                                </label>
                                                            </Box>
                                                    :<CircularProgress/>
                                                }
                                                { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                <Typography className="" display="block" gutterBottom>
                                                    *Los documentos deben ser de tipo JPG, JPEG, PNG o PDF. 
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={6}>
                                            <Box px={2} pb={3}>
                                                <Typography className="" display="block" gutterBottom>
                                                    *Los documentos deben ser de tipo PDF, DOC, JPEG o PNG. 
                                                </Typography>
                                            </Box>
                                        </Grid> */}
                                    </Grid>
                                </Box>
                                </Box>
                            </Grid>
                        </Grid>   
                    </Form>
            </Formik>
            
        </>
    )
}

export default MaestroInfo