import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../Components/Global/PageHeader';
import ImformationItem from './../Components/Global/ImformationItem';
import ModalShow from '../Components/Global/Modal';
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
import user from './../Assets/Img/General/placeholder_man.png';
import pencil from './../Assets/Img/Home/Icon_Pencil.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

//firebase
import * as firebase from 'firebase';
import { checkFile, checkFilepdf } from '../Components/functions/helpapers';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../Constants/textfield';
import Select from '../Constants/select';


function AlumnoInfo () {

    const history = useHistory();
    const match = useRouteMatch('/alumnos/informacion/:id');
    const childID = match.params.id;
    const { currentUser }= useContext(AuthContext);
    const [ refresh, setRefresh ]   = useState( 0 );
    
    const [loading, setLoading] = useState({
        ofi: false,
        dom: false,
        opc: false
    }) 

    const [ loadValues, setLoadValues ] = useState({
        nombre: '',
        apellido: '',
        email:'',
        direccion: '',
        codigopostal: '',
        ciudad: '',
        sexo: '',
        turn: '',
        grade: '',
        group: '',
        level: '',
        observaciones: '',
        childID: ''
    })

    const formRef = useRef();

    const [avatar, setAvatar ]     = useState('');

    const [lastGroupId, setLastGroupId] = useState('')
    const [groupSchool, setGroupSchool] = useState('')
    const [groupInfo, setGroupInfo] = useState([])
    const [grados, setGrados] = useState([])
    const [grupos, setGrupos] = useState([])
    const [groupAct, setGroupAct] = useState(true)
    const [gradeAct, setGradeAct] = useState(true)
    const [active, setActive] = useState(true)

    const [docProfile, setDocProfile ]         = useState('');
    const [docId, setDocId ]                   = useState('');
    const [docProofAddres, setDocProofAddres ] = useState('');
    const [docOpcional, setDocOpcional ]       = useState('');

    const [ChangeProfile, setChangeProfile ]         = useState( false );
    const [ChangeId, setChangeId ]                   = useState( false );
    const [ChangeProofAddres, setChangeProofAddres ] = useState( false );
    const [ChangeOpcional, setChangeOpcional ]       = useState( false );

    const [info, setInfo]       = useState([]);
    const [tutor_1Avatar, setTutor_1Avatar] = useState('');
    const [tutor_1, setTutor_1] = useState('');
    const [tutor_2, setTutor_2] = useState('');
    const [tutor_3, setTutor_3] = useState('');
    const [tutor_4, setTutor_4] = useState('');
    const [infoTutor_1, setInfoTutor_1]       = useState([])
    const [infoTutor_2, setInfoTutor_2]       = useState([])
    const [infoTutor_3, setInfoTutor_3]       = useState([])
    const [infoTutor_4, setInfoTutor_4]       = useState([])
    const [errors, seterrors] = useState('');
    const [groupDetails, setGroupDetails] = useState([])
    const [ChangeTutor, setChangeTutor] = useState({
        tutor_1: false,
        tutor_2: false,
        tutor_3: false,
        tutor_4: false,
    });
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    let listGrados = []
    let listGrupos = []

    useEffect( () =>{
        let db = firebase.database();
        let list = [];
        let Ref = db.ref(`schools/${currentUser}/groups`);
        Ref.once('value').then( snapshot => {
            snapshot.forEach( item => {
                console.log('ITEM GROUP INFO: ', item)
                let itemInfo = item.val()
                    list.push( 
                        <item
                            key={ item.key }
                            grade={ itemInfo.grade }
                            group={ itemInfo.group }
                            turn={ itemInfo.turn }
                            level={ itemInfo.level }
                        />
                    )
            })
        })
        setGroupInfo( list )
    }, [ ] );

    useEffect( () =>{
        let db = firebase.database();
        let list = [];
        let Ref = db.ref(`schools/${currentUser}/groups/details`);
        Ref.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                list.push( itemInfo )
            })
        })
        setGroupDetails( list )
    }, [ ] );

    useEffect(() =>{
        let db = firebase.database();
        let childRef = db.ref(`childs/${childID}`);
        childRef.once('value').then(function(snapshot) {
            let item = snapshot.val()
            setInfo     (item);
            setAvatar   (item.avatar);

            setLoadValues({
                nombre: item.name,
                apellido: item.last_name,
                observaciones: item.observaciones ? item.observaciones:'',
                direccion: item.location.address,
                email: item.email,
                ciudad: item.location.city,
                codigopostal: item.location.zip_code,
                sexo: item.sex,
                turn: item.details.turn,
                grade: item.details.grade,
                level: item.child_type,
                group: item.details.group,
                childId: childID
            })
            
            setLastGroupId (item.group)
            setGroupSchool (item.group)

            let db;
            let tutorRef;
            if ( item.tutors?.tutor_1 ) {
                db = firebase.database();
                tutorRef = db.ref(`tutors/${item.tutors.tutor_1}`);
                tutorRef.once('value').then(function(snapshot) {
                    if ( snapshot.val() && (snapshot.val().status && snapshot.val().status === 'active')  ) setTutor_1(item.tutors.tutor_1);
                    else setTutor_1(false);                   
                })
            }
            else( 
                setTutor_1( false )
            )
            if ( item.tutors?.tutor_2 ) {
                db = firebase.database();
                tutorRef = db.ref(`tutors/${item.tutors.tutor_2}`);
                tutorRef.once('value').then(function(snapshot) {
                    if ( snapshot.val() && (snapshot.val().status && snapshot.val().status === 'active')  ) setTutor_2(item.tutors.tutor_2);
                    else setTutor_2(false);                   
                })
            }
            else( 
                setTutor_2( false )
            )
            if ( item.tutors?.tutor_3 ) {
                db = firebase.database();
                tutorRef = db.ref(`tutors/${item.tutors.tutor_3}`);
                tutorRef.once('value').then(function(snapshot) {
                    if ( snapshot.val() && (snapshot.val().status && snapshot.val().status === 'active')  ) setTutor_3(item.tutors.tutor_3);
                    else setTutor_3(false);                   
                })
            }
            else{
                setTutor_3( false )
            }
            if ( item.tutors?.tutor_4 ) {
                db = firebase.database();
                tutorRef = db.ref(`tutors/${item.tutors.tutor_4}`);
                tutorRef.once('value').then(function(snapshot) {
                    if ( snapshot.val() && (snapshot.val().status && snapshot.val().status === 'active')  ) setTutor_4(item.tutors.tutor_4);
                    else setTutor_4(false);                   
                })
            }
            else{
                setTutor_4( false )
            }

            if(item?.documents?.id){
                setDocId(item?.documents?.id)
            }
            if(item?.documents?.opcional){
                setDocOpcional(item?.documents?.opcional)
            }
            if(item?.documents?.proof_address){
                setDocProofAddres(item?.documents?.proof_address)
            }
        })
    }, [ refresh ] );

    const selectGroup = () => {
        setGroupSchool('')
        let grade = formRef.current.values.grade
        let group = formRef.current.values.group
        let turn = formRef.current.values.turn
        let level = formRef.current.values.level

        groupInfo.forEach( item => {
            if( item.props.grade === grade && item.props.group === group && item.props.turn === turn && item.props.level === level){
                setGroupSchool(item.key)
            }
        })
    }

    const fillGrados = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        setGrados([])
        setActive(false)
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        setGradeAct(false)
        setLoadValues({...loadValues, grade:''})
        groupInfo.forEach( item => {
            
            if(item.props.level === level && item.props.turn === turn && !listGrados.includes(item.props.grade) ){
                switch(item.props.grade){
                    case 'Primero':
                        listGrados.push([1,item.props.grade])
                        break;
                    case 'Segundo':
                        listGrados.push([2,item.props.grade])
                        break;
                    case 'Tercero':
                        listGrados.push([3,item.props.grade])
                        break;
                    case 'Cuarto':
                        listGrados.push([4,item.props.grade])
                        break;
                    case 'Quinto':
                        listGrados.push([5,item.props.grade])
                        break;
                    case 'Sexto':
                        listGrados.push([6,item.props.grade])
                        break;
                }
            }

            const unicos = []
            const aux = {}
            listGrados.forEach(el => !(el in aux) && (aux[el] = true) && unicos.push(el))
            console.log('unicos:  ', unicos)
            setGrados( unicos.sort((a,b) => a[0] -b[0]))

            if(listGrados.length === 0){
                setGrados(false)
            }
        })
    }

    const fillGrupos = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        let grade = formRef.current.values.grade
        setGrupos([])
        formRef.current.values.group = ''
        setGroupAct(false)
        setLoadValues({...loadValues, group:''})
        groupInfo.forEach( item => {
            if(item.props.level === level && item.props.turn === turn && item.props.grade === grade && !listGrupos.includes(item.props.group)){
                listGrupos.push(item.props.group)
            }
            setGrupos(listGrupos.sort())
            if(listGrupos.length === 0){
                setGrupos(false)
            }
        })
    }

    const reset = () => {       
        setGrados([])
        setGrupos([])
        setActive(false)
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        formRef.current.values.turn = ''
        listGrados = []
        listGrupos = []
    }
    
    const handleSubmit = val =>{
        const avatarIMG = ChangeProfile ? document.getElementById('AvatarIMG').src : info.avatar ;
        const idIMG = ChangeId ? document.getElementById('idIMG').src : (info.documents.id) ? info.documents.id : '' ;
        const comprobanteIMG = ChangeProofAddres ?document.getElementById('comprobanteIMG').src : (info.documents.proof_addres) ? info.documents.proof_addres : ''  ;
        const opcionalIMG =  ChangeOpcional ?document.getElementById('opcionalIMG').src : (info.documents.opcional) ? info.documents.opcional : '' ;
        let db = firebase.database();
        let childRef = db.ref(`childs/${childID}`);
        childRef.update({
            avatar: avatarIMG,
            name: val.nombre,
            observaciones: val.observaciones,
            last_name: val.apellido,
            child_type: val.level,
            group: groupSchool,
            location:{
                address: val.direccion,
                city: val.ciudad,
                zip_code: val.codigopostal
            },
            email:val.email,
            details:{
                grade: val.grade,
                group: val.group,
                turn: val.turn,
            },
            documents:{
                id: idIMG,
                proof_addres: comprobanteIMG,
                opcional: opcionalIMG,
            },
            
        }, function(error) {
            if (error) {// The write failed...
                console.log(error)
            } else {
                setRefresh( refresh + 1 )
                setModalmsg('Información actualizada');
                setModalLink(`/alumnos`);
                ModalOpen();
            }
        });

        let GroupRef = db.ref(`schools/${currentUser}/groups/${lastGroupId}/student/${childID}`);
        GroupRef.remove()

        let GroupRef1 = db.ref(`schools/${currentUser}/groups/${groupSchool}/student/${childID}`);
        GroupRef1.update({
            avatar: avatarIMG,
            name: val.nombre,
            last_name: val.apellido,
        }, 
        error => {
            if (error) {
                // The write failed...
                console.log(error)
            } 
            else {
                // Data saved successfully!
            }
        });

        // let GroupRef = db.ref(`schools/${currentUser}/groups/group767/student/child_1619681223427`);
        // GroupRef.remove()
         
    }

    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProfile( img );
                setChangeProfile( true );
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, ofi: true})
                setChangeId( true );
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                        setDocId(profileImg.src)
                    })
                    setLoading({...loading, ofi: false})
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            setLoading({...loading, ofi: false})
            return seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg o pdf' });
        }  
    }

    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, dom: true})
                setChangeProofAddres( true );
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                        setDocProofAddres(profileImg.src)
                    })
                    setLoading({...loading, dom: false})
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            setLoading({...loading, dom: false})
            return seterrorMsg({...errorMsg, comprobante: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 

    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, opc: true})
                setChangeOpcional( true );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional(profileImg.src)
                    })
                    setLoading({...loading, dom: false})
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            setLoading({...loading, dom: false})
            return seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg o pdf' });
        } 
    }

    const handleclickAvatar = () => document.getElementById("uploadFile").click();

    const validateForm = yup.object({
        nombre: yup
            .string('Ingrese un nombre')
            .required('El nombre es obligatorio')
            .matches(/^\w\D{2,50}$/, "El nombre debe contener entre 2 a 50 caracteres, sin numero" ),
        apellido: yup
            .string('Ingrese un apellido')
            .required('El apellido es obligatorio')
            .matches(/^\w\D{2,50}$/, "El apellido debe contener entre 2 a 50 caracteres, sin numero" ),
        email: yup
            .string('Ingrese un correo electronico')
            .email('Ingrese un correo válido')
            .required('El correo electronico es obligatorio'),
        direccion: yup
            .string('Ingrese una dirección')
            .required('La dirección es obligatoria'),
        codigopostal: yup
            .string()
            .required('El CP es obligatorio')
            .matches(/^(?:\d{5})?$/, 'El CP debe contener al menos 5 dígitos y ser númerico'),
        ciudad: yup
            .string('Ingrese una ciudad')
            .required('La ciudad es obligatoria')
            .matches(/^\w\D{2,50}$/, "La ciudad debe contener entre 2 a 50 caracteres, sin numero" ),
        sexo: yup
            .string('Seleccione el género')
            .required('El genero es obligatorio'),
        turn: yup
            .string('Seleccione un turno')
            .required('El turno es obligatorio'),
        grade: yup
            .string('Seleccione un grado')
            .required('El grado es obligatorio'),
        group: yup
            .string('Seleccione un grupo')
            .required('El grupo es obligatorio'),
        observaciones: yup
            .string('Ingrese observaciones')
            .required('Las observaciones son obligatorias'),
        level: yup
            .string('Seleccione el género')
            .required('El nivel es obligatorio'),
    });
      
    const initialFormState ={
        nombre: loadValues.nombre,
        apellido: loadValues.apellido,
        email: loadValues.email,
        direccion: loadValues.direccion,
        codigopostal: loadValues.codigopostal,
        ciudad: loadValues.ciudad,
        sexo: loadValues.sexo,
        turn: loadValues.turn,
        grade: loadValues.grade,
        group: loadValues.group,
        level: loadValues.level,
        observaciones: loadValues.observaciones,
        childID: childID
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                innerRef={formRef}
                enableReinitialize={true}
                validationSchema={validateForm}
                onSubmit={values =>{
                    handleSubmit(values)
                }}>


                <Form>
                    <Grid container>
                        {/* <NavEscuelaPhone /> */}
                        <Grid  xs={12} sm={12} >
                            <Box  pb={3}>
                                <PageHeader 
                                    title={`${info?.name} ${info?.last_name}`}
                                    text="Información y documentación del alumno"
                                    btn_back="/alumnos"
                                    btn_action={true}
                                    btn_action_text="Guardar"
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} className="Form_add-content">
                            <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                                <Box >
                                    <img src={  portada } alt="" class="kipperBox_portada-img" />
                                </Box>
                                <Box  className="Form_add-profile-img">
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <div className="kipperBox_content-upload">
                                            { ChangeProfile
                                                ?<img id="AvatarIMG" className="rounded previw_img fit" alt="teacher avatar"/>
                                                :<img src={ avatar ? avatar : user } alt="teacher avatar " className="rounded previw_img fit"  />
                                            }
                                            <img src={pencil} style={{cursor:'pointer'}} className="icon_pencil kipper_txt-grey" onClick={e => handleclickAvatar()} alt="teacher avatar"  />
                                            <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { uploadAvatar(e) } }  />
                                        </div><br/>
                                        <Grid container direction="row" justify="center" alignItems="center">                                    
                                            { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={3}>
                                    <Grid container>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink>
                                                    Nombre <b className="asterisk-w">*</b>
                                                </InputLabel> 
                                                <TextField name="nombre"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Apellido <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="apellido"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Correo electronico <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="email"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Dirección <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="direccion"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Código postal <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="codigopostal"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Ciudad <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField name="ciudad"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}> 
                                                <InputLabel shrink>
                                                    Sexo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select name="sexo">
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Masculino">Masculino</MenuItem>
                                                    <MenuItem value="Femenino">Femenino</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink> 
                                                    Nivel <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    name="level"
                                                    onBlur={ e => reset()}
                                                >
                                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Kinder">Kinder</MenuItem>
                                                    <MenuItem value="Primaria">Primaria</MenuItem>
                                                    <MenuItem value="Secundaria">Secundaria</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink id="turn">
                                                    Turno <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    name="turn"
                                                    onBlur={e => fillGrados()}
                                                >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="Matutino">Matutino</MenuItem>
                                                    <MenuItem value="Vespertino">Vespertino</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink id="grade">
                                                    Grado <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    name="grade"
                                                    onBlur={e => fillGrupos()}
                                                    disabled={active} 
                                                >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    {
                                                        gradeAct?
                                                            
                                                            <MenuItem value={ loadValues.grade } key={ loadValues.grade }>{  loadValues.grade }</MenuItem>
                                                            
                                                        :
                                                        grados ?
                                                            grados.map( item => 
                                                                <MenuItem value={ item[1] } key={ item[1] }>{ item[1] }</MenuItem>
                                                            )
                                                        :
                                                            <MenuItem disabled >No hay grados registrados en este turno</MenuItem>

                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink id="group">
                                                    Grupo <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select
                                                    name="group"
                                                    onBlur={e => selectGroup()}
                                                    disabled={active}
                                                >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                {
                                                    groupAct?
                                                        <MenuItem value={ loadValues.group } key={ loadValues.group }>{  loadValues.group }</MenuItem>
                                                    :
                                                    grupos ? 
                                                        grupos.map( item => 
                                                            <MenuItem value={ item } key={ item }>{  item }</MenuItem>
                                                        )
                                                    :
                                                        <MenuItem disabled >No hay grupos registrados en este grado</MenuItem>
                                                }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={3}>
                                            <Box p={2}>
                                                <InputLabel shrink id="group">
                                                    Código de alumno <b className="asterisk-w">*</b>
                                                </InputLabel> 
                                                <TextField name="childID" disabled
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box p={2}> 
                                            <InputLabel shrink >
                                                Observaciones <b className="asterisk-w">*</b>
                                            </InputLabel>               
                                                <TextField  
                                                    placeholder="Observaciones del alumno" 
                                                    className="text_area-style" 
                                                    name="observaciones"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2}>
                                                <Typography variant="h5" gutterBottom>
                                                    Documentación 
                                                </Typography>
                                            </Box>
                                            <Grid xs={12}>
                                                <Box px={2} pb={3}>
                                                <img src="" alt="" className="previw_img d-none" id={'idIMG'} onChange={e => setDocId( e.target.src )} />
                                                <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} onChange={e => setDocProofAddres( e )}/>
                                                <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} onChange={e => setDocOpcional( e )}/>
                                                    {
                                                        !loading.ofi?
                                                            docId ?
                                                                <Box display="flex" pb={2}>
                                                                    <a href={ `${docId}` } target="_blank" >
                                                                        Documento 1
                                                                    </a>
                                                                    <Box ml={1}>
                                                                    <a href={ `${docId}` } target="_blank" >
                                                                        <VisibilityIcon className="kipper_txt-grey" />
                                                                    </a>
                                                                    </Box>
                                                                    <Box ml={1} >
                                                                        <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                                        <label for="docIdentificacion" className="kipper_txt-grey pointer">
                                                                            <CreateIcon />
                                                                        </label>
                                                                    </Box>
                                                                </Box>
                                                            :
                                                                <Box pb={1} >
                                                                    <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                                    <label for="docIdentificacion" className="kipper_txt-grey pointer">
                                                                        Agregar Documento <AddIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>
                                                        :<CircularProgress/>
                                                    }
                                                    { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                                    {
                                                        !loading.dom?
                                                            docProofAddres ? 
                                                                <Box display="flex" pb={2}>
                                                                    <a href={ `${docProofAddres}` } target="_blank">
                                                                        Documento 2
                                                                    </a>
                                                                    <Box ml={1}>
                                                                        <a href={ `${docProofAddres}` } target="_blank">
                                                                            <VisibilityIcon className="kipper_txt-grey" />
                                                                        </a>
                                                                    </Box>
                                                                    <Box ml={1} >
                                                                        <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                                        <label for="docComprobante" className="kipper_txt-grey pointer">
                                                                            <CreateIcon />
                                                                        </label>
                                                                    </Box>
                                                                </Box>
                                                            :
                                                                <Box pb={1} >
                                                                    <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                                    <label for="docComprobante" className="kipper_txt-grey pointer">
                                                                        Agregar Documento <AddIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>
                                                        :<CircularProgress/>
                                                    }
                                                    { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                                    {
                                                        !loading.opc ?
                                                            docOpcional ?
                                                                <Box display="flex" pb={2}>
                                                                    <a href={ `${docOpcional}` } target="_blank">
                                                                        Documento extra
                                                                    </a>
                                                                    <Box ml={1}>
                                                                        <a href={ `${docOpcional}` } target="_blank">
                                                                            <VisibilityIcon className="kipper_txt-grey" />
                                                                        </a>
                                                                    </Box>
                                                                    <Box ml={1}>
                                                                        <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                                        <label for="docOpcional" className="kipper_txt-grey pointer">
                                                                            <CreateIcon />
                                                                        </label>
                                                                    </Box>
                                                                </Box>
                                                            :
                                                                <Box pb={1}>
                                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                                    <label for="docOpcional" className="kipper_txt-grey pointer">
                                                                        Agregar Documento <AddIcon className="kipper_txt-grey" />
                                                                    </label>
                                                                </Box>
                                                        :<CircularProgress/>
                                                    }
                                                    { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                    <Typography className="" variant="body1" display="block" gutterBottom>
                                                        *Los documentos deben ser de tipo JPG, JPEG, PNG o PDF. 
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2}>
                                                <Typography variant="h5" gutterBottom>
                                                    Tutor y delegados escolares  
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {infoTutor_1.avatar}
                                        <Grid item xs={12} md={6}>
                                            <ImformationItem
                                                childID={ childID }
                                                tutorID={ tutor_1 ? tutor_1 : false  }
                                                type={ 1 }
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                numberTutor="tutor_1"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImformationItem
                                                childID={ childID }
                                                tutorID={ infoTutor_2 ? tutor_2 : false }
                                                type={ 2 }
                                                delegado={ 2 }
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                numberTutor="tutor_2"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImformationItem
                                                childID={ childID }
                                                tutorID={ infoTutor_3 ? tutor_3 : false }
                                                type={ 2 }
                                                delegado={ 3 }
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                numberTutor="tutor_3"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImformationItem
                                                childID={ childID }
                                                tutorID={ infoTutor_4 ? tutor_4 : false }
                                                type={ 2 }
                                                delegado={ 4 }
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                numberTutor="tutor_4"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
        
    )
    
}

export default AlumnoInfo