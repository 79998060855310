import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
// MaterialUI
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import './../../Assets/Styles/Responsive.css';
// Imagenes
import home from './../../Assets/Img/Layout/Icon_Home.png';
import student from './../../Assets/Img/Layout/Icon_Alumnos.png';
import chat from './../../Assets/Img/Layout/chat_bubble.png';
import logout from './../../Assets/Img/Layout/Icon_Salir.png';
import evento from './../../Assets/Img/Layout/Icon_Evento.png';
import group from './../../Assets/Img/Layout/Icon_Group.png';
import maestros from './../../Assets/Img/Layout/Icon_Maestros.png';
import ajustes from './../../Assets/Img/Layout/father-settings.png';
import materias from './../../Assets/Img/Layout/Icon_list.png';
import { AuthContext } from '../functions/Auth';

const BottomNavSchool = () => {

    const { handlelogout } = useContext( AuthContext );

    return (
        <div className="bottomSchoolNav">
            <Link to="/home" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerSchool">
                    <img src={ home } />
                    <h4 >Inicio</h4>
                </div>
            </Link>
            <Link to="/maestros" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerSchool">
                    <img src={ maestros } />
                    <h4 >Maestros</h4>
                </div>
            </Link>
            {/* <Link to="/alumnos" className="flex">
                <BottomNavigationAction 
                    label="Alumnos" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ student } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    } 
                />
            </Link> */}
            <Link to="/grupos" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerSchool">
                    <img src={ group } />
                    <h4 >Grupos</h4>
                </div>
            </Link>
            {/* <Link to="/escuela/lista/materias" className="flex">
                <BottomNavigationAction 
                    label="Materias" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ materias } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <Link to="/comunicados" className="flex">
                <BottomNavigationAction 
                    label="Eventos y Comunicados" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ evento } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    } 
                />
            </Link> */}
            <Link to="/escuela/chat" className="flex">
                <div id="botomNav_item1" className="botomNav_itemContainerSchool">
                    <img src={ chat } />
                    <h4 >Chat</h4>
                </div>
            </Link>
            {/* <Link to="/sede" className="flex">
                <BottomNavigationAction 
                    label="Configuraciones" 
                    value="recents" 
                    style={{ outline: 'none' }}
                    icon={ 
                        <img src={ ajustes } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                    } 
                />
            </Link>
            <BottomNavigationAction 
                label="Salir" 
                value="recents" 
                style={{ outline: 'none' }}
                onClick={ () => handlelogout() }
                icon={ 
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                } 
            /> */}
            
        </div>
    )
}

export default BottomNavSchool
