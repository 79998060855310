import React from 'react'
import { Select } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { FormHelperText } from '@material-ui/core';

const SelectFieldComponent = ({
    name,
    options,
    ...others
}) => {

    const { setFieldValue } = useFormikContext()    
    const [field, data] = useField(name) 

    const handleChange = e => {
        const { value } = e.target
        setFieldValue(name, value)
    }
    
    const configSelect = {
        ...field,
        ...others,
        fullWidth: true,
        displayEmpty: true,
        onChange: handleChange
    }

    if(data && data.touched && data.error){
        configSelect.error = true
        configSelect.helperText = data.error
    }

    return (
        <>
            <Select {...configSelect}/>
            {
                (data && data.touched && data.error) &&
                <FormHelperText error> {data.error} </FormHelperText>                
            }
        </>
    )
}
export default SelectFieldComponent
