import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormHelperText } from '@material-ui/core';

//import 'moment-timezone';

//firebase
import * as firebase from 'firebase';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

//Components Override
import TextField from '../../Constants/textfield';
import Select from '../../Constants/select';

const GroupAssign = ( ) => {

    const history = useHistory();
    const {id:GroupID,editId} = useParams();

    const [ loadValues, setLoadValues ]         = useState({
        materia: '',
        maestro: '',
        timeStart: '',
        timeEnd: '',
        monday: false, 
        tuersday: false,
        wensday: false,
        tursday: false, 
        friday: false,
        saturday: false
    })

    const { currentUser }         = useContext(AuthContext);

    const [saveGroup, setSaveGroup ]  = useState( true );
    const [materiaInfo, setMateriaInfo] = useState([])
    const [maestroInfo, setMaestroInfo] = useState([])
    const [gruposInfo, setgruposInfo]   = useState([])
    const [maestro, setMaestro]         = useState('')
    const [maestroName, setMaestroName] = useState('');
    const [materia, setMateria]         = useState('')
    const [materiaTitle, setmateriaTitle]= useState('');
    const [materiaGrade, setmateriaGrade]= useState('');
    const [materiaDetails, setmateriaDetails]= useState('');

    const [classDay, setClassDay]       = useState({
        monday:false,
        horaInicioLunes:'',
        horaFinalLunes:'',

        tuersday:false,
        horaInicioMartes:'',
        horaFinalMartes:'',

        wensday:false,
        horaInicioMiercoles:'',
        horaFinalMiercoles:'',

        tursday:false,
        horaInicioJueves:'',
        horaFinalJueves:'',

        friday:false,
        horaInicioViernes:'',
        horaFinalViernes:'',

        saturday:false,
        horaInicioSabado:'',
        horaFinalSabado:'',
    })

    const [errorsHours, setErrorsHours]       = useState({
        noDaySelected:'',

        lunesError:false,
        lunesHelperError:'',
        
        martesError:false,
        martesHelperError:'',

        miercolesError:false,
        miercolesHelperError:'',

        juevesError:false,
        juevesHelperError:'',

        viernesError:false,
        viernesHelperError:'',

        sabadoError:false,
        sabadoHelperError:'',

    })

    const [errorsHoursEmpties, setErrorsHoursEmpties]       = useState({
        noDaySelected:'',

        lunesError:false,
        lunesHelperError:'',
        
        martesError:false,
        martesHelperError:'',

        miercolesError:false,
        miercolesHelperError:'',

        juevesError:false,
        juevesHelperError:'',

        viernesError:false,
        viernesHelperError:'',

        sabadoError:false,
        sabadoHelperError:'',

    })

    const [timeStart, setTimeStart]     = useState('');
    const [timeEnd, setTimeEnd]         = useState('');
    const [schedule, setSchedule]       = useState([7.6, 22.8])
    const [errors, seterrors]           = useState('');

    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };   

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect(() => {
        getSubjects()
    }, [])

    //Fill options to select subjects
    async function getSubjects () {
        let db = firebase.database();
        let listMaterias = [];
        let Ref1 = db.ref(`schools/${currentUser}/groups/${GroupID}`);
        let Ref2 = db.ref('school_subjects')
        let dataRef1 = await Ref1.once('value');
        let dataRef2 = await Ref2.once('value');
        var groupInfo = dataRef1.val();
        if(groupInfo)
        {
            dataRef2.forEach( item => {
                let data = item.val()
                if( groupInfo.grade == data.grade && groupInfo.level.toLowerCase() == data.level.toLowerCase() && data.school == currentUser && data.status == "active" ) {            
                    listMaterias.push( 
                    <item
                        key={ item.key }
                        id={  item.key  }
                        title={ `${data.title} ` }
                        grade={ `${data.grade} ` }
                        details={ `${data.details} ` }
                    />
                    )
                }
            })
            setMateriaInfo( listMaterias )
        }
    }

    //Fill options to select teachers...
    useEffect(() => {
        let db = firebase.database();
        let Ref3 = db.ref(`teacher`);
        let listMaestros = [];
        Ref3.once('value').then( (snapshot) => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                if ( itemInfo.status === "active"  && itemInfo.school === currentUser ) {
                    listMaestros.push( 
                        <item
                            key={ item.key }
                            avatar={ itemInfo.avatar }
                            name={ `${itemInfo.name} ${itemInfo.lastname} ` }
                        />
                    )
                }
            })
        })
        setMaestroInfo( listMaestros )    
    }, []);

    //Get information of the group like: grade, group, turn etc...
    useEffect( () =>{
        let db = firebase.database();
        let Ref = db.ref(`schools/${currentUser}/groups/${GroupID}`);
        Ref.once('value').then( (snapshot) => {    
            setgruposInfo( snapshot.val() )
        })
    }, [] );

    const handleChangeHorarioNuevo = (e) =>{

        switch (e.target.name) {
            case 'horaFinalLunes':
                    var horaInicioLunesLocal = moment(classDay.horaInicioLunes, 'hh:mm')
                    var horaFinalLunesLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalLunesLocal.isBefore(horaInicioLunesLocal)){
                        setErrorsHours({...errorsHours, lunesError:true, lunesHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{

                        setErrorsHours({...errorsHours, lunesError:false, lunesHelperError:''})
                        setClassDay({...classDay, horaFinalLunes:e.target.value})
                    }
                break;

            case 'horaFinalMartes':
                    var horaInicioMartesLocal = moment(classDay.horaInicioMartes, 'hh:mm')
                    var horaFinalMartesLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalMartesLocal.isBefore(horaInicioMartesLocal)){
                        setErrorsHours({...errorsHours, martesError:true, martesHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{
                        //alert('la hora del final es despues ')
                        setErrorsHours({...errorsHours, martesError:false, martesHelperError:''})
                        setClassDay({...classDay, horaFinalMartes:e.target.value})
                    }
                break;

            case 'horaFinalMiercoles':
                    var horaInicioMiercolesLocal = moment(classDay.horaInicioMiercoles, 'hh:mm')
                    var horaFinalMiercolesLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalMiercolesLocal.isBefore(horaInicioMiercolesLocal)){
                        setErrorsHours({...errorsHours, miercolesError:true, miercolesHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{
                        //alert('la hora del final es despues ')
                        setErrorsHours({...errorsHours, miercolesError:false, miercolesHelperError:''})
                        setClassDay({...classDay, horaFinalMiercoles:e.target.value})
                    }
                break;

            case 'horaFinalJueves':
                    var horaInicioJuevesLocal = moment(classDay.horaInicioJueves, 'hh:mm')
                    var horaFinalJuevesLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalJuevesLocal.isBefore(horaInicioJuevesLocal)){
                        setErrorsHours({...errorsHours, juevesError:true, juevesHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{
                        //alert('la hora del final es despues ')
                        setErrorsHours({...errorsHours, juevesError:false, juevesHelperError:''})
                        setClassDay({...classDay, horaFinalJueves:e.target.value})
                    }
                break;

            case 'horaFinalViernes':
                    var horaInicioViernesLocal = moment(classDay.horaInicioViernes, 'hh:mm')
                    var horaFinalViernesLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalViernesLocal.isBefore(horaInicioViernesLocal)){
                        setErrorsHours({...errorsHours, viernesError:true, viernesHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{
                        //alert('la hora del final es despues ')
                        setErrorsHours({...errorsHours, viernesError:false, viernesHelperError:''})
                        setClassDay({...classDay, horaFinalViernes:e.target.value})
                    }
                break;
        
            case 'horaFinalSabado':
                    var horaInicioSabadoLocal = moment(classDay.horaInicioSabado, 'hh:mm')
                    var horaFinalSabadoLocal = moment(e.target.value, 'hh:mm')
                    if(horaFinalSabadoLocal.isBefore(horaInicioSabadoLocal)){
                        setErrorsHours({...errorsHours, sabadoError:true, sabadoHelperError:'La hora de finalización debe ser mayor a la hora de inicio'})
                    }else{
                        //alert('la hora del final es despues ')
                        setErrorsHours({...errorsHours, sabadoError:false, sabadoHelperError:''})
                        setClassDay({...classDay, horaFinalSabado:e.target.value})
                    }
                break;
            default:
                break;
        }

        console.log('Hora recibida: ', e.target.name)
    }


    //ESTE VA A SER EL NUEVO SUBMIT
    const validateMoment = async val => {

        let db = firebase.database();
        let Usersref = db.ref(`schools/${currentUser}/groups/${GroupID}/school_subjects/${materia}`);
        

        
        const status = await validateHorarioMaestro(val)
        const statusGrupo = await validateHorarioGrupo(val)
        const statusCamposCapturados = await validateCapturaHorario()
        console.log('status: ', statusCamposCapturados)
        

        if(status && statusGrupo && statusCamposCapturados){
            //alert('no tiene horas repetidas o no tiene materias, aqui haces la insecion a la base de datos')
            Usersref.update({
                teacher_key: val.maestro,
                teacher_name: maestroName ? maestroName : '',
                days: classDay,
                // schedule:{
                //     0: val.timeStart,
                //     1: val.timeEnd,
                // },
                timestap: Date.now(),
            }, (error) => {
                if (error) {
                // The write failed...
                    setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
                else {
                    setSaveGroup( true )
                }
            });

            let TeacherRef = db.ref(`teacher/${maestro}/groups/${GroupID}/`);
            TeacherRef.update({
                group_key: GroupID,
                details:{
                    turn: gruposInfo.turn,
                    grade: gruposInfo.grade ,
                    group: gruposInfo.group,
                    level: gruposInfo.level,
                },
                school_subjects:{
                    school_subjects_key: val.materia,
                    school_subjects_title: materiaTitle ? materiaTitle : '',
                    school_subjects_grade: materiaGrade ? materiaGrade : '',
                    days: classDay,
                    // schedule:{
                    //     0: val.timeStart,
                    //     1: val.timeEnd,
                    // },
                }
            },
            error => {
                if ( saveGroup ) {
                    // Data saved successfully!
                    setModalmsg('Información guardada');
                    setModalLink(`/grupos/informacion/${GroupID}`);
                    ModalOpen();
                }
                else if (error) {
                    // The write failed...
                    setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
                else {
                    // The write failed...
                    // setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
            });    
        } else{
            //setModalLink()
            //return ModalOpen()
        }  
    }

    const validateHorarioMaestro = async(val) =>{
        let db = firebase.database();
        let status = true
        let datoTeacher = db.ref(`teacher/${val.maestro}/groups`);

        await datoTeacher.once('value').then(function(snapshot){
            

            if(snapshot.exists()){
                
                snapshot.forEach(item => {
                    let days = item.val().school_subjects?.days
                    let sub = item.val().school_subjects
                    let det = item.val().details
    
                    if(item.val().school_subjects.days.monday){
                        //console.log('entro aqui Maestro')
                        var horaInicioLunesBD = moment(item.val().school_subjects.days.horaInicioLunes, 'hh:mm');
                        var horaFinalLunesBD = moment(item.val().school_subjects.days.horaFinalLunes, 'hh:mm');

                        var horaInicioLunesLocal = moment(classDay.horaInicioLunes, 'hh:mm')
                        var horaFinalLunesLocal = moment(classDay.horaFinalLunes, 'hh:mm')
                        
                        //EXPLICACION
                        // condicion 1: verifica si la hora de inicio capturada esta entre alguna de las horas de inicio y de final en la bd del maestro
                        // condicion 2: verifica si la hora de inicio capturada sea igual a la hora de inicio de la base de datos
                        // condicion 3: lo mismo que la condicion 1 pero ahora con la hora final capturada
                        // condicion 4: lo mismo que la condicion 2 pero ahora con la hora final capturada
                        // condicion 5 verifica que la hora de inicio de la bd este entre la hora de inicio y de final capturadas
                        // si alguna de esas condiciones se cumplen muestra un aviso explicando que el horario capturado interviene con algun otro horario del profesor
                        
                        if(    horaInicioLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaInicioLunesLocal.isSame(horaInicioLunesBD)
                            || horaFinalLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaFinalLunesLocal.isSame(horaFinalLunesBD) 
                            || horaInicioLunesBD.isBetween(horaInicioLunesLocal, horaFinalLunesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los lunes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioLunes} a ${item.val().school_subjects.days.horaFinalLunes}`)
                                    status = false
                        }

                        
                    }
                    if(item.val().school_subjects.days.tursday){
                        var horaInicioMartesBD = moment(item.val().school_subjects.days.horaInicioMartes, 'hh:mm');
                        var horaFinalMartesBD = moment(item.val().school_subjects.days.horaFinalMartes, 'hh:mm');

                        var horaInicioMartesLocal = moment(classDay.horaInicioMartes, 'hh:mm')
                        var horaFinalMartesLocal = moment(classDay.horaFinalMartes, 'hh:mm')
                        
                        if(    horaInicioMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaInicioMartesLocal.isSame(horaInicioMartesBD)
                            || horaFinalMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaFinalMartesLocal.isSame(horaFinalMartesBD) 
                            || horaInicioMartesBD.isBetween(horaInicioMartesLocal, horaFinalMartesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los martes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioMartes} a ${item.val().school_subjects.days.horaFinalMartes}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.wensday){
                        var horaInicioMiercolesBD = moment(item.val().school_subjects.days.horaInicioMiercoles, 'hh:mm');
                        var horaFinalMiercolesBD = moment(item.val().school_subjects.days.horaFinalMiercoles, 'hh:mm');

                        var horaInicioMiercolesLocal = moment(classDay.horaInicioMiercoles, 'hh:mm')
                        var horaFinalMiercolesLocal = moment(classDay.horaFinalMiercoles, 'hh:mm')
                        
                        if(    horaInicioMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaInicioMiercolesLocal.isSame(horaInicioMiercolesBD)
                            || horaFinalMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaFinalMiercolesLocal.isSame(horaFinalMiercolesBD) 
                            || horaInicioMiercolesBD.isBetween(horaInicioMiercolesLocal, horaFinalMiercolesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los miercoles en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioMiercoles} a ${item.val().school_subjects.days.horaFinalMiercoles}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.tuersday){
                        var horaInicioJuevesBD = moment(item.val().school_subjects.days.horaInicioJueves, 'hh:mm');
                        var horaFinalJuevesBD = moment(item.val().school_subjects.days.horaFinalJueves, 'hh:mm');

                        var horaInicioJuevesLocal = moment(classDay.horaInicioJueves, 'hh:mm')
                        var horaFinalJuevesLocal = moment(classDay.horaFinalJueves, 'hh:mm')
                        
                        if(    horaInicioJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaInicioJuevesLocal.isSame(horaInicioJuevesBD)
                            || horaFinalJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaFinalJuevesLocal.isSame(horaFinalJuevesBD) 
                            || horaInicioJuevesBD.isBetween(horaInicioJuevesLocal, horaFinalJuevesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los jueves en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioJueves} a ${item.val().school_subjects.days.horaFinalJueves}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.friday){
                        var horaInicioViernesBD = moment(item.val().school_subjects.days.horaInicioViernes, 'hh:mm');
                        var horaFinalViernesBD = moment(item.val().school_subjects.days.horaFinalViernes, 'hh:mm');

                        var horaInicioViernesLocal = moment(classDay.horaInicioViernes, 'hh:mm')
                        var horaFinalViernesLocal = moment(classDay.horaFinalViernes, 'hh:mm')
                        
                        if(    horaInicioViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaInicioViernesLocal.isSame(horaInicioViernesBD)
                            || horaFinalViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaFinalViernesLocal.isSame(horaFinalViernesBD) 
                            || horaInicioViernesBD.isBetween(horaInicioViernesLocal, horaFinalViernesLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los viernes en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioViernes} a ${item.val().school_subjects.days.horaFinalViernes}`)
                                    status = false
                        }
                    }
                    if(item.val().school_subjects.days.saturday){
                        var horaInicioSabadoBD = moment(item.val().school_subjects.days.horaInicioSabado, 'hh:mm');
                        var horaFinalSabadoBD = moment(item.val().school_subjects.days.horaFinalSabado, 'hh:mm');

                        var horaInicioSabadoLocal = moment(classDay.horaInicioSabado, 'hh:mm')
                        var horaFinalSabadoLocal = moment(classDay.horaFinalSabado, 'hh:mm')
                        
                        if(    horaInicioSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaInicioSabadoLocal.isSame(horaInicioSabadoBD)
                            || horaFinalSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaFinalSabadoLocal.isSame(horaFinalSabadoBD) 
                            || horaInicioSabadoBD.isBetween(horaInicioSabadoLocal, horaFinalSabadoLocal)
                        ){
                                    setModalStaus(true)
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los sabado en ${det.grade} "${det.group}" de ${item.val().school_subjects.days.horaInicioSabado} a ${item.val().school_subjects.days.horaFinalSabado}`)
                                    status = false
                        }
                    }
                })
            }else{
                status = true
                //alert('esta llegando')
            }

        })
        return status
    }

    const validateHorarioGrupo = async(val) =>{
        let db = firebase.database();
        let status = true
       //let datoTeacher = db.ref(`teacher/${val.maestro}/groups`);
        let datoTeacher = db.ref(`schools/${currentUser}/groups/${GroupID}`);
        

        await datoTeacher.once('value').then(function(snapshot){
            
            
            if(snapshot.exists()){
                

               

                // let days = item.val().school_subjects?.days
                // let sub = item.val().school_subjects
                let det = snapshot.val()

                
                var materias = snapshot.val().school_subjects

                if(materias != undefined){

                    Object.values(materias).forEach(item => {

                        console.log('ITEM: ', item)


                        
                            // if(item.days.monday === true) console.log('entra al lunes')
                                
                            // else if(item.days.tursday === true) console.log('entra al martes')
                                
                            // else if(item.days.wensday === true) console.log('entra al miercoles')
                            
                            // else if(item.days.tuersday === true) console.log('entra al jueves')

                            // else if(item.days.friday === true) console.log('entra al viernes')

                            // else if(item.days.saturday === true) console.log('entra al sabado')

                        
                        if(item.days.monday){
                            
                            var horaInicioLunesBD = moment(item.days.horaInicioLunes, 'hh:mm');
                            var horaFinalLunesBD = moment(item.days.horaFinalLunes, 'hh:mm');

                            var horaInicioLunesLocal = moment(classDay.horaInicioLunes, 'hh:mm')
                            var horaFinalLunesLocal = moment(classDay.horaFinalLunes, 'hh:mm')
                            
                            //EXPLICACION
                            // condicion 1: verifica si la hora de inicio capturada esta entre alguna de las horas de inicio y de final en la bd del maestro
                            // condicion 2: verifica si la hora de inicio capturada sea igual a la hora de inicio de la base de datos
                            // condicion 3: lo mismo que la condicion 1 pero ahora con la hora final capturada
                            // condicion 4: lo mismo que la condicion 2 pero ahora con la hora final capturada
                            // condicion 5 verifica que la hora de inicio de la bd este entre la hora de inicio y de final capturadas
                            // si alguna de esas condiciones se cumplen muestra un aviso explicando que el horario capturado interviene con algun otro horario del profesor
                            
                            if(    horaInicioLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaInicioLunesLocal.isSame(horaInicioLunesBD)
                                || horaFinalLunesLocal.isBetween(horaInicioLunesBD, horaFinalLunesBD) || horaFinalLunesLocal.isSame(horaFinalLunesBD) 
                                || horaInicioLunesBD.isBetween(horaInicioLunesLocal, horaFinalLunesLocal)
                            ){
                                        console.log('entro aqui grupo CONDICION')
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los lunes con el profesor ${item.teacher_name} de ${item.days.horaInicioLunes} a ${item.days.horaFinalLunes}`)
                                        status = false
                            }

                            
                        }
                        if(item.days.tursday){
                            var horaInicioMartesBD = moment(item.days.horaInicioMartes, 'hh:mm');
                            var horaFinalMartesBD = moment(item.days.horaFinalMartes, 'hh:mm');

                            var horaInicioMartesLocal = moment(classDay.horaInicioMartes, 'hh:mm')
                            var horaFinalMartesLocal = moment(classDay.horaFinalMartes, 'hh:mm')
                            
                            if(    horaInicioMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaInicioMartesLocal.isSame(horaInicioMartesBD)
                                || horaFinalMartesLocal.isBetween(horaInicioMartesBD, horaFinalMartesBD) || horaFinalMartesLocal.isSame(horaFinalMartesBD) 
                                || horaInicioMartesBD.isBetween(horaInicioMartesLocal, horaFinalMartesLocal)
                            ){
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los martes con el profesor ${item.teacher_name} de ${item.days.horaInicioMartes} a ${item.days.horaFinalMartes}`)
                                        status = false
                            }
                        }
                        if(item.days.wensday){
                            var horaInicioMiercolesBD = moment(item.days.horaInicioMiercoles, 'hh:mm');
                            var horaFinalMiercolesBD = moment(item.days.horaFinalMiercoles, 'hh:mm');

                            var horaInicioMiercolesLocal = moment(classDay.horaInicioMiercoles, 'hh:mm')
                            var horaFinalMiercolesLocal = moment(classDay.horaFinalMiercoles, 'hh:mm')
                            
                            if(    horaInicioMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaInicioMiercolesLocal.isSame(horaInicioMiercolesBD)
                                || horaFinalMiercolesLocal.isBetween(horaInicioMiercolesBD, horaFinalMiercolesBD) || horaFinalMiercolesLocal.isSame(horaFinalMiercolesBD) 
                                || horaInicioMiercolesBD.isBetween(horaInicioMiercolesLocal, horaFinalMiercolesLocal)
                            ){
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los miercoles con el profesor ${item.teacher_name} de ${item.days.horaInicioMiercoles} a ${item.days.horaFinalMiercoles}`)
                                        status = false
                            }
                        }
                        if(item.days.tuersday){
                            var horaInicioJuevesBD = moment(item.days.horaInicioJueves, 'hh:mm');
                            var horaFinalJuevesBD = moment(item.days.horaFinalJueves, 'hh:mm');

                            var horaInicioJuevesLocal = moment(classDay.horaInicioJueves, 'hh:mm')
                            var horaFinalJuevesLocal = moment(classDay.horaFinalJueves, 'hh:mm')
                            
                            if(    horaInicioJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaInicioJuevesLocal.isSame(horaInicioJuevesBD)
                                || horaFinalJuevesLocal.isBetween(horaInicioJuevesBD, horaFinalJuevesBD) || horaFinalJuevesLocal.isSame(horaFinalJuevesBD) 
                                || horaInicioJuevesBD.isBetween(horaInicioJuevesLocal, horaFinalJuevesLocal)
                            ){
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los jueves con el profesor ${item.teacher_name} de ${item.days.horaInicioJueves} a ${item.days.horaFinalJueves}`)
                                        status = false
                            }
                        }
                        if(item.days.friday){
                            var horaInicioViernesBD = moment(item.days.horaInicioViernes, 'hh:mm');
                            var horaFinalViernesBD = moment(item.days.horaFinalViernes, 'hh:mm');

                            var horaInicioViernesLocal = moment(classDay.horaInicioViernes, 'hh:mm')
                            var horaFinalViernesLocal = moment(classDay.horaFinalViernes, 'hh:mm')
                            
                            if(    horaInicioViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaInicioViernesLocal.isSame(horaInicioViernesBD)
                                || horaFinalViernesLocal.isBetween(horaInicioViernesBD, horaFinalViernesBD) || horaFinalViernesLocal.isSame(horaFinalViernesBD) 
                                || horaInicioViernesBD.isBetween(horaInicioViernesLocal, horaFinalViernesLocal)
                            ){
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los viernes con el profesor ${item.teacher_name} de ${item.days.horaInicioViernes} a ${item.days.horaFinalViernes}`)
                                        status = false
                            }
                        }
                        if(item.days.saturday){
                            var horaInicioSabadoBD = moment(item.days.horaInicioSabado, 'hh:mm');
                            var horaFinalSabadoBD = moment(item.days.horaFinalSabado, 'hh:mm');

                            var horaInicioSabadoLocal = moment(classDay.horaInicioSabado, 'hh:mm')
                            var horaFinalSabadoLocal = moment(classDay.horaFinalSabado, 'hh:mm')
                            
                            if(    horaInicioSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaInicioSabadoLocal.isSame(horaInicioSabadoBD)
                                || horaFinalSabadoLocal.isBetween(horaInicioSabadoBD, horaFinalSabadoBD) || horaFinalSabadoLocal.isSame(horaFinalSabadoBD) 
                                || horaInicioSabadoBD.isBetween(horaInicioSabadoLocal, horaFinalSabadoLocal)
                            ){
                                        setModalStaus(true)
                                        setModalmsg(`Horario empalmado. El grupo ${det.grade} "${det.group}" tiene clase los sabados con el profesor ${item.teacher_name} de ${item.days.horaInicioSabado} a ${item.days.horaFinalSabado}`)
                                        status = false
                            }
                        }
                    })
                }


            }else{
                status = true
                //alert('esta llegando')
            }

        })
        return status
    }

    const validateCapturaHorario = async() => {

        return new Promise(resolve =>{
            var status = true
           
            if(!classDay.monday && !classDay.tursday && !classDay.wensday && !classDay.tuersday && !classDay.friday && !classDay.saturday){
                setErrorsHours({...errorsHours, noDaySelected:'Debes seleccionar por lo menos un dia'}) 
                status=false
                console.log('esta llegando a la funcion de la promesa')
                resolve(status)
            }

            if(classDay.monday){
                if(classDay.horaInicioLunes === '' || classDay.horaFinalLunes === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, lunesError:true, lunesHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }

            if(classDay.tursday){
                if(classDay.horaInicioMartes === '' || classDay.horaFinalMartes === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, martesError:true, martesHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }

            if(classDay.wensday){
                if(classDay.horaInicioMiercoles === '' || classDay.horaFinalMiercoles === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, miercolesError:true, miercolesHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }

            if(classDay.tuersday){
                if(classDay.horaInicioJueves === '' || classDay.horaFinalJueves === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, juevesError:true, juevesHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }

            if(classDay.friday){
                if(classDay.horaInicioViernes === '' || classDay.horaFinalViernes === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, viernesError:true, viernesHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }

            if(classDay.saturday){
                if(classDay.horaInicioSabado === '' || classDay.horaFinalSabado === ''){
                    setErrorsHours({...errorsHours, noDaySelected:''}) 
                    setErrorsHoursEmpties({...errorsHoursEmpties, sabadoError:true, sabadoHelperError:'Ambos horarios deben estar correctamente capturados'})
                    status=false
                    resolve(status)
                }
            }
            else{
                //setErrorsHours({...errorsHours, noDaySelected:''}) 
                resolve(status)
            }

        })

    }
   
    const validateHorario = async (val)  =>{
        let db = firebase.database();
        let status = true
        let [hTS, mTS] = val.timeStart.split(":");
        let datoTeacher = db.ref(`teacher/${val.maestro}/groups`);
        await datoTeacher.once('value').then( (snapshot) => {
            snapshot.forEach(item => {
                let days = item.val().school_subjects?.days
                let sub = item.val().school_subjects
                let det = item.val().details
                let [hourTS, minuteTS] = item.val()?.school_subjects?.schedule[0].split(":");
                let [hourTE, minuteTE] = item.val()?.school_subjects?.schedule[1].split(":");
                for(const day of Object.keys(days)){
                    switch(day){
                        case "monday":
                            if(days.monday && classDay.monday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los lunes de ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break
                        case "tuersday":
                            if(days.tuersday && classDay.tuersday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los martes de ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break
                        case "wensday":
                            if(days.wensday && classDay.wensday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los miercoles de ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break
                        case "tursday":
                            if(days.tursday && classDay.tursday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los jueves de ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break    
                        case "friday":
                            if(days.friday && classDay.friday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene clase los viernes de  ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break
                        case "saturday":
                            if(days.saturday && classDay.saturday){
                                if(checkTime(hourTS, minuteTS, hourTE, minuteTE, hTS, mTS)){
                                    setModalmsg(`Horario empalmado. El docente ${maestroName} tiene otra clase los sabados de ${sub.school_subjects_title} en ${det.grade} "${det.group}" de ${sub.schedule[0]} a ${sub.schedule[1]}`)
                                    status = false
                                }
                            }
                            break
                        default:
                            setModalmsg(`Intentalo de nuevo en unos minutos`)
                            status = false
                    }
                }
            })
        })
        return status
    }


    const handleSubmit = async val =>{
        let db = firebase.database();

        let Usersref = db.ref(`schools/${currentUser}/groups/${GroupID}/school_subjects/${materia}`);
        //const status = await validateHorario(val)
        
        if(1<2){
            Usersref.update({
                teacher_key: val.maestro,
                teacher_name: maestroName ? maestroName : '',
                days: classDay,
                // schedule:{
                //     0: val.timeStart,
                //     1: val.timeEnd,
                // },
                timestap: Date.now(),
            }, (error) => {
                if (error) {
                // The write failed...
                    setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
                else {
                    setSaveGroup( true )
                }
            });

            let TeacherRef = db.ref(`teacher/${maestro}/groups/${GroupID}/`);
            TeacherRef.update({
                group_key: GroupID,
                details:{
                    turn: gruposInfo.turn,
                    grade: gruposInfo.grade ,
                    group: gruposInfo.group,
                    level: gruposInfo.level,
                },
                school_subjects:{
                    school_subjects_key: val.materia,
                    school_subjects_title: materiaTitle ? materiaTitle : '',
                    school_subjects_grade: materiaGrade ? materiaGrade : '',
                    days: classDay,
                    // schedule:{
                    //     0: val.timeStart,
                    //     1: val.timeEnd,
                    // },
                }
            },
            error => {
                if ( saveGroup ) {
                    // Data saved successfully!
                    setModalmsg('Información guardada');
                    setModalLink(`/grupos/informacion/${GroupID}`);
                    ModalOpen();
                }
                else if (error) {
                    // The write failed...
                    setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
                else {
                    // The write failed...
                    // setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink(`/grupos/informacion/${GroupID}`)
                } 
            });    
        } else{
            setModalLink()
            return ModalOpen()
        }  
    }

    

    const checkTime = (hTS, mTS, hTE, mTE, hiTS, miTS) => {
        var start =  hTS * 60 + mTS;
        var end   = hTE * 60 + mTE;
        var startIN =  hiTS * 60 + miTS;

        return startIN >= start && startIN < end;
    }

    const handleChangeCheckBox = ( e ) => {
        setClassDay({ ...classDay, [ e.target.name ]: e.target.checked })
        setLoadValues({ ...loadValues, [ e.target.name ]: e.target.checked })
    }

    const handleChangeMateria = ( e ) => {
        materiaInfo.forEach( item => {
            if ( `${item.key}` === `${e.target.value}` ) {
                setMateria(item.props.id)
                setmateriaTitle( item.props.title )
                setmateriaDetails( item.props.details )
                setmateriaGrade( item.props.grade )
            }
        })
    }
    const handleChangeMestro = ( e ) => {
        maestroInfo.forEach( item => {
           
            if ( `${item.key}` === `${e.target.value}` ) {
                setMaestroName( `${item.props.name}` )
                setMaestro(item.key)
            }
        })
    }

    const options = [
        {
          id: 0,
          name: "monday",
          label: "Lunes"
        },
        {
          id: 1,
          name: "tuersday",
          label: "Martes"
        },
        {
          id: 2,
          name: "wensday",
          label: "Miercoles"
        },
        {
          id: 3,
          name: "tursday",
          label: "Jueves"
        },
        {
          id: 4,
          name: "friday",
          label: "Viernes"
        },
        {
          id: 5,
          name: "saturday",
          label: "Sabado"
        }
    
    ];

      
    const validateForm = yup.object({
        materia: yup
            .string('Selecciona una materia')
            .required('Debe seleccionar una materia obligatoriamente'),
        maestro: yup
            .string('Selecciona un maestro')
            .required('Debe seleccionar un maestro obligatoriamente'),
        // timeStart: yup
        //     .string()
        //     .required('Defina una hora de inicio'),
        // timeEnd: yup
        //     .string()
        //     .required('Defina una hora de finalización')
        //     .test("is-greater", "La hora de finalización debe ser mayor a la hora de inicio", function(value) {
        //         const { timeStart } = this.parent;
        //         return moment(value, "HH:mm").isAfter(moment(timeStart, "HH:mm"));
        //     }),
        // monday:  yup
        // .string()
        // .test('test-name', 'Seleccione al menos un día', 
        //     function() {
        //         if(loadValues.monday || loadValues.tuersday || loadValues.wensday || loadValues.tursday || loadValues.friday || loadValues.saturday){
        //             return true
        //         }else{
        //             return false
        //         }
        //     })
        // .required('Seleccione al menos un día')
    });

    const initialFormState ={
        materia: loadValues.materia,
        maestro: loadValues.maestro,
        // timeStart: loadValues.timeStart,
        // timeEnd: loadValues.timeEnd,
        // monday: false, 
        // tuersday: false,
        // wensday: false,
        // tursday: false,
        // friday: false,
        // saturday: false
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            
            
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                enableReinitialize={true}
                validationSchema={validateForm}
                onSubmit={values =>{
                    validateMoment(values)
                }}>
                    {({touched, errors}) => (

                <Form>
                    <Grid container>

                        <Grid item xs={12} sm={12} >
                            <Box pb={3}>
                                <PageHeader
                                    title="Agregar horario de la materia"
                                    text="Define información de la materia"
                                    btn_back={`/grupos/informacion/${GroupID}`}
                                    btn_action={true}
                                    btn_action_text="Guardar"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} className="Form_add-content">
                            <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                                <Box >
                                    <img src={ portada } alt="" className="kipperBox_portada-img" />
                                </Box>
                                <Box p={3}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={6}>
                                            <Box p={2}>
                                                <InputLabel shrink id="materia">
                                                    Materia <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select 
                                                    name="materia" 
                                                    onBlur={e => handleChangeMateria( e )} >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    { 
                                                        materiaInfo ?
                                                            materiaInfo.map( item => <MenuItem value={item.key} key={item.key} ><em>{item.props.title}</em></MenuItem> )
                                                        : 
                                                        <MenuItem disabled value=""><em>Sin materias para este grado</em></MenuItem>
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box p={2}>
                                                <InputLabel shrink id="maestro">
                                                    Maestro <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <Select 
                                                    name="maestro" 
                                                    onBlur={e => handleChangeMestro( e )}
                                                    >
                                                    <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                    {
                                                        maestroInfo ? 
                                                            maestroInfo.map( item => <MenuItem value={item.key} key={item.key} ><em> { item.props.name } { item.props.lastname } </em></MenuItem> )
                                                        : 
                                                        <MenuItem disabled value=""><em>Sin maestros</em></MenuItem>
                                                    }
                                                </Select>

                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box px={2}>
                                                <Typography variant="h5" className="" >
                                                    Horario 
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box px={2}>
                                                <Typography variant="h6" className="kipper_txt-grey" >
                                                    Día <b className="asterisk-w">*</b>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                        
                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            
                                                            <Checkbox onChange={ e => setClassDay({...classDay, monday:!classDay.monday }) }   color="primary" name={'monday'} />
                                                              
                                                        }
                                                        
                                                        label='Lunes'
                                                    />
                                                    {
                                                        errorsHours.noDaySelected != ''
                                                            ?<small style={{color:'red'}}>{errorsHours.noDaySelected}</small>
                                                            :null
                                                    }
                                                    
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid  container spacing={2} xs={12}>

                                                        <Grid  item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioLunes"
                                                                    type="time"
                                                                    disabled={!classDay.monday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioLunes:e.target.value })}
                                                                    
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalLunes"
                                                                    type="time"
                                                                    disabled={!classDay.monday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalLunes}
                                                                    helperText={errorsHours.lunesHelperError}
                                                                    error={errorsHours.lunesError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    
                                                    
                                                    <br />
                                                </Box>

                                                {
                                                    errorsHoursEmpties.lunesError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.lunesHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>
                                            
                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            <Checkbox onChange={ e => setClassDay({...classDay, tursday:!classDay.tursday }) }   color="primary" name={'tursday'} />  
                                                        }
                                                        
                                                        label='Martes'
                                                    />
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid container spacing={2} xs={12}>

                                                        <Grid item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioMartes"
                                                                    type="time"
                                                                    disabled={!classDay.tursday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioMartes:e.target.value })}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalMartes"
                                                                    type="time"
                                                                    disabled={!classDay.tursday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalMartes}
                                                                    helperText={errorsHours.martesHelperError}
                                                                    error={errorsHours.martesError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    

                                                    <br />
                                                </Box>
                                                {
                                                    errorsHoursEmpties.martesError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.martesHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>
                                        
                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            <Checkbox onChange={ e => setClassDay({...classDay, wensday:!classDay.wensday }) }   color="primary" name={'wensday'} />  
                                                        }
                                                        
                                                        label='Miercoles'
                                                    />
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid container spacing={2} xs={12}>

                                                        <Grid item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioMiercoles"
                                                                    type="time"
                                                                    disabled={!classDay.wensday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioMiercoles:e.target.value })}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalMiercoles"
                                                                    type="time"
                                                                    disabled={!classDay.wensday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalMiercoles}
                                                                    helperText={errorsHours.miercolesHelperError}
                                                                    error={errorsHours.miercolesError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    

                                                    <br />
                                                </Box>
                                                {
                                                    errorsHoursEmpties.miercolesError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.miercolesHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>
                                        
                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            <Checkbox onChange={ e => setClassDay({...classDay, tuersday:!classDay.tuersday }) } color="primary" name={'tuersday'} />  
                                                        }
                                                        
                                                        label='Jueves'
                                                    />
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid container spacing={2} xs={12}>

                                                        <Grid item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioJueves"
                                                                    type="time"
                                                                    disabled={!classDay.tuersday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioJueves:e.target.value })}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalJueves"
                                                                    type="time"
                                                                    disabled={!classDay.tuersday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalJueves}
                                                                    helperText={errorsHours.juevesHelperError}
                                                                    error={errorsHours.juevesError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    

                                                    <br />
                                                </Box>
                                                {
                                                    errorsHoursEmpties.juevesError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.juevesHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>

                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            <Checkbox onChange={ e => setClassDay({...classDay, friday:!classDay.friday }) } color="primary" name={'friday'} />  
                                                        }
                                                        
                                                        label='Viernes'
                                                    />
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid container spacing={2} xs={12}>

                                                        <Grid item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioViernes"
                                                                    type="time"
                                                                    disabled={!classDay.friday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioViernes:e.target.value })}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalViernes"
                                                                    type="time"
                                                                    disabled={!classDay.friday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalViernes}
                                                                    helperText={errorsHours.viernesHelperError}
                                                                    error={errorsHours.viernesError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    

                                                    <br />
                                                </Box>
                                                {
                                                    errorsHoursEmpties.viernesError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.viernesHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>

                                            <Box style={{backgroundColor:''}} px={2} >
                                                <Box xs={12}>
                                                    <FormControlLabel
                                                    
                                                        control={
                                                            <Checkbox onChange={ e => setClassDay({...classDay, saturday:!classDay.saturday }) } color="primary" name={'saturday'} />  
                                                        }
                                                        
                                                        label='Sabado'
                                                    />
                                                    <br />
                                                    <br />

                                                    
                                                    <Grid container spacing={2} xs={12}>

                                                        <Grid item xs={6}>
                                                            
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaInicioSabado"
                                                                    type="time"
                                                                    disabled={!classDay.saturday}
                                                                    onChange={e => setClassDay({...classDay, horaInicioSabado:e.target.value })}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Box px={2}>
                                                                <InputLabel shrink id="timeStart">
                                                                    Hora final <b className="asterisk-w">*</b>
                                                                </InputLabel>
                                                                <TextField
                                                                    name="horaFinalSabado"
                                                                    type="time"
                                                                    disabled={!classDay.saturday}
                                                                    onChange={e => 
                                                                        //setClassDay({...classDay, horaFinalLunes:e.target.value })
                                                                        handleChangeHorarioNuevo(e)
                                                                    }
                                                                    value={classDay.horaFinalSabado}
                                                                    helperText={errorsHours.sabadoHelperError}
                                                                    error={errorsHours.sabadoError}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                    </Grid>

                                                    

                                                    <br />
                                                </Box>
                                                {
                                                    errorsHoursEmpties.sabadoError
                                                        ?<small style={{color:'red'}}>{errorsHoursEmpties.sabadoHelperError}</small>
                                                        :null
                                                }
                                                
                                                <br />
                                                <br />
                                            </Box>

                                        </Grid>

                                        {/* <Grid item xs={12}>
                                            <Box px={2}>
                                                <Typography variant="h6" className="kipper_txt-grey">
                                                    Horario de clase
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box px={2}>
                                                <InputLabel shrink id="timeStart">
                                                    Hora de inicio <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField
                                                    name="timeStart"
                                                    type="time"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box px={2}>
                                                <InputLabel shrink id="timeEnd">
                                                    Hora final <b className="asterisk-w">*</b>
                                                </InputLabel>
                                                <TextField
                                                    name="timeEnd"
                                                    type="time"
                                                />
                                            </Box>
                                        </Grid> */}
                                    
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
                )}
            </Formik>
        </>
    )

}

export default GroupAssign
