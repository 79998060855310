import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, Link } from 'react-router-dom';
// import  './../../Assets/Styles/Responsive.css';
//Components
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import BoxImg from './../../Assets/Img/General/portada_alumnos.png';
import user from './../../Assets/Img/General/placeholder_boy.png';
//materialui
import { Paper, Typography, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// firebases
import * as firebase from 'firebase';
import PageHeader from './../../Components/Global/PageHeader';
import ChildItem from '../../Components/tutors/ChildItem';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';


function AlumnosForTutor () {

    const history   = useHistory();
    const { currentUser, infoSchool } = useContext(AuthContext);
    const [ChildInfo, setChildInfo] = useState([]);

    useEffect(() => {
        console.log(ChildInfo)
    }, [])

    useEffect( () =>{

        let db = firebase.database();
        let ref = db.ref(`tutors/${currentUser}/childs`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                setChildInfo( snapshot.val() );
                console.log(  snapshot.val() );
            }
            if (  snapshot.val() == [] ) {
                setChildInfo( false );
            }
            if ( snapshot.val() == null){
                setChildInfo( false );
            }
        });
    }, [] );

    return(
        <Grid container>
            {/* <NavTutorPhone /> */}
            <Grid item xs={12} md={12}>
                <Box pb={3} className="PageHeader_toRight" >
                    <PageHeader 
                        title="Alumno para cita"
                        text="Selecciona uno de tus hijos por el cual tu desees hacer una cita." 
                        btn_back="/"
                    />
                </Box>
            </Grid>

           
            <Grid sm={12} xs={12} md={12} >
                <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                    { ChildInfo?.child_1 && <ChildItem id={ ChildInfo?.child_1 } /> }
                    { ChildInfo?.child_2 && <ChildItem id={ ChildInfo?.child_2 } /> }
                    { ChildInfo?.child_3 && <ChildItem id={ ChildInfo?.child_3 } /> }
                    { ChildInfo?.child_4 && <ChildItem id={ ChildInfo?.child_4 } /> }
                    { ChildInfo?.child_5 && <ChildItem id={ ChildInfo?.child_5 } /> }
                    { ChildInfo?.child_6 && <ChildItem id={ ChildInfo?.child_6 } /> }
                    { ChildInfo?.child_7 && <ChildItem id={ ChildInfo?.child_7 } /> }
                    { ChildInfo?.child_8 && <ChildItem id={ ChildInfo?.child_8 } /> }
                    { ChildInfo?.child_9 && <ChildItem id={ ChildInfo?.child_9 } /> }
                </Box>
            </Grid>
        </Grid>
    );
}

export default AlumnosForTutor
