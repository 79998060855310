import React from 'react'
//MaterialUI
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

const Loader = ( { withImg, onlyText, onlyLoad } ) => {
    if (withImg) {
        return(
            <Box p={3}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                    <Grid item xs={2}>
                        <Grid container direction="row" justify="center" alignItems="center" >
                            <Skeleton variant="circle" width={60} height={60} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Grid>
                    <Grid item xs={2}>
                        <Box px={2}>
                            <Skeleton variant="rect" width={150} height={50} />
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box px={2}>
                            <Skeleton variant="rect" width={150} height={50} />
                        </Box>
                    </Grid>
                </Grid> 
            </Box>
        )
    }

    if (onlyText) {
        return(
            <Box p={3}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                    <Grid item xs={6}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Grid>
                </Grid> 
            </Box>
        )
    }
    if (onlyLoad) {
        return(
            <Box p={3}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" >
                    <CircularProgress />
                </Grid> 
            </Box>
        )
    }}
        
export default Loader