import React from 'react'
//Import
import { Grid, Box, } from '@material-ui/core'
//Components
import PageHeader from '../../Components/Global/PageHeader';
import MaestroReportesBox from '../../Components/tutors/MaestroReportesBox';



const MaestroReportes = () =>{
    return(
        
        <Grid container className="boxReports_width" >
            
            <Grid item xs={12}>
                <Box>
                    <PageHeader 
                        title="Reportes"
                        text="Descarga un reporte con las caracteristicas que deseas ver en particular" 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <MaestroReportesBox/>
                </Box>
            </Grid>
            </Grid>
            
            )
    }

export default MaestroReportes