import React, {useContext} from 'react';
// import { Link } from 'react-router-dom';
import { AuthContext } from "../Components/functions/Auth";
// Componentes 
import Sidebar from '../Components/Layout/SideBar'
import SideBarTeacher from '../Components/Layout/SideBarTeacher'
import SideBarTutor from '../Components/Layout/SideBarTutor'
import SideLoad from '../Components/Layout/SideLoad'
import SideBarChild from '../Components/Layout/SibeBarChild'
import SideBarAdmin from '../Components/Layout/SideBarAdmin'

//MaterialUI
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
//styles
import './../Assets/Styles/Global.css';
import './../Assets/Styles/Responsive.css';
import '@fortawesome/fontawesome-free/css/all.css';

import BottomNavSchool from '../Components/Layout/BottomNavSchool';
import BottomNavTeacher from '../Components/Layout/BottomNavTeacher';
import BottomNavChild from '../Components/Layout/BottomNavChild';
import BottomNavTutor from '../Components/Layout/BottomNavTutor';
import BottomNavAdmin from '../Components/Layout/BottonNavAdmin';
import HeaderSchoolInfo from '../Components/Global/Headers/HeaderSchoolInfo';
import NavTeacherPhone from './Maestros/NavTeacherPhone/NavTeacherPhone';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';
import NavTutorPhone from './Tutores/NavTutorPhone/NavTutorPhone';
import HeaderSchoolInfoTutor from '../Components/Global/Headers/HeaderSchoolInfoTutor';

const Layout = ({ children }) => {
    const { info, userType }   = useContext(AuthContext);

    let sidebar = <Sidebar/>
    let BottomNav = <BottomNavSchool />

    if(userType === "school"){
        sidebar = <Sidebar/>
        BottomNav = <BottomNavSchool />
    }
    if(userType === "teacher"){
        sidebar = <SideBarTeacher/>
        BottomNav = <BottomNavTeacher />
    }
    if(userType === "tutor"){
        sidebar = <SideBarTutor/>
        BottomNav = <BottomNavTutor />
    }
    if(userType === "child"){
        sidebar = <SideBarChild/>
        BottomNav = <BottomNavChild />
    }
    if(userType === "Admin"){
        sidebar = <SideBarAdmin/>
        BottomNav = <BottomNavAdmin />
    }

    
    return (
        <>
        <Grid id="app_back" container spacing={3}>
            <Hidden smDown>
                <Grid item fixed="true" md={3} >
                    { sidebar }
                </Grid>
            </Hidden>
            <Grid item xs={12} md={9}>
                <Container fixed >
                    <Box mt={3}>
                        {
                            (userType && (userType === 'school' || userType=== 'teacher' ) ) && (
                                <>
                                    {userType === 'school' && <NavEscuelaPhone />}
                                    {userType === 'teacher' && <NavTeacherPhone /> }
                                    <Box display="flex" justifyContent="center" mb={5} className="SchoolInformation__main">
                                        <HeaderSchoolInfo />
                                    </Box>
                                </>
                            ) 
                        }
                        {
                            ( userType && ( userType === 'tutor') && (
                                <>
                                    <NavTutorPhone />
                                    <Box>
                                        <HeaderSchoolInfoTutor />
                                    </Box>
                                </>
                            ) )
                        }
                        
                        { children }
                    </Box>
                </Container>
            </Grid>
        </Grid>
        <Hidden mdUp>
            { BottomNav }
        </Hidden>
        </>
    );
}


export default Layout;
