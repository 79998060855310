// import  './../../Assets/Styles/Responsive.css'
import React, { useState, useContext, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from "../../Components/functions/Auth";
//Components
import SchoolInformation from '../../Components/Global/SchoolInformation';
import BoxHome from '../../Components/Global/BoxInfo';
import { school, teacher } from '../../Constants/cards'
import Calendario from '../../Components/Global/Calendar'
// meterialui
import DehazeIcon from '@material-ui/icons/Dehaze';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography,ListItemText,ListItemIcon  } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DraftsIcon from '@material-ui/icons/Drafts';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';      
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';

// IMG
import logo_school from './../../Assets/Img/Home/Logo_School.png';
import box1_img from './../../Assets/Img/General/portada_entrega.png';
import box2_img from './../../Assets/Img/General/portada_lista-de-espera.png';
import box3_img from './../../Assets/Img/General/portada_alumnos-home.png';
import box4_img from './../../Assets/Img/General/portada_sede.png';
import notificationIcon from './../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg'
import logo from './../../Assets/Img/Layout/Logo_text.png';
//
import * as firebase from 'firebase';
import NavHeaderPhoneOptions from './NavHeaderPhoneOptions';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';


const box_info = {
    "box_1":{
        "box_title": "Citas",
        "box_text":  "Establece horarios para reunirte con padres de familia",
        "box_list":[
            'Aprueba o niega solicitudes de citas.',
            'Analiza el calendario de citas.',
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/citas",
        }
    },
    "box_2":{
        "box_title": "Chats",
        "box_text":  "Comunicate directamente con los padres de familia.",
        "box_list":[
            'Chat de mensahes con padres de familia.',
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/teacher/chat",
        }
    },
    "box_3":{
        "box_title": "Materias",
        "box_text":  "Gestiona las actividades de todas tus asignaturas",
        "box_list":[
            'Modifica deta;;es de la materia.',
            'Agregar y ver detalles de tareas.',
            'Pasar lista de asistencia.'
        ],
        "box_button":{
            "btn_text": "Ver mas",
            "btn_link": "/materias",
        }
    }
}

function Home (){
    const { currentUser, userType, info, infoSchool, notif,handlelogout }   = useContext(AuthContext);
    
    const [Mision, setMision] = useState('')
    const [Vision, setVision] = useState('')

    useEffect(() => {
        let db = firebase.database();
        if(infoSchool){
            let ref = db.ref(`schools/${infoSchool.school}/details`);
            ref.once('value').then(function(snapshot) {
                setMision(snapshot.val().mision)
                setVision(snapshot.val().vision)
            })
        }
    }, [infoSchool])    

    return (
        <Box mb={3}>
            {/* <NavTeacherPhone /> */}
            <Box display="row" justifyContent="left" mb={5}>
            {/* { infoSchool &&  <Typography> { Mision } </Typography> }
            { infoSchool &&  <Typography> { Vision } </Typography> } */}
            </Box>
            <Box className="bg-white" borderRadius={15} boxShadow={3} p={3} >
                <Calendario type="teacher" />
            </Box>
        </Box>
    );
}

export default Home;
