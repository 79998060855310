import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// img
import icon_location from './../../Assets/Img/Home/Icon_Location.png';
import icon_edit from './../../Assets/Img/Home/Icon_Pencil.png';
import icon_phone from './../../Assets/Img/Home/Icon_phone.png';
import logo_school from './../../Assets/Img/Home/Logo_School.png';
import placehole_user from './../../Assets/Img/General/placeholder_man.png';
import { Block } from '@material-ui/icons';

const SchoolInformation = ( { isTutor, user, nameuser, logo, name, address, phone, edit_link, mision,vision } ) => {

    if ( isTutor ) {
        return(
        <>  
            <div className="homeTutor_headerInformationContainer">
                <div>
                    { logo &&  <img src={ logo } alt="Logo Escuela" className="home_logo" /> }
                </div>
                <div className="homeTutor_headerDataContainer">
                    <div className="homeTutor_tutorInformationContainer"> 
                        <img src={ user ? user : placehole_user } alt="avatar" className="mr-3 img_home" style={{"width": "30px", height: "30px" ,display:'block' }} />
                        <Typography variant="body1" className="schoolInfo_title center_center" > { nameuser } </Typography>
                    </div>


                    <Typography id="homeTutor_schoolTitle" variant="h5" className="schoolInfo_title"> <b> Bienvenido a { name } </b> </Typography>
                    
                    <div className="homeTutor_tutorInformationContainer">
                        <img id="icon_schoolInfo" src={ icon_location } alt="Logo Escuela" className="mr-3" />
                        { address }
                    </div>

                    <div className="homeTutor_tutorInformationContainer">
                        <img id="icon_schoolInfo" src={ icon_phone } alt="Logo Escuela" className="mr-3" />
                        { phone }
                    </div>
                </div>
            </div>

            {/*<div className="homeTutor_misionVisionContainer">
                <div className="homeTutor_misionSchool">
                    <Box >
                        <Typography variant="h6" className="kipper_txt-color">
                            <b> Misión </b>
                        </Typography>
                        <Typography variant="body1" gutterBottom className="kipper_txt-grey">
                            { mision }    
                        </Typography>
                    </Box>
                </div>

                <div className="homeTutor_visionSchool">
                    <Box >
                        <Typography variant="h6" className="kipper_txt-color">
                        <b> Visión</b> 
                        </Typography>
                        <Typography variant="body1" gutterBottom className="kipper_txt-grey">                            
                            { vision }    
                        </Typography>
                    </Box> 
                </div>
            </div>*/}

            <Grid id="homeTutor_headerInformationContainerDesktop" container direction="row" justify="flex-start">
                <Grid item xs={12} md={2} >
                    <Box justifyContent="flex-start">
                        { logo &&  <img src={ logo } alt="Logo Escuela" className="home_logo" /> }
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} >
                    <Box>
                        <ul>
                            <li className="flex my-3">
                                <img src={ user ? user : placehole_user } alt="avatar" className="mr-3 img_home" style={{"width": "30px", height: "30px" ,display:'block' }} />
                                <Typography variant="body1" className="schoolInfo_title center_center" > { nameuser } </Typography>
                            </li>
                            <li className="flex my-3">
                                <Typography variant="h5" className="schoolInfo_title"> <b> Bienvenido a { name } </b> </Typography>
                            </li>
                            <li className="flex my-3">
                                <img id="icon_schoolInfo" src={ icon_location } alt="Logo Escuela" className="mr-3" />
                                { address }
                            </li>
                            <li className="flex my-3">
                                <img id="icon_schoolInfo" src={ icon_phone } alt="Logo Escuela" className="mr-3" />
                                { phone }
                            </li>
                        </ul>
                        <Box >
                                <Typography variant="h6" className="kipper_txt-color">
                                    <b> Misión </b>
                                </Typography>
                                <Typography variant="body1" gutterBottom className="kipper_txt-grey">
                                    { mision }    
                                </Typography>
                            </Box>
                            <Box >
                                <Typography variant="h6" className="kipper_txt-color">
                                <b> Visión</b> 
                                </Typography>
                                <Typography variant="body1" gutterBottom className="kipper_txt-grey">                            
                                    { vision }    
                                </Typography>
                            </Box> 
                    </Box>
                </Grid>
            </Grid>
        </>
        )
    }

    else{
        return(
            <>  
                <Grid container direction="row" justify="flex-start" >
                    <Grid item xs={5} sm={5} md={2}>
                        <Box>
                            { logo &&  <img src={ logo } alt="Logo Escuela" className="home_logo" /> }
                        </Box>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} className="SchoolInformation__details">
                        <Box>
                            <ul>
                                {
                                    nameuser && (nameuser !== null && nameuser !== undefined ) &&
                                    <li className="flex my-3">
                                        <img src={ user ? user : placehole_user } alt="avatar" className="mr-3 img_home" style={{"width": "30px", height: "30px" ,display:'block' }} />
                                        <Typography variant="body1" className="schoolInfo_title center_center" > { nameuser } </Typography>
                                    </li>
                                }
                                
                                <li className="flex my-3">
                                    <Typography variant="h5" className="schoolInfo_title"> 
                                        Bienvenido a { name } 
                                    </Typography>
                                </li>
                                <li className="flex my-3">
                                    <img id="icon_schoolInfo" src={ icon_location } alt="Logo Escuela" className="mr-3" />
                                    { address }
                                </li>
                                <li className="flex my-3">
                                    <img id="icon_schoolInfo" src={ icon_phone } alt="Logo Escuela" className="mr-3" />
                                    { phone }
                                </li>
                               
                            </ul>      
                        </Box>
                    </Grid>
                </Grid>
            </>
        )
    }

}; 

SchoolInformation.propTypes = {
    logo:    PropTypes.string.isRequired,
    name:    PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone:   PropTypes.string.isRequired,
}
export default SchoolInformation
