import React,{useContext,useEffect,useState} from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import Box from '@material-ui/core/Box';
import { ChildHomeItem } from '../../Components/childs/ChildHomeItem';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

import * as firebase from 'firebase';

const TutorChild =()=>{

    const { infoSchool, currentUser} = useContext(AuthContext);

    const [schoolInfo, setSchoolInfo] = useState('');
    const [tutorInfo, setTutorInfo] = useState('')
    const [childs, setChild] = useState([])
  
    useEffect( () =>{
      if(infoSchool){
        infogen()
        infochild()
      }
    }, [infoSchool] );

    const infogen = () =>{

        let db = firebase.database();
        let ref = db.ref(`tutors/${currentUser}`);
        ref.once('value').then( snapshot => {
          if ( snapshot.val()?.school ) {
            setTutorInfo(snapshot.val())
            let ref = db.ref(`schools/${snapshot.val()?.school}`);
            ref.once('value').then( snapshotSchool => {
              setSchoolInfo( snapshotSchool.val() );      
            })
          }
          else {
            setSchoolInfo( false );
          }
        });
    }

    const infochild = () =>{
        var listchilds = []
        let db = firebase.database();
        let arraychilds = infoSchool.childs
        if(arraychilds){
          Object.entries(arraychilds).forEach(element => {
            let ref = db.ref(`childs/${element[1]}`);
            ref.once('value').then( snapshot => {
              listchilds.push(snapshot.val())
            });
          })
          setChild(listchilds)
        }
    }

    return(
        <Box>
            {/* <NavTutorPhone /> */}
            <Box id="TutorChild__main">
                <Grid container spacing={3}>          
                    {   
                        childs.length>0?(
                            childs.map(element => 
                            <>
                                <ChildHomeItem                
                                    key={ element.id }
                                    fronTutor={ true }
                                    avatar={`${element.avatar}`}
                                    title={ `${element.name} ${element.last_name}` }
                                    turno={`${element.details.grade} ${element.details.group} `}
                                    grado={`${element.details.turn} `}
                                    link={`/tutor/alumnos/${element.id}`}
                                />
                            </>
                            )
                        ):(<Typography className="TutorChild__title" > No tiene hijos registrados </Typography>)
                    }
                </Grid>
            </Box>
        </Box>
    );
}

export default TutorChild;