import React from 'react';
import { Link } from 'react-router-dom';
//img
import Logo from './../Assets/Img/Layout/Logo.png';
// Componentes 
//MaterialUI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
//styles
// import './../Assets/Styles/Responsive.css';
import './../Assets/Styles/Global.css';


const LayoutOutSide = ({ children }) => (
    <>
        <Grid id="app_back-login" container direction="column" justify="space-between" alignItems="center" spacing={3}>
            <div></div>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Container fixed >
                        <Paper elevation={3}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="Login_box">
                                        <div id="login_content-header">
                                            <Grid item className="login_header" container direction="column" justify="center" alignItems="center">
                                                <Link to="/login">
                                                    <img src={ Logo } alt="Logo Kiper"/>
                                                </Link>
                                            </Grid>
                                        </div>
                                        <Box px={5}>
                                            {children}
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid> 
                        </Paper>
                    </Container>
                </Box>
            </Grid>
            <Grid className="foot_grid chatbody_term_xs" container direction="row" justify="space-between" alignItems="center">
                <Box p={10}></Box>
                <Box p={10}  className="Caja_inferior_login ">
                    <Link to="" className="out-link app__menu-link">
                        Terminos y condiciones 
                    </Link>   
                    <Link to="" className="out-link app__menu-link">
                        Políticas de privacidad
                    </Link>
                </Box>
            </Grid>
        </Grid>
    </>
);

export default LayoutOutSide;