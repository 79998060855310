import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../Components/Global/PageHeader';
import ModalShow from '../Components/Global/Modal';
//img
import portada from './../Assets/Img/General/portada_add-blue.png';
import file from './../Assets/Img/General/File.png';
import upload from './../Assets/Img/General/Icon_upload.png';
import user from './../Assets/Img/General/placeholder_teacher-m.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
//firebase
import * as firebase from 'firebase';
import { checkFile } from '../Components/functions/helpapers';
import { checkFilepdf } from '../Components/functions/helpapers';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../Constants/textfield';
import Select from '../Constants/select';



function MaestrosAgregar () {

    const history               = useHistory();
    const { currentUser, info } = useContext( AuthContext );
    // const match = useRouteMatch('/padres/agregar/:id');

    const [loading, setLoading] = useState({
        ofi: false,
        dom: false,
        opc: false
    }) 

    const [ materia, setMateria ]                       = useState('');
    const [ docProfile, setDocProfile ]                 = useState('');
    const [ docId, setDocId ]                           = useState('');
    const [ docProofAddres, setDocProofAddres ]         = useState('');
    const [ docOpcional, setDocOpcional ]               = useState('');

    //bandera para encontrar email o telefono repetido
    const [teacherRepeated, setTeacherRepeated] = useState(false);

    const [subjects, setSubjects] = useState([])
    const [dataSubjects, setDataSubjects] = useState([])
    const [subjectsCount, setSubjectsCount] = useState(1)

    const [ error, seterror ]                   = useState( false );
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");


    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`schools/${info.coderef}/school_subjects`);
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    listItem.push(item.val())
                })
                console.log( listItem );
                setSubjects( listItem );      
            }
            if (  snapshot.val() == [] ) {
                setSubjects( false );
            }
            if ( snapshot.val() == null){
                setSubjects( false );
            }
        });
    }, [] );
    

    const handleSubmit = val => {
        
        let db = firebase.database();
        let ref = db.ref(`teacher`);
        let resultSearch = false;

        setDataSubjects({...dataSubjects, 
            [ subjectsCount ]:{
                key: `${subjectsCount}`,
                turn: val.turn,
                grade: val.grade,
                group: val.group,
                name: materia,   
            }
        })
     
        const promesa = new Promise((resolve, reject)=>{
            if(val.email){
                ref.orderByChild('school').equalTo(currentUser.toString()).on('value', function(snapshot){
                    snapshot.forEach(function(data){

                        if(data.val().contact.email === val.email || data.val().contact.phone === val.telephone){
                            resolve(resultSearch = true)
                        }
                    })
                    if(resultSearch == false){
                        resolve(resultSearch = false);
                    }
                })
            }
        }).then(()=>{
            if(resultSearch === false){
                const avatarIMG = docProfile && document.getElementById('AvatarIMG').src ;
                const idIMG = docId && document.getElementById('idIMG').src ;
                const comprobanteIMG = docProofAddres && document.getElementById('comprobanteIMG').src;
                const opcionalIMG =  docOpcional && document.getElementById('opcionalIMG').src;
                let db = firebase.database();
                let id = `teacher_${Date.now()}`;
                let Usersref = db.ref(`teacher/${id}`);
                Usersref.set({
                    avatar: docProfile && avatarIMG,
                    school: currentUser,
                    name: val.name,
                    lastname: val.lastName,
                    sex: val.gender,
                    status:"active",
                    type: "teacher",
                    teacher_key: id,
                    teacher_type: val.level,
                    contact: {
                        email: val.email,
                        phone: val.telephone,
                    },
                    location:{
                        address: val.address,
                        city: val.city,
                        zip_code: val.zipCode,
                        country: val.country,
                    },
                    documents:{
                        proof_addres: docId && comprobanteIMG,
                        id: docProofAddres && idIMG,
                        opcional: docOpcional && opcionalIMG,
                    },
                    details:{
                        turn: val.turn,
                        grade: val.grade,
                        group: val.group,
                    },
                    school_subject: dataSubjects
                }, 
                (error) => {
                    if (error) {
                      // The write failed...
                        console.log(error)
                    } 
                    else {
                        setModalmsg(`Maestro creado con éxito`);
                        setModalLink('/maestros');
                        ModalOpen();
                    }
                });
            }else{
                setModalmsg(`Ya existe un maestro con el mismo email o teléfono`);
                setModalLink(`/maestros`);
                ModalOpen();
            }
        }
        ).catch(console.log(error))
    }

    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProfile( img );
                seterrorMsg({...errorMsg, avatar: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setDocId( img );
                setLoading({...loading, ofi: true})
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                        setDocId(profileImg.src)
                    })
                    setLoading({...loading, ofi: false})
                })
            } catch (error) {
                setLoading({...loading, ofi: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, ofi: false})
            return seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }  
    }

    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setDocProofAddres( img );
                setLoading({...loading, dom: true})
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                        setDocProofAddres(profileImg.src)
                    })
                    setLoading({...loading, dom: false})
                })
            } catch (error) {
                setLoading({...loading, dom: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, dom: false})
            return seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }
    
    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setDocOpcional( img );
                setLoading({...loading, opc: true})
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`teacher/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional(profileImg.src)
                    })
                    setLoading({...loading, opc: false})
                })
            } catch (error) {
                setLoading({...loading, opc: false})
                console.log( error );
            } 
        }else {
            setLoading({...loading, opc: false})
            return seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const validateForm = yup.object({
        name: yup
            .string('Ingresa un nombre')
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El nombre es obligatorio')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        lastName: yup
            .string('Ingrese un apellido')
            .max(60, "Se admite un máximo de 60 caracteres")
            .required('El apellido es obligatorio')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        address: yup
            .string('Ingrese una dirección')
            .max(150, "Se admite un máximo de 150 caracteres")
            .required('La dirección es obligatoria'),
        email: yup
            .string('Ingrese un correo electrónico')
            .email('Ingrese un correo válido')
            .required('El correo electrónico es obligatorio'),
        telephone: yup
            .string()
            .required('El teléfono es obligatorio')
            .matches(/^[1-9][0-9]{9,14}$/, 'El teléfono debe contener de 10 a 15 dígitos y debe ser númerico'),
        zipCode: yup
            .string()
            .required('El CP es obligatorio')
            .matches(/^(?:\d{5})?$/, 'El CP debe contener 5 dígitos y ser númerico'),
        city: yup
            .string('Ingrese una ciudad')
            .max(20, "Se admite un máximo de 20 caracteres")
            .required('La ciudad es obligatoria')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        country: yup
            .string('Ingrese un país')
            .max(20, "Se admite un máximo de 20 caracteres")
            .required('El país es obligatorio')
            .matches(/^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, 'Solo se admiten letras'),
        gender: yup
            .string('Seleccione el género')
            .required('El genero es obligatorio'),
        level: yup
            .string('Seleccione un nivel')
            .required('El nivel es obligatorio'),
    });
      
    const initialFormState ={
        name: '',
        lastName: '',
        address: '',
        email: '',
        telephone: '',
        zipCode: '',
        city: '',
        country: '',
        gender: '',
        level: '',
        turn: '',
        grade: '',
        group: ''
    }
    
    

    return(
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={validateForm}
            onSubmit={values =>{
                handleSubmit(values)
            }}
        >
            <Form>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        />
                    </Grid>
                </Modal>
                <Grid container>
                    <Grid xs={12}>
                        <Box pb={3}>
                            <PageHeader
                                title="Nuevo maestro"
                                text="Información y documentación del maestro"
                                btn_back="/maestros"
                                btn_action={ true }
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w" mb={3}>
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box className="Form_add-profile-img">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <input type="file" id="ProfileImage" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e )  } />
                                    <div className="kipperBox_content-upload" >
                                        <label htmlFor="ProfileImage" className="pointer">
                                            <Grid container direction="column" justify="center" alignItems="center">
                                                {
                                                    docProfile ? 
                                                        <img alt="" className="previw_img rounded fit" id={'AvatarIMG'} />
                                                    :
                                                        <img src={file} alt="" className="profileImg rounded fit" />
                                                }
                                                <span>
                                                   <small>Elegir una foto de perfil</small> 
                                                </span>
                                                { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                            </Grid>
                                        </label>
                                    </div>
                                </Grid>
                            </Box>

                            
                            <Box pt={3}>
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Nombre <b className="asterisk-w">*</b>
                                            </InputLabel>
                                           <TextField 
                                                name="name"
                                           />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Apellidos <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="lastName"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Dirección <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="address"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Código Postal <b className="asterisk-w">*</b>
                                            </InputLabel>
                                                <TextField 
                                                    name="zipCode" 
                                                    inputProps={{ maxLength: 5 }}
                                                />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                País <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="country"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Ciudad <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="city" 
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Sexo <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select 
                                                name="gender"
                                            >
                                                <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Masculino">Masculino</MenuItem>
                                                <MenuItem value="Femenino">Femenino</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Email <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="email" 
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Teléfono <b className="asterisk-w">*</b>
                                            </InputLabel>
                                                <TextField 
                                                    name="telephone"
                                                    inputProps={{ maxLength: 10 }}
                                                />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="nivel">
                                                Nivel <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select 
                                                name="level" 
                                            >
                                                <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Kinder">Kinder</MenuItem>
                                                <MenuItem value="Primaria">Primaria</MenuItem>
                                                <MenuItem value="Secundaria">Secundaria</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                </Grid> 
                                <Grid container>
                                    <img src="" alt="" className="previw_img d-none" id={'idIMG'} onChange={e => setDocId( e )} />
                                    <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} onChange={e => setDocProofAddres( e )}/>
                                    <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} onChange={e => setDocOpcional( e )}/>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                            <label for="docIdentificacion" className="kipper_txt-color pointer">
                                                <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                    <img className="imW" src={ upload } alt=""/>
                                                    
                                                    <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                    
                                                </Grid>
                                            </label>
                                            <img src="" alt="" className="previw_img d-none" id={'idIMG'} />
                                            {
                                                (!loading.ofi)?
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        <small>Identificación oficial</small> 
                                                        {
                                                            docId ? <a href={ `${docId}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                        }
                                                    </Typography>
                                                :<CircularProgress />
                                            }
                                            { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                            <label for="docComprobante" className="kipper_txt-color pointer">
                                                <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                    <img className="imW" src={ upload } alt=""/>
                                                    <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                </Grid>
                                            </label>
                                            <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} />
                                            {
                                                (!loading.dom)?
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        <small>Comprobante de domicilio</small>
                                                        {
                                                            docProofAddres ? <a href={ `${docProofAddres}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                        }
                                                    </Typography>
                                                :<CircularProgress />
                                            }
                                            { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                            <label for="docOpcional" className="kipper_txt-color pointer">
                                                <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                    <img className="imW" src={ upload } alt=""/>
                                                    <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                </Grid>
                                            </label>
                                            {
                                                (!loading.opc)?
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        <small>Documento opcional</small>
                                                        {
                                                            docOpcional ? <a href={ `${docOpcional}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                        }
                                                    </Typography>
                                                :<CircularProgress />
                                            }
                                            <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                            { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2} pb={3}>
                                            <Typography className="" variant="" display="block" gutterBottom>
                                                <b>*Los documentos deben ser de tipo JPG, JPEG, PNG y PDF.</b> <br/>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Form>

        </Formik>
            
    )
}

export default MaestrosAgregar