import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../Components/functions/Auth";
//Components
import PageHeader from '../Components/Global/PageHeader'
import ShowContracts from '../Components/Global/ShowContracts';
import BoxInfo from '../Components/Global/BoxInfo';
import ContactItem from './../Components/Global/ContactItem';
import ModalShow from '../Components/Global/Modal';
import Loader from './../Components/Global/Loader';
// IMG
import BoxImg from './../Assets/Img/General/portada_alumnos.png';
import user from './../Assets/Img/General/placeholder_teacher-m.png';
//materialui
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

// firebases
import * as firebase from 'firebase';
import { Typography, Paper, Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

const boxInfo = {
    "box_title":    "Maestros",
    "box_info":     "Busca, añade o modifica la información correspondiente a los maestros",
    "box_list":[
        // "Lorem ipsum dolor sit amet.",
        // "Lorem ipsum dolor sit amet.",
        // "Lorem ipsum dolor sit amet."
    ],
    "box_button":{
        "btn_text": "Agregar maestro",
        "btn_link": "/maestros/agregar",
        // "btn_link": "/maestros/secundaria/agregar",
    },
    "box_button-2":{
        "btn_text": "Agregar maestro (secundaria)*",
        "btn_link": "/maestros/secundaria/agregar",
    }
}

function Maestros () {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const {from,id} = useParams();

    const { currentUser } = useContext(AuthContext);
    const [ refresh, setRefresh ]   = useState( 0 );
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ teacherID, setTeacherID ]       = useState('');
    const [ list, setList ] = useState([]);
    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };


    const isElementDeleted = ( e, id ) => {
        e.preventDefault()
        ModalOpen();
        setIsDeleted( true );
        setTeacherID( id );
    }

    const deleteTeacher = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let db = firebase.database();
            let teacherRef = db.ref(`teacher/${teacherID}`);
            teacherRef.update(
                { status: "inactive", },
                (error) => {
                    if (error) {
                        setIsDeleted( false );
                        setModalmsg( "Lo sentimos, algo extraño paso. Por favor intenta de nuevo" )
                        ModalOpen();
                    }
                    else {
                        setIsDeleted( false );
                        setRefresh( refresh + 1 )
                        setModalmsg( "Maestro eliminado" )
                        ModalOpen();
                    }
                }
            );
        }else{
            ModalClose();
        }
    }
    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`teacher`).orderByChild("name");
        let listItem = [];
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active" && ( (from && from === 'admin')  ? itemInfo.school === id:itemInfo.school === currentUser) ) {
                        listItem.push(
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar ? itemInfo.avatar : user  }
                                name={ `${itemInfo.name} ${itemInfo.lastname} ` }
                                info={ `` }
                                link={ (from && id && from === 'admin') ?`/admin/horarios/profesores/detalles/info/${item.key}`:`/maestros/informacion/${item.key}` }
                                btnFirstButton={(from && id && from === 'admin') ?`VER HORARIO`:`DETALLES`}
                                onlyOneButton={ (from && id && from === 'admin') ?true:false}
                                isDeleted={ isElementDeleted }
                            />
                        )
                    }
                })
                setList( listItem );      
            }
            if (  snapshot.val() === [] ) {
                setList( false );   
            }
            if (  snapshot.val() === null ) {
                setList( false );   
            }
            if (  listItem.length === 0 ) {
                setList( false );   
            }
        });
    }, [ refresh ] );

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted ?
                            <ModalShow
                                title=" ¿Estás seguro de eliminar un maestro? "
                                confirmDelete={ true }
                                Delete={ deleteTeacher }
                            />
                        :
                            <ModalShow
                                title={ modalMsg }
                                btnText="Continuar"
                                btnTo="/maestros"
                            />
                    }

                </Grid>
            </Modal>
            <Grid className="alumn_grid" container>
                {/* <NavEscuelaPhone /> */}
                { (from && id && from === 'admin') ?
                    <Grid item xs={12} >
                        <Box pb={3}>
                            <PageHeader 
                                title="Opciones de horarios"
                                text="Seleccione al profesor que desea ver su horario"
                                btn_back="/"
                                btn_action={false}
                            />
                        </Box>
                    </Grid>
                    :
                    <Grid xs={12}>
                        <Box className="box_w">
                            <BoxInfo
                                box_img={ BoxImg }
                                box_title={ boxInfo.box_title }
                                box_text={ boxInfo.box_info }
                                box_item={ boxInfo.box_list }
                                box_btn_text={ boxInfo.box_button.btn_text }
                                box_btn_link={ boxInfo.box_button.btn_link }
                            />
                        </Box>    
                    </Grid>
                }
                <Grid xs={12}>
                    <Box mt={5} className="box_w">
                        { list 
                            ? <ShowContracts
                                list={ list }
                                details={ true }
                                onDeleted={ isElementDeleted }
                            />
                            : <Paper elevation={3} >
                                <Box className="" p={5} >
                                    <Typography variant="h6" className="schoolInfo_title"> Sin información por el momento </Typography>
                                </Box>
                            </Paper>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Maestros
