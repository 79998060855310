import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// Imagenes
import logo from './../../Assets/Img/Layout/Logo_text.png';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ScheduleIcon from '@material-ui/icons/Schedule';
// Estilos
import './../../Assets/Styles/Layout.css';
//
import app from "../functions/credentials";

import { AuthContext } from "../functions/Auth";


const SideBarTutor = ({}) => {
    const { handlelogout } = useContext( AuthContext );

    return(
    <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
        <Box className="sidebar__header" display="flex">
            <Box flexGrow={1} pt={2} pl={3}>
                <Link to="/home">
                    <img src={ logo } alt="Logo Kiper" />
                </Link>
            </Box>

        </Box>
        <Box className="sidebar__search" display="flex" justifyContent="center">
        </Box>
        <Box className="sidebar__menu ">
            <ul className="pl-10 pt-5">
                <li className="flex my-5 cursor-pointer">
                    <Link to="/home" className="flex">
                        <HomeIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                        <span>Inicio</span>
                    </Link>
                </li>  
                <li className="flex my-5 cursor-pointer">
                    <Link to="/admin/escuela/crear" className="flex"> 
                        <AddCircleIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                        <span>Creacion de Escuelas</span>
                    </Link> 
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/admin/escuela/grupos" className="flex">
                        <GroupWorkIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                        <span>Asignacion de Grupo a Escuela</span>
                    </Link>
                </li>                
                <li className="flex my-5 cursor-pointer">
                    <Link to="/admin/notificaciones" className="flex">
                        <NotificationsIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                        <span>Creacion de notificaciones</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/admin/horarios" className="flex">
                        <ScheduleIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                        <span>Ver horarios</span>
                    </Link>
                </li>                                
                <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                    <ExitToAppIcon style={{color: "grey", width: "25px"}} className="mr-3 sidebar_icon" />
                    <span>Salir</span>
                </li> 
                
            </ul>
        </Box>
    </Box>
    )
};

export default SideBarTutor
