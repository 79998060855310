import React, {useState, useContext, useEffect} from 'react'
import { Grid, Box, InputBase, Button, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import * as firebase from 'firebase';
//Assets
import icon from '../../Assets/Img/General/Academy.png'
import avatarimg from "../../Assets/Img/General/placeholder_teacher-f.png";
import style from '../../Assets/Styles/chat.module.css'
//Context
import { AuthContext } from "../functions/Auth";
//Funciones del chat
import { CheckChatGroups, Send, GroupChilds } from "./ChatFunctions";
import { ContactSupportOutlined } from '@material-ui/icons';
import {useHistory} from "react-router-dom";


export default function ChatComponent() {
//Hooks del componente padre para los mensajes
    const [msgs, setMsgs] = useState(null)
    const [chatsmsgs, setChatmsgs] = useState(null)
    const [childsarray, setChildsarray]=useState([])
    const [contact, setContact] = useState([]);
//Llamar el context para las variables globales
    const { info, infoSchool, currentUser } = useContext(AuthContext);
//Llamar a la base de datos
    const db = firebase.database();


//UseEffect para llamar a la informacion una vez el componente fue montado
    useEffect(() => {
        if(info.type === "teacher"){
            CheckChatGroups(info, infoSchool);
            var listchats = [];
            var offlinechats = [];
            let ref = db.ref('chats')
            ref.once('value').then(snapshot =>{
                    Object.entries(snapshot.val()).forEach(e => { 
                        if(info.type === "teacher"){
                            if(e[1].teacher == info.coderef && e[1].status.Active == 1 && e[1].status.IsGroup == 1){
                                listchats.push(e)
                            }else if(e[1].teacher.key == info.coderef && e[1].status.Request == 1){
                                listchats.push(e)
                            }else if(e[1].teacher.key == info.coderef && e[1].status.Active == 1){
                                listchats.push(e)
                            }else if(e[1].teacher.key == info.coderef && e[1].status.Active == 1 && e[1].school.key == infoSchool.school){
                                listchats.push(e)
                            }else if(e[1].teacher.key == info.coderef){
                                listchats.push(e)
                            }
                        }
                    });
                    setMsgs(listchats)
                    setChildsarray(snapshot.val())
            })
        }else if(info.type === "school"){
            var listchats = [];
            var offlinechats = [];
            let ref = db.ref('chats')
            ref.once('value').then(snapshot =>{
                Object.entries(snapshot.val()).forEach(e =>{
                    if(info.type === "school"){
                        if(e[1].school?.key == info.coderef && e[1].status.Active == 1){
                            listchats.push(e) 
                        }     
                    }
                })
                setMsgs(listchats);
                setChildsarray(offlinechats);
            })
            

        }else if(info.type === "tutor"){
            GroupChilds(infoSchool.childs, infoSchool);
            var listchats = [];
            var offlinechats = [];
            let ref = db.ref('chats')
            ref.once('value').then(snapshot =>{
                Object.entries(snapshot.val()).forEach(e =>{
                    if(info.type === "tutor"){
                        if(e[1].tutor?.key == info.coderef && e[1].status.IsTutor == 1){
                            listchats.push(e)
                        }else if(e[1].tutor?.key == info.coderef){
                            offlinechats.push(e)
                        }      
                    }
                })
                setMsgs(listchats);
                setChildsarray(offlinechats);
            })
        }else if(info.type === "child"){
            var listchats = []
            let ref = db.ref('chats')
            ref.once('value').then(snapshot =>{
                Object.entries(snapshot.val()).forEach(e =>{
                    if(info.type === "child"){
                        if(e[1].group?.key == info.group && e[1].status.Active == 1 && e[1].status.IsGroup == 1){
                            listchats.push(e)
                            handlemessages(e[0])
                        }
                    }
                })
                setMsgs(listchats)
            })
        }
    }, [])

    const handlemessages = (ActualMessages) =>{
        setChatmsgs(ActualMessages)
    }

    if(info.type === "school"){
        return (
            <Grid xs={12} className="Form_add-content kipper_box">
                <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <TeacherContacts messages={msgs} changemsg={handlemessages} contact={setContact}/>
                        </Grid>
                        <Grid item xs={12} md={9}>
                        {
                            chatsmsgs
                            ?<ChatBody group={chatsmsgs} uid={currentUser} changemsg={handlemessages} receiver={contact} />
                            :null
                        }                        
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        )
    } else if(info.type === "teacher"){
        return (
        <Grid xs={12} className="Form_add-content kipper_box">
            <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <ContactListGroups messages={msgs} changemsg={handlemessages} contact={setContact}/>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {
                            chatsmsgs
                            ?<ChatBody group={chatsmsgs} uid={currentUser} changemsg={handlemessages} receiver={contact}/>
                            :null
                        }
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        )
    }else if(info.type === "tutor"){
        return (
        <Grid xs={12} className="Form_add-content kipper_box">
            <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        {
                            msgs
                            ?<GroupsTutor messages={msgs} changemsg={handlemessages} contact={setContact}/>
                            :null
                        }
                    </Grid>
                    <Grid item xs={12} md={9}>
                    {
                            chatsmsgs
                            ?<ChatBody group={chatsmsgs} uid={currentUser} changemsg={handlemessages} receiver={contact}/>
                            :null
                    }
                    </Grid>
                </Grid>            
            </Box>
        </Grid>
        )
    }else if(info.type === "child"){
        return (
        <Grid xs={12} className="Form_add-content kipper_box">
            <Box boxShadow={3} borderRadius={16} className="kipperBox_content" mb={3}>
                <ChatBody group={chatsmsgs} uid={currentUser}/>
            </Box>
        </Grid>
        )
    }
}

const TeacherContacts = ({messages, changemsg, contact}) => {
    return(
        <>
        {
            messages
            ?messages.map( i=>{
                return <ContactItem teacherslist={i} msgs={changemsg} changecontact={contact}/>
            })
            :null
        }
        </>
    )
}

const ContactListGroups = ({messages, changemsg, contact}) => {
    return (
        <>
        {
            messages
            ?messages.map( i=>{
                return <ContactItem group={i} msgs={changemsg} changecontact={contact}/>
            })
            :null
        }
        </>
    )
}

const GroupsTutor = ({messages, changemsg, user, contact}) => {
    return(
        <>
        {
            messages
            ?messages.map( i=>{
                return <ContactItem tutor={i} msgs={changemsg} type={'tutor'} changecontact={contact}/>
            })
            :null
        }
        </>
    )
}

const ContactItem = ({msgs, group, teacher, tutor, teacherslist, changecontact}) =>{
    const [teacherlistinfo, setTeacherlistinfo] = useState(null);
    const db = firebase.database();
    useEffect(() => {
        if(teacherslist){
            db.ref(`teacher/${teacherslist[1].teacher.key}`).once('value').then(snapshot =>{
                if(snapshot.val()){
                    setTeacherlistinfo(snapshot.val());
                }
            })
        }else if(tutor){
            db.ref(`teacher/${tutor[1].teacher.key}`).once('value').then(snapshot =>{
                if(snapshot.val()){
                    setTeacherlistinfo(snapshot.val());
                }
            })
        }
    }, [teacherslist])
    var gruoptype;
    var tutortype;
    var schooltype;
    if(group){
         gruoptype = group[1].status.IsGroup;
         tutortype = group[1].status.IsTutor;
         schooltype = group[1].status.IsSchool;
    }
        return(
            <>
            {
                gruoptype
                ?<Box p={3} onClick={() => msgs(group[0])} >
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ icon } alt="" width="75px" style={{borderRadius:'50%', height: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `${group[1].group.grade} ${group[1].group.group}`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null
            }
            {
                schooltype
                ?<Box p={3} onClick={() => msgs(group[0])}>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ group[1].school.avatar ?group[1].school.avatar :icon } alt="" style={{borderRadius:'50%', height: "75px", width: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `School`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null
            }
            {
                tutortype
                ?<Box p={3} onClick={() => msgs(group[0])}>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ group[1].tutor.avatar ?group[1].tutor.avatar :avatarimg } alt="" style={{borderRadius:'50%', height: "75px", width: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `${group[1].tutor.name}`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null

            }
            {
                teacher
                ?<Box p={3} onClick={() => msgs(teacher.teacher.key)}>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ teacher.teacher.avatar ?teacher.teacher.avatar :avatarimg  } alt="" width="75px" style={{borderRadius:'50%', height: "75px", width: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `${teacher.teacher.name}`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null
            }
            {
                tutor
                ?<Box p={3} onClick={() => msgs(tutor[0])}>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ teacherlistinfo?.avatar ?teacherlistinfo?.avatar :avatarimg } alt="" width="75px" style={{borderRadius:'50%', height: "75px", width: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `${teacherlistinfo?.name}`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null
            }
            {
                teacherslist
                ?<Box p={3} onClick={() => {msgs(teacherslist[0]); changecontact(teacherlistinfo)} }>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                        <Grid item xs={4}>
                            <Box borderRadius="50%">
                                <img src={ teacherlistinfo ?teacherlistinfo?.avatar :avatarimg } alt="" width="75px" style={{borderRadius:'50%', height: "75px", width: "75px"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="Kipper_title-black">
                                { `${teacherlistinfo?.name}`  } 
                            </span> <br/> 
                        </Grid>
                    </Grid>
                </Box>
                :null
            }
            </>
        )
}

const ChatBody = ({group, uid, changemsg}) => { 

    const CHATS_REF = firebase.database().ref().child('/chats/');
    const TEACHER_REF = firebase.database().ref().child('/teacher/')
    const { info, infoSchool, currentUser } = useContext(AuthContext);

    const [ text, setText ] = useState('')
    const [ messages, setMessages ] = useState([])
    const [ refinfo, setRefinfo ] = useState(null)
    
    const [ statuschat, setStatuschat ] = useState(null)
    const [ statusmsg, setStatusmsg ] = useState(null)
    
    const [ avatarholder, setAvatarHolder] = useState(null)

    const [ active, setActive ] = useState(null)
    const [ request, setRequest ] = useState(null)
    const [ close, setClose ] = useState(null)

    const [ groupstatus, setGroupstatus ] = useState(null);
    const [ tutorstatus, setTutorstatus ] = useState(null);
    const [ schoolstatus, setSchoolstatus ] = useState(null);
    const history   = useHistory();


    useEffect(() => {
        if(group){
            if(info.type === "teacher"){
                CHATS_REF.child(`/${group}/`).on('value', snap => {
                    if(snap.exists()){
                        setStatuschat('teacher')
                        let data = snap.val()
                        setStatusmsg(snap.val())
                        setMessages(data.messages)
                        if(data.status.IsTutor){
                            setRefinfo(data.tutor)
                            setAvatarHolder(avatarimg)
                            setTutorstatus(true)
                            if(data.status?.Active){
                                setActive(true)
                                setRequest(null)
                                setClose(null)
                            }else if(data.status.Closed){
                                setClose(true)
                                setRequest(null)
                                setActive(null)
                            }else if(data.status?.Request){
                                setRequest(true)
                                setClose(null)
                                setActive(null)
                            }else{
                                setRequest(null)
                                setClose(null)
                                setActive(null)
                            }
                        }else if(data.status.IsGroup){
                            setRefinfo(data.group)
                            setAvatarHolder(icon)
                            setGroupstatus(true)
                            if(data.status?.Active){
                                setActive(true)
                                setRequest(null)
                                setClose(null)
                            }else if(data.status.Closed){
                                setClose(true)
                                setRequest(null)
                                setActive(null)
                            }else if(data.status?.Request){
                                setRequest(true)
                                setClose(null)
                                setActive(null)
                            }else{
                                setRequest(null)
                                setClose(null)
                                setActive(null)
                            }
                        }else if(data.status.IsSchool){
                            setRefinfo(data.school)
                            setAvatarHolder(icon)
                            setActive(true)
                            setSchoolstatus(true)
                        }
                    }else{
                        setMessages(null)
                    }
                })
            }else if(info.type === "tutor"){
                CHATS_REF.child(`/${group}/`).on('value', snap => {
                    if(snap.exists()){
                        setStatuschat('tutor')
                        let data = snap.val()
                        setMessages(data.messages)
                        if(data.status.IsTutor){
                            setAvatarHolder(avatarimg)
                            setRefinfo(data.teacher)
                            setTutorstatus(true)
                            if(data.status?.Active){
                                setActive(true)
                                setRequest(null)
                                setClose(null)
                            }else if(data.status.Closed){
                                setClose(true)
                                setRequest(null)
                                setActive(null)
                            }else if(data.status?.Request){
                                setRequest(true)
                                setClose(null)
                                setActive(null)
                            }else{
                                setClose(null)
                                setRequest(null)
                                setActive(null)
                            }
                            TEACHER_REF.child(`/${data?.teacher.key}/`).on('value', snap2 =>{
                                setRefinfo(snap2.val())
                            })
                        }else if(data.status.IsGroup){
                            setGroupstatus(true)
                            setRefinfo(data.group)
                            setAvatarHolder(icon)
                            if(data.status?.Active){
                                setActive(true)
                                setRequest(null)
                                setClose(null)
                            }else if(data.status.Closed){
                                setClose(true)
                                setRequest(null)
                                setActive(null)
                            }else if(data.status?.Request){
                                setRequest(true)
                                setClose(null)
                                setActive(null)
                            }else{
                                setRequest(null)
                                setClose(null)
                                setActive(null)
                            }
                        }
                    }else{
                        setMessages(null)
                    }
                })
            }else if(info.type === 'school'){
                CHATS_REF.child(`/${group}/`).on('value', snap => {
                    if(snap.exists()){
                        setStatuschat('school')
                        let data = snap.val()
                        setMessages(data.messages)
                        setSchoolstatus(true)
                        if(data.status.IsSchool){
                            if(data.status?.Active){
                                setActive(true)
                            }
                        }
                        TEACHER_REF.child(`/${data?.teacher.key}/`).on('value', snap2 =>{
                            setRefinfo(snap2.val())
                        })
                    }else{
                        setMessages(null)
                    }
                })
            }else{
                CHATS_REF.child(`/${group}/`).on('value', snap => {
                    if(snap.exists()){
                        setStatuschat('child')
                        let data = snap.val()
                        setAvatarHolder(icon)
                        setRefinfo(data.group)
                        setMessages(data.messages)
                        setGroupstatus(true)
                        if(data.status?.Active){
                            setActive(true)
                        }
                    }else{
                        setMessages(null)
                    }
                })
            }
        }
        return () => {
            setClose(null)
            setActive(null)
            setRequest(null)
            setGroupstatus(null)
            setTutorstatus(null)
            setSchoolstatus(null)
        }
    },[group || statusmsg])

    useEffect(() => {
        var el = document.getElementById('mensajeschat')
        var hel = el.scrollHeight;
        el.scrollTop = hel + 150;
        if(statusmsg){
            if(statusmsg.status?.Active){
                setActive(true)
                setRequest(null)
                setClose(null)
            }else if(statusmsg.status.Closed){
                setClose(true)
                setActive(null)
                setRequest(null)
            }else if(statusmsg.status?.Request){
                setRequest(true)
                setClose(null)
                setActive(null)
            }else{
                setRequest(null)
                setClose(null)
                setActive(null)
            }
        }
    }, [messages])
    
    const handleSubmit = e => {
        e.preventDefault();
        if(info.type === 'teacher'){
            Send(text, infoSchool, group);
            setText("")
        }else if(info.type === 'child'){
            Send(text, info, group);
            setText("")
        }else if(info.type === 'tutor'){
            Send(text, infoSchool, group);
            setText("")
        }else if(info.type === 'school'){
            Send(text, info, group);
            setText("")
        }
    }

    function Activate(){
        CHATS_REF.child(`/${group}/status`).update({
            Active:1,
            Request:0,
            Closed:0
        })
        setRequest(null)
        setClose(null)
        setActive(true)
    }

    function Close(){
        if(!groupstatus){
            CHATS_REF.child(`/${group}/status`).update({
                Active:0,
                Request:0,
                Closed:1
            })
            setRequest(null)
            setClose(true)
            setActive(null)
        }
        changemsg(null)
    }

    function Request(){
        CHATS_REF.child(`/${group}/status`).update({
            Active:0,
            Request:1,
            Closed:0
        })
        setRequest(true)
        setClose(null)
        setActive(null)
    }

    return(
            <Grid container >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Box display="flex" mt={2}>
                        <Box borderRadius="50%">
                            <img src={ refinfo?.avatar ?refinfo.avatar :avatarholder } style={{borderRadius: "50%", height: "75px"}} width="75px" />
                        </Box>
                        <Box pl={3}>
                            {
                                groupstatus
                                ?<Typography variant="h5" className="schoolInfo_title capitalize"> { `${refinfo?.grade} ${refinfo?.group}` } </Typography>
                                :<Typography variant="h5" className="schoolInfo_title capitalize"> { refinfo?.name } {refinfo?.lastname} </Typography>
                            }
                            
                        </Box>
                    </Box>
                        {
                            active && statuschat == "teacher" && schoolstatus 
                            ?<Box display="flex" >
                                <Button onClick={e => changemsg(null)} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>     
                            :null
                        }   
                        {
                            active && statuschat == "teacher" && tutorstatus
                            ?<Box display="flex" >
                                <Button onClick={e => Close()} variant="outlined" className="kipper_btn-outline-black">Close</Button>      
                                <Button onClick={e => changemsg(null)} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>     
                            :null
                        }
                        {
                            request && statuschat == "teacher" && tutorstatus
                            ?<Box display="flex" >
                                <Button onClick={e => Activate()} variant="outlined" className="kipper_btn-outline-black">Active</Button>
                                <Button onClick={e => changemsg(null)} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>     
                            :null
                        }
                        {
                            close && statuschat == "tutor" && tutorstatus
                            ?<Box display="flex" >
                                <Button onClick={e => Request()} variant="outlined" className="kipper_btn-outline-black">Request</Button>      
                                <Button onClick={e => changemsg(null)} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            </Box>     
                            :null
                        }
                        {
                            active && statuschat == "child"
                            ?<Box display="flex" >
                                {/* <Link to={ '/home' }> */}
                                    <Button onClick={() => history.goBack()} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                                {/* </Link> */}
                            </Box>     
                            :null
                        }
                        {
                            active && statuschat == "teacher" && groupstatus
                            ?<Button onClick={e => changemsg(null)} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                            :null
                        }                  
                </Grid>
                <MessagesBody msgs={messages} sender={refinfo}/>
                <Grid item xs={ 12 }>
                {
                    active
                    ?<form className="form_chat" onSubmit={ e => handleSubmit(e)}>
                        <Grid container   direction="row"  className={ style.form_control_chat } >
                            <Grid item xs={ 9 } md={ 10 } lg={ 10 }>
                                <InputBase inputProps={{ 'aria-label': 'naked' }} style={{width:"100%"}} placeholder="Escribe aquí" value={ text } onChange={e => setText(e.target.value) }/>
                            </Grid>
                            <Grid item xs={ 3 } md={ 2 } lg={2}>
                                    {/* <Box className={ style.submit_content }> */}
                                        <Button type="submit" color="primary" className="kipper_button" style={{width: "97%"}}>
                                            Enviar
                                        </Button>
                                    {/* </Box> */}
                                </Grid>
                        </Grid>
                    </form>
                    :null 
                }
                </Grid>
            </Grid>
    )
}

const MessagesBody = (msgs) =>{
    const renderMessages = (msgs) => {
        if(msgs.msgs){
            return Object.entries(msgs.msgs).map(e => <Messages  key={e[0]} msg={e} sender={msgs.sender} />)
        }else{
            return  <>Envía un mensaje</>
        }
    }    
    
    return(
        <Grid item xs={ 12 }  className={ style.chat_content } id={'mensajeschat'}>
            { msgs && renderMessages(msgs) }
        </Grid>
    )
}

const Messages = ( {msg, sender} ) => {
    const { currentUser, infoSchool } = useContext(AuthContext);
    const [senderinfo, setSenderinfo] = useState(sender);
    const [typeUser, setType] = useState('');
    useEffect(() => {
        var typeuser = msg[1]?.sender?.key.split("_");
        if(typeuser && msg[1]?.sender?.key !== currentUser){
            setType(typeuser[0])
            if(typeuser[0] === 'school' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/schools/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else if(typeuser[0] === 'teacher' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/teacher/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else if(typeuser[0] === 'tutor' && msg[1]?.sender?.key !== currentUser){
                firebase.database().ref(`/tutors/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }else{
                firebase.database().ref(`/childs/${msg[1].sender.key}`).on('value', snap2 =>{
                    setSenderinfo(snap2.val())
                })
            }
        }
        console.log(infoSchool)
        console.log(senderinfo);
    }, [sender])
//condicionales para decidir como imprimir el mensaje
//el tipo uno seria como un mensaje del sistema, como chat creado, cerrado o inicio/fin de las conversaciones
    if(msg[1].type === 1){
        return (
            <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                <Grid item xs={ 10 } className={ `${style.msg_content} ${style.grey}` }>
                    <Box className={ style.msg_text }>
                        { msg[1].msg } 
                    </Box>
                </Grid>
                <Grid item xs={ 2 } className={ style.avatar_content }>
                </Grid>
            </Grid>
        )
//El tipo 2 son los mensajes normales de usuarios, estos incluyen la informacion de su creador en el apartado array.sender
    }else if(msg[1].type === 2){
//Esta condicional nos permite saber si somos los creadores del mensaje o los que lo recibimos para imprimir la informacion del creador
//y asi el usuario tenga una idea de con quien(es) esta hablando
        if(msg[1].sender.key === currentUser && typeUser == "school" ){
            return (
                <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${style.blue}` }>
                        <Box className={ style.msg_text }>
                            { msg[1].msg }
                        </Box>
                    </Grid>
                    <Grid item xs={ 2 } className={ style.avatar_content }>
                        <img src={  infoSchool?.avatar ?infoSchool?.avatar :avatarimg }  className={ style.avatar_chat }/>
                    </Grid>
                    
                </Grid>
            )
        }else if(msg[1].sender.key === currentUser && typeUser !== "school" ){
            return (
                <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${style.blue}` }>
                        <Box className={ style.msg_text }>
                            { msg[1].msg }
                        </Box>
                    </Grid>
                    <Grid item xs={ 2 } className={ style.avatar_content }>
                        <img src={  msg[1]?.sender?.avatar ?msg[1].sender.avatar :avatarimg }  className={ style.avatar_chat }/>
                    </Grid>
                    
                </Grid>
            )
        }else{
            return(
                <Grid container className= {` ${style.msg_content}` } spacing={ 2 }>
                    <Grid item xs={ 2 } className={ style.avatar_content }>
                        <img src={ senderinfo?.avatar ?senderinfo?.avatar :avatarimg } className={ style.avatar_chat } />
                    </Grid>
                    <Grid item xs={ 10 } className={ `${style.msg_body} ${ style.lowblue }`} style={{display:"block"}} >
                        <span>{senderinfo?.name}</span>
                        <Box className={ style.msg_text.dark }>
                            <span>{ msg[1].msg }<br></br></span>
                        </Box>
                    </Grid>
                    
                </Grid>
            )
        }
    }
}