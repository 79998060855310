import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import style from '../../Assets/Styles/chat.module.css'
import { useEffect } from 'react'
import { fetchMessages, Message } from "./ChatFunctions";

import * as Firebase from 'firebase'
const CHATS_REF = Firebase.database().ref().child('/chats/')



const ChatBody = ({ idChat, uid, schoolid }) => {

    const [ messages, setMessages ] = useState([])
    
    const renderMessages = (messages) => {
        // console.log('render ', messages);
        if(messages) {
            console.log('MM id+', uid);
            return Object.entries(messages).map(msg => <Message uid={ uid } key={msg[0]} msg={ msg } />)
        }else{
            return  <>Envía un mensaje</>
        }
    }


    useEffect(() => {
        console.log('cbody ', idChat, 'school_id', schoolid);
        
        if(idChat){
            CHATS_REF.child(`/${idChat}/`).on('value', snap => {
                if(snap.val()){
                    let data = snap.val()
                    setMessages(data.messages)
                    // console.log('mssss ', data.messages);
                }
                    else{
                    // console.log('snap empty ', snap.val());
                }
            })
        }
        // fetchMessages(idChat, setMessages, idTutor)
    },[idChat])

    return(
        <>
            { messages && renderMessages(messages) }
        
        </>
        
    )
}

export default ChatBody