import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ImformationItem from './../../Components/Global/ImformationItem';
import AssistanceItem from '../../Components/Global/Maestros/AssistanceItem';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/File.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//firebase
import * as firebase from 'firebase';
import HomeWorkItem from '../../Components/Global/Maestros/HomeWorkItem';

function SchoolSubjectsDetails () {

    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/asistencias/:id');
    const teacherID = match.params.id;
    const [ isHomeWork, setIsHomeWork ] = useState( true );
    
    const [ info, setInfo]        = useState('');
    const [ error, setError]        = useState('');
    const [ loader, setLoaderChild] = useState( false );
    const { currentUser }           = useContext(AuthContext);
    useEffect(() => handleLoad(),[]);

    function handleLoad ( e ) {
        let db = firebase.database();
        let ref = db.ref('teachers');
        let childRef = ref.child( teacherID );
        childRef.once('value').then(function(snapshot) {
            setInfo     (snapshot.val());

        })
    }

    const handlesubmit = e =>{
        e.preventDefault();
    }

    return(
        <form onSubmit={ e => handlesubmit( e ) } onLoad={ (e) => handleLoad( e ) }>
            <Grid container className="alumInfo_grid">
                <Grid item xs={12} sm={12} >
                    <Box  pb={3}>
                        <PageHeader 
                            title={`Asistencias`}
                            text={``}
                            btn_back="/teacher/materias"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} className="Form_add-content" >
                    {/* <Box display="flex"  >
                        <Button onClick={ e => setIsHomeWork( true ) } className={ `tab_btn ${ isHomeWork && 'active' }` }>Tareas</Button>
                        <Button onClick={ e => setIsHomeWork( false ) } className={ `tab_btn ${ !isHomeWork && 'active' }` } >Asistencias</Button>
                    </Box> */}
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content" >
                        <Box p={3}>
                            <Grid container>
                                <Grid item xs={6} md={12} >
                                    {/* <Box display="flex" >
                                        <Button onClick={ e => setIsHomeWork( true ) } >Tareas</Button>
                                        <Button onClick={ e => setIsHomeWork( false ) }>Asistencias</Button>
                                    </Box> */}
                                </Grid>
                                <Grid item xs={12} >
                                    {/* <HomeWorkItem date="date" title="Resumen" text="Lorem ipsum" link="/tareas/informacion/2"  /> */}
                                    <AssistanceItem date="date" list="" />
                                    <AssistanceItem date="date" list="" />
                                    <AssistanceItem date="date" list="" />

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
    
}

export default SchoolSubjectsDetails