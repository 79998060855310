import React, { Component, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import icon_alert from './../../Assets/Img/General/Icon_danger.png'
import icon_salir from './../../Assets/Img/General/Icon_out.png'
//Components
import PageHeader from './../../Components/Global/PageHeader';
//MaterialUI
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
// import Pagination from '@material-ui/lab/Pagination';
import app from '../../Components/functions/credentials';
import { AuthContext } from './../../Components/functions/Auth';

function Settings () {

    const [isCheked, setIsCheked] = useState(false)

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid xs={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Configuracion"
                        text="Ajustes."
                    />
                </Box>
            </Grid>
            <Grid xs={12}>
                <Box p={5} className="kipperBox_content" boxShadow={3}>
                    <Typography variant="h5" className="" >
                        Personal
                    </Typography>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Box my={1} >
                            <label htmlFor="notCheck">
                                <NotificationsIcon /> Activar notificaciones
                            </label>
                        </Box>
                        <Box my={1} >
                            <Switch
                                checked={ isCheked }
                                onChange={e => setIsCheked( e.target.checked ) }
                                color="primary"
                                id="notCheck"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Box>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Box my={1}>
                            <Link to='/tutor/faq' >
                                <HelpIcon /> Preguntas Frecuentes
                            </Link>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Settings