import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from './../../Components/functions/Auth';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal';
import user from './../../Assets/Img/General/placeholder_man.png';
//MaterialUI
import TextField from '@material-ui/core/TextField';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import * as firebase from 'firebase';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';


function Appointment () {
    
    const history   = useHistory();
    const match     = useRouteMatch('/tutor/citas/:teacherID/:studentID');
    const teacherID = match.params.teacherID;
    const studentID = match.params.studentID;
    
    const { info, currentUser, infoSchool }   = useContext(AuthContext);

    const [isCheked, setIsCheked]   = useState({})
    const [teacher, setTeacher]     = useState('');
    const [student, setStudent]     = useState('');
    const [date, setDate]           = useState('')
    const [time, setTime]           = useState('')
    const [motivo, setMotivo]       = useState('')
    const [details, setDetails]     = useState('')

    const [error, setError] = useState('')

    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    //errors
    const [errordetails, setErrordetails] = useState(false)
    const [errormotivo, setErrormotivo] = useState(false)
    const [errordate, setErrordate] = useState(false)
    const [errortime, setErrortime] = useState(false)

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect( () => {
        let db = firebase.database();
        let ref = db.ref(`teacher/${teacherID}`);
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                setTeacher( snapshot.val() );      
            }
            if (  snapshot.val() === [] ) {
                setTeacher( false );   
            }
            if (  snapshot.val() === null ) {
                setTeacher( false );   
            }
        });
    }, [ teacherID ] );

    useEffect( () =>{
        let db = firebase.database();
        let ref = db.ref(`childs/${studentID}`);
        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                setStudent( snapshot.val() );      
            }
            if (  snapshot.val() === [] ) {
                setStudent( false );   
            }
            if (  snapshot.val() === null ) {
                setStudent( false );   
            }
        });
    }, [ studentID ] );
    
    const handleSelect = ( e, id ) => {
        e.preventDefault()
        setIsCheked( id )
    }

    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1)

    const checkSubmit = ( e ) => {
        e.preventDefault();
        var todaydate = new Date(date)

        setErrordate(false)
        setErrortime(false)
        setErrormotivo(false)
        setErrordetails(false)
        setError('')

        
        if(date == ''){setError('Elija un dia para la cita'); setErrordate(true) }
        else if(todaydate.getTime() < yesterday.getTime()){ setError('Elija una fecha que no sea pasada'); setErrordate(true) }
        else if (time == ''){setError('Elija una hora para la cita'); setErrortime(true) }
        else if (motivo == ''){setError('Escriba el motivo de la cita'); setErrormotivo(true) }
        else if(motivo.length > 100){setError('El motivo no puede ser superior a 100 caracteres'); setErrormotivo(true) }
        else if (details == ''){setError('Escriba los detalles para la cita'); setErrordetails(true)  }
        else if (details.length > 500 ){setError('Los detalles no pueden ser superiores a 500 caracteres'); setErrordetails(true)  }
        else{handleSubmit()}
    }

    const handleSubmit = ( e ) => {
        let db = firebase.database();
        let id_comunicado = `comunicado_${Math.floor(Math.random() * 100000)}`;
        let teacherRef = db.ref(`comunicados/${id_comunicado}`);
        teacherRef.set({
            id: id_comunicado,
            school: infoSchool.school,
            name: motivo,
            receiver_event: 'appointment',
            details_event: details,
            date: date,
            time: time,
            timestamp: Date.now(),
            confirm: 0,
            status: 'active',
            type: 'appointment',
            details:{
                grade: student?.details?.grade,
                group: student?.details?.group,
                turn: student?.details?.turn,
            },
            teacher:{
                teacher: teacher.teacher_key,
                teacher_name: `${teacher.name} ${teacher.lastname}`,
                teacher_avatar: teacher.avatar,
            },
            tutor:{
                tutor: infoSchool.id,
                tutor_name: `${infoSchool.name} ${infoSchool.last_name}`,
                tutor_avatar: infoSchool.avatar,
            },
            student:{
                student_key: student.id,
                student_name: `${student.name} ${student.last_name}`,
                student_avatar: student.avatar,
            }
        }, (error) => {
            if (error) { console.log(error) } 
            else {
                setModalmsg(`Cita solicitada con éxito`);
                setModalLink('/tutor/citas');
                ModalOpen();
                createNotif()
            }
        });
    }

    const createNotif = () => {
        let db = firebase.database();
        let teacherRef = db.ref(`notifications/`);
        const { teacher_key } = teacher
        let userList = []
        userList.push({ key: teacher_key, status: 0 }) 
        teacherRef.push({
            school_key: infoSchool.school,
            title: 'Cita solicitada',
            body: `${infoSchool.name} ${infoSchool.last_name} tutor de ${student.name} ${student.last_name} solicito una cita con motivo de ${ motivo }`,
            date: new Date().getDate(),
            time: new Date().getTime(),
            type: 'teacher',
            userList
        }, (error) => {
            if (error) { console.log(error) } 
        });
    }

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={ e => checkSubmit( e ) } >
                <Grid container direction="row" justify="center" alignItems="center">
                    {/* <NavTutorPhone /> */}
                    <Grid xs={12}>
                        <Box pb={3}>
                            <PageHeader 
                                title="Solicitar una cita"
                                text="Detalla el motivo y la hora para la cita"
                                btn_action={ true }
                                btn_action_text="guardar"
                                btn_back="/tutor/citas/alumno"
                            />
                            {
                                error
                                ?<Alert severity="error">{error}</Alert>
                                :null
                            }
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                            <Grid  container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={6} >
                                    <Box px={2} py={1} mt={3} >
                                        <label htmlFor="citaFecha" > FECHA </label>
                                        <TextField error={errordate} id="citaFecha" type="date" fullWidth value={date} onChange={ e => setDate( e.target.value )} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2} py={1} mt={3} >
                                        <label htmlFor="citaHora" > HORA </label>
                                        <TextField id="citaHora" error={errortime} type="time" fullWidth value={time} onChange={ e => setTime( e.target.value )} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box px={2} py={1} >
                                        <label htmlFor="citaFecha" > MOTIVO </label>
                                        <TextField id="citaMotivo" error={errormotivo} type="text" fullWidth placeholder="Escribe aqui tu motivo..." value={motivo} onChange={ e => setMotivo( e.target.value )} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box px={2} py={1} >
                                        <label htmlFor="citaFecha" > DETALLES </label>
                                        <TextField 
                                        error={errordetails}
                                        rows={4}
                                        multiline
                                        variant="outlined"
                                        placeholder="Escribe aqui tu mensaje..." 
                                        className="text_area-style" 
                                        value={details} 
                                        onChange={ e => setDetails( e.target.value )} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default Appointment