import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


// Imagenes
import chat from './../../Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import home from './../../Assets/Img/svg_icons/Icon feather-home.svg';
import sticky from './../../Assets/Img/svg_icons/Icon awesome-sticky-note.svg';
import logout from './../../Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import sede from './../../Assets/Img/svg_icons/Icon feather-user.svg';
import evento from './../../Assets/Img/svg_icons/Icon feather-calendar.svg';
import notification from './../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';
import materias from './../../Assets/Img/svg_icons/Icon feather-list.svg';
import evaluations from '../../Assets/Img/svg_icons/Icon material-playlist-add-check.svg';

import logo from './../../Assets/Img/Layout/Logo_text.png';

// Estilos
import './../../Assets/Styles/Layout.css';

import { AuthContext } from "../functions/Auth";


const Sidebar = ({}) => {
    const { handlelogout , currentUser } = useContext( AuthContext );

    return(
    <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
        <Box className="sidebar__headerTeacher" display="flex">
            <Box flexGrow={1} pt={2} pl={3}>
                <Link to="/home">
                    <img src={ logo } alt="Logo Kiper" />
                </Link>
            </Box>
            <Box pt={6}>
                <ul className="flex">
                    <li className="mx-3 bellinsidebar">
                        <Link to="/notificaciones">
                            <img src={ notification } alt="Usuario Kiper" style={{filter: "contrast(1) invert(100%)"}} className="sidebar_icon"/>
                        </Link>
                    </li>
                </ul>
            </Box>
        </Box>
        <Box className="sidebar__menu ">
            <ul className="pl-10 pt-5">
                <li className="my-5 cursor-pointer">
                    <Link to="/" className="flex">
                        <img src={ home } alt="Inicio Kiper" className="mr-3 sidebar_icon" />
                        <span>Inicio</span>
                    </Link>
                </li>
                <li className="my-5 cursor-pointer">
                    <Link to="/teacher/citas/tutores" className="flex">
                        <img src={ sticky } alt="Inicio Kiper" className="mr-3 sidebar_icon" />
                        <span>Solicitar Citas</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/teacher/materias/select/" className="flex">
                        <img src={ materias } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Materias</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/teacher/evaluaciones/group" className="flex"> 
                        <img src={ evaluations } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Evaluaciones</span>
                    </Link> 
                </li> 
                <li className="flex my-5 cursor-pointer">
                    <Link to="/teacher/citas" className="flex">
                        <img src={ evento } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Citas</span>
                    </Link>
                </li> 
                <li className="flex my-5 cursor-pointer">
                    <Link to="/teacher/profile" className="flex">
                        <img src={ sede } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Perfil</span>
                    </Link>
                </li> 
                <li className="flex my-5 cursor-pointer">
                    <Link to="/teacher/chat" className="flex">
                        <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Chat</span>
                    </Link>
                </li>
                {
                    currentUser && 
                    <li className="flex my-5 cursor-pointer">
                        <Link to={`/admin/horarios/profesores/detalles/info/${currentUser}`} className="flex">
                            <img src={ evento } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                            <span>Ver horario</span>
                        </Link>
                    </li> 
                }
                <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon"/>
                    <span>Salir</span>
                </li> 
            </ul>
        </Box>
    </Box>
    )
};

export default Sidebar
