import React from 'react'
import { TextField } from '@material-ui/core'
import { useField } from 'formik'
 
const TextFieldComponent = ({
    name,
    placeholder,
    variant,
    ...others
}) => {

    const [field, data] = useField(name)
    
    const configTxtField = {
        ...field,
        ...others,
        fullWidth: true,
        placeholder,
        variant,
    }

    if(data && data.touched && data.error) {
        configTxtField.error = true
        configTxtField.helperText = data.error
    }

    return (
        <TextField {... configTxtField} />
    )
}
export default TextFieldComponent