import React, {useEffect, useState} from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

//Resources
import user from './../../Assets/Img/General/placeholder_boy.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function GroupSelect({ obj }) {
    return(
        <Link to={ `/teacher/citas/tutores/${obj[1]?.group_key}` } >
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Box id="kiper_tutor_cita_childItem" p={2} display="flex" mt={1} >
                    <img src={ user } alt="avatar" className="img_home" style={{height:"70px", width:"70px"}}/>
                    <Box  ml={2} className="align-items-center" >
                        <Typography variant="h6" className="kipper_txt-grey " >
                            { obj[1]?.details?.level } { obj[1]?.details?.grade } { obj[1]?.details?.group } { obj[1]?.details?.turn }
                        </Typography>
                    </Box>
                </Box>
                <Box >
                    <ArrowForwardIosIcon />
                </Box>
            </Grid>
            <hr/>
        </Link>
   
    );
}
