import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//materialui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// Imagenes
import notification from './../../Assets/Img/svg_icons/Icon ionic-ios-notifications.svg';
import solicitar from './../../Assets/Img/svg_icons/Icon awesome-sticky-note.svg';
import chat from './../../Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import calendar from './../../Assets/Img/svg_icons/Icon feather-calendar.svg';
import profile from './../../Assets/Img/svg_icons/Icon feather-user.svg';
import settings from './../../Assets/Img/svg_icons/Icon feather-settings.svg';
import information from './../../Assets/Img/svg_icons/Icon ionic-ios-information-circle-outline.svg';
import documents from './../../Assets/Img/svg_icons/Icon material-file-download.svg';
import logout from './../../Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import home from './../../Assets/Img/svg_icons/Icon feather-home.svg';
import logo from './../../Assets/Img/Layout/Logo_text.png';

// Estilos
import './../../Assets/Styles/Layout.css';
//
import app from "../functions/credentials";

import { AuthContext } from "../functions/Auth";


const SideBarTutor = ({}) => {
    const { handlelogout} = useContext( AuthContext );

    return(
    <Box className="sidebar" boxShadow={1} borderRadius={16} m={3} >
        <Box className="sidebar__headerTutor" display="flex">
            <Box flexGrow={1} pt={2} pl={3}>
                <Link to="/home">
                    <img src={ logo } alt="Logo Kiper" />
                </Link>
            </Box>
            <Box pt={6}>
                <ul className="flex">
                    <li className="mx-3 bellinsidebar">
                        <Link to="/notificaciones">
                            <img src={ notification } alt="Usuario Kiper" style={{filter: "contrast(1) invert(100%)"}} className="sidebar_icon" />
                        </Link>
                    </li>
                </ul>
            </Box>
        </Box>
        <Box className="sidebar__menu ">
            <ul className="pl-10 pt-5">
            <li className="flex my-5 cursor-pointer">
                    <Link to="/" className="flex">
                        <img src={ home } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Inicio</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/citas" className="flex">
                        <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Citas</span>
                    </Link>
                </li> 
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/citas/alumno" className="flex">
                        <img src={ solicitar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Solicitar Citas</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/calendario" className="flex">
                        <img src={ calendar } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Eventos</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/profile" className="flex">
                        <img src={ profile } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Perfil</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/information" className="flex">
                        <img src={ information } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Información</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/documents" className="flex">
                        <img src={ documents } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Documentos</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/chat" className="flex">
                        <img src={ chat } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>Chat</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer">
                    <Link to="/tutor/faq" className="flex">
                        <img src={ settings } alt="Usuario Kiper" className="mr-3 sidebar_icon" />
                        <span>FAQ's</span>
                    </Link>
                </li>
                <li className="flex my-5 cursor-pointer" onClick={() => handlelogout()}>
                    <img src={ logout } alt="Usuario Kiper" className="mr-3 sidebar_icon"/>
                    <span>Salir</span>
                </li> 
                
            </ul>
        </Box>
    </Box>
    )
};

export default SideBarTutor