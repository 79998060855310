import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ImformationItem from './../../Components/Global/ImformationItem';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import file from './../../Assets/Img/General/File.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
//firebase
import * as firebase from 'firebase';
import { logDOM } from '@testing-library/react';

function SchoolSubjectsDetails () {

    const history = useHistory();
    const match = useRouteMatch('/teacher/materias/informacion/:id');
    const subjectsID = match.params.id;

    
    const [ docOpcional, setDocOpcional ]       = useState('');
    const [ scheduleTime, setScheduleTime ] = useState([ 10, 30 ]);
    const [ scheduleDay, setScheduleDay ] = useState({
        monday: true,
        thuesday: false,
        wesday: false,
        tursday: false,
        friday: true,
        saturday: false
    });
    const [ Data, setData ]         = useState([]);
    const [ error, setError]        = useState('');
    const [ loader, setLoaderChild] = useState( false );
    const { currentUser, info, infoSchool} = useContext(AuthContext);
    useEffect(() => handleLoad(),[]);

    function handleLoad ( e ) {
        let db = firebase.database();
        let ref = db.ref('/schools/'+infoSchool.school+'/school_subjects/');
        let childRef = ref.child( subjectsID );
        childRef.once('value').then(function(snapshot) {
            setData(snapshot.val());
            // console.log('test/schools/'+info.code+'/school_subjects/'+subjectsID)
        })
    }

    

    const handlesubmit = e =>{
        console.log("info",info);
    //     e.preventDefault();
    //     let db = firebase.database();
    //     let ref = db.ref(`teachers`);
    //     let teacherRef = ref.child( teacherID );
    //     teacherRef.update({
            
    //     }, 
    //     (error) => {
    //         if (error) { console.log(error) } 
    //         else {
    //             alert('information was change');
    //         }
    //     });
    //     handleLoad(e);
    }

    return(
        <form onSubmit={ e => handlesubmit( e ) } onLoad={ (e) => handleLoad( e ) }>
            <Grid container className="alumInfo_grid">
                <Grid item xs={12} sm={12} >
                    <Box  pb={3}>
                        <PageHeader 
                            title="Detalles de la materia"
                            text="Define detalles de la asignatura."
                            btn_back="/teacher/materias"
                            btn_action={true}
                            btn_action_text="Guardar"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} className="Form_add-content">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content">
                        <Box >
                            <img src={ portada } alt="" className="kipperBox_portada-img" />
                        </Box>
                        <Box  className="Form_add-profile-img">
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <div className="kipperBox_content-upload">
                                    <img src={ file } alt=""/>
                                </div>
                            </Grid>
                        </Box>
                        <Box p={3}>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}>
                                        <Typography variant="h5">
                                            {Data.nombre}
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${Data.nombre}, ${Data.grade}, ${Data.group}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box py={1}> 
                                        <Typography variant="body1" align="right">
                                            $Fecha $hora
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Acerca de la materia
                                        </Typography>
                                    </Box>
                                    <Box border={1} p={2} >
                                        {Data.description}    
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="h6">
                                            Horario de clase
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                <RadioGroup value={ scheduleDay } onChange={ e => setScheduleDay( e.target.value ) } row  >
                                    <FormControlLabel value="monday" control={<Radio  color="primary" />} label="Lunes" />
                                    <FormControlLabel value="tuersday" control={<Radio  color="primary" />} label="Martes" />
                                    <FormControlLabel value="wensday" control={<Radio  color="primary" />} label="Miercoles" />
                                    <FormControlLabel value="thursady" control={<Radio  color="primary" />} label="Jueves" />
                                    <FormControlLabel value="friday" control={<Radio  color="primary" />} label="Viernes" />
                                    <FormControlLabel value="saturday" control={<Radio  color="primary" />} label="Sabado" />
                                </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="subtitle1">
                                            Horario
                                        </Typography>
                                        <Typography id="range-slider" gutterBottom>
                                            Temperature range
                                        </Typography>
                                        <Slider
                                            value={ Data.schedule }
                                            onChange={ e => setScheduleTime( e.target.value ) }
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                        />
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography variant="subtitle1">
                                            Temario
                                        </Typography>
                                        <Box p={2}>
                                                <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => setDocOpcional( ( e.target.files[0] ) ) }  />
                                                <label for="uploadFile" className="kipper_txt-color pointer">
                                                    <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                        <img src={ upload } alt=""/>
                                                        <p className="kipper_txt-color">Selecciona archivo</p>
                                                    </Grid>
                                                </label>
                                                <Typography className="" variant="body1" display="block" gutterBottom>
                                                    <b>*Los documentos deben ser de tipo PDF, DOC, JPEG o PNG.</b> 
                                                </Typography>
                                            </Box>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
    
}

export default SchoolSubjectsDetails