import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Coponent
import { AuthContext } from "./../Components/functions/Auth";
import ModalShow from '../Components/Global/Modal';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// firebases
import * as firebase from 'firebase';

const SingIn = () => {
    const { setCurrentUser, setSession } = useContext(AuthContext);

    const history   = useHistory();

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [type, setType] = useState("");
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    
    const handleSubmit = ( e ) => {
        e.preventDefault();
        setError('')

        const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        let checkemail = regexemail.test(email);
        let checkpass = regexpassword.test(password);

        if( code === '' ){
            setError('Ingrese algun código');
        }else if( email === "" ){
            setError('Ingrese algun email');
        }else if( password === "" ){
            setError('Ingrese alguna contraseña');
        }else if(checkemail === false){
            setError('El email no presenta el formato correcto');
        }else if(checkpass === false){
            setError('La contraseña debe contener numero, una mayuscula y 8 caracteres como minimo');
        }else {
            setError('');
            SaveDoc();
        }
    }

    async function SaveDoc (){
        var typecode = code.split("_")
        if(typecode[0] == "teacher"){
            firebase.database().ref(`teacher/${code}`).once('value')
            .then(function(snapshot) {
                if(snapshot.exists()){
                    if(snapshot.val().contact.email == email )
                    {
                        let db = firebase.database();
                        let ref = db.ref('teacher');
                        ref.once('value').then(function(snapshot) {
                            snapshot.forEach( e=>{
                                if(e.key == code){
                                    firebase.auth().createUserWithEmailAndPassword(email, password)
                                    .then((result) => {
                                        firebase.database().ref('Info').child( result.user.uid ).set( { coderef: code, type: 'teacher' } );
                                        setCurrentUser(result.user.uid)
                                        setSession(true)
                                        localStorage.setItem("currentUser" ,code)
                                        localStorage.setItem("userType" , 'teacher')
                                        history.push('/');
                                        window.location.reload();
                                    })
                                    .catch(e => {
                                        setError(e.message);
                                    });
                                }
                            })
                        });
                    }
                    else
                    {
                        setError('El email y el código proporcionado no concuerdan con los proporcionados por la escuela')
                    }
                }else{
                    setError('El código que ingreso no existe en nuestra base de datos')
                }
            })
            .catch(function(error) {
                setError(error.message)
            })
        }else if(typecode[0] == "child"){
           
            firebase.database().ref(`childs/${code}`).once('value')
            .then(function(snapshot) {
                if(snapshot.exists()){
                        let db = firebase.database();
                        let ref = db.ref('childs');
                        ref.once('value').then(function(snapshot) {
                            snapshot.forEach( e=>{
                                if(e.key == code){
                                    firebase.auth().createUserWithEmailAndPassword(email, password)
                                    .then((result) => {        
                                        firebase.database().ref('Info').child( result.user.uid ).set( { coderef: code, type: 'child' } );
                                        setCurrentUser(result.user.uid)
                                        setSession(true)
                                        localStorage.setItem("currentUser" ,code)
                                        localStorage.setItem("userType" , 'child')
                                        history.push('/');
                                        window.location.reload();
                                    })
                                    .catch(e => {
                                        setError(e.message);
                                    });
                                }
                            })
                        });
                }else{
                    setError('El código que ingreso no existe en nuestra base de datos')
                }
            })
            .catch(function(error) {
                setError(error.message)
            })
        }else if(typecode[0] == "tutor"){
            firebase.database().ref(`tutors/${code}`).once('value')
            .then(function(snapshot) {
                if(snapshot.exists()){
                    if(snapshot.val().contact.email == email )
                    {
                        let db = firebase.database();
                        let ref = db.ref('tutors');
                        ref.once('value').then(function(snapshot) {
                            snapshot.forEach( e=>{
                                if(e.key == code){
                                    firebase.auth().createUserWithEmailAndPassword(email, password)
                                    .then((result) => {
                                        firebase.database().ref('Info').child( result.user.uid ).set( { coderef: code, type: 'tutor' } );
                                        setCurrentUser(result.user.uid)
                                        setSession(true)
                                        localStorage.setItem("currentUser" ,code)
                                        localStorage.setItem("userType" , 'tutor')
                                        history.push('/');
                                        window.location.reload();
                                    })
                                    .catch(e => {
                                        setError(e.message);
                                    });
                                }
                            })
                        });
                    }
                    else
                    {
                        setError('El email y el código proporcionado no concuerdan con los proporcionados por la escuela')
                    }
                }else{
                    setError('El código que ingreso no existe en nuestra base de datos')
                }
            })
            .catch(function(error) {
                setError(error.message)
            })
        }else if(typecode[0] == "school"){
            firebase.database().ref(`schools/${code}`).once('value')
            .then(function(snapshot) {
                if(snapshot.exists()){
                    if(snapshot.val().contact.email == email )
                    {
                        let db = firebase.database();
                        let ref = db.ref('schools');
                        ref.once('value').then(function(snapshot) {
                            snapshot.forEach( e=>{
                                if(e.key == code){
                                    firebase.auth().createUserWithEmailAndPassword(email, password)
                                    .then((result) => {
                                        firebase.database().ref('Info').child( result.user.uid ).set( { coderef: code, type: 'school' } );
                                        setCurrentUser(result.user.uid)
                                        setSession(true)
                                        localStorage.setItem("currentUser" ,code)
                                        localStorage.setItem("userType" , 'school')
                                        history.push('/');
                                        window.location.reload();
                                    })
                                    .catch(e => {
                                        setError(e.message);
                                    });
                                }
                            })
                        });
                    }
                    else
                    {
                        setError('El email y el código proporcionado no concuerdan con los proporcionados por la escuela')
                    }
                }else{
                    setError('El código que ingreso no existe en nuestra base de datos')                   
                }
            })
            .catch(function(error) {
                setError(error.message)
            })

        }else{
            setError('El codigo es incorrecto')
        }
    }
    
    return(
        <form onSubmit={ e => handleSubmit( e ) }>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Box>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box py={3}>
                        <Typography className="kipper_title" variant="h5">Registro para maestros</Typography>
                        <Typography className="" variant="body1" > 
                            Introduce código de referencia, correo electrónico y contraseña
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box pb={1}>
                        <TextField type="text" variant="outlined" placeholder="Código de registro" fullWidth value={ code } onChange={e => setCode(e.target.value)} />
                    </Box>
                    <Box pb={1}>
                        <TextField type="text" variant="outlined" placeholder="Correo eletrónico" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                    </Box>
                    <Box pb={1}>
                        <TextField type="password" variant="outlined" placeholder="Contraseña" fullWidth value={ password } onChange={e => setPassword(e.target.value)} />
                    </Box>
                    {
                        error ?
                            <Alert severity="error">{error}</Alert> : null
                        
                    }
                    {
                        type ? 
                            <Alert severity="success">{type}</Alert> : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box py={1}>
                        <Button id="Enviar_button_registerUser" type="submit" className="kipper_button FullWidthButton" variant="contained" fullWidth >Enviar</Button>
                    </Box>
                </Grid>
            </Box>
        </form>
    );
}

export default SingIn
