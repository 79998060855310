import React, { useState, useContext} from 'react';
import { Link, Redirect } from 'react-router-dom';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
//
import { AuthContext } from "./Auth";
import app from './credentials';

import info from '../../../src/Assets/Img/svg_icons/Icon ionic-ios-information-circle-outline-red.svg';

import '../../Assets/Styles/Responsive.css';

function Formlogin(){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setErrors] = useState("");
    const {currentUser, setCurrentUser, setuserType, setInfo, setSession  }  = useContext(AuthContext);

    
    const handlesubmit = e =>{
        e.preventDefault();
        app.auth().signInWithEmailAndPassword(email, password)
        .then(res => {
            var db = app.database();
            var ref = db.ref(`Info/${res.user.uid}`);
            ref.once('value')
            .then(function(snapshot){
                setSession(true)
                localStorage.setItem("currentUser" ,snapshot.val()?.coderef)
                localStorage.setItem("userType" ,snapshot.val()?.type)
                localStorage.setItem("email" ,email)
                setInfo(snapshot.val())
                setuserType(snapshot.val()?.type)
                setCurrentUser(snapshot.val()?.coderef)

                if(snapshot.val().type == "Admin"){
                    setuserType(snapshot.val().type)
                    setCurrentUser(res.user.uid)
                    localStorage.setItem("userType" , "Admin")
                    localStorage.setItem("currentUser" ,res.user.uid)

                }else{
                    if(snapshot.val().type == 'child'){
                        //Guardo grupo, grado y turno del niño para Auth.js en eventos y comunicados
                        let ref = db.ref(`childs/${snapshot.val().coderef}/details/`);
                        ref.once('value').then(chil =>{
                            localStorage.setItem("childGroup", chil.val().group);
                            localStorage.setItem("childGrade", chil.val().grade);
                            localStorage.setItem("childTurn", chil.val().turn);
                        }) 
                    }else if(snapshot.val().type == 'teacher'){
                        //Guardo grupo, grado y turno del maestro para Auth.js en eventos y comunicados
                        let ref = db.ref(`teacher/${snapshot.val().coderef}/groups`);         
                        let listGroups = [];
                        let teacherGroups = '';
                        ref.once('value').then(chil =>{
                            chil.forEach(e=>{
                                if(e.val().details){
                                    const group = e.val().details;

                                    Object.values(group).forEach(t=>{
                                        const ff = listGroups.filter(obj => obj.grade === group.grade && obj.group === group.group && obj.turn === group.turn );
                                        if(ff.length == 0){
                                            listGroups.push({grade:group.grade,group:group.group,turn:group.turn});
                                        } 
                                    })
                                }  
                            });
                            teacherGroups = JSON.stringify(listGroups);
                            localStorage.setItem("teacherGroups",teacherGroups);
                        })
                    }else if(snapshot.val().type == 'tutor'){
                        let tutorChilds = '';
                        let ref = db.ref(`childs`);
                        let listChild = [];
                        ref.once('value').then(chil =>{
                            chil.forEach(e=>{
                                const child = e.val();
                                
                                if(child.tutors){
                                    Object.values(child.tutors).forEach(t =>{
                                        const ff = listChild.filter(obj => obj.grade === child.details.grade && obj.group === child.details.group && obj.turn === child.details.turn );
                                        if(t == snapshot.val().coderef && ff.length == 0){
                                            listChild.push({grade:child.details.grade,group:child.details.group,turn:child.details.turn});
                                        } 
                                    })
                                }
                            })
                            tutorChilds = JSON.stringify(listChild);
                            localStorage.setItem("tutorChilds",tutorChilds);
                        })

                    }
                }

            });
        })
        .catch(e => { 
            setPassword('');
            setEmail('');
            console.log(e.message)
            setErrors(e.message); 
        });

    }

    const handleAutocomplete = (event) => {
        if(event.target.autocomplete)
        {
            event.target.autocomplete = "";
        }
    }
    if (currentUser || localStorage.getItem('currentUser') ) {
        return <Redirect to="/" />
    }
    else
    {

        return(
            <>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box py={2}>
                        <Typography className="kipper_title" variant="h4"> Inicia sesión </Typography>
                    </Box>
                </Grid>
                <form onSubmit={e=> handlesubmit(e)}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box py={1}>
                                <TextField onChange={e => setEmail(e.target.value)} id="email" variant="outlined" placeholder="Correo electrónico" type='email' value={email} autoComplete="off" onFocus={handleAutocomplete} fullWidth required />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box py={1}>
                                <TextField onChange={e => setPassword(e.target.value)} id="Password" variant="outlined" placeholder="Contraseña" type='password' value={password} fullWidth required/>
                            </Box>
                        </Grid>
                        <Grid container direction="column" justify="center" alignItems="center" id="Login__options" >
                            <Box py={1} >
                                {  error &&<Grid container justify="center" alignItems="center" id="Login__alertBox"><img src={info} alt="informativo" /><Typography id="Login__alert">El email y/o contraseña no son válidos</Typography></Grid>  }
                                <Link to="/recuperar_contrasena"> 
                                    <Typography className="Texto_login_links"  variant="caption" > ¿Olvidaste tu contraseña?  </Typography>
                                </Link> 
                                <br/>
                                <Link to="/registro/maestros"> 
                                    <Typography className="Texto_login_links"  variant="caption" > Registro para usuarios </Typography>
                                </Link> 
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="start_box log-mb" py={1}>
                            <Button type="submit" className="kipper_btn-outline-primary" variant="outlined" fullWidth>INICIAR</Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }

}
export default Formlogin;
