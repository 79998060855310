import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import user from './../../Assets/Img/General/user_example.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';

function ChatView () {

    const history = useHistory();
    
    const [ isShow, setIsShow ]         = useState( false );
      
    const [ tutorError, setTutorError ] = useState('');
    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const GetParents = () => {
        const { currentUser }   = useContext(AuthContext);
        const [ list, setList ] = useState([]);
        useEffect(() => handleload(),[]);
    
        const handleload = e =>{
            let db = firebase.database();
            let ref = db.ref('tutors');
            let listItem = [];
            ref.once('value').then(function(snapshot) {
                // console.log('chat list', snapshot.val());
                snapshot.forEach( item => {
                    // console.log('item chat', item);
                    let itemInfo = item.val()
                    if ( itemInfo.status === "active"  ) {
                        listItem.push( 
                            <item
                                key={ item.key }
                                img={ itemInfo.avatar || user } 
                                name={ `${itemInfo.name} ${itemInfo.lastname ? itemInfo.lastname :""} ` } 
                                info={ `Email ${itemInfo.contact.email}` } 
                                link={ `/chat/${item.key}` }
                            />
                        )
                    }
                })
                setList( listItem );
                // console.log('listItem', listItem);
            });
        }
        return ( list );
    }

    return(
        <Grid container className="padres_grid">
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global"> 
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3}>
                    <PageHeader 
                        title="Chats de padres"
                        text="Comunicate directamen" 
                        btn_back="/alumnos"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box py={3}>
                    <ShowContracts
                        list={ GetParents()  } 
                        page="tutor"  
                        isChat={ true }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ChatView