import React, { useContext } from 'react';
import { ListItemText,ListItemIcon  } from '@material-ui/core';
import { Link } from 'react-router-dom';

//Icons
import materias from '../../../../src/Assets/Img/svg_icons/Icon feather-list.svg';
import chat from '../../../../src/Assets/Img/svg_icons/Icon material-chat_bubble_outline.svg';
import logout from '../../../../src/Assets/Img/svg_icons/Icon material-exit-to-app.svg';
import home from '../../../../src/Assets/Img/svg_icons/Icon feather-home.svg';
import calendar from '../../../../src/Assets/Img/svg_icons/Icon feather-calendar.svg';
import information from '../../../../src/Assets/Img/svg_icons/Icon ionic-ios-information-circle-outline.svg';

const NavHeaderPhoneOptions =({StyledMenuItem,handlelogout})=>{

    return(
        <>
            <StyledMenuItem>
                <ListItemIcon>
                    <img src={home} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/" className="flex">
                    <ListItemText primary="Inicio" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ chat } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/alumno/chat" className="flex">
                    <ListItemText primary="Chat" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={calendar} alt="Usuario Kiper"/>
                </ListItemIcon>
                <Link to="/alumno/calendario" className="flex">
                    <ListItemText primary="Calendario" />
                </Link>  

            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ information } alt="Usuario Kiper" />
                </ListItemIcon>
                <Link to="/child/information" className="flex">
                    <ListItemText primary="Informacion" />
                </Link>
                
            </StyledMenuItem>

            <StyledMenuItem>
                <ListItemIcon>
                    <img src={ logout } alt="Usuario Kiper" />
                </ListItemIcon>
                
                <ListItemText primary="Salir" onClick={handlelogout}/>
          
            </StyledMenuItem>
        </>
    );
}

export default NavHeaderPhoneOptions;