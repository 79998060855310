import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from "./../../Components/functions/Auth";
import { Grid, Box } from '@material-ui/core'
import PageHeader from '../../Components/Global/PageHeader'
import CalendarItem from '../../Components/tutors/CalendarItem'
import Calendario from '../../Components/Global/Calendar'
import EventoItem from './../../Components/Global/EventoItem';
import { Typography } from '@material-ui/core';


// firebases
import * as firebase from 'firebase';
import NavTutorPhone from './NavTutorPhone/NavTutorPhone';

function Calendar() {
    
    const history   = useHistory();
    const { info, currentUser, infoSchool, userType }   = useContext(AuthContext);
    const loading = useRef(true);

    const GetEvents = () => {
        const [ list, setList ] = useState([]);

        useEffect(() => {
            if(infoSchool){
                console.log("loading: ",loading.current);
                handleload()
            }
        },[infoSchool,loading]);

        const handleload = e =>{
            let db = firebase.database();
            let ref = db.ref(`comunicados`);
            let listItem = [];
            console.log("ref: ",ref);
            ref.orderByChild("date").once('value').then(function(snapshot) {
                // console.log(snapshot.val())
                snapshot.forEach( item => {
                    let itemInfo = item.val()
                    if(userType === "tutor"){
                        
                        if ( itemInfo.status === "active" && itemInfo.school === infoSchool.school && ( itemInfo.receiver_event === 'tutor' || itemInfo.receiver_event === 'all' )) {

                            listItem.push( 
                                <Grid item sm={6} key={ item.key }>
                                    <Box m={3}>
                                        <EventoItem  
                                            key={ item.key }
                                            id={ item.key }
                                            isRemove={ true }
                                            type={ itemInfo.type } 
                                            title={ `${itemInfo.name}` } 
                                            body={ `${itemInfo.details_event}` } 
                                            date={ `${itemInfo.date}` } 
                                            btn_to={ `/public/comunicados/${item.key}` }
                                        /> 
                                    </Box>
                                </Grid>
                            )
                        }
                    }else if(userType === "teacher"){
                            console.log(itemInfo)
                        if ( itemInfo.status === "active" && itemInfo.school === infoSchool.school && itemInfo.type === "evento" && ( itemInfo.receiver_event === 'teacher' || itemInfo.receiver_event === 'all' )) {
                            listItem.push( 
                                <Grid item sm={6} key={ item.key }>
                                    <Box m={3}>
                                        <EventoItem  
                                            key={ item.key }
                                            isRemove={ true }
                                            id={ item.key }
                                            type={ itemInfo.type } 
                                            title={ `${itemInfo.name}` } 
                                            body={ `${itemInfo.details_event}` } 
                                            date={ `${itemInfo.date}` } 
                                            btn_to={ `/public/comunicados/${item.key}` }
                                        /> 
                                    </Box>
                                </Grid>
                            )
                        }
                    }
                    loading.current = false;
                })
                setList( listItem );
            });
        }
        if(loading.current){//Cargando
            return(
                <Grid item sm={6} >
                    <Box m={3}>
                        <Typography variant="p">Cargando...</Typography> 
                    </Box>
                </Grid>
            )
        }else{//Consulta realizada
            if(list.length != 0)
                return ( list );
            else
                return(
                    <Grid item sm={6} >
                        <Box m={3}>
                        <Typography variant="p">No hay eventos para ti</Typography> 
                        </Box>
                    </Grid>
                ) 
        }
    }


    
    return (
        <Grid container className="padres_grid">
            {/* <NavTutorPhone /> */}
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Calendario de eventos"
                        text="Selecciona un evento para ver mas detalles." 
                        btn_back="/"
                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Grid container>
                    { GetEvents() }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Calendar
