import React from 'react';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';

const ContentSection =({ box_img, box_title, box_text, box_btn_text, box_btn_link, })=>{
    return(
        <Box boxShadow={3} borderRadius={16}>
            <Grid>
                <Grid className="ContentSection_Img" xs={12}>
                    <img src={ box_img } alt=""/>
                </Grid >
                <Grid xs={12}  className="ContentSection" container direction="column" justify="space-around" alignItems="stretch" >
                    <Box my={3}>
                        <Typography variant="h5" className="ContentSection_Title">{box_title}</Typography>
                        <Typography className="ContentSection_Text"> {box_text} </Typography>    
                    </Box>
                    <Link to={ box_btn_link }> 
                        <Button className="ContentSection_button" variant="contained" color="primary" size="large" disableElevation>
                            { box_btn_text }
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
}


export default ContentSection;