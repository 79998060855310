import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
import Layout from '../../Pages/Layout'

const PrivateRoute = ({ component: Component, type: typeUser, ...rest }) => {
  const { currentUser, userType } = useContext(AuthContext);
  if( rest.location.pathname !== "/" )
  {
    localStorage.setItem('lastpath', rest.location.pathname)
  }
  const type = localStorage.getItem('userType');
  const user = localStorage.getItem('currentUser')

  return (
    <Route {...rest} render={routeProps =>
      user && (type == typeUser || typeUser == "all")
        ?<Layout>
          <Component {...routeProps} />
        </Layout> 
      :<Redirect to={"/login"} />
    }/>
  );
};


export default PrivateRoute