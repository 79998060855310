import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//Component
import PageHeader from '../Components/Global/PageHeader';
import { AuthContext } from "../Components/functions/Auth";
import ModalShow from '../Components/Global/Modal';
//img
import portada from './../Assets/Img/General/portada_add-yellow.png';
import file from './../Assets/Img/General/File.png';
import upload from './../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';

//firebase
import * as firebase from 'firebase';
import { checkFile } from '../Components/functions/helpapers';
import { checkFilepdf } from '../Components/functions/helpapers';
import NavEscuelaPhone from './Escuela/NavEscuelaPhone/NavEscuelaPhone';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';

//Components Override
import TextField from '../Constants/textfield';
import Select from '../Constants/select';
import { TextareaAutosize } from '@material-ui/core';


function AgregarAlumno(){

    const history = useHistory();
    const { currentUser } = useContext(AuthContext);

    const [stopHanldeSubmit,setStopHanldeSubmit] = useState(false);

    const formRef = useRef();
    const [loading, setLoading] = useState({
        ofi: false,
        dom: false,
        opc: false
    })  

    const [errors, seterrors] = useState('');
    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    
    const [groupDetails, setGroupDetails] = useState([])
    const [groupSchool, setGroupSchool] = useState('')
    const [groupInfo, setGroupInfo] = useState([])
    const [grados, setGrados] = useState([])
    const [grupos, setGrupos] = useState([])
    
    const [ docProfile, setDocProfile ]           = useState('');
    const [ docProfileName, setDocProfileName ]   = useState('');
    const [ docId, setDocId ]                     = useState('');
    const [ docIdName, setDocIdName ]             = useState('');
    const [ docProofAddres, setDocProofAddres ]   = useState('');
    const [ docProofAddresName, setDocProofAddresName ]   = useState('');
    const [ docOpcional, setDocOpcional ]         = useState('');
    const [ docOpcionalName, setDocOpcionalName ] = useState('');
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    let listGrados = []
    let listGrupos = []

    // const regExps ={
    //     nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
    //     apellido:  /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
    //     codigoPostal: /^[0-9]{0,7}$/,
    // }

    // const regexname = RegExp(/\w/g);
    //     const regexemail = RegExp(/^\w+([\.-]?\D+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    //     const regexphone = RegExp(/\d+[^a-zA-Z]{6,}/);
    //     const regexpassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);  
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect( () =>{
        let db = firebase.database();
        let list = [];
        let Ref = db.ref(`schools/${currentUser}/groups/details`);
        Ref.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                list.push( itemInfo )
            })
        })
        setGroupDetails( list )
    }, [ ] );

    useEffect( () =>{
        let db = firebase.database();
        let list = [];
        let Ref = db.ref(`schools/${currentUser}/groups`);
        Ref.once('value').then( snapshot => {
            snapshot.forEach( item => {
                let itemInfo = item.val()
                list.push( 
                    <item
                        key={ item.key }
                        grade={ itemInfo.grade }
                        group={ itemInfo.group }
                        turn={ itemInfo.turn }
                        level={ itemInfo.level }
                    />
                )
            })
        })
        setGroupInfo( list )
    }, [ ] );

    const selectGroup = () => {
        setGroupSchool('')
        let grade = formRef.current.values.grade
        let group = formRef.current.values.group
        let turn = formRef.current.values.turn
        let level = formRef.current.values.level

        groupInfo.forEach( item => {
            if( item.props.grade === grade && item.props.group === group && item.props.turn === turn && item.props.level === level){
                setGroupSchool(item.key)
            }
        })
    }

    const fillGrados = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        setGrados([])
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        groupInfo.forEach( item => {
            if(item.props.level === level && item.props.turn === turn && !listGrados.includes(item.props.grade) ){
                switch(item.props.grade){
                    case 'Primero':
                        listGrados.push([1,item.props.grade])
                        break;
                    case 'Segundo':
                        listGrados.push([2,item.props.grade])
                        break;
                    case 'Tercero':
                        listGrados.push([3,item.props.grade])
                        break;
                    case 'Cuarto':
                        listGrados.push([4,item.props.grade])
                        break;
                    case 'Quinto':
                        listGrados.push([5,item.props.grade])
                        break;
                    case 'Sexto':
                        listGrados.push([6,item.props.grade])
                        break;
                }
            }

            const unicos = []
            const aux = {}
            listGrados.forEach(el => !(el in aux) && (aux[el] = true) && unicos.push(el))

            setGrados( unicos.sort((a,b) => a[0] -b[0]))

            if(listGrados.length === 0){
                setGrados(false)
            }
        })
    }

    const fillGrupos = () => {
        let level = formRef.current.values.level
        let turn = formRef.current.values.turn
        let grade = formRef.current.values.grade
        setGrupos([])
        formRef.current.values.group = ''
        groupInfo.forEach( item => {
            if(item.props.level === level && item.props.turn === turn && item.props.grade === grade && !listGrupos.includes(item.props.group)){
                listGrupos.push(item.props.group)
            }
            setGrupos(listGrupos.sort())
            if(listGrupos.length === 0){
                setGrupos(false)
            }
        })
    }

    const reset = () => {       
        setGrados([])
        setGrupos([])
        formRef.current.values.grade = ''
        formRef.current.values.group = ''
        formRef.current.values.turn = ''
        listGrados = []
        listGrupos = []
    }

    const handleSubmit = val =>{
        if(!stopHanldeSubmit){
            setStopHanldeSubmit(true);

            const avatarIMG = docProfile && document.getElementById('AvatarIMG').src ;
            const idIMG = docId && document.getElementById('idIMG').src ;
            const comprobanteIMG = docProofAddres && document.getElementById('comprobanteIMG').src;
            const opcionalIMG =  docOpcional && document.getElementById('opcionalIMG').src;

            let id = `child_${Date.now()}`;
            let db = firebase.database();
            let Usersref = db.ref(`childs/${id}`);
            Usersref.set({
                id: id,
                school: currentUser,
                avatar: avatarIMG,
                name: val.nombre,
                last_name: val.apellido,
                sex: val.sexo,
                age: val.nombre,
                observaciones:val.observaciones,
                status: 'active',
                type: 'child',
                child_type: val.level,
                group: groupSchool,
                details:{
                    grade: val.grade,
                    group: val.group,
                    turn: val.turn,
                },
                location:{
                    address: val.direccion,
                    city: val.ciudad,
                    zip_code: val.codigopostal,
                },
                email:val.email,
                documents:{
                    id: idIMG,
                    proof_address: comprobanteIMG,
                    opcional: opcionalIMG
                },
                tutors:{},
                teachers:{}
            }, function(error) {
                if (error) {
                // The write failed...
                    console.log(error)
                    setStopHanldeSubmit(false);
                } else {
                // Data saved successfully!
                    setModalmsg('El alumno fue creado con éxito');
                    setModalLink(`/padres/${id}`);
                    ModalOpen();
                }
            });
        
            let GroupRef = db.ref(`schools/${currentUser}/groups/${groupSchool}/student/${id}`);
            GroupRef.update({
                avatar: avatarIMG,
                name: val.nombre,
                last_name: val.apellido,
            }, 
            error => {
                if (error) {
                    // The write failed...
                    console.log(error)
                } 
                else {
                    // Data saved successfully!
                }
            });  
        }

    }

    const uploadAvatar = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFile( img );
        if ( fileIsValid ) {
            try {
                setDocProfile( img );
                seterrorMsg({...errorMsg, avatar: false });
                const storage = firebase.storage().ref();
                const upload = storage.child(`childs/avatar/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("AvatarIMG")
                        profileImg.src = url;
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            return seterrorMsg({...errorMsg, avatar: 'Formatos válidos: png, jpg y jpeg' });
        }  
    }

    const uploadDocId = async ( e ) =>{ 
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, ofi: true})
                setDocId( img );
                seterrorMsg({...errorMsg, id: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("idIMG")
                        profileImg.src = url;
                        setDocId(profileImg.src)
                        setDocIdName(img.name)
                    })
                    setLoading({...loading, ofi: false})
                })
            } catch (error) {
                setLoading({...loading, ofi: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, ofi: false})
            seterrorMsg({...errorMsg, id: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const uploadDocComprobante = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, dom: true})
                setDocProofAddres( img );
                seterrorMsg({...errorMsg, comprobante: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("comprobanteIMG")
                        profileImg.src = url;
                        setDocProofAddres(profileImg.src)
                        setDocProofAddresName(img.name)
                    })
                    setLoading({...loading, dom: false})
                })
            } catch (error) {
                setLoading({...loading, dom: false})
                console.log( error );
            }
        } else {
            setLoading({...loading, dom: false})
            seterrorMsg({...errorMsg, comprobante: 'Formatos válidos: png, jpg, jpeg y PDF' });
        }
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setLoading({...loading, opc: true})
                setDocOpcional( img );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional(profileImg.src)
                        setDocOpcionalName(img.name)
                    })
                    setLoading({...loading, opc: false})
                })
            } catch (error) {
                setLoading({...loading, opc: false})
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg y PDF' });
            setLoading({...loading, opc: false})
        }
    }

    const validateForm = yup.object({
        nombre: yup
            .string('Ingrese un nombre')
            .required('El nombre es obligatorio')
            .matches(/^\w\D{2,100}$/, "El nombre debe contener entre 2 a 100 caracteres y no tener numeros" ),
        apellido: yup
            .string('Ingrese un apellido')
            .required('El apellido es obligatorio')
            .matches(/^\w\D{2,100}$/, "El apellido debe contener entre 2 a 100 caracteres y no tener numeros" ),
        email: yup
            .string('Ingrese un correo electronico')
            .email('Ingrese un correo válido')
            .required('El correo electronico es obligatorio'),
        direccion: yup
            .string('Ingrese una dirección')
            .required('La dirección es obligatoria'),
        codigopostal: yup
            .string()
            .required('El CP es obligatorio')
            .matches(/^(?:\d{5})?$/, 'El CP debe contener al menos 5 dígitos y ser númerico'),
        ciudad: yup
            .string('Ingrese una ciudad')
            .required('La ciudad es obligatoria')
            .matches(/^\w\D{2,100}$/, "La ciudad debe no tener numeros y contener entre 2 a 100 caracteres" ),
        sexo: yup
            .string('Seleccione el género')
            .required('El genero es obligatorio'),
        turn: yup
            .string('Seleccione un turno')
            .required('El turno es obligatorio'),
        observaciones: yup
            .string('Ingrese observaciones')
            .required('Las observaciones son obligatorias'),
        grade: yup
            .string('Seleccione un grado')
            .required('El grado es obligatorio'),
        group: yup
            .string('Seleccione un grupo')
            .required('El grupo es obligatorio'),
        level: yup
            .string('Seleccione el género')
            .required('El nivel es obligatorio'),

    });
      
    const initialFormState ={
        nombre: '',
        apellido: '',
        direccion: '',
        email:'',
        codigopostal: '',
        ciudad: '',
        sexo: '',
        turn: '',
        grade: '',
        group: '',
        level: '',
        observaciones:''
    }
    
    return(
        <Formik
            initialValues={{
                ...initialFormState
            }}
            innerRef={formRef}
            validationSchema={validateForm}
            onSubmit={values =>{
                handleSubmit(values)
            }}
        >
            <Form>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        />
                    </Grid>
                </Modal>
                <Grid container>
                    {/* <NavEscuelaPhone /> */}
                    <Grid item xs={12}>
                        <Box pb={3}>
                            <PageHeader 
                                title="Nuevo alumno"
                                text="Información y documentación del alumno"
                                btn_back="/alumnos"
                                btn_action={true}
                                btn_action_text="Siguiente"
                            />
                            <p className="kipper_txt-color">{errors}</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="Form_add-content kipper_box">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box className="Form_add-profile-img">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <input type="file" id="ProfileImage" className="fileUpload" name="fileUpload" onChange={ e => uploadAvatar( e )  } />
                                    <div className="kipperBox_content-upload" >
                                        <label htmlFor="ProfileImage" className="pointer">
                                            <Grid container direction="column" justify="center" alignItems="center">
                                                {
                                                    docProfile ? 
                                                        <img alt="" className="previw_img rounded fit" id={'AvatarIMG'} />
                                                    :
                                                        <img src={file} alt="" className="profileImg rounded fit" />
                                                }
                                                <span>
                                                   <small>Elegir una foto de perfil</small> 
                                                </span>
                                                { !!errorMsg.avatar && <span style={{color: 'red'}}><small>{ errorMsg.avatar }</small></span> }
                                            </Grid>
                                        </label>
                                    </div>
                                </Grid>
                            </Box>
                            <Box pt={3}>
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Nombre <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="nombre"
                                                inputProps={{ maxLength: 30 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Apellido <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="apellido"
                                                inputProps={{ maxLength: 30 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Correo electronico <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="email"
                                                inputProps={{ maxLength: 130 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Dirección <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="direccion"
                                                inputProps={{ maxLength: 130 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Código Postal <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="codigopostal"
                                                inputProps={{ maxLength: 5 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}> 
                                            <InputLabel shrink>
                                                Ciudad <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <TextField 
                                                name="ciudad"
                                                inputProps={{ maxLength: 30 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Sexo <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select name="sexo">
                                                <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Masculino">Masculino</MenuItem>
                                                <MenuItem value="Femenino">Femenino</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Nivel <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="level"
                                                onBlur={ e => reset()}
                                            >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Kinder">Kinder</MenuItem>
                                                <MenuItem value="Primaria">Primaria</MenuItem>
                                                <MenuItem value="Secundaria">Secundaria</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Turno <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="turn"
                                                onBlur={e => fillGrados()}
                                            >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Matutino">Matutino</MenuItem>
                                                <MenuItem value="Vespertino">Vespertino</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink>
                                                Grado <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="grade"
                                                onBlur={e => fillGrupos()}
                                            >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                {
                                                    grados ?
                                                        grados.map( item => 
                                                            <MenuItem value={ item[1] } key={ item[1] }>{ item[1] }</MenuItem>
                                                        )
                                                    :<MenuItem disabled >No hay grados registrados en este turno</MenuItem>

                                                }
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2}>
                                            <InputLabel shrink >
                                                Grupo <b className="asterisk-w">*</b>
                                            </InputLabel>
                                            <Select
                                                name="group"
                                                onBlur={e => selectGroup()}
                                            >
                                                <MenuItem disabled value=""><em>Seleccionar</em></MenuItem>
                                                {
                                                    grupos ? 
                                                        grupos.map( item => 
                                                            <MenuItem value={ item } key={ item }>{  item }</MenuItem>
                                                        )
                                                    :<MenuItem disabled >No hay grupos registrados en este grado</MenuItem>
                                                }
                                            </Select>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Box p={2}> 
                                        <InputLabel shrink >
                                            Observaciones <b className="asterisk-w">*</b>
                                        </InputLabel>               
                                            <TextField  
                                                placeholder="Observaciones del alumno" 
                                                className="text_area-style" 
                                                name="observaciones"
                                            />
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Box p={2}>
                                            <Typography variant="h5" gutterBottom>
                                                Documentación (opcional)
                                            </Typography>
                                        </Box>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docIdentificacion" className="fileUpload" name="fileUpload" onChange={ e => uploadDocId( e ) } />
                                                    <label for="docIdentificacion" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img className="imW" src={ upload } alt=""/>
                                                            <Typography className="kipper_txt-color txtHw">Identificación</Typography>
                                                            <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                            
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'idIMG'} />
                                                    {
                                                        (!loading.ofi)?
                                                            <Typography className="" variant="" display="block" gutterBottom>
                                                                <small>{docIdName} </small>
                                                                {
                                                                    docId ? <a href={ `${docId}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                                }
                                                            </Typography>
                                                        :  <CircularProgress />
                                                    }
                                                        
                                                    { !!errorMsg.id && <span style={{color: 'red'}}><small>{ errorMsg.id }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocComprobante( e ) }  />
                                                    <label for="docComprobante" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img className="imW" src={ upload } alt=""/>
                                                            <Typography className="kipper_txt-color txtHw">Comprobante de domicilio</Typography>
                                                            <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'comprobanteIMG'} />
                                                    {
                                                        (!loading.dom)?
                                                            <Typography className="" variant="" display="block" gutterBottom>
                                                                <small>{docProofAddresName}</small>
                                                                {
                                                                    docProofAddres ? <a href={ `${docProofAddres}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                                }
                                                            </Typography>
                                                        :<CircularProgress />
                                                    }
                                                    
                                                    { !!errorMsg.comprobante && <span style={{color: 'red'}}><small>{ errorMsg.comprobante }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box p={2}>
                                                    <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                    <label for="docOpcional" className="kipper_txt-color pointer">
                                                        <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                            <img className="imW" src={ upload } alt=""/>
                                                            <Typography className="kipper_txt-color txtHw">Documento opcional</Typography>
                                                            <Typography className="kipper_txt-color txtHw">Selecciona archivo</Typography>
                                                        </Grid>
                                                    </label>
                                                    <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                                    {
                                                        (!loading.opc)?
                                                            <Typography className="" variant="" display="block" gutterBottom>
                                                                <small>{docOpcionalName}</small>
                                                                {
                                                                    docOpcional ? <a href={ `${docOpcional}` } target="_blank"> <VisibilityIcon className="kipper_txt-grey" />  </a>: ""
                                                                }
                                                            </Typography>
                                                        :<CircularProgress />
                                                    }
                                                    { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box px={2} pb={3}>
                                                    <Typography className="" variant="" display="block" gutterBottom>
                                                        <b>*Los documentos deben ser de tipo JPG, JPEG, PNG y PDF.</b> <br/>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            
            </Form>
        </Formik>
    )
}

export default AgregarAlumno