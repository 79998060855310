import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
//Component
import PageHeader from './../../Components/Global/PageHeader';
import ModalShow from './../../Components/Global/Modal';
//img
import portada from './../../Assets/Img/General/portada_add-yellow.png';
import upload from './../../Assets/Img/General/Icon_upload.png';
//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
// import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
//firebase
import * as firebase from 'firebase';
import { checkFilepdf } from '../../Components/functions/helpapers';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';
import { FormHelperText } from '@material-ui/core';

//Validators
import { Form, Formik } from 'formik';
import * as yup from 'yup';
//
import TextField from '../../Constants/textfield';


function MateriasDetalles ({ }) {

    const history = useHistory();
    const match = useRouteMatch('/escuela/materias/informacion/:id/:nivel/:type');
    const MateriaID =  match.params.id;
    const level = match.params.nivel;
    const type = match.params.type;
    const { currentUser }           = useContext(AuthContext);


    const { info } = useContext(AuthContext);
    const [infoMateria, setinfoMateria] = useState([]);
    const [title, setTitle] = useState('');
    const [grade, setgrade] = useState('');
    const [about, setAbout] = useState('');
    const [nivel,setNivel] = useState(level);
    const [docOpcional, setDocOpcional] = useState('');
    const [ChangeOpcional, setChangeOpcional] = useState(false);
    const [gradeAnterior,setGradeAnterior] = useState('');

    const [aboutError, setAboutError] = useState(false);
    const [aboutErrorText, setAboutErrorText] = useState('');

    const [gradeError,setGradeError] = useState(false);
    const [gradeErrorText,setGradeErrorText] = useState('');

    const [levelError,setLevelError] = useState(false);
    const [levelErrorText,setLevelErrorText] = useState('');

    const [errorMsg, seterrorMsg] = useState({
        avatar: false,
        id: false,
        comprobante: false,
        doc: false
    });
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    };
        
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect(() => {
        let db = firebase.database();
        let childRef = db.ref(`school_subjects/${MateriaID}`);
        childRef.once('value').then(function(snapshot) {
            setinfoMateria  (snapshot.val());
            setTitle        (snapshot.val().title);
            setgrade        (snapshot.val().grade);
            setGradeAnterior (snapshot.val().grade);
            setAbout        (snapshot.val().details);
            setDocOpcional  (snapshot.val().documents?.temary);
        })
    }, []);



    const handleForm = (newValues) =>{
        // e.preventDefault();
        const opcionalIMG = ChangeOpcional ? document.getElementById('opcionalIMG').src : docOpcional;

        let db = firebase.database();
        let Usersref = db.ref(`school_subjects/${MateriaID}`);
        Usersref.update({
            title: newValues.title ?newValues.title :title,
            details: newValues.description ?newValues.description :about,
            grade: grade,
            level: nivel.toLowerCase(),
            documents:{
                temary: opcionalIMG
            }
        }, function(error) {
            if (error) {
              // The write failed...
                setModalmsg('Inténtalo de nuevo en unos minutos')
                setModalLink('/')
            } 
            else {
              // Data saved successfully!
                setModalmsg('Materia actualizada');
                if(type == 1)
                    setModalLink(`/grupos`);
                else
                    setModalLink(`/escuela/materias/${gradeAnterior}/${level}`);
                ModalOpen();
            }
        });
    }

    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        let img = e.target.files[0];
        const fileIsValid = await checkFilepdf( img );
        if ( fileIsValid ) {
            try {
                setChangeOpcional( true )
                // setDocOpcional( img );
                seterrorMsg({...errorMsg, doc: false });
                var storage = firebase.storage().ref();
                var upload = storage.child(`school_subjects/documents/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("opcionalIMG")
                        profileImg.src = url;
                        setDocOpcional(profileImg.src)
                    })
                })
            } catch (error) {
                console.log( error );
            }
        } else {
            seterrorMsg({...errorMsg, doc: 'Formatos válidos: png, jpg, jpeg o pdf' });
        }
    }

        
    const validateForm = yup.object({
        title: yup
            .string('Ingresa un nombre')
            .matches(/^.{2,50}$/, "El titulo debe contener entre 2 a 50 caracteres" ),
            description: yup
            .string('Ingrese un correo electrónico')
            .matches(/^.{2,200}$/, "La descripción debe contener entre 2 a 200 caracteres" ),
    });

    const initialFormState ={
        title: '',
        description: '',
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={validateForm}
            onSubmit={newValues =>{
                handleForm(newValues)
            }}
            >
                <Form>                
                    <Grid container>
                    {/* <NavEscuelaPhone /> */}
                    <Grid item xs={12} sm={12} >
                        <Box pb={3}>
                            <PageHeader
                                title="Detalles de la materia"
                                text="Agrega o modifica la información de la asignatura"
                                btn_back="/escuela/lista/materias"
                                btn_action={true}
                                btn_action_text="Guardar"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="Form_add-content">
                        <Box boxShadow={3} borderRadius={16} className="kipperBox_content box_w">
                            <Box >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Box>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid id="kiper_materiasDetail_gridTitulo" xs={12} sm={12} md={4} lg={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="turno">
                                                Título 
                                            </InputLabel>
                                            <TextField 
                                                name="title"
                                                // inputProps={{ minLength: 5, maxLength: 20 }}
                                                // helperText={titleErrorText}
                                                placeholder={title} 
                                                size="small" 
                                                fullWidth 
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="materia">
                                                Nivel educativo 
                                            </InputLabel>
                                            <Select error={levelError} labelId="materia" value={nivel} onChange={e => setNivel(e.target.value)} displayEmpty fullWidth required  >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Kinder">Kinder</MenuItem>
                                                <MenuItem value="Primaria">Primaria</MenuItem>
                                                <MenuItem value="Secundaria">Secundaria</MenuItem>
                                            </Select>
                                            <FormHelperText error={levelError}>{levelErrorText}</FormHelperText>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box p={2}>
                                            <InputLabel shrink id="turno">
                                                Materia para: 
                                            </InputLabel>
                                            <Select error={gradeError} labelId="sexo" value={grade} onChange={e => setgrade(e.target.value)} displayEmpty className="" fullWidth required  >
                                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                                <MenuItem value="Primero">Primero</MenuItem>
                                                <MenuItem value="Segundo">Segundo</MenuItem>
                                                <MenuItem value="Tercero">Tercero</MenuItem>
                                                <MenuItem value="Cuarto">Cuarto</MenuItem>
                                                <MenuItem value="Quinto">Quinto</MenuItem>
                                                <MenuItem value="Sexto">Sexto</MenuItem>
                                            </Select>
                                            <FormHelperText error={gradeError}>{gradeErrorText}</FormHelperText>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6" className="kipper_txt-grey">
                                                Acerca de la materia
                                            </Typography>
                                        </Box>
                                        <Box p={2} >
                                            <TextField 
                                                className="text_area-style"
                                                placeholder={about} 
                                                name="description"
                                                // style={{ width: '100%', border: '1px solid grey', padding: '10px' }} 
                                                aria-label="minimum height" 
                                                rows={4} 
                                                multiline
                                                variant="outlined"
                                            />
                                            <FormHelperText error={aboutError}>{aboutErrorText}</FormHelperText>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box px={2}>
                                            <Typography variant="h6" className="kipper_txt-grey">
                                                Temario
                                            </Typography>
                                            <Box py={2}>
                                            <img src="" alt="" className="previw_img d-none" id={'opcionalIMG'} />
                                            {
                                                docOpcional ? 
                                                    <Box display="flex" pb={1}>
                                                        <a href={ docOpcional } target="_blank" className="kipper_txt-grey">
                                                        Temario { docOpcional.name }
                                                        </a>
                                                        <Box ml={1}>
                                                            <a href={docOpcional} target="_blank">
                                                                <VisibilityIcon className="kipper_txt-grey" />
                                                            </a>
                                                        </Box>
                                                        <Box ml={1}>
                                                            <input type="file" id="docComprobante" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                            <label for="docComprobante" className="kipper_txt-grey pointer">                                                            
                                                                <CreateIcon className="kipper_txt-grey" />
                                                            </label>
                                                        </Box>
                                                    </Box>
                                                :
                                                    <>
                                                        <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={e => uploadDocOpcional( e ) } />
                                                        <label htmlFor="uploadFile" className="kipper_txt-color pointer">
                                                            <Grid container className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                <img src={upload} alt="" />
                                                                <p className="kipper_txt-color">Selecciona archivo</p>
                                                            </Grid>
                                                        </label>
                                                        <Typography className="" variant="body1" display="block" gutterBottom>
                                                            <b>*Los documentos deben ser de tipo JPG, JPEG, PNG o PDF.</b>
                                                        </Typography>
                                                    </>
                                            }
                                            { !!errorMsg.doc && <span style={{color: 'red'}}><small>{ errorMsg.doc }</small></span> }
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
            </Formik>
        </>
    )

}

export default MateriasDetalles