import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Component
import PageHeader from '../../Components/Global/PageHeader'
import ModalShow from './../../Components/Global/Modal';

// Img
import { AuthContext } from '../../Components/functions/Auth';
import upload from './../../Assets/Img/General/Icon_upload.png';
// Material UI
import { Box, Button, Grid, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Modal from '@material-ui/core/Modal';
// Firebase
import * as firebase from 'firebase';
import { Alert } from '@material-ui/lab';
import Homework from '../Maestros/HomeWorks';

import { checkFilepdf } from '../../Components/functions/helpapers';


const HomeworkDetailsChild = () => {

    const history   = useHistory();
    const match     = useRouteMatch('/alumno/tarea/detalles/:materiaID/:tareaID');
    const MateriaID = match.params.materiaID;
    const TareaID   = match.params.tareaID;
    const { info, currentUser, infoSchool } = useContext(AuthContext);

    const [refresh, setRefresh]             = useState(1)
    const [HomeworkDoc, setHomeworkDoc]     = useState('')
    const [HomeworkName, setHomeworkName]   = useState('')
    const [groupInfo, setGroupInfo]     = useState('')
    const [HomworkInfo, setHomworkInfo]     = useState('')
    const [status, setStatus]               = useState(1)
    const [loadfile, setLoadfile]           = useState( false )
    const [Error, setError]           = useState( false )
    const [loading, setLoading]       = useState( true )
    const [docTypeError, setDocTypeError ] = useState(false)
    // modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");
    const today = moment()

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        history.goBack()
        setModalStaus(false);
    };    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    useEffect(() => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}`);
        groupRef.once('value').then(  (snapshot) => {
            let itemInfo = snapshot.val()
            setGroupInfo( itemInfo )
        })
    }, [infoSchool, info]);

    useEffect(() => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${MateriaID}/homework/${TareaID}`);
        groupRef.once('value').then(  (snapshot) => {
            let itemInfo = snapshot.val()
            setHomworkInfo( itemInfo )
        })
    }, [refresh, infoSchool, info]);

    useEffect(() => {
        let db = firebase.database();
        let groupRef = db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${MateriaID}/homework/${TareaID}/deliveries`);
        groupRef.once('value').then(  (snapshot) => {
            let itemInfo = snapshot.val()
            if ( snapshot.val() ) {
                snapshot.forEach( item => { 

                    if ( item.key === currentUser ) {
                        setHomeworkDoc( item.val().document !== '' ? item.val().document : false )
                        setHomeworkName( item.val().document_name )
                        setStatus( item.val().status === '' ? 1 : item.val().status )
                    }
                    else{
                        setStatus( 0 )
                    }
                })
            }
            else {
                setStatus( 0 )
            }
            setHomworkInfo( itemInfo )
        })
    }, [refresh, infoSchool, info]);

    const handleStatus = (status) => {
        setStatus(status)
        setLoadfile(true)
    }
    
    const uploadDocOpcional = async ( e ) =>{
        e.preventDefault();
        setHomeworkDoc( e.target.files[0] );
        setHomeworkName(e.target.files[0].name)
        var img = e.target.files[0]
        const fileIsValid = await checkFilepdf( e.target.files[0]  );
        if(fileIsValid){
            try {
                setLoading(false)
                var storage = firebase.storage().ref();
                var upload = storage.child(`childs/documents/homework/Img${Date.now()}`);
                upload.put( img ).then( snapshot => {
                    upload.getDownloadURL().then(url=>{
                        const profileImg =  document.getElementById("HomeworkDOC")
                        profileImg.src = url;
                        setLoadfile( true )
                        setHomeworkDoc(url)
                        if ( status === 0 ) {
                            setStatus( 1 )
                        }
                        setLoading(true)
                    })
                })
            } catch (error) {
                setLoading(true)
                console.log( error );
            }
        }
        else
        {
            setDocTypeError(true)
        }
    }

    const handleSubmit = ( e ) => {
        /*
        0 = No Entregado
        1 = Entregado
        2 = Entrega editada 
        3 = Entrega anulada
        */
        e.preventDefault();
        let db = firebase.database();
        let Usersref = db.ref(`schools/${infoSchool}/groups/${info.group}/school_subjects/${MateriaID}/homework/${TareaID}/deliveries/${info.id}`);
        let LocalHomework;
        let LocalHomeworkName;
        switch ( status ) {
            case 1:
                LocalHomework =  HomeworkDoc
                LocalHomeworkName = HomeworkName
                break;
            case 2:
                LocalHomework = document.getElementById('HomeworkDOC').src;
                LocalHomeworkName = HomeworkDoc.name;
                break;
            case 3:
                LocalHomework = HomeworkDoc              
                LocalHomeworkName = HomeworkName
                break;
            case 4:
                LocalHomework =  HomeworkDoc;             
                LocalHomeworkName = HomeworkName
                break;
            default:
                setStatus('')
                break;
        }
        if ( status !== 0) {
            Usersref.update({
                document: LocalHomework,
                document_name: LocalHomeworkName,
                time: moment().format('MMMM Do YYYY, h:mm:ss a'),
                status: status
            }, 
            (error) => {
                if (error) {
                    // The write failed...
                    setModalmsg('Inténtalo de nuevo en unos minutos')
                    setModalLink('/')
                } 
                else {
                    // Data saved successfully!
                    setRefresh( refresh + 1 )
                    setModalmsg('Información guardada');
                    ModalOpen();
                    createNotif()
                }
            })
        }
        else{
        }
    }

    const createNotif = () => {
        let db = firebase.database();
        let teacherRef = db.ref(`notifications/`);
        const { teacher_key } = groupInfo.teacher
        let userList = []
        userList.push({ key: teacher_key, status: 0 }) 
        teacherRef.push({
            school_key: info.school,
            title: 'Tarea entregada', 
            body: `${info.name} ${info.last_name} entrego actividad ${HomworkInfo?.title}`,
            date: new Date().getDate(),
            time: new Date().getTime(),
            type: 'teacher',
            userList
        }, (error) => {
            if (error) { console.log(error) } 
        });
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={ handleSubmit } >
                <Grid container >
                    <Grid item xs={12} >
                        <Box pb={3} >
                            <div className="pageHeader_notShowUser_container">
                                <div className="pageHeader_notShowUser_textContainer">
                                    <Box ml={1}>
                                        <Typography variant="h4" className="schoolInfo_title margin_button"> Detalles de la tarea </Typography>
                                    </Box>
                                    <Box ml={1} mt={1}>
                                        <Typography className=""> Agrega documentos y entrega la tarea correspondiente </Typography>
                                    </Box>
                                </div>

                                <div className="pageHeader_notShowUser_buttonsContainer">
                                    <div className="pageHeader_notShowUser_buttons">
                                            {/* <Link to={`/alumno/materia/details/${MateriaID}`}> */}
                                            <Button onClick={() => history.goBack()} variant="outlined">Atras</Button>
                                            {/* </Link> */}
                                            {
                                                loadfile 
                                                ?   <Button variant="contained"  id="pageHeader_buttonBlueKiper" type="submit" disableElevation>
                                                        Guardar 
                                                    </Button> 
                                                :   <Button variant="contained"  id="pageHeader_buttonBlueKiper" type="button" disabled disableElevation>
                                                        Guardar
                                                    </Button> 
                                            }

                                    </div>
                                </div>
                            </div>    
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} >
                        <Box my={3} boxShadow={3} borderRadius={10} className="bg-white text-content box_w" >
                            <Grid container >
                                <Grid item xs={12} >
                                    <Box px={5} py={3} >
                                        <Grid container >
                                            <Grid item xs={12} className="" >
                                                <Box display="flex" >
                                                    <Typography variant="h4" >
                                                        { HomworkInfo?.title } 
                                                    </Typography>
                                                    <Box className="center_center" ml={1} >
                                                        {
                                                            ( status === 1 || status === 2 || status === 4 )
                                                            ? <span className="assistance-true"> Entregado </span>
                                                            : <span className="homework-pending"> Pendiente </span>
                                                        }
                                                    </Box>
                                                </Box>
                                                <Typography variant="overline" >
                                                    <WatchLaterIcon fontSize="small"/> &nbsp;
                                                    { HomworkInfo?.fulltime } { HomworkInfo?.time } &nbsp;
                                                    { moment(HomworkInfo?.fulltime, "YYYYMMDD").fromNow() }
                                                </Typography>
                                                <Box mt={2} >
                                                    <Typography variant="body1" >
                                                        { HomworkInfo?.description }
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {
                                                Error &&
                                                    <Grid item xs={12} className="" >
                                                        <Alert severity="error">Debes subir alguna evidencia para poder entregar la tarea.</Alert>
                                                    </Grid>
                                            }
                                            {
                                                HomworkInfo?.document &&
                                                    <Grid item xs={12} className="" >
                                                        <Box className="documents-download" textAlign="center" my={2} py={1} >
                                                            <a href={ HomworkInfo.document } download={ HomworkInfo.document } sim target="_blank" rel="noopener noreferrer"> Descargar material </a>
                                                        </Box>
                                                    </Grid>
                                            }
                                            <Grid item xs={12} className="" >
                                                <Box pb={2} >
                                                    <Typography variant="subtitle1" >
                                                        LINKS DE UTILIDAD 
                                                    </Typography>
                                                    <a href={ HomworkInfo?.link } target="_blank" rel="noopener noreferrer"> { HomworkInfo?.link } </a>
                                                </Box>
                                                <hr/>
                                            </Grid>
                                            <Grid item xs={12} md={12} sm={12} className="" >
                                                <Box mt={1} >
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="h5" >
                                                                Entregables
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="" >
                                                            {/*
                                                                0 = No Entregado
                                                                1 = Entregado
                                                                2 = Entrega editada 
                                                                3 = Entrega anulada
                                                                4 = Entrega editada y anulada 
                                                             */}
                                                            {
                                                                ( today.isBefore(`${HomworkInfo?.fulltime} ${HomworkInfo?.time}`) && (status === 1 || status === 2 || status === 4) ) && 
                                                                <Box textAlign="right" > 
                                                                    <span
                                                                        onClick={ () => handleStatus( 3 ) }
                                                                        className="assistance-false pointer" 
                                                                    > 
                                                                        Anular entrega 
                                                                    </span> 
                                                                </Box> 
                                                            }
                                                            {
                                                                ( status === 3 )&& 
                                                                <Box textAlign="right" > 
                                                                    <span
                                                                        onClick={  () => handleStatus( 4 ) }
                                                                        className="assistance-true pointer" 
                                                                    > 
                                                                        Habilitar entrega 
                                                                    </span> 
                                                                </Box> 
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        ( status === 0 ) &&
                                                        <>
                                                        {
                                                            loading ?

                                                                <a href={ HomeworkDoc } target="_blank" rel="noopener noreferrer"> 
                                                                    <Typography  className="txtDescTitle fonsty"> { HomeworkName } </Typography>
                                                                </a>
                                                            :
                                                                <CircularProgress />
                                                        }
                                                        </>

                                                    }
                                                    {
                                                        ( status === 1 || status === 2 ) && 
                                                        <>
                                                            {
                                                                loading ?

                                                                    <a href={ HomeworkDoc } target="_blank" rel="noopener noreferrer"> 
                                                                        <Typography  className="txtDescTitle fonsty"> { HomeworkName } </Typography>
                                                                    </a>
                                                                :
                                                                    <CircularProgress />
                                                            }
                                                    {docTypeError &&
                                                            <Grid item xs={12} className="" >
                                                                <Alert severity="error">Formatos válidos: png, jpg, jpeg y PDF</Alert>
                                                            </Grid>
                                                    }
                                                            <Typography variant="body1" > Editar entregable </Typography>
                                                            <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                            <label for="docOpcional" className="kipper_txt-color pointer">
                                                                <Grid xs={12} md={12} sm={12} className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                    <img className="imW" p={3} src={ upload } alt=""/>
                                                                    <Typography className="kipper_txt-color txtHw txtDescTitle">Subir archivo</Typography>
                                                                </Grid>
                                                            </label>
                                                        </>
                                                    }
                                                    {
                                                        ( status === 3 || status === 4 ) &&
                                                        <>
                                                            {
                                                                loading ?

                                                                <a href={ HomeworkDoc } target="_blank" rel="noopener noreferrer"> 
                                                                    <Typography  className="txtDescTitle fonsty"> { HomeworkName } </Typography>
                                                                </a>
                                                                :
                                                                <CircularProgress />
                                                            }
                                                            <Typography variant="body1" > Editar entregable </Typography>
                                                            <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                            <label for="docOpcional" className="kipper_txt-color pointer">
                                                                <Grid xs={12} md={12} sm={12} className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                    <img className="imW" p={3} src={ upload } alt=""/>
                                                                    <Typography className="kipper_txt-color txtHw txtDescTitle">Subir archivo</Typography>
                                                                </Grid>
                                                            </label>
                                                        </>
                                                    }
                                                    {
                                                        ( status < 1) &&
                                                    <>
                                                        <input type="file" id="docOpcional" className="fileUpload" name="fileUpload" onChange={ e => uploadDocOpcional( e ) }  />
                                                        <label for="docOpcional" className="kipper_txt-color pointer">
                                                            <Grid xs={12} md={12} sm={12} className="kipper_upload-file" direction="column" justify="center" alignItems="center" >
                                                                
                                                                <img className="imW" src={ upload } alt=""/>
                                                                <Typography className="kipper_txt-color txtHw txtDescTitle">Subir archivo</Typography>
                                                            </Grid>
                                                        </label>
                                                    </>
                                                    }
                                                    <img src="" alt="" className="previw_img d-none" id={'HomeworkDOC'} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default HomeworkDetailsChild
