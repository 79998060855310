import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import style from '../../Assets/Styles/chat.module.css'

import * as Firebase from 'firebase'
const Fire = Firebase.database().ref()
const CHATS_REF = Firebase.database().ref().child('/chats/')


export const getTutor = async(id, setTutor) => {
    Fire.child(`/tutors/${id}`).once('value', snap => {
        let tutor = snap.val()
        // console.log('tutor info ',tutor);
        setTutor(tutor)
    })
}

export const startChat = async ( user = null, setIdChat, idTutor, schoolid ) => {
    // console.log('tutor start ', user);
    if(user){
        console.log('user ', user, 'idTutor ', idTutor, 'schooldid ', schoolid);
        let struct = {
            "status"    : "request",
            "tutor"     : idTutor,
            "teacher"   :  {
                    "avatar"    : user.avatar || "https://i.picsum.photos/id/125/200/200.jpg?hmac=hAcxXCRvPrmKoCW1b3Ju59FSZTMFWwHAPU5nmm84n6A" ,
                    "isOnline"  : false,
                    "isTyping"  : false,
                    "name"      : `${user.name}`,
                    "email"     : `${user.contact.email}`
                }
            
        }
        const listener =  CHATS_REF.orderByChild("tutor").equalTo(idTutor).limitToFirst(1)

        const CHATS_REF2 = Firebase.database().ref().orderByChild(`/chats/tutors/${schoolid}`).equalTo(idTutor).limitToFirst(1)

        listener.once('value', async (snap) => {
            if(!snap.val()){
                // console.log('listener ', listener);
                let chatRef = await CHATS_REF.push(struct)
                await chatRef.update(struct)
                setIdChat(chatRef.key)
                // console.log('refchat ', chatRef.key);
            }else{
                
                const chat =  listener.once('value', snap => {
                    let keys = Object.keys(snap.val())
                    console.log(keys);
                    setIdChat(keys[0])
                    // console.log('key 0 ', keys[0]);
                })
            }
        })
        
    }
}

export const sendMessage = async(message, user, idChat, info ) => {
    // console.log(message, '////', user, '/////', idChat);
    let body = {
        _id   : genSign(),
        date  : new Date().toDateString(),
        message: message,
        user : {
            _id    : info ? info.coderef :  user.id,
            avatar : user.avatar,
            name   : `${ user.name } ` 
        }
    }
    let push_ = await CHATS_REF.child(`/${idChat}/messages/`).push(body)
    // let push_ = await CHATS_REF.child(`/${user.school}/${idChat}/messages/`).push(body)
    console.log('push ', push_);
    // const send = await push_.ref('/messages/').push(body)
    return true
}


export const fetchMessages = (idChat, setMessages, idTutor) => {
    // console.log('cbody in ', idChat, idTutor);
    if(idChat){
       CHATS_REF.child(`/${idChat}/`).on('value', snap => {
        if(snap.val()){
            let data = snap.val()
            setMessages(data.messages)
            // console.log('mssss ', data.messages);
        }else{
            console.log('snap empty ', snap.val());
        }
    })
    }
}

export const Message = ( {uid, msg} ) => {
    let user_id = msg[1].user
    // console.log('Message ', 'uid ', uid, 'msg ', msg);
    if(user_id._id === uid){
        return (
            <Grid container className= {` ${style.msg_content}` } style={{float:'right'}}  spacing={ 2 }>
                <Grid item xs={ 10 } className={ `${style.msg_body} ${style.blue}` }>
                    <Box className={ style.msg_text }>
                        { msg[1].message }
                    </Box>
                </Grid>
                <Grid item xs={ 2 } className={ style.avatar_content }>
                    <img src={ msg[1].user.avatar }  className={ style.avatar_chat }/>
                </Grid>
                
            </Grid>
        )
    }else{
        return(
            <Grid container className= {` ${style.msg_content}` } spacing={ 2 }>
                <Grid item xs={ 2 } className={ style.avatar_content }>
                    <img src={ msg[1].user.avatar } className={ style.avatar_chat } />
                </Grid>
                <Grid item xs={ 10 } className={ `${style.msg_body} ${ style.lowblue }` }>
                    <Box className={ style.msg_text.dark }>
                        { msg[1].message }
                    </Box>
                </Grid>
                
            </Grid>
        )
    }
}


export const getMyInfo = async( currentUser, setMyInfo, setIdChat, id ) => {
    // console.log('info__', currentUser);
    if(currentUser){
        const PRO_REF =  Firebase.database().ref().child(`/teacher/${currentUser}/`)
        PRO_REF.once('value', async(snap) => {
            let rt = await snap.val()
            setMyInfo(snap.val())
            startChat( snap.val(),  setIdChat, id, snap.val().school)
            // console.log('snap ', snap.val());
        })
    }
}


export const genSign = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/* Tutor Chat Funcions add */

export const getMyInfoTutor = async( currentUser, setMyInfo ) => {
    // console.log('info__', currentUser);
    if(currentUser){
        const TUT_REF = Firebase.database().ref().child(`/tutors/${currentUser}`)
        // const listener = TUT_REF.orderByChild('id').equalTo(currentUser).limitToFirst(1)
        TUT_REF.once('value', snap => {
            // console.log(snap.val());
            // console.log('MyinfoTutor ', snap.val());
            if(snap.val()){
                setMyInfo(snap.val())
            }
        })
    }
}

export const getTeacher = ( id, setTeacher, setIdChat ) => {
    const TREF = Firebase.database().ref().child('/chats').orderByChild("tutor").limitToFirst(1)
    // const toREF = TREF.orderByChild('tutor').equalTo(id).limitToFirst(1)
    TREF.once('value', snap => {
        console.log('snap get teacher', snap.val());
        if(snap.val()){
            let chat_ = snap.val()
            Object.entries(chat_).map(chat => {
                console.log('chat ', chat);
                setTeacher(chat[1].teacher)
                console.log('teacher ', chat[1].teacher);
                setIdChat(chat[0])
            })
            
        }
    })
}