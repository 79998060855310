import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { useHistory, useRouteMatch } from 'react-router-dom';
import ModalShow from '../../Components/Global/Modal'
//Material UI
import { Grid,Box, Typography, InputLabel, Select, MenuItem, Modal } from '@material-ui/core';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import EvaluacionesPromedio from '../../Components/Global/Maestros/EvaluacionesPromedio';
//Firebase
import * as firebase from 'firebase';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

const Evaluaciones = () =>{
    const db = firebase.database();
    const {infoSchool} = useContext(AuthContext);
    const match = useRouteMatch('/teacher/evaluaciones/details/:group/:subject');
    const groupID = match.params.group;
    const subjectID = match.params.subject;
    //hooks
    const [arrayinfo, setarrayinfo] = useState(null)
    const [parciales, setParciales] = useState(null)
    const [refresh, setRefresh]     = useState(0)
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);

    useEffect(() => {
        if(infoSchool)
        {
            if(arrayinfo === null)
            {
                firstquery();
            }
            getSubjects()
        }
    }, [ refresh || infoSchool ])

    const ModalOpen = (valor) =>{
        if(valor!=0)
            setModalStaus(true);
    } 
    const ModalClose = () => {
        setModalStaus(false)
    };

    function firstquery(){
        if(infoSchool){
            let ref = db.ref('schools/'+infoSchool?.school+'/groups/'+groupID+'/school_subjects/'+subjectID+'/evaluations/');
            ref.once('value').then(function(snapshot) {
                if(snapshot.val()){
                    setarrayinfo( Object.entries(snapshot.val()));
                }else{
                    try{
                        doevaluations()
                    }finally{
                        firstquery();
                    }
                }
            });
        }
    }   

    function doevaluations(){
        if(infoSchool)
        {
            let ref3 = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/student')
            ref3.once('value').then(function(students){
                if( !isNaN(students.val()) && students.val() != null ){

                    var studentsarray = Object.entries(students.val())
                    studentsarray.forEach(element => {
                        let ref4 = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/school_subjects/'+subjectID+'/evaluations/'+element[0])
                        ref4.update({
                            P1: 0,
                            P2: 0,
                            P3: 0,
                            P4: 0
                        })
                        
                    });
                }    
            })
        }
    }

    async function getSubjects (){
        let refSubjects = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/school_subjects/'+subjectID)
        const data = await refSubjects.once('value')
        setParciales( data.val().partials )
    }
    
    const handlePartials = ( e, status ) => {
        e.preventDefault();
        
        if ( status === true && parciales > 0 ){
            let refPartials = db.ref('schools/'+infoSchool.school+'/groups/'+groupID+'/school_subjects/'+subjectID)
            refPartials.update({ partials: parciales })
            setRefresh( refresh + 1 )
            ModalClose()
        }
        else {
            ModalClose()
        }
     }

    return(
        <Grid container className="boxReports_width" id="Evaluaciones_parciales_container" >
            {/* <NavTeacherPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title='¿Estás seguro de cambiar el numero de parciales?'
                        confirmOperation={ true }
                        Operation={ handlePartials }
                    />
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3} mx={3}>
                    <PageHeader 
                        title="Evaluaciones"
                        text="Descarga un reporte con las caracteristicas que deseas ver en particular" 
                        btn_back={`/teacher/evaluaciones/group/${groupID}`}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box justifyContent="center"  boxShadow={3} borderRadius={16} className="evaluations_teacher" id="evaluations_teacher">
                    <Box display="flex" flexDirection="row" alignItems="center" id="evaluations_teacher_box" >
                        <Typography>PARCIALES: </Typography>
                        <select id="evaluations_teacher_select" className="evaluations_num"  value={ parciales } onChange={e => {setParciales( e.target.value ); ModalOpen(e.target.value);}}>
                            <option value="0">Seleccionar</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </Box>
                    { arrayinfo && arrayinfo.map( i => <EvaluacionesPromedio fresh={refresh} infoindex={i[0]} infogen={i[1]} /> ) }
                </Box>
            </Grid>
        </Grid>
    )
}

export default Evaluaciones