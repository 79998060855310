import React,{useState, useEffect, useContext} from 'react';
import {Grid,Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

//Components
import { AuthContext } from "./../../Components/functions/Auth";
import GroupSelect from '../../Components/Teachers/GroupSelect';
import PageHeader from '../../Components/Global/PageHeader'

//FireBase
import * as firebase from 'firebase';
import NavTeacherPhone from './NavTeacherPhone/NavTeacherPhone';

const CitasTutores =()=>{

    const history   = useHistory();

    //Retorna id del usuario que esta usando
    const { currentUser, infoSchool } = useContext(AuthContext);
    const [grouplist, setGroupList] = useState([])


    useEffect( () =>{
        if(infoSchool)
        {
            getGroups();
        }
    }, [infoSchool] );

    async function getGroups()
    {
        var listGroups = [];
        var groups = Object.entries(infoSchool?.groups)
        for(const item of groups) 
        {
            let checking = await checkSubjects(item[0])
            if(checking)
            {
                listGroups.push(item)
            }
        }
        setGroupList(listGroups);
    }

    async function checkSubjects (group) 
    {
        var checking = false;
        let checkthegroup = await firebase.database().ref(`schools/${infoSchool?.school}/groups/${group}/`).once('value');
        let data = checkthegroup.val()
        if(data?.school_subjects)
        {
            let arraysubjects = Object.entries(data?.school_subjects)
            for(const subject of arraysubjects)
            {
                if(subject[1].teacher_key == currentUser)
                {
                    console.log(data, group)
                    checking = true
                }
            }
            if(!checking && data.teacher.teacher_key == currentUser)
            {
                console.log(data, group)
                checking = true
            }
        }
        return checking;
    }

    return(
        <Grid container >
            {/* <NavTeacherPhone /> */}
            <Grid item xs={12} md={12}>
                <Box pb={3} className="PageHeader_toRight" >
                    <PageHeader 
                        title="Grupo para cita"
                        text="Selecciona uno de los grupos donde esta el niño" 
                        btn_back="/"
                    />
                </Box>
                
            </Grid>
            <Grid sm={12} xs={12} md={12} >
                <Box p={3} mt={2} className="kipperBox_content box_w" boxShadow={3}>
                    {
                        grouplist
                        ?grouplist.map(child =>
                            <GroupSelect obj={child} /> 
                        )
                        :null
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

export default CitasTutores;