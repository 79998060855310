import React, { useContext } from 'react';
import { AuthContext } from "./../../Components/functions/Auth";
import { Box } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin  from '@fullcalendar/interaction'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import esLocale from '@fullcalendar/core/locales/es';
import { useHistory } from 'react-router-dom';
// firebases
import * as firebase from 'firebase';


export default function Calendar({type}) {
    
    const history = useHistory();
    const { events } = useContext(AuthContext);
    
    const Dateclick = (arg) =>{
        console.log(arg)
        history.push(`/events/details/${arg.event.id}`);
    } 
    
    return (
        events && ( 
            <Box mt={3} mb={3} className="calendar__general">
                <Box  mb={1} className="calendar__acotaciones">
                    <FiberManualRecordIcon className="color-blue" />
                    Comunicados 
                </Box>
                <Box mb={1} className="calendar__acotaciones">
                    <FiberManualRecordIcon className="color-green" />
                    Eventos 
                </Box>
                {
                    (type=='teacher'||type=='tutor')&&(
                        <Box mb={1} className="calendar__acotaciones">
                            <FiberManualRecordIcon className="color-orange" />
                            Citas 
                        </Box>  
                    )
                }

                <Box id="calendar" >
                    <FullCalendar
                        id='calendar'
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        height={"auto"}
                        displayEventTime={true}
                        dayMaxEvents={2}
                        eventClick={Dateclick}
                        locales= {esLocale}
                        locale ={'es'} 
                        titleFormat = {{ year: 'numeric', month: 'long' }}
                    />
                </Box>
            </Box>
        )
    )
}
