import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//materialui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import { AuthContext } from '../functions/Auth';


const PageHeader = ( { 
    title, 
    text, 
    btn_back, 
    btn_action, 
    btn_action_to, 
    btn_action_to_text, 
    btn_action_text, 
    btn_to, 
    btn_to_text, 
    btn_select_submit, 
    onSelect, 
    id_selected, 
    showUser, 
    userImg
} ) => {

    const history   = useHistory();

    const { userType }   = useContext(AuthContext);

    const pathname = window.location.pathname;

    const handlePush=()=>{
        const array = pathname.split('/');

        if(userType === 'school' && (array[1] === 'escuela' && array[2] === 'agregar' && array[3] === 'materias' )) history.push('/escuela/lista/materias');
        else history.goBack();  
    }

    if ( showUser ) {
        return(
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Box display="flex">
                    <Box borderRadius="50%">
                        <img src={ userImg } alt="" className="chat_img-radius" width="100px" height="100px"/>
                    </Box>
                    <Box ml={3}>
                        <Typography variant="h4" className="schoolInfo_title capitalize"> { title } </Typography>
                        <Typography id="schoolInfo_comunicados_headerText" className=""> { text } </Typography>
                    </Box>
                </Box>
                <Box display="flex">
                    {/* <Link to={ btn_back ? btn_back : '/home' } > */}
                        <Button onClick={() => history.goBack()} variant="outlined" className="kipper_btn-outline-black">Atras</Button>
                    {/* </Link> */}
                </Box>
            </Grid>
        )
    }
    else{
        return(
            <div className="pageHeader_notShowUser_container">
                <div className="pageHeader_notShowUser_textContainer">
                    <Box ml={1}>
                        <Typography variant="h4" className="schoolInfo_title margin_button"> { title } </Typography>
                    </Box>
                    <Box ml={1} mt={1}>
                        <Typography className=""> { text } </Typography>
                    </Box>
                </div>

                <div className="pageHeader_notShowUser_buttonsContainer">
                    <div className="pageHeader_notShowUser_buttons">
                        {/* <Link to={ btn_back ? btn_back : '/home' }> */}
                            <Button onClick={handlePush} variant="outlined" >Atras</Button>
                        {/* </Link> */}
                        {
                            btn_action_to ?
                            <Link to={ btn_action_to }>
                                <Button  id="pageHeader_buttonBlueKiper" type="submit" >
                                    { btn_action_to_text }
                                </Button>
                            </Link>
                            : null
                        }
                        {
                            btn_action ?
                            <Button   id="pageHeader_buttonBlueKiper" type="submit" >
                                { btn_action_text || 'Actualizar' } 
                            </Button> : null
                        }
                        {
                            btn_to ?
                            <Link to={ btn_to }>
                                <Button  id="pageHeader_buttonBlueKiper" >
                                    { btn_to_text }
                                </Button>
                            </Link> : null
                        }
                        {
                            btn_select_submit ?
                            <Button onClick={ e => onSelect( e, id_selected ) } id="pageHeader_buttonBlueKiper" type="submit"  >
                                Terminar
                            </Button> : null
                        }
                    </div>
                </div>
            
            </div>
        );
    }
}

PageHeader.propTypes = {
    title:  PropTypes.string.isRequired,
    text:   PropTypes.string.isRequired,
    btn_back: PropTypes.string,
    btn_action: PropTypes.bool,
}


export default PageHeader
