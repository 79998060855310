import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import PageHeader from '../../Components/Global/PageHeader'
import ModalShow from './../../Components/Global/Modal'
import moment from 'moment';
// Materia UI
import portada from './../../Assets/Img/General/portada_add-blue.png';
import { Box, FormHelperText, Grid, InputLabel, Modal, TextareaAutosize, TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { Select } from '@material-ui/core'
// firebases
import * as firebase from 'firebase';

const NotificatiosforSchool = () => {

    const history = useHistory();
    const [titulo, setTitulo]   = useState('')
    const [body, setBody]       = useState('')
    const [publico, setPublico] = useState('')

    const [tituloError, setTituloError] = useState(false);
    const [tituloErrorText, setTituloErrorText] = useState('');

    const [descripcionError, setDescripcionError] = useState(false);
    const [descripcionErrorText, setDescripcionErrorText] = useState('');
    //modal
    const [ modalStaus, setModalStaus ] = useState(false);
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };  
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const handleSubmit = ( e ) => {

        console.log('AQUI: ', publico, titulo, body)
        e.preventDefault();

        if (validarDatos()){
            let db = firebase.database();
            let kiperRef = db.ref(`notifications/`);
            kiperRef.push({
                title: titulo,
                body: body,
                publico:publico,
                date: moment().format('LL'),
                time: moment().format('h:mm:ss a'),
                type: 'kiper',
            }, (error) => {
                if (error) { console.log(error) } 
                else{
                    setModalmsg('Notificación creada');
                    setModalLink(`/`);
                    ModalOpen();
                }
            });
            //Vaciar campos del formulario
            setTitulo('');
            setBody('');
        }
    }

    const validarDatos =()=>{
        resetError();
        if(titulo.length != 0 && body.length != 0){//Verificar que no esten vacias
            if( (titulo.length >= 5 && titulo.length <= 40) && (body.length >= 5 && body.length <= 200) ){//Verificar rango de caracteres
                return true;
            }else{
                if(titulo.length < 5 || titulo.length > 40){
                    setTituloError(true);
                    setTituloErrorText('El titulo debe tener entre 5 a 40 caracteres');
                }
                if(body.length < 5 || body.length > 200){
                    setDescripcionError(true);
                    setDescripcionErrorText('La descripción debe tener entre 5 a 200 caracteres');
                }
            }
        }else{
            if(titulo.length == 0){
                setTituloError(true);
                setTituloErrorText('Ingrese el titulo');
            }
            
            if(body.length == 0){
                setDescripcionError(true);
                setDescripcionErrorText('Ingrese la descripcion');
            }
        }
        return false;
    }

    const resetError =()=>{
        setTituloError(false);
        setTituloErrorText('');
        setDescripcionError(false);
        setDescripcionErrorText('');
    }

    return (
        <Grid container >
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    />
                </Grid>
            </Modal>
            <form onSubmit={ handleSubmit } >
                <Grid item xs={12} >
                    <Box pb={3}>
                        <PageHeader 
                            title={`Desplegar notificaciones`}
                            text="Crea notificaciones para los usuarios"
                            btn_back="/"
                            btn_action={true}
                            btn_action_text="Guardar"
                        />
                    </Box>
                </Grid>
                <Grid xs={12} className="Form_add-content kipper_box">
                    <Box boxShadow={3} borderRadius={16} className="kipperBox_content"  id="notif_admin_box"  mb={3} >
                        <Grid container >
                            <Grid item xs={12} >
                                <img src={ portada } alt="" className="kipperBox_portada-img" />
                            </Grid>
                            <Grid item xs={12} >
                                <Box p={3}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Box px={2} pb={2}> 
                                                <TextField
                                                    error={tituloError}
                                                    helperText={tituloErrorText} 
                                                    label="Titulo de notificación"
                                                    type="text" 
                                                    placeholder="Titulo de notificación" 
                                                    fullWidth
                                                    required
                                                    value={ titulo } 
                                                    onChange={ e => setTitulo( e.target.value ) }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ minLength: 5, maxLength: 40 }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box px={2} pb={2}> 
                                                <InputLabel shrink> Público<b className="asterisk-w">*</b> </InputLabel>
                                                <Select 
                                                    name="country"
                                                    style={{minWidth:'100%'}}
                                                    onChange={ e => setPublico( e.target.value ) }
                                                >
                                                    <MenuItem disabled value=''><em>Seleccionar</em></MenuItem>
                                                    <MenuItem value="todos">Todos</MenuItem>
                                                    <MenuItem value="escuelas">Escuelas</MenuItem>
                                                    <MenuItem value="maestros">Maestros</MenuItem>
                                                    <MenuItem value="padres">Padres de familia</MenuItem>
                                                    <MenuItem value="alumnos">Alumnos</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box px={2} pb={2}> 
                                                <InputLabel shrink> Detalles de notificaciones * </InputLabel>
                                                            
                                                <TextareaAutosize
                                                    rowsMin={4} 
                                                    required 
                                                    placeholder="Detalles de notificación" 
                                                    className="text_area-style" 
                                                    value={ body } 
                                                    onChange={e => setBody(e.target.value)}
                                                />
                                                <FormHelperText error={descripcionError}>{descripcionErrorText}</FormHelperText>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </Grid>
    )
}

export default NotificatiosforSchool
