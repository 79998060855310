import React, { useState, useContext, useEffect  } from 'react';
import { AuthContext } from "../../Components/functions/Auth";
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
//Components
import PageHeader from '../../Components/Global/PageHeader';
import ShowContracts from '../../Components/Global/ShowContracts';
import ModalShow from '../../Components/Global/Modal';
//img
import academy from './../../Assets/Img/General/Academy.png';
//MaterialUI
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// firebases
import * as firebase from 'firebase';
import NavEscuelaPhone from './NavEscuelaPhone/NavEscuelaPhone';

function Groups () {
    const {from,id} = useParams();
    const { currentUser }   = useContext(AuthContext);
    const history = useHistory();

    const [ isShow, setIsShow ]         = useState( false );
    const [ tutorError, setTutorError ] = useState('');
    const [ list, setList ]             = useState([]);

    //Modal
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => setModalStaus(true);
    
    const ModalClose = () => setModalStaus(false);
    
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const sortListByLevel = () =>{

        
        return list.sort((a, b) => (a.props.sortKey > b.props.sortKey) ? 1 : -1)
    }

    const  levelOrder = (level, grade, group)=>
    {
        var levelvalue = 0;
        var gradevalue = 0;
        var groupvalue = 0;
        if( level?.toLowerCase() == "kinder" )
        {
            levelvalue = 100;
            switch (grade?.toLowerCase()) {
                case "segundo":
                    gradevalue = 10
                break;
                case "tercero":
                    gradevalue = 20
                break;
                case "cuarto":
                    gradevalue = 30
                break;
                case "quinto":
                    gradevalue =40
                break;
                case "sexto":
                    gradevalue = 50
                break;
            }
            switch (group?.toLowerCase()) {
                case "a":
                    groupvalue = 1
                break;
                case "b":
                    groupvalue = 2
                break;
                case "c":
                    groupvalue = 3
                break;
                case "d":
                    groupvalue = 4
                break;
                case "f":
                    groupvalue = 5
                break;
                case "g":
                    groupvalue = 6
                break;
                case "h":
                    groupvalue = 7
                break;
                case "i":
                    groupvalue = 8
                break;
                case "j":
                    groupvalue = 9
                break;
            }
        }
        else if( level?.toLowerCase() == "primaria" )
        {
            levelvalue = 200;
            switch (grade?.toLowerCase()) {
                case "segundo":
                    gradevalue = 10
                break;
                case "tercero":
                    gradevalue = 20
                break;
                case "cuarto":
                    gradevalue = 30
                break;
                case "quinto":
                    gradevalue =40
                break;
                case "sexto":
                    gradevalue = 50
                break;
            }
            switch (group?.toLowerCase()) {
                case "a":
                    groupvalue = 1
                break;
                case "b":
                    groupvalue = 2
                break;
                case "c":
                    groupvalue = 3
                break;
                case "d":
                    groupvalue = 4
                break;
                case "f":
                    groupvalue = 5
                break;
                case "g":
                    groupvalue = 6
                break;
                case "h":
                    groupvalue = 7
                break;
                case "i":
                    groupvalue = 8
                break;
                case "j":
                    groupvalue = 9
                break;
            }
        }
        else if( level?.toLowerCase() == "secundaria" )
        {
            levelvalue = 300;
            switch (grade?.toLowerCase()) {
                case "segundo":
                    gradevalue = 10
                break;
                case "tercero":
                    gradevalue = 20
                break;
                case "cuarto":
                    gradevalue = 30
                break;
                case "quinto":
                    gradevalue =40
                break;
                case "sexto":
                    gradevalue = 50
                break;
            }
            switch (group?.toLowerCase()) {
                case "a":
                    groupvalue = 1
                break;
                case "b":
                    groupvalue = 2
                break;
                case "c":
                    groupvalue = 3
                break;
                case "d":
                    groupvalue = 4
                break;
                case "f":
                    groupvalue = 5
                break;
                case "g":
                    groupvalue = 6
                break;
                case "h":
                    groupvalue = 7
                break;
                case "i":
                    groupvalue = 8
                break;
                case "j":
                    groupvalue = 9
                break;
            }
        }
        return levelvalue + gradevalue + groupvalue;
    }


    useEffect( () =>{
        let db = firebase.database();
        let ref;
        if(id && from === 'admin') ref = db.ref(`schools/${id}/groups`);
        else ref = db.ref(`schools/${currentUser}/groups`);
        let listItem = [];

        ref.once('value').then( snapshot => {
            if (  snapshot.val() ) {
                // console.log(snapshot.val())
                snapshot.forEach( item => {
                    console.log(typeof item.key)
                    if ( item.key !== 'details' ) {
                        let itemInfo = item.val()
                        // if(itemInfo.level === "Kinder"){
                            listItem.push(
                                <item
                                    sortKey={levelOrder(itemInfo.level, itemInfo.grade, itemInfo.group)}
                                    key={ item.key }
                                    img={ academy }
                                    grade={ `${itemInfo.grade}` }
                                    grade_number={ `${itemInfo.key}` }
                                    group={ `${itemInfo.group}` }
                                    info={ `${itemInfo.turn}` }
                                    level={ `${itemInfo.level}` }
                                    btn_to={ (id && from === 'admin') ? `/admin/horarios/${id}/detalles/info/${item.key}`: `/grupos/informacion/${item.key}`}
                                    btnFirstButton={(id && from === 'admin')?'VER HORARIO':'VER GRUPO'}
                                />
                            ) 
                        // }else if(itemInfo.level === "Primaria"){
                        //     listItem.push(
                        //         <item
                        //             sortKey={2}
                        //             key={ item.key }
                        //             img={ academy }
                        //             grade={ `${itemInfo.grade}` }
                        //             grade_number={ `${itemInfo.key}` }
                        //             group={ `${itemInfo.group}` }
                        //             info={ `${itemInfo.turn}` }
                        //             level={ `${itemInfo.level}` }
                        //             btn_to={ `/grupos/informacion/${item.key}` }
                        //         />
                        //     ) 
                        // }else if(itemInfo.level === "Secundaria"){
                        //     listItem.push(
                        //         <item
                        //             sortKey={3}
                        //             key={ item.key }
                        //             img={ academy }
                        //             grade={ `${itemInfo.grade}` }
                        //             grade_number={ `${itemInfo.key}` }
                        //             group={ `${itemInfo.group}` }
                        //             info={ `${itemInfo.turn}` }
                        //             level={ `${itemInfo.level}` }
                        //             btn_to={ `/grupos/informacion/${item.key}` }
                        //         />
                        //     ) 
                        // }else{
                        //     listItem.push(
                        //         <item
                        //             sortKey={4}
                        //             key={ item.key }
                        //             img={ academy }
                        //             grade={ `${itemInfo.grade}` }
                        //             grade_number={ `${itemInfo.key}` }
                        //             group={ `${itemInfo.group}` }
                        //             info={ `${itemInfo.turn}` }
                        //             level={ `${itemInfo.level}` }
                        //             btn_to={ `/grupos/informacion/${item.key}` }
                        //         />
                        //     )    
                        // }

                    }
                })
                setList( listItem.sort((a, b) => (a.props.sortKey > b.props.sortKey) ? 1 : -1)  );      
            }
            if (  snapshot.val() == [] ) {
                setList( false );
            }
            if ( snapshot.val() == null){
                setList( false );
            }
        });
    }, [] )

    return(
        <Grid container className="alumn_grid">
            {/* <NavEscuelaPhone /> */}
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global"> 
                    {
                        isShow &&
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> 
                    }
                </Grid>
            </Modal>
            <Grid item xs={12}>
                <Box pb={3} className="PageHeader_toRight">
                    <PageHeader 
                        title="Grupos"
                        // text="Ver y administrar grupos"
                        btn_to={`/grupos/crearGrupo`}
                        btn_to_text="Crear Grupo"
                        text={id ?"Ver horarios de los grupos":"Ver y administrar grupos"}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="box_w">
                    <ShowContracts 
                        list={ list } 
                        isGroups={ true }
                        
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Groups
