export const checkFile = async ( image ) => {
    if( image && (image.type == "image/png" || image.type == "image/jpg" || image.type == "image/jpeg")) 
        return true
    else
        return false
}

export const checkFilepdf = async ( file ) => {
    if( file && (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "application/pdf" || file.type == "aplication/doc")) 
        return true
    else
        return false
}
